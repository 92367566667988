import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PaletteIcon from '@mui/icons-material/Palette';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldColorPicker } from '../Field/FieldColorPicker';
import { FieldImageUpload } from '../Field/FieldImageUpload';
import { FieldLabel } from '../Field/FieldLabel';

import { updatePlateformeSettingsStyle } from '../../api/PlateformeSettings.api';

import {  IPlateformeSettingsStyle } from '../../model/PlateformeSettings.model';

import { messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settings: IPlateformeSettingsStyle;
    send: (settings: IPlateformeSettingsStyle) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsStyle = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const regexColorHexa: RegExp = /^\#([a-f]|[A-F]|\d){6}$/g;
    const errorColorHexa: string = "La couleur doit être définie en hexadécimal";

    const validationSchema = yup.object({
        imageLogo: yup.string().nullable(),
        colorPrimary: yup.string().matches(regexColorHexa, errorColorHexa).nullable().required(messageFieldRequired),
        colorSecondary: yup.string().matches(regexColorHexa, errorColorHexa).nullable().required(messageFieldRequired),
        colorTextPrimary: yup.string().matches(regexColorHexa, errorColorHexa).nullable().required(messageFieldRequired),
        colorTextSecondary: yup.string().matches(regexColorHexa, errorColorHexa).nullable().required(messageFieldRequired),
    })

    const formik = useFormik({
        initialValues: {
            imageLogo: props.settings.imageLogo,
            colorPrimary: props.settings.colorPrimary,
            colorSecondary: props.settings.colorSecondary,
            colorTextPrimary: props.settings.colorTextPrimary,
            colorTextSecondary: props.settings.colorTextSecondary
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsStyle = {
                id: props.settings.id,
                imageLogo: values.imageLogo,
                colorPrimary: values.colorPrimary,
                colorSecondary: values.colorSecondary,
                colorTextPrimary: values.colorTextPrimary,
                colorTextSecondary: values.colorTextSecondary
            }
            updatePlateformeSettingsStyle(toSend)
            .then((response: IPlateformeSettingsStyle) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres de l'apparence.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <PaletteIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Apparence
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Ces paramètres seront utilisés pour la génération des documents (PDF et mails) envoyés au souscripteur.<br />
                                    <div style={{marginTop: "8px", color: colors.red.main}}>❗L'apparence du tunnel n'est pas gérée via le back office❗</div>
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Palette de couleur
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <FormLabel error={formik.touched.colorPrimary && Boolean(formik.errors.colorPrimary)} >
                                <FieldLabel
                                    label="Principale"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <FieldColorPicker
                                id="colorPrimary"
                                value={formik.values.colorPrimary}
                                onChange={(value) => formik.setFieldValue("colorPrimary", value)}
                                onBlur={() => formik.setFieldTouched("colorPrimary")}
                                error={formik.touched.colorPrimary && Boolean(formik.errors.colorPrimary)}
                                helperText={formik.touched.colorPrimary && formik.errors.colorPrimary} />
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <FormLabel error={formik.touched.colorSecondary && Boolean(formik.errors.colorSecondary)} >
                                <FieldLabel
                                    label="Secondaire"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <FieldColorPicker
                                id="colorSecondary"
                                value={formik.values.colorSecondary}
                                onChange={(value) => formik.setFieldValue("colorSecondary", value)}
                                onBlur={() => formik.setFieldTouched("colorSecondary")}
                                error={formik.touched.colorSecondary && Boolean(formik.errors.colorSecondary)}
                                helperText={formik.touched.colorSecondary && formik.errors.colorSecondary} />
                        </Grid>

                        <Grid
                            item
                            sx={{
                                display: {
                                    md: "flex",
                                    xs: "none"
                                }
                            }}
                            xs={4} >
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <FormLabel error={formik.touched.colorTextPrimary && Boolean(formik.errors.colorTextPrimary)} >
                                <FieldLabel
                                    label="Texte principal"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <FieldColorPicker
                                id="colorTextPrimary"
                                value={formik.values.colorTextPrimary}
                                onChange={(value) => formik.setFieldValue("colorTextPrimary", value)}
                                onBlur={() => formik.setFieldTouched("colorTextPrimary")}
                                error={formik.touched.colorTextPrimary && Boolean(formik.errors.colorTextPrimary)}
                                helperText={formik.touched.colorTextPrimary && formik.errors.colorTextPrimary} />
                        </Grid>

                        <Grid
                            item
                            md={4}
                            sm={6}
                            xs={12} >
                            <FormLabel error={formik.touched.colorTextSecondary && Boolean(formik.errors.colorTextSecondary)} >
                                <FieldLabel
                                    label="Texte secondaire"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <FieldColorPicker
                                id="colorTextSecondary"
                                value={formik.values.colorTextSecondary}
                                onChange={(value) => formik.setFieldValue("colorTextSecondary", value)}
                                onBlur={() => formik.setFieldTouched("colorTextSecondary")}
                                error={formik.touched.colorTextSecondary && Boolean(formik.errors.colorTextSecondary)}
                                helperText={formik.touched.colorTextSecondary && formik.errors.colorTextSecondary} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Images
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            sm={8}
                            xs={12} >
                            <FormLabel error={formik.touched.imageLogo && Boolean(formik.errors.imageLogo)} >
                                <FieldLabel
                                    label="Logo"
                                    fontWeight={500} />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                L'image doit être au format paysage ou carré.
                            </Typography>
                            <FieldImageUpload
                                image={formik.values.imageLogo}
                                send={(_: string) => formik.setFieldValue("imageLogo", _)} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import Box from '@mui/system/Box';
import { Button } from '@mui/material';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    label: string;
    icon?: JSX.Element;
    color?: string;
    isActive?: boolean;
    onClick: () => void;
}


export const ButtonLink = (props: Props) => {

    return (
        <Box
            sx={{
                position: "relative"
            }}>
            <Button
                fullWidth
                startIcon={props.icon}
                onClick={props.onClick}
                sx={{
                    textAlign: "left",
                    justifyContent: "flex-start",
                    py: 1,
                    px: 2,
                    borderRadius: 4,
                    background: props.isActive ? colors.grey.secondary : "none",
                    color: props.color ? props.color : props.isActive ? colors.blue.main : colors.black.main,
                    fontWeight: props.isActive ? 700 : 500,
                    fontSize: "1rem !important",
                    ":hover": {
                        background: colors.grey.main
                    }
                }}>
                {props.label}
            </Button>
            {props.isActive &&
            <Box
                sx={{
                    position: "absolute",
                    top: 1,
                    right: -16,
                    height: "calc(100% - 6px)",
                    border: 2.5,
                    borderColor: colors.blue.main,
                    borderRadius: "8px 0 0 8px"
                }}>
            </Box>}
        </Box>
    )
}

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { CloseIcon } from '../Icon/CloseIcon';
import { TransitionSlideUP } from '../../utils/transistions/transitions';
import { Accordion } from '../Accordion/Accordion';
import { CardInfo } from '../Card/CardInfo';

import RIBSpecimen from '../../static/img/rib-specimen.png';

interface Props {
    plateformStyle: any;
    isDisplayed: boolean;
    onClose: () => void;
}


export const DialogInfoRIB = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle = props.plateformStyle;
    const iconInfoStyle: any = props.plateformStyle.components.IconInfo;
    const listStyle: any = plateformStyle.components.List;

    return (
        <Dialog
            open={props.isDisplayed}
            fullWidth
            maxWidth='lg'
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            onClose={() => props.onClose()}>
            <DialogTitle
                sx={{
                    pt: 1
                }} >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                    onClick={() => props.onClose()}>
                    <CloseIcon color={plateformStyle.colors.black.main}/>
                </IconButton>
                <Typography variant="h2">Où trouver vos informations bancaire ?</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2} >
                    <Grid
                        item
                        xs={12}>
                        <CardInfo
                            plateformStyle={props.plateformStyle}>
                            <Typography fontWeight={500}>Votre <b>IBAN</b> et <b>BIC</b> peuvent être trouvé sur votre <b>RIB</b> (Relevé d’Identité Bancaire).</Typography>
                        </CardInfo>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: "justify"
                        }} >
                        <Accordion
                            plateformStyle={props.plateformStyle}
                            label="Où trouver son RIB ?">
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    width: "100%",
                                    mt: 0.5,
                                    px: 1
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>Vous pouvez trouver votre RIB à plusieurs endroits.</Typography>
                                    <ul
                                        style={{
                                            margin: 0,
                                            marginTop: '4px'
                                        }}>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Sur l’espace client ou l’application de votre banque
                                            </Typography>
                                        </li>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Dans votre chéquier
                                            </Typography>
                                        </li>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Au guichet de votre banque
                                            </Typography>
                                        </li>
                                    </ul>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: {
                                md: "left",
                                xs: "justify"
                            }
                        }}>
                        <Accordion
                            plateformStyle={props.plateformStyle}
                            label="Contenu du RIB"
                            isOpen>
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    mt: 0.5,
                                    px: 1
                                }}>
                                <Grid
                                    item
                                    md
                                    xs={12}>
                                    <Typography fontWeight={500}>L'<b>IBAN</b> est composé de <b>27</b> caractères au format <b style={{whiteSpace: "nowrap"}}>FRXX XXXX XXXX XXXX XXXX XXXX XXX</b> (pour les comptes détenus en France).</Typography>
                                    <Typography fontWeight={500} sx={{mt: 2}}>Le <b>BIC</b> est composé de <b>8</b> ou <b>11</b> caractères au format <b style={{whiteSpace: "nowrap"}}>XXXX XX XX XXX</b>.<br/>
                                    Si votre BIC contient <b>11</b> caractères, veuillez ne pas saisir les 3 derniers caractères.</Typography>
                                    <Typography fontWeight={500} sx={{mt: 2}}>Vous trouverez ces informations sur votre <b>RIB</b>, comme indiqué sur l'illustration suivante.</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md="auto"
                                    xs={12}
                                    sx={{
                                        textAlign: "center",
                                        height: {
                                            sm: "250px",
                                            xs: "200px"
                                        }
                                    }}>
                                    <img src={RIBSpecimen} height="100%"/>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

import { useState } from 'react';

import { ErrorCode, useDropzone } from 'react-dropzone';

import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import CardActionArea from '@mui/material/CardActionArea';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Typography from "@mui/material/Typography"

import { default as MUICloseIcon} from '@mui/icons-material/Close';

import { CloseIcon } from '../Icon/CloseIcon';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { FolderEmptyIcon } from "../Icon/FolderEmptyIcon"
import { UploadIcon } from "../Icon/UploadIcon"

import { colors } from '../../static/themes/gsmc/colors';
import { CheckFullIcon } from '../Icon/CheckFullIcon';
import Slide from '@mui/material/Slide';
import { Box, LinearProgress, Tooltip, useTheme } from '@mui/material';


interface Props {
    label?: string;
    sublabel?: string;
    required?: Boolean;
    hideIconEmpty?: boolean;
    image?: string;
    send: (image: string) => void;
}


export const FieldImageUpload = (props: Props) => {

    const theme = useTheme()

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("Format de fichier invalide.");

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const { open, getRootProps, getInputProps } = useDropzone({
        accept: {
            'image/bmp': [],
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        maxSize: 10000000,
        onDragEnter: e => setIsDragOver(true),
        onDragLeave: e => setIsDragOver(false),
        onDrop: async files => {

            if (files.length <= 0)
                return;
            
            setIsLoading(true);

            const base64Image = await convertToBase64(files[0]);

            props.send(base64Image as string);

            setIsLoading(false);
        },
        onDropRejected: files => {
            if (files[0].errors.find(_ => _.code === ErrorCode.FileInvalidType) !== undefined)
                setSnackBarMessage("Format de fichier invalide.")
            else if (files[0].errors.find(_ => _.code === ErrorCode.FileTooLarge) !== undefined)
                setSnackBarMessage("Fichier trop volumineux (10Mo maximum).")
            else if (files[0].errors.find(_ => _.code === ErrorCode.TooManyFiles) !== undefined)
                setSnackBarMessage("Un seul fichier autorisé.")
            else
                setSnackBarMessage("Fichier invalide.")

            setIsSnackbarOpened(true);
            setIsDragOver(false);
        }
    });

    const convertToBase64 = (file: any) => {
        return new Promise((resolve, reject) => {
            const fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = () => {
                resolve(fileReader.result);
            };
            fileReader.onerror = (error) => {
                reject(error);
            };
        });
    };

    return (
        <>
        <Card
            sx={{
                position: "relative",
                textAlign: 'center',
                cursor: "pointer",
                backgroundColor: 'white',
                borderStyle: props.image ? 'solid' : 'dashed',
                borderColor: props.image ? colors.grey.main : colors.black.secondary,
                borderWidth: 1,
                transition: "transform 400ms",
                transform: isDragOver ? "translateY(-6px)" : "",
                boxShadow: isDragOver ? "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : 0,
                ":hover": {
                    boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    transform: "translateY(-6px)"
                }
            }}
            {...getRootProps({ className: 'dropzone' })} >
            <LinearProgress
                value={100}
                color='primary'
                sx={{
                    display: isLoading ? 'block' : 'none'
                }} />

            <Box
                sx={{
                    p: 1
                }}>
                <input {...getInputProps()} />

                {props.image &&
                <Box
                    sx={{
                        position: 'absolute',
                        top: 0,
                        right: 0,
                        borderRadius: 2,
                        background: "white"
                    }}>
                    <Tooltip
                        title="Supprimer"
                        placement="top">
                        <IconButton
                            onClick={(e) => {
                                e.stopPropagation();
            
                                props.send("");
                            }}>
                            <CloseIcon color={colors.black.main}/>
                        </IconButton>
                    </Tooltip>
                </Box>}

                <Grid
                    container
                    justifyContent='center'
                    alignItems='center'
                    gap={1}>
                    {(props.image || props.hideIconEmpty !== true) &&
                    <Grid
                        item>
                        {props.image ?
                        <img
                            src={props.image}
                            loading="lazy"
                            style={{ 
                                verticalAlign: "middle",
                                width: "100%",
                                borderRadius: "8px"
                            }} />
                        : <FolderEmptyIcon color={theme.palette.primary.main} height='30px' width='40px'/>}
                    </Grid>}

                    {!props.image && (props.label || props.sublabel) &&
                    <Grid
                        item
                        xs>
                        {props.label &&
                        <Typography
                            variant='body2'
                            fontWeight={700} >
                            {props.label}
                            {props.required && <Typography variant='h3' component='span' color='error' >*</Typography>}
                        </Typography>}

                        {props.sublabel && 
                        <Typography
                            variant='caption'
                            fontWeight={500} >
                            {props.sublabel}
                        </Typography>}
                    </Grid>}
                </Grid>
            </Box>
        </Card>
        
        <Snackbar
            sx={{
                maxWidth: '1200px',
                width: {
                    sm: '100%'
                },
                px: {
                    sm: 2
                },
                py: 'auto'
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnackbarOpened}
            autoHideDuration={5000}
            onClose={handleSnackbarClose} >
            <Alert
                sx={{
                    mx: {
                        sm: 2
                    },
                    my: 'auto',
                }}
                severity='error'
                iconMapping={{
                    success: <CheckFullIcon bgColor='white' color={theme.palette.success.main} />,
                    error: <CloseFullIcon bgColor='white' color={theme.palette.error.main} />,
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}>
                        <MUICloseIcon fontSize="inherit" fontWeight={700} />
                    </IconButton>
                }>
                <Typography fontWeight={500}>{snackBarMessage}</Typography>
            </Alert>
        </Snackbar>
        </>
    )
}

import { useLocation, useNavigate } from 'react-router-dom';

import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import { colors } from '../../static/themes/gsmc/colors';
import { ButtonLink } from '../Button/ButtonLink';

export type type = "TITLE" | "LINK"

export interface IAppBarItem {
    type: type;
    label: string;
    icon?: JSX.Element;
    url?: string;
    urlBase?: string;
    isActive?: boolean;
}

interface Props {
    header: JSX.Element;
    items: IAppBarItem[];
    footer: JSX.Element;
}

export default function AppBar(props: Props) {

    const navigate = useNavigate();
    const location = useLocation();

    const menu = props.items.map(_ => 
        _.type === "TITLE" ?
        <Grid
            container
            alignItems='center'
            gap={1}
            sx={{
                mt: 2,
                mb: 1,
            }}>
            <Grid
                item>
                {_.icon}
            </Grid>
            <Grid
                item>
                <Typography
                    variant='body1'
                    sx={{
                        color: colors.black.main,
                        textTransform: "uppercase",
                    }}>
                    {_.label}
                </Typography>
            </Grid>
        </Grid>

        :
        <ButtonLink
            label={_.label}
            icon={_.icon} 
            isActive={_.isActive || location.pathname.match("^" + (_.urlBase ? `/${_.urlBase}/${_.url}` : _.url) + "(/[0-9]+)*$") !== null}
            onClick={() => {
                if (_.url) navigate(_.url)
            }} />)

    return (
        <>
        <Grid
            container
            direction="column"
            justifyContent="space-between"
            sx={{
                height: "100%"
            }}>
            <Grid
                item
                sx={{
                    pt: 1,
                    pb: 2
                }} >
                {props.header}
            </Grid>

            <Grid
                item
                xs >
                {menu}
            </Grid>

            <Grid
                item >
                {props.footer}
            </Grid>
        </Grid>
        </>
    );
}

import {
    IPlateformeSettings,
    IPlateformeSettingsBanking,
    IPlateformeSettingsContact,
    IPlateformeSettingsDocument,
    IPlateformeSettingsFraud,
    IPlateformeSettingsSmtp,
    IPlateformeSettingsStyle,
    IPlateformeSettingsSubscription,
    IPlateformeSettingsTarification,
    IPlateformeSettingsTemplateDocument,
    IPlateformeSettingsTemplateEmail,
    IPlateformeSettingsWebhook
} from "../model/PlateformeSettings.model"

import { msalConfig } from "../utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";


export const msalInstance = new PublicClientApplication(msalConfig);


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const apiUrl = process.env.REACT_APP_API_URL + '/backoffice'

export const getPlateformeSettings = (code: string): Promise<IPlateformeSettings> => {

    return fetch(`${apiUrl}/plateforme/${code}/settings`,
        {
            method: "GET",
        }).then(_ => _.json())
}


export const updatePlateformeSettingsBanking = (settings: IPlateformeSettingsBanking): Promise<IPlateformeSettingsBanking> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/banking`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsContact = (settings: IPlateformeSettingsContact): Promise<IPlateformeSettingsContact> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/contact`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsDocument = (settings: IPlateformeSettingsDocument): Promise<IPlateformeSettingsDocument> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/document`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsFraud = (settings: IPlateformeSettingsFraud): Promise<IPlateformeSettingsFraud> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/fraud`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}

export const updatePlateformeSettingsTarification = (settings: IPlateformeSettingsTarification) : Promise<IPlateformeSettingsTarification> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/tarification`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}

export const updatePlateformeSettingsSmtp = (settings: IPlateformeSettingsSmtp): Promise<IPlateformeSettingsSmtp> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/smtp`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}

export const updatePlateformeSettingsWebhook = (settings: IPlateformeSettingsWebhook): Promise<IPlateformeSettingsWebhook> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/webhook`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsSubscription = (settings: IPlateformeSettingsSubscription): Promise<IPlateformeSettingsSubscription> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/subscription`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsStyle = (settings: IPlateformeSettingsStyle): Promise<IPlateformeSettingsStyle> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/style`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsTemplateDocument = (settings: IPlateformeSettingsTemplateDocument): Promise<IPlateformeSettingsTemplateDocument> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/template/document`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}


export const updatePlateformeSettingsTemplateEmail = (settings: IPlateformeSettingsTemplateEmail): Promise<IPlateformeSettingsTemplateEmail> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/settings/template/email`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(settings)
            }).then(_ => _.json())
    });
}

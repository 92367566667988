import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import TelegramIcon from '@mui/icons-material/Telegram';
import Typography from '@mui/material/Typography';
import WebhookIcon from '@mui/icons-material/Webhook';

import { CardError } from '../../../components/Card/CardError';
import { FormCampaignSettings } from '../../../components/Form/FormCampaignSettings';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';

import { getCampaignSettings } from '../../../api/Campaign.api';

import { ICampaign, ICampaignSettings } from '../../../model/Campaign.model';

import { colors } from '../../../static/themes/gsmc/colors';
import AppBarHeader from '../../../components/AppBar/AppBarHeader';


export const CampaignSettings = () => {

    // URL parameters.
    const urlCampaignCode = useParams<{ code: string }>().code

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const [isSnackbarDisplayed, setIsSnackbarDisplayed] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();

    const [tabValue, setTabValue] = useState<string>("90");

    const [campaign, setCampaign] = useState<ICampaign | null>();
    const [campaignSettings, setCampaignSettings] = useState<ICampaignSettings | null>();

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarDisplayed(false);
    };

    useEffect(() => {
        if (!urlCampaignCode)
            return;

        getCampaignSettings(urlCampaignCode)
            .then((response: ICampaignSettings | any) => {
                if (response.status && response.status !== 200) {
                    setCampaign(null);
                    return;
                }

                setCampaign(response.campaign);
                setCampaignSettings(response);
                document.title = `BO - Campagne ${response.campaign.code}`;
            })
            .catch((exception) => {
                setCampaign(null);
                setCampaignSettings(null);
            })
    }, [urlCampaignCode])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <TelegramIcon />},
                {type: "LINK", label: "Campagne", url: "/bo/campaign"},
                {type: "LINK", label: urlCampaignCode, url: "/bo/campaign"},
                {type: "TITLE", label: "Paramètres"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>

                {campaign === undefined ?
                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            height: '150px',
                            textAlign: 'center'
                        }}>
                        <Grid
                            container
                            spacing={0}
                            direction="column"
                            alignItems="center"
                            justifyContent="center"
                            sx={{
                                height: '100%'
                            }} >
                            <Grid
                                item>
                                <Typography
                                    variant='subtitle1'>
                                    Chargement des paramètres de la campagne en cours...
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                : campaign === null ?
                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            height: '150px',
                            textAlign: 'center'
                        }}>
                        <CardError
                            title={`Une erreur est survenue lors de la récupération de la campagne “${urlCampaignCode}”`}
                            description="Veuillez réessayer ultérieurement" />
                    </Card>
                </Grid>

                :
                <>
                <Grid
                    item
                    xs={12} >
                    <Card>
                        <TabContext value={tabValue}>
                            <Grid
                                container
                                spacing={0}
                                alignItems="flex-start"
                                justifyContent="flex-start"
                                sx={{
                                    height: '100%'
                                }} >
                                <Grid
                                    item
                                    md={2}
                                    sm={3}
                                    xs={12}>
                                    <Tabs
                                        value={tabValue}
                                        orientation={screenSizeUpSM ? "vertical" : "horizontal"}
                                        onChange={(e, value) => setTabValue(value)}
                                        variant="scrollable"
                                        allowScrollButtonsMobile
                                        sx={{
                                            textAlign: 'left'
                                        }}>

                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'
                                                    sx={{
                                                        color: colors.black.secondary
                                                    }}>
                                                    Général
                                                </Typography>}
                                            disabled
                                            sx={{
                                                display: {
                                                    sm: 'block',
                                                    xs: 'none'
                                                }
                                            }} />
                                        <Tab
                                            label={
                                                <Typography
                                                    variant='body1'>
                                                    <WebhookIcon
                                                        fontSize='small'
                                                        sx={{
                                                            verticalAlign: 'bottom',
                                                            mb: 0.25,
                                                            mr: 0.5
                                                        }} />
                                                    <Typography
                                                        component='span'
                                                        fontWeight={tabValue === "90" ? 700 : 400}
                                                        sx={{
                                                            display: {
                                                                sm: 'inline',
                                                                xs: 'none'
                                                            }
                                                        }}>
                                                        Actions
                                                    </Typography>
                                                </Typography>}
                                            value="90" />
                                    </Tabs>
                                </Grid>
                                <Grid
                                    item
                                    md={10}
                                    sm={9}
                                    xs={12}
                                    sx={{
                                        minHeight: '650px',
                                        borderLeft: 1,
                                        borderColor: {
                                            sm: colors.grey.main,
                                            xs: colors.grey.secondary
                                        }
                                    }}>
                                    <TabPanel value="90">
                                        {!campaignSettings ?
                                        <CardError
                                            title="Une erreur est survenue lors de la récupération des paramètres"
                                            description="Veuillez réessayer ultérieurement" />

                                        :
                                        <FormCampaignSettings
                                            settings={campaignSettings}
                                            send={(_) => setCampaignSettings(_)}
                                            handleError={(_) => {
                                                setSnackbarMessage(_);
                                                setIsSnackbarDisplayed(true);
                                            }} />}
                                    </TabPanel>

                                </Grid>
                            </Grid>
                        </TabContext>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
                </>}
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarDisplayed}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />
                    }} >
                    <Typography fontWeight={500} >{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

import { ErrorContent } from './ErrorContent';


export const ServerError = () => {

    return (
        <ErrorContent
            code="500"
            color="error" />
    )
}

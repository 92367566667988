import * as styleGraphiste from "./graphiste/style";
import * as styleGSMC from "./gsmc/style";
import * as styleLesFurets from "./lesfurets/style";
import * as styleCodeur from "./codeur/style";
import * as styleRedacteur from "./redacteur/style";
import * as styleTraduc from "./traduc/style";
import * as styleAngel from "./angel/style";
import * as styleBleuBonheur from "./bleuBonheur/style";
import * as themeGraphiste from "./graphiste/theme";
import * as themeGSMC from "./gsmc/theme";
import * as themeLesFurets from "./lesfurets/theme";
import * as themeCodeur from "./codeur/theme";
import * as themeRedacteur from "./redacteur/theme";
import * as themeTraduc from "./traduc/theme";
import * as themeAngel from "./angel/theme";
import * as themeBleuBonheur from "./bleuBonheur/theme";


import { enumPlateformCodeGraphiste,
    enumPlateformCodeGSMC,
    enumPlateformCodeLesFurets,
    enumPlateformCodeCodeur,
    enumPlateformCodeTraduc,
    enumPlateformCodeRedacteur,
    enumPlateformCodeAngel,
    enumPlateformCodeLPBB} from '../../utils/enums';


export const getPlateformTheme = (plateformCode?: string) => {
    switch (plateformCode) {
        case enumPlateformCodeGraphiste:
            return themeGraphiste.theme;

        case enumPlateformCodeLesFurets:
            return themeLesFurets.theme;

        case enumPlateformCodeCodeur:
            return themeCodeur.theme;

        case enumPlateformCodeRedacteur:
            return themeRedacteur.theme;

        case enumPlateformCodeTraduc:
            return themeTraduc.theme;

        case enumPlateformCodeAngel:
            return themeAngel.theme;

        case enumPlateformCodeLPBB:
            return themeBleuBonheur.theme;

        default:
        case enumPlateformCodeGSMC:
            return themeGSMC.theme;
    }
}


export const getPlateformStyle = (plateformCode?: string) => {
    switch (plateformCode) {
        case enumPlateformCodeGraphiste:
            return styleGraphiste.style;

        case enumPlateformCodeLesFurets:
            return styleLesFurets.style;

        case enumPlateformCodeCodeur:
            return styleCodeur.style;

        case enumPlateformCodeRedacteur:
            return styleRedacteur.style;

        case enumPlateformCodeTraduc:
            return styleTraduc.style;

        case enumPlateformCodeAngel:
            return styleAngel.style;

        case enumPlateformCodeLPBB:
            return styleBleuBonheur.style;

        default:
        case enumPlateformCodeGSMC:
            return styleGSMC.style;
    }
}

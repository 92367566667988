import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


interface Props {
    plateformStyle: any;
    comment: string | JSX.Element | JSX.Element[];
}


export const OfferDetailsComment = (props: Props) => {
        return (
        <Grid
            item
            xs={12}
            sx={{
                textAlign: 'justify',
                pt: 2,
                pb: 1,
                pl: 1
            }} >
            <Typography variant='caption'>{props.comment}</Typography>
        </Grid>
    )
}


import BusinessIcon from '@mui/icons-material/Business';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import FormatListBulletedIcon from '@mui/icons-material/FormatListBulleted';
import InventoryIcon from '@mui/icons-material/Inventory';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import TelegramIcon from '@mui/icons-material/Telegram';

import { StepConfirmationIcon } from '../components/Icon/StepConfirmationIcon';
import { StepInformationIcon } from '../components/Icon/StepInformationIcon';
import { StepOfferIcon } from '../components/Icon/StepOfferIcon';
import { StepSignatureIcon } from '../components/Icon/StepSignatureIcon';

import { colors } from '../static/themes/colors';
import {ReactElement} from "react";


export const gsmcUrl = "https://www.mutuelle-gsmc.fr/home";

export enum FormStepId {
    OFFER="OFFER",
    INFORMATIONS="INFORMATIONS",
    SIGNATURE="SIGNATURE",
    CONFIRMATION="CONFIRMATION",
}

export interface FormStep {
    id: FormStepId,
    label: string,
    icon: ReactElement,
    innerStepsNumber: number,
}

export const formSteps: FormStep[] = [
    {
        id: FormStepId.OFFER,
        label: "Choix de l'offre",
        icon: <StepOfferIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    },
    {
        id: FormStepId.INFORMATIONS,
        label: "Informations",
        icon: <StepInformationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 3
    },
    {
        id: FormStepId.SIGNATURE,
        label: "Signature",
        icon: <StepSignatureIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 5
    },
    {
        id: FormStepId.CONFIRMATION,
        label: "Confirmation",
        icon: <StepConfirmationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    }
];

export const formStepsCourtier: FormStep[] = [
    {
        id: FormStepId.OFFER,
        label: "Choix de l'offre",
        icon: <StepOfferIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    },
    {
        id: FormStepId.INFORMATIONS,
        label: "Informations",
        icon: <StepInformationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 3
    },
    {
        id: FormStepId.SIGNATURE,
        label: "Signature",
        icon: <StepSignatureIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    },
    {
        id: FormStepId.CONFIRMATION,
        label: "Confirmation",
        icon: <StepConfirmationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    }
];

export const formSignatureOnlySteps: FormStep[] = [
    {
        // Pas de section offre pour le parcours de signature donc zéro inner step
        id: FormStepId.OFFER,
        label: "Choix de l'offre",
        icon: <StepOfferIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 0
    },
    {
        // Pas de section informations pour le parcours de signature donc zéro inner step
        id: FormStepId.INFORMATIONS,
        label: "Informations",
        icon: <StepInformationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 0
    },
    {
        id: FormStepId.SIGNATURE,
        label: "Signature",
        icon: <StepSignatureIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 5
    },
    {
        id: FormStepId.CONFIRMATION,
        label: "Confirmation",
        icon: <StepConfirmationIcon color={colors.green.main} width='100%' height='100%' />,
        innerStepsNumber: 1
    }
];


export const paramTablesBo = [
    {
        key: 'plateforme',
        label: "Plateformes",
        icon: <BusinessIcon />
    },
    {
        key: 'verification',
        label: "Vérification",
        icon: <FactCheckIcon />
    },
    {
        key: 'campaign',
        label: "Campagnes",
        icon: <TelegramIcon />
    }
];


export const paramTablesDwh = [
    {
        key: 'segment',
        label: "Segments",
        icon: <SupervisedUserCircleIcon />
    },
    {
        key: 'offre',
        label: "Offres",
        icon: <LocalOfferIcon />
    },
    {
        key: 'gamme',
        label: "Gammes",
        icon: <FormatListBulletedIcon />
    },
    {
        key: 'produit',
        label: "Produits",
        icon: <InventoryIcon />
    }
];

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import Box from "@mui/material/Box";

import Chip from "@mui/material/Chip";
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import AppBarHeader from "../../../components/AppBar/AppBarHeader";

import { ControlValidation } from "./{id}_content";
import { ControlStatus, controlStatusParam, IControle } from "../../../model/ControleDocument.model";
import { getControlById } from "../../../api/Controle.api";
import { colors } from "../../../static/themes/gsmc/colors";


export const Subscription = () => {
    const { id } = useParams()

    const [control, setControl] = useState<IControle | null>();
    const [statusParam, setStatusParam] = useState<any>();


    useEffect(() => {

        if (id === undefined)
            return;

        getControlById(parseInt(id))
        .then(response => {
            setControl(response);

            document.title = `#${response.id} - ${response.form.contract.person.lastname} ${response.form.contract.person.firstname} - Souscription GSMC`;
        })
        .catch(_ => {
            setControl(null);
        })
    }, []);

    useEffect(() => {
        if (!control?.status)
            return;

        if (control.hennerSent && control.form.contract.hennerContract?.isCallOver && (!control.form.contract.hennerContract?.codeContrat || control.form.contract.hennerContract.codeContrat === "GSM0"))
            setStatusParam({...controlStatusParam.find(e => e.value === ControlStatus.MEG), value: ControlStatus.MEG_KO})
        else
            setStatusParam(controlStatusParam.find(e => e.value === control.status))

    }, [control?.status, control?.isMEG]);

    return (
        <>
        <AppBarHeader
            items={[
                {type: "LINK", icon: <EmojiEventsIcon />, url: "/verification/souscription"},
                {type: "LINK", label: "Souscription", url: "/verification/souscription"},
                {type: "TITLE", label: id},
                {type: "TITLE", endIcon: <Chip label={`${statusParam?.value === ControlStatus.MEG_KO ? "⚠️ " : ""}${statusParam?.label}`}
                    sx={{
                        border: statusParam?.value === ControlStatus.MEG_KO ? 2 : 0,
                        borderColor: colors.red.main,
                        color: statusParam?.textColor || 'white',
                        backgroundColor: statusParam?.color,
                        fontSize: 14
                    }} />}
            ]} />
        <Box
            sx={{
                p: 2,
            }}>
            {control &&
            <ControlValidation
                control={control}
                send={(_) => {
                    setControl(prev => {
                        if (!prev)
                            return prev

                        return {...prev, status: _.status, form: _.form, hennerSent: _.hennerSent, isMEG: _.isMEG}
                    })
                }} />}
        </Box>
        </>
    )
}

import { useEffect, useState } from 'react';
import { ErrorCode, useDropzone } from 'react-dropzone';

import { useTheme } from '@mui/material/styles';
import * as Sentry from '@sentry/react';

import Alert from '@mui/material/Alert';
import Button from "@mui/material/Button"
import Card from "@mui/material/Card"
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Snackbar from '@mui/material/Snackbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from "@mui/material/Typography"

import { default as MUICloseIcon} from '@mui/icons-material/Close';

import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { CheckFullIcon } from '../Icon/CheckFullIcon';
import { CloseIcon } from '../Icon/CloseIcon';
import { DownloadIcon } from '../Icon/DownloadIcon';
import { FolderEmptyIcon } from "../Icon/FolderEmptyIcon"
import { UploadIcon } from "../Icon/UploadIcon"

import { deleteDocument, downloadDocument, uploadFormDocument } from "../../api/FormDocument.api"

import { IForm } from '../../model/Form.model';
import { IFormDocument } from "../../model/FormDocument.model"
import { ControlStatus, IControleDocument } from '../../model/ControleDocument.model';


interface Props {
    plateformStyle: any;
    title?: string;
    description?: string;
    emoji?: string;
    required?: boolean;
    disabled?: boolean;
    readOnly?: boolean;
    document: IFormDocument;
    form: IForm;
    downloadFlag?: number;
    send: (doc: IFormDocument) => void;
    delete?: (doc: IFormDocument) => void;
}


export const FieldFileUpload = (props: Props) => {

    const theme = useTheme()
    const plateformStyle: any = props.plateformStyle;

    const [isDragOver, setIsDragOver] = useState<boolean>(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackBarMessage, setSnackBarMessage] = useState<string>("Format de fichier invalide.");
    const status: ControlStatus | undefined = (props.document as IControleDocument).status
    const comment: String | undefined = (props.document as IControleDocument).comment

    const disabled: boolean = props.disabled !== undefined && props.disabled
    const readOnly: boolean = props.disabled || props.readOnly || status === ControlStatus.ACCEPTED

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const { open, getRootProps, getInputProps } = useDropzone({
        accept: {
            'application/pdf': [],
            'image/bmp': [],
            'image/jpg': [],
            'image/jpeg': [],
            'image/png': []
        },
        maxFiles: 1,
        maxSize: 10000000,
        disabled: disabled || readOnly,
        onDragEnter: e => setIsDragOver(true),
        onDragLeave: e => setIsDragOver(false),
        onDrop: files => {
            if (files.length <= 0)
                return;

            let doc: IFormDocument = {...props.document};

            doc.fileNameOrigin = files[0].name;
            doc.fileTypeMIME = files[0].type;

            const formData = new FormData();
            formData.append('file', files[0]);

            if (!props.form.id || !props.form.uuid) {
                setIsDragOver(false);
                return;
            }

            setIsLoading(true);

            uploadFormDocument(doc, props.form.uuid, formData)
            .then((response: IFormDocument) => {
                props.send(response);  // Update Form states.
                setIsLoading(false);
                setIsDragOver(false);
            })
            .catch(_ => {
                _.then((error: any) => {
                    if (error?.status === 400 && error.detail)
                        setSnackBarMessage(error.detail)
                    else
                        setSnackBarMessage("Erreur lors de l'envoi du document.")
                })
                
                Sentry.captureMessage("uploadFormDocument - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: _.toString(),
                            form: props.form.id,
                            filename: doc.fileName,
                            filenameOrigin: doc.fileNameOrigin,
                            extension: doc.fileExtension,
                            typeMime: doc.fileTypeMIME,
                            path: doc.filePath,
                        }
                    }
                )

                setIsSnackbarOpened(true);
                props.send({...props.document})

                setIsLoading(false);
                setIsDragOver(false);
            })
        },
        onDropRejected: files => {
            if (files[0].errors.find(_ => _.code === ErrorCode.FileInvalidType) !== undefined)
                setSnackBarMessage("Format de fichier invalide.")
            else if (files[0].errors.find(_ => _.code === ErrorCode.FileTooLarge) !== undefined)
                setSnackBarMessage("Fichier trop volumineux (10Mo maximum).")
            else if (files[0].errors.find(_ => _.code === ErrorCode.TooManyFiles) !== undefined)
                setSnackBarMessage("Un seul fichier autorisé.")
            else
                setSnackBarMessage("Fichier invalide.")

            Sentry.captureMessage("onDropRejected - " + files[0].errors[0].code,
                {
                    level: 'error',
                    extra: {
                        error: files[0].errors[0].code,
                        form: props.form.id,
                        filename: files[0].file.name,
                        size: files[0].file.size,
                        type: files[0].file.type,
                    }
                }
            )

            setIsSnackbarOpened(true);
            setIsDragOver(false);
        }
    });

    const download = () => {
        if (!props.document.uuid || !props.document.fileName)
            return;

        setIsLoading(true);

        downloadDocument(props.document.uuid).then(response => {
            let fileName: string;

            const link = document.createElement('a');
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );

            link.href = url;
            fileName = props.document.type + props.document.fileExtension || "";

            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();

            setIsLoading(false);
        });
    }

    useEffect(() => {
        if (props.downloadFlag !== undefined && props.downloadFlag !== 0)
            download();
    }, [props.downloadFlag])


    return (
        <>
        <Card
            sx={{
                position: 'relative',
                backgroundColor:
                    (props.document.fileNameOrigin || props.required) ?
                        `${status === undefined ? theme.palette.warning.main
                        : status === ControlStatus.ACCEPTED ? theme.palette.success.main
                        : status === ControlStatus.REFUSED ? theme.palette.error.main
                        : theme.palette.info.main}18`
                    : "none",
                opacity: disabled ? 0.5 : 1,
                borderStyle: !(props.document.fileNameOrigin || props.required) ? 'dashed' : "",
                borderColor: !(props.document.fileNameOrigin || props.required) ? theme.palette.text.secondary : "",
                borderWidth: !(props.document.fileNameOrigin || props.required) ? 1 : "",
                transition: "transform 400ms",
                transform: !(props.document.fileNameOrigin || props.required) && isDragOver ? "translateY(-6px)" : "",
                boxShadow: !(props.document.fileNameOrigin || props.required) && isDragOver ? "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : 0,
                ":hover": !(props.document.fileNameOrigin || props.required) ? {
                    boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                    transform: disabled || readOnly ? "none" : "translateY(-6px)"
                } : {}
            }}>
            {(props.document.fileNameOrigin || props.required) &&
            <Grid
                container
                justifyContent="flex-start"
                alignItems="center"
                spacing={1}
                sx={{
                    py: 1,
                    px: 2
                }}>
                <Grid
                    item
                    xs={12}>
                    <Typography
                        sx={{
                            my: 'auto'
                        }} >
                        {status === undefined ? "⏳ En attente du document"
                        : status === ControlStatus.ACCEPTED ? "✅ Document validé"
                        : status === ControlStatus.REFUSED ? "❌ Document refusé"
                        : "🔍 Validation en cours"}
                    </Typography>
                </Grid>

                {status === ControlStatus.REFUSED && comment &&
                <Grid
                    item
                    xs={12}>
                    <Typography
                        fontWeight={500}
                        sx={{
                            whiteSpace: "pre-line",
                            mt: 1,
                            p: 1,
                            background: plateformStyle.colors.grey.secondary + "A0",
                            borderLeft: 2,
                            borderColor: theme.palette.error.main,
                            borderRadius: 2
                        }}>
                        {comment}
                    </Typography>
                </Grid>}
            </Grid>}

            {!props.document.fileNameOrigin ?
            <Card
                sx={{
                    cursor: "pointer",
                    opacity: disabled ? 0.5 : 1,
                    backgroundColor: 'white',
                    borderStyle: (props.document.fileNameOrigin || props.required) ? 'dashed' : "",
                    borderColor: (props.document.fileNameOrigin || props.required) ? theme.palette.text.secondary : "",
                    borderWidth: (props.document.fileNameOrigin || props.required) ? 1 : "",
                    transition: "transform 400ms",
                    transform: (props.document.fileNameOrigin || props.required) && isDragOver ? "translateY(-6px)" : "",
                    boxShadow: (props.document.fileNameOrigin || props.required) && isDragOver ? "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px" : 0,
                    ":hover": (props.document.fileNameOrigin || props.required) ? {
                        boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                        transform: disabled || readOnly ? "none" : "translateY(-6px)"
                    } : {}
                }}
                onClick={() => open()}
                {...getRootProps({ className: 'dropzone' })} >
                <LinearProgress
                    value={100}
                    color='primary'
                    sx={{
                        display: isLoading ? 'block' : 'none'
                    }} />
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-start"
                    alignItems="center"
                    sx={{
                        p: 2
                    }}>
                    <input {...getInputProps()} />
                    {(props.title || props.description) && 
                    <>
                    <Grid
                        item
                        xs={12}>
                        {props.title &&
                        <Typography variant='h3'>
                            {props.title}{props.required && <Typography variant='h3' component='span' color='error' >*</Typography>}
                        </Typography>}
                            
                        {props.description &&
                        <Typography variant='caption'>
                            {props.description}
                        </Typography>}
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <Divider/>
                    </Grid>
                    </>}

                    <Grid
                        item
                        xs="auto">
                        <FolderEmptyIcon color={theme.palette.primary.main} height='30px' width='40px'/>
                    </Grid>
                    <Grid
                        item
                        xs>
                        <Typography
                            variant='body2'
                            fontWeight={700} >
                            Cliquer ou glisser votre fichier
                        </Typography>
                        <Typography
                            variant='caption'
                            fontWeight={500} >
                            PDF, JPEG, JPG, PNG, BMP (10Mo maximum)
                        </Typography>
                    </Grid>
                </Grid>
            </Card> :

            <Card
                sx={{
                    position: 'relative',
                    backgroundColor: 'white'
                }}>
                <LinearProgress
                    value={100}
                    color='primary'
                    sx={{
                        display: isLoading ? 'block' : 'none'
                    }} />

                {props.delete && status !== ControlStatus.ACCEPTED &&
                <Tooltip title="Supprimer" placement="top">
                    <IconButton
                        sx={{
                            position: "absolute",
                            top: 8,
                            right: 8,
                            color: theme.palette.text.primary,
                            p: 0,
                            transition: "transform 400ms",
                            ":hover":  {
                                transform: disabled || readOnly ? "none" : "translateY(-6px)"
                            }
                        }}
                        onClick={(e) => {
                            e.stopPropagation();

                            if (props.document.uuid) {
                                setIsLoading(true);

                                deleteDocument(props.document.uuid)
                                .then((response) => {
                                    if (props.delete !== undefined)
                                        props.delete(response)
                                    setIsLoading(false)
                                })
                                .catch((ex) => {
                                    setIsLoading(false);
                                    Sentry.captureException(ex);

                                })
                            }
                        }}>
                        <CloseIcon color={theme.palette.text.primary} />
                    </IconButton>
                </Tooltip>}

                <Grid
                    container
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{
                        p: 2
                    }}>
                    {(props.title || props.description) &&
                    <Grid
                        item
                        xs={12}>
                        {props.title &&
                        <Typography variant='h3'>
                            {props.title}{props.required && <Typography variant='h3' component='span' color='error' >*</Typography>}
                        </Typography>}
                            
                        {props.description &&
                        <Typography variant='caption'>
                            {props.description}
                        </Typography>}
                    </Grid>}

                    {props.emoji &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: "flex",
                            alignItems: "center"
                        }}>
                        <Typography variant='h3' fontSize="1.4rem" sx={{mr: 1}}>
                            {props.emoji}
                        </Typography>
                        <Typography
                            noWrap 
                            variant='body1'
                            fontWeight={500}
                            sx={{
                                maxWidth: {
                                    lg: "300px",
                                    md: "350px",
                                    sm: "450px",
                                    xs: "225px",
                                }
                            }}>
                            {props.document.fileNameOrigin}
                        </Typography>
                    </Grid>}

                    {/*  -  <Chip icon={<FaceIcon />} label={props.form.contract?.person.lastname} /> */}

                    {!props.disabled &&
                    <>
                    <Grid
                        item
                        xs={12}>
                        <Divider />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent={{
                                md: "center",
                                sm: "left"
                            }}>
                            {status !== ControlStatus.ACCEPTED && !props.readOnly && !props.disabled &&
                            <Grid
                                item
                                xs='auto'>
                                <Button
                                    sx={{
                                        color: theme.palette.text.primary,
                                        px: 1,
                                        py: 0.5,
                                        transition: "transform 400ms",
                                        ":hover":  {
                                            transform: disabled || readOnly ? "none" : "translateY(-6px)"
                                        }
                                    }}
                                    onClick={() => open()}
                                    startIcon={<UploadIcon color={theme.palette.text.primary} />}>
                                    Modifier
                                </Button>
                            </Grid>}

                            {!props.disabled &&
                            <Grid
                                item
                                xs='auto'>
                                <Button
                                    sx={{
                                        color: theme.palette.text.primary,
                                        px: 1,
                                        py: 0.5,
                                        transition: "transform 400ms",
                                        ":hover":  {
                                            transform: disabled || readOnly ? "none" : "translateY(-6px)"
                                        }
                                    }}
                                    onClick={download}
                                    startIcon={<DownloadIcon color={theme.palette.text.primary} />}>
                                    Télécharger
                                </Button>
                            </Grid>}

                        </Grid>
                    </Grid>
                    </>}
                </Grid>
            </Card>}
        </Card>

        <Snackbar
            sx={{
                maxWidth: '1200px',
                width: {
                    sm: '100%'
                },
                px: {
                    sm: 2
                },
                py: 'auto'
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnackbarOpened}
            autoHideDuration={5000}
            onClose={handleSnackbarClose} >
            <Alert
                sx={{
                    mx: {
                        sm: 2
                    },
                    my: 'auto',
                }}
                severity='error'
                iconMapping={{
                    success: <CheckFullIcon bgColor='white' color={theme.palette.success.main} />,
                    error: <CloseFullIcon bgColor='white' color={theme.palette.error.main} />,
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}>
                        <MUICloseIcon fontSize="inherit" fontWeight={700} />
                    </IconButton>
                }>
                <Typography fontWeight={500}>{snackBarMessage}</Typography>
            </Alert>
        </Snackbar>
        </>
    )
}

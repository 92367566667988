import { defaultIconHeaderSize, IIcon } from "./settings"


export const AdressIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={props.bgColor}/>
        <mask id="mask0_258_4444" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <path d="M20 1.9868e-06C31.0457 1.50397e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 2.46962e-06 20 1.9868e-06Z" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_258_4444)">
        <path d="M1.43182 17.4427L-10.0964 28.6753C-10.8029 29.3637 -11.1245 30.3561 -10.956 31.3281L-9.22899 41.2917C-8.97886 42.7348 -7.72044 43.7844 -6.25589 43.7714L50.2641 43.2727C52.3997 43.2539 53.8278 41.0671 52.9866 39.1041L38.7188 5.81264C37.7281 3.50115 34.5003 3.37014 33.3256 5.59373L22.2668 26.5264C21.3813 28.2027 19.1794 28.6425 17.7178 27.4351L5.42359 17.279C4.24633 16.3065 2.5255 16.3771 1.43182 17.4427Z" fill="url(#paint0_linear_258_4444)"/>
        </g>
        <g filter="url(#filter0_i_258_4444)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.245 38.9336C24.4461 33.631 31.195 21.9085 31.195 17.3278C31.195 11.323 26.0026 6.45514 19.5975 6.45514C13.1924 6.45514 8 11.323 8 17.3278C8 21.9085 14.7489 33.631 17.95 38.9336C18.7077 40.1887 20.4873 40.1887 21.245 38.9336ZM19.5975 23.1266C21.9994 23.1266 23.9466 21.1794 23.9466 18.7775C23.9466 16.3756 21.9994 14.4284 19.5975 14.4284C17.1956 14.4284 15.2484 16.3756 15.2484 18.7775C15.2484 21.1794 17.1956 23.1266 19.5975 23.1266Z" fill={props.color}/>
        </g>
        <defs>
        <filter id="filter0_i_258_4444" x="8" y="6.45514" width="23.1951" height="33.4198" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood flood-opacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.920833 0 0 0 0 0.502545 0 0 0 0 0.126615 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_258_4444"/>
        </filter>
        <linearGradient id="paint0_linear_258_4444" x1="22.9804" y1="4.91537" x2="22.9804" y2="43.7939" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

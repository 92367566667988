import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { CheckFullIcon } from "../Icon/CheckFullIcon";


interface Props {
    plateformStyle: any;
    isOpened: boolean;
    title: string | JSX.Element;
    description?: string | JSX.Element;
    handleContinue: () => void;
}


export const DialogSuccess = (props: Props) => {

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={props.isOpened}
            sx={{
                textAlign: 'center',
                borderRadius: 40
            }} >
            <Grid
                container
                spacing={2}
                sx={{
                    p: 2
                }}>
                <Grid
                    item
                    xs={12} >
                    <CheckFullIcon
                        color='white'
                        bgColor={props.plateformStyle.colors.green.main}
                        width='60px'
                        height='60px'/>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Typography variant="h2">{props.title}</Typography>
                </Grid>

                {props.description &&
                <Grid
                    item
                    xs={12} >
                    <Typography variant="h3">{props.description}</Typography>
                </Grid>}
            </Grid>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Grid
                    container
                    justifyContent="center"
                    spacing={2} >
                    <Grid
                        item
                        sm={6}
                        xs={12} >
                        <Button
                            fullWidth
                            autoFocus
                            variant="contained"
                            color="success"
                            onClick={() => props.handleContinue()}
                            sx={{
                                color: 'white'
                            }} >
                            Continuer
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

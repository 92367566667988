import { ISegment, IPageSegment } from "../model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getSegments = (page: number, pageSize: number, search?: string) : Promise<IPageSegment> => {

    let url: string = `${apiUrl}/segment?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getSegment = (id: number) : Promise<ISegment> => {

    return fetch(`${apiUrl}/segment/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const createSegment = (segment: ISegment) : Promise<ISegment> => {

    return fetch(`${apiUrl}/segment`,
    {
        method: "POST",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(segment)

    }).then(_ => _.json()) 
}

export const modifySegment = (segment: ISegment) : Promise<ISegment> => {

    return fetch(`${apiUrl}/segment`,
    {
        method: "PUT",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(segment)

    }).then(_ => _.json()) 
}

import { IAdress } from "../model/Adress.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const createAdress = (adress: IAdress) : Promise<IAdress> => {

    return fetch(`${apiUrl}/adress`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(adress)

    }).then(_ => _.json()) 
}


export const modifyAdress = (adress: IAdress) : Promise<IAdress> => {

    return fetch(`${apiUrl}/adress`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(adress)

    }).then(_ => _.json()) 
}


export const deleteAdress = (id: number) : Promise<any> => {

    return fetch(`${apiUrl}/adress/${id}`,
    {
        method: "DELETE",

    }).then(_ => _.json()) 
}

import React, {Dispatch, SetStateAction, useCallback, useContext} from 'react';
import {IWarrantiesAcronyms, IWarrantiesSection} from "../../model/Waranties.model";

interface WarrantiesContextContent {
  warranties: IWarrantiesSection[];
  setWarranties: (warranties: IWarrantiesSection[]) => void;
  warrantiesAcronyms: IWarrantiesAcronyms[];
  setWarrantiesAcronyms: (warrantiesAcronyms: IWarrantiesAcronyms[]) => void;
}

const WarantiesContext = React.createContext<WarrantiesContextContent | null>(null);

export const useWarantiesContext = (): [
    IWarrantiesSection[],
  (warranties: IWarrantiesSection[]) => void,
    IWarrantiesAcronyms[],
  (warrantiesAcronyms: IWarrantiesAcronyms[]) => void,
] => {
  const context = useContext(WarantiesContext);

  if (context === null) {
    throw new Error('useDossierContext called outside Context.');
  }

  const setter = context.setWarranties;
  const setWarrantiesNonNull = useCallback(
      (warranties: IWarrantiesSection[]) => {
        setter(warranties);
      },
      [setter],
  );
  return [context.warranties,  setWarrantiesNonNull, context.warrantiesAcronyms, context.setWarrantiesAcronyms];
};

const WarrantiesProvider = WarantiesContext.Provider;

export default WarrantiesProvider;

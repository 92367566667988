import { useEffect, useState } from "react";

import * as Sentry from '@sentry/react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";

import { FieldCPAM } from "../Field/FieldCPAM";
import { FieldLabel } from "../Field/FieldLabel";
import { formatString } from "../Field/utils";
import { pattern as patternBIC, separator as separatorBIC } from '../Field/TextFieldBIC';
import { defaultParamsIBAN as paramsIBAN, separator as separatorIBAN } from '../Field/TextFieldIBAN';
import { CardInfo } from "./CardInfo";

import { IContractBilling } from "../../model/ContractBilling.model";
import { ControlStatus, IControle, IControleDocument, controlStatusParam } from "../../model/ControleDocument.model";
import { IPerson } from "../../model/Person.model";

import { modifyPerson } from "../../api/Person.api";

import { enumFormDocumentCriteres,
         enumFormDocumentTypeAttestationCPAM,
         enumFormDocumentTypeRIB,
         enumFormDocumentTypes,
         messageFieldRequired } from "../../utils";
         import getCpamFromCP from "../../utils/cpam";
import { colors } from "../../static/themes/gsmc/colors";


interface Props {
    plateformStyle: any;
    control: IControle;
    document: IControleDocument;
    subscriber: IPerson;
    billing: IContractBilling;
    isCpamRequired: boolean;
    isControlDone: boolean;
    handleUpdateSubscriber: (person: IPerson) => void;
    handleValidate: () => void;
    handleRefuse: (comment: string) => void;
}


export const CardValidateDocument = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const [refused, setRefused] = useState<boolean>(false);
    const [comment, setComment] = useState<string>("");
    const [error, setError] = useState<string>();
    const [errorCpam, setErrorCpam] = useState<string>();

    const handleChangeComment = (t: string) => {
        setComment(t);
        setError(undefined);
    }

    const handleRefuse = () => {
        if (comment.length < 10) {
            setError("Champ obligatoire (10 caractères minimums)")
            return;
        }

        props.handleRefuse(comment);
    }

    const handleValidate = () => {
        if (props.isCpamRequired && (formik.values.cpamId === undefined || formik.values.cpamId === null)) {
            setErrorCpam(messageFieldRequired);
            return;
        }

        props.handleValidate();
    }

    useEffect(() => {
        setComment("");
        setRefused(false);
        setError(undefined);
    }, [props.document])

    const proposedCpamResult = props.subscriber.adress?.postalCode ? getCpamFromCP(props.subscriber.adress?.postalCode) : []
    const proposedCpam = proposedCpamResult && proposedCpamResult.length === 1 ? proposedCpamResult : undefined
    const statusParam = controlStatusParam.find(e => e.value === props.document.status) || controlStatusParam[0];

    const initialValues = {
        cpamId: props.subscriber?.cpamId || (proposedCpam && proposedCpam[0].cpamId)
    }

    const validationSchema = yup.object({
        cpamId: yup.string().nullable()
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: IPerson = {...props.subscriber};
            toSend.cpamId = values.cpamId;

            modifyPerson(toSend).then((response: IPerson) => {
                props.handleUpdateSubscriber(response);
            })
            .catch(exception => {
                Sentry.captureException(exception);
            });
        }
    })

    useEffect(() => {
        if (props.document.type === enumFormDocumentTypeAttestationCPAM) {
            setErrorCpam(undefined);
            formik.handleSubmit();
        }
    }, [formik.values.cpamId])


    return (
        <Card
            sx={{
                p: 2
            }}>
            <Grid
                container
                justifyContent='flex-end'
                spacing={2}>
                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h3'
                        color={plateformStyle.colors.black.main} >
                        {enumFormDocumentTypes.find(_ => _.value === props.document.type)?.label}
                    </Typography>
                </Grid>

                {(props.document.status === ControlStatus.ACCEPTED ||
                  props.document.status === ControlStatus.REFUSED ||
                  props.control.status === ControlStatus.CLOSED) &&
                <>
                <Grid
                    item
                    xs={12}>
                    <Typography fontWeight={500}>
                        <Chip
                            label={props.document.status === ControlStatus.ACCEPTED ? "Accepté"
                                : props.document.status === ControlStatus.REFUSED ? "Refusé"
                                : "Non vérifié"}
                            sx={{
                                mr: 1,
                                color: 'white',
                                backgroundColor: props.document.status === ControlStatus.ACCEPTED ? colors.green.main
                                : props.document.status === ControlStatus.REFUSED ? colors.red.main
                                : colors.black.secondary,
                                fontSize: 14,
                                fontWeight: 700
                            }} />
                        {(props.document.status === ControlStatus.ACCEPTED || props.document.status === ControlStatus.REFUSED) &&
                        <>
                        {props.document.user !== undefined && props.document.user !== null &&
                        <>par <b>@{props.document.user.username}</b>&nbsp;</>}

                        {props.document.techDateControl !== undefined && props.document.techDateControl !== null &&
                        <>
                        le {new Date(props.document.techDateControl).getDate().toString().padStart(2, '0') + "/" + (new Date(props.document.techDateControl).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(props.document.techDateControl).getFullYear()}
                        &nbsp;à {new Date(props.document.techDateControl).getHours().toString() + "h" + (new Date(props.document.techDateControl).getMinutes() + 1).toString().padStart(2, '0')}
                        </>}
                        </>}
                    </Typography>

                    {props.document.comment &&
                    <Typography
                        fontWeight={500}
                        sx={{
                            whiteSpace: "pre-line",
                            mt: 1,
                            p: 1,
                            background: colors.grey.main + "40",
                            borderLeft: 2,
                            borderColor: colors.red.main,
                            borderRadius: 2
                        }}>
                        {props.document.comment}
                    </Typography>}
                </Grid>
                <Grid
                    item
                    xs={12}>
                        <Divider/>
                </Grid>
                </>}

                {props.document.type === enumFormDocumentTypeAttestationCPAM &&
                <>
                <Grid
                    item
                    xs={12} >
                    <FormLabel error={Boolean(errorCpam)}>
                        <FieldLabel
                            isRequired={props.isCpamRequired}
                            label="Numéro de caisse du souscripteur :" />
                    </FormLabel>

                    <FieldCPAM
                        disabled={props.isControlDone}
                        placeholder="Ex : 491"
                        value={formik.values.cpamId}
                        onChange={(_) => _ !== null ?  formik.setFieldValue('cpamId', _.value) : formik.setFieldValue('cpamId', undefined)} // check if null for clear option
                        onBlur={(_) => formik.setFieldTouched('cpamId')}
                        error={Boolean(errorCpam)}
                        helperText={errorCpam} />
                </Grid>

                {props.document.status === ControlStatus.PENDING &&
                 (props.control.status == ControlStatus.PENDING || props.control.status == ControlStatus.IN_PROGRESS) &&
                <Grid
                    item
                    xs={12} >
                    <CardInfo
                        plateformStyle={plateformStyle}>
                        Assurez-vous qu'il s'agisse bien de l'attestation CPAM du souscripteur.
                    </CardInfo>
                </Grid>}

                <Grid
                    item
                    xs={12} >
                    <Divider />
                </Grid>
                </>}

                <Grid
                    item
                    xs={12} >
                    <ul
                        style={{
                            paddingInlineStart: "25px",
                            margin: 0
                        }}>
                        {enumFormDocumentCriteres.find(_ => _.selector === props.document.type)?.criteres.map(crit =>
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                {crit}
                            </Typography>
                        </li>)}

                        {props.document.type === enumFormDocumentTypeRIB &&
                        <>
                        {props.billing.iban !== undefined && props.billing.iban !== null &&
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                Vérifier l'IBAN :<br/>
                                <Typography
                                    variant='body2'
                                    component='span'
                                    color={plateformStyle.colors.blue.main}
                                    fontWeight={700}
                                    sx={{
                                        borderRadius: 4,
                                        px: 1,
                                        backgroundColor: plateformStyle.colors.blue.secondary
                                    }}>
                                    {formatString(props.billing.iban, "", { toUpperCase: true, pattern: paramsIBAN.pattern, separator: separatorIBAN }).value}
                                </Typography>
                            </Typography>
                        </li>}

                        {props.billing.bic !== undefined && props.billing.bic !== null &&
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                Vérifier le BIC :<br/>
                                <Typography
                                    variant='body2'
                                    component='span'
                                    color={plateformStyle.colors.blue.main}
                                    fontWeight={700}
                                    sx={{
                                        borderRadius: 4,
                                        px: 1,
                                        backgroundColor: plateformStyle.colors.blue.secondary
                                    }}>
                                    {formatString(props.billing.bic, "", { toUpperCase: true, pattern: patternBIC, separator: separatorBIC }).value}
                                </Typography>
                            </Typography>
                        </li>}

                        {props.billing.bankName !== undefined && props.billing.bankName !== null &&
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                Vérifier le nom de la banque :<br/>
                                <Typography
                                    variant='body2'
                                    component='span'
                                    color={plateformStyle.colors.blue.main}
                                    fontWeight={700}
                                    sx={{
                                        borderRadius: 4,
                                        px: 1,
                                        backgroundColor: plateformStyle.colors.blue.secondary
                                    }}>
                                    {props.billing.bankName}
                                </Typography>
                            </Typography>
                        </li>}

                        {props.billing.ownerName !== undefined && props.billing.ownerName !== null &&
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                Vérifier le nom du titulaire du compte :<br/>
                                <Typography
                                    variant='body2'
                                    component='span'
                                    color={plateformStyle.colors.blue.main}
                                    fontWeight={700}
                                    sx={{
                                        borderRadius: 4,
                                        px: 1,
                                        backgroundColor: plateformStyle.colors.blue.secondary
                                    }}>
                                    {props.billing.ownerName}
                                </Typography>
                            </Typography>
                        </li>}
                        </>}

                        {props.document.type === enumFormDocumentTypeAttestationCPAM &&
                        <>
                        {props.subscriber.adress?.postalCode !== undefined &&
                        <li
                            style={{
                                color: plateformStyle.colors.blue.main
                            }}>
                            <Typography
                                variant='body2'
                                color={plateformStyle.colors.black.secondary}
                                fontWeight={500}>
                                Vérifier que la caisse correspond au code postal :
                                <Typography
                                    variant='body2'
                                    component='span'
                                    color={plateformStyle.colors.blue.main}
                                    fontWeight={700}
                                    sx={{
                                        borderRadius: 4,
                                        px: 1,
                                        backgroundColor: plateformStyle.colors.blue.secondary
                                    }}>
                                    {props.subscriber.adress?.postalCode}
                                </Typography>
                            </Typography>
                        </li>}
                        </>}
                    </ul>
                </Grid>

                {props.document.status === ControlStatus.PENDING &&
                 (props.control.status == ControlStatus.PENDING || props.control.status == ControlStatus.IN_PROGRESS) &&
                <>
                <Grid
                    item
                    xs={12} >
                    <Divider />
                </Grid>

                {!refused ?
                <>
                <Grid
                    item>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={() => setRefused(true)}
                        sx={{
                            px: 3,
                            py: 1,
                            backgroundColor: plateformStyle.colors.black.main,
                            color: "white",
                            ":hover": {
                                backgroundColor: "black"
                            }}}>
                        Refuser
                    </Button>
                </Grid>

                <Grid
                    item>
                    <Button
                        fullWidth
                        variant="contained"
                        color="success"
                        onClick={() => handleValidate()}
                        sx={{
                            px: 3,
                            py: 1,
                            color: "white"
                        }}>
                        Valider
                    </Button>
                </Grid>
                </>

                :
                <>
                <Grid
                    item
                    xs={12}>
                    <FormLabel error={Boolean(error)}>
                        <FieldLabel
                            isRequired={props.isCpamRequired}
                            label="Indiquez la ou les raisons de ce refus :" />
                    </FormLabel>

                    <TextField
                        fullWidth
                        multiline
                        minRows={2}
                        maxRows={10}
                        onChange={(e) => handleChangeComment(e.target.value)}
                        error={Boolean(error)}
                        helperText={error} />
                </Grid>

                <Grid
                    item
                    md="auto"
                    xs={12}>
                    <Button
                        fullWidth
                        onClick={() => setRefused(false)}
                        sx={{
                            px: 3,
                            py: 1,
                            color: plateformStyle.colors.black.main
                        }}>
                        Annuler
                    </Button>
                </Grid>

                <Grid
                    item
                    md="auto"
                    xs={12}>
                    <Button
                        fullWidth
                        onClick={() => handleRefuse()}
                        sx={{
                            px: 3,
                            py: 1,
                            backgroundColor: plateformStyle.colors.black.main,
                            color: "white",
                            ":hover": {
                                backgroundColor: "black"
                            }
                        }}>
                        Valider ce refus
                    </Button>
                </Grid>
                </>}
                </>}
            </Grid>
        </Card>
    )
}

import { PublicClientApplication } from "@azure/msal-browser";
import { ControlStatus, IControle, IControleDocument, IControleDocumentRequestPOST, IControlMEG, IPageControle } from "../model/ControleDocument.model"
import AuthService from "../services/AuthService";
import { msalConfig } from "../utils/authConfig";

const apiUrl = process.env.REACT_APP_API_URL + '/fileservice'

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const authService = new AuthService();


export const getControlBO = (page: number, pageSize: number, search?: string, status?: ControlStatus[]) : Promise<IPageControle> => {
    let url: string = `${apiUrl}/control?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    if (status && status.length > 0)
        status.map(_ => url += `&status=${_}`)

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(url,
        {
            method: "GET",
            headers: {
                "Authorization": accessTokenResponse.idToken
            }
        })
        .then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
    });
}

export const getControlByIdBO = (id: Number) : Promise<IControle> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/control/${id}`,
        {
            method: "GET",
            headers: {
                "Authorization": accessTokenResponse.idToken
            }
        })
        .then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
    });
}


export const getControl = (page: number, pageSize: number, search?: string, status?: ControlStatus[]) : Promise<IPageControle> => {
    let url: string = `${apiUrl}/control?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    if (status && status.length > 0)
        status.map(_ => url += `&status=${_}`)

    return fetch(url,
    {
        method: "GET",
        headers: {
            "Authorization": authService.getToken() || ""
        }
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}

export const getControlById = (id: Number) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}`,
    {
        method: "GET",
        headers: {
            "Authorization": authService.getToken() || ""
        }
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const getControlByFormUuid = (uuid: string) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/form/${uuid}`,
    {
        method: "GET"
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const megControl = (id: number, payload: IControlMEG) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}/meg`,
    {
        method: "POST",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        },
        body: JSON.stringify(payload)
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const updateControl = (id: number, payload: IControle) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        },
        body: JSON.stringify(payload)
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const updateControlFraud = (id: number, payload: IControle) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}/fraud`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        },
        body: JSON.stringify(payload)
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const closeControl = (id: number, payload: IControle) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}/close`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        },
        body: JSON.stringify(payload)
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const reactivateControl = (id: number) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}/reactivate`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        }
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const deactivateControl = (id: number) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/${id}/deactivate`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        }
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}


export const updateControlDocument = (id: number, payload: IControleDocumentRequestPOST) : Promise<IControle> => {

    return fetch(`${apiUrl}/control/document/${id}`,
    {
        method: "PUT",
        headers: {
            "content-type": "application/json",
            "Authorization": authService.getToken() || ""
        },
        body: JSON.stringify(payload)
    })
    .then(_ => {
        if (_.status / 100 >= 3) {
            throw new Error(_.status.toString())
        }
        return _.json()
    })
}

import { defaultIconSize, IIcon } from "./settings"


export const ChevronLeftIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_203_556)">
        <path d="M18 7L9.84083 14.2526C9.39332 14.6504 9.39332 15.3496 9.84083 15.7474L18 23" stroke={props.color} strokeWidth="2" strokeLinecap="round"/>
        <circle cx="15" cy="15" r="14" stroke={props.color} strokeWidth="2"/>
        </g>
        </svg>
    )
}

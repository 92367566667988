import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "../utils/authConfig";
import { IContractHenner } from "../model/ControleDocument.model";
import { IContract } from "../model/Contract.model";

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel/subscription'


export const postSubscription = (uuid: string) : Promise<IContract> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/form/${uuid}`,
        {
            method: "POST",
            headers: {
                "Authorization": accessTokenResponse.idToken
            }
        })
        .then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
    });
}

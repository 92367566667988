import React, {useEffect, useState} from 'react';

import {FormikErrors, useFormik} from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from "@mui/material/Checkbox";
import Divider from '@mui/material/Divider';
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from '@mui/material/Grid';
import FormLabel from "@mui/material/FormLabel";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WebhookIcon from '@mui/icons-material/Webhook';

import {InfoIcon} from '../Icon/InfoIcon';
import {IPlateformeSettingsWebhook} from '../../model/PlateformeSettings.model';
import {colors} from '../../static/themes/gsmc/colors';
import {updatePlateformeSettingsWebhook} from "../../api/PlateformeSettings.api";
import {FieldLabel} from "../Field/FieldLabel";
import {messageFieldRequired} from "../../utils";

interface Props {
    settingsWebhook: IPlateformeSettingsWebhook;
    send: (settingsWebhook: IPlateformeSettingsWebhook) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsWebhook = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        enabled: yup.boolean().required(messageFieldRequired),
        uri: yup.string().nullable()
    })

    const formik = useFormik({
        initialValues: {
            enabled: props.settingsWebhook.enabled,
            uri: props.settingsWebhook.uri,
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};
            if (values.enabled && !values.uri) {
                errors.uri = "Champ obligatoire lorsque le Webhook est activé ";
            }
            return errors;
        },
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsWebhook = {
                id: props.settingsWebhook.id,
                enabled: values.enabled,
                uri: values.uri,

            }

            updatePlateformeSettingsWebhook(toSend)
            .then((response: IPlateformeSettingsWebhook) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres du webhook.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <WebhookIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Webhook
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                   Configurer le webhook de la plateforme.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Paramètres
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les webhooks du partenaire seront appelés lors du lead et de la souscription.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.enabled}
                                        onChange={(e, value) => formik.setFieldValue("enabled", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Webhook Activé
                                    </Typography>
                                } />
                        </Grid>
                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.uri && Boolean(formik.errors.uri)} >
                                <FieldLabel
                                    label="URL du webhook"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.enabled)
                                    }/>
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                URL du webhook utilisée pour informer le partenaire de l'avancée de la souscription.
                            </Typography>
                            <TextField
                                fullWidth
                                id="uri"
                                name="uri"
                                value={formik.values.uri}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.uri && Boolean(formik.errors.uri)}
                                helperText={formik.touched.uri && formik.errors.uri}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import { useEffect, useState } from 'react';

import Alert, { AlertColor } from '@mui/material/Alert';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import { IconButton, Slide } from '@mui/material';

import { default as MUICloseIcon} from '@mui/icons-material/Close';

import { ChevronLeftIcon } from '../Icon/ChevronLeftIcon';

import { enumContactMethodRedirect, enumContactMethodForm} from '../../utils/enums';
    import PhoneIcon from '@mui/icons-material/Phone';

import { IPlateformeSettings, IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';

import { CheckFullIcon } from '../Icon/CheckFullIcon';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { DialogSupport } from '../Dialog/DialogSupport';
import { IForm } from '../../model/Form.model';
import { IPerson } from '../../model/Person.model';
import { ICallback } from '../../model/Callback.model';


interface Props {
    plateformStyle: any;
    plateformSettings?: IPlateformeSettings;
    form?: IForm;
    subscriber?: IPerson;
    isReturnEnabled: Boolean;
    goToPrevious: () => void;
}


const apiUri = process.env.REACT_APP_API_URL


export const MainHeader = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const settingsContact: IPlateformeSettingsContact | undefined = props.plateformSettings?.settingsContact;

    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
    const [logoPlateformeCode, setlogoPlateformeCode] = useState<string>(props.plateformStyle.plateform);

    useEffect(() => {
        setlogoPlateformeCode(props.plateformStyle.plateform)
    }, [props.plateformStyle.plateform])

    const handleAction = () => {
        if (!settingsContact) return;

        if (settingsContact.supportMethod === enumContactMethodRedirect &&
            settingsContact.supportUri && settingsContact.supportUri !== null) {
            window.open(settingsContact.supportUri, '_blank');
            return;
        }

        if (settingsContact.supportMethod === enumContactMethodForm) {
            setIsDialogOpened(true);
            return;
        }

        return;
    }

    const handleTelTo = () => {
        if (!settingsContact) return;

        const link = document.createElement('a');

        link.href = 'tel:' + settingsContact.supportPhone;
        link.click();

        document.body.appendChild(link);
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    return (
        <Grid
            container
            sx={{
                background: "white",
                p: 1,
                borderBottom: 1,
                borderColor: plateformStyle.colors.grey.main 
            }} >
            <Grid
                item
                md={2.5}
                xs="auto"
                sx={{
                    pl: {
                        md: 2,
                        xs: 0
                    },
                    my: 'auto'
                }} >
                {props.isReturnEnabled &&
                <>
                <Button
                    sx={{
                        display: {
                            md: "flex",
                            xs: "none"
                        },
                        py: 1,
                        color: plateformStyle.colors.black.main
                    }}
                    startIcon={<ChevronLeftIcon color={plateformStyle.colors.black.main } /> }
                    onClick={props.goToPrevious} >
                    Retour
                </Button>
                <IconButton
                    sx={{
                        display: {
                            md: "none",
                            xs: "flex"
                        },
                        p: 0
                    }}
                    onClick={props.goToPrevious}>
                    <ChevronLeftIcon color={plateformStyle.colors.black.main } />
                </IconButton>
                </>}
            </Grid>

            <Grid
                item
                xs
                sx={{
                    textAlign: 'center'
                }} >
                <Box
                    sx={{
                        height: {
                            md: '45px',
                            xs: '40px'
                        }
                    }} >
                    <img src={`${apiUri}/backoffice/plateforme/code/${logoPlateformeCode}/logo`}
                        onError={() => setlogoPlateformeCode("GSMC")}
                        width="auto"
                        height="100%"/>
                </Box>
            </Grid>

            <Grid
                item
                md={2.5}
                xs="auto"
                sx={{
                    textAlign: "right",
                    pr: {
                        md: 2,
                        xs: 0
                    },
                    my: 'auto'
                }} >

                {settingsContact?.displaySupportCard &&
                <>
                {settingsContact?.displaySupportAction &&
                 settingsContact?.supportPhone && settingsContact?.supportPhone !== null &&
                <>
                <Button
                    id="bouton_etrerappele_header"
                    variant="contained"
                    sx={{
                        display: {
                            md: "inline-flex",
                            xs: "none"
                        },
                        py: 0.5,
                        backgroundColor: plateformStyle.colors.black.main,
                        color: "white",
                        ":hover": {
                            backgroundColor: "black",
                        }
                    }}
                    startIcon={<PhoneIcon sx={{
                        fontSize: "25px !important"
                    }} /> }
                    onClick={() => {
                        if (settingsContact?.displaySupportAction) {
                            handleAction()
                            return;
                        }

                        handleTelTo()
                        return;
                    }} >
                    {settingsContact?.displaySupportAction ? "Être rappelé" : settingsContact.supportPhone}
                </Button>
                <IconButton
                    id="bouton_etrerappele_header"
                    sx={{
                        display: {
                            md: "none",
                            xs: "inline-flex"
                        },
                        p: 0.5,
                        color: plateformStyle.colors.black.main,
                        border: 2,
                        borderColor: plateformStyle.colors.black.main
                    }}
                    onClick={() => {
                        if (settingsContact?.displaySupportAction) {
                            handleAction()
                            return;
                        }

                        handleTelTo()
                        return;
                    }}>
                    <PhoneIcon sx={{
                        fontSize: "25px !important"
                    }} />
                </IconButton>
                </>}

                {props.form &&
                 settingsContact &&
                 settingsContact.displaySupportAction &&
                 settingsContact.supportMethod === enumContactMethodForm &&
                <>
                <DialogSupport
                    isOpened={isDialogOpened}
                    plateformStyle={props.plateformStyle}
                    callback={{
                        form: props.form,
                        senderMail: props.subscriber?.email,
                        senderPhone: props.subscriber?.phoneMobile,
                        senderLastname: props.subscriber?.lastname,
                        senderFirstname: props.subscriber?.firstname,
                    } as ICallback}
                    handleClose={() => {
                        setIsDialogOpened(false);
                    }} />

                <Snackbar
                    sx={{
                        maxWidth: '1200px',
                        width: {
                            sm: '100%'
                        },
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    open={isSnackbarOpened}
                    autoHideDuration={5000}
                    onClose={handleSnackbarClose}
                    TransitionComponent={(_) => <Slide {..._} direction="down" />} >
                    <Alert
                        sx={{
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                        }}
                        severity={snackbarSeverity as AlertColor}
                        iconMapping={{
                            success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                            error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                        }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleSnackbarClose}>
                                <MUICloseIcon fontSize="inherit" fontWeight={700} />
                            </IconButton>
                        }>
                        <Typography fontWeight={500}>{snackbarMessage}</Typography>
                    </Alert>
                </Snackbar>
                </>}
                </>}
            </Grid>
        </Grid>
    )
}

import React from 'react';

import { TextField } from '@mui/material';

import { formatString } from './utils';


interface Props {
    id: string;
    label?: string | JSX.Element;
    value: string | undefined;
    onChange: (event: React.ChangeEvent, value: string, error: string) => void;
    onBlur?: (e: any) => void;
    disabled?: boolean;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
    fullWidth?: boolean;
    toUpperCase?: boolean;
    capitalize?: boolean;
    textLength?: number;
    charactersEnabled?: string;
    pattern?: number[];
    separator?: string;
}


export const TextFieldFormatted = (props: Props) => {

    const [oldValue, setOldValue] = React.useState(props.value === undefined ? "" : props.value);

    const onChange = (event: React.ChangeEvent) => {
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
        const value = target.value;
        let paramsText: { value: string, cursorPosition: number | null };

        paramsText = formatString(value,
            oldValue,
            {
                toUpperCase: props.toUpperCase,
                capitalize: props.capitalize,
                maxLength: props.textLength,
                cursorPosition: target.selectionStart,
                charactersEnabled: props.charactersEnabled,
                pattern: props.pattern,
                separator: props.separator
            });

        target.value = paramsText.value;

        if (paramsText.cursorPosition != null) {
            target.selectionStart = paramsText.cursorPosition;
            target.selectionEnd = paramsText.cursorPosition;
        }

        props.onChange(event, target.value, "");
        setOldValue(target.value);
    }


    return (
        <TextField
            label={props.label}
            fullWidth
            value={props.value}
            disabled={props.disabled}
            onChange={onChange}
            onBlur={props.onBlur}
            error={props.error}
            helperText={props.helperText} />
    )
}

import { IPromotion, IPromotionDTO, IPromotionPage, PromotionStatus, PromotionType } from "../model/Promotion.model";

import { msalInstance } from "./auth/User.api";


const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel/promotion'


export const getAll = (
    page: number,
    pageSize: number,
    search?: string,
    types?: PromotionType[],
    offers?: string[],
    plateformes?: number[],
    status?: PromotionStatus[]) : Promise<IPromotionPage> => {
    let uri: string = `${apiUrl}?page=${page}&pageSize=${pageSize}`;

    if (search) uri += `&search=${search}`
    if (types && types.length > 0) uri += `&types=${types.join(",")}`
    if (offers && offers.length > 0) uri += `&offers=${offers.join(",")}`
    if (plateformes && plateformes.length > 0) uri += `&plateformes=${plateformes.join(",")}`
    if (status && status.length > 0) uri += `&status=${status.join(",")}`

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(uri,
        {
            method: "GET",
            headers: { "Authorization": accessTokenResponse.idToken }
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw _.json()
        })
    })
}


export const getById = (id: number) : Promise<IPromotion> => {
    let uri: string = `${apiUrl}/${id}`;

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(uri,
        {
            method: "GET",
            headers: { "Authorization": accessTokenResponse.idToken }
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw _.json()
        })
    })
}


export const create = (payload: IPromotionDTO) : Promise<IPromotion> => {
    let uri: string = `${apiUrl}`;

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(uri,
        {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                "Authorization": accessTokenResponse.idToken
            },
            body: JSON.stringify(payload)
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw _.json()
        })
    })
}


export const update = (id: number, payload: IPromotionDTO) : Promise<IPromotion> => {
    let uri: string = `${apiUrl}/${id}`;

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(uri,
        {
            method: "PUT",
            headers: {
                "Content-Type": "application/json",
                "Authorization": accessTokenResponse.idToken
            },
            body: JSON.stringify(payload)
        })
        .then(_ => {
            if (_.status === 200) return _.json()
            else throw _.json()
        })
    })
}

import { defaultIconSize, IIcon } from "./settings"


export const ChevronUpIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="15" cy="15" r="14" transform="rotate(-90 15 15)" stroke={props.color} fill={props.bgColor} strokeWidth="2"/>
            <path d="M23 18L15.7474 9.84083C15.3496 9.39332 14.6504 9.39332 14.2526 9.84083L7 18" stroke={props.color} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

import { Outlet } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';
import Grid from "@mui/material/Grid";

import { theme as themeGSMC } from '../../static/themes/gsmc/theme'


export const Layout = () => {

    // Theming.
    const theme = themeGSMC;

    document.title = 'Mutuelle GSMC';

    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                alignItems="center"
                justifyContent="center"
                sx={{
                    minWidth: "100%",
                    minHeight: "100vh",
                    px: {
                        xs: 2,
                        sm: 0
                    }
                }}>
                <Grid
                    item
                    xs={12}
                    sm={10}
                    md={8}
                    lg={4}
                >
                    <Outlet />
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

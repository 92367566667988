import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import {HeaderStep} from './HeaderStep';

import {IForm} from '../../model/Form.model';
import {FormStep, FormStepId} from "../../utils";


interface Props {
    plateformStyle: any;
    form: IForm;
    steps: FormStep[];
    activeStep: number;
    activeInnerStep: number;
    goToStep: (index: number) => void;
}


export const Header = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const componentStyle: any = plateformStyle.components.Stepper;

    const getProgress = (isCompleted: boolean, isActive: boolean) => {
        if (isCompleted)
            return 100;
        if (isActive) {
            const nbInnerStep = props.steps[props.activeStep].innerStepsNumber;
            let progressStep: number = props.activeInnerStep / nbInnerStep * 100;
            return progressStep
        }
        else {
            return 0;
        }
    }

    const getIsGoToEnabled = (stepIndex: number) => {

        // No goTo on the last step.
        if (props.activeStep === props.steps.length - 1 && props.activeInnerStep === props.steps[3].innerStepsNumber - 1)
            return false;

        // No goTo if the step isn't completed.
        const currentStep: number = props.form.lastStepCompleted !== null && props.form.lastStepCompleted !== undefined ? props.form.lastStepCompleted + 1 : 0;

        if (stepIndex > currentStep)
            return false;

        return true;
    }


    return (
        <Grid
            container
            spacing={2}
            justifyContent="space-between"
            alignItems="center" >
            {props.steps.map((_, id) => (
                <>
                    {_.innerStepsNumber > 0 && <Grid
                        item
                        md={2}
                        xs >
                        <HeaderStep
                            key={`step_${id}`}
                            plateformStyle={plateformStyle}
                            label={_.label}
                            icon={_.icon}
                            isActive={_.id === FormStepId.OFFER ||
                                      (props.form.lastStepCompleted !== undefined && props.form.lastStepCompleted !== null && id <= props.form.lastStepCompleted + 1)}
                            isGoToEnabled={getIsGoToEnabled(id)}
                            goToStep={() => props.goToStep(id)} />
                    </Grid>}

                    {_.innerStepsNumber > 0 && id < props.steps.length - 1 &&
                    <Grid
                        item
                        xs >
                        <LinearProgress
                            color={componentStyle.progressBar.color}
                            variant="determinate"
                            value={getProgress(props.form.lastStepCompleted !== undefined && props.form.lastStepCompleted !== null && id <= props.form.lastStepCompleted, props.activeStep === id)} />
                    </Grid>}
                </>
            ))}
        </Grid>
    )
}

import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';
import SettingsIcon from '@mui/icons-material/Settings';
import TelegramIcon from '@mui/icons-material/Telegram';
import WarningIcon from '@mui/icons-material/Warning';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import CustomizedDialogs from '../../../components/Dialog/CustomizedDialog';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';

import { ICampaign, ICampaignDTO } from '../../../model/Campaign.model';
import { IPlateforme } from '../../../model';

import { getPlateformes } from '../../../api/Plateforme.api';
import { createCampaign, getCampaigns, modifyCampaign } from '../../../api/Campaign.api';

import { messageFieldRequired } from '../../../utils/messages';

import { colors } from '../../../static/themes/gsmc/colors';
import { CardInfo } from '../../../components/Card/CardInfo';
import { style } from '../../../static/themes/gsmc/style';
import { Collapse } from '@mui/material';


interface IDialogProps {
    open: boolean;
    campaign?: ICampaign;
    plateforms: IPlateforme[];
    handleClose: () => void;
    handleError: () => void;
}


const initialValues = {
    code: undefined as undefined | string,
    plateform: undefined as any,
    isActive: true as boolean
}

const appUrl = process.env.REACT_APP_URL

const DialogCampaign = (props: IDialogProps) => {

    useEffect(() => {
        formik.resetForm();

        if (props.campaign !== undefined) {
            const values = {
                plateform: props.campaign.plateforme?.code,
                code: props.campaign.code,
                isActive: props.campaign.isActive
            }
            formik.setValues(values);
        }
        else {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.campaign])

    const validationSchema = yup.object({
        code: yup.string().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (props.campaign) {
                const toSend: ICampaignDTO = {
                    id: props.campaign.id,
                    plateformeId: props.plateforms.find(_ => _.code === values.plateform)?.id,
                    code: props.campaign.code as string,
                    token: props.campaign.token,
                    isActive: values.isActive,
                }
                modifyCampaign(toSend).then((res: ICampaign) => {
                    props.handleClose();
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
            else {
                const toSend: ICampaignDTO = {
                    plateformeId: props.plateforms.find( _ => _.code === values.plateform)?.id,
                    code: values.code as string,
                    isActive: values.isActive,
                }
                createCampaign(toSend).then((res: ICampaign) => {
                    if (res.id)
                        props.handleClose();
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
        }
    })


    return (
        <CustomizedDialogs
            title="Campagne"
            open={props.open}
            handleClose={props.handleClose}>
            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                disabled={props.campaign !== undefined}
                                variant="outlined"
                                fullWidth
                                id="code"
                                name="code"
                                label={"Code*"}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <Select
                                variant="outlined"
                                fullWidth
                                id="plateform"
                                name="plateform"
                                label={"Plateforme*"}
                                value={formik.values.plateform}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.plateform && Boolean(formik.errors.plateform)}>
                                {props.plateforms.map(p => {
                                        return <MenuItem value={p.code}>{p.code}</MenuItem>
                                    })
                                }
                            </Select>
                            <FormHelperText error={formik.touched.plateform && Boolean(formik.errors.plateform)}> {formik.touched.plateform && <>{formik.errors.plateform}</>}</FormHelperText>
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <span><strong>Actif</strong></span>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus type="submit">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </CustomizedDialogs>
    )
}


export const CampaignList = () => {

    document.title = 'BO - Campagnes';

    const [campaign, setCampaign] = useState<ICampaign | undefined>();
    const [plateforms, setPlateforms] = useState<IPlateforme[]>([])
    const [campaigns, setCampaigns] = useState<ICampaign[] | undefined | null>(undefined);
    const [campaignsCount, setCampaignsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);
    const [isInfoDetailShown, setIsInfoDetailShown] = useState<boolean>(true);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleClickModify = (campaign: ICampaign) => {
        setCampaign(campaign);
        setOpen(true);
    }

    const handleClickSettings = (campaign: ICampaign) => {
        window.location.href = `/bo/campaign/${campaign.code}/settings`
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        getPlateformes(0, 10000, "").then(res => {
            setPlateforms(res.content);
        })
    }, [])

    useEffect(() => {
        getCampaigns(pageNumber, rowPerPage, search).then(response => {
            setCampaigns(response.content);
            setCampaignsCount(response.totalElements);
        })
            .catch((exception) => {
                setCampaigns(null);
                setCampaignsCount(0);
            });
    }, [pageNumber, rowPerPage])


    const forceRefresh = () => {
        getCampaigns(pageNumber, rowPerPage, search).then(response => {
            setCampaigns(response.content);
            setCampaignsCount(response.totalElements);
        })
            .catch((exception) => {
                setCampaigns(null);
                setCampaignsCount(0);
            });
        setOpen(false);
        setCampaign(undefined);
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])

    const copy = (value: string) => {
        navigator.clipboard.writeText(value);
    }

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <TelegramIcon />},
                {type: "LINK", label: "Campagne"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>

            <DialogCampaign
                open={open}
                campaign={campaign}
                plateforms={plateforms}
                handleClose={() => forceRefresh()}
                handleError={() => setIsSnackbarOpened(true)} />

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12} >
                    <CardInfo
                        plateformStyle={style}>
                        Comment construire l'URL du formulaire de ma campagne de rappel ?
                        <Button
                            variant="text"
                            sx={{
                                ml: 2,
                                height: 30
                            }}
                            onClick={() => setIsInfoDetailShown(!isInfoDetailShown)}>
                            <Typography fontSize={16}>{isInfoDetailShown ? "masquer" : "voir"}</Typography>
                        </Button>

                        <Collapse in={isInfoDetailShown}>
                            URL :&nbsp;
                            <Typography
                                variant='body2'
                                component='span'
                                color={colors.blue.main}
                                sx={{
                                    fontFamily: "monospace"
                                }}>
                                {appUrl}/contact?campaign=TOKEN&offer=CODE_OFFRE
                            </Typography>
                            <br/>
                            &nbsp;&nbsp;•&nbsp;
                            <Typography
                                variant='body2'
                                component='span'
                                color={colors.blue.main}
                                sx={{
                                    fontFamily: "monospace"
                                }}>
                                TOKEN
                            </Typography>
                            &nbsp;: Token de la campagne visée
                            <br/>
                            &nbsp;&nbsp;•&nbsp;
                            <Typography
                                variant='body2'
                                component='span'
                                color={colors.blue.main}
                                sx={{
                                    fontFamily: "monospace"
                                }}>
                                CODE_OFFRE
                            </Typography>
                            &nbsp;(Optionnel) : Code de l'offre à laquelle correspond le formulaire (312 : Sénior / 512 : Génération / 539 : TNS)
                            <br/>
                        </Collapse>
                    </CardInfo>
                </Grid>
                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                        <IconButton
                            onClick={() => setOpen(true)}>
                            <AddCircleIcon color="primary"/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Plateforme</TableCell>
                                        <TableCell align="left">Code</TableCell>
                                        <TableCell align="left">Token / URL</TableCell>
                                        <TableCell align="center">Actif</TableCell>
                                        <TableCell
                                            key={'settings'}
                                            sx={{
                                                minWidth: '80px'
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {campaigns === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des campagnes en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : campaigns === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des campagnes
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                            : campaigns.length === 0 ?
                                                <TableRow>
                                                    <TableCell
                                                        colSpan={8}
                                                        sx={{
                                                            height: '150px',
                                                            textAlign: 'center'
                                                        }}>
                                                        <InboxIcon
                                                            fontSize="large"
                                                            sx={{
                                                                color: colors.black.secondary
                                                            }} />
                                                        <Typography
                                                            variant='subtitle1'>
                                                            Aucune campagne
                                                        </Typography>
                                                    </TableCell>
                                                </TableRow>

                                                : campaigns.map((_, id) => {
                                                    return (
                                                        <TableRow key={id}>
                                                            <TableCell>{_.plateforme?.code}</TableCell>
                                                            <TableCell>{_.code}</TableCell>
                                                            <TableCell>
                                                                <Stack direction="row" alignItems="center" gap={1}
                                                                    sx={{
                                                                        display: "block",
                                                                        maxWidth: {
                                                                            md: "700px",
                                                                            sm: "300px"
                                                                        },
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}>
                                                                    <IconButton
                                                                        onClick={() => copy(_.token || "")}>
                                                                        <ContentCopyIcon color="primary"/>
                                                                    </IconButton>
                                                                    {_.token}
                                                                </Stack>
                                                                <Stack direction="row" alignItems="center" gap={1}
                                                                    sx={{
                                                                        display: "block",
                                                                        maxWidth: {
                                                                            md: "700px",
                                                                            sm: "300px"
                                                                        },
                                                                        textOverflow: "ellipsis",
                                                                        overflow: "hidden",
                                                                        whiteSpace: "nowrap",
                                                                    }}>
                                                                    <IconButton
                                                                        onClick={() => copy(`${appUrl}/contact/${_.token}?offer=` || "")}>
                                                                        <ContentCopyIcon color="primary"/>
                                                                    </IconButton>
                                                                    {appUrl}/contact/{_.token}?offer=
                                                                </Stack>
                                                            </TableCell>
                                                            <TableCell align="center">
                                                                {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                            </TableCell>
                                                            <TableCell
                                                                align="center">
                                                                <IconButton
                                                                    onClick={() => handleClickSettings(_)}>
                                                                    <SettingsIcon
                                                                        sx={{
                                                                            color: colors.black.main
                                                                        }} />
                                                                </IconButton>
                                                                <IconButton
                                                                    onClick={() => handleClickModify(_)}>
                                                                    <CreateIcon
                                                                        sx={{
                                                                            color: colors.blue.main
                                                                        }} />
                                                                </IconButton>
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={campaignsCount}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >Erreur lors de l'enregistrement de la campagne.<br />⚠️  Le code doit être unique.</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

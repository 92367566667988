import { useTheme } from '@mui/material';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


interface Props {
    plateformStyle: any;
    title: string | JSX.Element | JSX.Element[];
    formuleCenter: JSX.Element;
    formuleLeft?: JSX.Element;
    formuleRight?: JSX.Element;
    formuleRecommended?: string;
    formulesDetailsLength?: number;
}


export const OfferDetailsRow = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const theme = useTheme();

    return (
        <Grid
            item
            xs={12} >
            <Grid
                container
                alignItems='stretch'
                sx={{
                    color: theme.palette.text.secondary
                }} >

                <Grid
                    item
                    lg={props.formulesDetailsLength === 1 ? 6 : props.formulesDetailsLength === 2 ? 4 : 3}
                    md={props.formulesDetailsLength === 1 ? 6 : 4}
                    sm={6}
                    xs={12}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end',
                        textAlign: 'right',
                        py: 1,
                        pr: {
                            sm: 1,
                            xs: 0
                        }
                    }} >
                    <Typography variant='caption'>{props.title}</Typography>
                </Grid>

                <Grid
                    item
                    lg={props.formulesDetailsLength === 2 ? 4 : 3}
                    md={4}
                    xs={12}
                    sx={{
                        display: {
                            lg: props.formulesDetailsLength === 1 ? "none" : 'flex',
                            xs: 'none'
                        },
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: props.formuleRecommended === "left" ? props.plateformStyle.components.IconInfo.bgColor : props.plateformStyle.components.Card.bgColor,
                        color: props.formuleRecommended === "left" ? theme.palette.primary.main : theme.palette.text.secondary,
                        fontWeight:  props.formuleRecommended === "left" ? 700 : 500,
                        borderRight: 1,
                        borderRightColor: plateformStyle.colors.grey.main,
                        py: 1,
                        px: 2
                    }} >
                    {props.formuleLeft && props.formuleLeft}
                </Grid>

                <Grid
                    item
                    lg={props.formulesDetailsLength === 1 ? 6 : props.formulesDetailsLength === 2 ? 4 : 3}
                    md={props.formulesDetailsLength === 1 ? 6 : 4}
                    sm={6}
                    xs={12}
                    sx={{
                        display: 'flex',
                        justifyContent: {
                            sm: 'center',
                            xs: 'flex-end'
                        },
                        alignItems: 'center',
                        background: props.formuleRecommended === "center" ? props.plateformStyle.components.IconInfo.bgColor : props.plateformStyle.components.Card.bgColor,
                        color: props.formuleRecommended === "center" ? theme.palette.primary.main : theme.palette.text.secondary,
                        fontWeight:  props.formuleRecommended === "center" ? 700 : 500,
                        borderBottom: {
                            sm: 0,
                            xs: 1
                        },
                        borderBottomColor: {
                            sm: 'none',
                            xs: plateformStyle.colors.grey.main
                        },
                        py: 1,
                        px: {
                            sm: 2,
                            xs: 0
                        }
                    }} >
                    {props.formuleCenter}
                </Grid>

                <Grid
                    item
                    lg={3}
                    md={4}
                    xs={12}
                    sx={{
                        display: {
                            md: props.formulesDetailsLength === 1 ? "none" : 'flex',
                            xs: 'none'
                        },
                        alignItems: 'center',
                        justifyContent: 'center',
                        background: props.formuleRecommended === "right" ? props.plateformStyle.components.IconInfo.bgColor : props.plateformStyle.components.Card.bgColor,
                        color: props.formuleRecommended === "right" ? theme.palette.primary.main : theme.palette.text.secondary,
                        fontWeight:  props.formuleRecommended === "right" ? 700 : 500,
                        borderLeft: 1,
                        borderLeftColor: plateformStyle.colors.grey.main,
                        py: 1,
                        px: 2
                    }} >
                    {props.formuleRight}
                </Grid>

            </Grid>
        </Grid>
    )
}



import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import { Typography } from '@mui/material';

import { CgvContent } from './CgvContent';
import { ButtonLink } from '../Button/ButtonLink';
import { colors } from '../../static/themes/gsmc/colors';

export const CgvContentSummary = () => {

    return (
        <Grid
            container
            justifyContent='center'
            alignItems="flex-start"
            spacing={2}
            sx={{
                position: "relative"
            }} >

            <Grid
                md={3}
                xs={12}
                item
                sx={{
                    position: "sticky",
                    display: {
                        md: "flex",
                        xs: "none"
                    },
                    top: 0
                }}>
                <Box >
                    <Typography variant="h3" color={colors.blue.main} sx={{mb: 1.5}}>Sommaire</Typography>
                    <ButtonLink
                        label="Mentions légales"
                        onClick={() => {document.getElementById("mentions_legales")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Adresse électronique"
                        onClick={() => {document.getElementById("adresse_electronique")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Propriété intellectuelle"
                        onClick={() => {document.getElementById("propriete_intellectuelle")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Validité des informations"
                        onClick={() => {document.getElementById("validite_informations")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Protection des données"
                        onClick={() => {document.getElementById("protection_donnees")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Données collectées"
                        onClick={() => {document.getElementById("donnees_collectees")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Finalités de traitement"
                        onClick={() => {document.getElementById("finalites_traitement")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Destinataires des données"
                        onClick={() => {document.getElementById("destinataires_donnees")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Conservation des données"
                        onClick={() => {document.getElementById("conservation_donnees")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Sécurité des données"
                        onClick={() => {document.getElementById("securite_donnees")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Droits des personnes"
                        onClick={() => {document.getElementById("droit_personnes")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Limitation de la responsabilité"
                        onClick={() => {document.getElementById("limitation_responsabilite")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Cookies"
                        onClick={() => {document.getElementById("cookies")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Espace réservé"
                        onClick={() => {document.getElementById("confidentilite")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Evolution des CGU"
                        onClick={() => {document.getElementById("evolution")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Droit applicable"
                        onClick={() => {document.getElementById("droit_applicable")?.scrollIntoView();}} />
                    <ButtonLink
                        label="Réclamation / Médiation"
                        onClick={() => {document.getElementById("reclamation")?.scrollIntoView();}} />
                </Box>
            </Grid>

            <Grid
                md={9}
                xs={12}
                item >
                <CgvContent />
            </Grid>
        </Grid>
    )
}


export enum PageLoginSteps {
    SIGNIN,
    PASSWORD_FORGOTTEN,
    PASSWORD_FORGOTTEN_INFO,
    ACCOUNT_ACTIVATION,
    ACCOUNT_ACTIVATION_INFO,
    SUCCESS
}


export const passwordMinLength: number = 10;
export const passwordMaxLength: number = 100;


export interface ISigninRequest {
    username: string;
    password: string;
}

export interface ISignupRequest {
    username?: string;
    email: string;
    lastname?: string;
    firstname?: string;
    picture?: string;
    password: string;
}

export interface IPasswordResetRequest {
    email: string;
}

export interface IPasswordChangeRequest {
    oldPassword: string;
    password: string;
}

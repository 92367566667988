import { ThemeProvider } from '@mui/material/styles';

import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid"
import Typography from '@mui/material/Typography';
import LinearProgress from "@mui/material/LinearProgress";

import { ReactComponent as LogoGSMC } from '../static/img/gsmc/logo.svg';

import { theme } from '../static/themes/gsmc/theme'


export const Loading = () => {
    return (
        <ThemeProvider theme={theme}>
            <Grid
                container
                spacing={0}
                alignItems="center"
                justifyContent="center"
                sx={{
                    height: '100vh',
                    textAlign: 'center'
                }} >

                <Grid
                    item
                    sx={{
                        maxWidth: '500px'
                    }}>
                    <Card
                        sx={{
                            mx: 2
                        }} >
                        <LinearProgress />

                        <Box
                            sx={{
                                px: {
                                    sm: 6,
                                    xs: 3
                                },
                                py: 8
                            }} >
                            <LogoGSMC />
                            <Typography
                                variant='h2'
                                sx={{
                                    mt: 2,
                                    mb: 1
                                }}>
                                Connexion en cours...
                            </Typography>
                            <Typography variant='body1' fontWeight={500}>Bienvenue sur le BO du tunnel GSMC</Typography>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
}

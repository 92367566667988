import { Page } from ".";

import { IFormDocument } from "./FormDocument.model";

import { colors } from "../static/themes/gsmc/colors";
import { IUser } from "./auth/User.model";


export interface IPageControle extends Page {
    content: IControle[];
}

export interface IContractHenner {
    id: number;
    techDateModification?: Date;
    codeDevis?: string;
    codeContrat?: string;
    isCallOver: boolean;
    devisCreated: boolean;
    souscrireCreated: boolean;
    contractCreated: boolean;
    responseDevis?: string
    responseSouscrire?: string
    responseContract?: string
    user?: IUser;
}

interface IPerson {
    id: number;
    lastname: string;
    firstname: string;
    email: string;
    civility: string;
}

interface IContract {
    id: number;
    dateStart: Date;
    priceContract: number;
    person: IPerson;
    hennerContract?: IContractHenner;
}

interface IForm {
    id: number;
    uuid: string;
    crmId?: string;
    plateformId: number;
    contract: IContract;
}


export interface IControle {
    id: number;
    techDateCreated: Date;
    techDateModification: Date;
    techDateControl: Date;
    dateLimit?: Date;
    comment?: string;
    commentFraud?: string;
    status: ControlStatus;
    user: IUser;
    form: IForm;
    documents: IControleDocument[];
    offerCode?: string;
    formulaCode?: string;
    hennerSent: boolean;
    isMEG: boolean;
    plateformeCode?: string;
}


export interface IControleDocument extends IFormDocument {
    id: number;
    documentId: number;
    documentUuid?: string;
    techDateControl?: Date;
    user?: IUser;
    status: ControlStatus;
    comment?: String;
    content?:string;
}


export interface IControleDocumentRequestPOST {
    id: number;
    status: ControlStatus;
    comment?: String;
}


export interface IControlMEG {
    dateStart: Date;
}


export enum ControlStatus {
    ACCEPTED="ACCEPTED",
    CLOSED="CLOSED",
    EXPIRED="EXPIRED",
    IN_PROGRESS="IN_PROGRESS",
    INCOMPLETE="INCOMPLETE",
    PENDING="PENDING",
    REFUSED="REFUSED",
    MEG="MEG",
    MEG_KO="MEG_KO"
}


export const controlStatusParam = [
    {
        value: ControlStatus.INCOMPLETE,
        label: "Incomplet",
        color: colors.grey.main,
        textColor: colors.black.main
    },
    {
        value: ControlStatus.PENDING,
        label: "A vérifier",
        color: colors.grey.main,
        textColor: colors.black.main
    },
    {
        value: ControlStatus.REFUSED,
        label: "Attente correction",
        description: "Le prospect doit corriger ses documents",
        color: colors.black.secondary
    },
    {
        value: ControlStatus.IN_PROGRESS,
        label: "En cours",
        color: "#2155CD"
    },
    {
        value: ControlStatus.ACCEPTED,
        label: "Accepté",
        color: colors.green.main
    },
    {
        value: ControlStatus.MEG,
        label: "Mis en gestion",
        color: colors.black.main
    },
    {
        value: ControlStatus.MEG_KO,
        label: "Mise en gestion échouée",
        description: "La mise en gestion automatique a échoué",
        color: colors.black.main,
        borderColor: colors.red.main
    },
    {
        value: ControlStatus.CLOSED,
        label: "Clôturé",
        color: colors.red.main
    },
    {
        value: ControlStatus.EXPIRED,
        label: "Expiré",
        color: colors.orange.main
    },
]


export const controlToDocument = (_: IControleDocument) => {
    return {..._, id: _.documentId, uuid: _.documentUuid} as IFormDocument
}


export const getStatusValue = (status: ControlStatus) => {
    switch (status) {
        case ControlStatus.REFUSED:
            return 100;

        case ControlStatus.ACCEPTED:
            return 90;

        default:
            return 0;
    }
}


export const documentOrderByStatus = (a: IControleDocument, b: IControleDocument) => {

    const aTypeValue = getStatusValue(a.status);
    const bTypeValue = getStatusValue(b.status);

    if (aTypeValue > bTypeValue)
        return -1;

    if (aTypeValue < bTypeValue)
        return 1;

    return 0;
}

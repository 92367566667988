import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

import { IUser } from "../../model/auth/User.model";
import { FormSignup } from "../../components/Form/Auth/FormSignup";

import { getUserByResetToken } from "../../api/auth/User.api";
import AuthService from "../../services/AuthService";


const authService = new AuthService();


export const Signup = () => {
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    const queryParameters = new URLSearchParams(window.location.search)
    const token = queryParameters.get("token")

    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        if (token === undefined || token === null) {
            navigate("/auth/signin")
            return
        }

        // Get user from reset token
        getUserByResetToken(token)
        .then(response => {
            setUser(response);
        })
        .catch(error => {
            if (error.message === "404")
                navigate("/auth/signin")
                return
        });
    }, [])

    return (
        <>
        {token === null || user === undefined ?
        <></>
        :
        <FormSignup
            user={user}
            token={token}
            send={(_) => {
                authService.logout()

                if (searchParams.get('redirect') && searchParams.get('redirect') !== null) {
                    navigate(searchParams.get('redirect') as string);
                    return;
                }

                navigate("/verification/souscription");
            }} />}
        </>
    )
}

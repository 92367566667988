export function formatString(text: string,
                             oldText: string,
                             options?: {
                                maxLength?: number,
                                cursorPosition?: number,
                                toUpperCase?: boolean,
                                capitalize?: boolean,
                                charactersEnabled?: string,
                                pattern?: number[],
                                separator?: string
                            }) {

    if (!text)
        return { value: "", cursorPosition: null };

    if (!options)
        return { value: text, cursorPosition: null };

    if (options.maxLength !== undefined) {
        let cleanTextMaxLength: number = options.maxLength;

        if (options.pattern && options.separator)
            cleanTextMaxLength += options.separator.length * (options.pattern.length - 1);  // Add the separators to the text length.

        if (text.length > cleanTextMaxLength && oldText.length <= cleanTextMaxLength) 
            return { value: oldText, cursorPosition: options.cursorPosition ? options.cursorPosition - 1 : null };
    }

    // Clean the text.
    let cleanText: string = text;
    let cleanTextParts: string[] = [];

    if (options.charactersEnabled)
        cleanText = cleanText.replace(new RegExp(options.charactersEnabled, "gi"), "");  // white space not deleted

    if (options.toUpperCase)
        cleanText = cleanText.toUpperCase();

    if (options.capitalize) {
        cleanText = cleanText.toLowerCase().replace(/(^[A-zÀ-ú]|\s[A-zÀ-ú]|-[A-zÀ-ú])/g, _ => _.toUpperCase())
    }

    cleanText = cleanText.replace(/\s\s+/g, " ");  // Delete multiples white spaces.

    if (!options.pattern)
        return { value: cleanText, cursorPosition: options.cursorPosition ? oldText && cleanText.length === oldText.length ? options.cursorPosition - 1 : options.cursorPosition : null};

    // Format the text.
    let lastItem: number = 0;

    options.pattern.forEach((item: number) => {

        if (cleanText.length >= lastItem)
            cleanTextParts.push(cleanText.substring(lastItem, item));

        lastItem = item;
    });

    cleanText = cleanTextParts.join(options.separator);

    if (options.cursorPosition === undefined || options.separator === undefined)
        return { value: cleanText, cursorPosition: options.cursorPosition ? options.cursorPosition : null };

    // Compute the new cursor position.
    let cursorPosition: number = options.cursorPosition;
    let deltaCursorPosition: number = cleanText.substring(0, cursorPosition).length - oldText.substring(0, cursorPosition).length;
    let deltaTextLength: number = cleanText.length - oldText.length;

    cursorPosition += deltaCursorPosition;

    if (cleanText === oldText && cleanText.substr(cleanText.length - options.separator.length) !== options.separator)  // No change : An invalid character have been inputted.
        cursorPosition--;

    if (cleanText.substring(cursorPosition - options.separator.length, cursorPosition) === options.separator && cursorPosition !== cleanText.length)
        deltaTextLength > 0 ? cursorPosition += options.separator.length : cursorPosition -= options.separator.length;

    return { value: cleanText, cursorPosition: cursorPosition };
};

import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/system/Box';

import { MainHeader } from "../../components/Header/MainHeader"
import { Footer } from "../../components/Footer/Footer";

import { FormDocumentsDisplay } from "../form/FormDocumentsDisplay";

import { getFormContext } from "../../api/Form.api";
import { getPlateformeSettings } from "../../api/PlateformeSettings.api";

import { IForm } from "../../model/Form.model";
import { IFormContext } from "../../model/FormContext.model";
import { IPlateformeSettings } from "../../model/PlateformeSettings.model";

import { theme as themeGSMC } from '../../static/themes/gsmc/theme'
import { style as styleGSMC } from '../../static/themes/gsmc/style'
import { getPlateformStyle, getPlateformTheme } from "../../static/themes/theme";
import { PageLayout } from "../../components/Layout/PageLayout";


export const EspaceDocument = () => {
    document.title = 'Mutuelle GSMC';

    // Theming.
    const [theme, setTheme] = useState<any>(themeGSMC);
    const [plateformStyle, setPlateformStyle] = useState<any>(styleGSMC);
    const [plateformSettings, setPlateformSettings] = useState<IPlateformeSettings>();
    const [form, setForm] = useState<IForm>();
    const [codeOffer, setCodeOffer] = useState<string>();

    // URL parameters.
    const urlFormUuid = useParams<{ uuid: string }>().uuid || ""

    // Initialize States after the first render() lifecycle.
    useEffect(() => {
        let uuid: string | undefined = urlFormUuid;

        // Get the UUID from the URL.
        if (uuid)
            localStorage.setItem('form_uuid', uuid.toString());

        // Get the UUID from the local storage.
        else
            uuid = localStorage.getItem('form_uuid') || undefined;

        if (!uuid)
            return;

        getFormContext(uuid)
        .then((response: IFormContext) => {
            setTheme(getPlateformTheme(response.plateform.code));
            setPlateformStyle(getPlateformStyle(response.plateform.code));
            setForm(response.form);
            setCodeOffer(response.contractNeeds.codeOffre)

            // Get plateforme settings.
            return getPlateformeSettings(response.plateform.code);
        })
        .then((response?: IPlateformeSettings) => {
            setPlateformSettings(response);
        })
    }, []);


    return (
        form?.id &&
        <PageLayout
            theme={theme}
            header={
                <MainHeader
                    plateformStyle={plateformStyle}
                    isReturnEnabled={false}
                    goToPrevious={() => {}} />}
            content={
                <Box
                    sx={{
                        maxWidth: '1200px',
                        mx: 'auto'
                    }}>
                    {plateformSettings &&
                    <FormDocumentsDisplay
                        plateformStyle={plateformStyle}
                        plateformSettings={plateformSettings}
                        form={form}
                        codeOffer={codeOffer}
                        isSinglePage
                        goToNext={() => {}}
                        goToPrevious={() => {}} /> }
                </Box>
            }
            footer={
                <Box
                    sx={{
                        maxWidth: '1200px',
                        mx: 'auto'
                    }}>
                    {plateformSettings &&
                    <Footer
                        plateformStyle={plateformStyle}
                        plateformSettings={plateformSettings}
                        form={form} /> }
                </Box>
            }/>
    )
}

import React, { useEffect, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import { FormAdress } from '../../components/Form/FormAdress';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { AdressIcon } from '../../components/Icon/AdressIcon';

import { createAdress, modifyAdress } from '../../api/Adress.api';
import { modifyPerson } from '../../api/Person.api';

import { IAdress } from '../../model/Adress.model';
import { IPerson } from '../../model/Person.model';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { DialogRegime } from '../../components/Dialog/DialogRegime';
import { enumGsmcRegimeAM, enumGsmcRegimeGeneral, enumGsmcRegimeIndep } from '../../utils';
import * as Sentry from "@sentry/react";


interface Props {
    plateformStyle: any;
    sidebar?: JSX.Element;
    subscriber: IPerson;
    sendSubscriber: (person: IPerson) => void;
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormSubscriberAdress = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const iconHeaderStyle: any = plateformStyle.components.IconHeader;
    const progressBarStyle: any = plateformStyle.components.ProgressBar;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const formRef = React.createRef<HTMLFormElement>();
    const [progress, setProgress] = React.useState(0);

    const sendSubscriber = (subscriber: IPerson) => {
        props.sendSubscriber(subscriber);  // Update Form states.
    }

    const updateSubscriberAdress = (adress: IAdress) => {
        let subscriber: IPerson = props.subscriber;

        subscriber.adress = adress;

        sendSubscriber(subscriber);
    }

    const saveSubscriberAdress = (adress: IAdress) => {
        if (!adress.id) {
            createAdress(adress).then((response : IAdress) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriberAdress -> createAdress - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: adress,
                            }
                        }
                    );
                }

                let subscriber: IPerson = props.subscriber;

                subscriber.adress = response;

                return modifyPerson(subscriber);
            })
            .then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriberAdress -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.subscriber,
                            }
                        }
                    );
                }
                sendSubscriber(response);  // Update Form states.
            });
        }
        else {
            modifyAdress(adress).then((response : IAdress) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriberAdress -> modifyAdress - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: adress,
                            }
                        }
                    );
                }

                let subscriber: IPerson = props.subscriber;

                subscriber.adress = response;

                return modifyPerson(subscriber);
            })
            .then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriberAdress -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.subscriber,
                            }
                        }
                    );
                }
                sendSubscriber(response);  // Update Form states.
            });
        }
    }

    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        if (formRef.current)
            formRef.current.leave();

        props.goToPrevious();
    }, [props.goToPreviousFlag])

    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Votre adresse" />}
            sidebar={props.sidebar}
            content={
                <Card>
                    <LinearProgress
                        variant="determinate"
                        value={progress}
                        color={progressBarStyle.form.color} />

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection
                                label="Votre adresse"
                                icon={<AdressIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor} />} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormAdress
                                plateformStyle={props.plateformStyle}
                                ref={formRef}
                                adress={props.subscriber.adress}
                                person={props.subscriber}
                                send={updateSubscriberAdress}
                                save={saveSubscriberAdress}
                                sendProgress={setProgress}
                                sendPerson={props.sendSubscriber}
                                goToNext={() => props.goToNext()} />
                        </Grid>
                    </Grid>
                </Card>
            }
            footer={
                <Button
                    variant='contained'
                    color={buttonSubmitStyle.color}
                    sx={{
                        width: {
                            sm: 400,
                            xs: '100%'
                        },
                        my: 5,
                        px: 5,
                        color: 'white'
                    }}
                    onClick={() => {
                        let isValid: boolean = false;

                        if (formRef.current)
                            isValid = formRef.current.handleSubmit();
                    }} >
                    Valider les informations
                </Button>} />
    )
}

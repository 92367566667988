import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { colors } from '../../../static/themes/gsmc/colors';
import FormHelperText from '@mui/material/FormHelperText';
import { TextFieldPassword } from '../../Field/TextFieldPassword';
import { IUser } from '../../../model/auth/User.model';
import { ISignupRequest, passwordMaxLength, passwordMinLength } from '../../../model/auth/Authentication.model';
import { messageFieldRequired } from '../../../utils/messages';
import { signup } from '../../../api/auth/Authentication.api';


interface Props {
    user?: IUser;
    token: string;
    send: (user: IUser) => void;
}


export const FormSignup = (props: Props) => {

    const getInitialValues = () => {
        return {
            picture: props.user?.picture,
            email: props.user?.email,
            username: props.user?.username,
            lastname: props.user?.lastname,
            firstname: props.user?.firstname,
            password: undefined as string | undefined,
            passwordConfirm: undefined as string | undefined
        }
    };

    const validationSchema = yup.object({
        email: yup.string().required(messageFieldRequired),
        username: yup.string()
            .required(messageFieldRequired)
            .matches(/^[\w\.\-]+$/, "Votre nom de compte ne doit pas contenir d'espace, de caratére accentué ou de caratère spécial (excepté ., - et _)"),
        lastname: yup.string(),
        firstname: yup.string(),
        password: yup.string()
            .required(messageFieldRequired)
            .min(passwordMinLength, `Votre mot de passe doit contenir au moins ${passwordMinLength} caractères`)
            .matches(/[0-9]/, 'Votre mot de passe doit contenir au moins un chiffre')
            .matches(/[a-zà-ú]/, 'Votre mot de passe doit contenir au moins un caratère minuscule')
            .matches(/[A-ZÀ-Ú]/, 'Votre mot de passe doit contenir au moins un caratère majuscule')
            .matches(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'Votre mot de passe doit contenir au moins un caratère spécial'),
        passwordConfirm: yup.string()
            .required(messageFieldRequired)
            .min(passwordMinLength, `Votre mot de passe doit contenir au moins ${passwordMinLength} caractères`)
            .matches(/[0-9]/, 'Votre mot de passe doit contenir au moins un chiffre')
            .matches(/[a-zà-ú]/, 'Votre mot de passe doit contenir au moins un caratère minuscule')
            .matches(/[A-ZÀ-Ú]/, 'Votre mot de passe doit contenir au moins un caratère majuscule')
            .matches(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'Votre mot de passe doit contenir au moins un caratère spécial')
            .oneOf([yup.ref('password')], "Les mots de passe ne sont pas identiques")
})

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!values.email || !values.password)
                return;  // Should never occur with the yup validation.

            const toSend: ISignupRequest = {
                email: values.email,
                username: values.username,
                lastname: values.lastname,
                firstname: values.firstname,
                picture: values.picture,
                password: values.password
            };

            signup(toSend, props.token)
            .then(response => {
                if (response.id) {
                    props.send(response);
                }

                if (response.errors) {
                    response.errors.map((_: any) => {
                        formik.setFieldError(_.field, _.message);
                    })
                }
            })
            .catch(error => {
                if (error.message === "404")
                    formik.setFieldError("email", "Le compte associé à cet email n'existe pas")
            });
        }
    })


    useEffect(() => {
        formik.resetForm({values: getInitialValues()});
    }, [props.user])


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>
                <Grid
                    item
                    xs={12}>
                    {props.user?.isActivated ?
                    <>
                    <Typography variant='h1'>Changer de votre mot de passe !</Typography>
                    <Typography variant='body2'>Renseigner votre nouveau mot de passe.</Typography>
                    </>
                    :
                    <>
                    <Typography variant='h1'>Bienvenue !</Typography>
                    <Typography variant='body2'>Veuillez finaliser la création de votre compte.</Typography>
                    </>
                    }
                </Grid>

                {!props.user?.isActivated &&
                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="username"
                        name="username"
                        placeholder="Votre nom de compte"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username} />
                </Grid>}

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        disabled
                        id="email"
                        name="email"
                        placeholder="Votre email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />

                        {!props.user?.isActivated &&
                        <FormHelperText
                            sx={{
                                ml: 2
                            }}>
                            <Typography
                                variant='caption'>
                                Votre email pourra être modifié une fois votre compte créé.
                            </Typography>
                        </FormHelperText>}
                </Grid>

                {!props.user?.isActivated &&
                <>
                <Grid
                    item
                    xs={6}>
                    <TextField
                        fullWidth
                        id="lastname"
                        name="lastname"
                        placeholder="Votre nom de famille"
                        value={formik.values.lastname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                        helperText={formik.touched.lastname && formik.errors.lastname} />
                </Grid>

                <Grid
                    item
                    xs={6}>
                    <TextField
                        fullWidth
                        id="firstname"
                        name="firstname"
                        placeholder="Votre prénom"
                        value={formik.values.firstname}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                        helperText={formik.touched.firstname && formik.errors.firstname} />
                </Grid>
                </>}

                <Grid
                    item
                    xs={12}>
                    <TextFieldPassword
                        fullWidth
                        id="password"
                        placeholder="Votre mot de passe"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)} />

                    {formik.touched.password && formik.errors.password &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.password}
                        </Typography>
                    </FormHelperText>}

                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'>
                            Votre mot de passe doit contenir au moins 10 caractères dont :
                            <br />- Un chiffre
                            <br />- Une majuscule
                            <br />- Une minuscule
                            <br />- Un cacactère spécial
                        </Typography>
                    </FormHelperText>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextFieldPassword
                        fullWidth
                        id="passwordConfirm"
                        placeholder="Confirmer votre mot de passe"
                        value={formik.values.passwordConfirm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.passwordConfirm && Boolean(formik.errors.passwordConfirm)} />

                    {formik.touched.passwordConfirm && formik.errors.passwordConfirm &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.passwordConfirm}
                        </Typography>
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        type='submit'
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        {!props.user?.isActivated ? "Créer mon compte" : "Changer mon mot de passe"}
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}

import { IIcon } from "./settings"


const defaultWidth: string = "100";
const defaultHeight: string = "70";


export const FolderEmptyIcon = (props: IIcon) => {

    return (
        <svg 
            width={props.width ? props.width : defaultWidth}
            height={props.height ? props.height : defaultHeight}
            viewBox="0 0 96 69"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_8_138" maskUnits="userSpaceOnUse" x="0" y="0" width="96" height="69">
        <rect width="96" height="69" fill="white"/>
        </mask>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0109 1C11.2363 1 7.36572 4.87057 7.36572 9.64516V53.5914C7.36572 58.366 11.2363 62.2366 16.0109 62.2366H32.5808C35.3811 62.2366 38.4256 62.2366 41.2259 62.2366H78.6883C83.4629 62.2366 87.3335 58.366 87.3335 53.5914V17.5699C87.3335 12.7953 83.4629 8.92473 78.6883 8.92473H41.2259C41.2092 8.92473 41.1953 8.91182 41.1939 8.89517C40.8137 4.47174 37.1027 1 32.5808 1H16.0109Z" fill={props.color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M16.0109 1C11.2363 1 7.36572 4.87057 7.36572 9.64516V53.5914C7.36572 58.366 11.2363 62.2366 16.0109 62.2366H32.5808C35.3811 62.2366 38.4256 62.2366 41.2259 62.2366H78.6883C83.4629 62.2366 87.3335 58.366 87.3335 53.5914V17.5699C87.3335 12.7953 83.4629 8.92473 78.6883 8.92473H41.2259C41.2092 8.92473 41.1953 8.91182 41.1939 8.89517C40.8137 4.47174 37.1027 1 32.5808 1H16.0109Z" fill="black" fillOpacity="0.15"/>
        <path d="M1.00767 34.1772C-0.543713 26.7258 5.1446 19.7312 12.7557 19.7312H82.7707C90.3469 19.7312 96.0272 26.6653 94.536 34.0932L89.6636 58.3621C88.5383 63.9671 83.6152 68 77.8984 68H17.8085C12.1239 68 7.21916 64.0113 6.06045 58.446L1.00767 34.1772Z" fill={props.color}/>
        </svg>
    )
}

import { IProduit, IPageProduit } from "../model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getProduits = (page: number, pageSize: number, search?: string) : Promise<IPageProduit> => {

    let url: string = `${apiUrl}/produit?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getProduit = (id: number) : Promise<IProduit> => {

    return fetch(`${apiUrl}/produit/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const createProduit = (produit: IProduit) : Promise<IProduit> => {

    return fetch(`${apiUrl}/produit`,
    {
        method: "POST",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(produit)

    }).then(_ => _.json()) 
}

export const modifyProduit = (produit: IProduit) : Promise<IProduit> => {

    return fetch(`${apiUrl}/produit`,
    {
        method: "PUT",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(produit)

    }).then(_ => _.json()) 
}

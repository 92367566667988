import { useEffect, useState } from 'react';

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';

import { DialogOfferDetails } from '../Dialog/DialogOfferDetails';
import { OfferLeafIcon } from '../Icon/OfferLeafIcon';
import { DownloadIcon } from '../Icon/DownloadIcon';

import { downloadDocumentStatic } from '../../api/FormDocument.api';

import { getFormuleDetails, IFormule, IFormuleDetails } from '../../model/Formule.model';

import { enumFormDocumentTypeTG, enumOfferLabels } from '../../utils/enums';

import AnimatedNumber from "animated-number-react";
import Tooltip from '@mui/material/Tooltip';


interface Props {
    plateformStyle: any;
    codeOffer?: string;
    formule?: IFormule;
    formuleLoading?: Boolean;
    color?: string;
    isDisplayed?: Boolean;
    isSelected?: Boolean;
    isRecommended?: Boolean;
    isMinified?: Boolean;
    onClick?: () => void;
    onClickSecondary?: () => void;
}


export const CardOffer = (props: Props) => {

    const [isInitialized, setIsInitialized] = useState<boolean>(false);
    const [isPriceChanged, setIsPriceChanged] = useState<boolean>(false);
    const [formuleCode, setFormuleCode] = useState<number>();
    const [price, setPrice] = useState<number>(props.formule?.tarif || 0);
    const [displayFormuleDetails, setDisplayFormuleDetails] = useState<boolean>(false);

    const plateformStyle: any = props.plateformStyle;
    const linkStyle: any = plateformStyle.components.Link;
    const offerStyle: any = plateformStyle.components.Offer;
    const progressBarStyle: any = plateformStyle.components.ProgressBar;

    const formuleDetails: IFormuleDetails|undefined = props.formule ? getFormuleDetails(props.formule, plateformStyle) : undefined;
    const color: string|undefined = formuleDetails?.color || props.color || undefined;

    const downloadDocumentation = () => {
        if(!props.formule || !props.codeOffer)
            return;

        downloadDocumentStatic(props.codeOffer, enumFormDocumentTypeTG).then(response => {

            const link = document.createElement('a');
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );

            link.href = url;
            link.setAttribute('download', props.codeOffer === '539' ? "GSMC - Tableau de garanties - Activ' Santé TNS.pdf" : props.codeOffer === '512' ? "GSMC - Tableau de garanties - Generation 100 pourcent nous.pdf" : "GSMC - Tableau de garanties - Seniors 100 pourcent nous.pdf");

            document.body.appendChild(link);

            link.click();
        })
        .catch(e => {});  // TODO
    }

    useEffect(() => {
        setIsInitialized(true)
    }, [])

    useEffect(() => {
        if (props.formule) {

            if (isInitialized && props.formule.identifiant === formuleCode) {
                setIsPriceChanged(true)
                setTimeout(() => {
                    setIsPriceChanged(false)
                },
                600)
            }

            setFormuleCode(props.formule.identifiant);
            setPrice(props.formule.tarif || 0);
        }
    }, [props.formule])


    return (
        <Card
            sx={{
                textAlign: 'center',
                p: {
                    md: '0px 3px 3px 3px',
                    xs: props.isMinified ? '3px' : '0px 3px 3px 3px'
                },
                borderRadius: props.isRecommended ? '19px' : 4,
                color: 'white',
                background: props.isRecommended ? offerStyle.recommendedColor : 'none'
            }} >

            <Typography
                sx={{
                    my: '4px',
                    display: {
                        md: props.isRecommended ? 'block' : 'none',
                        xs: props.isRecommended && !props.isMinified ? 'block' : 'none'
                    }
                }} >
                Meilleur choix pour vous
            </Typography>
            
            <Card
                sx={{
                    border: props.isMinified && !props.isRecommended ? 2 : 0,
                    borderColor: color || progressBarStyle.color,
                    textAlign: 'left'
                }} >

                <LinearProgress
                    value={100}
                    sx={{
                        display: props.formuleLoading ? 'block' : 'none',
                        '& .MuiLinearProgress-bar': {
                            backgroundColor: color || progressBarStyle.color,
                        }
                    }} />
                <Box
                    sx={{
                        display: 'flex',
                        alignItems: 'center'
                    }} >

                    {/* TODO : adapt width on xs */}
                    <CardMedia
                        sx={{
                            display: {
                                sm: 'block',
                                xs: 'none'
                            },
                            px: 1
                        }}>
                        <OfferLeafIcon
                            color={color || offerStyle.color}
                            width='100%' />
                    </CardMedia>

                    <CardContent
                        sx={{
                            width: '100%',
                            px: 1,
                            py: props.isMinified ? "12px !important" : 2,
                            "&:last-child": {
                                paddingBottom: props.isMinified ? "12px !important" : 1,
                            }
                        }} >

                        {props.isDisplayed &&
                        <Tooltip title="Télécharger les garanties" placement="top">
                            <IconButton
                                aria-label="download"
                                sx={{
                                    p: 0,
                                    mr: {
                                        sm: 1,
                                        xs: 0.5
                                    },
                                    float: 'right',
                                    transition: "transform 400ms",
                                    ":hover": {
                                        transform: "translateY(-6px)"
                                    }
                                }}
                                onClick={() => downloadDocumentation()} >
                                <DownloadIcon color={plateformStyle.colors.black.main} />
                            </IconButton>
                        </Tooltip>}

                        {props.codeOffer && props.isDisplayed &&
                        <Typography variant='caption'>
                            {enumOfferLabels.find(_ => _.value === props.codeOffer)?.label}
                        </Typography>}
                        <Typography variant='h3' color={color || offerStyle.color} >{props.formule?.formuleLibelle}</Typography>
                        <Grid
                            container
                            columnSpacing={1}
                            justifyContent='flex-start'
                            alignItems='flex-end' >
                            <Grid
                                item >
                                <Typography variant='h3' component='div' noWrap >
                                    <AnimatedNumber
                                        value={price}
                                        formatValue={(value: number) => value.toFixed(2)}
                                        duration={isInitialized && (props.isDisplayed || isPriceChanged) ? 500 : 0}
                                    />€/mois
                                </Typography>
                            </Grid>
                            <Grid
                                item>
                                <Typography variant='caption' component='div' noWrap >Soit <AnimatedNumber
                                        value={price * 12}
                                        formatValue={(value: number) => value.toFixed(2)}
                                        duration={isInitialized && (props.isDisplayed || isPriceChanged) ? 500 : 0}
                                    />€/an
                                </Typography>
                            </Grid>
                        </Grid>

                        {props.isDisplayed &&
                        <Box
                            sx={{
                                mt: 1
                            }} >
                            <Link
                                sx={{
                                    ':hover': {
                                        cursor: 'pointer'
                                    },
                                    color: linkStyle.color,
                                    textDecorationColor: linkStyle.color
                                }}
                                onClick={() => setDisplayFormuleDetails(true)} >
                                Voir le détail de l'offre
                            </Link>
                        </Box> }
                    </CardContent>
                </Box>

                {!props.isMinified && !props.isDisplayed && props.onClick &&
                <Box
                    sx={{
                        mx: {
                            sm: 2,
                            xs: 1
                        },
                        mb: {
                            sm: 2,
                            xs: 1
                        },
                        textAlign: "center"
                    }}>
                    <Button
                        className="Button_Goto_Step1_ChoixOffre"
                        sx={{
                            width: '100%',
                            maxWidth: {
                                sm: "auto",
                                xs: 350
                            },
                            py: 1.25,
                            color: 'white',
                            backgroundColor: plateformStyle.colors.black.main,
                            transition: "transform 400ms",
                            ':hover': {
                                transform: "translateY(-6px)",
                                backgroundColor: 'black'
                            }
                        }}
                        onClick={props.onClick}>
                        Choisir cette offre
                    </Button>
                </Box>}


                {formuleDetails && props.codeOffer &&
                <DialogOfferDetails
                    plateformStyle={plateformStyle}
                    codeOffer={props.codeOffer}
                    formuleDetails={formuleDetails}
                    isDisplayed={displayFormuleDetails}
                    onClose={() => setDisplayFormuleDetails(false)}/> }
            </Card>
        </Card>
    )
}

import { useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import InboxIcon from '@mui/icons-material/Inbox';
import GradingIcon from '@mui/icons-material/Grading';
import SearchIcon from '@mui/icons-material/Search';
import Stack from '@mui/material/Stack';
import WarningIcon from '@mui/icons-material/Warning';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';

import { ControlStatus, controlStatusParam, IControle } from '../../../model/ControleDocument.model';

import { getControlBO } from '../../../api/Controle.api';

import { colors } from '../../../static/themes/gsmc/colors';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import { Link } from 'react-router-dom';
import { Lock, LockOpen } from '@mui/icons-material';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

export const ControlIncompleteList = () => {
    document.title = 'BO - Souscriptions incomplètes';

    const [controls, setControls] = useState<IControle[] | null>();
    const [controlsCount, setControlsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();

    useEffect(() => {
        getControlBO(pageNumber, rowPerPage, search, [ControlStatus.INCOMPLETE]).then(response => {
            setControls(response.content);
            setControlsCount(response.totalElements);
        })
        .catch((exception) => {
            setControls(null);
            setControlsCount(0);
        })
    }, [pageNumber, rowPerPage])

    const forceRefresh = () => {
        getControlBO(pageNumber, rowPerPage, search, [ControlStatus.INCOMPLETE]).then(response => {
            setControls(response.content);
            setControlsCount(response.totalElements);
        })
        .catch((exception) => {
            setControls(null);
            setControlsCount(0);
        })
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <EmojiEventsIcon />},
                {type: "LINK", label: "Souscription", url: "/bo/souscription"},
                {type: "LINK", label: "Incomplète"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Id</TableCell>
                                        <TableCell align="left">Statut</TableCell>
                                        <TableCell align="left">Offre</TableCell>
                                        <TableCell align="left">Date souscription</TableCell>
                                        <TableCell align="left">Souscripteur</TableCell>
                                        <TableCell align="left">Plateforme</TableCell>
                                        <TableCell align="left">N° contrat</TableCell>
                                        <TableCell align="left">Vérificateur</TableCell>
                                        <TableCell align="left">Date de vérification</TableCell>
                                        <TableCell
                                            key={'outil'}
                                            sx={{
                                                minWidth: '20px',
                                                position: "sticky",
                                                right: 0,
                                                background: colors.grey.secondary,
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {controls === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des documents à vérifier en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des documents à vérifier
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucune souscription
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls.map((_, id) => {

                                        const statusParam = controlStatusParam.find(e => e.value === _.status) || controlStatusParam[0];

                                        return (
                                            <TableRow key={id}>
                                                <TableCell align="left">#{_.id}</TableCell>
                                                <TableCell align="left">
                                                    <Stack direction="row" alignItems="center" gap={1}
                                                        sx={{
                                                            flexWrap: "nowrap"
                                                        }}>
                                                        <Chip
                                                            label={statusParam.label}
                                                            sx={{
                                                                color: statusParam.textColor || 'white',
                                                                backgroundColor: statusParam.color,
                                                                fontSize: 14
                                                            }} />
                                                        {_.hennerSent && _.form.contract.hennerContract?.isCallOver && (!_.form.contract.hennerContract?.codeContrat || _.form.contract.hennerContract.codeContrat === "GSM0") &&
                                                        <Tooltip
                                                            title={<>
                                                                <Typography variant="caption" color="inherit">La mise en gestion Henner n'a pas aboutie.</Typography>
                                                                {_.form.contract.hennerContract.codeContrat === "GSM0" && <Typography component="div" variant="caption" color="inherit">Un contrat existe déjà sur Henner pour ce NNI.</Typography>}
                                                            </>} >
                                                            <IconButton>
                                                                <WarningIcon
                                                                    sx={{
                                                                        color: colors.red.main
                                                                    }} />
                                                            </IconButton>
                                                        </Tooltip>}
                                                        {(_.status === ControlStatus.EXPIRED || (_.status === ControlStatus.INCOMPLETE && _.dateLimit && Math.abs((new Date(_.dateLimit).getTime() - new Date(_.techDateCreated).getTime()) / (1000 * 60 * 60 * 24)) > 8)) &&
                                                        <Tooltip
                                                            title={_.status === ControlStatus.EXPIRED ?
                                                                <Typography variant="caption" color="inherit">Espace document du souscripteur verrouillé</Typography> :
                                                                <Typography variant="caption" color="inherit">Espace document du souscripteur déverrouillé</Typography>} >
                                                            <IconButton>
                                                                {_.status === ControlStatus.EXPIRED ? <Lock /> : <LockOpen />}
                                                            </IconButton>
                                                        </Tooltip>}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="left">{_.offerCode ? _.offerCode === "312" ? "SENIOR" :  _.offerCode === "512" ? "GENERATION" : _.offerCode === "539" ? "TNS" : _.offerCode : "-"}</TableCell>
                                                <TableCell align="left">
                                                    {(_.techDateCreated !== undefined && _.techDateCreated !== null) ?
                                                        new Date(_.techDateCreated).getDate().toString().padStart(2, '0') + "/" + (new Date(_.techDateCreated).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.techDateCreated).getFullYear() : "-"}
                                                </TableCell>
                                                <TableCell align="left">{_.form.contract.person.lastname} {_.form.contract.person.firstname}<br/>{_.form.contract.person.email}</TableCell>
                                                <TableCell align="left">{_.plateformeCode || "-"}</TableCell>
                                                <TableCell align="left">{_.form.contract.hennerContract?.codeContrat || "-"}</TableCell>
                                                <TableCell align="left">{_.user ? `@${_.user.username}` : "-"}</TableCell>
                                                <TableCell align="left">
                                                    {(_.techDateControl !== undefined && _.techDateControl !== null) ?
                                                        new Date(_.techDateControl).getDate().toString().padStart(2, '0') + "/" + (new Date(_.techDateControl).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.techDateControl).getFullYear() : "-"}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        width: '20px',
                                                        position: "sticky",
                                                        right: 0,
                                                        background: colors.grey.secondary
                                                    }}>
                                                    <Link style={{
                                                        textDecoration: "none"
                                                    }} to={`/verification/souscription/${_.id.toString()}`} target="_blank" rel="noopener noreferrer" >
                                                        <Button
                                                            sx={{
                                                                py: 1,
                                                                height: "auto"
                                                            }}>
                                                            Voir
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={controlsCount}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

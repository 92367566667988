import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmailIcon from '@mui/icons-material/Email';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldLabel } from '../Field/FieldLabel';

import { updatePlateformeSettingsTemplateEmail } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsTemplateEmail } from '../../model/PlateformeSettings.model';

import { messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settings: IPlateformeSettingsTemplateEmail;
    send: (settings: IPlateformeSettingsTemplateEmail) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsTemplateEmail = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        subscriptionSender: yup.string().nullable().required(messageFieldRequired),
        subscriptionSubject: yup.string().nullable().required(messageFieldRequired),
        subscriptionHeaderText: yup.string().nullable().required(messageFieldRequired),
        subscriptionDescriptionText: yup.string().nullable().nullable().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues: {
            subscriptionSender: props.settings.subscriptionSender,
            subscriptionSubject: props.settings.subscriptionSubject,
            subscriptionHeaderText: props.settings.subscriptionHeaderText,
            subscriptionDescriptionText: props.settings.subscriptionDescriptionText
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsTemplateEmail = {
                id: props.settings.id,
                subscriptionSender: values.subscriptionSender,
                subscriptionSubject: values.subscriptionSubject,
                subscriptionHeaderText: values.subscriptionHeaderText,
                subscriptionDescriptionText: values.subscriptionDescriptionText
            }
            updatePlateformeSettingsTemplateEmail(toSend)
            .then((response: IPlateformeSettingsTemplateEmail) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres des mails.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <EmailIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Mails
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Renseigner les informations des mails envoyés au souscripteur
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Confirmation de la demande de souscription
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.subscriptionSender && Boolean(formik.errors.subscriptionSender)} >
                                <FieldLabel
                                    label="Nom de l'expéditeur"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="subscriptionSender"
                                name="subscriptionSender"
                                value={formik.values.subscriptionSender}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subscriptionSender && Boolean(formik.errors.subscriptionSender)}
                                helperText={formik.touched.subscriptionSender && formik.errors.subscriptionSender}
                                sx={{
                                    maxWidth: '400px'
                                }} />
                        </Grid>

                        {/* Email subject is now managed in API (see Brevo implementation #643)
                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.subscriptionSubject && Boolean(formik.errors.subscriptionSubject)} >
                                <FieldLabel
                                    label="Objet"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="subscriptionSubject"
                                name="subscriptionSubject"
                                value={formik.values.subscriptionSubject}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subscriptionSubject && Boolean(formik.errors.subscriptionSubject)}
                                helperText={formik.touched.subscriptionSubject && formik.errors.subscriptionSubject}
                                sx={{
                                    maxWidth: '600px'
                                }} />
                        </Grid>
                        */}

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.subscriptionHeaderText && Boolean(formik.errors.subscriptionHeaderText)} >
                                <FieldLabel
                                    label="Titre"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                maxRows={2}
                                id="subscriptionHeaderText"
                                name="subscriptionHeaderText"
                                value={formik.values.subscriptionHeaderText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subscriptionHeaderText && Boolean(formik.errors.subscriptionHeaderText)}
                                helperText={formik.touched.subscriptionHeaderText && formik.errors.subscriptionHeaderText} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.subscriptionDescriptionText && Boolean(formik.errors.subscriptionDescriptionText)} >
                                <FieldLabel
                                    label="Sous-titre"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                id="subscriptionDescriptionText"
                                name="subscriptionDescriptionText"
                                value={formik.values.subscriptionDescriptionText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.subscriptionDescriptionText && Boolean(formik.errors.subscriptionDescriptionText)}
                                helperText={formik.touched.subscriptionDescriptionText && formik.errors.subscriptionDescriptionText} />
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Déprécié avec Brevo
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import { useEffect, useState } from 'react';

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CommentIcon from '@mui/icons-material/Comment';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldLabel } from '../Field/FieldLabel';
import { FormRadioButton } from './FormRadioButton';

import { updatePlateformeSettingsContact } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';

import { enumContactMethodForm, enumContactMethodRedirect, enumContactMethods, messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settingsContact: IPlateformeSettingsContact;
    send: (settingsContact: IPlateformeSettingsContact) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsContact = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        displayContactCard: yup.boolean().required(messageFieldRequired),
        displayContactAction: yup.boolean().required(messageFieldRequired),
        displayContactMail: yup.boolean().required(messageFieldRequired),
        contactMethod: yup.string().nullable(),
        contactUri: yup.string().nullable(),
        contactMail: yup.string().email("Email non valide").nullable()
    })

    const formik = useFormik({
        initialValues: {
            displayContactCard: props.settingsContact.displayContactCard,
            displayContactAction: props.settingsContact.displayContactAction,
            displayContactMail: props.settingsContact.displayContactMail,
            contactMethod: props.settingsContact.contactMethod,
            contactUri: props.settingsContact.contactUri,
            contactMail: props.settingsContact.contactMail
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};

            // Validation Contact.
            if (values.displayContactAction &&
                (!values.contactMethod || values.contactMethod === null))
                errors.contactMethod = "La méthode de contact doit être renseignée si le bouton de contact est affiché";

            if (values.displayContactAction &&
                values.contactMethod === enumContactMethodRedirect &&
                (!values.contactUri || values.contactUri === null))
                errors.contactUri = "L'URL de redirection doit être renseigné avec la méthode de contact redirection";

            if (values.displayContactAction &&
                values.contactMethod === enumContactMethodForm &&
                (!values.contactMail || values.contactMail === null))
                errors.contactMail = "L'email de contact doit être renseigné avec la méthode de contact formulaire";

            if (values.displayContactMail &&
                (!values.contactMail || values.contactMail === null))
                errors.displayContactMail = "L'email de contact doit être renseigné";

            return errors;
        },
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsContact = {
                id: props.settingsContact.id,
                displayContactCard: values.displayContactCard,
                displayContactAction: values.displayContactAction,
                displayContactMail: values.displayContactMail,
                contactMethod: values.contactMethod,
                contactUri: values.contactUri,
                contactMail: values.contactMail,
                displaySupportCard: props.settingsContact.displaySupportCard,
                displaySupportAction: props.settingsContact.displaySupportAction,
                supportMethod: props.settingsContact.supportMethod,
                supportUri: props.settingsContact.supportUri,
                supportMail: props.settingsContact.supportMail,
                supportPhone: props.settingsContact.supportPhone,
                supportHours: props.settingsContact.supportHours,
            }
            updatePlateformeSettingsContact(toSend)
            .then((response: IPlateformeSettingsContact) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres de contact.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <CommentIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Contact
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Renseigner les informations de contact de la plateforme
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Carte de contact
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                La carte de contact se trouve en pied de page du formulaire.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.displayContactCard}
                                        onChange={(e, value) => formik.setFieldValue("displayContactCard", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Afficher la carte de contact
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.displayContactCard && Boolean(formik.errors.displayContactCard)} >
                                {formik.touched.displayContactCard && formik.errors.displayContactCard}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Le bouton de contact permet de rediriger ou d'afficher un formulaire de contact au souscripteur.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.displayContactAction}
                                        onChange={(e, value) => formik.setFieldValue("displayContactAction", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Afficher le bouton de contact
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.displayContactAction && Boolean(formik.errors.displayContactAction)} >
                                {formik.touched.displayContactAction && formik.errors.displayContactAction}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Afficher l'email de contact permet au souscripteur d'envoyer un email sans passer par la méthode du formulaire.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.displayContactMail}
                                        onChange={(e, value) => formik.setFieldValue("displayContactMail", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Afficher l'email de contact
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.displayContactMail && Boolean(formik.errors.displayContactMail)} >
                                {formik.touched.displayContactMail && formik.errors.displayContactMail}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.contactMethod && Boolean(formik.errors.contactMethod)} >
                                <FieldLabel
                                    label="Méthode de contact"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displayContactAction)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                La méthode de contact définie l'action effectué par le bouton de contact (redirection ou ouverture d'un formulaire).
                            </Typography>
                            <Grid
                                container
                                spacing={2} >
                                {enumContactMethods.map((_, id) => {
                                    return <Grid
                                        item
                                        lg={2}
                                        md={3}
                                        sm={4}
                                        xs={12} >
                                        <FormRadioButton
                                            code={_.value}
                                            selected={formik.values.contactMethod === _.value}
                                            color={colors.blue.main}
                                            onClick={(value) => {
                                                formik.setFieldValue("contactMethod", value);
                                            }} >
                                            {_.label}
                                        </FormRadioButton>
                                    </Grid>
                                })}
                            </Grid>
                            <FormHelperText
                                error={formik.touched.contactMethod && Boolean(formik.errors.contactMethod)} >
                                {formik.touched.contactMethod && formik.errors.contactMethod}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.contactMail && Boolean(formik.errors.contactMail)} >
                                <FieldLabel
                                    label="Email de contact"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displayContactMail ||
                                                (formik.values.displayContactAction &&
                                                 formik.values.contactMethod === enumContactMethodForm))
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                L'email de contact recevra les messages des souscripteurs avec la méthode formulaire.
                            </Typography>
                            <TextField
                                fullWidth
                                id="contactMail"
                                name="contactMail"
                                value={formik.values.contactMail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contactMail && Boolean(formik.errors.contactMail)}
                                helperText={formik.touched.contactMail && formik.errors.contactMail}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.contactUri && Boolean(formik.errors.contactUri)} >
                                <FieldLabel
                                    label="URL de redirection"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displayContactAction &&
                                                formik.values.contactMethod === enumContactMethodRedirect)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                URL de redirection utilisé avec la méthode redirection.
                            </Typography>
                            <TextField
                                fullWidth
                                id="contactUri"
                                name="contactUri"
                                value={formik.values.contactUri}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contactUri && Boolean(formik.errors.contactUri)}
                                helperText={formik.touched.contactUri && formik.errors.contactUri}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

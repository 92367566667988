import { defaultIconSize, IIcon } from "./settings"


export const CloseFullIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill={props.bgColor}/>
            <path fillRule="evenodd" clipRule="evenodd" d="M6.96022 22.2929C6.5697 22.6834 6.5697 23.3166 6.96022 23.7071C7.35075 24.0976 7.98391 24.0976 8.37444 23.7071L14.8336 17.2479L21.2929 23.7071C21.6834 24.0977 22.3166 24.0977 22.7071 23.7071C23.0977 23.3166 23.0977 22.6834 22.7071 22.2929L16.2479 15.8336L22.7072 9.37428C23.0978 8.98376 23.0978 8.35059 22.7072 7.96007C22.3167 7.56954 21.6835 7.56954 21.293 7.96007L14.8336 14.4194L8.37434 7.96013C7.98382 7.5696 7.35065 7.5696 6.96013 7.96013C6.56961 8.35065 6.56961 8.98382 6.96013 9.37434L13.4194 15.8336L6.96022 22.2929Z" fill={props.color}/>
        </svg>
    )
}

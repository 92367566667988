import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';

import { FieldFileDownload } from '../Field/FieldFileDownload';

import { getFormDocuments } from '../../api/FormDocument.api';

import { documentOrderByType, IFormDocument } from '../../model/FormDocument.model';

import { enumFormDocumentTypeRM, enumFormDocumentTypeRMS, enumFormDocumentTypes } from '../../utils/enums';


interface Props {
    plateformStyle: any;
    isCompact?: boolean
    formUuid: string;
    offerCode?: string;
    documents?: IFormDocument[];
    downloadAllFlag: number;
    typesExcluded?: string[];
}


export const DocumentsDownload = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const [documents, setDocuments] = useState<IFormDocument[]>(props.documents || []);

    useEffect(() => {
        if (props.documents !== undefined) {
            let docs: IFormDocument[] = props.documents;

            if (props.typesExcluded)
                docs = docs.filter(_ => !props.typesExcluded?.includes(_.type));

            docs.sort(documentOrderByType);

            setDocuments(docs);
            return;
        }

        getFormDocuments(props.formUuid).then(response => {

            let docs: IFormDocument[] = response;

            if (props.typesExcluded)
                docs = docs.filter(_ => !props.typesExcluded?.includes(_.type));

            docs.sort(documentOrderByType);

            setDocuments(docs);
        })
    }, [])


    return (
        <Grid
            container
            spacing={2} >

            {documents.map((_, id) => {
                let currentDoc = enumFormDocumentTypes.find((e) => e.value === _.type)

                if (!currentDoc) return

                if (props.offerCode === "539") {
                    if (_.type === enumFormDocumentTypeRM) currentDoc.label = "Conditions Générales"
                    else if (_.type === enumFormDocumentTypeRMS) currentDoc.label = "Conditions Générales Surco"
                }
 
                return <Grid
                    item
                    md={props.isCompact ? 6 : 4}
                    sm={6}
                    xs={12} >
                    <FieldFileDownload
                        plateformStyle={plateformStyle}
                        label={currentDoc.label}
                        icon={currentDoc.icon}
                        document={_}
                        downloadFlag={props.downloadAllFlag} />
                </Grid>
            })}
        </Grid>
    )
}

import React, {useEffect} from 'react';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';

import {FormHeader} from '../../components/Form/FormHeader';
import {FormHeaderSection} from '../../components/Form/FormHeaderSection';
import {IPerson} from '../../model/Person.model';
import {PageContentLayout} from '../../components/Layout/PageContentLayout';
import {FormSendEmailSignature} from "../../components/Form/FormSendEmailSignature";
import {getFormContext} from "../../api/Form.api";
import {IFormContext} from "../../model/FormContext.model";

interface Props {
    plateformStyle: any;
    sidebar?: JSX.Element;
    formUuid?: string;
    subscriber: IPerson;
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormContractBrokerSignEmail = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const formRef = React.createRef<HTMLFormElement>();

    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        props.goToPrevious();
    }, [props.goToPreviousFlag])

    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Signature du contrat"
                    description="Envoyez la demande de signature au prospect"/>}
            sidebar={props.sidebar}
            content={
                <Card>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection
                                label="Envoi par e-mail de la demande de signature du contrat"
                                description="Un e-mail permettant à votre client de signer son contrat va être envoyé à l'adresse ci-dessous."/>
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sm={6}>
                            <FormSendEmailSignature
                                ref={formRef}
                                formId={props.formUuid}
                                subscriberEmail={props.subscriber.email}
                                goToNext={props.goToNext}
                                goToPrevious={props.goToPrevious}/>
                        </Grid>
                    </Grid>
                </Card>
            }
            footer={
                <>
                    <Grid
                        item
                        md={12}
                        sx={{
                            width: '100%',
                            textAlign: 'center'
                        }}>
                        <Button
                            className="Button_Goto_Step4_Confirmation"
                            variant='contained'
                            color={buttonSubmitStyle.color}
                            sx={{
                                width: {
                                    sm: 400,
                                    xs: '100%'
                                },
                                mt: 3,
                                mb: 5,
                                px: 5,
                                color: 'white'
                            }}
                            onClick={() => {
                                if (formRef.current)
                                    formRef.current.handleSubmit();
                            }}>
                            Envoyer
                        </Button>
                    </Grid>
                </>
            }/>
    )
}

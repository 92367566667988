import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { Grid } from '@mui/material';
import Typography from "@mui/material/Typography";

import { TransitionSlideUP } from '../../utils/transistions/transitions';
import { IPromotion } from '../../model/Promotion.model';
import { CardPromotion } from '../Card/CardPromotion';


interface Props {
    plateformStyle: any;
    isOpened: boolean;
    promotions: IPromotion[];
    handleClose: () => void;
}


export const DialogPromotions = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle: any = props.plateformStyle;


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}
            PaperProps={{
                sx: {
                    borderRadius: {
                        sm: 4,
                        xs: 4
                    }
                }
            }} >

            <DialogTitle>
                <Typography variant="h2">Mes avantages</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    gap={2}
                    sx={{
                        py: 1
                    }}>
                    {props.promotions.map(_ => <Grid
                        item
                        xs={12}>
                        <CardPromotion plateformStyle={props.plateformStyle} promotion={_} />
                    </Grid>)}
                    
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Button
                    onClick={() => props.handleClose()}
                    sx={{
                        width: {
                            sm: "auto",
                            xs: "100%"
                        },
                        px: 2,
                        color: "white",
                        backgroundColor: plateformStyle.colors.black.main,
                        transition: "transform 400ms",
                        ':hover': {
                            transform: "translateY(-6px)",
                            backgroundColor: 'black'
                        }
                    }} >
                    Fermer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

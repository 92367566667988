import { IGamme, IPageGamme } from "../model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getGammes = (page: number, pageSize: number, search?: string) : Promise<IPageGamme> => {
    let url: string = `${apiUrl}/gamme?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getGamme = (id: number) : Promise<IGamme> => {

    return fetch(`${apiUrl}/gamme/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const createGamme = (gamme: IGamme) : Promise<IGamme> => {

    return fetch(`${apiUrl}/gamme`,
    {
        method: "POST",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(gamme)

    }).then(_ => _.json()) 
}

export const modifyGamme = (gamme: IGamme) : Promise<IGamme> => {

    return fetch(`${apiUrl}/gamme`,
    {
        method: "PUT",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(gamme)

    }).then(_ => _.json()) 
}

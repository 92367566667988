import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import * as yup from 'yup';
import { useFormik, FormikErrors } from 'formik'

import Grid from '@mui/material/Grid';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldBIC, pattern as patternBIC, separator as separatorBIC } from '../Field/TextFieldBIC';
import { TextFieldIBAN, defaultParamsIBAN as paramsIBAN, separator as separatorIBAN } from '../Field/TextFieldIBAN';
import { formatString } from '../Field/utils';

import { IContractBilling } from '../../model/ContractBilling.model';

import { messageFieldRequired } from '../../utils';
import { checkIban } from '../../api/ContractBilling.api';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { DialogContact } from '../Dialog/DialogContact';
import { IForm } from '../../model/Form.model';
import { IPerson } from '../../model/Person.model';
import { ICallback } from '../../model/Callback.model';


interface Props {
    plateformStyle?: any;
    form?: IForm
    subscriber?: IPerson
    billing: IContractBilling;
    contractId?: number
    isControlFraud?: boolean
    showBic?: Boolean;
    showOwnerName?: Boolean;
    showBankName?: Boolean;
    send: (billing: IContractBilling) => void;
    save: (billing: IContractBilling) => void;
    sendProgress?: (progress: number) => void;
}


interface FormValues {
    iban: string | undefined;
    bic: string | undefined;
    ownerName: string | undefined;
    bankName: string | undefined;
}


export const FormBilling = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();

            if (formik.isValid)
                return true;

            return false;
        },
        leave() {
            send();
        }
    }));

    const [fieldIBANError, setFieldIBANError] = useState<string>();
    const [fieldBicError, setFieldBicError] = useState<string>();
    const [isFieldIbanAlreadyUsed, setIsFieldIbanAlreadyUsed] = useState<boolean>(false);
    const [lastIbanChecked, setLastIbanChecked] = useState<string>();
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [nbFieldsRequired, setNbFieldsRequired] = useState<number>(1 + (props.showBic ? 1 : 0));
    const [isFormikValidatedInit, setIsFormikValidatedInit] = useState<boolean>(false);

    // Get the validation schema according to the props.
    const getValidationSchema = () => {

        let yupIban = yup.string()
            .test('isUnique',
                `Un contrat GSMC a déjà été signé avec cet IBAN`,
                value => {
                    if (!props.isControlFraud) return true

                    if (value === undefined || value === null || value.length <= 1) return true;

                    if (fieldIBANError) return true
                    if (!props.contractId) return true

                    if (lastIbanChecked === value) return !isFieldIbanAlreadyUsed
                    else (setIsFieldIbanAlreadyUsed(false))

                    setLastIbanChecked(value)

                    return checkIban(props.contractId, value)
                    .then((response) => {
                        if (response.status === 400) {
                            setIsFieldIbanAlreadyUsed(true)
                            return false
                        }

                        return true
                    })
                })
            .nullable()
            .required(messageFieldRequired);
        let yupBic = props.showBic ? yup.string().nullable().required(messageFieldRequired) : yup.string().nullable();
        let yupOwnerName = yup.string().nullable().max(100, "Champ non valide (100 caractères max)");
        let yupBankName = yup.string().nullable().max(100, "Champ non valide (100 caractères max)");

        return yup.object({
            iban: yupIban,
            bic: yupBic,
            ownerName: yupOwnerName,
            bankName: yupBankName
        });
    };

    const formik = useFormik({
        initialValues: {
            iban: props.billing?.iban ? formatString(props.billing.iban, "", { toUpperCase: true, pattern: paramsIBAN.pattern, separator: separatorIBAN }).value : undefined,
            bic: props.billing?.bic ? formatString(props.billing.bic, "", { toUpperCase: true, pattern: patternBIC, separator: separatorBIC }).value : undefined,
            ownerName: props.billing?.ownerName,
            bankName: props.billing?.bankName
        },
        validationSchema: getValidationSchema(),
        validateOnBlur: false,
        validate: (values: FormValues) => {
            let errors: FormikErrors<FormValues> = {};

            if (fieldIBANError)
                errors.iban = fieldIBANError;

            if (fieldBicError)
                errors.bic = fieldBicError;

            setIsFormikValidatedInit(true)

            return errors;
        },
        onSubmit: (values) => {
            save();
        }
    });


    const save = () => {
        let billing: IContractBilling = { ...props.billing };

        billing.iban = formik.values.iban?.replace(/\s/g, "");
        billing.bic = formik.values.bic?.replace(/\s/g, "");
        billing.ownerName = formik.values.ownerName;
        billing.bankName = formik.values.bankName;

        props.save(billing);
    }


    const send = () => {
        let billing: IContractBilling = { ...props.billing };

        billing.iban = formik.values.iban?.replace(/\s/g, "");
        billing.bic = formik.values.bic?.replace(/\s/g, "");
        billing.ownerName = formik.values.ownerName;
        billing.bankName = formik.values.bankName;

        props.send(billing);
    }

    const sendProgress = () => {
        if (props.sendProgress)
            props.sendProgress((nbFieldsRequired - Object.keys(formik.errors).length) / nbFieldsRequired * 100);
    }

    useEffect(() => {
        sendProgress();
    }, [])

    useEffect(() => {
        sendProgress();
    }, [formik.values,
        formik.errors])

    useEffect(() => {
        formik.validateForm();
    }, [props.billing])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        formik.validateForm();
    }, [fieldIBANError,
        fieldBicError,
        formik.values.iban])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={3}
                sx={{ textAlign: 'left' }} >

                <Grid
                    item
                    sm={8}
                    xs={12} >
                    <FormLabel error={formik.touched.iban && Boolean(formik.errors.iban)} >
                        <FieldLabel label="IBAN" isRequired />
                    </FormLabel>
                    <TextFieldIBAN
                        plateformStyle={props.plateformStyle}
                        id="iban"
                        fullWidth
                        showInfo
                        onChange={(e, value, error) => {
                            formik.setFieldValue("iban", value, false)
                            setFieldIBANError(error)
                        }}
                        onBlur={(e) => formik.setFieldTouched("iban")}
                        value={formik.values.iban}
                        error={formik.touched.iban && Boolean(formik.errors.iban)}
                        helperText={formik.touched.iban && formik.errors.iban} />
                    {isFieldIbanAlreadyUsed &&
                    <>
                    <FormHelperText>
                        Besoin d'aide ? <Link onClick={() => setIsDialogOpened(true)}>Contactez nos équipes !</Link>
                    </FormHelperText>

                    <DialogContact
                        isOpened={isDialogOpened}
                        plateformStyle={props.plateformStyle}
                        callback={{
                            form: props.form,
                            senderLastname: props.subscriber?.lastname,
                            senderFirstname: props.subscriber?.firstname,
                            senderMail: props.subscriber?.email,
                            senderPhone: props.subscriber?.phoneMobile,
                            subject: "Un contrat GSMC utilise déjà mon IBAN",
                            message: `Bonjour,\n\nMon IBAN (${formik.values.iban}) a déjà été utilisé pour un autre contrat GSMC.\n\nMerci de me recontacter pour débloquer ma demande d'adhésion.`
                        } as ICallback}
                        handleClose={() => {
                            setIsDialogOpened(false);
                        }} />
                    </>}
                </Grid>

                {props.showBic &&
                <>
                <Grid
                    item
                    sm={6}
                    xs={12} >
                    <FormLabel error={formik.touched.bic && Boolean(formik.errors.bic)} >
                        <FieldLabel label="BIC" isRequired />
                    </FormLabel>
                    <TextFieldBIC
                        plateformStyle={props.plateformStyle}
                        id="bic"
                        fullWidth
                        showInfo
                        onChange={(e, value, error) => {
                            formik.setFieldValue("bic", value, false);
                            setFieldBicError(error);
                        }}
                        onBlur={(e) => formik.setFieldTouched("bic")}
                        value={formik.values.bic}
                        error={formik.touched.bic && Boolean(formik.errors.bic)}
                        helperText={formik.touched.bic && formik.errors.bic} />
                </Grid>
                <Grid
                    item
                    sm={6}
                    sx={{
                        display: {
                            sm: 'flex',
                            xs: 'none'
                        }
                    }} >
                </Grid>
                </>}

                {props.showBankName &&
                <Grid
                    item
                    sm={6}
                    xs={12} >
                    <FormLabel error={formik.touched.bankName && Boolean(formik.errors.bankName)} >
                        <FieldLabel label="Nom de la banque" />
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="bankName"
                        name="bankName"
                        value={formik.values.bankName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.bankName && Boolean(formik.errors.bankName)}
                        helperText={formik.touched.bankName && formik.errors.bankName}/>
                </Grid>}

                {props.showOwnerName &&
                <Grid
                    item
                    sm={6}
                    xs={12} >
                    <FormLabel error={formik.touched.ownerName && Boolean(formik.errors.ownerName)} >
                        <FieldLabel label="Titulaire du compte" />
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="ownerName"
                        name="ownerName"
                        value={formik.values.ownerName}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
                        helperText={formik.touched.ownerName && formik.errors.ownerName}/>
                </Grid>}
            </Grid>
        </form>
    )
})

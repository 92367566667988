import { ErrorContent } from './ErrorContent';


export const NotFound = () => {

    return (
        <ErrorContent
            code="404"
            color="warning" />
    )
}

import { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { LoadingButton } from '@mui/lab';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { changePassword, resetPassword } from '../../../api/auth/Authentication.api';
import { IPasswordChangeRequest, PageLoginSteps, passwordMaxLength, passwordMinLength } from '../../../model/auth/Authentication.model';

import { colors } from '../../../static/themes/gsmc/colors';
import { messageFieldRequired } from '../../../utils';
import { TextFieldPassword } from '../../Field/TextFieldPassword';
import Button from '@mui/material/Button';
import FormLabel from '@mui/material/FormLabel';
import { FieldLabel } from '../../Field/FieldLabel';
import FormHelperText from '@mui/material/FormHelperText';


interface Props {
    send: (value: boolean) => void;
}


export const FormPasswordChange = (props: Props) => {

    const [loading, setLoading] = useState<boolean>(false);
    const [formSent, setFormSent] = useState<boolean>(false);

    const getInitialValues = () => {
        return {
            passwordOld: undefined as string | undefined,
            passwordNew: undefined as string | undefined,
            passwordNewConfirm: undefined as string | undefined,
        }
    };

    const validationSchema = yup.object({
        passwordOld: yup.string().required(messageFieldRequired)
            .test('length',
                `Votre mot de passe doit avoir entre ${passwordMinLength} et ${passwordMaxLength} caractères`,
                value => value !== undefined && value !== null && value.length >= passwordMinLength && value.length <= passwordMaxLength),
        passwordNew: yup.string()
            .required(messageFieldRequired)
            .min(passwordMinLength, `Votre mot de passe doit contenir au moins ${passwordMinLength} caractères`)
            .matches(/[0-9]/, 'Votre mot de passe doit contenir au moins un chiffre')
            .matches(/[a-zà-ú]/, 'Votre mot de passe doit contenir au moins un caratère minuscule')
            .matches(/[A-ZÀ-Ú]/, 'Votre mot de passe doit contenir au moins un caratère majuscule')
            .matches(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'Votre mot de passe doit contenir au moins un caratère spécial'),
        passwordNewConfirm: yup.string()
            .required(messageFieldRequired)
            .min(passwordMinLength, `Votre mot de passe doit contenir au moins ${passwordMinLength} caractères`)
            .matches(/[0-9]/, 'Votre mot de passe doit contenir au moins un chiffre')
            .matches(/[a-zà-ú]/, 'Votre mot de passe doit contenir au moins un caratère minuscule')
            .matches(/[A-ZÀ-Ú]/, 'Votre mot de passe doit contenir au moins un caratère majuscule')
            .matches(/[~`¿¡!#$%\^&*€£@+÷=\-\[\]\\';,/{}\(\)|\\":<>\?\.\_]/, 'Votre mot de passe doit contenir au moins un caratère spécial')
            .oneOf([yup.ref('passwordNew')], "Les mots de passe ne sont pas identiques")
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!values.passwordNew || !values.passwordOld)
                return;

            const request: IPasswordChangeRequest = {
                password: values.passwordNew,
                oldPassword: values.passwordOld
            }

            changePassword(request)
            .then(response => {
                props.send(true);
            })
            .catch(error => {
                if (error.message === "404" || error.message === "400")
                    formik.setFieldError("passwordOld", "Mot de passe incorrect")
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={3}>

                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.passwordOld && Boolean(formik.errors.passwordOld)} >
                        <FieldLabel
                            label="Votre mot de passe actuel"
                            fontWeight={500}
                            isRequired />
                    </FormLabel>
                    <TextFieldPassword
                        fullWidth
                        id="passwordOld"
                        placeholder="Votre mot de passe"
                        value={formik.values.passwordOld}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.passwordOld && Boolean(formik.errors.passwordOld)} />

                    {formik.touched.passwordOld && formik.errors.passwordOld &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.passwordOld}
                        </Typography>
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.passwordNew && Boolean(formik.errors.passwordNew)} >
                        <FieldLabel
                            label="Votre nouveau mot de passe"
                            fontWeight={500}
                            isRequired />
                    </FormLabel>
                    <TextFieldPassword
                        fullWidth
                        id="passwordNew"
                        placeholder="Votre mot de passe"
                        value={formik.values.passwordNew}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.passwordNew && Boolean(formik.errors.passwordNew)} />

                    {formik.touched.passwordNew && formik.errors.passwordNew &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.passwordNew}
                        </Typography>
                    </FormHelperText>}

                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'>
                            Votre mot de passe doit contenir au moins 10 caractères dont :
                            <br />- Un chiffre
                            <br />- Une majuscule
                            <br />- Une minuscule
                            <br />- Un cacactère spécial
                        </Typography>
                    </FormHelperText>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.passwordNewConfirm && Boolean(formik.errors.passwordNewConfirm)} >
                        <FieldLabel
                            label="Confirmer votre nouveau mot de passe"
                            fontWeight={500}
                            isRequired />
                    </FormLabel>
                    <TextFieldPassword
                        fullWidth
                        id="passwordNewConfirm"
                        placeholder="Votre mot de passe"
                        value={formik.values.passwordNewConfirm}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.passwordNewConfirm && Boolean(formik.errors.passwordNewConfirm)} />

                    {formik.touched.passwordNewConfirm && formik.errors.passwordNewConfirm &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.passwordNewConfirm}
                        </Typography>
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs="auto"
                    order={{
                        xs: 2,
                        sm: 3
                    }}>
                    <Button
                        type='submit'
                        sx={{
                            height: "auto !important",
                            px: 2,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Modifier
                    </Button>
                </Grid>
                <Grid
                    item
                    xs="auto"
                    order={{
                        xs: 2,
                        sm: 3
                    }}>
                    <Button
                        variant="outlined"
                        onClick={() => props.send(false)}
                        sx={{
                            height: "auto !important",
                            px: 2
                        }} >
                        Annuler
                    </Button>
                </Grid>

            </Grid>
        </form>
    )
}

import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { useFormik } from 'formik';
import * as yup from 'yup';


import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { colors } from '../../../static/themes/gsmc/colors';
import { IUser } from '../../../model/auth/User.model';
import { messageFieldRequired } from '../../../utils/messages';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import { FieldLabel } from '../../Field/FieldLabel';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { updateUserCurrent } from '../../../api/auth/User.api';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import { FormPasswordChange } from '../Auth/FormPasswordChange';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';


interface Props {
    user?: IUser;
    send: (user: IUser) => void;
}


export const FormAccount = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [successPassword, setSuccessPassword] = useState<boolean>(false);
    const [errorPassword, setErrorPassword] = useState<boolean>(false);

    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

    useEffect(() => {
        if (!success) return;
        setTimeout(function () { setSuccess(false); }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error) return;
        setTimeout(function () { setError(false); }, 2000);
    }, [error]);

    useEffect(() => {
        if (!successPassword) return;
        setTimeout(function () { setSuccessPassword(false); }, 2000);
    }, [successPassword]);

    useEffect(() => {
        if (!errorPassword) return;
        setTimeout(function () { setErrorPassword(false); }, 2000);
    }, [errorPassword]);

    const getInitialValues = () => {
        return {
            picture: props.user?.picture,
            email: props.user?.email,
            username: props.user?.username,
            lastname: props.user?.lastname,
            firstname: props.user?.firstname
        }
    };

    const validationSchema = yup.object({
        email: yup.string().required(messageFieldRequired),
        username: yup.string()
            .required(messageFieldRequired)
            .matches(/^[\w\.\-]+$/, "Votre nom de compte ne doit pas contenir d'espace, de caratére accentué ou de caratère spécial (excepté ., - et _)"),
        lastname: yup.string(),
        firstname: yup.string()
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!props.user?.id || !values.email)
                return;  // Should never occur with the yup validation.

            const toSend: IUser = {
                email: values.email,
                username: values.username,
                lastname: values.lastname,
                firstname: values.firstname,
                picture: values.picture,
                isActive: true
            };

            updateUserCurrent(toSend)
            .then((response: any) => {
                if (response.id) {
                    props.send(response);
                    setSuccess(true);
                }

                if (response.errors) {
                    response.errors.map((_: any) => {
                        formik.setFieldError(_.field, _.message);
                    })
                }
            })
            .catch(exception => {
                setError(true);
                Sentry.captureException(exception);

            });
        }
    })


    return (
        <Grid
            container
            spacing={2}
            sx={{
                textAlign: 'left'
            }} >

            <Grid
                item
                xs={12} >
                <form
                    onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Informations
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.username && Boolean(formik.errors.username)} >
                                <FieldLabel
                                    label="Nom du compte"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Le nom du compte doit être unique
                            </Typography>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    fullWidth
                                    id="username"
                                    name="username"
                                    value={formik.values.username}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.username && Boolean(formik.errors.username)}
                                    helperText={formik.touched.username && formik.errors.username}
                                    sx={{
                                        maxWidth: '500px'
                                    }} />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.email && Boolean(formik.errors.email)} >
                                <FieldLabel
                                    label="Email"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                L'email compte doit être unique
                            </Typography>
                            <Grid
                                item
                                xs={12}>
                                <TextField
                                    fullWidth
                                    id="email"
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.email && Boolean(formik.errors.email)}
                                    helperText={formik.touched.email && formik.errors.email}
                                    sx={{
                                        maxWidth: '500px'
                                    }} />
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}>
                            <FormLabel error={formik.touched.lastname && Boolean(formik.errors.lastname)} >
                                <FieldLabel
                                    label="Nom"
                                    fontWeight={500} />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="lastname"
                                name="lastname"
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                helperText={formik.touched.lastname && formik.errors.lastname}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12}>
                            <FormLabel error={formik.touched.firstname && Boolean(formik.errors.firstname)} >
                                <FieldLabel
                                    label="Prénom"
                                    fontWeight={500} />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="firstname"
                                name="firstname"
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                helperText={formik.touched.firstname && formik.errors.firstname}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Button
                                type='submit'
                                sx={{
                                    height: "auto !important",
                                    px: 2,
                                    color: 'white',
                                    backgroundColor: colors.black.main,
                                    ':hover': {
                                        backgroundColor: 'black'
                                    }
                                }} >
                                Enregistrer
                            </Button>

                            <Typography
                                component='span'
                                sx={{
                                    display: success ? 'inline' : 'none',
                                    ml: 2,
                                    color: colors.green.main
                                }}>
                                <CheckCircleIcon
                                    fontSize='small'
                                    sx={{
                                        verticalAlign: 'middle',
                                        mr: 1,
                                        mb: 0.5
                                    }} />
                                <Typography
                                    component='span'
                                    sx={{
                                        display: {
                                            sm: 'inline',
                                            xs: 'none'
                                        }
                                    }}>
                                    Enregistrement réussi
                                </Typography>
                            </Typography>

                            <Typography
                                component='span'
                                sx={{
                                    display: error ? 'inline' : 'none',
                                    ml: 2,
                                    color: colors.red.main
                                }}>
                                <CancelIcon
                                    fontSize='small'
                                    sx={{
                                        verticalAlign: 'middle',
                                        mr: 1,
                                        mb: 0.5
                                    }} />
                                <Typography
                                    component='span'
                                    sx={{
                                        display: {
                                            sm: 'inline',
                                            xs: 'none'
                                        }
                                    }}>
                                    Erreur lors de l'enregistrement
                                </Typography>
                            </Typography>
                        </Grid>
                    </Grid>
                </form>
            </Grid>

            <Grid
                item
                xs={12} >
                <Divider light />
            </Grid>

            <Grid
                item
                xs={12} >
                <Grid
                    container
                    spacing={2} >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mb: 1
                        }} >
                        <Typography
                            variant='h3'>
                            Sécurité
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <FormLabel>
                            <FieldLabel
                                label="Mot de passe"
                                fontWeight={500}/>
                        </FormLabel>
                        <Button
                            onClick={() => setIsDialogOpened(true)}
                            sx={{
                                height: "auto !important",
                                px: 2,
                                color: 'white',
                                backgroundColor: colors.black.main,
                                ':hover': {
                                    backgroundColor: 'black'
                                }
                            }} >
                            Modifier mon mot de passe
                        </Button>
                            <Typography
                                component='span'
                                sx={{
                                    display: successPassword ? 'inline' : 'none',
                                    ml: 2,
                                    color: colors.green.main
                                }}>
                                <CheckCircleIcon
                                    fontSize='small'
                                    sx={{
                                        verticalAlign: 'middle',
                                        mr: 1,
                                        mb: 0.5
                                    }} />
                                <Typography
                                    component='span'
                                    sx={{
                                        display: {
                                            sm: 'inline',
                                            xs: 'none'
                                        }
                                    }}>
                                    Enregistrement réussi
                                </Typography>
                            </Typography>

                            <Typography
                                component='span'
                                sx={{
                                    display: errorPassword ? 'inline' : 'none',
                                    ml: 2,
                                    color: colors.red.main
                                }}>
                                <CancelIcon
                                    fontSize='small'
                                    sx={{
                                        verticalAlign: 'middle',
                                        mr: 1,
                                        mb: 0.5
                                    }} />
                                <Typography
                                    component='span'
                                    sx={{
                                        display: {
                                            sm: 'inline',
                                            xs: 'none'
                                        }
                                    }}>
                                    Erreur lors de l'enregistrement
                                </Typography>
                            </Typography>

                        <Dialog
                            fullWidth
                            maxWidth="sm"
                            open={isDialogOpened}
                            onClose={(event, reason) => {
                                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                                    return;

                                setIsDialogOpened(false)
                            }}
                            sx={{
                                borderRadius: 40
                            }} >

                            <DialogTitle>
                                <Typography variant="h2">Modifier votre mot de passe</Typography>
                            </DialogTitle>
                            <DialogContent>
                                <FormPasswordChange
                                    send={(_) => {
                                        if (_) setSuccessPassword(true)

                                        setIsDialogOpened(false)}
                                    } />
                            </DialogContent>
                        </Dialog>
                    </Grid>
                </Grid>
            </Grid>

            {props.user?.plateformes &&
            <>
            <Grid
                item
                xs={12} >
                <Divider light />
            </Grid>

            <Grid
                item
                xs={12} >
                <Grid
                    container
                    spacing={2} >
                    <Grid
                        item
                        xs={12}
                        sx={{
                            mb: 1
                        }} >
                        <Typography
                            variant='h3'>
                            Partenaires
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12} >
                        <FieldLabel
                            label="Partenaires associés"
                            fontWeight={500} />
                        <Typography
                            variant='caption'
                            component='div'
                            fontSize='0.9rem'
                            sx={{
                                mb: 1
                            }}>
                            Votre compte est associé aux partenaires suivant :
                        </Typography>

                        {props.user.plateformes.map((_, id) =>
                            <Stack
                                direction="column"
                                justifyContent="flex-start"
                                alignItems="flex-start"
                                spacing={2}>
                                <Chip
                                    label={_.name}
                                    sx={{
                                        mb: 1,
                                        fontWeight: 700
                                    }}/>
                            </Stack>
                        )}
                    </Grid>
                </Grid>
            </Grid>
            </>}
        </Grid>
    )
}

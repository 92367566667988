import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import CustomizedDialogs from '../../../components/Dialog/CustomizedDialog';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';

import {  ISegment } from '../../../model';
import { createSegment, getSegments, modifySegment } from '../../../api/Segment.api';

import { messageFieldRequired } from '../../../utils/messages';

import { colors } from '../../../static/themes/gsmc/colors';


interface IDialogProps {
    open: boolean;
    segment?: ISegment;
    handleClose: () => void;
    handleError: () => void;
}


const initialValues = {
    code: undefined as undefined | string,
    label: undefined as undefined | string,
    isActive: true as boolean
}


const DialogSegment = (props: IDialogProps) => {

    useEffect(() => {
        formik.resetForm();

        if (props.segment !== undefined) {
            const values = {
                code: props.segment.codeSegment,
                label: props.segment.libelleSegment,
                isActive: props.segment.estActif
            };

            formik.setValues(values);
        }
        else {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.segment])

    const validationSchema = yup.object({
        code: yup.string().required(messageFieldRequired),
        label: yup.string().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(props.segment) {
                const toSend : ISegment = {
                    id: props.segment.id,
                    techDateInsertion: props.segment.techDateInsertion,
                    codeSegment: values.code as string,
                    libelleSegment: values.label as string,
                    estActif: values.isActive
                }
                modifySegment(toSend)
                .then((response : ISegment )=> {
                    if(response.id) {
                        props.handleClose();
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
            else {
                const toSend : ISegment = {
                    codeSegment: values.code as string,
                    libelleSegment: values.label as string,
                    estActif: values.isActive
                }

                createSegment(toSend)
                .then((response : ISegment )=> {
                    if(response.id) {
                        props.handleClose();
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
        }
    })


    return (
        <CustomizedDialogs
            title="Segment"
            open={props.open}
            handleClose={props.handleClose}>
            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="code"
                                name="code"
                                label={"Code*"}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="label"
                                name="label"
                                label={"Libelle*"}
                                value={formik.values.label}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.label && Boolean(formik.errors.label)}
                                helperText={formik.touched.label && formik.errors.label} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <span><strong>Actif</strong></span>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus type="submit">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </CustomizedDialogs>
    )
}


export const SegmentList = () => {
    document.title = 'BO - Segments';

    const [segment, setSegment] = useState<ISegment | undefined>();
    const [segments, setSegments] = useState<ISegment[] | undefined | null>(undefined);
    const [segmentsCount, setSegmentsCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleClickModify = (segment: ISegment) => {
        setSegment(segment);
        setOpen(true);
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        getSegments(pageNumber, rowPerPage, search).then(response => {
            setSegments(response.content);
            setSegmentsCount(response.totalElements);
        })
        .catch((exception) => {
            setSegments(null);
            setSegmentsCount(0);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, rowPerPage])

    const forceRefresh = () => {
        getSegments(pageNumber, rowPerPage, search).then(response => {
            setSegments(response.content);
            setSegmentsCount(response.totalElements);
        })
        .catch((exception) => {
            setSegments(null);
            setSegmentsCount(0);
        })
        setOpen(false);
        setSegment(undefined);
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "LINK", label: "Segment"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <DialogSegment
                open={open}
                segment={segment}
                handleClose={() => forceRefresh()}
                handleError={() => setIsSnackbarOpened(true)} />

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2} >
                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                        <IconButton
                            onClick={() => setOpen(true)}>
                            <AddCircleIcon color="primary"/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell align="center">Actif</TableCell>
                                        <TableCell
                                            key={'outil'}
                                            sx={{
                                                minWidth: '20px'
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {segments === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des segments en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : segments === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des segments
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : segments.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={4}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucun segment
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : segments.map((_, id) => {
                                        return (
                                            <TableRow key={id}>
                                                <TableCell>{_.codeSegment}</TableCell>
                                                <TableCell>{_.libelleSegment}</TableCell>
                                                <TableCell align="center">
                                                    {_.estActif ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        width: '20px'
                                                    }}>
                                                    <IconButton
                                                        onClick={() => handleClickModify(_)}>
                                                        <CreateIcon
                                                            sx={{
                                                                color: colors.blue.main
                                                            }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={segmentsCount}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >Erreur lors de l'enregistrement du segment.<br />⚠️  Le code doit être unique.</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

import { IFormule } from "../model/Formule.model"
import {
    ITarificationContextDTO,
    ITarificationGsmcRequest,
    ITarificationHennerXmlRequest,
    ITarificationsControlResponse
} from "../model/Tarification"


const apiBaseUrl = process.env.REACT_APP_API_URL
const apiUrl = apiBaseUrl + '/tunnel'


export const getTarifFormule = (uuid: String): Promise<IFormule[]> => {

    return fetch(`${apiUrl}/form/${uuid}/tarification`,
    {
        method: "POST",
        headers:{"content-type": "application/json"}
    }).then(_ => _.json())
}


export const getTarifContext = (payload: ITarificationContextDTO): Promise<IFormule[]> => {

    return fetch(`${apiUrl}/form/${payload.uuid}/tarification/context`,
    {
        method: "POST",
        body: JSON.stringify(payload),
        headers: {"content-type": "application/json"}
    }).then(_ => _.json())
}


export const getTarifFormules = (uuid: String): Promise<IFormule[]> => {

    return fetch(`${apiUrl}/form/${uuid}/tarification/all`,
    {
        method: "POST",
        headers:{"content-type": "application/json"}
    }).then(_ => _.json())
}

export const postTarificationControl = (payload: ITarificationGsmcRequest) : Promise<ITarificationsControlResponse> => {
    const token = process.env.REACT_APP_TARIFICATION_TOKEN;

    return fetch(`${apiBaseUrl}/tarification/control`,
        {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`}
        }).then(_ => _.json())
}

export const convertHennerContext = (payload: ITarificationHennerXmlRequest): Promise<ITarificationGsmcRequest> => {
    return fetch(`${apiBaseUrl}/tunnel/tarification/convert-gsmc-format`,
        {
            method: "POST",
            body: JSON.stringify(payload),
            headers: {
                "content-type": "application/json"}
        }).then(_ => _.json())
}

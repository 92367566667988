
import { FormPasswordForgotten } from "../../components/Form/Auth/FormPasswordForgotten";


export const PasswordForgotten = () => {

    return (
        <FormPasswordForgotten />
    )
}

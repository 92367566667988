import { RefObject, useEffect, useMemo, useRef, useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { CardOffer } from '../../components/Card/CardOffer';
import { DocumentDisplay } from '../../components/Document/DocumentDisplay';
import { DocumentsDownload } from '../../components/Document/DocumentsDownload';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { InfoIcon } from '../../components/Icon/InfoIcon';

import { DownloadIcon } from '../../components/Icon/DownloadIcon';

import { IFormule } from '../../model/Formule.model';

import { enumFormDocumentTypeAttestationCPAM, enumFormDocumentTypeBulletin,
         enumFormDocumentTypeCniRecto,
         enumFormDocumentTypeCniVerso,
         enumFormDocumentTypeKbis,
         enumFormDocumentTypeRIB } from '../../utils';
import Box from '@mui/material/Box';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import useOnScreen from '../../utils/hooks/useOnScreen';
import FormControlLabel from '@mui/material/FormControlLabel';


interface Props {
    plateformStyle: any;
    isUpdated: boolean;
    sidebar?: JSX.Element;
    formUuid: string;
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormContractDisplay = (props: Props) => {

    const refCheckbox = useRef<any>(null)
    const refButtonSubmit = useRef<any>(null)
    const isVisible = useOnScreen(refButtonSubmit, {threshold: 1})

    const plateformStyle: any = props.plateformStyle;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;
    const iconInfoStyle: any = plateformStyle.components.IconInfo;

    const [downloadAllFlag, setDownloadAllFlag] = useState<number>(0);
    const [downloading, setDownloading] = useState<boolean>(false);

    const [isChecked, setIsChecked] = useState(props.isUpdated);
    const [showCheckError, setShowCheckError] = useState(false);

    const handleCheckBoxClick = (checked: boolean) => {

        if (checked)
            setShowCheckError(false);

        setIsChecked(checked);
    };

    const scrollTo = (ref: any) => {
        if (ref.current)
            (ref.current as any).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
    }


    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        props.goToPrevious();
    }, [props.goToPreviousFlag])


    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Contrat" />}
            sidebar={props.sidebar}
            content={
                <Grid
                    container
                    spacing={2} >
                    <Grid
                        item
                        xs={12} >
                        <Card
                            sx={{
                                p: 0
                            }} >
                            {downloading &&
                            <LinearProgress
                                color='primary' />
                            }

                            <DocumentDisplay
                                plateformStyle={plateformStyle}
                                formUuid={props.formUuid}
                                sendDownloading={setDownloading}
                                onClickSecondary={
                                    !isVisible ? () => scrollTo(refCheckbox) : undefined
                                } />
                        </Card>
                    </Grid>
                    
                    <Grid
                        item
                        xs={12} >
                        {!downloading &&
                        <>
                        <FormControlLabel
                            ref={refCheckbox}
                            control={
                                <Checkbox
                                    checked={isChecked}
                                    onChange={e => handleCheckBoxClick(e.target.checked)}
                                    color="success"
                                    sx={{
                                        color: showCheckError ? plateformStyle.colors.red.main : plateformStyle.colors.black.secondary,
                                        pt: 0,
                                        '& .MuiSvgIcon-root': {
                                            fontSize: 32
                                        }
                                    }} />}
                            label={
                                <Typography
                                    fontWeight={500}
                                    color={showCheckError ? "error" : "inherit"}>
                                    Je reconnais avoir pris connaissance des documents d'informations et contractuels disponibles ci-dessus et vérifié l'exactitude des informations sur mon mandat SEPA.
                                </Typography>
                            }/>

                        {showCheckError &&
                        <FormHelperText
                            error={true}
                            sx={{
                                ml: 5,
                                mb: 1
                            }} >
                            Vous devez avoir pris connaissance des documents pour continuer
                        </FormHelperText>}</>}
                        <Box
                            sx={{
                                position: {
                                    md: "relative",
                                    xs: "sticky"
                                },
                                bottom: '0px',
                                width: '100%',
                                textAlign: 'center',
                                py: 4
                            }}>

                            <Button
                                ref={refButtonSubmit}
                                variant='contained'
                                color={buttonSubmitStyle.color}
                                disabled={downloading}
                                sx={{
                                    width: {
                                        sm: 400,
                                        xs: '100%'
                                    },
                                    px: 5,
                                    color: 'white'
                                }}
                                onClick={() => {
                                    scrollTo(refCheckbox);

                                    if (!isChecked)
                                        setShowCheckError(true);
                                    else
                                        props.goToNext();
                                }} >
                                Signer votre contrat
                            </Button>
                        </Box>
                    </Grid>

                    <Grid
                        item
                        xs={12} >

                        <Card
                            sx={{
                                p: 2
                            }} >

                            <FormHeaderSection label="Documents Complémentaires" />

                            <Card
                                sx={{
                                    p: 2,
                                    mt: 2,
                                    mb: 4,
                                    backgroundColor: iconInfoStyle.bgColor
                                }} >
                                <Grid
                                    container
                                    alignItems='center'
                                    wrap='nowrap'
                                    spacing={2} >
                                    <Grid
                                        item >
                                        <InfoIcon color={iconInfoStyle.color} />
                                    </Grid>
                                    <Grid
                                        item
                                        sx={{
                                            my: 'auto'
                                        }} >
                                        <Typography
                                            component='span'
                                            fontWeight={500}
                                            sx={{
                                                my: 'auto'
                                            }} >
                                            Ces documents seront aussi disponibles en fin de souscription.
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>

                            <DocumentsDownload
                                plateformStyle={plateformStyle}
                                isCompact
                                formUuid={props.formUuid}
                                downloadAllFlag={downloadAllFlag}
                                typesExcluded={[enumFormDocumentTypeCniRecto,
                                                enumFormDocumentTypeCniVerso,
                                                enumFormDocumentTypeRIB,
                                                enumFormDocumentTypeBulletin,
                                                enumFormDocumentTypeAttestationCPAM,
                                                enumFormDocumentTypeKbis]}  /* Hide the subscriber documents and the contract */ />
                        </Card>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }} >
                            <Button
                                sx={{
                                    py: 1,
                                    color: plateformStyle.colors.black.main,
                                    transition: "transform 400ms",
                                    ':hover': {
                                        transform: "translateY(-6px)"
                                    }
                                }}
                                startIcon={<DownloadIcon color={plateformStyle.colors.black.main } /> }
                                onClick={() => setDownloadAllFlag(downloadAllFlag + 1)} >
                                Tout télécharger
                            </Button>
                    </Grid>
                </Grid>
            } />
    )
}

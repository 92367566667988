import { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DialogContact } from '../Dialog/DialogContact';
import { ArrowRightIcon } from '../Icon/ArrowRightIcon';
import { ContactMailBackgroundIcon } from '../Icon/ContactMailBackgroundIcon';

import { IForm } from '../../model/Form.model';
import { IPerson } from '../../model/Person.model';
import { IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';

import { enumContactMethodForm, enumContactMethodRedirect } from '../../utils';
import { ICallback } from '../../model/Callback.model';

interface Props {
    plateformStyle: any;
    form?: IForm;
    person?: IPerson;
    settings: IPlateformeSettingsContact;
}


export const CardContact = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const cardStyle: any = plateformStyle.components.Card.contact;

    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

    const handleMailTo = () => {
        const link = document.createElement('a');

        link.href = 'mailto:' + props.settings.contactMail;
        link.click();

        document.body.appendChild(link);
    }

    const handleAction = () => {

        if (props.settings.contactMethod === enumContactMethodRedirect &&
            props.settings.contactUri && props.settings.contactUri !== null) {
            window.open(props.settings.contactUri, '_blank');
            return;
        }

        if (props.settings.contactMethod === enumContactMethodForm) {
            setIsDialogOpened(true);
            return;
        }

        return;
    }


    return (
        <Card
            sx={{
                display: !props.settings.displayContactCard ? 'none' : 'block',
                position: 'relative',
                zIndex: 100,
                height: '100%',
                backgroundColor: cardStyle.bgColor
            }} >
            <CardMedia
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: -90,
                    height: '85%',
                    pl: 1,
                    pt: 2
                }} >
                <ContactMailBackgroundIcon color={cardStyle.color} width='100%' height='100%' />
            </CardMedia>
            <CardContent
                sx={{
                    "&:last-child": {
                        pb: 1
                    }
                }}>
                <Grid
                    container
                    spacing={1}>
                    <Grid
                        item
                        xs={12}>
                        <Typography variant='h2'>Envoyez-nous un message !</Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        {props.settings.displayContactMail && props.settings.contactMail && props.settings.contactMail !== null &&
                        <Typography
                            variant='body1'
                            fontWeight={500}>
                            Par mail :
                            <br />
                            <span
                                style={{
                                    textDecoration:'underline',
                                    cursor:'pointer'
                                }}
                                onClick={() => handleMailTo()}>
                                {props.settings.contactMail}
                            </span>
                        </Typography>}
                    </Grid>

                    {props.settings.displayContactAction &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            pl: "0px !important"
                        }}>
                        <Button
                            onClick={() => handleAction()}
                            sx={{
                                py: 1,
                                textAlign: 'left',
                                color: plateformStyle.colors.black.main,
                                transition: "transform 400ms",
                                ':hover': {
                                    transform: "translateY(-6px)"
                                }
                            }}
                            endIcon={<ArrowRightIcon color={plateformStyle.colors.black.main} />} >
                            J'envoie un email
                        </Button>
                    </Grid>}
                </Grid>
            </CardContent>

            {props.settings.displayContactAction &&
             props.settings.contactMethod === enumContactMethodForm &&
            <DialogContact
                isOpened={isDialogOpened}
                plateformStyle={props.plateformStyle}
                callback={{
                    form: props.form,
                    senderLastname: props.person?.lastname,
                    senderFirstname: props.person?.firstname,
                    senderMail: props.person?.email,
                    senderPhone: props.person?.phoneMobile
                } as ICallback}
                handleClose={() => {
                    setIsDialogOpened(false);
                }} />}
        </Card>
    )
}

import { useEffect } from 'react';

import * as yup from 'yup';
import { FormikErrors, useFormik } from 'formik'

import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { FormHeaderSection } from './FormHeaderSection';
import { FormRadioButton } from './FormRadioButton';

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldFormatted } from '../Field/TextFieldFormatted';
import { HrSubscriberIcon } from '../Icon/HrSubscriberIcon';

import { getPlateformeViaTunnel } from '../../api/Plateforme.api';
import { postWebhook } from '../../api/Webhook.api';

import { IWebhook } from '../../model/Webhook.model';

import { enumGsmcRegimeIndep, enumGsmcRegimes, enumPlateformCodeGSMC, messageFieldRequired } from '../../utils';
import { convertUTCDateToLocalDate } from '../../utils/dateUtils';

import { colors } from '../../static/themes/colors';


interface Props {
    sendContextFlag: number;
    send: (uuid: string) => void;
    sendProgress?: (progress: number) => void;
}


interface FormValues {
    dateContract?: Date | null;
    dateBirth?: Date | null;
    regime?: { label: string, value: string };
    postalCode?: string;
}

const dateToday = new Date();

const dateTomorrow = new Date(dateToday);
dateTomorrow.setDate(dateTomorrow.getDate() + 1);

const dateContractMax = new Date(dateToday);
dateContractMax.setFullYear(dateContractMax.getFullYear() + 1);

const dateBirthMin = new Date(dateToday);
dateBirthMin.setFullYear(dateBirthMin.getFullYear() - 200);


const nbFields = 4;
const validationSchema = yup.object({
    dateContract: yup
        .date()
        .typeError("Date non valide")
        .nullable()
        .min(new Date(), "La date doit être supérieure à la date d'aujourd'hui")
        .max(dateContractMax, "La date doit être inférieure à un an")
        .required(messageFieldRequired),
    dateBirth: yup
        .date()
        .typeError("Date non valide")
        .nullable()
        .min(dateBirthMin, "Date non valide")
        .max(new Date(), "Date non valide")
        .required(messageFieldRequired),
    regime: yup
        .object()
        .required(messageFieldRequired),
    postalCode: yup
        .string()
        .required(messageFieldRequired),
});


export const FormContext = (props: Props) => {

    const formik = useFormik({
        initialValues: {
            dateContract: dateTomorrow,
            dateBirth: null,
            regime: undefined as { value: string, label: string } | undefined,
            postalCode: undefined
        },
        validationSchema: validationSchema,
        validate: (values: FormValues) => {
            let errors: FormikErrors<FormValues> = {};

            if (values.dateBirth) {
                let dateDelta: number = new Date().getTime() - values.dateBirth.getTime();

                if (new Date(dateDelta).getFullYear() - 1970 < 18)
                    errors.dateBirth = "Vous devez être majeur pour souscrire"

                else if (!formik.errors.dateBirth) {
                    if (new Date(dateDelta).getFullYear() - 1970 > 70 && values.regime?.value === enumGsmcRegimeIndep)
                        errors.regime = "Vous devez avoir moins de 70 ans pour sélectionner ce regime"
                }
            }

            return errors;
        },
        onSubmit: (values) => {

            getPlateformeViaTunnel(enumPlateformCodeGSMC)
            .then((response: string) => {
                if (response === undefined)
                    return;

                if (values.dateBirth === null || !values.regime || !values.postalCode)
                    return;

                let context: IWebhook = {
                    offer: {
                        codeOffer: getOffer(values.dateBirth, values.regime.value)
                    },
                    contract: {
                        dateStart: convertUTCDateToLocalDate(values.dateContract)
                    },
                    subscriber: {
                        dateBirth: convertUTCDateToLocalDate(values.dateBirth),
                        regime: values.regime.value,
                        adress: {
                            postalCode: values.postalCode
                        },
                    }
                };

                return postWebhook(context, response);
            })
            .then((response: any) => {
                if (response !== undefined)
                    props.send(response.replace(/\"/g, ""));  // FIXME : Returns "\"uuid\"".
            });
        }
    });

    const getOffer = (dateBirth: Date, regime: string) => {

        let dateDelta: number = new Date().getTime() - dateBirth.getTime();

        if (new Date(dateDelta).getFullYear() - 1970 < 18)
            return "";

        if (regime === enumGsmcRegimeIndep) {  // Régime des indépendants.
            if (new Date(dateDelta).getFullYear() - 1970 > 70)  // 70 ans max.
                return "";

            return "539";
        }

        if (new Date(dateDelta).getFullYear() - 1970 < 66)  // Autres régimes.
            return "512";

        return "312";
    }

    const sendProgress = () => {
        if (props.sendProgress)
            props.sendProgress((Object.keys(formik.values).length - Object.keys(formik.errors).length) / nbFields * 100);
    }

    useEffect(() => {
        if (props.sendContextFlag !== 0)
            formik.handleSubmit();
    }, [props.sendContextFlag])

    useEffect(() => {
        formik.validateForm();
    }, [formik.values.regime])

    useEffect(() => {
        sendProgress();
    }, [])

    useEffect(() => {
        sendProgress();
    }, [formik.values,
    formik.errors])

    const handleClickPhone = () => {
        const link = document.createElement('a');
        link.href = 'tel:0320476200';
        link.click();
        document.body.appendChild(link);
    }

    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={2}
                sx={{
                    p: 2,
                    textAlign: 'left'
                }} >
                <Grid
                    item
                    xs={12}>
                    <FormHeaderSection label="Le contrat" />
                </Grid>

                <Grid
                    item
                    sm={6}
                    xs={12}>
                    <FormLabel error={formik.touched.dateContract && Boolean(formik.errors.dateContract)} >
                        <FieldLabel label="Date d'effet du contrat" isRequired />
                    </FormLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            disablePast
                            value={formik.values.dateContract}
                            onChange={(value: any) => formik.setFieldValue("dateContract", value)}
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            minDate={dateTomorrow}
                            maxDate={dateContractMax}
                            format='dd/MM/yyyy'
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '100%'
                                    },
                                    onBlur: (e) => formik.setFieldTouched("dateContract"),
                                    error: formik.touched.dateContract && Boolean(formik.errors.dateContract),
                                    helperText: formik.touched.dateContract && <>{formik.errors.dateContract}</>,
                                },
                            }} />
                    </LocalizationProvider>

                    {formik.touched.dateContract &&
                        !formik.errors.dateContract &&
                        formik.values.dateContract !== null &&
                        formik.values.dateContract.getFullYear() === dateToday.getFullYear() + 1 &&
                        (dateToday.getMonth() < 8 || (dateToday.getMonth() === 8 && dateToday.getDate() < 15)) &&
                    <FormHelperText>
                        Ce tarif ne tient pas compte des potentielles augmentations tarifaires au 1er janvier pour l’année à venir.
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormHeaderSection
                        label="Adhérent principal"
                        icon={<HrSubscriberIcon color={colors.orange.main} bgColor={colors.orange.secondary} />} />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.regime && Boolean(formik.errors.regime)} >
                        <FieldLabel label="Régime de Sécurité sociale" isRequired />
                    </FormLabel>
                    <Grid
                        container
                        spacing={2} >
                        {enumGsmcRegimes.map(regime => {
                            return <Grid
                                item
                                sm={4}
                                xs={12} >
                                <FormRadioButton
                                    code={regime.value}
                                    selected={formik.values.regime?.value === regime.value}
                                    onClick={(value) => {
                                        formik.setFieldValue("regime", { value: value, lavel: regime.label });
                                        formik.setFieldTouched("regime");
                                    }}>
                                    {regime.label}
                                </FormRadioButton>
                            </Grid>
                        })}
                        <FormHelperText
                            error={formik.touched.regime && Boolean(formik.errors.regime)} >
                            {formik.touched.regime && formik.errors.regime}
                        </FormHelperText>
                    </Grid>
                    <FormHelperText
                        sx={{
                            textDecoration: 'underline',
                            cursor: 'pointer'
                        }}
                        onClick={() => handleClickPhone()}>
                        Vous ne trouvez pas votre régime social ? Contactez-nous
                    </FormHelperText>
                </Grid>

                <Grid
                    item
                    sm={6}
                    xs={12} >
                    <FormLabel error={formik.touched.dateBirth && Boolean(formik.errors.dateBirth)} >
                        <FieldLabel label="Date de naissance" isRequired />
                    </FormLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            value={formik.values.dateBirth}
                            onChange={(value: any) => formik.setFieldValue("dateBirth", value)}
                            maxDate={dateToday}
                            format='dd/MM/yyyy'
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '100%'
                                    },
                                    onBlur: (e) => formik.setFieldTouched("dateBirth"),
                                    error: formik.touched.dateBirth && Boolean(formik.errors.dateBirth),
                                    helperText: formik.touched.dateBirth && <>{formik.errors.dateBirth}</>,
                                },
                            }} />
                    </LocalizationProvider>
                </Grid>

                <Grid
                    item
                    sm={6}
                    xs={12} >
                    <FormLabel error={formik.touched.postalCode && Boolean(formik.errors.postalCode)} >
                        <FieldLabel label="Code postal" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="postalCode"
                        fullWidth
                        onChange={(e, value) => formik.setFieldValue("postalCode", value)}
                        value={formik.values.postalCode}
                        error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                        helperText={formik.touched.postalCode && formik.errors.postalCode}
                        charactersEnabled="[^0-9A-z\-\s]"
                        textLength={100} />
                </Grid>
            </Grid>
        </form>
    )
}

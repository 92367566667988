import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldPhone } from '../Field/TextFieldPhone';

import { messageFieldRequired } from '../../utils/messages';
import { checkPersonPhone } from '../../api/Person.api';
import { IPerson } from '../../model/Person.model';
import FormHelperText from '@mui/material/FormHelperText';
import Link from '@mui/material/Link';
import { DialogContact } from '../Dialog/DialogContact';
import { ICallback } from '../../model/Callback.model';
import { IForm } from '../../model/Form.model';


interface Props {
    plateformStyle: any
    form?: IForm
    subscriber: IPerson
    isControlFraud?: boolean
    send: (subscriberPhone: string) => void;
    goToNext: () => void;
}


export const FormSignSend = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {

            validatePhone();

            if (!phone || phoneError)
                return false;

            props.send(phone);
            return true;
        },
        leave() {
            if (!phone) return
            props.send(phone);
        }
    }));

    const [phone, setPhone] = useState<string | undefined>(props.subscriber?.phoneMobile);
    const [phoneError, setPhoneError] = useState<string | undefined>();
    const [isFieldPhoneAlreadyUsed, setIsFieldPhoneAlreadyUsed] = useState<boolean>(false);
    const [lastPhoneChecked, setLastPhoneChecked] = useState<string>();
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

    const validatePhone = () => {

        if (!phone) {
            setPhoneError(messageFieldRequired);
            return;
        }

        if (!phone.match(/((\+?)33|32)(\s*\d){9}\s*$/g)) {
            setPhoneError("Format de téléphone non valide");
            return;
        }

        if (props.subscriber.id && props.isControlFraud) {
            if (lastPhoneChecked === phone) {
                setIsFieldPhoneAlreadyUsed(true)
                setPhoneError("Un contrat GSMC a déjà été signé avec ce numéro");
                return;
            }

            setLastPhoneChecked(phone)

            checkPersonPhone(props.subscriber.id, phone)
            .then((response: IPerson) => {
                if (!response.id) {
                    setIsFieldPhoneAlreadyUsed(true)
                    setPhoneError("Un contrat GSMC a déjà été signé avec ce numéro");
                    return;
                }

                return;
            })

        }
    }

    useEffect(() => {
        validatePhone();
    }, [phone])


    return (
        <Grid
            container
            spacing={3}
            sx={{ textAlign: 'left' }} >
            <Grid
                item
                xs={12}
                sm={6} >
                <FormLabel error={phoneError !== undefined} >
                    <FieldLabel label="N° de téléphone" isRequired />
                </FormLabel>
                <TextFieldPhone
                    id="phone"
                    fullWidth
                    value={phone}
                    onChange={(value) => {
                        setIsDialogOpened(false)
                        setIsFieldPhoneAlreadyUsed(false)
                        setPhone(value);
                        setPhoneError(undefined);
                    }}
                    error={phoneError !== undefined}
                    helperText={phoneError} />
                    {isFieldPhoneAlreadyUsed &&
                    <>
                    <FormHelperText>
                        Besoin d'aide ? <Link onClick={() => setIsDialogOpened(true)}>Contactez nos équipes !</Link>
                    </FormHelperText>

                    <DialogContact
                        isOpened={isDialogOpened}
                        plateformStyle={props.plateformStyle}
                        callback={{
                            form: props.form,
                            senderLastname: props.subscriber?.lastname,
                            senderFirstname: props.subscriber?.firstname,
                            senderMail: props.subscriber?.email,
                            senderPhone: props.subscriber?.phoneMobile,
                            subject: "Un contrat GSMC a déjà été signé avec mon numéro de téléphone",
                            message: `Bonjour,\n\nMon numéro de téléphone (${phone}) a déjà été utilisé pour signer un contrat GSMC.\n\nMerci de me recontacter pour débloquer ma demande d'adhésion.`
                        } as ICallback}
                        handleClose={() => {
                            setIsDialogOpened(false);
                        }} />
                    </>}
            </Grid>
        </Grid>
    )
})

import * as ReactDOMClient from 'react-dom/client';
import { BrowserRouter as Router } from "react-router-dom";

import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';

// MSAL imports
import { PublicClientApplication, EventType, EventMessage, AuthenticationResult } from "@azure/msal-browser";
import { msalConfig } from "./utils/authConfig";

export const msalInstance = new PublicClientApplication(msalConfig);


const sentry_enabled = process.env.REACT_APP_SENTRY_ENABLED === '1'

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: sentry_enabled,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT
});


// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = msalInstance.getAllAccounts();
if (accounts.length > 0) {
    msalInstance.setActiveAccount(accounts[0]);
}

msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const payload = event.payload as AuthenticationResult;
        const account = payload.account;
        msalInstance.setActiveAccount(account);
    }
});


const rootElement = document.getElementById("root");

const root = ReactDOMClient.createRoot(rootElement as HTMLElement);

root.render(
    <Router>
        <App pca={msalInstance} />
    </Router>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import { useEffect, useState } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { TextFieldFormatted } from './TextFieldFormatted';
import { InfoIcon } from '../Icon/InfoIcon';
import { DialogInfoNIR } from '../Dialog/DialogInfoNIR';


interface Props {
    plateformStyle?: any;
    id: string;
    label?: string | JSX.Element;
    value: string | undefined;
    showInfo?: boolean;
    onChange: (event: React.ChangeEvent | undefined, value: string, error: string) => void;
    onBlur?: (e: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
    fullWidth?: boolean;
}


export const pattern: number[] = [1, 3, 5, 7, 10, 13, 15];
export const length: number = 15;
export const separator: string = " ";


function checkNIR(value: string): string {

    let cleanValue: string;

    if (!value)
        return "";

    cleanValue = value.replace(new RegExp(separator, "g"), "");

    // Check the first character.
    if (!cleanValue.match(/^[1-2]/g))
        return "NIR non valide";

    // Check the NIR month.
    if (parseInt(cleanValue.substring(3, 5)) > 12)
        return "NIR non valide";

    // Check the NIR key.
    if (97 - parseInt(cleanValue.substring(0, 13)) % 97 !== parseInt(cleanValue.substring(13, 15)))
        return "NIR non valide";

    return "";
}


export const TextFieldNIR = (props: Props) => {

    const iconStyle: any = props.plateformStyle?.components.IconHeader;

    const [isDialogOpened, setIsDialogOpened] = useState(false);

    useEffect(() => {
        if (props.value)
            props.onChange(undefined, props.value, checkNIR(props.value));
    }, [])

    const onChange = (event: React.ChangeEvent, value: string) => {
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
        let error: string;

        value = target.value;

        error = checkNIR(value);

        props.onChange(event, value, error);
    }


    return (
        <Grid
            container
            alignItems="center"
            gap={1}>
            <Grid
                item
                xs>
                <TextFieldFormatted
                    id={props.id}
                    label={props.label}
                    fullWidth
                    value={props.value}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    error={props.error}
                    helperText={props.helperText}
                    toUpperCase
                    textLength={length}
                    charactersEnabled="[^0-9ABab]"
                    pattern={pattern}
                    separator={separator} />
            </Grid>

            {props.showInfo &&
            <Grid
                item
                xs='auto'
                sx={{
                    pb: props.error ? 3 : 0,
                    transition: "transform 400ms",
                    ":hover": {
                        transform: "translateY(-6px)"
                    }
                }}>
                <Tooltip title="Où le trouver ?" placement="top">
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setIsDialogOpened(true)}>
                        <InfoIcon color={iconStyle?.color || "black"} />
                    </IconButton>
                </Tooltip>
                <DialogInfoNIR
                    plateformStyle={props.plateformStyle}
                    isDisplayed={isDialogOpened}
                    onClose={() => setIsDialogOpened(false)} />
            </Grid>}
        </Grid>
    )
}

import { IContractNeeds } from "../model/ContractNeeds.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getContractNeeds = (id: number) : Promise<IContractNeeds> => {

    return fetch(`${apiUrl}/contract_needs/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}


export const createContractNeeds = (contractNeeds: IContractNeeds) : Promise<IContractNeeds> => {

    return fetch(`${apiUrl}/contract_needs`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractNeeds)

    }).then(_ => _.json()) 
}


export const modifyContractNeeds = (contractNeeds: IContractNeeds) : Promise<IContractNeeds> => {

    return fetch(`${apiUrl}/contract_needs`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractNeeds)

    }).then(_ => _.json()) 
}

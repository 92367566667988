import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { useFormik } from 'formik';
import * as yup from 'yup';
import { enumPromotionOffer, enumPromotionStatus, enumPromotionType, IPromotion, IPromotionDTO, PromotionStatus, PromotionType } from '../../../model/Promotion.model';
import { messageFieldRequired } from '../../../utils/messages';
import Card from '@mui/material/Card';
import { colors } from '../../../static/themes/gsmc/colors';
import Radio from '@mui/material/Radio';
import Typography from '@mui/material/Typography';
import Checkbox from '@mui/material/Checkbox';
import { FieldLabel } from '../../Field/FieldLabel';
import Divider from '@mui/material/Divider';
import { FieldImageUpload } from '../../Field/FieldImageUpload';
import Link from '@mui/material/Link';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';
import TrendingFlatIcon from '@mui/icons-material/TrendingFlat';
import { Box, Button, Chip, ClickAwayListener, IconButton, Popper, Stack, Tooltip, useTheme } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { DialogDeleteConfirmation } from '../../Dialog/DialogDeleteConfirmation';
import { style } from '../../../static/themes/gsmc/style';
import { create, update } from '../../../api/Promotion.api';
import { IPlateforme } from '../../../model/Plateforme.model';
import { getPlateformes } from '../../../api/Plateforme.api';
import CloseIcon from '@mui/icons-material/Close';
import { getTextColorBasedOnBgColor } from '../../../utils/color';
import { CardPromotion } from '../../Card/CardPromotion';
import { FieldColorPickerMinify } from '../../Field/FieldColorPickerMinify';


const enumHennerCode = [
    {
        value: 1,
        label: "Un mois gratuit par an pendant X an(s)"
    },
    {
        value: 2,
        label: "X mois gratuit(s) la 1ère année"
    },
    {
        value: 4,
        label: "12ème mois gratuit pendant X an(s)"
    }
]


interface Props {
    promotion?: IPromotionDTO;
    send: (promotion: IPromotionDTO) => void;
    save: (promotion: IPromotionDTO) => void;
}


export const FormPromotion = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();
        },
        leave() {
            send();
        }
    }));
    
    const theme =  useTheme()

    // const [dialodResetDateStartOpened, setDialodResetDateStartOpened] = useState<boolean>(false);
    const [dialodResetDateEndOpened, setDialodResetDateEndOpened] = useState<boolean>(false);

    const validationSchema = yup.object({
        type: yup.string().nullable().required(messageFieldRequired),
        offers: yup.array().of(yup.string()).min(1, "Au moins une offre doit être sélectionnée").nullable().required("Au moins une offre doit être sélectionnée"),
        plateformes: yup.array().of(yup.number()).nullable(),
        dateStart: yup.date().typeError("Date non valide").nullable(),
        dateEnd: yup.date().typeError("Date non valide").nullable()
        .test(
            'afterDateStart',
            "La date de fin doit être postérieure à la date de début",
            value => {
                if (value === null || value === undefined || formik.values.dateStart === null) return true

                if (formik.values.dateStart >= value) return false

                return true
            }
        ),
        hennerCode: yup.object().nullable()
        .test(
            'hennerValue',
            "Champ obligatoire si la valeur Henner est renseignée",
            value => {
                if (formik.values.hennerValue !== undefined && value === undefined) return false

                return true
            }
        ),
        hennerValue: yup.number().nullable()
        .test(
            'hennerCode',
            "Champ obligatoire si le code Henner est renseigné",
            value => {
                if (formik.values.hennerCode?.value !== undefined && value === undefined) return false

                return true
            }
        ),
        title: yup.string().nullable().required(messageFieldRequired).max(100, "Champ non valide (100 caractères max)"),
        description: yup.string().nullable(),
        descriptionLegal: yup.string().nullable(),
        color: yup.string().nullable(),
        textColor: yup.string().nullable(),
        borderColor: yup.string().nullable(),
        image: yup.string().nullable(),
        productUri: yup.string().nullable().matches(
            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            'Lien non valide'
        ),
        cgvUri: yup.string().nullable().matches(
            /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/,
            'Lien non valide'
        ),
        isActive: yup.boolean().nullable(),
    });

    const getInitialValues = (promo?: IPromotionDTO) => {

        if (promo === undefined) promo = props.promotion

        return {
            type: promo?.type,
            offers: (promo?.id && !promo?.offers) ? enumPromotionOffer.map(_ => _.value) : promo?.offers,
            plateformes: promo?.plateformes,
            dateStart: promo?.dateStart ? new Date(promo.dateStart) : new Date(),
            dateEnd: promo?.dateEnd ? new Date(promo.dateEnd) : null,
            hennerCode: promo?.hennerCode ? enumHennerCode.find(_ => _.value.toString() === promo?.hennerCode) : null,
            hennerValue: promo?.hennerValue,
            title: promo?.title || "",
            description: promo?.description || "",
            descriptionLegal: promo?.descriptionLegal || "",
            color: promo?.color,
            textColor: promo?.textColor,
            borderColor: promo?.borderColor,
            image: promo?.image,
            productUri: promo?.productUri || "",
            cgvUri: promo?.cgvUri || "",
            isActive: promo?.isActive !== undefined ? promo.isActive : false
        }
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save();
        }
    });

    const getPromotionFromForm = (): IPromotionDTO => {
        return {
            ...props.promotion,
            type: formik.values.type ? PromotionType[formik.values.type] : PromotionType.BASE,
            offers: formik.values.offers?.length === enumPromotionOffer.length ? undefined : formik.values.offers,
            plateformes: formik.values.plateformes,
            dateStart: formik.values.dateStart || undefined,
            dateEnd: formik.values.dateEnd || undefined,
            hennerCode: formik.values.hennerCode?.value.toString(),
            hennerValue: formik.values.hennerValue,
            title: formik.values.title || "",
            description: formik.values.description,
            descriptionLegal: formik.values.descriptionLegal,
            color: formik.values.color,
            textColor: formik.values.textColor,
            borderColor: formik.values.borderColor,
            image: formik.values.image,
            productUri: formik.values.productUri,
            cgvUri: formik.values.cgvUri,
            isActive: formik.values.isActive || false
        }
    };

    const save = () => {
        const dto = getPromotionFromForm()

        if (props.promotion?.id) {
            update(props.promotion.id, dto)
            .then(_ => {
                const p = {..._, plateformes: _.plateformes?.map(_ => _.id)} as IPromotionDTO

                props.save(p)

                formik.resetForm()
                formik.setValues(getInitialValues(p))
            })
        }
        else {
            create(dto)
            .then(_ => {
                const p = {..._, plateformes: _.plateformes?.map(_ => _.id)} as IPromotionDTO

                props.save(p)

                formik.resetForm()
                formik.setValues(getInitialValues(p))
            })
        }
    }

    const send = () => {
        props.send(getPromotionFromForm());
    }

    useEffect(() => {
        formik.setFieldValue("isActive", props.promotion?.isActive)
    },
    [props.promotion])

    let before = new Date()
    before.setHours(23, 59, 59)
    let after = new Date()
    after.setHours(2, 0, 0)

    const status: any = enumPromotionStatus.find(_ => _.value === (formik.values.isActive === false || formik.values.isActive === undefined ? PromotionStatus.DEACTIVATED
        : formik.values.dateStart !== null && formik.values.dateStart > before ? PromotionStatus.PENDING
        : formik.values.dateEnd !== null && formik.values.dateEnd < after ? PromotionStatus.EXPIRED
        : PromotionStatus.IN_PROGRESS))

    let startInDay
    let startInMonth
    let endInDay
    let endInMonth

    if (formik.values.dateStart) {
        const date = formik.values.dateStart
        const diffTime = Math.abs(before.getTime() - date.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        startInDay = diffDays;

        if (startInDay > 30)
            startInMonth = Math.floor(startInDay / 30);
    }
    if (formik.values.dateEnd) {
        const date = formik.values.dateEnd
        const diffTime = Math.abs(after.getTime() - date.getTime());
        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
        endInDay = diffDays;

        if (endInDay > 30)
            endInMonth = Math.floor(endInDay / 30);
    }

    const [plateformes, setPlateformes] = useState<IPlateforme[]>([]);
    const [anchorFilterPlateforme, setAnchorFilterPlateforme] = useState<null | HTMLElement>(null);
    const filterPlateformeOpen = Boolean(anchorFilterPlateforme);
    const openFilterPlateforme = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterPlateforme(event.currentTarget);
    };

    const closeFilterPlateforme = () => {
        if (anchorFilterPlateforme) {
            anchorFilterPlateforme.focus();
        }
        setAnchorFilterPlateforme(null);
    };

    useEffect(() => {
        getPlateformes(0, 1000).then(_ => setPlateformes(_.content));
    }, [])

    useEffect(() => {
        if (formik.values.color && (formik.values.textColor === undefined || formik.values.textColor === "white" || formik.values.textColor === colors.black.main))
            formik.setFieldValue("textColor", getTextColorBasedOnBgColor(formik.values.color, "white", colors.black.main))
    }, [formik.values.color])

    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    lg={9}
                    xs={12}>
                    <Card
                        sx={{
                            p: 2,
                            border: 1,
                            borderColor: colors.grey.main,
                            background: "white"
                        }}>
                        <Grid
                            container
                            spacing={2}>

                            <Grid
                                item
                                lg={5}
                                xs={12}
                                order={{
                                    lg: 1,
                                    xs: 2
                                }}>
                                <Grid
                                    container
                                    spacing={2}>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.dateEnd && Boolean(formik.errors.dateEnd)} >
                                            <FieldLabel
                                                label="Plannification"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <Grid
                                            container
                                            alignItems="center"
                                            gap={1}>
                                            <Grid
                                                item
                                                md
                                                xs={12}>
                                                <Stack
                                                    direction="row">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={fr}
                                                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                        <DatePicker
                                                            value={formik.values.dateStart}
                                                            onChange={(value: any) => {
                                                                let date = value
                                                                date.setHours(2, 0, 0)
                                                                formik.setFieldValue("dateStart", date)
                                                                formik.setFieldTouched("dateStart")
                                                            }}
                                                            format='dd/MM/yyyy'
                                                            localeText={{
                                                                toolbarTitle: "Sélectionnez une date",
                                                                okButtonLabel: "Valider"
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    onBlur: (e) => formik.setFieldTouched("dateStart"),
                                                                    error: formik.touched.dateStart && Boolean(formik.errors.dateStart),
                                                                    sx: {
                                                                        width: {
                                                                            xs: "100%"
                                                                        },
                                                                        minWidth: "190px"
                                                                    }
                                                                },
                                                            }} />
                                                    </LocalizationProvider>

                                                    {/* dateStart : Initialise at today. */}
                                                    {/*
                                                    {formik.values.dateStart !== null &&
                                                    <Tooltip
                                                        title="Réinitialiser la date de début"
                                                        placement='top'>
                                                        <IconButton
                                                            onClick={() => setDialodResetDateStartOpened(true)}
                                                            sx={{
                                                                transition: "transform 400ms",
                                                                ":hover": {
                                                                    transform: "translateY(-6px)"
                                                                }
                                                            }}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>}*/}
                                                </Stack>
                                                {/* <DialogDeleteConfirmation
                                                    isOpened={dialodResetDateStartOpened}
                                                    title="Réinitialiser la date de début"
                                                    description="Êtes-vous sûr de vouloir réinitialiser la date de début de la promotion ?"
                                                    textConfirmation='Réinitialiser'
                                                    handleClose={() => setDialodResetDateStartOpened(false)}
                                                    handleConfirmation={() => {
                                                        formik.setFieldValue("dateStart", null)
                                                        formik.setFieldTouched("dateStart")
                                                        setDialodResetDateStartOpened(false)}
                                                    } />
                                                */}
                                            </Grid>
        
                                            <Grid
                                                item
                                                xs="auto"
                                                sx={{
                                                    display: {
                                                        md: "inline",
                                                        xs: "none"
                                                    }
                                                }}>
                                                <TrendingFlatIcon />
                                            </Grid>
        
                                            <Grid
                                                item
                                                md
                                                xs={12}>
                                                <Stack
                                                    direction="row">
                                                    <LocalizationProvider
                                                        dateAdapter={AdapterDateFns}
                                                        adapterLocale={fr}
                                                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                        <DatePicker
                                                            value={formik.values.dateEnd}
                                                            onChange={(value: any) => {
                                                                let date = value
                                                                date.setHours(23, 59, 59)
                                                                formik.setFieldValue("dateEnd", date)
                                                                formik.setFieldTouched("dateEnd")
                                                            }}
                                                            format='dd/MM/yyyy'
                                                            localeText={{
                                                                toolbarTitle: "Sélectionnez une date",
                                                                okButtonLabel: "Valider"
                                                            }}
                                                            slotProps={{
                                                                textField: {
                                                                    onBlur: (e) => formik.setFieldTouched("dateEnd"),
                                                                    error: formik.touched.dateEnd && Boolean(formik.errors.dateEnd),
                                                                    sx: {
                                                                        width: {
                                                                            xs: "100%",
                                                                            minWidth: "190px"
                                                                        }
                                                                    }
                                                                },
                                                            }} />
                                                    </LocalizationProvider>

                                                    {formik.values.dateEnd !== null &&
                                                    <Tooltip
                                                        title="Réinitialiser la date de fin"
                                                        placement='top'>
                                                        <IconButton
                                                            onClick={() => setDialodResetDateEndOpened(true)}
                                                            sx={{
                                                                transition: "transform 400ms",
                                                                ":hover": {
                                                                    transform: "translateY(-6px)"
                                                                }
                                                            }}>
                                                            <ClearIcon />
                                                        </IconButton>
                                                    </Tooltip>}
                                                </Stack>
        
                                                <DialogDeleteConfirmation
                                                    isOpened={dialodResetDateEndOpened}
                                                    title="Réinitialiser la date de fin"
                                                    description="Êtes-vous sûr de vouloir réinitialiser la date de fin de la promotion ?"
                                                    textConfirmation='Réinitialiser'
                                                    handleClose={() => setDialodResetDateEndOpened(false)}
                                                    handleConfirmation={() => {
                                                        formik.setFieldValue("dateEnd", null)
                                                        formik.setFieldTouched("dateEnd")
                                                        setDialodResetDateEndOpened(false)}
                                                    } />
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    {formik.touched.dateStart && Boolean(formik.errors.dateStart) &&
                                    <Grid
                                        item
                                        xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="error" >
                                            <>{formik.errors.dateStart}</>
                                        </Typography>
                                    </Grid>}

                                    {formik.touched.dateEnd && Boolean(formik.errors.dateEnd) &&
                                    <Grid
                                        item
                                        xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="error" >
                                            {formik.errors.dateEnd}
                                        </Typography>
                                    </Grid>}

                                    <Grid
                                        item
                                        xs={12}>
                                        <Divider />
                                    </Grid>

                                    <Grid
                                        item
                                        lg={12}
                                        sm={6}
                                        xs={12}>
                                        <Typography
                                            variant="caption" >
                                            Affichage mini
                                        </Typography>
                                        <Box>
                                            <CardPromotion
                                                plateformStyle={style}
                                                promotion={getPromotionFromForm() as IPromotion}
                                                minified />
                                        </Box>
                                    </Grid>

                                    <Grid
                                        item
                                        lg={12}
                                        sm={6}
                                        xs={12}>
                                        <Typography
                                            variant="caption" >
                                            Affichage détaillé
                                        </Typography>
                                        <Box>
                                            <CardPromotion
                                                plateformStyle={style}
                                                promotion={getPromotionFromForm() as IPromotion} />
                                        </Box>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            spacing={2}
                                            sx={{
                                                textAlign: "center"
                                            }}>
                                            <Grid
                                                item>
                                                <Card
                                                    sx={{
                                                        p: 0.5,
                                                        background: colors.grey.main + "60"
                                                    }}>
                                                    <FieldLabel
                                                        label="Fond"
                                                        fontWeight={500}
                                                        mb={false} />
                                                    <FieldColorPickerMinify
                                                        value={formik.values.color}
                                                        defaultValue={colors.grey.secondary}
                                                        resetEnabled
                                                        onChange={value => {
                                                            formik.setFieldValue("color", value)
                                                            formik.setFieldTouched("color")
                                                        }}/>
                                                </Card>
                                            </Grid>
                                            <Grid
                                                item>
                                                <Card
                                                    sx={{
                                                        p: 0.5,
                                                        background: colors.grey.main + "60"
                                                    }}>
                                                    <FieldLabel
                                                        label="Bordure"
                                                        fontWeight={500}
                                                        mb={false} />
                                                    <FieldColorPickerMinify
                                                        value={formik.values.borderColor}
                                                        resetEnabled
                                                        onChange={value => {
                                                            formik.setFieldValue("borderColor", value)
                                                            formik.setFieldTouched("borderColor")
                                                        }}/>
                                                </Card>
                                            </Grid>
                                            <Grid
                                                item>
                                                <Card
                                                    sx={{
                                                        p: 0.5,
                                                        background: colors.grey.main + "60"
                                                    }}>
                                                    <FieldLabel
                                                        label="Texte"
                                                        fontWeight={500}
                                                        mb={false} />
                                                    <FieldColorPickerMinify
                                                        value={formik.values.textColor}
                                                        defaultValue={formik.values.color ? getTextColorBasedOnBgColor(formik.values.color, "white", colors.black.main) : colors.black.main}
                                                        resetEnabled
                                                        onChange={value => {
                                                            formik.setFieldValue("textColor", value)
                                                            formik.setFieldTouched("textColor")
                                                        }}/>
                                                </Card>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid
                                item
                                lg={7}
                                xs={12}
                                order={{
                                    lg: 2,
                                    xs: 1
                                }}>
                                <Grid
                                    container
                                    spacing={2}>
                                    <Grid
                                        item
                                        xs={12}>
                                        <Stack
                                            direction="row"
                                            alignItems="flex-end"
                                            gap={1}>
                                            <Box
                                                sx={{
                                                    maxWidth: "100px"
                                                }}>
                                                <FieldImageUpload
                                                    label="Icône"
                                                    sublabel="Cliquer ou Glisser"
                                                    hideIconEmpty
                                                    image={formik.values.image}
                                                    send={image => {
                                                        formik.setFieldValue("image", image)
                                                        formik.setFieldTouched("image")
                                                    }} />
                                            </Box>
                                            <Box
                                                sx={{
                                                    width: "100%"
                                                }}>
                                                <FormLabel error={formik.touched.title && Boolean(formik.errors.title)} >
                                                    <FieldLabel
                                                        label="Titre"
                                                        isRequired
                                                        fontWeight={500}
                                                        mb={false} />
                                                </FormLabel>
                                                <TextField
                                                    fullWidth
                                                    required
                                                    id="title"
                                                    name="title"
                                                    value={formik.values.title}
                                                    onChange={formik.handleChange}
                                                    onBlur={formik.handleBlur}
                                                    error={formik.touched.title && Boolean(formik.errors.title)}
                                                    helperText={formik.touched.title && formik.errors.title} />
                                            </Box>
                                        </Stack>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.description && Boolean(formik.errors.description)} >
                                            <FieldLabel
                                                label="Description"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="description"
                                            name="description"
                                            value={formik.values.description}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.description && Boolean(formik.errors.description)}
                                            helperText={formik.touched.description && formik.errors.description} />
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <FormLabel error={formik.touched.descriptionLegal && Boolean(formik.errors.descriptionLegal)} >
                                            <FieldLabel
                                                label="Mentions légales"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            id="descriptionLegal"
                                            name="descriptionLegal"
                                            multiline
                                            minRows={1}
                                            maxRows={10}
                                            value={formik.values.descriptionLegal}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.descriptionLegal && Boolean(formik.errors.descriptionLegal)}
                                            helperText={formik.touched.descriptionLegal && formik.errors.descriptionLegal} />
                                    </Grid>

                                    <Grid
                                        item
                                        sm={8}
                                        xs={12}>
                                        <FormLabel error={formik.touched.productUri && Boolean(formik.errors.productUri)} >
                                            <FieldLabel
                                                label="Lien vers page de présentation"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="https://"
                                            id="productUri"
                                            name="productUri"
                                            value={formik.values.productUri}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.productUri && Boolean(formik.errors.productUri)}
                                            helperText={formik.touched.productUri && formik.errors.productUri} />
                                        <Typography
                                            variant="caption" >
                                            Utiliser dans le formulaire et les emails
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        sm={8}
                                        xs={12}>
                                        <FormLabel error={formik.touched.cgvUri && Boolean(formik.errors.cgvUri)} >
                                            <FieldLabel
                                                label="Lien vers CGV"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            placeholder="https://"
                                            id="cgvUri"
                                            name="cgvUri"
                                            value={formik.values.cgvUri}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.cgvUri && Boolean(formik.errors.cgvUri)}
                                            helperText={formik.touched.cgvUri && formik.errors.cgvUri} />
                                            <Typography
                                                variant="caption" >
                                                Utiliser dans le contrat
                                            </Typography>
                                    </Grid>

                                    {formik.values.type === PromotionType.BASE &&
                                    <>
                                    <Grid
                                        item
                                        sm={6}
                                        xs={12}>
                                        <FormLabel error={formik.touched.hennerCode && Boolean(formik.errors.hennerCode)} >
                                            <FieldLabel
                                                label="Code Henner"
                                                fontWeight={500}
                                                mb={false} />
                                        </FormLabel>
                                        <Autocomplete
                                            fullWidth
                                            id="hennerCode"
                                            value={enumHennerCode.find(_ => _.value === formik.values.hennerCode?.value) || null}
                                            options={enumHennerCode}
                                            getOptionLabel={(option) => `${option?.value} - ${option?.label}`}
                                            onChange={(e, value) => formik.setFieldValue("hennerCode", value)}
                                            onBlur={formik.handleBlur}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    name="hennerCode"
                                                    error={formik.touched.hennerCode && Boolean(formik.errors.hennerCode)}
                                                    helperText={formik.touched.hennerCode && formik.errors.hennerCode} />)
                                            }
                                        />
                                        <Typography
                                            variant="caption" >
                                            Utiliser lors de la mise en gestion automatique
                                        </Typography>
                                    </Grid>

                                    <Grid
                                        item
                                        sm={6}
                                        xs={12}>
                                        <FormLabel error={formik.touched.hennerValue && Boolean(formik.errors.hennerValue)} >
                                            <FieldLabel
                                                label="Valeur Henner"
                                                fontWeight={500}
                                                mb={false}
                                                isRequired={formik.values.hennerCode?.value !== undefined} />
                                        </FormLabel>
                                        <TextField
                                            fullWidth
                                            type="number"
                                            id="hennerValue"
                                            name="hennerValue"
                                            value={formik.values.hennerValue}
                                            onChange={formik.handleChange}
                                            onBlur={formik.handleBlur}
                                            error={formik.touched.hennerValue && Boolean(formik.errors.hennerValue)}
                                            helperText={formik.touched.hennerValue && formik.errors.hennerValue}
                                            InputProps={{
                                                inputProps: { step: 1, min: 0 }
                                            }} />
                                    </Grid>
                                    </>}
                                </Grid>
                            </Grid>

                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    lg={3}
                    xs={12}>
                    <Card
                        sx={{
                            p: 2,
                            border: 1,
                            borderColor: colors.grey.main,
                            background: "white"
                        }}>
                        <Grid
                            container
                            spacing={2}>
                            <Grid
                                item
                                xs={12}>
                                <Chip
                                    label={status.label.toUpperCase()}
                                    sx={{
                                        mr: 1,
                                        color: 'white',
                                        backgroundColor: status.color || colors.black.main,
                                        fontSize: 14,
                                        fontWeight: 700
                                    }} />
                                {status.value === PromotionStatus.PENDING && startInDay !== undefined ? <>🕑 démarre dans {startInMonth ? `${startInMonth} mois` : `${startInDay} jour(s)`}</>
                                : status.value === PromotionStatus.IN_PROGRESS && endInDay !== undefined ? <>🕑 se termine dans {endInMonth ? `${endInMonth} mois` : `${endInDay} jour(s)`}</>
                                : status.value === PromotionStatus.EXPIRED && endInDay !== undefined ? <>🕑 depuis {endInMonth ? `${endInMonth} mois` : `${endInDay} jour(s)`}</> : ""}
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Divider />
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Grid
                                    container
                                    gap={1}>
                                    {enumPromotionType.map(_ => {
                                    const checked: boolean = formik.values.type === _.value
                                    const disabled: boolean = props.promotion?.id !== undefined

                                    return (
                                    <Grid
                                        item
                                        lg
                                        sm="auto"
                                        xs={12}>
                                        <Card
                                            onClick={() => {
                                                if (disabled) return

                                                formik.setFieldValue('type', _.value)
                                                formik.setFieldTouched('type')
                                            }}
                                            sx={{
                                                alignItems: "center",
                                                py: 1,
                                                px: 2,
                                                background: colors.grey.main + "60",
                                                border: checked ? 1 : 0,
                                                borderColor: colors.blue.main,
                                                cursor: !disabled ? "pointer" : "default",
                                                transition: "transform 400ms",
                                                ":hover": {
                                                    background: !disabled ? colors.grey.main : "",
                                                    transform: !disabled ? "translateY(-6px)" : "none"
                                                }
                                            }}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}>
                                                <Radio
                                                    size="small"
                                                    value={_.value}
                                                    checked={checked}
                                                    sx={{
                                                        py: 0.5,
                                                        px: 0
                                                    }} />
                                                <Typography
                                                    noWrap
                                                    fontWeight={checked ? 700 : 500}
                                                    color={checked ? "primary" : "text.primary"}>
                                                    {_.label}
                                                </Typography>
                                            </Stack>
                                        </Card>
                                    </Grid>)})}
                                </Grid>
                                {props.promotion?.id &&
                                <Typography
                                    variant="caption" >
                                    Non modifiable
                                </Typography>}
                                {formik.touched.type && Boolean(formik.errors.type) &&
                                <Typography
                                    variant="caption"
                                    color="error" >
                                    {formik.errors.type}
                                </Typography>}
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Divider />
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Grid
                                    container
                                    alignItems="center"
                                    gap={1}>
                                    {enumPromotionOffer.map(_ => {
                                    const selected: boolean = formik.values.offers !== undefined && formik.values.offers.find(o => o === _.value) !== undefined

                                    return (
                                    <Grid
                                        item
                                        lg={12}
                                        sm="auto"
                                        xs={12}>
                                        <Card
                                            onClick={() => {
                                                let newList = formik.values.offers !== undefined ? [...formik.values.offers] : []

                                                if (selected) newList = newList.filter(o => o !== _.value)
                                                else newList.push(_.value)

                                                formik.setFieldValue("offers", newList)
                                                formik.setFieldTouched('offers')
                                            }}
                                            sx={{
                                                alignItems: "center",
                                                py: 1,
                                                px: 2,
                                                background: colors.grey.main + "60",
                                                border: selected ? 1 : 0,
                                                borderColor: colors.blue.main,
                                                cursor: "pointer",
                                                transition: "transform 400ms",
                                                ":hover": {
                                                    background: colors.grey.main,
                                                    transform: "translateY(-6px)"
                                                }
                                            }}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}>
                                                <Checkbox
                                                    size="small"
                                                    checked={selected}
                                                    sx={{
                                                        py: 0.5,
                                                        px: 0
                                                    }} />
                                                <Typography
                                                    noWrap
                                                    fontWeight={selected ? 700 : 500}
                                                    color={selected ? "primary" : "text.primary"}>
                                                    {_.icon && `${_.icon} `}{_.label}
                                                </Typography>
                                            </Stack>
                                        </Card>
                                    </Grid>)})}
                                    
                                    {formik.touched.offers && Boolean(formik.errors.offers) &&
                                    <Grid
                                        item
                                        xs={12}>
                                        <Typography
                                            variant="caption"
                                            color="error" >
                                            {formik.errors.offers}
                                        </Typography>
                                    </Grid>}

                                    <Grid
                                        item
                                        lg
                                        sm="auto"
                                        xs={12}>
                                        <Link
                                            noWrap
                                            underline='none'
                                            onClick={() => formik.setFieldValue("offers", enumPromotionOffer.map(o => o.value))}>
                                            Tout sélectionner
                                        </Link>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Divider />
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Grid
                                    container
                                    gap={1}>
                                    <Grid
                                        item
                                        lg
                                        sm="auto"
                                        xs={12}>
                                        <Card
                                            onClick={() => {
                                                formik.setFieldValue('plateformes', undefined)
                                                formik.setFieldTouched('plateformes')
                                            }}
                                            sx={{
                                                alignItems: "center",
                                                py: 1,
                                                px: 2,
                                                background: colors.grey.main + "60",
                                                border: formik.values.plateformes === undefined ? 1 : 0,
                                                borderColor: colors.blue.main,
                                                cursor: "pointer",
                                                transition: "transform 400ms",
                                                ":hover": {
                                                    background: colors.grey.main,
                                                    transform: "translateY(-6px)"
                                                }
                                            }}>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}>
                                                <Radio
                                                    size="small"
                                                    value={undefined}
                                                    checked={formik.values.plateformes === undefined}
                                                    sx={{
                                                        py: 0.5,
                                                        px: 0
                                                    }} />
                                                <Typography
                                                    noWrap
                                                    fontWeight={formik.values.plateformes === undefined ? 700 : 500}
                                                    color={formik.values.plateformes === undefined ? "primary" : "text.primary"}>
                                                    🚀 Toutes
                                                </Typography>
                                            </Stack>
                                        </Card>
                                    </Grid>
                                    <Grid
                                        item
                                        lg
                                        sm="auto"
                                        xs={12}>
                                        <Box>
                                            <Card
                                                onClick={openFilterPlateforme}
                                                sx={{
                                                    alignItems: "center",
                                                    py: 1,
                                                    px: 2,
                                                    background: colors.grey.main + "60",
                                                    border: formik.values.plateformes !== undefined ? 1 : 0,
                                                    borderColor: colors.blue.main,
                                                    cursor: "pointer",
                                                    transition: "transform 400ms",
                                                    ":hover": {
                                                        background: colors.grey.main,
                                                        transform: "translateY(-6px)"
                                                    }
                                                }}>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    gap={1}>
                                                    <Radio
                                                        size="small"
                                                        value={undefined}
                                                        checked={formik.values.plateformes !== undefined}
                                                        sx={{
                                                            py: 0.5,
                                                            px: 0
                                                        }} />
                                                    <Typography
                                                        noWrap
                                                        fontWeight={formik.values.plateformes !== undefined ? 700 : 500}
                                                        color={formik.values.plateformes !== undefined ? "primary" : "text.primary"}>
                                                        Plateformes
                                                        {formik.values.plateformes && formik.values.plateformes.length > 0 && 
                                                        <Chip
                                                            label={formik.values.plateformes.length}
                                                            size="small"
                                                            sx={{
                                                                ml: 1,
                                                                fontWeight: 700,
                                                                borderRadius: 2
                                                            }}
                                                            />}
                                                    </Typography>
                                                </Stack>
                                            </Card>
                                            <Popper open={filterPlateformeOpen} anchorEl={anchorFilterPlateforme} placement="bottom-start">
                                                <ClickAwayListener onClickAway={closeFilterPlateforme}>
                                                    <Box
                                                        sx={{
                                                            border: 1,
                                                            borderColor: colors.grey.main,
                                                            borderRadius: 2,
                                                            background: "white"
                                                        }}>
                                                        <Autocomplete
                                                            open
                                                            multiple
                                                            disableCloseOnSelect
                                                            options={plateformes}
                                                            value={formik.values.plateformes ? formik.values.plateformes.map(_ => plateformes.find(p => p.id === _)) : []}
                                                            getOptionLabel={(item) => item?.name || ""}
                                                            onChange={(event, newValue, reason) => {
                                                                if (event.type === 'keydown' &&
                                                                    (event as React.KeyboardEvent).key === 'Backspace' &&
                                                                    reason === 'removeOption') {
                                                                    return;
                                                                }
                                                                formik.setFieldValue("plateformes", newValue.length > 0 ? newValue.map(_ => _?.id) : undefined);
                                                                formik.setFieldTouched('plateformes')
                                                            }}
                                                            onClose={(
                                                                event: React.ChangeEvent<{}>,
                                                                reason: AutocompleteCloseReason,
                                                            ) => {
                                                                if (reason === 'escape') {
                                                                    closeFilterPlateforme();
                                                                }
                                                            }}
                                                            renderOption={(props, option, { selected }) => (
                                                                <li {...props}
                                                                    style={{
                                                                        alignItems: "flex-start"
                                                                    }}>
                                                                    <Box
                                                                        sx={{
                                                                            flexGrow: 1
                                                                        }}>
                                                                        <Typography fontWeight={500}>
                                                                            {option?.name}
                                                                        </Typography>
                                                                    </Box>
                                                                    <Box
                                                                        component={CloseIcon}
                                                                        sx={{
                                                                            opacity: 0.6,
                                                                            width: 18,
                                                                            height: 18,
                                                                            mt: "4px"
                                                                        }}
                                                                        style={{
                                                                            visibility: selected ? 'visible' : 'hidden',
                                                                        }}
                                                                    />
                                                                </li>
                                                            )}
                                                            renderInput={(params) => (
                                                                <TextField
                                                                    autoFocus
                                                                    size="small"
                                                                    placeholder="Recherche"
                                                                    ref={params.InputProps.ref}
                                                                    inputProps={params.inputProps}
                                                                    InputProps={{
                                                                        sx: {
                                                                            p: 0,
                                                                            m: 1,
                                                                            borderRadius: 2,
                                                                        }
                                                                    }}
                                                                />
                                                            )} />
                                                    </Box>
                                                </ClickAwayListener>
                                            </Popper>
                                        </Box>
                                    </Grid>
                                </Grid>
                            </Grid>

                            <Grid
                                item
                                xs={12}>
                                <Divider />
                            </Grid>

                            {props.promotion?.id &&
                            <Grid
                                item
                                lg={12}
                                sm="auto"
                                xs={12}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    color={props.promotion.isActive ? "error" : "success"}
                                    onClick={() => {
                                        // TODO : Dialog. : ACTIVE OU PAS
    
                                        if (!props.promotion?.id) return
    
                                        const dto = {...props.promotion, isActive: !props.promotion.isActive}
    
                                        update(props.promotion.id, dto)
                                        .then((_) => {
                                            if (props.send) props.send({..._, plateformes: _.plateformes?.map(_ => _.id)} as IPromotionDTO)
    
                                            // setSnackbarMessage(props.promotion?.isActive ? "Promotion désactivée" : "Promotion activée")
                                            // setSnackbarSeverity("success")
                                            // setIsSnackbarOpened(true)
                                        })
                                        .catch((_) => {
                                            // setSnackbarMessage(`Une erreur est survenue lors de ${props.promotion?.isActive ? "la désactivation" : "l'activation"} de la promotion`)
                                            // setSnackbarSeverity("error")
                                            // setIsSnackbarOpened(true)
                                        })
                                    }}
                                    sx={{
                                        py: 1,
                                        px: 2,
                                        color: "white",
                                        transition: "transform 400ms",
                                        ":hover": {
                                            boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                            transform: "translateY(-6px)"
                                        }
                                    }}>
                                    {props.promotion.isActive ? "Désactiver" : "Activer"}
                                </Button>
                            </Grid>}

                            <Grid
                                item
                                lg={12}
                                sm="auto"
                                xs={12}>
                                <Button
                                    fullWidth
                                    variant='contained'
                                    onClick={() => {
                                        formik.handleSubmit()
                                    }}
                                    sx={{
                                        py: 1,
                                        px: 2,
                                        background: theme.palette.text.primary,
                                        color: "white",
                                        transition: "transform 400ms",
                                        ":hover": {
                                            boxShadow: "rgba(0, 0, 0, 0.1)  0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
                                            transform: "translateY(-6px)",
                                            background: "black"
                                        }
                                    }}>
                                    Enregistrer
                                </Button>
                            </Grid>

                            {props.promotion?.id && Object.values(formik.touched).filter(_=> _ === true).length > 0 && Object.values(formik.touched) &&
                            <Grid
                                item
                                lg={12}
                                sm="auto"
                                xs={12}>
                                <Button
                                    fullWidth
                                    variant='text'
                                    onClick={() => {
                                        formik.resetForm()
                                        formik.setValues(getInitialValues())
                                    }}
                                    sx={{
                                        py: 1,
                                        px: 2,
                                        color: colors.black.main,
                                        transition: "transform 400ms",
                                        ":hover": {
                                            transform: "translateY(-6px)"
                                        }
                                    }}>
                                    Annuler
                                </Button>
                            </Grid>}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </form>
    )
})

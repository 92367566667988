import { cloneElement } from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';

import { colors } from '../../static/themes/gsmc/colors';
import Stack from '@mui/material/Stack';

export type type = "TITLE" | "LINK"

export interface IAppBarHeaderItem {
    type: type;
    label?: string;
    icon?: JSX.Element;
    endIcon?: JSX.Element;
    url?: string;
    urlBase?: string;
    isActive?: () => void;
}

interface Props {
    items: IAppBarHeaderItem[];
}

export default function AppBarHeader (props: Props) {

    const navigate = useNavigate();

    return (
        <Box
            sx={{
                zIndex: "100",
                position: "sticky",
                top: {
                    md: 0,
                    xs: 57
                },
                p: 2,
                height: "auto",
                background: "white",
                borderBottom: 1,
                borderColor: colors.grey.main
            }} >

            <Breadcrumbs
                aria-label="breadcrumb"
                separator={<NavigateNextIcon fontSize="small" />}>

                {props.items.map(_ => {

                    const content = 
                    <Stack direction="row" alignItems="center" gap={1}>
                        {_.icon && cloneElement(_.icon, { sx: { mr: 0.5, display: 'flex', alignItems: 'center' }, fontSize: "inherit",  })}
                        {_.label &&
                        <Typography
                            sx={{ display: 'flex', alignItems: 'center' }}
                            color="text.primary" >
                            {_.label}
                        </Typography>}
                        {_.endIcon}
                    </Stack>

                    if (_.type === "LINK") {
                        return <Link
                            underline="hover"
                            color="inherit"
                            sx={{ display: 'flex', alignItems: 'center' }}
                            href={_.url || ""}>
                            {content}
                        </Link>
                    }

                    return content
                })}
            </Breadcrumbs>
        </Box>
    );
}

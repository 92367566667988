import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


interface Props {
    plateformStyle: any;
    title: string | JSX.Element |JSX.Element[];
}


export const OfferDetailsHeader = (props: Props) => {

    return (
        <Grid
            item
            xs={12}
            sx={{
                textAlign: {
                    sm: 'left',
                    xs: 'right'
                },
                pt: 2,
                pb: {
                    sm: 1,
                    xs: 0
                },
                pl: 1
            }} >
            <Typography variant='body1'>{props.title}</Typography>
        </Grid>
    )
}

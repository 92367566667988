import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { OfferInfo } from './OfferInfo';
import {IWarrantiesAcronyms,} from "../../model/Waranties.model";


interface Props {
    plateformStyle: any;
    content: (string|IWarrantiesAcronyms)[];
}


export const OfferDetailLabel = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    return (
        <Box
            sx={{
                textAlign: {
                    sm: 'center',
                    xs: 'right'
                }
            }} >
            {props.content.map((_) => {
                if (typeof _ === "string") {
                    return (
                        <Typography component="span" fontWeight="inherit">
                            {_}
                        </Typography>
                    );
                }
                else {  // TODO : Check instance of Info (but it will be a number ?)
                    return (
                        <OfferInfo plateformStyle={plateformStyle} info={_} />
                    );
                }
            })}
        </Box>
    )
}

import { ICallback, ICallbackDTO } from "../model/Callback.model";


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'



export const getCallBackByUuid = (uuid: string): Promise<ICallback> => {

    return fetch(
        `${apiUrl}/callback/uuid/${uuid}`,
        {
            method: "GET"
        })
        .then(_ => {
            if (_.status === 200)
                return _.json();
            throw "getCallback by UUID status=" + _.status;
        }
    );
}

export const createCallback = (payload: ICallbackDTO): Promise<ICallback> => {

    return fetch(
        `${apiUrl}/callback`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(_ => {
            if (_.status === 200)
                return _.json();
            throw "createCallback status=" + _.status;
        }
    );
}

export const updateCallback = (id: number, payload: ICallbackDTO): Promise<ICallback> => {

    return fetch(
        `${apiUrl}/callback/${id}`,
        {
            method: "PUT",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(payload)
        })
        .then(_ => {
            if (_.status === 200)
                return _.json();
            throw "updateCallback status=" + _.status;
        }
    );
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const HrChildIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={props.bgColor} />
        <mask id="mask0_295_3911" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <path d="M20 2.94047e-06C31.0457 2.45765e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 3.42329e-06 20 2.94047e-06Z" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_295_3911)">
        <path d="M9.85763 32.5813L13.8212 48.1813C14.0642 49.1373 14.7629 49.9121 15.6888 50.2522L25.181 53.7383C26.5559 54.2432 28.0941 53.6782 28.8151 52.4034L56.6432 3.20633C57.6947 1.3474 56.515 -0.982775 54.3943 -1.2357L18.4292 -5.52518C15.9321 -5.82301 14.2047 -3.09316 15.543 -0.964022L28.1419 19.0795C29.1508 20.6846 28.4308 22.8113 26.6543 23.4734L11.7118 29.0425C10.2809 29.5758 9.4816 31.1013 9.85763 32.5813Z" fill="url(#paint0_linear_295_3911)"/>
        </g>
        <g filter="url(#filter0_d_295_3911)">
        <path d="M18 31C18 24.9249 22.9249 20 29 20C35.0751 20 40 24.9249 40 31V38H18V31Z" fill={props.color}/>
        <circle cx="28.8512" cy="12.8512" r="6.85123" fill={props.color}/>
        </g>
        <g filter="url(#filter1_d_295_3911)">
        <path d="M0 34.5C0 29.2533 4.25329 25 9.5 25C14.7467 25 19 29.2533 19 34.5V39H0V34.5Z" fill={props.color}/>
        <circle cx="9.5" cy="17.5" r="6.5" fill={props.color}/>
        </g>
        <g filter="url(#filter2_d_295_3911)">
        <path d="M12 37.6998C12 33.8338 15.134 30.6998 19 30.6998C22.866 30.6998 26 33.8338 26 37.6998V39.6149H12V37.6998Z" fill={props.color}/>
        <circle cx="19.024" cy="25.855" r="4.45755" fill={props.color}/>
        </g>
        <defs>
        <filter id="filter0_d_295_3911" x="-2" y="-10" width="62" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3911"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3911" result="shape"/>
        </filter>
        <filter id="filter1_d_295_3911" x="-20" y="-5" width="59" height="68" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3911"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3911" result="shape"/>
        </filter>
        <filter id="filter2_d_295_3911" x="-8" y="5.39746" width="54" height="58.2174" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3911"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3911" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_295_3911" x1="9.78295" y1="7.65598" x2="43.4527" y2="27.0952" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

import { useState } from 'react';

import Box from '@mui/material/Box';
import Popover from '@mui/material/Popover';
import Typography from '@mui/material/Typography';
import {IWarrantiesAcronyms} from "../../model/Waranties.model";

interface Props {
    plateformStyle: any;
    info: IWarrantiesAcronyms;
}


export const OfferInfo = (props: Props) => {

    const [anchorEl, setAnchorEl] = useState<HTMLElement|null>();

    const openPopover = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closePopover = () => {
        setAnchorEl(null);
    };

    return (
        <Box
            component="span" >
            <Typography
                aria-owns={Boolean(anchorEl) ? 'mouse-over-popover' : undefined}
                aria-haspopup="true"
                onMouseEnter={(e: any) => openPopover(e)}
                onMouseLeave={closePopover}
                component='span'
                fontWeight="inherit"
                fontSize="inherit"
                sx={{
                    // textDecoration: 'underline dotted',
                    px: 0.5,
                    borderRadius: 1,
                    border: 1.5,
                    ":hover": {
                        cursor: "pointer"
                    }
                }}>
                {props.info.acronym}
            </Typography>

            <Popover
                id="mouse-over-popover"
                sx={{
                    pointerEvents: 'none',
                }}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                onClose={closePopover}
                disableRestoreFocus >
                <Typography
                    fontWeight={500}
                    sx={{
                        p: 1.5
                    }} >
                    <b>{props.info.acronym}</b> : {props.info.description}
                </Typography>
            </Popover>
        </Box>
    )
}

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import WarningIcon from '@mui/icons-material/Warning';

import { FormHeader } from '../../components/Form/FormHeader';

import { colors } from '../../static/themes/colors';


interface Props {
    message: string;
}


export const FormContextError = (props: Props) => {

    return (
        <Grid
            container
            justify-content='center'
            spacing={2}
            sx={{
                px: 2
            }} >
            <Grid
                item >
                <FormHeader
                    label="Une erreur est survenue" />
            </Grid>

            <Grid
                xs={12}
                item >
                <Card>
                    <Grid
                        container
                        direction="column"
                        alignItems='center'
                        justifyContent='center'
                        sx={{
                            height: '150px'
                        }}>
                        <Grid
                            item>
                            <WarningIcon
                                fontSize="large"
                                sx={{
                                    color: colors.red.main
                                }} />
                        </Grid>
                        <Grid
                            item>
                            <Typography
                                variant='subtitle1'>
                                {props.message}
                            </Typography>
                        </Grid>
                        <Grid
                            item>
                            <Typography
                                variant='body2'
                                sx={{
                                    mt: 1
                                }}>
                                Veuillez réessayer ultérieurement
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>
        </Grid>
    )
}

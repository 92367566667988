import { IPlateforme, IPagePlateforme } from "../model"
import { msalConfig } from "../utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}
const apiUrl = process.env.REACT_APP_API_URL + '/backoffice'
const apiUrlTunnel = process.env.REACT_APP_API_URL + '/tunnel'


export const getPlateformes = (page: number, pageSize: number, search?: string): Promise<IPagePlateforme> => {

    let url: string = `${apiUrl}/plateformes?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
        {
            method: "GET",
        })
        .then(_ => {
            if (_.status === 200)
                return _.json();
            throw "getPlateformes status=" + _.status;
        })
}

export const getPlateforme = (id: number): Promise<IPlateforme> => {

    return fetch(`${apiUrl}/plateforme/${id}`,
        {
            method: "GET",
        }).then(_ => _.json())
}

export const getPlateformeViaTunnel = (code: string): Promise<string> => {

    return fetch(`${apiUrlTunnel}/plateforme/auth/${code}`,
        {
            method: "GET",
        }).then(_ => _.text())
}


export const createPlateforme = (plateforme: IPlateforme): Promise<IPlateforme> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(plateforme)
            }).then(_ => _.json())
    });
}

export const modifyPlateforme = (plateforme: IPlateforme): Promise<IPlateforme> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(plateforme)
            }).then(_ => _.json())
    });
}

export const activationPlateforme = (id: number): Promise<IPlateforme> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/plateforme/activation/${id}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json", "Authorization": accessTokenResponse.idToken
                }
            }).then(_ => _.json())
    });
}

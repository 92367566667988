import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { FormOfferHeader } from '../Form/FormOfferHeader';
import { CloseIcon } from '../Icon/CloseIcon';
import { OfferDetails } from '../Offer/OfferDetails';

import { IFormuleDetails } from '../../model/Formule.model';
import { TransitionSlideUP } from '../../utils/transistions/transitions';

interface Props {
    plateformStyle: any;
    codeOffer: string;
    formuleDetails: IFormuleDetails;
    isDisplayed: boolean;
    onClose: () => void;
}


export const DialogOfferDetails = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle = props.plateformStyle;

    return (
        <Dialog
            open={props.isDisplayed}
            fullWidth
            maxWidth='xl'
            fullScreen={!screenSizeUpSM}
            onClose={() => props.onClose()}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}>
            <DialogTitle
                sx={{
                    pt: 3,
                }} >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 40,
                        right: 40,
                    }}
                    onClick={() => props.onClose()}>
                    <CloseIcon color={plateformStyle.colors.black.main}/>
                </IconButton>
                <FormOfferHeader
                    plateformStyle={plateformStyle}
                    formulesDetailsLength={1}
                    formuleCenter={props.formuleDetails}
                    displaySingle
                    goToPrevious={() => {}}
                    goToNext={() => {}} />

            </DialogTitle>

            <DialogContent>
                <OfferDetails
                    plateformStyle={plateformStyle}
                    codeOffer={props.codeOffer || "512"}
                    formulesDetailsLength={1}
                    formuleCenterId={props.formuleDetails.formule.identifiant}
                    formuleSelectedId={props.formuleDetails.formule.identifiant}
                />
            </DialogContent>
        </Dialog>
    )
}

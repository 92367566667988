import { useEffect, useRef, useState } from "react"

import Box from "@mui/system/Box";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import { downloadDocument, getFormDocument } from "../../api/FormDocument.api";

import { enumFormDocumentTypeBulletin } from "../../utils/enums";
import { DownloadIcon } from "../Icon/DownloadIcon";
import { FullScreenIcon } from "../Icon/FullScreenIcon";
import { Button, Fade, Stack, Tooltip } from "@mui/material";
import { ArrowRightIcon } from "../Icon/ArrowRightIcon";


pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


interface Props {
    plateformStyle: any;
    formUuid: string;
    sendDownloading: (downloading: boolean) => void;
    onClickSecondary?: () => void;
}


export const DocumentDisplay = (props: Props) => {

    const ref = useRef();

    const [numPages, setNumPages] = useState<any>(null);
    const [bulletinAdhesion, setBulletinAdhesion] = useState<any>();
    const [fileName, setFileName] = useState<string>("");

    const onDocumentLoadSuccess = (numPages: any) => {
        setNumPages(numPages._pdfInfo.numPages);
    }

    useEffect(() => {
        props.sendDownloading(true);
        getFormDocument(props.formUuid, enumFormDocumentTypeBulletin).then(response => {
            if (response !== undefined && response.uuid) {
                setFileName(`${response?.fileName}${response?.fileExtension}`);
                downloadDocument(response.uuid).then(response => {
                    setBulletinAdhesion(response);
                    props.sendDownloading(false);
                });
            }
        })
    }, [props.formUuid])


    return (
        <Box
            sx={{
                position: "relative"
            }}>
            {!bulletinAdhesion ?
            <Box
                sx={{
                    textAlign: 'center'
                }} >

                <Typography
                    component='div'
                    variant='h2'
                    sx={{
                        my: 6,
                        mx: 1
                    }} >
                    Téléchargement en cours...
                </Typography>
            </Box>

            :
            <>
            <Stack
                direction={{
                    sm: "column",
                    xs: "row-reverse"
                }}
                spacing={0}
                sx={{
                    position: "absolute",
                    borderRadius: 4,
                    zIndex: 10,
                    right: 10,
                    top: 2
                }}>

                <Tooltip title="Agrandir" placement="left">
                <a
                    href={window.URL.createObjectURL(new Blob([bulletinAdhesion], {type: "application/pdf"}))}
                    target="_blank"
                    rel="noopener noreferrer">
                    <IconButton
                        aria-label="open"
                        sx={{
                            transition: "transform 400ms",
                            ":hover": {
                                transform: "translateY(-6px)"
                            }
                        }}>
                        <FullScreenIcon color={props.plateformStyle.colors.black.main} />
                    </IconButton>
                </a>
                </Tooltip>

                <Tooltip title="Télécharger" placement="left">
                <a
                    href={window.URL.createObjectURL(new Blob([bulletinAdhesion], {type: "application/pdf"}))}
                    target="_blank"
                    rel="noopener noreferrer"
                    download={fileName}>
                    <IconButton
                        aria-label="download"
                        sx={{
                            transition: "transform 400ms",
                            ":hover": {
                                transform: "translateY(-6px)"
                            }
                        }}>
                        <DownloadIcon color={props.plateformStyle.colors.black.main} />
                    </IconButton>
                </a>
                </Tooltip>
            </Stack>
            <Box
                ref={ref}
                sx={{
                    position: "relative",
                    maxHeight: 550,
                    overflow: 'auto',
                    p: 2
                }} >
                <Document
                    file={bulletinAdhesion}
                    onLoadSuccess={onDocumentLoadSuccess} >
                    {Array.apply(null, Array(numPages)).map((x, i) => <>
                        <Page pageNumber={i + 1} scale={((ref.current as any)?.offsetWidth - 32) / 595} />
                        {i + 1 < numPages && <br />}
                    </>)}
                </Document>

                <Fade in={props.onClickSecondary !== undefined}>
                    <Box
                        sx={{
                            position: 'sticky',
                            bottom: 0,
                            right: 0,
                            textAlign: "right",
                            pt: 2
                        }}>
                        <Button
                            variant='contained'
                            color={props.plateformStyle.components.Button.submit.color}
                            sx={{
                                py: 0.5,
                                px: 1,
                                color: 'white',
                                transition: "transform 400ms",
                                ":hover": {
                                    transform: "translateY(-6px)"
                                }
                            }}
                            startIcon={
                                <Box
                                    sx={{
                                        my: 'auto',
                                        transform: "rotate(90deg)",
                                        textAlign: "left",
                                        mr: 1
                                    }}>
                                    <ArrowRightIcon color="white" />
                                </Box>
                            }
                            onClick={props.onClickSecondary} >
                            Signer
                        </Button>
                    </Box>
                </Fade>
            </Box>
            </>
            }
        </Box>
    )
}

import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';

import { InfoIcon } from '../Icon/InfoIcon';

import { updatePlateformeSettingsSubscription } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsSubscription } from '../../model/PlateformeSettings.model';

import { messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settingsSubscription: IPlateformeSettingsSubscription;
    send: (settingsSubscription: IPlateformeSettingsSubscription) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsSubscription = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        hennerSubscription: yup.boolean().required(messageFieldRequired),
        mailSubscription: yup.boolean().required(messageFieldRequired),
    })

    const formik = useFormik({
        initialValues: {
            hennerSubscription: props.settingsSubscription.hennerSubscription,
            mailSubscription: props.settingsSubscription.mailSubscription,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: IPlateformeSettingsSubscription = {
                id: props.settingsSubscription.id,
                hennerSubscription: values.hennerSubscription,
                mailSubscription: values.mailSubscription,
            }
            updatePlateformeSettingsSubscription(toSend)
            .then((response: IPlateformeSettingsSubscription) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement du paramétrage de la souscription.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);

    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <EmojiEventsIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Souscription
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Configurer la méthode de souscription.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Méthodes de souscription
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les webservices Henner de mise en gestion seront appelés en fin de parcours.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.hennerSubscription}
                                        onChange={(e, value) => formik.setFieldValue("hennerSubscription", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Webservices Henner
                                    </Typography>
                                } />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Un mail de mise en gestion sera envoyé à l'adresse précisée dans le back office.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.mailSubscription}
                                        onChange={(e, value) => formik.setFieldValue("mailSubscription", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Mail de mise en gestion
                                    </Typography>
                                } />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import { useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { Alert, LoadingButton } from '@mui/lab';

import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { resetPassword } from '../../../api/auth/Authentication.api';

import { colors } from '../../../static/themes/gsmc/colors';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import Snackbar from '@mui/material/Snackbar';
import { CheckFullIcon } from '../../Icon/CheckFullIcon';
import Slide from '@mui/material/Slide';


export const FormPasswordForgotten = () => {
    const navigate = useNavigate();

    const [loading, setLoading] = useState<boolean>(false);
    const [formSent, setFormSent] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const getInitialValues = () => {
        return {
            email: undefined as string | undefined,
        }
    };

    const validationSchema = yup.object({
        email: yup.string().email("Email non valide").required("Champ obligatoire")
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setLoading(true);

            if (!values.email) {  // Should never occur with the yup validation.
                setLoading(false);
                return;
            }

            resetPassword({
                email: values.email
            })
            .then(response => {
                setFormSent(true);
                setIsSnackbarOpened(true);
                setLoading(false);
            })
            .catch(error => {
                // We don't want show if an email exists or not.
                // formik.setFieldError("email", "Aucun utilisateur n'est associé à cet email");
                setLoading(false);
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                TransitionComponent={(_) => <Slide {..._} direction="down" />} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='success'
                    iconMapping={{
                        success: <CheckFullIcon color='white' bgColor={colors.green.main} />,
                    }} >
                    <Typography fontWeight={500} >Un email vous a été envoyé à votre adresse pour réinitialiser votre mot de passe.</Typography>
                </Alert>
            </Snackbar>

            <Grid
                container
                justifyContent="space-between"
                spacing={4}>
                <Grid
                    item
                    xs={12}
                    sx={{
                    }}>
                    <Button
                        variant='text'
                        color='inherit'
                        sx={{
                            height: 'auto !important'
                        }}
                        onClick={() => {
                            if (formSent)
                                setFormSent(false)
                            else
                                navigate("/auth/signin")
                        }}>
                        <Typography
                            variant='body1'
                            sx={{
                                color: colors.black.main
                            }}>
                            {"< Retour"}
                        </Typography>
                    </Button>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    {formSent ?
                    <>
                    <Typography variant='h1'>Consultez vos emails</Typography>
                    <Typography variant='body2'>
                        Un email vous a été envoyé à votre adresse : <span style={{fontWeight: 700}}>{formik.values.email}</span>
                        <div style={{ paddingTop: "8px" }} />
                        Veuillez le consulter pour finaliser la réinitialisation de votre mot de passe.
                    </Typography>

                        {/* TODO : button renvoyer email */}
                    </>
                    :
                    <>
                    <Typography variant='h1'>Mot de passe oublié ?</Typography>
                    <Typography variant='body2'>Un email vous sera envoyé pour réinitialiser votre mot de passe.</Typography>
                    </>
                    }
                </Grid>

                {!formSent &&
                <>
                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        placeholder="Votre adresse mail"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        loadingIndicator={
                            <Typography
                                noWrap
                                sx={{
                                    textAlign: 'center',
                                    color: 'white'
                                }}>
                                Réinitialisation en cours...
                            </Typography>
                        }
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Réinitialiser mon mot de passe
                    </LoadingButton>
                </Grid>
                </>}

                {formSent &&
                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        onClick={() => navigate("/auth/signin")}
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Fermer
                    </Button>
                </Grid>}

            </Grid>
        </form>
    )
}

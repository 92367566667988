import { msalConfig } from "../utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { ICampaign, ICampaignDTO, ICampaignSettings, ICampaignSettingsFrontDTO, IPageCampaign } from "../model/Campaign.model";

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const apiUrl = process.env.REACT_APP_API_URL + '/backoffice'


export const getCampaigns = (page: number, pageSize: number, search?: string): Promise<IPageCampaign> => {

    let url: string = `${apiUrl}/campaign?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
        {
            method: "GET",
        })
        .then(_ => {
            if (_.status === 200)
                return _.json();
            throw "getCampaigns status=" + _.status;
        })
}


export const getCampaign = (id: number): Promise<ICampaign> => {

    return fetch(`${apiUrl}/campaign/${id}`,
        {
            method: "GET",
        }).then(_ => _.json()
    );
}


export const getCampaignByToken = (token: string): Promise<ICampaign> => {

    return fetch(`${apiUrl}/campaign/token`,
        {
            method: "GET",
            headers: {
                "Authorization": token
            },
        }).then(_ => _.json()
    );
}


export const createCampaign = (campaign: ICampaignDTO): Promise<ICampaign> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/campaign`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(campaign)
            }).then(_ => _.json())
    });
}


export const modifyCampaign = (campaign: ICampaignDTO): Promise<ICampaign> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/campaign`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(campaign)
            }).then(_ => _.json())
    });
}


export const getCampaignSettings = (code: string): Promise<ICampaignSettings> => {

    return fetch(`${apiUrl}/campaign/code/${code}/settings`,
        {
            method: "GET",
        }).then(_ => _.json())
}


export const getCampaignSettingsFront = (code: string): Promise<ICampaignSettingsFrontDTO> => {

    return fetch(`${apiUrl}/campaign/code/${code}/settings/front`,
        {
            method: "GET",
        }).then(_ => _.json())
}


export const updateCampaignSettings = (settings : ICampaignSettings ): Promise<ICampaignSettings> => {
    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/campaign/settings`,
        {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "Authorization": accessTokenResponse.idToken
            },
            body: JSON.stringify(settings)
        }).then(_ => _.json())
    });
}

import './App.css';

import {Navigate, Route, Routes, useNavigate} from 'react-router-dom';

import {MsalProvider} from '@azure/msal-react';
import {IPublicClientApplication} from '@azure/msal-browser';

import TagManager from 'react-gtm-module';

import {CustomNavigationClient} from './utils/CustomNavigationClient';


// Error
import {Layout as LayoutError} from './pages/error/Layout';
import {NotFound} from './pages/error/404';


// BackOffice
import {Layout as LayoutBO} from './pages/backOffice/Layout';
import {PlateformeList} from './pages/backOffice/plateforme/index';
import {PlateformeSettings} from './pages/backOffice/plateforme/settings';
import {AccountList} from './pages/backOffice/account/index';
import {CampaignList} from './pages/backOffice/campaign/index';
import {CampaignSettings} from './pages/backOffice/campaign/settings';
import {ControlList} from './pages/backOffice/control/index';
import {SegmentList} from './pages/backOffice/segment/index';
import {OfferList} from './pages/backOffice/offer/index';
import {GammeList} from './pages/backOffice/gamme/index';
import {ProductList} from './pages/backOffice/product/index';

// Tunnel
import {Home} from './pages/Home';
import {Lead} from './pages/lead/{uuid}'
import {EspaceDocument} from './pages/documents';

// Rappel
import {Contact} from './pages/contact/index';
import {ContactCampaign} from './pages/contact/campaign';

// Auth
import {Layout as LayoutAuth} from './pages/auth/Layout';
import {Signin} from './pages/auth/Signin';
import {Signup} from './pages/auth/Signup';
import {PasswordForgotten} from './pages/auth/PasswordForgotten';

// Control
import { Layout as LayoutControl } from './pages/control/Layout';
import { SubscriptionList } from './pages/control/subscription';
import { Subscription } from './pages/control/subscription/{id}';
import { Account } from './pages/control/account';
import { Cgv } from './pages/Cgv';
import { SubscriptionIncompleteList } from './pages/control/subscription/incomplete';
import { ControlIncompleteList } from './pages/backOffice/control/incomplete';
import { ServerError } from './pages/error/500';
import { PromotionList } from './pages/backOffice/promotion';
import { PromotionNew } from './pages/backOffice/promotion/new';
import { Promotion } from './pages/backOffice/promotion/{id}';
import { Tarification } from './pages/backOffice/tarification';
import { formSignatureOnlySteps } from "./utils";


const moment = require('moment-timezone')
moment.tz.setDefault('Europe/Paris')

type AppProps = {
    pca: IPublicClientApplication
};


const tagManagerArgs = {
    gtmId: process.env.REACT_APP_GOOGLE_GTM_KEY || "",
    events: {
        event: 'gtm.js',
        'gtm.start': new Date().getTime()
    },
}

TagManager.initialize(tagManagerArgs)


function App({ pca }: AppProps) {

    const navigate = useNavigate();
    const navigationClient = new CustomNavigationClient(navigate);
    pca.setNavigationClient(navigationClient);

    return (
        <MsalProvider instance={pca}>
            <Routes>
                <Route path="*" element={<Navigate to="/error/404" replace />}/>
                <Route path="error"  element={<LayoutError />}>
                    <Route path="404" element={<NotFound />} />
                    <Route path="500" element={<ServerError />} />
                </Route>

                <Route index element={<Home />} />

                <Route path="lead">
                    <Route path=":uuid" element={<Lead />} />
                </Route>

                <Route path="sign">
                    <Route path=":uuid" element={<Lead steps={formSignatureOnlySteps} />} />
                </Route>

                <Route path="doc">
                    <Route path=":uuid" element={<EspaceDocument />} />
                </Route>

                <Route path="contact">
                    <Route index element={<Contact />} />
                    <Route path=":token" element={<ContactCampaign />} />
                </Route>

                <Route path="bo" element={<LayoutBO />} >
                    <Route index element={<PlateformeList />} />
                    <Route path="plateforme">
                        <Route index element={<PlateformeList />} />
                        <Route path=":code/settings" element={<PlateformeSettings />} />
                    </Route>
                    <Route path="account" element={<AccountList />} />
                    <Route path="verification" element={<ControlList />} />
                    <Route path="souscription">
                        <Route index element={<ControlList />} />
                        <Route path="incomplete" element={<ControlIncompleteList />} />
                    </Route>
                    <Route path="campaign">
                        <Route index element={<CampaignList />} />
                        <Route path=":code/settings" element={<CampaignSettings />} />
                    </Route>
                    <Route path="promotion">
                        <Route index element={<PromotionList />} />
                        <Route path=":id" element={<Promotion />} />
                        <Route path="new" element={<PromotionNew />} />
                    </Route>
                    <Route path="segment" element={<SegmentList />} />
                    <Route path="offre" element={<OfferList />} />
                    <Route path="gamme" element={<GammeList />} />
                    <Route path="produit" element={<ProductList />} />
                    <Route path="tarification" element={<Tarification />} />

                </Route>

                <Route path="auth" element={<LayoutAuth />} >
                    <Route path="signin" element={<Signin />} />
                    <Route path="signup" element={<Signup />} />
                    <Route path="password/reset" element={<PasswordForgotten />} />
                </Route>

                <Route path="verification" element={<LayoutControl />} >
                    <Route index element={<SubscriptionList />} />
                    <Route path="souscription" >
                        <Route index element={<SubscriptionList />} />
                        <Route path=":id" element={<Subscription />} />
                        <Route path="incomplete" >
                            <Route index element={<SubscriptionIncompleteList />} />
                        </Route>
                    </Route>
                    <Route path="account" >
                        <Route index element={<Account />} />
                    </Route>
                </Route>

                <Route path="cgv" element={<Cgv />} />
            </Routes>
        </MsalProvider>
    );
}

export default App;

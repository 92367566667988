import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { cloneElement, createRef, useEffect, useState } from 'react';
import { FormRadioButton } from '../Form/FormRadioButton';
import { TransitionSlideUP } from '../../utils/transistions/transitions';

import { NeedAudioIcon } from '../../components/Icon/NeedAudioIcon';
import { CloseIcon } from '../Icon/CloseIcon';
import { NeedCureIcon } from '../../components/Icon/NeedCureIcon';
import { NeedDentalIcon } from '../../components/Icon/NeedDentalIcon';
import { NeedHospitalIcon } from '../../components/Icon/NeedHospitalIcon';
import { NeedOpticalIcon } from '../../components/Icon/NeedOpticalIcon';
import { NeedRoutineCareIcon } from '../../components/Icon/NeedRoutineCareIcon';
import { NeedSoftMedicineIcon } from '../../components/Icon/NeedSoftMedicineIcon';

import { IContractNeeds } from '../../model/ContractNeeds.model';
import { IFormuleDetails } from '../../model/Formule.model';

import { modifyContractNeeds } from '../../api/ContractNeeds.api';

import { colors } from '../../static/themes/gsmc/colors';
import Link from '@mui/material/Link';
import { CallbackStatus, CallbackType, ICallback } from '../../model/Callback.model';
import { DialogContact } from './DialogContact';
import { IForm } from '../../model/Form.model';
import { FormContact } from '../Form/Contact/FormContact';
import SendIcon from '@mui/icons-material/Send';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';
import Slide from '@mui/material/Slide';
import Alert, { AlertColor } from '@mui/material/Alert';
import { CheckFullIcon } from '../Icon/CheckFullIcon';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import { default as MUICloseIcon} from '@mui/icons-material/Close';


interface Props {
    plateformStyle: any;
    isOpened: boolean;
    contractNeeds?: IContractNeeds;
    formules?: IFormuleDetails[];
    callback?: ICallback;
    handleClose: () => void;
    sendContractNeeds: (need: IContractNeeds) => void;
    sendCallback?: (callback: ICallback) => void;
}


const initialValues = [
    {
        label: 'Hospitalisation',
        icon: <NeedHospitalIcon color={colors.orange.main} />,
        selected: '2'
    },
    {
        label: 'Soins courants',
        icon: <NeedRoutineCareIcon color={colors.orange.main} />,
        selected: '2'
    },
    {
        label: 'Optique',
        icon: <NeedOpticalIcon color={colors.orange.main} />,
        selected: '2'
    },
    {
        label: 'Dentaire',
        icon: <NeedDentalIcon color={colors.orange.main} />,
        selected: '2'
    },
    {
        label: 'Médecine douce',
        icon: <NeedSoftMedicineIcon color={colors.orange.main} bgColor={colors.grey.secondary} />,
        selected: '2'
    },
    {
        label: 'Prothèse auditive',
        icon: <NeedAudioIcon color={colors.orange.main} />,
        selected: '2'
    },
    {
        label: 'Cure de soin',
        icon: <NeedCureIcon color={colors.orange.main} />,
        selected: '2'
    }
]

export const DialogNeeds = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));
    const screenSizeUpXL = useMediaQuery(theme.breakpoints.up('xl'));
    const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));
    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));

    const plateformStyle: any = props.plateformStyle;
    const iconStyle: any = plateformStyle.components.Icon;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;
    const radioButtonStyle: any = plateformStyle.components.RadioButton;

    const formRef = createRef<HTMLFormElement>();

    const [needs, setNeeds] = useState<{ label: string, icon: JSX.Element, selected?: string }[]>([]);
    const [isDialogCallbackOpened, setIsDialogCallbackOpened] = useState<boolean>(false);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };


    useEffect(() => {
        let newNeeds: { label: string, icon: JSX.Element, selected?: string }[] = [];

        switch (props.contractNeeds?.codeOffre) {
            case "312":
                newNeeds = [...initialValues];
                newNeeds[0].selected = props.contractNeeds?.needHospital?.toString();
                newNeeds[1].selected = props.contractNeeds?.needRoutineCare?.toString();
                newNeeds[2].selected = props.contractNeeds?.needOptical?.toString();
                newNeeds[3].selected = props.contractNeeds?.needDental?.toString();
                newNeeds[4].selected = props.contractNeeds?.needSoftMedicine?.toString();
                newNeeds[5].selected = props.contractNeeds?.needAudio?.toString();
                newNeeds[6].selected = props.contractNeeds?.needHealthCure?.toString();
                break;

            default:
            case "512":
                newNeeds = [...initialValues.filter(f => f.label !== "Cure de soin")];
                newNeeds[0].selected = props.contractNeeds?.needHospital?.toString();
                newNeeds[1].selected = props.contractNeeds?.needRoutineCare?.toString();
                newNeeds[2].selected = props.contractNeeds?.needOptical?.toString();
                newNeeds[3].selected = props.contractNeeds?.needDental?.toString();
                newNeeds[4].selected = props.contractNeeds?.needSoftMedicine?.toString();
                newNeeds[5].selected = props.contractNeeds?.needAudio?.toString();
                break;

            case "539":
                newNeeds = [...initialValues.filter(f => f.label !== "Cure de soin" && f.label !== "Prothèse auditive")];
                newNeeds[0].selected = props.contractNeeds?.needHospital?.toString();
                newNeeds[1].selected = props.contractNeeds?.needRoutineCare?.toString();
                newNeeds[2].selected = props.contractNeeds?.needOptical?.toString();
                newNeeds[3].selected = props.contractNeeds?.needDental?.toString();
                newNeeds[4].selected = props.contractNeeds?.needSoftMedicine?.toString();
                break;
        }

        setNeeds(newNeeds);

    }, [props.contractNeeds])

    const formToContractNeeds = () => {

        if (!props.contractNeeds)
            return;

        const contractNeeds = props.contractNeeds;

        switch (props.contractNeeds?.codeOffre) {
            case "312":
                contractNeeds.needHospital = parseInt(needs[0].selected ? needs[0].selected : '0');
                contractNeeds.needRoutineCare = parseInt(needs[1].selected ? needs[1].selected : '0');
                contractNeeds.needOptical = parseInt(needs[2].selected ? needs[2].selected : '0');
                contractNeeds.needDental = parseInt(needs[3].selected ? needs[3].selected : '0');
                contractNeeds.needSoftMedicine = parseInt(needs[4].selected ? needs[4].selected : '0');
                contractNeeds.needAudio = parseInt(needs[5].selected ? needs[5].selected : '0');
                contractNeeds.needHealthCure = parseInt(needs[6].selected ? needs[6].selected : '0');
                contractNeeds.needSoftwareService = null;
                break;

            default:
            case "512":
                contractNeeds.needHospital = parseInt(needs[0].selected ? needs[0].selected : '0');
                contractNeeds.needRoutineCare = parseInt(needs[1].selected ? needs[1].selected : '0');
                contractNeeds.needOptical = parseInt(needs[2].selected ? needs[2].selected : '0');
                contractNeeds.needDental = parseInt(needs[3].selected ? needs[3].selected : '0');
                contractNeeds.needSoftMedicine = parseInt(needs[4].selected ? needs[4].selected : '0');
                contractNeeds.needAudio = parseInt(needs[5].selected ? needs[5].selected : '0');
                contractNeeds.needHealthCure= null;
                contractNeeds.needSoftwareService = null;
                break;

            case "539":
                contractNeeds.needHospital = parseInt(needs[0].selected ? needs[0].selected : '0');
                contractNeeds.needRoutineCare = parseInt(needs[1].selected ? needs[1].selected : '0');
                contractNeeds.needOptical = parseInt(needs[2].selected ? needs[2].selected : '0');
                contractNeeds.needDental = parseInt(needs[3].selected ? needs[3].selected : '0');
                contractNeeds.needSoftMedicine = parseInt(needs[4].selected ? needs[4].selected : '0');
                contractNeeds.needAudio = null;
                contractNeeds.needHealthCure = null;
                contractNeeds.needSoftwareService = null;
                break;
        }

        return contractNeeds;
    }


    const handleClick = (name: string, code: string) => {
        const index = needs.findIndex(n => n.label === name);
        const newNeeds = [...needs];

        newNeeds[index].selected = code;

        setNeeds(newNeeds);
    }

    const handleValidate = () => {

        const contractNeeds = formToContractNeeds()

        if (!contractNeeds) return

        modifyContractNeeds(contractNeeds).then(response => {
            props.sendContractNeeds(response);
            props.handleClose();
        })
    }

    const needsUI = needs.filter((_, id) => {
        if (screenSizeUpXL)
            return id < 6;
        else if (screenSizeUpLG)
            return id < 5;
        else if (screenSizeUpMD)
            return id < 6;

        return true
    })

    useEffect(() => {
        if (props.isOpened)
            setIsDialogCallbackOpened(false)
    }, [props.isOpened])


    return (
        <>
        <Dialog
            fullWidth
            maxWidth={isDialogCallbackOpened ? "lg" : "xl"}
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}>
            <IconButton
                sx={{
                    position: 'absolute',
                    top: 10,
                    right: 10,
                }}
                onClick={() => props.handleClose()}>
                <CloseIcon color={plateformStyle.colors.black.main}/>
            </IconButton>

            {!isDialogCallbackOpened || props.callback === undefined ?
            <>
            <DialogTitle>
                <Typography variant="h2">Quels sont vos besoins ?</Typography>
                <Typography fontWeight={500}>Découvrez quelle offre vous correspond le mieux !</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        pt: {
                            md: 0,
                            xs: 2
                        }
                    }}>
                    <Grid
                        item
                        xs={12}>
                        <Grid
                            container
                            spacing={2}
                            justifyContent="flex-start"
                            alignItems="center">
                            {needsUI.map((need) => { return (
                            <Grid
                                xl={needsUI.length > 5 ? 2 : 2.4}
                                lg={2.4}
                                md={4}
                                xs={12}
                                key={need.label}
                                item >
                                <Card
                                    sx={{
                                        p: 2
                                    }}
                                    key={need.label} >
                                    <Grid
                                        container
                                        spacing={2}>
                                        <Grid
                                            item
                                            xs={12}
                                            sx={{
                                                display: 'flex',
                                                my: 'auto'
                                            }} >
                                            <Grid
                                                container
                                                alignItems="center"
                                                spacing={2}
                                                wrap="nowrap" >
                                                <Grid
                                                    item
                                                    xs="auto" >
                                                    {cloneElement(need.icon, { color: iconStyle.color })}
                                                </Grid>
                                                <Grid
                                                    item
                                                    xs >
                                                    <Typography variant='h3' >{need.label}</Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
        
                                        <Grid
                                            item
                                            xs={12}>
                                            <Grid
                                                container
                                                spacing={{
                                                    md: 2,
                                                    xs: 1
                                                }} >
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs >
                                                    <FormRadioButton
                                                        code='0'
                                                        selected={need.selected === '0'}
                                                        color={radioButtonStyle.color}
                                                        onClick={(code) => handleClick(need.label, code)}>
                                                        Minimum
                                                    </FormRadioButton>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs>
                                                    <FormRadioButton
                                                        code='2'
                                                        selected={need.selected === '2'}
                                                        color={radioButtonStyle.color}
                                                        onClick={(code) => handleClick(need.label, code)}>
                                                        Moyen
                                                    </FormRadioButton>
                                                </Grid>
                                                <Grid
                                                    item
                                                    md={12}
                                                    xs >
                                                    <FormRadioButton
                                                        code='5'
                                                        selected={need.selected === '5'}
                                                        color={radioButtonStyle.color}
                                                        onClick={(code) => handleClick(need.label, code)}>
                                                        Fort
                                                    </FormRadioButton>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Card>
                            </Grid>)})}
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2,
                    pt: 1,
                    justifyContent: "center"
                }}>
                <Grid
                    container
                    spacing={{
                        md: 2,
                        xs: 1
                    }}
                    justifyContent="center"
                    alignItems="center"
                    sx={{
                        textAlign: {
                            sm: "center",
                            xs: "left"
                        }
                    }}>
                    {props.callback &&
                    <Grid
                        item
                        xs={12}>
                        <Link
                            onClick={() => {setIsDialogCallbackOpened(true)}} >
                            <span style={{whiteSpace: "nowrap"}}>Un besoin spécifique ?</span> <span style={{whiteSpace: "nowrap"}}>Discutez-en avec l'un de nos conseillers.</span>
                        </Link>
                    </Grid>}
                    <Grid
                        item
                        xs={12}>
                        <Button
                            type="submit"
                            variant="contained"
                            color={buttonSubmitStyle.color}
                            sx={{
                                width: {
                                    sm: 400,
                                    xs: '100%'
                                },
                                px: 5,
                                color: 'white'
                            }}
                            onClick={() => {
                                handleValidate()
                            }} >
                            Continuer
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
            </>
            :

            <>
            <DialogTitle>
                <Typography variant="h2">Un besoin spécifique ?</Typography>
                <Typography fontWeight={500}>Demandez à être rappelé pour en discuter avec un de nos conseillers.</Typography>
            </DialogTitle>

            <DialogContent>
                {props.callback.form &&
                <FormContact
                    ref={formRef}
                    plateformStyle={plateformStyle}
                    payload={props.callback}
                    send={(_) => {
                        if (_.id !== undefined) {
                            setSnackbarMessage("Votre demande de rappel a bien été prise en compte.");
                            setSnackbarSeverity("success")
                            setIsSnackbarOpened(true)
                        }
                        else {
                            setSnackbarMessage("Une erreur est survenue lors de votre demande de rappel.");
                            setSnackbarSeverity("error")
                            setIsSnackbarOpened(false)
                        }
                        setIsDialogCallbackOpened(false)

                        if (props.sendCallback)
                            props.sendCallback(_)
                    }} />}
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Button
                    onClick={() => setIsDialogCallbackOpened(false)}
                    sx={{
                        px: 3,
                        mr: 2,
                        color: plateformStyle.colors.black.main
                    }} >
                    Retour
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={() => {
                        if (formRef.current)
                            formRef.current.handleSubmit();

                    }}
                    sx={{
                        px: 3,
                        color: 'white',
                        backgroundColor: plateformStyle.colors.black.main,
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }} >
                    Envoyer
                </Button>
            </DialogActions>
            </>}
        </Dialog>

        <Portal>
            <Snackbar
                sx={{
                    zIndex: 10000,
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                    }}
                    severity={snackbarSeverity as AlertColor}
                    iconMapping={{
                        success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                        error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleSnackbarClose}>
                            <MUICloseIcon fontSize="inherit" fontWeight={700} />
                        </IconButton>
                    }>
                    <Typography fontWeight={500}>{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>
        </Portal>
        </>
    )
}

import Box from "@mui/material/Box";
import PersonIcon from '@mui/icons-material/Person';

import { useNavigate } from "react-router-dom";
import { FormAccount } from "../../../components/Form/Account/FormAccount";

import { useEffect, useState } from "react";
import { IUser } from "../../../model/auth/User.model";
import AuthService from "../../../services/AuthService";
import { getUserCurrent } from "../../../api/auth/User.api";
import AppBarHeader from "../../../components/AppBar/AppBarHeader";


const authService = new AuthService();

export const Account = () => {

    const navigate = useNavigate();

    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        if (!authService.isLoggedIn()) {
            navigate("/auth/signin")
            return;
        }

        getUserCurrent().then(response => {
            setUser(response);
            document.title = `@${response.username}`;
        })
    }, [])

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <PersonIcon />},
                {type: "LINK", label: "Compte"}
            ]} />

        <Box
            sx={{
                overflow:"auto",
                maxWidth: "1200px",
                p: 2
            }} >
            {user &&
            <FormAccount
                user={user}
                send={() => {}} />
            }
        </Box>
        </>
    )
}

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { colors } from '../../static/themes/colors';


interface Props {
    isOpened: boolean;
    title: string | JSX.Element;
    description?: string | JSX.Element;
    textClose?: string
    textContinue?: string
    handleClose: () => void;
    handleContinue: () => void;
}


export const DialogContinue = (props: Props) => {

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            sx={{
                borderRadius: 40
            }} >

            <DialogTitle id="alert-dialog-title">
                <Typography variant="h2">{props.title}</Typography>
            </DialogTitle>

            {props.description &&
            <DialogContent>
                <Typography variant="body1">{props.description}</Typography>
            </DialogContent>}

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end" >
                    <Grid
                        item
                        sm="auto"
                        xs={12} >
                        <Button
                            fullWidth
                            variant="text"
                            onClick={() => props.handleClose()}
                            sx={{
                                px: 3,
                                color: colors.black.main
                            }} >
                            {props.textClose || "Recommencer"}
                        </Button>
                    </Grid>
                    <Grid
                        item
                        sm="auto"
                        xs={12} >
                        <Button
                            fullWidth
                            autoFocus
                            variant="contained"
                            color="success"
                            onClick={() => props.handleContinue()}
                            sx={{
                                px: 3,
                                color: 'white',
                                backgroundColor: colors.black.main,
                                ':hover': {
                                    backgroundColor: 'black'
                                }
                            }} >
                            {props.textContinue || "Continuer"}
                            
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
        )
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const NeedSoftMedicineIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M1 15.4878C11.2151 16.0081 19.3376 24.4555 19.3376 34.8V38.0454C9.1225 37.525 1 29.0777 1 18.7332L1 15.4878Z" fill={props.bgColor || "none"} stroke={props.color} strokeWidth="2" strokeLinejoin="round"/>
        <path d="M19.9905 2.44207C27.4257 12.239 27.3332 27.398 19.713 37.0615C12.2779 27.2646 12.3704 12.1056 19.9905 2.44207Z" fill={props.bgColor || "none"} stroke={props.color} strokeWidth="2" strokeLinejoin="round"/>
        <path d="M39 15.4878L39 18.7332C39 29.0777 30.8775 37.525 20.6624 38.0454L20.6624 34.8C20.6624 24.4555 28.7849 16.0081 39 15.4878Z" fill={props.bgColor || "none"} stroke={props.color} strokeWidth="2" strokeLinejoin="round"/>
        </svg>
    )
}

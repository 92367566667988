import { IPerson } from "../model/Person.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getPerson = (id: number) : Promise<IPerson> => {

    return fetch(`${apiUrl}/person/${id}`,
    {
        method: "GET",

    }).then(_ => _.json())
}


export const getPersonConjoint = (id: number) : Promise<IPerson> => {

    return fetch(`${apiUrl}/person/${id}/conjoint`,
    {
        method: "GET",

    }).then(_ => _.json())
}


export const getPersonChildrens = (id: number) : Promise<IPerson[]> => {

    return fetch(`${apiUrl}/person/${id}/childrens`,
    {
        method: "GET",

    }).then(_ => _.json())
}


export const checkPersonPhone = (id: number, phone: string) : Promise<any> => {

    return fetch(`${apiUrl}/person/${id}/unique?phone=${phone.replaceAll("+", "").replaceAll(" ", "")}`,
    {
        method: "GET",
    })
    .then(_ => _.json())
}


export const createPerson = (person: IPerson) : Promise<IPerson> => {

    return fetch(`${apiUrl}/person`,
    {
        method: "POST",
        headers: {"content-type": "application/json"},
        body: JSON.stringify(person)

    }).then(_ => _.json()) 
}


export const modifyPerson = (person: IPerson) : Promise<IPerson> => {

    return fetch(`${apiUrl}/person`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(person)

    }).then(_ => _.json())
}


export const deletePerson = (id: number) : Promise<any> => {

    return fetch(`${apiUrl}/person/${id}`,
    {
        method: "DELETE",

    })
}

import { Button } from "@mui/material"

import { colors } from '../../static/themes/colors'


interface Props {
    children: string;
    code: string;
    selected: boolean;
    color?: string;
    disabled?: boolean;
    variant?: "outlined" | "contained";
    onClick: (code: string) => void;
}


export const FormRadioButton = (props: Props) => {

    return (
        <Button
            fullWidth
            variant={props.variant || "outlined"}
            onClick={() => !props.disabled && props.onClick(props.code)}
            sx={{
                py: 1,
                cursor: props.disabled ? 'default' : 'pointer',
                color: props.variant === "contained" ? "white" : props.selected ? (props.color ? props.color : colors.orange.main) : colors.black.secondary,
                backgroundColor: props.variant === "contained" ? props.color : "",
                border: props.variant === "contained" ? 0 : 2,
                borderColor: props.selected ? (props.color ? props.color : colors.orange.main) : colors.black.secondary,
                ':hover': {
                    filter: 'brightness(85%)',
                    backgroundColor: props.variant === "contained" ? props.color ? props.color : colors.orange.main : "",
                    border: props.variant === "contained" ? 0 : 2,
                    borderColor: props.selected ? (props.color ? props.color : colors.orange.main) : colors.black.secondary,
                }
            }} >
            {props.children}
        </Button>
    )
}

import React, { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import Autocomplete from '@mui/material/Autocomplete';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';
import usePlacesService from "react-google-autocomplete/lib/usePlacesAutocompleteService";

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldFormatted } from '../Field/TextFieldFormatted';

import { IAdress } from '../../model/Adress.model';

import { enumGsmcRegimeAM, enumGsmcRegimeGeneral, messageFieldRequired } from '../../utils';
import { IPerson } from '../../model/Person.model';
import { CardInfo } from '../Card/CardInfo';
import Link from '@mui/material/Link';
import { Collapse, Divider, Typography } from '@mui/material';
import { DialogRegime } from '../Dialog/DialogRegime';


interface Props {
    plateformStyle: any;
    adress?: IAdress;
    person?: IPerson;
    send: (adress: IAdress) => void;
    save: (adress: IAdress) => void;
    sendProgress?: (progress: number) => void;
    sendPerson?: (person: IPerson) => void;
    goToNext: () => void;
}


interface IAdressComponent {
    long_name: string;
    short_name: string;
    types: string[];
}


const googleApiKey = process.env.REACT_APP_GOOGLE_API_KEY;
const nbMaxCallAPI = 100;

const nbFields = 3;

export const FormAdress = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();
        },
        leave() {
            send();
        }
    }));

    const [nbCallAPI, setNbCallAPI] = useState<number>(0);
    const [isCardInfoDisplay, setIsCardInfoDisplay] = useState<boolean>(false);
    const [isCardErrorDisplay, setIsCardErrorDisplay] = useState<boolean>(false);
    const [isDialogRegimeDisplay, setIsDialogRegimeDisplay] = useState<boolean>(false);

    const validationSchema = yup.object({
        adressLine1: yup
            .string()
            .nullable()
            .required(messageFieldRequired)
            .max(100, "Champ non valide (100 caractères max)"),
        postalCode: yup
            .string()
            .nullable()
            .matches(/^[0-9]+$/, "Code postal non valide")
            .min(5, 'Code postal non valide (5 caractères nécessaires)')
            .max(5, 'Code postal non valide')
            .required(messageFieldRequired)
            .test('invalid',
                `Code postal non valide`,
                value => {
                    if (value === undefined || value === null) return true;
    
                    const cp = parseInt(value);
                    if (isNaN(cp)) return true;
    
                    if (Math.floor(cp / 1000) === 96 || Math.floor(cp / 1000) >= 99 || Math.floor(cp / 1000) == 0) return false;
                    return true;
                })
            .test('corse',
                `Le département de la Corse n'est pas géré par nos services`,
                value => {
                    if (value === undefined || value === null) return true;
    
                    const cp = parseInt(value);
                    if (isNaN(cp)) return true;
    
                    if (Math.floor(cp / 1000) === 20) return false;
    
                    return true;
                })
            .test('drom-tom',
                `Les départements des DROM-TOM ne sont pas gérés par nos services`,
                value => {
                    if (value === undefined || value === null) return true;
    
                    const cp = parseInt(value);
                    if (isNaN(cp)) return true;

                    if (Math.floor(cp / 1000) === 97 || Math.floor(cp / 1000) === 98) return false;
                    return true;
                })
            .test('AM',
                `Département et régime incompatibles`,
                value => {
                    setIsCardInfoDisplay(false)

                    if (value === undefined || value === null || value.length !== 5) return true;

                    const cp = parseInt(value);
                    if (isNaN(cp)) return true;

                    const inAM: boolean = Math.floor(cp / 1000) === 57 || Math.floor(cp / 1000) === 67 || Math.floor(cp / 1000) === 68;

                    if (props.person?.regime === enumGsmcRegimeAM && !inAM) {
                        if (isCardErrorDisplay === false) {
                            setIsCardErrorDisplay(true)
                            setIsDialogRegimeDisplay(true)
                        }
                        return false;
                    }
                    else if (props.person?.regime !== enumGsmcRegimeAM && inAM) {
                        setIsCardInfoDisplay(true)
                    }

                    setIsCardErrorDisplay(false)
    
                    return true;
                }),
        city: yup
            .string()
            .nullable()
            .required(messageFieldRequired)
            .max(100, "Champ non valide (100 caractères max)"),
        country: yup
            .string()
            .nullable()
            .required(messageFieldRequired)
            .max(100, "Champ non valide (100 caractères max)")
            .test('france',
                `Veuillez saisir une adresse Française pour pouvoir accéder à nos services`,
                value => {
                    if (value === undefined || value === null) return true;
    
                    if (value.toUpperCase() !== "FRANCE") return false;
    
                    return true;
                }),
    });

    const {
        placesService,
        placePredictions,
        isPlacePredictionsLoading,
        getPlacePredictions
    } = usePlacesService({
        apiKey: googleApiKey,
        options: {
            componentRestrictions: { country: 'FR' }
        }
    });

    // Get the adress: IAdress from the form fields.
    const getAdressFromForm = () => {
        let adress: IAdress = { ...props.adress, postalCode: '' };

        adress.adressLine1 = formik.values.adressLine1;
        adress.adressLine2 = formik.values.adressLine2;
        adress.postalCode = formik.values.postalCode as string;
        adress.city = formik.values.city;
        adress.country = formik.values.country;

        return adress;
    };

    const formik = useFormik({
        initialValues: {
            adressLine1: props.adress?.adressLine1,
            adressLine2: props.adress?.adressLine2,
            postalCode: props.adress?.postalCode,
            city: props.adress?.city,
            country: props.adress?.country || "FRANCE"
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save();
            props.goToNext();
        }
    });

    const save = () => {
        props.save(getAdressFromForm());
    }

    const send = () => {
        props.send(getAdressFromForm());
    }

    useEffect(() => {
        if (!formik.touched.postalCode) return
        formik.validateForm().then((errors: FormikErrors<any>) => {
            if (errors.postalCode) return;
            send();
        })
    }, [formik.values.postalCode])

    const sendProgress = () => {
        if (props.sendProgress)
            props.sendProgress((Object.keys(formik.values).filter(_ =>_ !== "adressLine2" && _ !== "country").length - Object.keys(formik.errors).filter(_ =>_ !== "adressLine2" && _ !== "country").length) / nbFields * 100);
    }

    useEffect(() => {
        formik.validateForm()
        sendProgress();
    }, [])

    useEffect(() => {
        sendProgress();
    }, [formik.values,
        formik.errors])

    useEffect(() => {
        formik.validateForm()
    }, [props.person?.regime])


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{ textAlign: 'left' }} >
                <Grid
                    item
                    xs={12}
                    sx={{
                        p: isCardErrorDisplay ? "" : "0px !important"
                    }}>
                    <Collapse in={isCardErrorDisplay}>
                        <CardInfo
                            plateformStyle={props.plateformStyle}
                            type="error">
                            <Typography fontWeight={500}>
                                Votre département n'est pas compatible avec votre régime (Alsace-Moselle).
                            </Typography>
                            <Typography fontWeight={700} sx={{mt: 1}}>
                                Vérifiez votre adresse ou sélectionner le régime Général en <Link onClick={() => setIsDialogRegimeDisplay(true)}>cliquant ici</Link>.
                            </Typography>

                            <Divider sx={{my: 1}}/>
                            <Typography fontWeight={500}>
                                ⚠️ Changer votre régime de Sécurité Sociale aura une incidence sur le tarif proposé.
                            </Typography>
                        </CardInfo>
                    </Collapse>
                </Grid>

                {/* 
                <Grid
                    item
                    xs={12}
                    sx={{
                        p: isCardInfoDisplay ? "" : "0px !important"
                    }}>
                    <Collapse in={isCardInfoDisplay}>
                        <CardInfo
                            plateformStyle={props.plateformStyle}
                            type="info">
                            <Typography fontWeight={500}>
                                Votre adresse est située dans un département d'Alsace-Moselle.
                            </Typography>
                            <Typography fontWeight={500} sx={{mt: 1}}>
                                Si vous bénéficiez du régime Alsace-Moselle, vous pouvez le sélectionner en <Link onClick={() => setIsDialogRegimeDisplay(true)}>cliquant ici</Link>.
                            </Typography>
                        </CardInfo>
                    </Collapse>
                </Grid> */}

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.adressLine1 && Boolean(formik.errors.adressLine1)} >
                        <FieldLabel label="Adresse" isRequired />
                    </FormLabel>
                    <Autocomplete
                        autoComplete={true}
                        id="adressLine1"
                        value={formik.values.adressLine1}
                        onChange={(e, value) => {
                            if (value.place_id) {
                                // Get the place details.
                                placesService.getDetails(
                                    {placeId: value.place_id},
                                    (placeDetails: any) => {
                                        const adress: IAdressComponent[] = placeDetails.address_components;

                                        const streetNumber: string | undefined = adress.find(c => c.types.includes("street_number"))?.long_name;
                                        const colloquialArea: string | undefined = adress.find(c => c.types.includes("colloquial_area"))?.long_name;
                                        const route: string | undefined = adress.find(c => c.types.includes("route"))?.long_name;
                                        const postalCode: string | undefined = adress.find(c => c.types.includes("postal_code"))?.long_name;
                                        const city: string | undefined = adress.find(c => c.types.includes("locality"))?.long_name;
                                        const country: string | undefined = adress.find(c => c.types.includes("country"))?.long_name;

                                        formik.setValues({
                                            adressLine1: streetNumber && route ? `${streetNumber} ${route}`
                                                : route ? route
                                                : colloquialArea ? colloquialArea
                                                : undefined,
                                            adressLine2: formik.values.adressLine2,
                                            postalCode: postalCode || formik.values.postalCode,
                                            city: city || formik.values.city,
                                            country: country || formik.values.country
                                        }, true)

                                        setNbCallAPI((prev) => prev++);
                                    })
                            }
                        }}
                        freeSolo
                        options={placePredictions}
                        getOptionLabel={(prediction) => {
                            return prediction.description ? prediction.description : prediction;
                        }}
                        filterOptions={
                            (options, state) => options  // Disable the Autocomplete filter.
                        }
                        disableClearable
                        loading={isPlacePredictionsLoading}
                        renderInput={
                            (params) => {
                                return (<TextField
                                    {...params}
                                    autoComplete='off'
                                    name="adressLine1"
                                    onChange={(e) => {
                                        formik.setFieldValue('adressLine1', e.target.value).then(() => { formik.setFieldTouched('adressLine1', true, true) })
                                        if (nbCallAPI < nbMaxCallAPI)
                                            getPlacePredictions({ input: e.target.value });
                                    }}
                                    error={(formik.touched.adressLine1 && Boolean(formik.errors.adressLine1)) || (formik.touched.country && Boolean(formik.errors.country))}
                                    helperText={(formik.touched.adressLine1 && Boolean(formik.errors.adressLine1)) ? formik.errors.adressLine1 : formik.errors.country} />)
                            }
                        } />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <TextField
                        fullWidth
                        placeholder="Complément d'adresse"
                        id="adressLine2"
                        name="adressLine2"
                        value={formik.values.adressLine2}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.adressLine2 && Boolean(formik.errors.adressLine2)}
                        helperText={formik.touched.adressLine2 && formik.errors.adressLine2}/>
                </Grid>

                <Grid item xs={12} sm={4} >
                    <FormLabel error={Boolean(formik.errors.postalCode)} >
                        <FieldLabel label="Code postal" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="postalCode"
                        fullWidth
                        onChange={(e, value) => formik.setFieldValue("postalCode", value)}
                        onBlur={(e: any) => formik.setFieldTouched("postalCode")}
                        value={formik.values.postalCode}
                        error={Boolean(formik.errors.postalCode)}
                        helperText={formik.errors.postalCode}
                        charactersEnabled="[^0-9A-z\-\s]"
                        textLength={5} />
                </Grid>

                <Grid item xs={12} sm={8} >
                    <FormLabel error={formik.touched.city && Boolean(formik.errors.city)} >
                        <FieldLabel label="Ville" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="city"
                        fullWidth
                        onChange={(e, value) => formik.setFieldValue("city", value)}
                        value={formik.values.city}
                        error={formik.touched.city && Boolean(formik.errors.city)}
                        helperText={formik.touched.city && formik.errors.city}
                        charactersEnabled="[^A-zÀ-ú\-\s]"
                        textLength={100} />
                </Grid>

                {/* <Grid item xs={12} >
                    <FormLabel error={formik.touched.country && Boolean(formik.errors.country)} >
                        <FieldLabel label="Pays" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="country"
                        fullWidth
                        onChange={(e, value) => formik.setFieldValue("country", value)}
                        value={formik.values.country}
                        error={formik.touched.country && Boolean(formik.errors.country)}
                        helperText={formik.touched.country && formik.errors.country}
                        toUpperCase
                        charactersEnabled="[^A-zÀ-ú\-\s]"
                        textLength={100} />
                </Grid> */}

                {props.person &&
                <DialogRegime
                    plateformStyle={props.plateformStyle}
                    isDisplayed={isDialogRegimeDisplay}
                    subscriber={props.person}
                    onChange={(_: string) => {
                        if (props.person === undefined || props.sendPerson === undefined) return

                        let subscriber = {...props.person, regime: _}
                        props.sendPerson(subscriber)

                        setIsDialogRegimeDisplay(false)
                        setIsCardErrorDisplay(false)
                        setIsCardInfoDisplay(false)
                    }}
                    onClose={() => setIsDialogRegimeDisplay(false)} />}
            </Grid>
        </form>
    )
})

import { useEffect, useRef, useState } from "react";

import { useFormik } from "formik";
import * as yup from 'yup';

import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from "@mui/material/Chip";
import Divider from "@mui/material/Divider";
import FormLabel from "@mui/material/FormLabel";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import LinearProgress from '@mui/material/LinearProgress';
import Link from "@mui/material/Link";
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import Snackbar from '@mui/material/Snackbar';
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from '@mui/material/Typography';
import WarningIcon from '@mui/icons-material/Warning';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { CardError } from "../../../components/Card/CardError";
import { CardInfo } from "../../../components/Card/CardInfo";
import { CardSubscriberInfo } from "../../../components/Card/CardSubscriberInfo";
import { CardValidateDocument } from "../../../components/Card/CardValidateDocument";
import { ArrowLeftIcon } from "../../../components/Icon/ArrowLeftIcon";
import { ArrowRightIcon } from "../../../components/Icon/ArrowRightIcon";
import { CloseFullIcon } from "../../../components/Icon/CloseFullIcon";
import { DownloadIcon } from "../../../components/Icon/DownloadIcon";
import { FullScreenIcon } from "../../../components/Icon/FullScreenIcon";
import { FieldLabel } from "../../../components/Field/FieldLabel";

import { deactivateControl, megControl, reactivateControl, updateControlDocument } from "../../../api/Controle.api";
import { getFormContext } from "../../../api/Form.api";
import { downloadDocument } from "../../../api/FormDocument.api";
import { getPlateformeSettings } from "../../../api/PlateformeSettings.api";

import { IContractBilling } from "../../../model/ContractBilling.model";
import { ControlStatus, IControle, IControleDocument, IControleDocumentRequestPOST, controlStatusParam } from "../../../model/ControleDocument.model";
import { IForm } from "../../../model/Form.model";
import { IFormContext } from "../../../model/FormContext.model";
import { documentOrderByType } from "../../../model/FormDocument.model";
import { IPlateformeSettings } from "../../../model/PlateformeSettings.model";
import { IPlateforme } from "../../../model";

import { enumFormDocumentTypeCniRecto,
         enumFormDocumentTypeCniVerso,
         enumFormDocumentTypeRIB,
         enumFormDocumentTypeAttestationCPAM,
         enumFormDocumentTypeKbis,
         enumFormDocumentTypes } from "../../../utils/enums";
import { messageFieldRequired } from "../../../utils";

import { style as styleGSMC } from '../../../static/themes/gsmc/style';
import { colors } from "../../../static/themes/gsmc/colors";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;


const dateContractMin = new Date();
dateContractMin.setDate(dateContractMin.getDate() - 7);
dateContractMin.setHours(0, 0, 0, 0);

const dateContractMax = new Date();
dateContractMax.setFullYear(dateContractMax.getFullYear() + 1);


interface Props {
    control: IControle;
    send: (control: IControle) => void;
}


export const ControlValidation = (props: Props) => {
    
    const ref = useRef();

    // Theming.
    const [plateformStyle, setPlateformStyle] = useState<any>(styleGSMC);
    const [isSnackbarOpened, setIsSnackBarOpen] = useState<boolean>(false);

    const [control, setControl] = useState<IControle | null>();
    const [documents, setDocuments] = useState<IControleDocument[]>([]);
    const [plateform, setPlateform] = useState<IPlateforme>();
    const [plateformSettings, setPlateformSettings] = useState<IPlateformeSettings>();
    const [form, setForm] = useState<IForm>();
    const [contractBilling, setContractBilling] = useState<IContractBilling>();

    const [docSize, setDocSize] = useState<number>(0);
    const [docIndex, setDocIndex] = useState<number>();
    const [isSummary, setIsSummary] = useState<boolean>(false);

    const [downloading, setDownloading] = useState<boolean>(false);
    const [documentWidth, setDocumentWidth] = useState<number>(595);
    const [numPages, setNumPages] = useState<any>(null);

    const typesIncluded = [
        enumFormDocumentTypeCniRecto,
        enumFormDocumentTypeCniVerso,
        enumFormDocumentTypeRIB,
        enumFormDocumentTypeAttestationCPAM,
        enumFormDocumentTypeKbis
    ]

    // Initialize States after the first render() lifecycle.
    useEffect(() => {
        setControl(props.control);

        let docs: IControleDocument[] = props.control.documents;

        docs = docs.filter(_ => typesIncluded?.includes(_.type));
        docs = docs.sort(documentOrderByType);

        const docIndex = docs.findIndex(_ => _.status === ControlStatus.PENDING)

        setDocuments(docs);
        setDocSize(docs.length - 1);
        setDocIndex(docIndex !== -1 ? docIndex : docSize);
        if (props.control.status !== ControlStatus.PENDING && props.control.status !== ControlStatus.IN_PROGRESS)
            setIsSummary(true);

        getFormContext(props.control.form.uuid)
        .then((response: IFormContext) => {
            // setTheme(getPlateformTheme(response.plateform.code));
            // setPlateformStyle(getPlateformStyle(response.plateform.code));
            setForm(response.form);
            setPlateform(response.plateform);
            setContractBilling(response.contractBillingMain);

            // Get plateforme settings.
            return getPlateformeSettings(response.plateform.code);
        })
        .then((response?: IPlateformeSettings) => {
            setPlateformSettings(response);
        })
        .catch(_ => {
            setControl(null);
        })
    }, [props.control.id]);

    const onDocumentLoadSuccess = (numPages: any) => {
        setNumPages(numPages._pdfInfo.numPages);
    }

    useEffect(() => {
        if (documents.length === 0)
            return;

        setDownloading(true);
        if (docIndex === undefined) {
            setDownloading(false);
            return;
        }

        if (documents[docIndex]?.content) {
            setDownloading(false);
            return;
        }

        downloadDocument(documents[docIndex].documentUuid as string)
        .then(response => {
            if (documents[docIndex].fileTypeMIME?.includes("pdf")) {
                setDocuments(_ => _.map((item: any, id: number) => {
                    return id !== docIndex ? item : {...item, content: response }
                }))
            }
            else {
                convertBlobToBase64(response).then(res => {
                    setDocuments(_ => _.map((item: any, id: number) => {
                        return id !== docIndex ? item : {...item, content: res as string }
                    }))
                });
            }
            setDownloading(false);
        })
        .catch(_ => {
            setIsSnackBarOpen(true);
        });
    }, [docIndex])

    const convertBlobToBase64 = async (blob: any) => { // blob data
        return await blobToBase64(blob);
    }

    const blobToBase64 = (blob: any) => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const handleGoBack = () => {
        if (docIndex !== undefined && docIndex > 0) {
            let di = docIndex - 1;
            setDocIndex(di);
        }
    }

    const handleGoNext = () => {
        if (docIndex !== undefined && docIndex < docSize) {
            let di = docIndex + 1;
            setDocIndex(di);
        }
    }

    const handleAction = (index: number, status: ControlStatus, comment?: string) => {
        const docs = [...documents];
        const doc = docs[index];

        doc.status = status;
        if (comment) doc.comment = comment;

        const toSend: IControleDocumentRequestPOST = {
            id: doc.id,
            status: doc.status,
            comment: doc.comment
        }

        updateControlDocument(toSend.id, toSend)
        .then(response => {
            if (docIndex !== undefined && docIndex < docSize)
                setDocIndex(docIndex + 1);

            if (response.status !== ControlStatus.PENDING && response.status !== ControlStatus.IN_PROGRESS)
                setIsSummary(true);

            setControl(response);
            setDocuments(prev => prev.map((item: any, id: number) => {
                return item.id !== toSend.id ? item : {...response.documents.find(_ => _.id === toSend.id), content: item.content}
            }))

            props.send(response);
        })
        .catch(_ => {
            doc.status = ControlStatus.PENDING;
            setIsSnackBarOpen(true);
        })
    }

    useEffect(() => {
        formik.setFieldValue("dateStart", (plateformSettings?.settingsSubscription.hennerSubscription && new Date(props.control.form.contract.dateStart) <= dateContractMin) ? new Date() : new Date(props.control.form.contract.dateStart))
    }, [plateformSettings])

    const getInitialValues = () => {
        return {
            dateStart: (plateformSettings?.settingsSubscription.hennerSubscription && new Date(props.control.form.contract.dateStart) <= dateContractMin) ? new Date() : new Date(props.control.form.contract.dateStart)
        }
    };

    useEffect(() => {
        if (!ref.current) return;
        setDocumentWidth((ref.current as any).offsetWidth);
    }, [ref.current])

    const validationSchema = yup.object({
        dateStart: yup
            .date()
            .typeError("Date non valide")
            .nullable()
            .test(
                'minDate',
                'Date non valide',
                function (item: any) {
                    if (item === undefined || item === null)
                        return false

                    return !(plateformSettings?.settingsSubscription.hennerSubscription && item < dateContractMin)
                })
            .max(dateContractMax, "La date doit être inférieure à un an")
            .required(messageFieldRequired),
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (!control?.id)
                return

            let date: Date = new Date(values.dateStart)
            date.setHours(12, 0, 0, 0)

            megControl(control.id, {dateStart: date}).then((response) => {
                let c = {...response};

                c.status = ControlStatus.MEG;
                c.hennerSent = true;
                c.isMEG = true;
                c.form.contract.hennerContract = undefined

                props.send(c);

                setControl((prev) => {
                    if (!prev) return;

                    return {...prev, status: c.status, hennerSent: c.hennerSent, isMEG: c.isMEG, form: c.form}
                })
            })
            .catch(_ => {
                setIsSnackBarOpen(true);
            })
        }
    })


    return (
        <Grid
            container
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}>
            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={() => setIsSnackBarOpen(false)}>
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={plateformStyle.colors.red.main} />,
                    }} >
                    <Typography fontWeight={500}>Une erreur est survenue, veuillez réessayer ultérieurement.</Typography>
                </Alert>
            </Snackbar>

            {control === undefined ?
            <Grid
                item
                xs={12} >
                <Card
                    sx={{
                        height: '150px',
                        textAlign: 'center'
                    }}>
                    <Grid
                        container
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                        sx={{
                            height: '100%'
                        }} >
                        <Grid
                            item>
                            <Typography
                                variant='subtitle1'>
                                Chargement des pièces justificatives en cours...
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            </Grid>

            : control === null ?
            <Grid
                item
                xs={12} >
                <Card
                    sx={{
                        height: '150px',
                        textAlign: 'center'
                    }}>
                    <CardError
                        title="Une erreur est survenue lors de la récupération des pièces justificatives"
                        description="Veuillez réessayer ultérieurement" />
                </Card>
            </Grid>

            :
            <>
            <Grid
                item
                xs={12}>
                <Grid
                    container
                    spacing={2}>
                    <Grid
                        item
                        lg={4}
                        xs={12}>

                        {form && form.uuid && form.contract &&
                        <CardSubscriberInfo
                            plateformStyle={plateformStyle}
                            control={control}
                            subscriber={form.contract.person}
                            formUuid={form.uuid}
                            crmId={props.control.form?.crmId}
                            sendControl={(_: IControle) => {
                                props.send(_)
                                setControl({...control, status: _.status, comment: _.comment, commentFraud: _.commentFraud, user: _.user, techDateControl: _.techDateControl})
                                setIsSummary(true);
                            }}/>}

                        {!isSummary && docIndex !== undefined && form?.contract?.person && contractBilling &&
                        <>
                        <br />
                        <CardValidateDocument
                            plateformStyle={plateformStyle}
                            isCpamRequired={documents.filter(e => e.type === enumFormDocumentTypeAttestationCPAM).at(0)?.id === documents[docIndex].id}
                            isControlDone={control.status !== ControlStatus.PENDING && control.status !== ControlStatus.IN_PROGRESS}
                            control={control}
                            document={documents[docIndex]}
                            subscriber={form?.contract?.person}
                            billing={contractBilling}
                            handleUpdateSubscriber={(_) => {
                                const toUpdate: IForm = {...form};

                                if (toUpdate?.contract === undefined)
                                    return;

                                toUpdate.contract.person = _;

                                setForm(toUpdate);
                            }}
                            handleValidate={() => handleAction(docIndex, ControlStatus.ACCEPTED, undefined)}
                            handleRefuse={(comment) => handleAction(docIndex, ControlStatus.REFUSED, comment)} />
                        </>}
                    </Grid>

                    <Grid
                        item
                        lg={8}
                        xs={12}
                        sx={{
                            height: {
                                md: control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS ? "calc(100vh - 120px)" : "100%",
                                xs: "100%"
                            }
                        }}>
                        <Card
                            sx={{
                                height: "100%"
                            }} >

                            {downloading &&
                            <LinearProgress
                                sx={{
                                    '& .MuiLinearProgress-bar': {
                                        backgroundColor: plateformStyle.components.ProgressBar.color,
                                    }
                                }} />}

                            {control.status !== ControlStatus.EXPIRED && control.status !== ControlStatus.INCOMPLETE && docSize > 0 &&
                            <Grid
                                container
                                justifyContent="space-between"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    p: 1
                                }}>
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        textAlign: "start"
                                    }}>
                                    {isSummary ?
                                    <Button
                                        startIcon={<ArrowLeftIcon color={plateformStyle.colors.black.main} />}
                                        onClick={() => {
                                            setDocIndex(docSize);
                                            setIsSummary(false);
                                        }}
                                        sx={{
                                            py: 1,
                                            color: plateformStyle.colors.black.main
                                        }} >
                                        Retour aux pièces
                                    </Button>

                                    : docIndex !== 0 &&
                                    <Button
                                        startIcon={<ArrowLeftIcon color={docIndex === 0 ? plateformStyle.colors.black.secondary : plateformStyle.colors.black.main} />}
                                        onClick={() => handleGoBack()}
                                        sx={{
                                            py: 1,
                                            color: docIndex === 0 ? plateformStyle.colors.black.secondary : plateformStyle.colors.black.main
                                        }} >
                                        Précédent
                                    </Button>}
                                </Grid>

                                {!isSummary &&
                                <Grid
                                    item
                                    xs
                                    sx={{
                                        textAlign: "center"
                                    }}>
                                    <Select
                                        value={docIndex}
                                        renderValue={(value) => `${docIndex ? docIndex + 1 : 1}/${docSize + 1}`}
                                        onChange={(e) => {
                                            setDocIndex(e.target.value as number)
                                        }}
                                        SelectDisplayProps={{ style: { fontSize: "20px", minHeight: 0 } }}>
                                        {documents.map((_, idx) => {
                                            return (
                                                <MenuItem
                                                    value={idx}
                                                    disabled={(control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS) && idx !== 0 && documents.findIndex(d => d.status === ControlStatus.PENDING) < idx}>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            width: 8,
                                                            height: 8,
                                                            flexShrink: 0,
                                                            borderRadius: 8,
                                                            mr: 1,
                                                        }}
                                                        style={{ backgroundColor: documents[idx].status === ControlStatus.ACCEPTED ? colors.green.main : documents[idx].status === ControlStatus.REFUSED ? colors.red.main : colors.black.secondary }}
                                                    />
                                                    {idx + 1} - {enumFormDocumentTypes.find(t => t.value === _.type)?.label}
                                                </MenuItem>
                                            )
                                        })}
                                        <Divider />
                                        <MenuItem
                                            onClick={() => setIsSummary(true)}
                                            disabled={control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS}>
                                            🔖 Résumé
                                        </MenuItem>
                                    </Select>
                                </Grid>}

                                <Grid
                                    item
                                    xs
                                    sx={{
                                        textAlign: "end"
                                    }}>
                                    {isSummary ?
                                    <></>

                                    : docIndex !== docSize ?
                                    <Button
                                        disabled={docIndex !== undefined && documents[docIndex].status === ControlStatus.PENDING && (control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS)}
                                        onClick={() => handleGoNext()}
                                        endIcon={
                                            <ArrowRightIcon
                                                color={docIndex === docSize || (docIndex !== undefined && documents[docIndex].status === ControlStatus.PENDING && (control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS)) ? plateformStyle.colors.black.secondary : plateformStyle.colors.black.main} />
                                        }
                                        sx={{
                                            py: 1,
                                            color: docIndex === docSize || (docIndex !== undefined && documents[docIndex].status === ControlStatus.PENDING && (control.status === ControlStatus.PENDING || control.status === ControlStatus.IN_PROGRESS)) ? plateformStyle.colors.black.secondary : plateformStyle.colors.black.main
                                        }}>
                                        Suivant
                                    </Button>

                                    : control.status !== ControlStatus.PENDING && control.status !== ControlStatus.IN_PROGRESS &&
                                    <Button
                                        onClick={() => setIsSummary(true)}
                                        endIcon={
                                            <ArrowRightIcon
                                                color={plateformStyle.colors.black.main} />
                                        }
                                        sx={{
                                            py: 1,
                                            color: plateformStyle.colors.black.main
                                        }}>
                                        Résumé
                                    </Button>}
                                </Grid>
                            </Grid>}

                            {control.status === ControlStatus.EXPIRED ?
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    p: 2
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        variant='h2' >
                                        Souscription expirée ⌛
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>
                                        Le souscripteur a dépassé le délai de <b>7 jours</b> pour déposer ses pièces justificatives.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography variant="body2">
                                        Pour rappel, le souscripteur souhaite que son contrat démarre le <b>{new Date(props.control.form.contract?.dateStart).getDate().toString().padStart(2, '0') + "/" + (new Date(props.control.form.contract?.dateStart).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(props.control.form.contract?.dateStart).getFullYear()}</b>.
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Divider /> 
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}>

                                        <Button
                                            sx={{
                                                py: 1,
                                                px: 2,
                                                color: 'white',
                                                backgroundColor: colors.black.main,
                                                ':hover': {
                                                    backgroundColor: 'black'
                                                }
                                            }}
                                            onClick={() => {
                                                reactivateControl(control.id).then((response) => {
                                                    props.send(response)
                                                    setControl({...control, status: response.status, dateLimit: response.dateLimit})
                                                })
                                            }}>
                                            Déverrouiller
                                        </Button>
                                        <Typography fontWeight={500}>En déverrouillant l'espace document, vous permettez au souscripteur de déposer ses pièces justificatives.</Typography>
                                    </Stack>
                                </Grid>
                            </Grid>

                            : control.status === ControlStatus.INCOMPLETE ?
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    p: 2
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        variant='h2' >
                                        Souscription incomplète 📥
                                    </Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>
                                        Le souscripteur n'a pas encore déposé toutes ses pièces justificatives.
                                    </Typography>
                                </Grid>

                                {control.dateLimit && 
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>
                                        Il reste au souscripteur <b>{Math.floor(Math.abs((new Date().getTime() - new Date(control.dateLimit).getTime()) / (1000 * 3600 * 24)))}</b> jour(s) pour les déposer.
                                    </Typography>
                                </Grid>}

                                {control.dateLimit && control.techDateCreated && (Math.abs((new Date(control.dateLimit).getTime() - new Date(control.techDateCreated).getTime()) / (1000 * 3600 * 24)) > 8) &&
                                <>
                                <Grid
                                    item
                                    xs={12}>
                                    <Divider /> 
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Stack
                                        direction="row"
                                        justifyContent="flex-start"
                                        alignItems="center"
                                        spacing={2}>
                                        <Button
                                            sx={{
                                                py: 1,
                                                px: 2,
                                                color: 'white',
                                                backgroundColor: colors.black.main,
                                                ':hover': {
                                                    backgroundColor: 'black'
                                                }
                                            }}
                                            onClick={() => {
                                                deactivateControl(control.id).then((response) => {
                                                    props.send(response)
                                                    setControl({...control, status: response.status, dateLimit: response.dateLimit})
                                                })
                                            }}>
                                            Verrouiller
                                        </Button>
                                        <Typography fontWeight={500}>En verrouillant l'espace document, le souscripteur ne pourra plus déposer ses pièces justificatives.</Typography>
                                    </Stack>
                                </Grid>
                                </>}
                            </Grid>

                            : (isSummary && control.status !== ControlStatus.PENDING && control.status !== ControlStatus.IN_PROGRESS) ?
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    p: 2
                                }}>

                                {control.status === ControlStatus.MEG ?
                                <>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        component='div'
                                        variant='h2'>
                                        Mis en gestion 🚀&nbsp;&nbsp;&nbsp;
                                        {plateformSettings?.settingsSubscription.hennerSubscription ?
                                        <Chip label="🤖 Automatique" variant="outlined" size="small" sx={{ marginRight: 1 }}/>
                                        :
                                        <Chip label="✍️ Manuelle" variant="outlined" size="small" sx={{ marginRight: 1 }}/>}
                                    </Typography>

                                    <Typography
                                        variant="body2">
                                        {control.form.contract.hennerContract ?
                                        <>
                                        {control.form.contract.hennerContract.user !== undefined && control.form.contract.hennerContract.user !== null && <>par <b>@{control.form.contract.hennerContract.user.username}</b></>}
                                        {control.form.contract.hennerContract.techDateModification !== undefined && control.form.contract.hennerContract.techDateModification !== null &&
                                        <>
                                            &nbsp;le {new Date(control.form.contract.hennerContract.techDateModification).getDate().toString().padStart(2, '0') + "/" + (new Date(control.form.contract.hennerContract.techDateModification).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.form.contract.hennerContract.techDateModification).getFullYear()}
                                            &nbsp;à {new Date(control.form.contract.hennerContract.techDateModification).getHours().toString() + "h" + (new Date(control.form.contract.hennerContract.techDateModification).getMinutes() + 1).toString().padStart(2, '0')}
                                        </>}
                                        </>

                                        :
                                        // MEG by email : Display information of the validation
                                        <>
                                        {control.user !== undefined && control.user !== null && <>par <b>@{control.user.username}</b></>}
                                        {control.techDateModification !== undefined && control.techDateModification !== null &&
                                        <>
                                            &nbsp;le {new Date(control.techDateModification).getDate().toString().padStart(2, '0') + "/" + (new Date(control.techDateModification).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.techDateModification).getFullYear()}
                                            &nbsp;à {new Date(control.techDateModification).getHours().toString() + "h" + (new Date(control.techDateModification).getMinutes() + 1).toString().padStart(2, '0')}
                                        </>}
                                        </>
                                        }
                                    </Typography>
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <Grid
                                        container
                                        spacing={1}>
                                        {plateformSettings?.settingsSubscription.hennerSubscription && control.form.contract.hennerContract === undefined &&
                                        <Grid item xs={12}>
                                            <CardInfo plateformStyle={plateformStyle}>
                                                Mise en gestion en cours...
                                            </CardInfo>
                                        </Grid>}

                                        {plateformSettings?.settingsSubscription.hennerSubscription &&
                                        <>
                                        <Grid item xs={12}>
                                            <Typography
                                                fontWeight={500}>
                                                N° devis :&nbsp;
                                                <Typography
                                                    variant='body2'
                                                    component='span'
                                                    color={plateformStyle.colors.blue.main}
                                                    fontWeight={700}
                                                    sx={{
                                                        borderRadius: 4,
                                                        px: 1,
                                                        backgroundColor: plateformStyle.colors.blue.secondary
                                                    }}>
                                                    {control.form.contract.hennerContract?.codeDevis || "-"}
                                                </Typography>

                                                {control.hennerSent && control.form.contract.hennerContract?.isCallOver && (!control.form.contract.hennerContract?.codeDevis) &&
                                                <Tooltip
                                                    title={<>
                                                        <Typography variant="caption" color="inherit">La mise en gestion Henner n'a pas aboutie.</Typography>
                                                    </>} >
                                                    <IconButton>
                                                        <WarningIcon
                                                            sx={{
                                                                color: colors.red.main
                                                            }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Typography
                                                fontWeight={500}>
                                                N° contrat :&nbsp;
                                                <Typography
                                                    variant='body2'
                                                    component='span'
                                                    color={plateformStyle.colors.blue.main}
                                                    fontWeight={700}
                                                    sx={{
                                                        borderRadius: 4,
                                                        px: 1,
                                                        backgroundColor: plateformStyle.colors.blue.secondary
                                                    }}>
                                                    {control.form.contract.hennerContract?.codeContrat || "-"}
                                                </Typography>

                                                {control.hennerSent && control.form.contract.hennerContract?.isCallOver && (!control.form.contract.hennerContract?.codeContrat || control.form.contract.hennerContract.codeContrat === "GSM0") &&
                                                <Tooltip
                                                title={<>
                                                    <Typography variant="caption" color="inherit">La mise en gestion Henner n'a pas aboutie.</Typography>
                                                    {control.form.contract.hennerContract.codeContrat === "GSM0" && <Typography component="div" variant="caption" color="inherit">Un contrat existe déjà sur Henner pour ce NNI.</Typography>}
                                                </>} >
                                                    <IconButton>
                                                        <WarningIcon
                                                            sx={{
                                                                color: colors.red.main
                                                            }} />
                                                    </IconButton>
                                                </Tooltip>}
                                            </Typography>
                                        </Grid>
                                        </>}

                                        <Grid item xs={12}>
                                            <Typography
                                                fontWeight={500}>
                                                Date d'effet :&nbsp;
                                                <Typography
                                                    variant='body1'
                                                    component='span'
                                                    fontWeight={700}>
                                                    {new Date(control.form.contract.dateStart).getDate().toString().padStart(2, '0') + "/" + (new Date(control.form.contract.dateStart).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.form.contract.dateStart).getFullYear()}
                                                </Typography>
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                </>

                                : control.status === ControlStatus.ACCEPTED &&
                                  plateformSettings && !plateformSettings?.settingsSubscription.hennerSubscription && !plateformSettings?.settingsSubscription.mailSubscription ?
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        component='div'
                                        variant='h2'>
                                        Mise en gestion ❌
                                    </Typography>

                                    <Typography
                                        variant="body1"
                                        sx={{
                                            fontWeight: 500
                                        }}>
                                        Aucune méthode de mise en gestion n'est définie pour la plateforme <b>{plateformSettings?.plateforme.name}</b>.<br/>
                                        Veuillez contacter un administrateur.
                                    </Typography>
                                </Grid>
                                
                                : control.status === ControlStatus.ACCEPTED ?
                                <>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        component='div'
                                        variant='h2'>
                                        Mise en gestion&nbsp;&nbsp;&nbsp;
                                        {plateformSettings?.settingsSubscription.hennerSubscription ? <Chip label="🤖 Automatique" variant="outlined" size="small" sx={{ marginRight: 1 }}/>
                                        : <Chip label="✍️ Manuelle" variant="outlined" size="small" sx={{ marginRight: 1 }}/>}
                                    </Typography>

                                    {plateformSettings?.settingsSubscription.hennerSubscription ?
                                    <Typography
                                        variant="body2">
                                        La souscription sera envoyée automatiquement sur HennerNet.
                                    </Typography>

                                    :
                                    <Typography
                                        variant="body2">
                                        La souscription devra être saisie manuellement sur HennerNet.<br/>
                                        Un email de mise en gestion sera envoyé sur l'adresse : <Link underline="none">{plateform?.mailMiseEnGestion}</Link>.
                                    </Typography>}
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <form
                                        onSubmit={formik.handleSubmit}>
                                        <Grid
                                            container
                                            spacing={2}>
                                            {new Date(props.control.form.contract.dateStart) <= dateContractMin && plateformSettings?.settingsSubscription.hennerSubscription &&
                                            <Grid item xs={12}>
                                                {/* If the date start of the contract is before or equal to today + 1J */}
                                                <CardInfo
                                                    plateformStyle={plateformStyle}>
                                                    <Typography fontWeight={500}>
                                                        La mise en gestion ne peut pas être effective à la date demandée par le souscripteur <b>{new Date(control.form.contract.dateStart).getDate().toString().padStart(2, '0') + "/" + (new Date(control.form.contract.dateStart).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.form.contract.dateStart).getFullYear()}</b>.
                                                        <br/>
                                                        Merci de choisir une nouvelle date.
                                                    </Typography>
                                                </CardInfo>
                                            </Grid>}

                                            <Grid item xs={12}>
                                                <FormLabel error={formik.touched.dateStart && Boolean(formik.errors.dateStart)} >
                                                    <FieldLabel label="Date d'effet du contrat" isRequired />
                                                </FormLabel>
                                                <LocalizationProvider
                                                    dateAdapter={AdapterDateFns}
                                                    adapterLocale={fr}
                                                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                    <DatePicker
                                                        disabled={new Date(props.control.form.contract.dateStart) >= dateContractMin || !plateformSettings?.settingsSubscription.hennerSubscription}
                                                        value={formik.values.dateStart}
                                                        onChange={(value: any) => formik.setFieldValue("dateStart", value)}
                                                        localeText={{
                                                            toolbarTitle: "Sélectionnez une date",
                                                            okButtonLabel: "Valider"
                                                        }}
                                                        minDate={dateContractMin}
                                                        maxDate={dateContractMax}
                                                        format='dd/MM/yyyy'
                                                        slotProps={{
                                                            textField: {
                                                                fullWidth: true,
                                                                sx: {
                                                                    width: '100%',
                                                                    maxWidth: '300px'
                                                                },
                                                                onBlur: (e: any) => formik.setFieldTouched("dateStart"),
                                                                error: formik.touched.dateStart && Boolean(formik.errors.dateStart),
                                                                helperText: formik.touched.dateStart && <>{formik.errors.dateStart}</>,
                                                            },
                                                        }} />
                                                </LocalizationProvider>
                                                {new Date(props.control.form.contract.dateStart) <= dateContractMin && plateformSettings?.settingsSubscription.hennerSubscription &&
                                                <Typography
                                                    variant='caption'
                                                    component='div'
                                                    fontSize='0.9rem'
                                                    sx={{
                                                        ml: 2
                                                    }} >
                                                    La nouvelle date d'effet du contrat doit au minimum être à J - 7 (soit le {dateContractMin.getDate().toString().padStart(2, '0') + "/" + (dateContractMin.getMonth() + 1).toString().padStart(2, '0') + "/" + dateContractMin.getFullYear()})
                                                </Typography>}
                                            </Grid>

                                            <Grid item xs={12}>
                                                <Button
                                                    type='submit'
                                                    sx={{
                                                        py: 1,
                                                        color: 'white',
                                                        backgroundColor: colors.black.main,
                                                        ':hover': {
                                                            backgroundColor: 'black'
                                                        }
                                                    }} >
                                                    Mettre en gestion
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </form>
                                </Grid>
                                </>

                                : <></>}

                                {(control.status === ControlStatus.ACCEPTED || control.status === ControlStatus.MEG) &&
                                <Grid item xs={12} sx={{my: 2}}>
                                    <Divider />
                                </Grid>}

                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        component='span'
                                        variant='h2'>
                                        {(control.status === ControlStatus.ACCEPTED || control.status === ControlStatus.MEG) ? "Souscription Validée 🎉"
                                         : control.status === ControlStatus.CLOSED ? "Souscription Clôturée ❌"
                                         : "Souscription en attente de correction ⏳"}
                                    </Typography>
                                    <Typography
                                        variant="body2">
                                        {control.user !== undefined && control.user !== null &&
                                            <>par <b>@{control.user.username}</b></>}
                                        {control.techDateControl !== undefined && control.techDateControl !== null &&
                                        <>
                                            &nbsp;le {new Date(control.techDateControl).getDate().toString().padStart(2, '0') + "/" + (new Date(control.techDateControl).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.techDateControl).getFullYear()}
                                            &nbsp;à {new Date(control.techDateControl).getHours().toString() + "h" + (new Date(control.techDateControl).getMinutes() + 1).toString().padStart(2, '0')}
                                        </>}
                                    </Typography>

                                    {control.status === ControlStatus.CLOSED && control.comment &&
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            whiteSpace: "pre-line",
                                            mt: 1,
                                            p: 1,
                                            background: colors.grey.main + "40",
                                            borderLeft: 2,
                                            borderColor: colors.red.main,
                                            borderRadius: 2
                                        }}>
                                        {control.comment}
                                    </Typography>}
                                    
                                    {control.status === ControlStatus.REFUSED &&
                                    <Typography
                                        fontWeight={500}
                                        sx={{
                                            mt: 2
                                        }}>
                                        ⚠️ Le souscripteur doit corriger la ou les pièce(s) justificative(s) refusée(s) sur son espace document.
                                    </Typography>}
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <List
                                        sx={{
                                            p: 0
                                        }} >
                                        {documents.map((_, idx) =>
                                        <ListItem
                                            key={idx}
                                            sx={{
                                                p: 0
                                            }}>
                                            <ListItemButton
                                                onClick={() => {
                                                    setDocIndex(idx);
                                                    setIsSummary(false);
                                                }}
                                                sx={{
                                                    borderRadius: 4,
                                                    ":hover": {
                                                        background: colors.grey.main
                                                    }
                                                }}>

                                                <ListItemText
                                                    primary={
                                                        <Typography sx={{mb: 0.5}}>
                                                            {enumFormDocumentTypes.find(e => e.value === _.type)?.label}
                                                        </Typography>}
                                                    secondary={
                                                        <>
                                                        <Chip
                                                            label={_.status === ControlStatus.ACCEPTED ? "Accepté"
                                                                : _.status === ControlStatus.REFUSED ? "Refusé"
                                                                : "Non vérifié"}
                                                            sx={{
                                                                mr: 1,
                                                                color: 'white',
                                                                backgroundColor: _.status === ControlStatus.ACCEPTED ? colors.green.main
                                                                : _.status === ControlStatus.REFUSED ? colors.red.main
                                                                : colors.black.secondary,
                                                                fontSize: 14,
                                                                fontWeight: 700
                                                            }} />

                                                        <Typography
                                                            component="span"
                                                            variant="body2"
                                                            color="text.primary">
                                                            {_.user !== undefined && _.user !== null &&
                                                                <>par <b>@{_.user.username}</b></>}
                                                            {_.techDateControl !== undefined && _.techDateControl !== null &&
                                                            <>
                                                                &nbsp;le {new Date(_.techDateControl).getDate().toString().padStart(2, '0') + "/" + (new Date(_.techDateControl).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.techDateControl).getFullYear()}
                                                                &nbsp;à {new Date(_.techDateControl).getHours().toString() + "h" + (new Date(_.techDateControl).getMinutes() + 1).toString().padStart(2, '0')}
                                                            </>}
                                                        </Typography>

                                                        {_.status === ControlStatus.REFUSED && _.comment !== undefined && _.comment !== null &&
                                                        <Typography
                                                            color="text.primary"
                                                            fontWeight={500}
                                                            sx={{
                                                                whiteSpace: "pre-line",
                                                                mt: 1,
                                                                p: 1,
                                                                background: colors.grey.main + "40",
                                                                borderLeft: 2,
                                                                borderColor: colors.red.main,
                                                                borderRadius: 2
                                                            }}>
                                                            {_.comment}
                                                        </Typography>}
                                                    </>}/>
                                            </ListItemButton>
                                        </ListItem>)}
                                    </List>
                                </Grid>
                            </Grid>

                            : downloading ?
                            <Grid
                                container
                                justifyContent="center"
                                alignItems="center"
                                spacing={2}
                                sx={{
                                    height: "100%",
                                    minHeight: "150px",
                                    textAlign: 'center'
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        component='div'
                                        variant='h2' >
                                        Téléchargement en cours...
                                    </Typography>
                                </Grid>
                            </Grid>

                            : docIndex !== undefined ?
                            <Box
                                sx={{
                                    position: "relative"
                                }}>
                                <Stack
                                    direction={{
                                        sm: "column",
                                        xs: "row-reverse"
                                    }}
                                    spacing={0}
                                    sx={{
                                        position: "absolute",
                                        background: documentWidth >= 700 ? "white" : "none",
                                        borderRadius: 4,
                                        zIndex: 1000,
                                        right: 10,
                                        top: 2
                                    }}>

                                    {documents[docIndex].fileTypeMIME?.includes("pdf") &&
                                    <a
                                        href={window.URL.createObjectURL(new Blob([documents[docIndex].content as string], {type: documents[docIndex].fileTypeMIME as string}))}
                                        target="_blank"
                                        rel="noopener noreferrer">
                                        <IconButton
                                            aria-label="open">
                                            <FullScreenIcon color={plateformStyle.colors.black.main} />
                                        </IconButton>
                                    </a>}

                                    <a
                                        href={documents[docIndex].fileTypeMIME?.includes("pdf") ?
                                            window.URL.createObjectURL(new Blob([documents[docIndex].content as string], {type: documents[docIndex].fileTypeMIME as string})) :
                                            documents[docIndex].content as string}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        download={documents[docIndex].fileName as string}>
                                        <IconButton
                                            aria-label="download">
                                            <DownloadIcon color={plateformStyle.colors.black.main} />
                                        </IconButton>
                                    </a>
                                </Stack>

                                <Box
                                    sx={{
                                        borderRadius: 4,
                                        height: {
                                            md: "765px",
                                            sm: "600px",
                                            xs: "450px"
                                        },
                                        overflow: 'auto',
                                        px: 2,
                                    }} >

                                    {documents[docIndex].fileTypeMIME?.includes("image") ?
                                    <Box
                                        ref={ref}
                                        sx={{
                                            height: "100%",
                                            width: "100%",
                                            background: `url(${documents[docIndex]?.content})`,
                                            backgroundRepeat: 'no-repeat',
                                            backgroundSize: 'contain',
                                            backgroundPosition: 'center'
                                        }} >
                                    </Box>

                                    : documents[docIndex].fileTypeMIME?.includes("pdf") ?
                                    <Box
                                        key={docIndex}
                                        ref={ref}
                                        sx={{
                                            width: "100%",
                                            maxWidth: "700px",
                                            mx: "auto",
                                        }} >
                                        <Document
                                            file={documents[docIndex]?.content}
                                            onLoadSuccess={onDocumentLoadSuccess} >
                                            {Array.apply(null, Array(numPages)).map((x, i) => <>
                                                <Page
                                                    pageNumber={i + 1}
                                                    scale={documentWidth / 595} />
                                                {i + 1 < numPages && <br />}
                                            </>)}
                                        </Document>
                                    </Box>

                                    :
                                    <></>}
                                </Box>
                            </Box>

                            :
                            <></>}
                        </Card>
                    </Grid>
                </Grid>
            </Grid>
            </>}
        </Grid>
    )
}

import { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { FormLabel, TextField } from '@mui/material';
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { TransitionSlideUP } from '../../utils/transistions/transitions';

import { messageFieldRequired } from '../../utils/messages';

import { colors } from '../../static/themes/gsmc/colors';
import { FieldLabel } from '../Field/FieldLabel';
import { IControle } from '../../model/ControleDocument.model';
import { updateControl, updateControlFraud } from '../../api/Controle.api';
import { CardInfo } from '../Card/CardInfo';
import { style } from '../../static/themes/gsmc/style';


interface Props {
    control: IControle;
    isOpened: boolean;
    handleClose: () => void;
    send: (_: IControle) => void;
}


export const DialogControlSuspicionFraud = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const getInitialValues = () => {
        return {
            comment: props.control.commentFraud
        }
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: yup.object({
            comment: yup
                .string()
                .nullable()
                .required(messageFieldRequired)
        }),
        onSubmit: (values) => {
            let payload: IControle = {...props.control}

            payload.commentFraud = values.comment;

            updateControlFraud(props.control.id, payload)
            .then((response: IControle) => {
                props.send(response)
                props.handleClose()
            })
        }
    });


    useEffect(() => {
        if (!props.isOpened)
            formik.resetForm()
    }, [props.isOpened])


    return (
        <Dialog
            fullWidth
            maxWidth="md"
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}>
            <DialogTitle>
                <Typography variant="h2">Suspicion de fraude</Typography>
            </DialogTitle>

            <DialogContent>
                {props.control.commentFraud ?
                <Grid
                    container
                    spacing={2}>
                    <Grid
                        item
                        xs={12}>
                        <Typography fontWeight={500}>Une suspicion de fraude a déjà été renseignée pour cette demande d'adhésion.</Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Typography
                            fontWeight={500}
                            sx={{
                                whiteSpace: "pre-line",
                                mt: 1,
                                p: 1,
                                background: colors.grey.main + "40",
                                borderLeft: 2,
                                borderColor: colors.blue.main,
                                borderRadius: 2
                            }}>
                            {props.control.commentFraud}
                        </Typography>
                    </Grid>
                </Grid>

                :
                <>
                <form onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="stretch"
                        sx={{
                            height: "100%"
                        }}>

                        <Grid
                            item
                            xs={12}>
                            <CardInfo
                                plateformStyle={style}
                                type="info">
                                <Typography>Demander l'analyse de cette demande d'adhésion.</Typography>
                                <Typography fontWeight={500}>Un email sera envoyé à l'équipe Fraudes pour analyser cette demande d'adhésion.</Typography>
                            </CardInfo>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={formik.touched.comment && Boolean(formik.errors.comment)} >
                                <FieldLabel label="Commentaire" isRequired mb={false}/>
                            </FormLabel>

                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 1
                                }}>
                                Veuillez indiquer le ou les éléments suspicieux
                            </Typography>

                            <TextField
                                fullWidth
                                multiline
                                minRows={3}
                                maxRows={10}
                                variant="outlined"
                                id="comment"
                                name="comment"
                                value={formik.values.comment}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.comment && Boolean(formik.errors.comment)}
                                helperText={formik.touched.comment && formik.errors.comment} />
                        </Grid>

                    </Grid>
                </form>
                </>}
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2,
                    pt: 0,
                }}>

                <Button
                    sx={{
                        width: {
                            sm: "auto"
                        },
                        px: 3,
                        color: colors.black.main,
                    }}
                    onClick={() => {
                        props.handleClose()
                    }} >
                    Fermer
                </Button>

                {!props.control.commentFraud &&
                <Button
                    type="submit"
                    variant="contained"
                    color="success"
                    sx={{
                        width: {
                            sm: "auto"
                        },
                        px: 3,
                        color: 'white',
                        backgroundColor: colors.black.main,
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }}
                    onClick={() => {
                        formik.handleSubmit()
                    }} >
                    Envoyer
                </Button>}
            </DialogActions>
        </Dialog>
    )
}

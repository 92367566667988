import { IRefFormule } from "."
import { IContract } from "../model/Contract.model"


export interface IContractNeeds {
    id?: number;
    dateCreated?: Date;
    techDateModification?: Date;
    contract: IContract;
    formule?: IRefFormule;
    codeOffre: string;
    needHospital?: number;
    needRoutineCare?: number;
    needOptical?: number;
    needDental?: number;
    needSoftMedicine?: number;
    needAudio?: number | null;
    needHealthCure?:number | null;
    needSoftwareService?: number | null;
}


export const getFormuleRecommendedId = (contractNeeds: IContractNeeds) => {
    let score: number = 0;

    if (contractNeeds.needAudio !== undefined && contractNeeds.needAudio !== null) score += contractNeeds.needAudio;
    if (contractNeeds.needDental !== undefined && contractNeeds.needDental !== null) score += contractNeeds.needDental;
    if (contractNeeds.needHealthCure !== undefined && contractNeeds.needHealthCure !== null) score += contractNeeds.needHealthCure;
    if (contractNeeds.needHospital !== undefined && contractNeeds.needHospital !== null) score += contractNeeds.needHospital;
    if (contractNeeds.needOptical !== undefined && contractNeeds.needOptical !== null) score += contractNeeds.needOptical;
    if (contractNeeds.needRoutineCare !== undefined && contractNeeds.needRoutineCare !== null) score += contractNeeds.needRoutineCare;
    if (contractNeeds.needSoftMedicine !== undefined && contractNeeds.needSoftMedicine !== null) score += contractNeeds.needSoftMedicine;
    if (contractNeeds.needSoftwareService !== undefined && contractNeeds.needSoftwareService !== null) score += contractNeeds.needSoftwareService;

    if (contractNeeds?.codeOffre === "312") {
        switch (true) {
            case score > 17:
                return 11904

            case score > 11:
                return 11903

            case score > 6:
                return 11902

            case score > 2:
                return 11901

            case score > 0:
                return 11900

            default:
                return 11899
        }
    }

    if (contractNeeds?.codeOffre === "512") {
        switch (true) {
            case score > 17:
                return 18646

            case score > 11:
                return 18645

            case score > 6:
                return 18644

            case score > 2:
                return 18643

            case score > 0:
                return 18642

            default:
                return 18642
        }
    }

    if (contractNeeds?.codeOffre === "539") {
        switch (true) {
            case score > 17:
                return 19030

            case score > 11:
                return 19029

            case score > 6:
                return 19028

            case score > 2:
                return 19027

            case score > 0:
                return 19026

            default:
                return 19036
        }
    }
    return;
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const GSMCIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 60 53"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M52.3718 44.5216C44.4695 28.1322 23.5312 20.3314 5.69602 27.1339L2.56583 28.327L3.95305 31.2039C7.78017 39.1435 14.743 45.3245 23.5589 48.6082C32.3754 51.8929 41.9877 51.8866 50.6282 48.5916L53.7584 47.398L52.3718 44.5216Z" fill="#3AC779"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M54.2562 43.0952C54.6266 25.6616 39.4065 10.7775 20.1418 9.83601L16.7372 9.66954L16.6349 12.7564C16.5228 16.1285 16.9986 19.4076 17.9722 22.5138C32.9816 22.0441 47.6706 29.8643 54.2562 43.0952Z" fill="#FF5996"/>
            <path fillRule="evenodd" clipRule="evenodd" d="M28.4697 8.67846C35.3887 10.3639 41.6849 13.8076 46.6816 18.7422C52.8099 24.794 56.3199 32.4228 56.754 40.4641C58.7625 33.2247 57.8959 25.5915 54.2154 18.6959C50.0962 10.9761 42.9559 5.1066 34.1096 2.16945L30.904 1.10533L29.8091 3.9964C29.2232 5.54445 28.7791 7.10934 28.4697 8.67846Z" fill="#F89136"/>
        </svg>
    )
}

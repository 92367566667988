import { IFormHistory } from "../model/FormHistory.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getFormHistory = (id: number) : Promise<IFormHistory> => {

    return fetch(`${apiUrl}/form_history/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}


export const createFormHistory = (formHistory: IFormHistory) : Promise<IFormHistory> => {

    return fetch(`${apiUrl}/form_history`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(formHistory)

    }).then(_ => _.json()) 
}


export const modifyFormHistory = (formHistory: IFormHistory) : Promise<IFormHistory> => {

    return fetch(`${apiUrl}/form_history`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(formHistory)

    }).then(_ => _.json()) 
}

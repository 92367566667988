import { RefObject, useEffect, useRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import LinearProgress from '@mui/material/LinearProgress';
import Link from '@mui/material/Link';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import { DialogDeleteConfirmation } from '../../components/Dialog/DialogDeleteConfirmation';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { FormPerson } from '../../components/Form/FormPerson';
import { CloseIcon } from '../../components/Icon/CloseIcon';
import { HrConjointIcon } from '../../components/Icon/HrConjoint';
import { HrChildIcon } from '../../components/Icon/HrChild';
import { PlusIcon } from '../../components/Icon/PlusIcon';
import { HrSubscriberIcon } from '../../components/Icon/HrSubscriberIcon';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { DialogCGU } from '../../components/Dialog/DialogCGU';

import { createPerson, modifyPerson, deletePerson } from '../../api/Person.api';

import { IPerson } from '../../model/Person.model';

import { enumHrRoleChild, enumHrRoleConjoint, enumHrRoleSubscriber } from '../../utils';
import { IForm } from '../../model/Form.model';
import * as Sentry from "@sentry/react";

interface Props {
    plateformStyle: any;
    sidebar?: JSX.Element;
    isUpdated: boolean;
    form?: IForm
    subscriber: IPerson;
    conjoint?: IPerson;
    children: IPerson[];
    sendSubscriber: (subscriber: IPerson) => void;
    sendConjoint: (conjoint: IPerson) => void;
    deleteConjoint: (conjoint: IPerson) => void;
    sendChild: (child: IPerson, index?: number) => void;
    deleteChild: (child: IPerson, index: number) => void;
    isControlFraud?: boolean
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormBeneficiaries = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const iconHeaderStyle: any = plateformStyle.components.IconHeader;
    const progressBarStyle: any = plateformStyle.components.ProgressBar;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const refFormSubscriber = useRef<HTMLFormElement>();
    const refFormConjoint = useRef<HTMLFormElement>();
    const [refFormChildren, setRefFormChildren] = useState<RefObject<HTMLFormElement>[]>([]);
    const [previousChildrenCount, setPreviousChildrenCount] = useState<number>(props.children.length);

    const refCardSubscriber = useRef<any>();
    const refCardConjoint = useRef<any>();
    const [refCardChildren, setRefCardChildren] = useState<any[]>([]);
    const [refCardChildrenLength, setRefCardChildrenLength] = useState<number>(props.children.length);

    const [addConjoint, setAddConjoint] = useState<boolean>(false);

    const [isDialogConjointOpened, setIsDialogConjointOpened] = useState<boolean>(false);
    const [dialogConjoint, setDialogConjoint] = useState<IPerson>({role: enumHrRoleConjoint});

    const [isDialogChildOpened, setIsDialogChildOpened] = useState<boolean>(false);
    const [dialogChildTitle, setDialogChildTitle] = useState<string>("");
    const [dialogChild, setDialogChild] = useState<IPerson>({role: enumHrRoleChild});
    const [dialogChildIndex, setDialogChildIndex] = useState<number>(0);

    const [progressSubscriber, setProgressSubscriber] = useState(0);
    const [progressConjoint, setProgressConjoint] = useState<number>(0);
    const [progressChildren, setProgressChildren] = useState<number[]>([]);

    const [render, setRender] = useState<number>(0);
    const [renderPerson, setRenderPerson] = useState<number>(0);

    const saveSubscriber = (subscriber: IPerson) => {
        subscriber.role = enumHrRoleSubscriber;

        if (!subscriber.id) {
            createPerson(subscriber).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriber -> createPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: subscriber,
                            }
                        }
                    );
                }
                else {
                    props.sendSubscriber(response);  // Update Form states.
                }
            });
        }
        else {
            modifyPerson(subscriber).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveSubscriber -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: subscriber,
                            }
                        }
                    );
                }
                else {
                    props.sendSubscriber(response);  // Update Form states.
                }
            });
        }
    }

    const displayDialogDeleteConjoint = (conjoint: IPerson) => {
        setDialogConjoint(conjoint);
        setIsDialogConjointOpened(true);
    }

    const displayDialogDeleteChild = (child: IPerson, index: number) => {
        let title: string;

        if (child.firstname)
            title = `Voulez-vous supprimer l'enfant ${child.firstname} ?`;
        else
            title = "Voulez-vous supprimer l'enfant ?";

        setDialogChildTitle(title);
        setDialogChild(child);
        setDialogChildIndex(index);
        setIsDialogChildOpened(true);
    }

    const saveConjoint = (conjoint: IPerson) => {
        conjoint.person = props.subscriber;

        if (!conjoint.id) {
            createPerson(conjoint).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveConjoint -> createPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: conjoint,
                            }
                        }
                    );
                }
                else {
                    props.sendConjoint(response);  // Update Form states.
                }
            });
        }
        else {
            modifyPerson(conjoint).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveConjoint -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: conjoint,
                            }
                        }
                    );
                }
                else {
                    props.sendConjoint(response);  // Update Form states.
                }
            });
        }
    }

    const deleteConjoint = (conjoint: IPerson) => {
        if (conjoint.id) {
            deletePerson(conjoint.id).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("deleteConjoint -> deletePerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: conjoint?.id?.toString(),
                            }
                        }
                    );
                }
            });
        }
        setAddConjoint(false)
        props.deleteConjoint(conjoint);  // Update Form states.

        setProgressConjoint(0);

        setIsDialogConjointOpened(false);
    }

    const sendChild = (child: IPerson, index?: number) => {
        if (child.dateBirth)
            child.dateBirth = new Date(child.dateBirth);

        props.sendChild(child, index);  // Update Form states.
    }

    const saveChild = (child: IPerson, index: number) => {
        child.person = props.subscriber;

        props.children.forEach((_, id) => sendChild(_, id));

        if (!child.id) {
            createPerson(child).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveChild -> createPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: child,
                            }
                        }
                    );
                }
                else {
                    sendChild(response, index);  // Update Form states.
                }
            });
        }
        else {
            modifyPerson(child).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveChild -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: child,
                            }
                        }
                    );
                }
                else {
                    sendChild(response, index);  // Update Form states.
                }
            });
        }
    }

    const deleteChild = (child: IPerson, index: number) => {
        if (child.id) {
            deletePerson(child.id).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("deleteChild -> deletePerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: child?.id?.toString(),
                            }
                        }
                    );
                }
            });
        }

        // Update Form states.
        refFormChildren.forEach((_, id) => {
            if (_.current)
                _.current.leave();
        })

        setRefFormChildren((prev) => prev.filter((_, id) => id !== index))
        setRefCardChildren((prev) => prev.filter((_, id) => id !== index))

        props.deleteChild(child, index);  // Update Form states.

        setProgressChildren(_ => _.splice(index, 1));

        setIsDialogChildOpened(false);
    }

    useEffect(() => {
        const prevCount = previousChildrenCount;

        if (props.children.length > prevCount) return;

        setRenderPerson(renderPerson + 1)
    }, [props.children.length])


    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        if (refFormConjoint.current)
            refFormConjoint.current.leave();

        refFormChildren.forEach((ref) => {
            if (ref.current)
                ref.current.leave();
        })

        props.goToPrevious();
    }, [props.goToPreviousFlag])


    // Focus the conjoint card when added.
    useEffect(() => {
        if (!addConjoint) return

        if (refCardConjoint.current)
            (refCardConjoint.current).scrollIntoView({behavior: "smooth", block: fullScreen ? "nearest" : "center", inline: "nearest"})

        setAddConjoint(false)
    }, [props.conjoint])


    // Focus the child card when added.
    useEffect(() => {
        if (previousChildrenCount === refCardChildrenLength) return;

        const prevCount = previousChildrenCount;

        setPreviousChildrenCount(props.children.length)

        if (refCardChildren.length === 0) return;

        if (props.children.length < prevCount) return;

        if (refCardChildren[refCardChildren.length - 1].current)
            (refCardChildren[refCardChildren.length - 1].current).scrollIntoView({behavior: "smooth", block: fullScreen ? "nearest" : "center", inline: "nearest"})

    }, [refCardChildrenLength])

    useEffect(() => {
        if (refCardChildrenLength === props.children.length) return;
        setRefCardChildrenLength(props.children.length)
    }, [props.children.length])
    

    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Vos bénéficiaires" />
            }
            sidebar={
                <Grid
                    container
                    justifyContent="center"
                    spacing={2}>
                    <Grid
                        item
                        xs={12}>
                        {props.sidebar}
                    </Grid>

                    { /*
                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                md: "inline",
                                xs: "none"
                            }
                        }}>
                        <Divider />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            display: {
                                md: "inline",
                                xs: "none"
                            }
                        }}>
                        <Typography sx={{mb: 1}}>Vos bénéficiaires</Typography>

                        <ButtonLink
                            label={subscriber?.firstname ? `${subscriber?.firstname} ${subscriber?.lastname || ""}` : "Adhérent principal"}
                            icon={<HrSubscriberIcon width="20px" height='20px' color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor}/>}
                            onClick={() => {
                                if (refCardSubscriber.current)
                                    (refCardSubscriber.current).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
                            }} />
        
                        {conjoint &&
                        <ButtonLink
                            label={conjoint?.firstname ? `${conjoint?.firstname} ${conjoint?.lastname || ""}` : "Conjoint"}
                            icon={<HrConjointIcon width="20px" height='20px' color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor}/>}
                            onClick={() => {
                                if (refCardConjoint.current)
                                    (refCardConjoint.current).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
                            }} />}
        
                        {children.map((_, id) => 
                        <ButtonLink
                            label={children[id]?.firstname ? `${children[id]?.firstname} ${children[id]?.lastname || ""}` : `${id + 1}${id === 0 ? "er" : "ème"} Enfant`}
                            icon={<HrChildIcon width="20px" height='20px' color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor}/>}
                            onClick={() => {
                                if (refCardChildren[id].current)
                                    (refCardChildren[id].current).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
                            }} />)}
                    </Grid>
                    */}
                </Grid>}
            content={
                <Grid
                    container
                    spacing={{
                        md: 4,
                        xs: 4
                    }}>

                    <Grid
                        xs={12}
                        item >
                        <Card
                            ref={refCardSubscriber}>
                            <LinearProgress
                                variant="determinate"
                                value={progressSubscriber}
                                color={progressBarStyle.form.color} />
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    p: 2
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <FormHeaderSection
                                        label="Adhérent principal"
                                        icon={<HrSubscriberIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor}/>} />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <FormPerson
                                        ref={refFormSubscriber}
                                        plateformStyle={plateformStyle}
                                        person={props.subscriber}
                                        form={props.form}
                                        isControlFraud={props.isControlFraud}
                                        send={props.sendSubscriber}
                                        save={saveSubscriber}
                                        sendProgress={setProgressSubscriber} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>

                    {props.conjoint &&
                    <Grid
                        xs={12}
                        item >
                        <Card
                            ref={refCardConjoint}>
                            <LinearProgress
                                variant="determinate"
                                value={progressConjoint}
                                color={progressBarStyle.form.color} />

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    position: 'relative',
                                    p: 2
                                }}>
                                <Tooltip title="Supprimer" placement="top">
                                    <IconButton
                                        aria-label="delete"
                                        sx={{
                                            position: 'absolute',
                                            top: 24,
                                            right: 8,
                                            transition: "transform 400ms",
                                            ":hover":  {
                                                transform: "translateY(-6px)"
                                            }
                                        }}
                                        onClick={() => { if (props.conjoint) displayDialogDeleteConjoint(props.conjoint); }} >
                                        <CloseIcon color={plateformStyle.colors.black.main} />
                                    </IconButton>
                                </Tooltip>

                                <Grid
                                    item
                                    xs={12}>
                                    <FormHeaderSection
                                        label="Votre conjoint"
                                        icon={<HrConjointIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor} />} />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <FormPerson
                                        ref={refFormConjoint}
                                        plateformStyle={plateformStyle}
                                        person={props.conjoint || { role: enumHrRoleConjoint }}
                                        persons={[...props.children, props.subscriber]}
                                        send={props.sendConjoint}
                                        save={saveConjoint}
                                        sendProgress={setProgressConjoint} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>}

                    {!(props.children === undefined || props.children.length === 0 || render === undefined) && props.children.map((_, id) => { return (
                    <Grid
                        item
                        xs={12}>
                        <Card
                            ref={(ref) => {
                                setRefCardChildren((prev) => {
                                    prev[id] = {current: ref};
                                    return prev;
                                })
                                return ref;
                            }}>
                            <LinearProgress
                                variant="determinate"
                                value={id < progressChildren.length ? progressChildren[id] : 0}
                                color={progressBarStyle.form.color} />

                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    position: 'relative',
                                    p: 2
                                }}>
                                <Tooltip title="Supprimer" placement="top">
                                    <IconButton
                                        aria-label="delete"
                                        sx={{
                                            position: 'absolute',
                                            top: 24,
                                            right: 8,
                                            transition: "transform 400ms",
                                            ":hover":  {
                                                transform: "translateY(-6px)"
                                            }
                                        }}
                                        onClick={() => displayDialogDeleteChild(_, id)} >
                                        <CloseIcon color={plateformStyle.colors.black.main} />
                                    </IconButton>
                                </Tooltip>

                                <Grid
                                    item
                                    xs={12}>
                                    <FormHeaderSection
                                        label={id === 0 ? "1er enfant" : `${id + 1}ème enfant` }
                                        icon={<HrChildIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor} />} />
                                </Grid>

                                <Grid
                                    item
                                    xs={12}>
                                    <FormPerson
                                        key={id}
                                        ref={(ref) => {
                                            setRefFormChildren((prevRefFormChildren) => {
                                                prevRefFormChildren[id] = {current: ref};
                                                return prevRefFormChildren;
                                            })
                                            return ref;
                                        }}
                                        plateformStyle={plateformStyle}
                                        person={_}
                                        persons={[props.subscriber, props.conjoint ? props.conjoint : {role: enumHrRoleConjoint}]}
                                        resetForm={renderPerson}
                                        send={(_) => sendChild(_, id)}
                                        save={(_) => saveChild(_, id)}
                                        sendProgress={_ => {
                                            setProgressChildren(prev => {
                                                prev[id] = _;
                                                return prev;
                                            });
                                            setRender(render + 1);  // Update the progressbar.
                                        }} />
                                </Grid>
                            </Grid>
                        </Card>
                    </Grid>
                    )})}

                    {/*
                    {!conjoint &&
                    <Grid
                        item
                        xs={12}>
                        <Card>
                            <CardContent sx={{ textAlign: 'center' }} >
                                <Typography component='div' variant='h2'>
                                    Aucun conjoint
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}

                    {(children === undefined || children.length === 0 || render === undefined) &&
                    <Grid
                        item
                        xs={12}>
                        <Card>
                            <CardContent sx={{ textAlign: 'center' }} >
                                <Typography component='div' variant='h2'>
                                    Aucun enfant
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>}
                    */}

                    <DialogDeleteConfirmation
                        isOpened={isDialogConjointOpened}
                        title="Voulez-vous supprimer le conjoint ?"
                        description="Toute suppression est définitive."
                        handleClose={() => setIsDialogConjointOpened(false)}
                        handleConfirmation={() => deleteConjoint(dialogConjoint)} />

                    <DialogDeleteConfirmation
                        isOpened={isDialogChildOpened}
                        title={dialogChildTitle}
                        description="Toute suppression est définitive."
                        handleClose={() => setIsDialogChildOpened(false)}
                        handleConfirmation={() => deleteChild(dialogChild, dialogChildIndex)} />

                    <Grid
                        xs={12}
                        item
                        sx={{
                            textAlign: 'center'
                        }}>
                        <Grid
                            container
                            alignItems='center'
                            justifyContent="center"
                            spacing={2}>

                            {!props.conjoint &&
                            <Grid
                                item
                                sm="auto"
                                xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: {
                                            sm: "auto",
                                            xs: "100%"
                                        },
                                        px: 3,
                                        color: 'white',
                                        backgroundColor: plateformStyle.colors.black.main,
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: 'black'
                                        }
                                    }}
                                    // startIcon={<PlusIcon color="white" />}
                                    onClick={() => {
                                        setAddConjoint(true)
                                        props.sendConjoint({role: enumHrRoleConjoint, regime: props.subscriber.regime})
                                    }} >
                                    Ajouter un conjoint
                                </Button>
                            </Grid>}

                            <Grid
                                item
                                sm="auto"
                                xs={12}>
                                <Button
                                    variant="contained"
                                    sx={{
                                        width: {
                                            sm: "auto",
                                            xs: "100%"
                                        },
                                        px: 3,
                                        color: 'white',
                                        backgroundColor: plateformStyle.colors.black.main,
                                        transition: "transform 400ms",
                                        ':hover': {
                                            transform: "translateY(-6px)",
                                            backgroundColor: 'black'
                                        }
                                    }}
                                    // startIcon={<PlusIcon color="white" />}
                                    onClick={() => sendChild({role: enumHrRoleChild})} >
                                    Ajouter un enfant
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                
                    <Grid
                        item
                        xs={12}>
                        <Grid
                            container
                            justifyContent='flex-start'
                            alignItems='flex-start'
                            wrap="nowrap">
                            <Grid
                                item
                                xs>
                                <Typography
                                    variant='caption'
                                    component='div'
                                    fontSize='0.9rem'
                                    sx={{ textAlign: 'justify' }}>
                                    Les informations recueillies dans ce formulaire sont nécessaires à GSMC pour traiter votre demande de souscription du contrat.
                                </Typography>
                                <Typography
                                    variant='caption'
                                    component='div'
                                    fontSize='0.9rem'
                                    sx={{ textAlign: 'justify', mt: 1 }}>
                                    Vous pouvez exercer vos droits d’accès, de suppression, de rectification des données auprès du Délégué à la protection des données par courrier adressé à « Mutuelle GSMC – Service DPO – 95 Rue de Jemmapes – 59000 Lille » ou via votre espace personnel.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            }
            footer={
                <Button
                    variant='contained'
                    color={buttonSubmitStyle.color}
                    sx={{
                        width: {
                            sm: 400,
                            xs: '100%'
                        },
                        my: 5,
                        px: 5,
                        color: 'white'
                    }}
                    onClick={() => {
                        let isValid: boolean = true;
                        let isFormValid: boolean = false;

                        if (refFormSubscriber.current) {
                            isFormValid = refFormSubscriber.current.handleSubmit();
                            if (!isFormValid) isValid = false;
                        }

                        if (refFormConjoint.current) {
                            isFormValid = refFormConjoint.current.handleSubmit();
                            if (!isFormValid) isValid = false;
                        }

                        refFormChildren.forEach((ref) => {
                            if (ref.current) {
                                isFormValid = ref.current.handleSubmit();
                                if (!isFormValid) isValid = false;
                            }
                        })

                        if (isValid)
                            props.goToNext();
                    }} >
                    Valider les informations
                </Button>
            } />
    )
}

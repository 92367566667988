import Card from "@mui/material/Card";
import { IPromotion } from "../../model/Promotion.model";
import { Box, Grid, Link, Typography, useTheme } from "@mui/material";
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';


interface Props {
    plateformStyle: any;
    promotion: IPromotion;
    minified?: boolean;
    onClick?: () => void
}


export const CardPromotion = (props: Props) => {

    const theme = useTheme()

    return (
        <Card
            sx={{
                height: "100%",
                border: props.promotion.borderColor ? 2 : 0,
                borderColor: props.promotion.borderColor,
                color: props.promotion.textColor || theme.palette.text.primary,
                background: props.promotion.color || props.plateformStyle.colors.grey.secondary,
                cursor: props.onClick !== undefined || props.promotion.productUri ? "pointer" : "default",
                transition: "transform 400ms",
                ":hover": {
                    transform: props.onClick !== undefined || props.promotion.productUri ? "translateY(-6px)" : "none"
                }
            }}
            onClick={() => {
                if (props.onClick !== undefined) props.onClick()
                else if (props.promotion.productUri) window.open(props.promotion.productUri, "blank")
            }}>
            <Grid
                container
                alignItems="center"
                justifyContent="space-between"
                columnSpacing={1}
                sx={{
                    height: "100%",
                    pl: !props.minified ? 1 : 2,
                    pr: props.minified && (props.onClick !== undefined || props.promotion.productUri) ? 1 : 2,
                    py: 1
                }}>
                {!props.minified && props.promotion.image &&
                <Grid
                    item
                    xs="auto">
                    <img
                        src={props.promotion.image}
                        width="60px"
                        height="60px"
                        style={{
                            verticalAlign: "middle",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "8px"
                        }}/>
                </Grid>}

                <Grid
                    item
                    xs>
                    <Box>
                        <Typography fontSize="1.1rem">{props.promotion.title.toUpperCase()}</Typography>
                        {!props.minified && props.promotion.description && <Typography variant="body2">{props.promotion.description}</Typography>}
                    </Box>
                </Grid>

                {props.onClick != undefined || props.promotion.productUri &&
                <Grid
                    item
                    xs="auto"
                    sx={{
                        display: "inline-flex",
                        alignItems: "center"
                    }}>
                    <KeyboardArrowRightIcon />
                </Grid>}
            </Grid>
        </Card>
    )
}

import { Configuration, PopupRequest } from "@azure/msal-browser";


const tenantId = process.env.REACT_APP_TENANT_ID
const clientId = process.env.REACT_APP_CLIENT_ID


// Config object to be passed to Msal on creation
export const msalConfig: Configuration = {
    auth: {
        clientId: clientId as string,
        authority: 'https://login.microsoftonline.com/' + "1635ea7a-c228-4e76-ab30-dc958ca6ae3b",
        redirectUri: window.location.origin + '/bo',
        postLogoutRedirectUri: "/bo"
    }
};

// Add here scopes for id token to be used at MS Identity Platform endpoints.
export const loginRequest: PopupRequest = {
    scopes: ["User.Read"]
};

// Add here the endpoints for MS Graph API services you would like to use.
export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft-ppe.com/v1.0/me"
};

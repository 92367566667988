
import { Ref, createRef, forwardRef, useImperativeHandle, useRef } from "react";


import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Collapse from "@mui/material/Collapse";

import { CardOffer } from "../Card/CardOffer";
import { HeaderOffer } from "../Header/HeaderOffer";
import { FormContract } from "../Form/FormContract";

import useOnScreen from "../../utils/hooks/useOnScreen";

import { IContract } from "../../model/Contract.model";
import { IFormule } from "../../model/Formule.model";

import { modifyContract } from "../../api/Contract.api";
import useMediaQuery from "@mui/material/useMediaQuery";
import { theme } from "../../static/themes/gsmc/theme";
import { IPromotion } from "../../model/Promotion.model";
import { CardPromotion } from "../Card/CardPromotion";
import * as Sentry from "@sentry/react";


interface Props {
    plateformStyle: any;
    codeOffer?: string;
    formule?: IFormule;
    formuleLoading?: Boolean;
    contract: IContract;
    showContractForm?: boolean;
    promotions?: IPromotion[];
    sendContract: (contract: IContract) => void;
}

export const Sidebar = forwardRef((props: Props, ref: Ref<any>) => {
    const refForm = useRef<any>(null);

    useImperativeHandle(ref, () => ({
        focusForm() {
            if (screenSizeUpMD) return

            if (refForm.current) {
                (refForm.current).scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
            }
        },
        handleSubmit() {
            if (formRef.current)
                return formRef.current.handleSubmit();

            return false;
        },
        leave() {
            if (formRef.current)
                formRef.current.leave()
        }
    }));

    const formRef = createRef<HTMLFormElement>();
    const refCardOffer = useRef<any>(null)
    const isVisible = useOnScreen(refCardOffer, {threshold: 0.6})

    const sendContract = (contract: IContract) => {
        props.sendContract(contract);
    }

    const saveContract = (contract: IContract) => {
        modifyContract(contract).then((response : IContract) => {
            if(!response.id) {
                // TODO : Gestion erreur.
                Sentry.captureMessage("saveContract -> modifyContract - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: response,
                            request: contract,
                        }
                    }
                );

            }
            else {
                sendContract(response);  // Update Form states.
            }
        });
    }

    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <Grid
            ref={refForm}
            container
            justify-content='center'
            spacing={2}
            sx={{
                position: "relative"
            }} >
            <Grid
                xs={12}
                item
                ref={refCardOffer}>
                <CardOffer
                    plateformStyle={props.plateformStyle}
                    codeOffer={props.codeOffer}
                    formule={props.formule}
                    formuleLoading={props.formuleLoading}
                    isDisplayed />
            </Grid>

            {props.promotions && props.promotions.length > 0 &&
            props.promotions.map(_ => {
                return (
                <Grid
                    item
                    md={12}
                    sm={6}
                    xs={12}>
                    <CardPromotion
                        plateformStyle={props.plateformStyle}
                        promotion={_}
                        minified />
                </Grid>
                )
            })}

            <Grid
                xs={12}
                sx={{
                    zIndex: 100,
                    display: {
                        md: "none",
                        xs: "flex"
                    },
                    position: "fixed",
                    top: 57,
                    p: 0,
                    left: 0,
                    width: "100%",
                    background: "white",
                }} >
                <Collapse in={!isVisible}
                    sx={{
                        width: "100%",
                    }}>
                    <HeaderOffer
                        plateformStyle={props.plateformStyle}
                        codeOffer={props.codeOffer}
                        formule={props.formule}
                        formuleLoading={props.formuleLoading}
                    />
                </Collapse>
            </Grid>

            {!isVisible &&
            <Grid
                xs={12}
                item
                sx={{
                    height: 70
                }}>
            </Grid>}

            {props.showContractForm &&
            <Grid
                xs={12}
                item>
                <Card
                    sx={{
                        p: 2
                    }}>
                    <FormContract
                        ref={formRef}
                        contract={props.contract}
                        send={sendContract}
                        save={saveContract} />
                </Card>
            </Grid>}

            <Grid
                xs={12}
                item>
            </Grid>
        </Grid>
    )
})

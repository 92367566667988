import { ThemeProvider } from '@mui/material/styles';

import { theme as themeGSMC } from '../static/themes/gsmc/theme';
import { style as styleGSMC } from '../static/themes/gsmc/style';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';

import { MainHeader } from '../components/Header/MainHeader';
import { FormHeader } from '../components/Form/FormHeader';
import { CgvContentSummary } from '../components/Content/CgvContentSummary';


export const Cgv = () => {

    document.title = 'Mutuelle GSMC - CGV';

    return (
        <ThemeProvider theme={themeGSMC}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }} >

                <MainHeader
                    plateformStyle={styleGSMC}
                    isReturnEnabled={false}
                    goToPrevious={() => { }} />

                <Box
                    sx={{
                        maxWidth: '1200px',
                        width: '100%',
                        mx: 'auto',
                        marginTop: '10px'
                    }} >
                    <Grid
                        container
                        justify-content='center'
                        spacing={2}
                        sx={{
                            position: "relative",
                            px: 2
                        }} >
                        <Grid
                            md={12}
                            item >
                            <FormHeader
                                label="Conditions Générales" />
                        </Grid>
        
                        <Grid
                            xs={12}
                            item>
                            <CgvContentSummary />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </ThemeProvider>
    )
}

import { defaultIconSize, IIcon } from "./settings"


export const ArrowLeftIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14" transform="rotate(180 15 15)" stroke={props.color} strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M11.486 22.7296C11.8311 23.0901 12.3905 23.0901 12.7356 22.7296C13.0806 22.3691 13.0806 21.7846 12.7356 21.4241L7.82476 16.5343L22.7311 16.5343C23.432 16.5343 24.0003 16.1037 24.0003 15.5726C24.0003 15.0415 23.432 14.6109 22.7311 14.6109L7.53487 14.6109L12.7356 9.1772C13.0806 8.81668 13.0806 8.23218 12.7356 7.87166C12.3905 7.51115 11.8311 7.51115 11.486 7.87166L5.00037 14.6479C4.65531 15.0084 4.65531 15.5929 5.00037 15.9534L11.486 22.7296Z" fill={props.color}/>
        </svg>
    )
}

import Grid from '@mui/material/Grid';

import { CardContact } from '../Card/CardContact';
import { CardSupport } from '../Card/CardSupport';

import { IForm } from '../../model/Form.model';
import { IPlateformeSettings, IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';
import { IPerson } from '../../model/Person.model';


interface Props {
    plateformStyle: any;
    plateformSettings: IPlateformeSettings;
    form?: IForm;
    subscriber?: IPerson;
}


export const Footer = (props: Props) => {

    const settingsContact: IPlateformeSettingsContact = props.plateformSettings.settingsContact;

    return (
        <>
        {(settingsContact.displayContactCard ||
          settingsContact.displaySupportCard) &&
        <Grid
            container
            spacing={2} >
            {settingsContact.displaySupportCard &&
            <Grid
                item
                sm={6}
                xs={12} >
                <CardSupport
                    plateformStyle={props.plateformStyle}
                    settings={settingsContact}
                    form={props.form}
                    person={props.subscriber} />
            </Grid>}

            {settingsContact.displayContactCard &&
            <Grid
                item
                sm={6}
                xs={12} >
                <CardContact
                    plateformStyle={props.plateformStyle}
                    settings={settingsContact}
                    form={props.form}
                    person={props.subscriber} />
            </Grid>}
        </Grid>}
        </>
    )
}

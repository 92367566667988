import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";

import { IPerson } from "../../model/Person.model";

import { enumHrCivitityMister } from "../../utils/enums";
import { Badge, Button, Divider, Grid, IconButton, Stack, Tooltip } from "@mui/material";
import { colors } from "../../static/themes/colors";
import { DialogControlClosed } from "../Dialog/DialogControlClosed";
import { useState } from "react";
import { ControlStatus, IControle } from "../../model/ControleDocument.model";
import { DialogControlSuspicionFraud } from "../Dialog/DialogControlSuspicionFraud";


interface Props {
    plateformStyle: any;
    control: IControle;
    subscriber: IPerson;
    formUuid: string;
    crmId?: string;
    sendControl?: (_: IControle) => void;
}


export const CardSubscriberInfo = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const [isDialogCloseOpened, setIsDialogCloseOpened] = useState<boolean>(false);
    const [isDialogFraudOpened, setIsDialogFraudOpened] = useState<boolean>(false);

    const handleMailTo = () => {
        const link = document.createElement('a');

        link.href = 'mailto:' + props.subscriber.email;
        link.click();

        document.body.appendChild(link);
    }

    const handleTelTo = () => {
        const link = document.createElement('a');

        link.href = 'tel:' + props.subscriber.phoneMobile;
        link.click();

        document.body.appendChild(link);
    }

    return (
        <Card
            sx={{
                p: 2
            }}>
            <Grid
                container
                justifyContent="flex-start"
                alignItems="stretch"
                spacing={1.5}>
                <Grid
                    item
                    xs="auto">
                    <Stack>
                        <Tooltip
                            title="Suspicion de fraude"
                            placement="right"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: colors.red.main
                                    }
                                }
                            }}>
                            <IconButton
                                onClick={() => setIsDialogFraudOpened(true)}
                                sx={{
                                    borderRadius: 4,
                                    color: colors.red.main,
                                    fontWeight: 700,
                                    fontSize: "1rem !important",
                                    ":hover": {
                                        background: colors.grey.main
                                    }
                                }}>
                                <Badge color="error" badgeContent="1" invisible={props.control.commentFraud === undefined || props.control.commentFraud === null}>
                                🕵️
                                </Badge>
                            </IconButton>
                        </Tooltip>
                        <DialogControlSuspicionFraud
                            control={props.control}
                            isOpened={isDialogFraudOpened}
                            handleClose={() => setIsDialogFraudOpened(false)}
                            send={(_: IControle) => {
                                if (props.sendControl !== undefined) props.sendControl(_)
                            }}/>

                        {props.control.status !== ControlStatus.CLOSED && props.control.status !== ControlStatus.MEG &&
                        <>
                        <Tooltip
                            title="Clôturer l'adhésion"
                            placement="right"
                            componentsProps={{
                                tooltip: {
                                    sx: {
                                        bgcolor: colors.red.main
                                    }
                                }
                            }}>
                            <IconButton
                                onClick={() => setIsDialogCloseOpened(true)}
                                sx={{
                                    borderRadius: 4,
                                    color: colors.red.main,
                                    fontWeight: 700,
                                    fontSize: "1rem !important",
                                    ":hover": {
                                        background: colors.grey.main
                                    }
                                }}>
                                ❌
                            </IconButton>
                        </Tooltip>
                        <DialogControlClosed
                            control={props.control}
                            isOpened={isDialogCloseOpened}
                            handleClose={() => setIsDialogCloseOpened(false)}
                            send={(_: IControle) => {
                                if (props.sendControl !== undefined) props.sendControl(_)
                            }}/>
                        </>}

                    </Stack>
                </Grid>

                <Grid
                    item
                    xs="auto">
                    <Divider orientation="vertical" />
                </Grid>

                <Grid item xs>
                    <Typography
                        variant='caption'
                        sx={{
                            mb: 1
                        }}>
                        Demande d’adhésion de
                    </Typography>

                    <Typography
                        variant='h3'
                        sx={{
                            mb: 1
                        }}>
                        {props.subscriber.civility === enumHrCivitityMister ? 'M.' : 'Mme'} {props.subscriber.firstname} {props.subscriber.lastname}
                    </Typography>

                    {props.subscriber.dateBirth &&
                    <Typography
                        variant='body1'
                        color={plateformStyle.colors.black.secondary}
                        fontWeight={500}>
                        {new Date(props.subscriber.dateBirth).getDate().toString().padStart(2, '0')  + "/" + (new Date(props.subscriber.dateBirth).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(props.subscriber.dateBirth).getFullYear()}
                    </Typography>}

                    <Typography
                        variant='body1'
                        color={plateformStyle.colors.black.secondary}
                        fontWeight={500}>
                        {props.subscriber.adress?.adressLine1}
                    </Typography>

                    <Typography
                        variant='body1'
                        color={plateformStyle.colors.black.secondary}
                        fontWeight={500}>
                        {props.subscriber.adress?.postalCode} {props.subscriber.adress?.city} - {props.subscriber.adress?.country}
                    </Typography>

                    <Typography
                        variant='body1'
                        color={plateformStyle.colors.black.secondary}
                        fontWeight={500}
                        sx={{
                            mt: 1,
                            cursor: 'pointer',
                            ":hover": {
                                textDecoration: "underline",
                                textDecorationColor: colors.blue.main
                            }
                        }}
                        onClick={() => handleMailTo()}>
                        <Link underline="none">{props.subscriber.email}</Link>
                    </Typography>

                    <Typography
                        variant='body1'
                        color={plateformStyle.colors.black.secondary}
                        fontWeight={500}
                        sx={{
                            mt: 1,
                            cursor: 'pointer',
                            ":hover": {
                                textDecoration: "underline",
                                textDecorationColor: colors.blue.main
                            }
                        }}
                        onClick={() => handleTelTo()}>
                        <Link underline="none">{props.subscriber.phoneMobile}</Link>
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <Link underline="none" href={`/doc/${props.formUuid}`} target="_blank" rel="noopener">
                        <Button
                            fullWidth
                            sx={{
                                justifyContent: "flex-start",
                                height: "auto",
                                px: 1.5,
                                py: 1,
                                borderRadius: 4,
                                color: colors.blue.main,
                                fontWeight: 700,
                                lineHeight: "normal",
                                fontSize: "1rem !important",
                                ":hover": {
                                    background: colors.grey.main
                                }
                            }}>
                            📚 Espace Document
                        </Button>
                    </Link>

                    <Link underline="none" href={`${process.env.REACT_APP_CRM_URL}/read_opportunity.fl?id=${props.crmId}`} target="_blank" rel="noopener">
                        <Button
                            fullWidth
                            sx={{
                                justifyContent: "flex-start",
                                height: "auto",
                                px: 1.5,
                                py: 1,
                                borderRadius: 4,
                                color: colors.blue.main,
                                fontWeight: 700,
                                lineHeight: "normal",
                                fontSize: "1rem !important",
                                ":hover": {
                                    background: colors.grey.main
                                }
                            }}>
                            🤝 Opportunité CRM
                        </Button>
                    </Link>
                </Grid>

            </Grid>
        </Card>
    )
}

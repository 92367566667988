import { useEffect, useState } from 'react';

import { ChromePicker } from 'react-color';

import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import { ClickAwayListener, Grid, InputAdornment } from '@mui/material';
import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    id?: string;
    disabled?: Boolean;
    label?: string | JSX.Element;
    value?: string;
    onChange: (value: string) => void;
    onBlur?: (e: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
}


export const FieldColorPicker = (props: Props) => {

    const [displayPicker, setDisplayPicker] = useState<boolean>(false);
    const [value, setValue] = useState<string | undefined>(props.value);


    useEffect(() => {
        if (value) {
            props.onChange(value);
        }
    }, [value])


    return (
        <ClickAwayListener
            onClickAway={() => setDisplayPicker(false)}>
            <Box
                onFocus={() => setDisplayPicker(true)}>
                <TextField
                    label={props.label}
                    fullWidth
                    value={value}
                    disabled={Boolean(props.disabled)}
                    onChange={(e) => {
                        setValue(e.target.value);
                        props.onChange(e.target.value);
                    }}
                    error={props.error}
                    helperText={props.helperText}
                    InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Box
                                sx={{
                                    width: "20px",
                                    height: "20px",
                                    border: 1,
                                    borderColor: colors.grey.main,
                                    borderRadius: 1,
                                    backgroundColor: value || "white"
                                }}>
                            </Box>
                        </InputAdornment>
                    )
                    }} />

                {displayPicker &&
                <Box
                    sx={{
                        position: "absolute",
                        zIndex: 100000000,
                        mt: 2,
                        ml: 2
                    }}>
                    <ChromePicker
                        color={value || colors.blue.main}
                        onChange={(_) => setValue(_.hex)} />
                </Box>}
            </Box>
        </ClickAwayListener>
    )
}

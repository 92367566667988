import { IContractBilling } from "../model/ContractBilling.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const checkIban = (contractId: number, iban: string) : Promise<any> => {

    return fetch(`${apiUrl}/contract_billing/unique?contractId=${contractId}&iban=${iban.replaceAll(" ", "")}`,
    {
        method: "GET",
    })
    .then(_ => _.json())
}


export const createContractBilling = (contractBilling: IContractBilling) : Promise<IContractBilling> => {

    return fetch(`${apiUrl}/contract_billing`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractBilling)

    }).then(_ => _.json()) 
}


export const modifyContractBilling = (contractBilling: IContractBilling) : Promise<IContractBilling> => {

    return fetch(`${apiUrl}/contract_billing`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractBilling)

    }).then(_ => _.json()) 
}


export const deleteContractBilling = (id: number) : Promise<IContractBilling> => {

    return fetch(`${apiUrl}/contract_billing/${id}`,
    {
        method: "DELETE",

    }).then(_ => _.json()) 
}

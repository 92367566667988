import { createRef, useState } from 'react';

import Alert, { AlertColor } from '@mui/material/Alert';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Slide from '@mui/material/Slide';
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';

import { colors } from '../../../static/themes/gsmc/colors';

import { IPromotionDTO } from '../../../model/Promotion.model';
import { FormPromotion } from '../../../components/Form/Promotion/FormPromotion';
import { CheckFullIcon } from '../../../components/Icon/CheckFullIcon';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';


interface Props {
    promotion?: IPromotionDTO | null;
    send?: (promotion: IPromotionDTO) => void;
}


export const PromotionContent = (props: Props) => {


    const formRef = createRef<HTMLFormElement>();
    
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    return (
        <>
        <Grid
            container
            justifyContent="space-between"
            alignItems="stretch"
            spacing={2}>
            <Grid
                item
                xs={12}>
                {props.promotion === null ?
                <></>

                :
                <FormPromotion
                    ref={formRef}
                    promotion={props.promotion}
                    send={(_) => {
                        if (props.send) props.send(_)
                    }}
                    save={(_) => {
                        if (props.send && _ !== undefined) props.send(_)

                        if (_ === undefined) {
                            setSnackbarMessage(`Une erreur est survenue lors de la ${props.promotion?.id ? "mise à jour" : "création"} de la promotion`)
                            setSnackbarSeverity("error")
                            setIsSnackbarOpened(true)
                        }
                        else {
                            setSnackbarMessage(props.promotion?.id ? "Promotion mise à jour" : "Promotion créée")
                            setSnackbarSeverity("success")
                            setIsSnackbarOpened(true)
                        }
                    }} />}
            </Grid>

        </Grid>

        <Snackbar
            sx={{
                zIndex: 1000,
                width: {
                    sm: '100%'
                },
                px: {
                    sm: 2
                },
                py: 'auto'
            }}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            open={isSnackbarOpened}
            autoHideDuration={2000}
            onClose={handleSnackbarClose}
            TransitionComponent={(_) => <Slide {..._} direction="down" />} >
            <Alert
                sx={{
                    mx: {
                        sm: 2
                    },
                    my: 'auto',
                }}
                severity={snackbarSeverity as AlertColor}
                iconMapping={{
                    success: <CheckFullIcon bgColor='white' color={colors.green.main} />,
                    error: <CloseFullIcon bgColor='white' color={colors.red.main} />,
                }}
                action={
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={handleSnackbarClose}>
                        <CloseIcon fontSize="inherit" fontWeight={700} />
                    </IconButton>
                }>
                <Typography fontWeight={500}>{snackbarMessage}</Typography>
            </Alert>
        </Snackbar>
        </>
    )
}

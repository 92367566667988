
import { msalConfig } from "../../utils/authConfig";
import { PublicClientApplication } from "@azure/msal-browser";
import { IPageUser, IUser } from "../../model/auth/User.model";
import AuthService from "../../services/AuthService";

export const msalInstance = new PublicClientApplication(msalConfig);

const accessTokenRequest = {
    scopes: ["user.read"],
    account: msalInstance.getAllAccounts()[0]
}

const authService = new AuthService();


const apiUrl = process.env.REACT_APP_API_URL + '/auth'


export const getUserCurrent = (): Promise<IUser> => {

    return fetch(`${apiUrl}/account/me`,
        {
            method: "GET",
            headers: {
                "Authorization": authService.getToken() || ""
            },
        }).then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const updateUserCurrent = (payload: IUser): Promise<IUser> => {

    return fetch(`${apiUrl}/account/me`,
        {
            method: "PUT",
            headers: {
                "content-type": "application/json",
                "Authorization": authService.getToken() || ""
            },
            body: JSON.stringify(payload)
        }).then(_ => {
            if ([401, 403, 404, 500, 503].includes(_.status)) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const getUsers = (page: number, pageSize: number, search?: string, isActive?: boolean, isActivated?: boolean): Promise<IPageUser> => {

    let url: string = `${apiUrl}/account?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`
    if (isActive)
        url += `&isActive=${isActive}`
    if (isActivated)
        url += `&isActivated=${isActivated}`

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(url,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                },
            })
            .then(_ => {
                if (_.status === 200)
                    return _.json();
                throw "getUsers status=" + _.status;
            })
    });
}

export const getUser = (id: number): Promise<IUser> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/account/${id}`,
            {
                method: "GET",
                headers: {
                    "Authorization": accessTokenResponse.idToken
                },
            }).then(_ => _.json())
    });
}

export const getUserByResetToken = (token: string): Promise<IUser> => {
    return fetch(`${apiUrl}/account/reset/${token}`,
        {
            method: "GET"
        }).then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const createUser = (payload: IUser): Promise<IUser> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/account`,
            {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(payload)
            }).then(_ => {
                if ([401, 403, 404, 500, 503].includes(_.status)) {
                    throw new Error(_.status.toString())
                }
                return _.json()
            })
    });
}


export const updateUser = (id: number, payload: IUser): Promise<IUser> => {

    return msalInstance.acquireTokenSilent(accessTokenRequest).then(accessTokenResponse => {
        return fetch(`${apiUrl}/account/${id}`,
            {
                method: "PUT",
                headers: {
                    "content-type": "application/json",
                    "Authorization": accessTokenResponse.idToken
                },
                body: JSON.stringify(payload)
            }).then(_ => {
                if ([401, 403, 404, 500, 503].includes(_.status)) {
                    throw new Error(_.status.toString())
                }
                return _.json()
            })
    });
}

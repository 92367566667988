import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Button, DialogActions, Grid, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import { CardInfo } from '../Card/CardInfo';

import { IPerson } from '../../model/Person.model';

import { enumGsmcRegimeAM, enumGsmcRegimeGeneral } from '../../utils';
import { TransitionSlideUP } from '../../utils/transistions/transitions';


interface Props {
    plateformStyle: any;
    isDisplayed: boolean;
    subscriber: IPerson;
    onChange: (regime: string) => void;
    onClose: () => void;
}


export const DialogRegime = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle = props.plateformStyle;

    const dep = props.subscriber.adress?.postalCode ? parseInt(props.subscriber.adress?.postalCode.slice(0, 2)) : undefined
    const isDepAM = (dep === 57 || dep === 67 || dep === 68)
    const isAM = props.subscriber.regime === enumGsmcRegimeAM

    return (
        <Dialog
            open={props.isDisplayed}
            fullWidth
            maxWidth='md'
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.onClose();
            }}>
            <DialogTitle>
                {!isAM ?
                <CardInfo
                    plateformStyle={plateformStyle}
                    type='info'>
                    <Typography fontWeight={500}>
                        Votre adresse est située dans un département d'Alsace-Moselle.
                    </Typography>
                </CardInfo>

                :
                <Typography variant="h3">
                    Votre département n'est pas compatible avec votre régime (Alsace-Moselle).
                </Typography>}
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    spacing={2}
                    sx={{
                        textAlign: "justify"
                    }} >
                    {!isAM ?
                    <Grid
                        item
                        xs={12}>
                        <Typography fontWeight={500}>
                            Changer votre régime si vous bénéficiez du régime Alsace-Moselle.
                        </Typography>
                    </Grid>

                    :
                    <>
                    <Grid
                        item
                        xs={12}>
                        <CardInfo
                            plateformStyle={plateformStyle}
                            type='error'>
                            <Typography fontWeight={500}>
                                Vous devez être dans un département d'Alsace-Moselle pour bénéficier du régime Alsace-Moselle.
                            </Typography>
                        </CardInfo>
                    </Grid>
                    <Grid
                        item
                        xs={12}>
                        <Typography>
                            Sélectionner le régime Général ou modifier votre adresse en cliquant sur “Retour”.
                        </Typography>
                    </Grid>
                    </>}

                    <Grid
                        item
                        xs={12}>
                        <Typography fontWeight={500}>
                            ⚠️ Changer votre régime de Sécurité Sociale aura une incidence sur le tarif proposé.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Grid
                    container
                    spacing={2}
                    justifyContent="flex-end"
                    alignItems="flex-end">
                    <Grid
                        item
                        sm="auto"
                        xs={12} >
                        <Button
                            fullWidth
                            autoFocus
                            variant="text"
                            onClick={() => props.onClose()}
                            sx={{
                                px: 3,
                                color: plateformStyle.colors.black.main
                            }} >
                            Retour
                        </Button>
                    </Grid>
                    <Grid
                        item
                        sm="auto"
                        xs={12} >
                        <Button
                            autoFocus
                            fullWidth
                            variant="contained"
                            onClick={() => props.onChange(!isAM ? enumGsmcRegimeAM : enumGsmcRegimeGeneral)}
                            sx={{
                                px: 3,
                                color: 'white',
                                backgroundColor: plateformStyle.colors.black.main,
                                ':hover': {
                                    backgroundColor: 'black'
                                }
                            }} >
                            Je bénéficie du {isAM ? "régime Général" : "régime Alsace-Moselle"}
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

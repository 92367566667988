import { IRefFormule, IPageRefFormule } from "../model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getRefFormules = (page: number, pageSize: number) : Promise<IPageRefFormule> => {

    return fetch(`${apiUrl}/ref_formule_henner_gsmc?page=${page}&pageSize=${pageSize}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getRefFormule = (id: number): Promise<IRefFormule> => {

    return fetch(`${apiUrl}/ref_formule_henner_gsmc/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getRefFormuleByHennerId = (hennerId: number): Promise<IRefFormule> => {

    return fetch(`${apiUrl}/ref_formule_henner_gsmc?hennerId=${hennerId}`,
    {
        method: "GET",
    })
    .then(_ => _.json()) 
}

export const createRefFormule = (ref_formule_henner_gsmc: IRefFormule) : Promise<IRefFormule> => {

    return fetch(`${apiUrl}/ref_formule_henner_gsmc`,
    {
        method: "POST",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(ref_formule_henner_gsmc)

    }).then(_ => _.json()) 
}

export const modifyRefFormule = (ref_formule_henner_gsmc: IRefFormule) : Promise<IRefFormule> => {

    return fetch(`${apiUrl}/ref_formule_henner_gsmc`,
    {
        method: "PUT",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(ref_formule_henner_gsmc)

    }).then(_ => _.json()) 
}

import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import TablePagination from "@mui/material/TablePagination";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import WarningIcon from '@mui/icons-material/Warning';

import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';

import AppBarHeader from "../../../components/AppBar/AppBarHeader";

import { ControlStatus, controlStatusParam, IControle } from "../../../model/ControleDocument.model";
import { getControl } from "../../../api/Controle.api";

import { colors } from '../../../static/themes/gsmc/colors';
import { Autocomplete, AutocompleteCloseReason, Badge, ClickAwayListener, Link, Popper } from "@mui/material";
import { FilterAlt } from "@mui/icons-material";
import CloseIcon from '@mui/icons-material/Close';
import DoneIcon from '@mui/icons-material/Done';


export const SubscriptionList = () => {
    
    const [anchorFilterStatus, setAnchorFilterStatus] = useState<null | HTMLElement>(null);

    const [controls, setControls] = useState<IControle[] | undefined | null>(undefined);
    const [count, setCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [filterStatus, setFilterStatus] = useState<any[]>([]);

    document.title = 'GSMC - Souscriptions';

    useEffect(() => {
        getControl(pageNumber, rowPerPage, search, filterStatus.length === 0 ? controlStatusParam.map(_ => _.value).filter(_ => _ !== ControlStatus.INCOMPLETE) : filterStatus.map(_ => _.value).filter(_ => _ !== ControlStatus.INCOMPLETE)).then(response => {
            setControls(response.content);
            setCount(response.totalElements);
        })
        .catch((exception) => {
            setControls(null);
            setCount(0);
        });
    }, [pageNumber, rowPerPage])

    const forceRefresh = () => {
        getControl(pageNumber, rowPerPage, search, filterStatus.length === 0 ? controlStatusParam.map(_ => _.value).filter(_ => _ !== ControlStatus.INCOMPLETE) : filterStatus.map(_ => _.value).filter(_ => _ !== ControlStatus.INCOMPLETE)).then(response => {
            setControls(response.content);
            setCount(response.totalElements);
        })
        .catch((exception) => {
            setControls(null);
            setCount(0);
        });
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])

    useEffect(() => {
        if (controls === undefined) return;
        forceRefresh();
    }, [filterStatus])

    const openFilterStatus = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterStatus(event.currentTarget);
    };

    const closeFilterStatus = () => {
        if (anchorFilterStatus) {
            anchorFilterStatus.focus();
        }
        setAnchorFilterStatus(null);
    };

    const open = Boolean(anchorFilterStatus);

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <EmojiEventsIcon />},
                {type: "LINK", label: "Souscription"}
            ]} />

        <Box
            sx={{
                overflow: "auto",
                p: 2
            }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="center"
                        spacing={3}>
                        <Grid
                            item
                            xs >
                            <Stack direction="row" alignItems="center" gap={1}>
                                <TextField
                                    fullWidth
                                    variant="outlined"
                                    id="search"
                                    name="search"
                                    onKeyDown={keyPress}
                                    value={search}
                                    onChange={(e: any) => setSearch(e.target.value)}
                                    InputProps={{
                                        placeholder: 'Recherche',
                                        startAdornment: (
                                            <InputAdornment
                                                position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    sx={{
                                        maxWidth: "500px"
                                    }}/>

                                    <IconButton onClick={openFilterStatus} color={open ? "primary" : "default"}>
                                        <Badge badgeContent={filterStatus.length} color="primary">
                                            <FilterAlt/>
                                        </Badge>
                                    </IconButton>
            
                                    {filterStatus.map((_) => (
                                    <Chip
                                        label={_.label}
                                        sx={{
                                            color: 'white',
                                            border: 2,
                                            borderColor: _.borderColor || _.color,
                                            backgroundColor: _.color,
                                            fontSize: 14
                                        }}
                                        onDelete={() => setFilterStatus(filterStatus.filter(f => f.value != _.value))} />
                                    ))}
            
                                    <Popper open={open} anchorEl={anchorFilterStatus} placement="bottom-start">
                                        <ClickAwayListener onClickAway={closeFilterStatus}>
                                            <Card>
                                                <Autocomplete
                                                    open
                                                    multiple
                                                    value={filterStatus}
                                                    options={controlStatusParam.filter(_=> _.value != ControlStatus.INCOMPLETE)}
                                                    getOptionLabel={(option) => option.label}
                                                    disableCloseOnSelect
                                                    onChange={(event, newValue, reason) => {
                                                        if (event.type === 'keydown' &&
                                                            (event as React.KeyboardEvent).key === 'Backspace' &&
                                                            reason === 'removeOption') {
                                                            return;
                                                        }
                                                        setFilterStatus(newValue);
                                                    }}
                                                    onClose={(
                                                        event: React.ChangeEvent<{}>,
                                                        reason: AutocompleteCloseReason,
                                                    ) => {
                                                        if (reason === 'escape') {
                                                            closeFilterStatus();
                                                        }
                                                    }}
                                                    renderOption={(props, option, { selected }) => (
                                                    <li {...props}
                                                        style={{
                                                            alignItems: "flex-start"
                                                        }}>
                                                        <Box
                                                            component="span"
                                                            sx={{
                                                                width: 10,
                                                                height: 10,
                                                                flexShrink: 0,
                                                                borderRadius: '3px',
                                                                mr: 1,
                                                                mt: "6px",
                                                                border: 2
                                                            }}
                                                            style={{
                                                                backgroundColor: option.color,
                                                                borderColor: option.borderColor || option.color
                                                            }}
                                                        />
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1
                                                            }}>
                                                            <Typography fontWeight={500}>
                                                                {option.label}
                                                            </Typography>
                                                            {option.description &&
                                                            <Typography fontWeight={500} variant="caption">
                                                                {option.description}
                                                            </Typography>}
                                                        </Box>
                                                        <Box
                                                            component={CloseIcon}
                                                            sx={{ opacity: 0.6, width: 18, height: 18,
                                                                mt: "4px", }}
                                                            style={{
                                                                visibility: selected ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                    </li>
                                                    )}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            ref={params.InputProps.ref}
                                                            inputProps={params.inputProps}
                                                            autoFocus
                                                            placeholder="Statut"
                                                        />
                                                    )} />
                                            </Card>
                                        </ClickAwayListener>
                                    </Popper>
                            </Stack>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell align="left">Id</TableCell>
                                        <TableCell align="left">Statut</TableCell>
                                        <TableCell align="left">Offre</TableCell>
                                        <TableCell align="left">Date souscription</TableCell>
                                        <TableCell align="left">Souscripteur</TableCell>
                                        <TableCell align="left">Plateforme</TableCell>
                                        <TableCell align="left">N° contrat</TableCell>
                                        <TableCell align="left">Vérificateur</TableCell>
                                        <TableCell align="left">Date de vérification</TableCell>
                                        <TableCell
                                            key={'outil'}
                                            sx={{
                                                minWidth: '20px',
                                                position: "sticky",
                                                right: 0,
                                                background: colors.grey.secondary,
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {controls === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des documents à vérifier en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des documents à vérifier
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={10}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucune souscription
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : controls.map((_, id) => {
                                        const statusParam = controlStatusParam.find(e => e.value === _.status) || controlStatusParam[0];

                                        const isMegKO = _.hennerSent && _.form.contract.hennerContract?.isCallOver && (!_.form.contract.hennerContract?.codeContrat || _.form.contract.hennerContract.codeContrat === "GSM0");

                                        return (
                                            <TableRow key={id}>
                                                <TableCell align="left">#{_.id}</TableCell>
                                                <TableCell align="left">
                                                    <Tooltip
                                                        placement="top-start"
                                                        title="La mise en gestion automatique a échouée"
                                                        disableHoverListener={!isMegKO}
                                                        slotProps={{
                                                            popper: {
                                                                modifiers: [
                                                                {
                                                                    name: 'offset',
                                                                    options: {
                                                                    offset: [0, -8],
                                                                    },
                                                                },
                                                                ],
                                                            },
                                                            }} >
                                                        <Chip
                                                            color="default"
                                                            label={`${isMegKO ? "⚠️ " : ""}${statusParam.label}`}
                                                            sx={{
                                                                border: isMegKO ? 2 : 0,
                                                                borderColor: colors.red.main,
                                                                color: statusParam.textColor || 'white',
                                                                backgroundColor: statusParam.color,
                                                                fontSize: 14,
                                                                ":hover": {
                                                                    color: statusParam.textColor || 'white',
                                                                    backgroundColor: statusParam.color
                                                                }
                                                            }} />
                                                    </Tooltip>
                                                </TableCell>
                                                <TableCell align="left">{_.offerCode ? _.offerCode === "312" ? "SENIOR" :  _.offerCode === "512" ? "GENERATION" : _.offerCode === "539" ? "TNS" : _.offerCode : "-"}</TableCell>
                                                <TableCell align="left">
                                                    {(_.techDateCreated !== undefined && _.techDateCreated !== null) ?
                                                        new Date(_.techDateCreated).getDate().toString().padStart(2, '0') + "/" + (new Date(_.techDateCreated).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.techDateCreated).getFullYear() : "-"}
                                                </TableCell>
                                                <TableCell align="left">{_.form.contract.person.lastname} {_.form.contract.person.firstname}<br/>{_.form.contract.person.email}</TableCell>
                                                <TableCell align="left">{_.plateformeCode || "-"}</TableCell>
                                                <TableCell align="left">{_.form.contract.hennerContract?.codeContrat || "-"}</TableCell>
                                                <TableCell align="left">{_.user ? `@${_.user.username}` : "-"}</TableCell>
                                                <TableCell align="left">
                                                    {(_.techDateControl !== undefined && _.techDateControl !== null) ?
                                                        new Date(_.techDateControl).getDate().toString().padStart(2, '0') + "/" + (new Date(_.techDateControl).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.techDateControl).getFullYear() : "-"}
                                                </TableCell>
                                                <TableCell
                                                    align="left"
                                                    sx={{
                                                        position: "sticky",
                                                        right: 0,
                                                        background: colors.grey.secondary
                                                    }}>
                                                    <Link href={`/verification/souscription/${_.id.toString()}`} underline="none">
                                                        <Button
                                                            sx={{
                                                                py: 1
                                                            }}>
                                                            Voir
                                                        </Button>
                                                    </Link>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={count}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

import { useEffect, useState } from 'react';

import * as Sentry from '@sentry/react';

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import WebhookIcon from '@mui/icons-material/Webhook';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldLabel } from '../Field/FieldLabel';

import { ICampaignSettings } from '../../model/Campaign.model';
import { updateCampaignSettings } from '../../api/Campaign.api';

import { gsmcUrl, messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settings: ICampaignSettings;
    send: (settings: ICampaignSettings) => void;
    handleError: (message: string) => void;
}


export const FormCampaignSettings = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        redirectionUrl: yup.string().nullable(),
        isEfficy: yup.boolean().required(messageFieldRequired),
        isMail: yup.boolean().required(messageFieldRequired),
        isSftp: yup.boolean().required(messageFieldRequired),
        mail: yup.string().email("Email non valide").nullable(),
        sftpHost: yup.string().nullable(),
        sftpPort: yup.number().nullable(),
        sftpUser: yup.string().nullable(),
        sftpPassword: yup.string().nullable(),
        sftpPath: yup.string().nullable()
    })

    const formik = useFormik({
        initialValues: {
            redirectionUrl: props.settings.redirectionUrl,
            isEfficy: props.settings.isEfficy,
            isMail: props.settings.isMail,
            isSftp: props.settings.isSftp,
            mail: props.settings.mail,
            sftpHost: props.settings.sftpHost,
            sftpPort: props.settings.sftpPort,
            sftpUser: props.settings.sftpUser,
            sftpPassword: props.settings.sftpPassword,
            sftpPath: props.settings.sftpPath
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};

            if (values.isMail && !values.mail)
                errors.mail = "Champ obligatoire avec la méthode email";

            if (values.isSftp && !values.sftpHost)
                errors.sftpHost = "Champ obligatoire avec la méthode SFTP";

            if (values.isSftp && !values.sftpPort)
                errors.sftpPort = "Champ obligatoire avec la méthode SFTP";

            if (values.isSftp && !values.sftpUser)
                errors.sftpUser = "Champ obligatoire avec la méthode SFTP";

            if (values.isSftp && !values.sftpPassword)
                errors.sftpPassword = "Champ obligatoire avec la méthode SFTP";

            if (values.isSftp && !values.sftpPath)
                errors.sftpPath = "Champ obligatoire avec la méthode SFTP";

            return errors;
        },
        onSubmit: (values) => {

            const toSend: ICampaignSettings = {
                id: props.settings.id,
                campaign: props.settings.campaign,
                redirectionUrl: values.redirectionUrl,
                isEfficy: values.isEfficy,
                isMail: values.isMail,
                isSftp: values.isSftp,
                mail: values.mail,
                sftpHost: values.sftpHost,
                sftpPort: values.sftpPort,
                sftpUser: values.sftpUser,
                sftpPassword: values.sftpPassword,
                sftpPath: values.sftpPath
            }

            updateCampaignSettings(toSend)
                .then((response: ICampaignSettings) => {
                    props.send(response);
                    setSuccess(true);
                })
                .catch(exception => {
                    Sentry.captureException(exception);
                    props.handleError("Erreur lors de l'enregistrement des paramètres.");
                    setError(true);
                });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <WebhookIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Actions
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Renseigner les actions effectuées suite à une demande de rappel pour cette campagne
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='h3'>
                                Redirection
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les utilisateurs seront redirigés vers cet URL une fois leur demande de contact terminée
                            </Typography>
                            <TextField
                                fullWidth
                                id="redirectionUrl"
                                name="redirectionUrl"
                                placeholder={gsmcUrl}
                                value={formik.values.redirectionUrl}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.redirectionUrl && Boolean(formik.errors.redirectionUrl)}
                                helperText={formik.touched.redirectionUrl && formik.errors.redirectionUrl}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Divider light />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='h3'>
                                Efficy
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les demandes de contact seront enregistrées dans le CRM
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isEfficy}
                                        onChange={(e, value) => formik.setFieldValue("isEfficy", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Enregistrer dans le CRM
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.isEfficy && Boolean(formik.errors.isEfficy)} >
                                {formik.touched.isEfficy && formik.errors.isEfficy}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Divider light />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='h3'>
                                Email
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les demandes de contact seront envoyées à l'adresse de destination
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isMail}
                                        onChange={(e, value) => formik.setFieldValue("isMail", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Envoyer la demande par mail
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.isMail && Boolean(formik.errors.isMail)} >
                                {formik.touched.isMail && formik.errors.isMail}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.mail && Boolean(formik.errors.mail)} >
                                <FieldLabel
                                    label="Email de destination"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isMail)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                Email de destination des demandes de contact
                            </Typography>
                            <TextField
                                disabled={!formik.values.isMail}
                                fullWidth
                                id="mail"
                                name="mail"
                                value={formik.values.mail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.mail && Boolean(formik.errors.mail)}
                                helperText={formik.touched.mail && formik.errors.mail}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Divider light />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Typography
                                variant='h3'>
                                SFTP
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Les demandes de contact seront enregistrées sur un SFTP
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.isSftp}
                                        onChange={(e, value) => formik.setFieldValue("isSftp", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Envoyer la demande sur un SFTP
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.isSftp && Boolean(formik.errors.isSftp)} >
                                {formik.touched.isSftp && formik.errors.isSftp}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12} >
                            <FormLabel error={formik.touched.sftpHost && Boolean(formik.errors.sftpHost)} >
                                <FieldLabel
                                    label="Host"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isSftp)
                                    } />
                            </FormLabel>
                            <TextField
                                disabled={!formik.values.isSftp}
                                fullWidth
                                id="sftpHost"
                                name="sftpHost"
                                value={formik.values.sftpHost}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sftpHost && Boolean(formik.errors.sftpHost)}
                                helperText={formik.touched.sftpHost && formik.errors.sftpHost}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12} >
                            <FormLabel error={formik.touched.sftpPort && Boolean(formik.errors.sftpPort)} >
                                <FieldLabel
                                    label="Port"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isSftp)
                                    } />
                            </FormLabel>
                            <TextField
                                disabled={!formik.values.isSftp}
                                type='number'
                                fullWidth
                                id="sftpPort"
                                name="sftpPort"
                                value={formik.values.sftpPort}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sftpPort && Boolean(formik.errors.sftpPort)}
                                helperText={formik.touched.sftpPort && formik.errors.sftpPort}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12} >
                            <FormLabel error={formik.touched.sftpUser && Boolean(formik.errors.sftpUser)} >
                                <FieldLabel
                                    label="Utilisateur"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isSftp)
                                    } />
                            </FormLabel>
                            <TextField
                                disabled={!formik.values.isSftp}
                                fullWidth
                                id="sftpUser"
                                name="sftpUser"
                                value={formik.values.sftpUser}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sftpUser && Boolean(formik.errors.sftpUser)}
                                helperText={formik.touched.sftpUser && formik.errors.sftpUser}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12} >
                            <FormLabel error={formik.touched.sftpPassword && Boolean(formik.errors.sftpPassword)} >
                                <FieldLabel
                                    label="Mot de passe"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isSftp)
                                    } />
                            </FormLabel>
                            <TextField
                                disabled={!formik.values.isSftp}
                                fullWidth
                                type='password'
                                id="sftpPassword"
                                name="sftpPassword"
                                value={formik.values.sftpPassword}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sftpPassword && Boolean(formik.errors.sftpPassword)}
                                helperText={formik.touched.sftpPassword && formik.errors.sftpPassword}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.sftpPath && Boolean(formik.errors.sftpPath)} >
                                <FieldLabel
                                    label="Chemin"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.isSftp)
                                    } />
                            </FormLabel>
                            <TextField
                                disabled={!formik.values.isSftp}
                                fullWidth
                                id="sftpPath"
                                name="sftpPath"
                                value={formik.values.sftpPath}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.sftpPath && Boolean(formik.errors.sftpPath)}
                                helperText={formik.touched.sftpPath && formik.errors.sftpPath}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import { defaultIconStepSize, IIcon } from "./settings"


export const StepConfirmationIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconStepSize.width}
            height={props.height ? props.height : defaultIconStepSize.height}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <ellipse cx="40" cy="40" rx="40" ry="40" transform="rotate(-90 40 40)" fill={props.color}/>
        <path d="M74.0187 29.1589C74.0187 39.069 65.9849 47.1028 56.0748 47.1028C46.1646 47.1028 38.1309 39.069 38.1309 29.1589C38.1309 19.2487 46.1646 11.215 56.0748 11.215C65.9849 11.215 74.0187 19.2487 74.0187 29.1589Z" fill="url(#paint0_linear_6_205)"/>
        <g filter="url(#filter0_bd_6_205)">
        <path d="M20.187 33.6449C20.187 31.1674 22.1955 29.1589 24.673 29.1589H55.3272C57.8047 29.1589 59.8132 31.1674 59.8132 33.6449V50.8412C59.8132 53.3187 57.8047 55.3272 55.3272 55.3272H24.673C22.1955 55.3272 20.187 53.3187 20.187 50.8412V33.6449Z" fill="white"/>
        </g>
        <g filter="url(#filter1_d_6_205)">
        <path d="M20.187 33.3992C20.187 31.0574 22.0855 29.1589 24.4273 29.1589H55.5729C57.9147 29.1589 59.8132 31.0574 59.8132 33.3992C59.8132 35.0015 58.91 36.4669 57.4787 37.1871L44.3687 43.7833C41.6204 45.1661 38.3798 45.1661 35.6315 43.7833L22.5215 37.1871C21.0902 36.4669 20.187 35.0015 20.187 33.3992Z" fill="white"/>
        </g>
        <path d="M9.34579 35.8878L10.4564 38.8893L13.4579 40L10.4564 41.1106L9.34579 44.1121L8.23514 41.1106L5.23364 40L8.23514 38.8893L9.34579 35.8878Z" fill="white"/>
        <path d="M52.7103 10.4673L53.8209 13.4688L56.8224 14.5794L53.8209 15.6901L52.7103 18.6916L51.5996 15.6901L48.5981 14.5794L51.5996 13.4688L52.7103 10.4673Z" fill="white"/>
        <path d="M57.1961 53.8318L58.3068 56.8333L61.3083 57.9439L58.3068 59.0546L57.1961 62.0561L56.0855 59.0546L53.084 57.9439L56.0855 56.8333L57.1961 53.8318Z" fill="white"/>
        <defs>
        <filter id="filter0_bd_6_205" x="0.187012" y="13.1589" width="79.6262" height="66.1682" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_6_205"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_6_205" result="effect2_dropShadow_6_205"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_6_205" result="shape"/>
        </filter>
        <filter id="filter1_d_6_205" x="0.187012" y="13.1589" width="79.6262" height="55.6615" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_6_205"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_6_205" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_6_205" x1="56.0748" y1="11.215" x2="56.0748" y2="47.1028" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.7"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

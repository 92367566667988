import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import PersonIcon from '@mui/icons-material/Person';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import CustomizedDialogs from '../../../components/Dialog/CustomizedDialog';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';


import { messageFieldRequired } from '../../../utils/messages';

import { colors } from '../../../static/themes/gsmc/colors';
import { IUser } from '../../../model/auth/User.model';
import { createUser, getUsers, updateUser } from '../../../api/auth/User.api';
import { IResponseAPI } from '../../../model/api/Api.model';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import Paper from '@mui/material/Paper';
import { getPlateformes } from '../../../api/Plateforme.api';


interface IDialogProps {
    open: boolean;
    user?: IUser;
    handleClose: () => void;
    handleError: () => void;
}


const initialValues = {
    email: undefined as undefined | string,
    username: undefined as undefined | string,
    lastname: undefined as undefined | string,
    firstname: undefined as undefined | string,
    picture: undefined as undefined | string,
    crmId: undefined as undefined | string,
    plateformeEmail: [] as string[],
    isActive: true as boolean
}


const DialogUser = (props: IDialogProps) => {

    const [plateformesEmail, setPlateformesEmail] = useState<string[]>([]);

    useEffect(() => {
        getPlateformes(0, 1000).then(response => {
            let emails: any[] = response.content.map(_ => {
                return _.mailMiseEnGestion;
            })

            emails = Array.from(new Set(emails.filter(_ => _))) || [];

            setPlateformesEmail(emails)
        })
    }, [])

    useEffect(() => {
        formik.resetForm();

        if (props.user !== undefined) {
            const values = {
                email: props.user?.email,
                username: props.user?.username,
                lastname: props.user?.lastname,
                firstname: props.user?.firstname,
                picture: props.user?.picture,
                crmId: props.user?.crmId,
                plateformeEmail: props.user?.plateformeEmail ? props.user.plateformeEmail.split(",") : [] as string[],
                isActive: props.user.isActive
            }

            formik.setValues(values);
        } else {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.user])

    const validationSchema = yup.object({
        email: yup.string().email("Email non valide").required(messageFieldRequired),
        username: yup.string().required(messageFieldRequired)
            .matches(/^[\w\.\-]+$/, "Le nom de compte ne doit pas contenir d'espace, de caratére accentué ou de caratère spécial (excepté ., - et _)"),
        lastname: yup.string().nullable(),
        firstname: yup.string().nullable(),
        picture: yup.string().nullable(),
        crmId: yup.string().nullable(),
        plateformeEmail: yup.array().of(yup.string().email("Email non valide"))
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (props.user?.id) {
                const toSend: IUser = {
                    email: values.email as string,
                    username: values.username as string,
                    lastname: values.lastname as string,
                    firstname: values.firstname as string,
                    picture: values.picture as string,
                    crmId: values.crmId as string,
                    plateformeEmail: values.plateformeEmail?.join(","),
                    isActive: values.isActive
                }
                updateUser(props.user.id, toSend).then((res: any) => {
                    if (res.id) {
                        props.handleClose();
                    }

                    if (res.errors) {
                        res.errors.map((_: any) => {
                            formik.setFieldError(_.field, _.message);
                        })
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
            else {
                const toSend: IUser = {
                    email: values.email as string,
                    username: values.username as string,
                    lastname: values.lastname as string,
                    firstname: values.firstname as string,
                    picture: values.picture as string,
                    crmId: values.crmId as string,
                    plateformeEmail: values.plateformeEmail?.join(","),
                    isActive: values.isActive
                }
                createUser(toSend).then((res: any) => {
                    if (res.id) {
                        props.handleClose();
                    }

                    if (res.errors) {
                        res.errors.map((_: any) => {
                            formik.setFieldError(_.field, _.message);
                        })
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
        }
    })


    return (
        <CustomizedDialogs
            title="Utilisateur"
            open={props.open}
            handleClose={props.handleClose}>
            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="email"
                                name="email"
                                label={"Email *"}
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.email && Boolean(formik.errors.email)}
                                helperText={formik.touched.email && formik.errors.email} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="username"
                                name="username"
                                label={"Pseudo *"}
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.username && Boolean(formik.errors.username)}
                                helperText={formik.touched.username && formik.errors.username} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="lastname"
                                name="lastname"
                                label={"Nom"}
                                value={formik.values.lastname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                                helperText={formik.touched.lastname && formik.errors.lastname} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="firstname"
                                name="firstname"
                                label={"Prénom"}
                                value={formik.values.firstname}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                                helperText={formik.touched.firstname && formik.errors.firstname} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="crmId"
                                name="crmId"
                                label={"CRM Id"}
                                value={formik.values.crmId}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.crmId && Boolean(formik.errors.crmId)}
                                helperText={formik.touched.crmId && formik.errors.crmId} />
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    ml: 2
                                }} >
                                Id de l'objet acteur dans le CRM Efficy.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Autocomplete
                                fullWidth
                                multiple
                                freeSolo
                                id="plateformeEmail"
                                value={formik.values.plateformeEmail}
                                options={plateformesEmail}
                                onChange={(e, value) => formik.setFieldValue("plateformeEmail", value)}
                                onBlur={formik.handleBlur}
                                PaperComponent={({ children }) => (
                                    <Paper
                                        sx={{
                                            mx: 1,
                                            mt: 0.2,
                                            borderRadius: 4,
                                            background: colors.blue.secondary
                                        }}>
                                        {children}
                                    </Paper>
                                )}
                                renderTags={(value: readonly string[], getTagProps) =>
                                    value.map((option: string, index: number) => (
                                        <Chip variant="outlined" label={option} {...getTagProps({ index })} />
                                    ))
                                }
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Email de mise en gestion"
                                        placeholder="Ajouter"
                                        name="plateformeEmail"
                                        error={formik.touched.plateformeEmail && Boolean(formik.errors.plateformeEmail)}
                                        helperText={formik.touched.plateformeEmail && formik.errors.plateformeEmail} />)
                                }
                            />
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    ml: 2
                                }} >
                                ⚠️ L'utilisateur aura uniquement accès aux formulaires des plateformes utilisant ces emails de mise en gestion.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <span><strong>Actif</strong></span>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus type="submit">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </CustomizedDialogs>
    )
}


export const AccountList = () => {

    document.title = 'BO - Comptes';

    const [user, setUser] = useState<IUser | undefined>();
    const [users, setUsers] = useState<IUser[] | undefined | null>(undefined);
    const [count, setCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleClickModify = (user: IUser) => {
        setUser(user);
        setOpen(true);
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        getUsers(pageNumber, rowPerPage, search, undefined, undefined).then(response => {
            setUsers(response.content);
            setCount(response.totalElements);
        })
        .catch((exception) => {
            setUsers(null);
            setCount(0);
        });
    }, [pageNumber, rowPerPage])


    const forceRefresh = () => {
        getUsers(pageNumber, rowPerPage, search).then(response => {
            setUsers(response.content);
            setCount(response.totalElements);
        })
        .catch((exception) => {
            setUsers(null);
            setCount(0);
        });
        setOpen(false);
        setUser(undefined);
    }


    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <PersonIcon />},
                {type: "LINK", label: "Compte"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <DialogUser
                open={open}
                user={user}
                handleClose={() => forceRefresh()}
                handleError={() => setIsSnackbarOpened(true)} />

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>

                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                        <IconButton
                            onClick={() => setOpen(true)}>
                            <AddCircleIcon color="primary"/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                minWidth: "100px",
                                                position: "sticky",
                                                left: 0,
                                                background: colors.grey.secondary,
                                            }}>Email</TableCell>
                                        <TableCell>Pseudo</TableCell>
                                        <TableCell>Nom</TableCell>
                                        <TableCell>Prénom</TableCell>
                                        <TableCell>CRM Id</TableCell>
                                        <TableCell>Emails MEG</TableCell>
                                        <TableCell align="center">Actif</TableCell>
                                        <TableCell
                                            key={'settings'}
                                            sx={{
                                                minWidth: '80px',
                                                position: "sticky",
                                                right: 0,
                                                background: colors.grey.secondary,
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {users === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des utilisateurs en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : users === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des utilisateurs
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : users.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucun utilisateur
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : users.map((_, id) => {
                                        return (
                                            <TableRow key={id}>
                                                <TableCell
                                                    sx={{
                                                        position: "sticky",
                                                        left: 0,
                                                        background: colors.grey.secondary,
                                                    }}>{_.email}</TableCell>
                                                <TableCell>{_.username}</TableCell>
                                                <TableCell>{_.lastname}</TableCell>
                                                <TableCell>{_.firstname}</TableCell>
                                                <TableCell>{_.crmId || "-"}</TableCell>
                                                <TableCell>
                                                    {_.plateformeEmail ?
                                                    <Grid container
                                                        justifyContent="flex-start"
                                                        alignItems="center"
                                                        spacing={1} >
                                                        {_.plateformeEmail.split(",").map(_ => <Grid item xs="auto"><Chip variant="outlined" label={_} /></Grid>)}
                                                    </Grid>
                                                    : "-"}
                                                </TableCell>
                                                {/* TODO : send isActivated in response API */}
                                                <TableCell align="center">
                                                    {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        position: "sticky",
                                                        right: 0,
                                                        background: colors.grey.secondary,
                                                    }}>
                                                    <IconButton
                                                        onClick={() => handleClickModify(_)}>
                                                        <CreateIcon
                                                            sx={{
                                                                color: colors.blue.main
                                                            }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={count}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >Erreur lors de l'enregistrement de l'utilisateur.<br />⚠️  L'email et le pseudo doivent être uniques.</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

import { useTheme } from '@mui/material/styles';

import useMediaQuery from '@mui/material/useMediaQuery';

import { Grid, Link, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';

import { CloseIcon } from '../Icon/CloseIcon';
import { TransitionSlideUP } from '../../utils/transistions/transitions';
import { Accordion } from '../Accordion/Accordion';
import { CardInfo } from '../Card/CardInfo';

import CNISpecimen from '../../static/img/cni-specimen.png';

interface Props {
    plateformStyle: any;
    isDisplayed: boolean;
    onClose: () => void;
}


export const DialogInfoNIR = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle = props.plateformStyle;

    return (
        <Dialog
            open={props.isDisplayed}
            fullWidth
            maxWidth='lg'
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            onClose={() => props.onClose()}>
            <DialogTitle
                sx={{
                    pt: 1
                }} >
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                    onClick={() => props.onClose()}>
                    <CloseIcon color={plateformStyle.colors.black.main}/>
                </IconButton>
                <Typography variant="h2">Où trouver votre numéro de sécurité sociale ?</Typography>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container
                    spacing={2} >

                    <Grid
                        item
                        xs={12}>
                        <CardInfo
                            plateformStyle={props.plateformStyle}>
                            <Typography fontWeight={500}>Le numéro de sécurité sociale est composé de <b>15</b> caractères au format <b style={{whiteSpace: "nowrap"}}>X XX XX XX XXX XXX XX</b>.</Typography>
                        </CardInfo>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: "justify"
                        }} >
                        <Accordion
                            plateformStyle={props.plateformStyle}
                            label="Sur votre Carte Vitale"
                            isOpen>
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    width: "100%",
                                    mt: 0.5,
                                    px: 1
                                }}>
                                <Grid
                                    item
                                    md
                                    xs={12}>
                                    <Typography fontWeight={500}>Votre numéro de sécurité sociale figure en caractères visibles sur votre <b>Carte Vitale</b>.</Typography>
                                    <Typography fontWeight={500} sx={{mt: 0.5}}>Vous trouverez votre numéro de sécurité sociale comme indiqué sur l'illustration suivante.</Typography>
                                </Grid>
                                <Grid
                                    item
                                    md="auto"
                                    xs={12}
                                    sx={{
                                        textAlign: "center"
                                    }}>
                                    <img src={CNISpecimen} height="150px"/>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: "justify"
                        }}>
                        <Accordion
                            plateformStyle={props.plateformStyle}
                            label="Sur votre attestation de droits">
                            <Grid
                                container
                                spacing={1}
                                sx={{
                                    mt: 0.5,
                                    px: 1
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>Votre numéro de sécurité sociale est inscrit sur votre <b>attestation de droits</b> que vous pouvez obtenir dans votre compte ameli.</Typography>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}><Link href="https://www.ameli.fr/assure/adresses-et-contacts/l-obtention-d-un-document/obtenir-une-attestation/obtenir-une-attestation-de-droits" rel="noopener noreferrer" target="_blank">Obtenir une attestation de droits</Link></Typography>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: "justify"
                        }}>
                        <Accordion
                            plateformStyle={props.plateformStyle}
                            label="Sur vos bulletins de salaire">
                            <Grid
                                container
                                spacing={2}
                                sx={{
                                    mt: 0.5,
                                    px: 1
                                }}>
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography fontWeight={500}>Si vous avez déjà travaillé en France, votre numéro de sécurité sociale est inscrit sur vos bulletins de salaire.</Typography>
                                </Grid>
                            </Grid>
                        </Accordion>
                    </Grid>
                </Grid>
            </DialogContent>
        </Dialog>
    )
}

import React, {forwardRef, Ref, useImperativeHandle} from 'react';

import CloseIcon from "@mui/icons-material/Close";
import Alert from '@mui/material/Alert';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Snackbar from '@mui/material/Snackbar';
import Typography from '@mui/material/Typography';
import TextField from "@mui/material/TextField";


import {FieldLabel} from '../Field/FieldLabel';
import {CheckFullIcon} from '../Icon/CheckFullIcon';

import {sendContractSignatureLink} from '../../api/ContractSignature.api';

import {messageFieldRequired} from '../../utils';

import {colors} from '../../static/themes/colors';
import {useFormik} from "formik";
import * as yup from "yup";


interface Props {
    formId?: string,
    subscriberEmail?: string;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormSendEmailSignature = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();
        }
    }));

    const validationSchema = yup.object({
        email: yup.string().email("Email non valide").required(messageFieldRequired),
    });

    const formik = useFormik({
        initialValues: {
            email: props.subscriberEmail
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            sendEmail();
        }
    });

    const [isSnackbarOpened, setIsSnackbarOpened] = React.useState<boolean>(false);

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    const sendEmail = () => {
        if (!formik.values.email) {
            return;
        }

        if (!props.formId) {
            return;
        }

        sendContractSignatureLink(props.formId, formik.values.email)
            .then(() => {
                setIsSnackbarOpened(true);
            });
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={3}
                sx={{textAlign: 'left'}}>
                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.email && Boolean(formik.errors.email)}>
                        <FieldLabel label="Email de votre client" isRequired/>
                    </FormLabel>
                    <TextField
                        fullWidth
                        id="email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.email && Boolean(formik.errors.email)}
                        helperText={formik.touched.email && formik.errors.email} />
                </Grid>

                <Snackbar
                    sx={{
                        maxWidth: '1200px',
                        width: {
                            sm: '100%'
                        },
                        px: {
                            sm: 2
                        },
                        py: 'auto'
                    }}
                    anchorOrigin={{vertical: 'top', horizontal: 'center'}}
                    open={isSnackbarOpened}
                    autoHideDuration={2000}
                    onClose={handleSnackbarClose}>
                    <Alert
                        sx={{
                            mx: {
                                sm: 2
                            },
                            my: 'auto',
                            boxShadow: 3
                        }}
                        severity='success'
                        iconMapping={{
                            success: <CheckFullIcon color='white' bgColor={colors.green.main}/>,
                        }}
                        action={
                            <IconButton
                                aria-label="close"
                                color="inherit"
                                size="small"
                                onClick={handleSnackbarClose}>
                                <CloseIcon fontSize="inherit" fontWeight={700}/>
                            </IconButton>
                        }>

                        <Typography fontWeight={500}>L'e-mail a été envoyé à votre client.</Typography>
                    </Alert>
                </Snackbar>
            </Grid>
        </form>
    )
})

import { useEffect, useState } from 'react';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';

import { DialogContinue } from '../components/Dialog/DialogContinue';
import { FormContext } from '../components/Form/FormContext';
import { FormHeader } from '../components/Form/FormHeader';
import { MainHeader } from '../components/Header/MainHeader';

import { theme as themeGSMC } from '../static/themes/gsmc/theme';
import { style as styleGSMC } from '../static/themes/gsmc/style';
import { useNavigate } from 'react-router-dom';


const production = process.env.REACT_APP_PRODUCTION === '1'

export const Home = () => {

    document.title = 'Mutuelle GSMC';

    const navigate = useNavigate();

    const [formUuid, setFormUuid] = useState<string | undefined>();
    const [progress, setProgress] = useState(0);

    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [sendContextFlag, setSendContextFlag] = useState<number>(0);


    const redirect = (uuid: string) => {
        navigate(`/lead/${uuid}`);
    }


    useEffect(() => {
        let uuid: string | undefined = localStorage.getItem('form_uuid') || undefined;

        if (uuid) {
            setIsDialogOpened(true);
            setFormUuid(uuid);
        }
    }, [])

    useEffect(() => {
        if(production) {
            window.location.replace("https://www.mutuelle-gsmc.fr");
        }
    },[])


    return (
        <ThemeProvider theme={themeGSMC}>
            {!production &&
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }} >

                <DialogContinue
                    isOpened={isDialogOpened}
                    title="Un formulaire est en cours de saisie"
                    description="Souhaitez-vous continuer le formulaire en cours ?"
                    handleClose={() => {
                        localStorage.setItem('form_uuid', "");
                        setIsDialogOpened(false)
                    }}
                    handleContinue={() => {
                        redirect(formUuid as string)
                    }} />

                <MainHeader
                    plateformStyle={styleGSMC}
                    isReturnEnabled={false}
                    goToPrevious={() => {}} />

                <Box
                    sx={{
                        maxWidth: '1200px',
                        width: '100%',
                        mx: 'auto',
                        marginTop: '20px'
                    }} >

                    <Grid
                        container
                        justify-content='center'
                        spacing={2}
                        sx={{
                            px: 2
                        }} >
                        <Grid
                            md={12}
                            item >
                            <FormHeader
                                label="Bienvenue"
                                description="Veuillez renseigner vos informations" />
                        </Grid>

                        <Grid
                            xs={12}
                            item >
                            <Card>
                                <LinearProgress
                                    variant="determinate"
                                    value={progress}
                                    color='success' />
                                <Box>

                                    <FormContext
                                        sendContextFlag={sendContextFlag}
                                        send={redirect}
                                        sendProgress={setProgress} />

                                </Box>
                            </Card>
                        </Grid>

                        <Grid
                            md={12}
                            item
                            sx={{
                                width: '100%',
                                textAlign: 'center'
                            }} >
                            <Button
                                variant='contained'
                                color='success'
                                sx={{
                                    width: {
                                        sm: 400,
                                        xs: '100%'
                                    },
                                    my: 5,
                                    px: 5,
                                    color: 'white'
                                }}
                                onClick={() => setSendContextFlag(sendContextFlag + 1)} >
                                Continuer
                            </Button>
                        </Grid>
                    </Grid>

                </Box>
            </Box>}

        </ThemeProvider>
    )
}

import { useEffect, useState } from 'react';

import { FormikErrors, useFormik } from 'formik';
import * as yup from 'yup';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ContactSupportIcon from '@mui/icons-material/ContactSupport';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldPhone } from '../Field/TextFieldPhone';
import { FormRadioButton } from './FormRadioButton';

import { updatePlateformeSettingsContact } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';

import { enumContactMethodForm, enumContactMethodRedirect, enumContactMethods, messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settingsContact: IPlateformeSettingsContact;
    send: (settingsContact: IPlateformeSettingsContact) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsSupport = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        displaySupportCard: yup.boolean().required(messageFieldRequired),
        displaySupportAction: yup.boolean().required(messageFieldRequired),
        supportMethod: yup.string().nullable(),
        supportUri: yup.string().nullable(),
        supportMail: yup.string().email("Email non valide").nullable(),
        supportPhone: yup.string().nullable().trim().matches(/((\+?)33|32)(\s*\d){9}\s*$/g, 'Format de téléphone non valide'),
        supportHours: yup.string().nullable(),
    })

    const formik = useFormik({
        initialValues: {
            displaySupportCard: props.settingsContact.displaySupportCard,
            displaySupportAction: props.settingsContact.displaySupportAction,
            supportMethod: props.settingsContact.supportMethod,
            supportUri: props.settingsContact.supportUri,
            supportMail: props.settingsContact.supportMail,
            supportPhone: props.settingsContact.supportPhone,
            supportHours: props.settingsContact.supportHours,
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};

            // Validation Support.
            if (values.displaySupportAction &&
                (!values.supportMethod || values.supportMethod === null))
                errors.supportMethod = "La méthode de contact du support doit être renseignée si le bouton demande de rappel est affiché";

            if (values.displaySupportAction &&
                values.supportMethod === enumContactMethodRedirect &&
                (!values.supportUri || values.supportUri === null))
                errors.supportUri = "L'URL de redirection doit être renseigné avec la méthode de contact redirection";

            if (values.displaySupportAction &&
                values.supportMethod === enumContactMethodForm &&
                (!values.supportMail || values.supportMail === null))
                errors.supportMail = "L'email de support doit être renseigné avec la méthode de contact formulaire";

            return errors;
        },
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsContact = {
                id: props.settingsContact.id,
                displayContactCard: props.settingsContact.displayContactCard,
                displayContactAction: props.settingsContact.displayContactAction,
                displayContactMail: props.settingsContact.displayContactMail,
                contactMethod: props.settingsContact.contactMethod,
                contactUri: props.settingsContact.contactUri,
                contactMail: props.settingsContact.contactMail,
                displaySupportCard: values.displaySupportCard,
                displaySupportAction: values.displaySupportAction,
                supportMethod: values.supportMethod,
                supportUri: values.supportUri,
                supportMail: values.supportMail,
                supportPhone: values.supportPhone,
                supportHours: values.supportHours,
            }
            updatePlateformeSettingsContact(toSend)
            .then((response: IPlateformeSettingsContact) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres du support.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <ContactSupportIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Support
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Renseigner les informations du support de la plateforme
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Carte du Support
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                La carte de support se trouve en pied de page du formulaire.<br />
                                Elle permet au souscripteur de contacter le support (via le numéro de téléphone) ou de demander à être recontacté.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.displaySupportCard}
                                        onChange={(e, value) => formik.setFieldValue("displaySupportCard", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Afficher la carte de support
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.displaySupportCard && Boolean(formik.errors.displaySupportCard)} >
                                {formik.touched.displaySupportCard && formik.errors.displaySupportCard}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Le bouton de contact du support permet au souscripteur de demander à être rappelé via une redirection ou l'affichage d'un formulaire.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.displaySupportAction}
                                        onChange={(e, value) => formik.setFieldValue("displaySupportAction", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Afficher le bouton de demande de rappel
                                    </Typography>
                                } />
                            <FormHelperText
                                error={formik.touched.displaySupportAction && Boolean(formik.errors.displaySupportAction)} >
                                {formik.touched.displaySupportAction && formik.errors.displaySupportAction}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.supportPhone && Boolean(formik.errors.supportPhone)} >
                                <FieldLabel
                                    label="Téléphone du support"
                                    fontWeight={500}/>
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Si le téléphone du support est renseigné, il sera affiché dans la carte support.
                            </Typography>
                            <Box
                                sx={{
                                    maxWidth: '500px'
                                }} >
                                <TextFieldPhone
                                    id="supportPhone"
                                    fullWidth
                                    value={formik.values.supportPhone}
                                    onChange={(value) => formik.setFieldValue("supportPhone", value)}
                                    error={formik.touched.supportPhone && Boolean(formik.errors.supportPhone)}
                                    helperText={formik.touched.supportPhone && formik.errors.supportPhone} />
                            </Box>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.supportHours && Boolean(formik.errors.supportHours)} >
                                <FieldLabel
                                    label="Horaires du support"
                                    fontWeight={500} />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                Exemple : Du lundi au vendredi de 08h30 à 17h30.
                            </Typography>
                            <TextField
                                fullWidth
                                multiline
                                id="supportHours"
                                name="supportHours"
                                value={formik.values.supportHours}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.supportHours && Boolean(formik.errors.supportHours)}
                                helperText={formik.touched.supportHours && formik.errors.supportHours}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.supportMethod && Boolean(formik.errors.supportMethod)} >
                                <FieldLabel
                                    label="Méthode de contact"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displaySupportAction)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                La méthode de contact du support définie l'action effectué par le bouton de demande de rappel (redirection ou ouverture d'un formulaire).
                            </Typography>
                            <Grid
                                container
                                spacing={2} >
                                {enumContactMethods.map((_, id) => {
                                    return <Grid
                                        item
                                        lg={2}
                                        md={3}
                                        sm={4}
                                        xs={12} >
                                        <FormRadioButton
                                            code={_.value}
                                            selected={formik.values.supportMethod === _.value}
                                            color={colors.blue.main}
                                            onClick={(value) => {
                                                formik.setFieldValue("supportMethod", value);
                                            }} >
                                            {_.label}
                                        </FormRadioButton>
                                    </Grid>
                                })}
                            </Grid>
                            <FormHelperText
                                error={formik.touched.supportMethod && Boolean(formik.errors.supportMethod)} >
                                {formik.touched.supportMethod && formik.errors.supportMethod}
                            </FormHelperText>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.supportMail && Boolean(formik.errors.supportMail)} >
                                <FieldLabel
                                    label="Email du support"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displaySupportAction &&
                                                formik.values.supportMethod === enumContactMethodForm)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                L'email du support recevra les messages des souscripteurs avec la méthode formulaire.
                            </Typography>
                            <TextField
                                fullWidth
                                id="supportMail"
                                name="supportMail"
                                value={formik.values.supportMail}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.supportMail && Boolean(formik.errors.supportMail)}
                                helperText={formik.touched.supportMail && formik.errors.supportMail}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.supportUri && Boolean(formik.errors.supportUri)} >
                                <FieldLabel
                                    label="URL de redirection"
                                    fontWeight={500}
                                    isRequired={
                                        Boolean(formik.values.displaySupportAction &&
                                                formik.values.supportMethod === enumContactMethodRedirect)
                                    } />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                URL de redirection utilisé avec la méthode redirection.
                            </Typography>
                            <TextField
                                fullWidth
                                id="supportUri"
                                name="supportUri"
                                value={formik.values.supportUri}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.supportUri && Boolean(formik.errors.supportUri)}
                                helperText={formik.touched.supportUri && formik.errors.supportUri}
                                sx={{
                                    maxWidth: '500px'
                                }} />
                        </Grid>

                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

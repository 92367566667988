import { defaultIconHeaderSize, IIcon } from "./settings"


export const NeedHospitalIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <mask id="path-1-inside-1_7_67" fill="white">
                <path fillRule="evenodd" clipRule="evenodd" d="M25.9794 3C25.9794 1.34315 24.6362 0 22.9794 0H17.0206C15.3638 0 14.0206 1.34314 14.0206 3V11.0206C14.0206 12.6775 12.6775 14.0206 11.0206 14.0206L3 14.0206C1.34315 14.0206 0 15.3638 0 17.0206V22.9794C0 24.6362 1.34315 25.9794 3 25.9794H11.0206C12.6775 25.9794 14.0206 27.3225 14.0206 28.9794V37C14.0206 38.6569 15.3638 40 17.0206 40H22.9794C24.6362 40 25.9794 38.6569 25.9794 37V28.9794C25.9794 27.3225 27.3225 25.9794 28.9794 25.9794H37C38.6569 25.9794 40 24.6362 40 22.9794V17.0206C40 15.3638 38.6569 14.0206 37 14.0206L28.9794 14.0206C27.3225 14.0206 25.9794 12.6775 25.9794 11.0206V3Z"/>
            </mask>
            <path d="M28.9794 14.0206V12.0206V14.0206ZM3 14.0206V12.0206V14.0206ZM17.0206 2H22.9794V-2H17.0206V2ZM16.0206 11.0206V3H12.0206V11.0206H16.0206ZM3 16.0206H11.0206V12.0206L3 12.0206L3 16.0206ZM2 22.9794V17.0206H-2V22.9794H2ZM11.0206 23.9794H3V27.9794H11.0206V23.9794ZM16.0206 37V28.9794H12.0206V37H16.0206ZM22.9794 38H17.0206V42H22.9794V38ZM23.9794 28.9794V37H27.9794V28.9794H23.9794ZM37 23.9794H28.9794V27.9794H37V23.9794ZM38 17.0206V22.9794H42V17.0206H38ZM28.9794 16.0206H37V12.0206L28.9794 12.0206V16.0206ZM23.9794 3V11.0206H27.9794V3H23.9794ZM28.9794 12.0206C28.4271 12.0206 27.9794 11.5729 27.9794 11.0206H23.9794C23.9794 13.782 26.218 16.0206 28.9794 16.0206V12.0206ZM42 17.0206C42 14.2592 39.7614 12.0206 37 12.0206V16.0206C37.5523 16.0206 38 16.4683 38 17.0206H42ZM37 27.9794C39.7614 27.9794 42 25.7408 42 22.9794H38C38 23.5317 37.5523 23.9794 37 23.9794V27.9794ZM27.9794 28.9794C27.9794 28.4271 28.4271 27.9794 28.9794 27.9794V23.9794C26.2179 23.9794 23.9794 26.218 23.9794 28.9794H27.9794ZM22.9794 42C25.7408 42 27.9794 39.7614 27.9794 37H23.9794C23.9794 37.5523 23.5317 38 22.9794 38V42ZM12.0206 37C12.0206 39.7614 14.2592 42 17.0206 42V38C16.4683 38 16.0206 37.5523 16.0206 37H12.0206ZM11.0206 27.9794C11.5729 27.9794 12.0206 28.4271 12.0206 28.9794H16.0206C16.0206 26.218 13.782 23.9794 11.0206 23.9794V27.9794ZM-2 22.9794C-2 25.7408 0.238581 27.9794 3 27.9794V23.9794C2.44772 23.9794 2 23.5317 2 22.9794H-2ZM3 12.0206C0.238577 12.0206 -2 14.2592 -2 17.0206H2C2 16.4683 2.44771 16.0206 3 16.0206L3 12.0206ZM12.0206 11.0206C12.0206 11.5729 11.5729 12.0206 11.0206 12.0206V16.0206C13.782 16.0206 16.0206 13.782 16.0206 11.0206H12.0206ZM22.9794 2C23.5317 2 23.9794 2.44771 23.9794 3H27.9794C27.9794 0.238578 25.7408 -2 22.9794 -2V2ZM17.0206 -2C14.2592 -2 12.0206 0.23857 12.0206 3H16.0206C16.0206 2.44771 16.4683 2 17.0206 2V-2Z" fill={props.color} mask="url(#path-1-inside-1_7_67)"/>
        </svg>
    )
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const HrConjointIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={props.bgColor}/>
        <mask id="mask0_295_3868" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <path d="M20 2.94047e-06C31.0457 2.45765e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 3.42329e-06 20 2.94047e-06Z" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_295_3868)">
        <path d="M12.52 5.2937L-3.53818 6.39157C-4.52229 6.45885 -5.40992 7.00707 -5.91073 7.85688L-11.0448 16.5688C-11.7884 17.8306 -11.5087 19.4453 -10.3842 20.3836L33.0161 56.5937C34.656 57.9619 37.1601 57.2198 37.7898 55.179L48.4678 20.5688C49.2092 18.1657 46.834 15.9762 44.4991 16.9105L22.519 25.7053C20.7588 26.4096 18.7959 25.3193 18.4636 23.4529L15.6683 7.75316C15.4007 6.24981 14.0434 5.18955 12.52 5.2937Z" fill="url(#paint0_linear_295_3868)"/>
        </g>
        <g filter="url(#filter0_d_295_3868)">
        <path d="M17 33C17 26.9249 21.9249 22 28 22C34.0751 22 39 26.9249 39 33V40H17V33Z" fill={props.color}/>
        <circle cx="27.8512" cy="14.8512" r="6.85123" fill={props.color}/>
        </g>
        <g filter="url(#filter1_d_295_3868)">
        <path d="M1 35C1 30.0294 5.02944 26 10 26C14.9706 26 19 30.0294 19 35V40H1V35Z" fill={props.color}/>
        <circle cx="10" cy="19" r="6" fill={props.color}/>
        </g>
        <defs>
        <filter id="filter0_d_295_3868" x="-3" y="-8" width="62" height="72" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3868"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3868" result="shape"/>
        </filter>
        <filter id="filter1_d_295_3868" x="-19" y="-3" width="58" height="67" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3868"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3868" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_295_3868" x1="37.0536" y1="9.69613" x2="11.8842" y2="39.3278" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

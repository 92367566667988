export const sortByOrder = function(order1: number, order2: number) {
    if (order1 < order2) {
        return -1;
    }
    if (order1 > order2) {
        return 1;
    }
    return 0;

}

import { useEffect, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from '@mui/material/Grid';
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import LoadingButton from '@mui/lab/LoadingButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

import { style } from '../../static/themes/gsmc/style';
import { AccordionBO } from '../Accordion/AccordionBO';
import { TransitionSlideUP } from '../../utils/transistions/transitions';
import { postSubscription } from '../../api/Subscription.api';
import { IContractHenner } from '../../model/ControleDocument.model';
import { colors } from '../../static/themes/gsmc/colors';

// @ts-ignore
import Highlight from 'react-highlight'


interface Props {
    isOpened: boolean
    formUuid: string
    contractHenner?: IContractHenner
    onClose: () => void
    onError: (msg: string) => void
    onSuccess: (msg: string) => void
}


const OperationLog = (props: {
    status: "success" | "error" | "notlaunch"  
    label: string,
    log?: string
}) => {
    const [openCopyTooltip, setOpenCopyTooltip] = useState(false);

    const onCloseCopyTooltip = () => {
        setOpenCopyTooltip(false);
    };
  
    const onOpenCopyTooltip = () => {
        setOpenCopyTooltip(true);
    };

    const status = [{
        name: "success",
        icon: "✅",
        description : "Opération réussie",
    },
    {
        name: "error",
        icon: "❌",
        description : "Opération échouée",
    },
    {
        name: "notlaunch",
        icon: "◽",
        description : "Opération non réalisée",
    }]

    const statusOperation = status.find(_ => _.name === props.status)

    return (
        <AccordionBO
            plateformStyle={style}
            isOpen={props.status === "error"}
            label={props.label}
            icon={
                statusOperation &&
                <Tooltip
                    title={statusOperation.description}
                    placement="top-start"
                    arrow>
                <Typography>{statusOperation.icon}</Typography>
            </Tooltip>}>
            <Card
                sx={{
                    position: "relative",
                    borderRadius: 2,
                    backgroundColor: colors.blue.secondary,
                    p: 1
                }}>
                {props.log ?
                <>
                <Stack
                    direction= "column"
                    spacing={0}
                    sx={{
                        position: "absolute",
                        zIndex: 10,
                        right: 4,
                        top: 4
                    }}>
                    <Tooltip
                        title="Copié !"
                        placement="left"
                        open={openCopyTooltip}
                        onClose={onCloseCopyTooltip}
                        leaveDelay={1000}>
                        <IconButton
                            sx={{
                                borderRadius: 3,
                                background: colors.grey.secondary
                            }}
                            onClick={() => {
                                if (props.log) {
                                    navigator.clipboard.writeText(props.log)
                                    onOpenCopyTooltip()
                                }
                            }}>
                            <ContentCopyIcon fontSize="small" />
                        </IconButton>
                    </Tooltip>
                </Stack>

                <Box
                    sx={{
                        overflow: "auto",
                        whiteSpace: "pre",
                        fontFamily: "monospace"
                    }}>
                    <Highlight language='json'>
                        {JSON.stringify(JSON.parse(props.log), null, 2)}
                    </Highlight>
                </Box>
                </>

                :
                <Typography
                    fontWeight={500}
                    sx={{
                        fontFamily: "monospace"
                    }}>
                    Aucune réponse
                </Typography>}
                
            </Card>
        </AccordionBO>
    )
}


export const DialogSubscription = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const [contractHenner, setContractHenner] = useState<IContractHenner | undefined>(props.contractHenner)

    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        setContractHenner(props.contractHenner);
    }, [props.contractHenner])

    return (
        <Dialog
            fullWidth
            maxWidth="md"
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.onClose();
            }}
            PaperProps={{
                sx: {
                    borderRadius: {
                        sm: 4,
                        xs: 4
                    }
                }
            }} >
            <DialogTitle>
                <Typography variant="h2">Mise en gestion</Typography>
            </DialogTitle>

            <DialogContent>
                <Grid
                    container
                    gap={2}
                    sx={{
                        py: 1
                    }}>

                    <Grid
                        item
                        xs={12}>
                        <Typography>
                            N° de devis :&nbsp;
                            <Typography
                                variant='body2'
                                component='span'
                                color={theme.palette.info.main}
                                fontWeight={700}
                                sx={{
                                    borderRadius: 4,
                                    px: 1,
                                    backgroundColor: colors.blue.secondary
                                }}>
                                {contractHenner?.codeDevis || "-"}
                            </Typography>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Typography>
                            N° de contrat :&nbsp;
                            <Typography
                                variant='body2'
                                component='span'
                                color={theme.palette.info.main}
                                fontWeight={700}
                                sx={{
                                    borderRadius: 4,
                                    px: 1,
                                    backgroundColor: colors.blue.secondary
                                }}>
                                {contractHenner?.codeContrat || "-"}
                            </Typography>
                        </Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <Divider/>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <OperationLog
                            status={!contractHenner?.isCallOver ? "notlaunch" : contractHenner?.devisCreated ? "success" : "error"}
                            label="Créer devis"
                            log={contractHenner?.responseDevis}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <OperationLog
                            status={!contractHenner?.isCallOver || !contractHenner.devisCreated ? "notlaunch" : contractHenner?.souscrireCreated ? "success" : "error"}
                            label="Souscrire devis"
                            log={contractHenner?.responseSouscrire}
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        <OperationLog
                            status={!contractHenner?.isCallOver || !contractHenner.souscrireCreated ? "notlaunch" : contractHenner?.contractCreated ? "success" : "error"}
                            label="Créer contrat"
                            log={contractHenner?.responseContract}
                        />
                    </Grid>
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Grid
                    container
                    justifyContent="flex-end"
                    gap={2}
                    sx={{
                        py: 1
                    }}>
                    <Grid
                        item
                        sm="auto"
                        xs={12}>
                        <Button
                            onClick={() => props.onClose()}
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                color: theme.palette.text.primary,
                            }} >
                            Fermer
                        </Button>
                    </Grid>

                    {!contractHenner?.codeContrat &&
                    <Grid
                        item
                        sm="auto"
                        xs={12}>
                        <LoadingButton
                            type='submit'
                            loading={loading}
                            loadingIndicator={
                                <Typography
                                    variant="button"
                                    noWrap
                                    sx={{
                                        textTransform: 'none',
                                        textAlign: 'center',
                                        color: 'white'
                                    }}>
                                    Mise en gestion...
                                </Typography>
                            }
                            sx={{
                                width: {
                                    sm: 'auto',
                                    xs: '100%'
                                },
                                px: 2,
                                color: 'white',
                                backgroundColor: theme.palette.text.primary,
                                ':hover': {
                                    backgroundColor: 'black'
                                }
                            }}
                            onClick={() => {
                                setLoading(true)

                                postSubscription(props.formUuid)
                                .then((response) => {
                                    setContractHenner(response.hennerContract)
                                    if (!response.hennerContract?.codeContrat) {
                                        props.onError("La mise en gestion a échoué")
                                    }
                                    else {
                                        props.onSuccess("La mise en gestion a été réalisée")
                                    }
                                    setLoading(false)
                                })
                                .catch((e) => {
                                    props.onError("La mise en gestion a échoué")
                                    setLoading(false)
                                })
                            }}>
                            Mettre en gestion
                        </LoadingButton>
                    </Grid>}
                </Grid>
            </DialogActions>
        </Dialog>
    )
}

import React, { useEffect, useState } from 'react';

import { Collapse, Divider, FormHelperText, IconButton, Link, List, ListItem, Slide, useTheme } from '@mui/material';
import Alert from '@mui/material/Alert';
import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Snackbar from '@mui/material/Snackbar';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabPanel from '@mui/lab/TabPanel';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';

import { default as MUICloseIcon} from '@mui/icons-material/Close';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { DocumentsDownload } from '../../components/Document/DocumentsDownload';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { AlertIcon } from '../../components/Icon/AlertIcon';
import { CheckFullIcon } from '../../components/Icon/CheckFullIcon';
import { DownloadIcon } from '../../components/Icon/DownloadIcon';
import { InfoIcon } from '../../components/Icon/InfoIcon';

import { IPerson } from '../../model/Person.model';

import { enumFormDocumentTypeAttestationCPAM,
         enumFormDocumentTypeCniRecto,
         enumFormDocumentTypeCniVerso,
         enumFormDocumentTypeKbis,
         enumFormDocumentTypeRIB, 
         enumFormDocumentTypes} from '../../utils';

import { getControlByFormUuid } from '../../api/Controle.api';
import { getFormDocuments } from '../../api/FormDocument.api';

import { ControlStatus, controlToDocument, documentOrderByStatus, IControle, IControleDocument } from '../../model/ControleDocument.model';
import { documentOrderByType, IFormDocument } from '../../model/FormDocument.model';
import { CloseFullIcon } from '../../components/Icon/CloseFullIcon';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { FieldFileUpload } from '../../components/Field/FieldFileUpload';
import { IForm } from '../../model/Form.model';
import { IPlateformeSettings } from '../../model/PlateformeSettings.model';
import { CardInfo } from '../../components/Card/CardInfo';
import { TransitionGroup } from 'react-transition-group';
import { ButtonAddFile } from '../../components/Button/ButtonAddFile';


interface Props {
    plateformStyle: any;
    plateformSettings: IPlateformeSettings;
    sidebar?: JSX.Element;
    form: IForm;
    codeOffer?: string;
    subscriber?: IPerson;
    isSinglePage?: boolean;
    snackbarDisplayed?: boolean;
    sendSnackbarDisplayed?: (snackbarDisplayed: boolean) => void;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormDocumentsDisplay = (props: Props) => {
    const theme = useTheme();

    const plateformStyle: any = props.plateformStyle;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;
    const iconInfoStyle: any = plateformStyle.components.IconInfo;

    const settingsDocument = props.plateformSettings.settingsDocument
    const settingsContact = props.plateformSettings.settingsContact

    const [control, setControl] = useState<IControle>();
    const [documentsStatic, setDocumentsStatic] = useState<IFormDocument[]>();
    const [documentsSubscriber, setDocumentsSubscriber] = useState<IFormDocument[]>();
    const [documentCniRecto, setDocumentCniRecto] = useState<IFormDocument>({ form: props.form, type: enumFormDocumentTypeCniRecto });
    const [documentCniVerso, setDocumentCniVerso] = useState<IFormDocument>({ form: props.form, type: enumFormDocumentTypeCniVerso });
    const [documentKbis, setDocumentKbis] = useState<IFormDocument>({ form: props.form, type: enumFormDocumentTypeKbis });
    const [documentRib, setDocumentRib] = useState<IFormDocument>({ form: props.form, type: enumFormDocumentTypeRIB });
    const [documentsAttestationsCPAM, setDocumentsAttestationsCPAM] = useState<IFormDocument[]>([{ form: props.form, type: enumFormDocumentTypeAttestationCPAM }]);
    const [delayNbDays, setDelayNbDays] = useState<number>();

    const [downloadAllFlag, setDownloadAllFlag] = useState<number>(0);
    const [downloadAllFlagSubscriber, setDownloadAllFlagSubscriber] = useState<number>(0);
    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(props.snackbarDisplayed === undefined ? false : !props.snackbarDisplayed);

    const [tabValue, setTabValue] = useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setTabValue(newValue);
    };

    const goToSite = () => {
        const link = document.createElement('a');
        link.href = 'https://www.mutuelle-gsmc.fr/home';
        link.target= '_blank'
        link.click();
        document.body.appendChild(link);
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);

        if (props.sendSnackbarDisplayed)
            props.sendSnackbarDisplayed(true);
    };
    

    const refreshControl = () => {
        if (!props.form?.uuid) return

        getControlByFormUuid(props.form.uuid)
        .then((response: IControle) => {
            let control: IControle = response;
            let doc: IControleDocument | undefined;
            let docs: IControleDocument[] = response.documents;

            control.documents = docs.sort(documentOrderByStatus);

            doc = control.documents.find(e => e.type === enumFormDocumentTypeCniRecto);
            if (doc) setDocumentCniRecto(controlToDocument(doc));

            doc = control.documents.find(e => e.type === enumFormDocumentTypeCniVerso);
            if (doc) setDocumentCniVerso(controlToDocument(doc));
            else setDocumentCniVerso({ form: props.form, type: enumFormDocumentTypeCniVerso });

            doc = control.documents.find(e => e.type === enumFormDocumentTypeKbis);
            if (doc) setDocumentKbis(controlToDocument(doc));

            doc = control.documents.find(e => e.type === enumFormDocumentTypeRIB);
            if (doc) setDocumentRib(controlToDocument(doc));

            let docsCPAM: IControleDocument[] = control.documents.filter(e => e.type === enumFormDocumentTypeAttestationCPAM);
            if (docsCPAM.length > 0) setDocumentsAttestationsCPAM(docsCPAM.map(_ => controlToDocument(_)));

            setControl(control);
            if (control && control.dateLimit) {
                let delta = new Date(control.dateLimit).getTime() - new Date().getTime()
                setDelayNbDays(Math.floor(delta / (1000 * 3600 * 24)))
            }

            if (response.status === ControlStatus.INCOMPLETE ||
                response.status === ControlStatus.PENDING ||
                response.status === ControlStatus.REFUSED ||
                response.status === ControlStatus.EXPIRED ||
                response.status === ControlStatus.CLOSED)
                setTabValue(1);
        })
        .catch((ex) => {
            setTabValue(1);
        });
    };

    useEffect(() => {
        if (!props.form?.uuid) return

        getFormDocuments(props.form.uuid).then(response => {
            let docs: IFormDocument[] = response;

            setDocumentsStatic(docs.filter(_ => ![enumFormDocumentTypeCniRecto,
                enumFormDocumentTypeCniVerso,
                enumFormDocumentTypeRIB,
                enumFormDocumentTypeAttestationCPAM,
                enumFormDocumentTypeKbis]?.includes(_.type)).sort(documentOrderByType));

            setDocumentsSubscriber(docs.filter(_ => [enumFormDocumentTypeCniRecto,
                enumFormDocumentTypeCniVerso,
                enumFormDocumentTypeRIB,
                enumFormDocumentTypeAttestationCPAM,
                enumFormDocumentTypeKbis]?.includes(_.type)).sort(documentOrderByType));
        })

        // Get control.
        refreshControl();
    }, []);

    useEffect(() => {
        setDownloadAllFlagSubscriber(0)
    }, [downloadAllFlagSubscriber]);

    useEffect(() => {
        setDownloadAllFlag(0)
    }, [downloadAllFlag]);

    return (
        <PageContentLayout
            header={
                <FormHeader
                    label={!props.isSinglePage ? "Merci pour votre confiance !" : "Votre espace document"} />}
            sidebar={props.sidebar}
            content={
                <Grid
                    container
                    spacing={2}>
                    <Grid
                        item
                        xs={12}>
                        <Card
                            sx={{
                                position: "relative",
                                p: 2
                            }}>
                            <Grid
                                container
                                spacing={2}>
                                <Grid
                                    item
                                    xs={12}>
                                    <CardInfo
                                        plateformStyle={plateformStyle}
                                        type={control === undefined || control.status === ControlStatus.INCOMPLETE ? "warning"
                                            : control.status === ControlStatus.ACCEPTED || control.status === ControlStatus.MEG ? "success"
                                            : control.status === ControlStatus.REFUSED || control.status === ControlStatus.EXPIRED || control.status === ControlStatus.CLOSED ? "error"
                                            : "info"}>
                                        {control === undefined || control.status === ControlStatus.INCOMPLETE ?
                                        <>
                                        <Grid
                                            container
                                            rowSpacing={1}
                                            columnSpacing={2}>
        
                                            <Grid
                                                item
                                                md
                                                xs={12}>
                                                <Typography fontWeight={500}>
                                                    Pour finaliser votre demande d’adhésion veuillez déposer vos justificatifs dans l'onglet “Vos justificatifs” ci-dessous avant la date limite.
                                                </Typography>
                                                <Typography fontWeight={500} sx={{mt: 1.5}}>
                                                    Attention, tant que vos justificatifs n'ont pas été fournis et validés par nos équipes, votre contrat n'est pas actif !
                                                </Typography>
                                            </Grid>
        
                                            <Grid
                                                item
                                                md={props.isSinglePage ? "auto" : 12}
                                                xs={12}
                                                sx={{
                                                    display: {
                                                        md: "flex",
                                                        xs: "none"
                                                    }
                                                }}>
                                                <Divider orientation="vertical" />
                                            </Grid>
        
                                            <Grid
                                                item
                                                xs={12}
                                                sx={{
                                                    display: {
                                                        md: props.isSinglePage ? "none" : "inline",
                                                        xs: "inline"
                                                    }
                                                }}>
                                                <Divider />
                                            </Grid>
        
                                            {props.form.contract?.dateStart &&
                                            <Grid
                                                item
                                                md={props.isSinglePage ? "auto" : 12}
                                                xs={12}
                                                sx={{
                                                }}>
                                                <Typography variant="body1" fontWeight={500}>
                                                    🗓️ Début du contrat souhaité<br/>
                                                    <b>{new Date(props.form.contract?.dateStart).getDate().toString().padStart(2, '0') + "/" + (new Date(props.form.contract?.dateStart).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(props.form.contract?.dateStart).getFullYear()}</b>
                                                </Typography>
        
                                                {control?.dateLimit &&
                                                <>
                                                <Divider sx={{my : 1}}/>
        
                                                <Typography fontWeight={500}>
                                                    ⏳ Date limite de dépôt de vos justificatifs<br/>
                                                    <b>{new Date(control.dateLimit).getDate().toString().padStart(2, '0') + "/" + (new Date(control.dateLimit).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(control.dateLimit).getFullYear()}</b>&nbsp;&nbsp;({delayNbDays} jours restants)
                                                </Typography>
                                                </>}
                                            </Grid>}
                                        </Grid>
                                        </>
        
                                        : control.status === ControlStatus.ACCEPTED ?
                                        <Typography fontWeight={500} sx={{display: "flex", alignItems: "center"}}>
                                            Félicitations, votre contrat GSMC a été validé !
                                            <Typography component='span' fontWeight={500} fontSize={24} sx={{ml: 1}}>
                                                🎉
                                            </Typography>
                                        </Typography>
        
                                        : control.status === ControlStatus.MEG ?
                                        <Typography fontWeight={500} sx={{display: "flex", alignItems: "center"}}>
                                            Félicitations, votre contrat GSMC est actif !
                                            <Typography component='span' fontWeight={500} fontSize={24} sx={{ml: 1}}>
                                                🎉
                                            </Typography>
                                        </Typography>
        
                                        : control.status === ControlStatus.REFUSED ?
                                        <Typography fontWeight={500}>
                                            Certains de vos documents ont été réfusés par nos équipes. Merci de les modifier dans l'onglet “Vos justificatifs” ci-dessous.
                                        </Typography>
        
                                        : control.status === ControlStatus.CLOSED ?
                                        <>
                                        <Typography fontWeight={500}>
                                            Votre demande d’adhésion a été refusée par nos équipes.
                                        </Typography>
                                        {control.comment &&
                                        <Typography
                                            fontWeight={500}
                                            sx={{
                                                whiteSpace: "pre-line",
                                                mt: 1,
                                                p: 1,
                                                background: plateformStyle.colors.grey.secondary + "A0",
                                                borderLeft: 2,
                                                borderColor: plateformStyle.colors.red.main,
                                                borderRadius: 2
                                            }}>
                                            {control.comment}
                                        </Typography>}
                                        <Divider sx={{my: 2}}/>
                                        <Typography fontWeight={500}>
                                            Pour plus d'informations veuillez contacter nos équipes.
                                            {settingsContact.supportPhone && settingsContact.displaySupportCard && <><br />📞 <Link href={`tel:${settingsContact.supportPhone}`}>{settingsContact.supportPhone}</Link></>}
                                            {settingsContact.contactMail && settingsContact.displayContactCard && <>&nbsp;&nbsp;•&nbsp;&nbsp;📧 <Link href={`mailto:${settingsContact.contactMail}`}>{settingsContact.contactMail}</Link></>}
                                        </Typography>
                                        </>
        
                                        : control.status === ControlStatus.EXPIRED ?
                                        <>
                                        <Typography fontWeight={500}>
                                            Le délai <b>7 jours</b> pour déposer vos justificatifs a expiré et votre demande d’adhésion a donc été suspendue.
                                        </Typography>
                                        <Divider sx={{my: 2}}/>
                                        <Typography fontWeight={500}>
                                            Pour finaliser le dépôt de vos justificatifs veuillez contacter nos équipes.
                                            {settingsContact.supportPhone && settingsContact.displaySupportCard && <><br />📞 <Link href={`tel:${settingsContact.supportPhone}`}>{settingsContact.supportPhone}</Link></>}
                                            {settingsContact.contactMail && settingsContact.displayContactCard && <>&nbsp;&nbsp;•&nbsp;&nbsp;📧 <Link href={`mailto:${settingsContact.contactMail}`}>{settingsContact.contactMail}</Link></>}
                                        </Typography>
                                        </>
        
                                        :
                                        <Typography fontWeight={500}>Votre contrat sera effectif dès la validation de vos pièces justificatives par nos services.</Typography>
                                        }
                                    </CardInfo>
                                </Grid>
        
                                <Grid
                                    item
                                    xs={12}>
                                    <TabContext value={tabValue.toString()}>
                                        <Tabs
                                            sx={{
                                                height: "32px",
                                                borderBottom: 1,
                                                borderColor: plateformStyle.colors.grey.main
                                            }}
                                            TabIndicatorProps={{
                                                style: {
                                                    backgroundColor: (tabValue === 1 && (control === undefined || control.status === ControlStatus.INCOMPLETE)) ? plateformStyle.colors.orange.main
                                                    : (tabValue === 1 && (control?.status === ControlStatus.REFUSED || control?.status === ControlStatus.EXPIRED)) ? plateformStyle.colors.red.main
                                                    : theme.palette.primary.main
                                                }
                                            }}
                                            variant="scrollable"
                                            scrollButtons="auto"
                                            value={tabValue}
                                            onChange={handleChange} >
                                            <Tab
                                                sx={{
                                                    height: "32px",
                                                    borderRadius: "8px 8px 0 0",
                                                    border: tabValue === 0 ? 1 : 0,
                                                    borderColor: plateformStyle.colors.grey.main,
                                                    background: tabValue === 0 ? "white" : "none"
                                                }}
                                                label={<Typography variant='body1'>Documents GSMC</Typography>} />
                                            <Tab
                                                sx={{
                                                    height: "32px",
                                                    borderRadius: "8px 8px 0 0",
                                                    border: tabValue === 1 ? 1 : 0,
                                                    borderColor: plateformStyle.colors.grey.main,
                                                    background: tabValue === 1 ? "white" : "none"
                                                }}
                                                icon={control === undefined || control.status === ControlStatus.INCOMPLETE ? <ErrorOutlineIcon color="warning" />
                                                    : control.status === ControlStatus.REFUSED || control.status === ControlStatus.EXPIRED ? <ErrorOutlineIcon color="error" />
                                                    : undefined}
                                                iconPosition="start"
                                                label={
                                                    <Typography
                                                        variant='body1'
                                                        color={tabValue === 1 &&
                                                                (control === undefined ||
                                                                control.status === ControlStatus.INCOMPLETE) ? plateformStyle.colors.orange.main
                                                                : tabValue === 1 && (control?.status === ControlStatus.REFUSED || control?.status === ControlStatus.EXPIRED) ? plateformStyle.colors.red.main
                                                                : "none"}>
                                                        Vos justificatifs
                                                    </Typography>} />
                                        </Tabs>
        
                                        <TabPanel
                                            value="0"
                                            sx={{
                                                px: 0,
                                                pb: 0
                                            }}>
                                            {documentsStatic &&
                                            <DocumentsDownload
                                                plateformStyle={plateformStyle}
                                                isCompact={!props.isSinglePage}
                                                formUuid={props.form.uuid || ""}
                                                offerCode={props.codeOffer}
                                                downloadAllFlag={downloadAllFlag}
                                                documents={documentsStatic}/>
                                            }
                                        </TabPanel>
        
                                        <TabPanel
                                            value="1"
                                            sx={{
                                                px: 0,
                                                pb: 0
                                            }}>

                                            {control?.status === ControlStatus.MEG ?
                                            <>
                                            {documentsSubscriber &&
                                            <DocumentsDownload
                                                plateformStyle={plateformStyle}
                                                isCompact={!props.isSinglePage}
                                                formUuid={props.form.uuid || ""}
                                                offerCode={props.codeOffer}
                                                downloadAllFlag={downloadAllFlag}
                                                documents={documentsSubscriber}/>}
                                            </>

                                            :
                                            <Grid
                                                container
                                                spacing={2}>
                                                <Grid
                                                    item
                                                    lg={props.isSinglePage ? 4 : 6}
                                                    md={props.isSinglePage ? 6 : 12}
                                                    xs={12}
                                                    order={(documentCniRecto as IControleDocument).status === ControlStatus.REFUSED ? -10 : 0}>
                                                    <FieldFileUpload
                                                        plateformStyle={plateformStyle}
                                                        document={documentCniRecto}
                                                        form={props.form}
                                                        title={`Pièce d'identité${documentCniVerso.fileNameOrigin ? " - Recto" : ""}`}
                                                        description="Recto et Verso de votre Carte d'identité, ou première page de votre Passeport"
                                                        emoji={enumFormDocumentTypes.find(_ => _.value === enumFormDocumentTypeCniRecto)?.icon}
                                                        required
                                                        disabled={control?.status === ControlStatus.EXPIRED || control?.status === ControlStatus.CLOSED}
                                                        readOnly={control?.status === ControlStatus.ACCEPTED}
                                                        downloadFlag={downloadAllFlagSubscriber}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }} />

                                                    {control?.status !== ControlStatus.EXPIRED &&
                                                    control?.status !== ControlStatus.CLOSED &&
                                                    control?.status !== ControlStatus.ACCEPTED &&
                                                    documentCniRecto.fileNameOrigin && !documentCniVerso.fileNameOrigin &&
                                                    <>
                                                    <Box sx={{mt: 2}}></Box>
                                                    <ButtonAddFile
                                                        plateformStyle={plateformStyle}
                                                        document={documentCniVerso}
                                                        form={props.form}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}>
                                                        Ajouter un Verso
                                                    </ButtonAddFile>
                                                    </>}
                                                </Grid>

                                                {documentCniVerso.fileNameOrigin &&
                                                <Grid
                                                    item
                                                    lg={props.isSinglePage ? 4 : 6}
                                                    md={props.isSinglePage ? 6 : 12}
                                                    xs={12}
                                                    order={(documentCniVerso as IControleDocument).status === ControlStatus.REFUSED ? -9 : 1}>
                                                    <FieldFileUpload
                                                        plateformStyle={plateformStyle}
                                                        document={documentCniVerso}
                                                        form={props.form}
                                                        title="Pièce d'identité - Verso"
                                                        description="si carte d'identité"
                                                        emoji={enumFormDocumentTypes.find(_ => _.value === enumFormDocumentTypeCniVerso)?.icon}
                                                        disabled={control?.status === ControlStatus.EXPIRED || control?.status === ControlStatus.CLOSED}
                                                        readOnly={control?.status === ControlStatus.ACCEPTED}
                                                        downloadFlag={downloadAllFlagSubscriber}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}
                                                        delete={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }} />
                                                </Grid>}

                                                {props.codeOffer === '539' && settingsDocument.displayKbis &&
                                                !(control?.status === ControlStatus.ACCEPTED && !documentKbis.fileNameOrigin) &&
                                                <Grid
                                                    item
                                                    lg={props.isSinglePage ? 4 : 6}
                                                    md={props.isSinglePage ? 6 : 12}
                                                    xs={12}
                                                    order={(documentKbis as IControleDocument).status === ControlStatus.REFUSED ? -8 : 2}>
                                                    <FieldFileUpload
                                                        plateformStyle={plateformStyle}
                                                        document={documentKbis}
                                                        form={props.form}
                                                        title="Kbis"
                                                        emoji={enumFormDocumentTypes.find(_ => _.value === enumFormDocumentTypeKbis)?.icon}
                                                        required={settingsDocument.kbisRequired}
                                                        disabled={control?.status === ControlStatus.EXPIRED || control?.status === ControlStatus.CLOSED}
                                                        downloadFlag={downloadAllFlagSubscriber}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}/>
                                                </Grid>}
                                                
                                                {settingsDocument.displayAttestationsCpam &&
                                                !(control?.status === ControlStatus.ACCEPTED && documentsAttestationsCPAM.length === 0) &&
                                                documentsAttestationsCPAM.map((_: IFormDocument, id) =>
                                                <Grid
                                                    item
                                                    lg={props.isSinglePage ? 4 : 6}
                                                    md={props.isSinglePage ? 6 : 12}
                                                    xs={12}
                                                    order={documentsAttestationsCPAM.find(_ => (_ as IControleDocument).status === ControlStatus.REFUSED) ? -7 : 3}>
                                                    <FieldFileUpload
                                                        plateformStyle={plateformStyle}
                                                        document={_}
                                                        form={props.form}
                                                        title="Attestation de Sécurité Sociale"
                                                        emoji={enumFormDocumentTypes.find(_ => _.value === enumFormDocumentTypeAttestationCPAM)?.icon}
                                                        required={settingsDocument.attestationsCpamRequired && id === 0}
                                                        disabled={control?.status === ControlStatus.EXPIRED || control?.status === ControlStatus.CLOSED}
                                                        readOnly={control?.status === ControlStatus.ACCEPTED}
                                                        downloadFlag={downloadAllFlagSubscriber}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}
                                                        delete={id === 0 ? undefined : (_: IFormDocument) => {
                                                            refreshControl();
                                                        }}/>

                                                    {control?.status !== ControlStatus.EXPIRED &&
                                                    control?.status !== ControlStatus.CLOSED &&
                                                    control?.status !== ControlStatus.ACCEPTED &&
                                                    id === 0 && _.fileNameOrigin &&
                                                    <>
                                                    <Box sx={{mt: 2}}></Box>
                                                    <ButtonAddFile
                                                        plateformStyle={plateformStyle}
                                                        document={{ form: props.form, type: enumFormDocumentTypeAttestationCPAM }}
                                                        form={props.form}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}>
                                                        Ajouter une Attestation
                                                    </ButtonAddFile>
                                                    </>}
                                                </Grid>)}

                                                {settingsDocument.displayRib &&
                                                 !(control?.status === ControlStatus.ACCEPTED && !documentRib.fileNameOrigin) &&
                                                <Grid
                                                    item
                                                    lg={props.isSinglePage ? 4 : 6}
                                                    md={props.isSinglePage ? 6 : 12}
                                                    xs={12}
                                                    order={(documentRib as IControleDocument).status === ControlStatus.REFUSED ? -6 : 4}
                                                    sx={{
                                                        mt: documentRib.fileNameOrigin || settingsDocument.ribRequired ? 0 : 5
                                                    }}>
                                                    <FieldFileUpload
                                                        plateformStyle={plateformStyle}
                                                        document={documentRib}
                                                        form={props.form}
                                                        title="RIB"
                                                        emoji={enumFormDocumentTypes.find(_ => _.value === enumFormDocumentTypeRIB)?.icon}
                                                        required={settingsDocument.ribRequired}
                                                        disabled={control?.status === ControlStatus.EXPIRED || control?.status === ControlStatus.CLOSED}
                                                        readOnly={control?.status === ControlStatus.ACCEPTED}
                                                        downloadFlag={downloadAllFlagSubscriber}
                                                        send={(_: IFormDocument) => {
                                                            refreshControl();
                                                        }}/>
                                                </Grid>}
                                            </Grid>}
                                        </TabPanel>
                                    </TabContext>
                                </Grid>

                                {control === undefined || control.status === ControlStatus.INCOMPLETE &&
                                <Grid
                                    item
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem'
                                        sx={{
                                            mt: 0.75
                                        }} >
                                        Conformément à notre politique de confidentialité, vos données personnelles seront effacées après 30 jours d'inactivité.
                                    </Typography>
                                </Grid>}
                            </Grid>
                        </Card>        
                    </Grid>

                    {tabValue !== 1 &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            textAlign: 'center'
                        }}>
                        <Button
                            sx={{
                                py: 1,
                                color: theme.palette.text.primary,
                                transition: "transform 400ms",
                                ":hover": {
                                    transform: "translateY(-6px)"
                                }
                            }}
                            startIcon={<DownloadIcon color={plateformStyle.colors.black.main } /> }
                            onClick={() => {
                                if (tabValue === 0)
                                    setDownloadAllFlag(downloadAllFlag + 1)
                                if (tabValue === 1)
                                    setDownloadAllFlagSubscriber(downloadAllFlagSubscriber + 1)
                            }} >
                            Tout télécharger
                        </Button>
                    </Grid>}

                    {!props.isSinglePage &&
                    <Snackbar
                        sx={{
                            maxWidth: '1200px',
                            width: {
                                sm: '100%'
                            },
                            px: {
                                sm: 2
                            },
                            py: 'auto'
                        }}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                        open={isSnackbarOpened}
                        autoHideDuration={5000}
                        onClose={handleSnackbarClose}
                        TransitionComponent={(_) => <Slide {..._} direction="down" />} >
                        <Alert
                            sx={{
                                mx: {
                                    sm: 2
                                },
                                my: 'auto',
                            }}
                            severity='success'
                            iconMapping={{
                                success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                                error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                            }}
                            action={
                                <IconButton
                                    aria-label="close"
                                    color="inherit"
                                    size="small"
                                    onClick={handleSnackbarClose}>
                                    <MUICloseIcon fontSize="inherit" fontWeight={700} />
                                </IconButton>
                            }>
                            <Typography fontWeight={500}>Un email de confirmation a bien été envoyé à l'adresse : {props.subscriber?.email}</Typography>
                        </Alert>
                    </Snackbar>}
                </Grid>
            }
            footer={
                <Button
                    variant='contained'
                    sx={{
                        width: {
                            sm: "auto",
                            xs: '100%'
                        },
                        my: 5,
                        px: 5,
                        color: 'white',
                        backgroundColor: theme.palette.text.primary,
                        transition: "transform 400ms",
                        ':hover': {
                            transform: "translateY(-6px)",
                            backgroundColor: 'black'
                        }
                    }}
                    onClick={() => {
                        goToSite()
                    }} >
                    Découvrez le site
                </Button>
            } />
    )
}

import { useState } from 'react';

import { ChromePicker } from 'react-color';

import Box from '@mui/material/Box';
import { ClickAwayListener, IconButton, Popper, Stack, Tooltip } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    id?: string;
    disabled?: Boolean;
    label?: string | JSX.Element;
    value?: string;
    defaultValue?: string;
    resetEnabled?: boolean;
    onChange: (value: string | undefined) => void;
    onBlur?: (e: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
}


export const FieldColorPickerMinify = (props: Props) => {

    const [displayPicker, setDisplayPicker] = useState<boolean>(false);
    
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

    return (
        <ClickAwayListener
            onClickAway={() => setDisplayPicker(false)}>
            <Stack
                direction="row"
                alignItems="center">
                <IconButton
                    onClick={(event) => {
                        setAnchorEl(event.currentTarget)
                        setDisplayPicker(!displayPicker)
                        event.stopPropagation()
                    }}>
                    <Box
                        sx={{
                            width: "20px",
                            height: "20px",
                            border: 1,
                            borderColor: colors.grey.main,
                            borderRadius: 1,
                            backgroundColor: props.value || props.defaultValue || "white",
                        }}>
                    </Box>
                </IconButton>

                <Popper open={displayPicker} anchorEl={anchorEl}>
                    <ChromePicker
                        color={props.value || props.defaultValue || colors.blue.main}
                        onChange={(_) => {
                            props.onChange(_.hex)
                        }} />
                </Popper>

                {props.resetEnabled &&
                <Tooltip
                    title="Reset"
                    placement="top">
                    <IconButton
                        onClick={() => {
                            props.onChange(props.defaultValue)
                        }}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>}
            </Stack>
        </ClickAwayListener>
    )
}

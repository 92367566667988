import Grid from "@mui/material/Grid";


interface Props {
    header?: JSX.Element;
    sidebar?: JSX.Element;
    content: JSX.Element;
    footer?: JSX.Element;
}


export const PageContentLayout = (props: Props) => {
    return (

    <Grid
        container
        justify-content='center'
        spacing={2}
        sx={{
            px: 2
        }} >
        {props.header &&
        <Grid
            xs={12}
            item >
            {props.header}
        </Grid>}

        {props.sidebar &&
        <Grid
            md={4}
            xs={12}
            item
            sx={{
                zIndex: 100,
                position: {
                    md: "sticky",
                    xs: "relative"
                },
                top: {
                    md: 60,
                    xs: 0
                },
                background: "white",
                height: "100%"
            }}>
            {props.sidebar}
        </Grid>}

        <Grid
            md={props.sidebar ? 8 : 12}
            xs={12}
            item >
            {props.content}
        </Grid>

        <Grid
            xs={12}
            item
            sx={{
                textAlign: 'center'
            }}>
            {props.footer}
        </Grid>
    </Grid>
    )
  }

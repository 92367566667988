import { defaultIconStepSize, IIcon } from "./settings"


export const StepSignatureIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconStepSize.width}
            height={props.height ? props.height : defaultIconStepSize.height}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M80 40C80 17.9086 62.0914 0 40 0C17.9086 0 0 17.9086 0 40C0 62.0914 17.9086 80 40 80C62.0914 80 80 62.0914 80 40Z" fill={props.color} />
        <path d="M10.8414 25.4207L11.5482 27.3307L13.4583 28.0375L11.5482 28.7443L10.8414 30.6543L10.1347 28.7443L8.2246 28.0375L10.1347 27.3307L10.8414 25.4207Z" fill="white"/>
        <path d="M60.1873 52.3364L61.298 55.3379L64.2995 56.4486L61.298 57.5592L60.1873 60.5607L59.0767 57.5592L56.0752 56.4486L59.0767 55.3379L60.1873 52.3364Z" fill="white"/>
        <path d="M20.1865 17.1962C20.1865 14.7186 22.195 12.7102 24.6725 12.7102H56.0744C58.5519 12.7102 60.5603 14.7186 60.5603 17.1962V63.5513C60.5603 66.0288 58.5519 68.0373 56.0744 68.0373H24.6725C22.195 68.0373 20.1865 66.0288 20.1865 63.5513V17.1962Z" fill="url(#paint0_linear_3_14)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.421 22.8395L16.0969 49.6811L13.5744 55.3157C13.1376 56.2915 13.8362 57.3971 14.905 57.4216L20.7603 57.5556L66.0844 30.714C67.8609 29.6619 68.4481 27.369 67.3961 25.5925L66.5425 24.1511C65.4904 22.3747 63.1975 21.7874 61.421 22.8395Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M66.0844 30.714C67.8609 29.6619 68.4481 27.369 67.3961 25.5925L66.5425 24.1511C65.4904 22.3747 63.1975 21.7874 61.421 22.8395L16.0969 49.6811L13.5744 55.3157C13.1376 56.2915 13.8362 57.3971 14.905 57.4216L20.7603 57.5556L66.0844 30.714ZM20.5634 56.8032L65.7034 30.0707C67.1246 29.229 67.5944 27.3947 66.7528 25.9735L65.8992 24.5321C65.0575 23.1109 63.2232 22.6411 61.802 23.4828L16.6823 50.2033L14.2568 55.6212C14.0384 56.109 14.3877 56.6619 14.9221 56.6741L20.5634 56.8032Z" fill="url(#paint1_linear_3_14)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M18.1248 52.1167C17.8115 51.5823 17.9908 50.8952 18.5251 50.582L59.9546 26.2958C60.4889 25.9825 61.176 26.1618 61.4893 26.6961C61.8025 27.2305 61.6232 27.9176 61.0889 28.2308L19.6595 52.517C19.1251 52.8303 18.438 52.651 18.1248 52.1167Z" fill={props.color}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M33.48 59.8262C33.2412 59.0131 32.375 58.5607 31.5712 58.8295L20.4243 62.556C20.0327 62.687 19.6091 62.4756 19.4782 62.084C19.3473 61.6924 19.5586 61.2688 19.9502 61.1379L31.0971 57.4113C32.7046 56.8738 34.4371 57.7785 34.9148 59.4048L36.0644 63.3191C36.2956 64.1063 37.1179 64.5602 37.9072 64.3363L41.5043 63.3161C42.7627 62.9592 44.1071 63.4615 44.8231 64.5562L46.0567 66.4422C46.3981 66.9642 47.0277 67.2194 47.6363 67.0825L51.8878 66.1261C52.2907 66.0355 52.6907 66.2886 52.7813 66.6914C52.872 67.0943 52.6189 67.4943 52.216 67.585L47.9645 68.5414C46.7474 68.8152 45.4881 68.3047 44.8053 67.2607L43.5717 65.3747C43.2137 64.8274 42.5415 64.5762 41.9123 64.7547L38.3152 65.7749C36.7366 66.2226 35.0921 65.3149 34.6297 63.7405L33.48 59.8262Z" fill="url(#paint2_linear_3_14)"/>
        <defs>
        <filter id="filter0_i_3_14" x="13.4423" y="22.3171" width="54.4761" height="37.2385" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3_14"/>
        </filter>
        <filter id="filter1_i_3_14" x="13.4423" y="22.3171" width="54.4761" height="37.2385" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="2"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_3_14"/>
        </filter>
        <linearGradient id="paint0_linear_3_14" x1="40.3734" y1="12.7102" x2="40.3734" y2="68.0373" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_3_14" x1="13.8241" y1="56.9782" x2="67.2469" y2="25.3406" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.4"/>
        </linearGradient>
        <linearGradient id="paint2_linear_3_14" x1="20.1873" y1="61.847" x2="53.048" y2="69.7884" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0.5"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

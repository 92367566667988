import { defaultIconSize, IIcon } from "./settings"


export const CheckFullIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M30 15C30 23.2843 23.2843 30 15 30C6.71573 30 0 23.2843 0 15C0 6.71573 6.71573 0 15 0C23.2843 0 30 6.71573 30 15Z" fill={props.bgColor}/>
        <path fillRule="evenodd" clipRule="evenodd" d="M22.614 8.21063C23.05 8.5497 23.1285 9.17798 22.7894 9.61393L12.6285 22.678L7.18357 14.9773C6.86472 14.5264 6.9718 13.9023 7.42275 13.5835C7.8737 13.2646 8.49774 13.3717 8.81659 13.8227L12.705 19.322L21.2107 8.38604C21.5498 7.9501 22.1781 7.87156 22.614 8.21063Z" fill={props.color}/>
        </svg>
    )
}

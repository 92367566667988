import { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import { msalInstance } from '../..';
import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { loginRequest } from '../../utils/authConfig';

import { ThemeProvider } from '@mui/material/styles';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import TimelapseIcon from '@mui/icons-material/Timelapse';
import LanguageIcon from '@mui/icons-material/Language';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';
import PersonIcon from '@mui/icons-material/Person';
import TelegramIcon from '@mui/icons-material/Telegram';

import AppBar from '../../components/AppBar/AppBar';
import { GSMCIcon } from "../../components/Icon/GSMCIcon";
import { ButtonLink } from '../../components/Button/ButtonLink';
import { ErrorComponent } from '../../components/ErrorComponent';
import { Loading } from '../../components/Loading';

import { colors } from '../../static/themes/gsmc/colors';
import { theme } from '../../static/themes/gsmc/theme'
import { TransitionSlideUP } from '../../utils/transistions/transitions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { CardGiftcard, GifBox } from '@mui/icons-material';


export function Layout() {
    document.title = 'GSMC BO';

    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const location = useLocation();

    const authRequest = {
        ...loginRequest
    };

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        handleClose();
    }, [location]);


    const appBar = <AppBar
        header={
            <GSMCIcon
                width="40px"
                height="auto" />}
        items={[
            {type: "TITLE", label: "Accès"},
            {type: "LINK", label: "Plateforme", icon: <LanguageIcon />, url: "plateforme", urlBase: "bo", isActive: "/bo" === location.pathname},
            {type: "LINK", label: "Compte", icon: <PersonIcon />, url: "account", urlBase: "bo"},
            {type: "TITLE", label: "Souscription"},
            {type: "LINK", label: "Vérification", icon: <SearchIcon />, url: "souscription", urlBase: "bo"},
            {type: "LINK", label: "Incomplète", icon: <TimelapseIcon />, url: "souscription/incomplete", urlBase: "bo"},
            {type: "TITLE", label: "Commercial"},
            {type: "LINK", label: "Campagne", icon: <TelegramIcon />, url: "campaign", urlBase: "bo"},
            {type: "LINK", label: "Promotion", icon: <CardGiftcard />, url: "promotion", urlBase: "bo"},
            {type: "TITLE", label: "Référentiel"},
            {type: "LINK", label: "Segment", url: "segment", urlBase: "bo"},
            {type: "LINK", label: "Offre", url: "offre", urlBase: "bo"},
            {type: "LINK", label: "Gamme", url: "gamme", urlBase: "bo"},
            {type: "LINK", label: "Produit", url: "produit", urlBase: "bo"},
            {type: "LINK", label: "Tarification", url: "tarification", urlBase: "bo"}
        ]}
        footer={
            <ButtonLink
                label="Se déconnecter"
                icon={<Logout />}
                color={colors.red.main}
                onClick={() => {
                    msalInstance.logoutRedirect()
            }} />} />

    return (
        <ThemeProvider theme={theme}>
            <MsalAuthenticationTemplate
                interactionType={InteractionType.Redirect}
                authenticationRequest={authRequest}
                errorComponent={ErrorComponent}
                loadingComponent={Loading} >
                <Grid
                    container
                    sx={{
                        position: "relative",
                        wrap: {
                            md: "nowrap",
                            xs: "wrap"
                        },
                        width: "100%",
                    }}>
                    <Grid
                        item
                        xs
                        sx={{
                            position: "sticky",
                            top: 0,
                            display: {
                                md: "flex",
                                xs: "none"
                            },
                            width: "300px",
                            height: "100vh",
                            p: 2,
                            borderRight: 1,
                            borderColor: colors.grey.main
                        }}>
                        {appBar}
                    </Grid>

                    <Grid
                        item
                        xs
                        sx={{
                            position: "sticky",
                            top: 0,
                            display: {
                                md: "none",
                                xs: "flex"
                            },
                            width: "100vw",
                            height: "100%",
                            p: 1,
                            zIndex: 1000,
                            background: "white",
                            borderBottom: 1,
                            borderColor: colors.grey.main
                        }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                width: "100%"
                            }}>
                            <GSMCIcon
                                width="36px"
                                height="auto" />
                            <IconButton onClick={handleClickOpen}>
                                <MenuIcon />
                            </IconButton>
                        </Stack>

                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}
                            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    m: 1
                                }}>
                                <CloseIcon />
                            </IconButton>
                            <DialogContent
                                sx={{
                                    p: 2
                                }}>
                                {appBar}
                            </DialogContent>
                        </Dialog>
                    </Grid>

                    <Grid
                        item
                        sx={{
                            width: {
                                md: "calc(100vw - 300px)",
                                xs: "100%"
                            },
                            minHeight: "100vh"
                        }} >
                        <Outlet />
                    </Grid>
                </Grid>
            </MsalAuthenticationTemplate>
        </ThemeProvider>
    )
};

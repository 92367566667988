import Badge from '@mui/material/Badge';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';


interface Props {
    label: string;
    description?: string;
    icon?: JSX.Element;
    badgeContent?: string | undefined;
}


export const FormHeaderSection = (props: Props) => {

    return (
        <Grid
            container
            columnSpacing={2}>
            {props.icon &&
            <Grid
                item
                xs="auto">
                {props.icon}
            </Grid>}

            <Grid
                item
                xs>
                <Typography variant="h2" >
                    {props.label}
                    {props.badgeContent &&  <Badge badgeContent={props.badgeContent} sx={{ml: 3}}></Badge>}
                </Typography>
            </Grid>

            {props.description &&
            <Grid
                item
                xs={12}>
                <Typography variant="body1" fontWeight={500}>
                    {props.description}
                </Typography>
            </Grid>}
        </Grid>
    )
}

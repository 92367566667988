
export interface IIcon {
    color?: string;
    secondaryColor?: string;
    bgColor?: string;
    width?: string;
    height?: string;
}


export interface IIconSize {
    width: string;
    height: string;
}


export const defaultIconSize: IIconSize = {
    width: "30",
    height: "30"
}


export const defaultIconHeaderSize: IIconSize = {
    width: "40",
    height: "40"
}


export const defaultIconStepSize: IIconSize = {
    width: "75",
    height: "75"
}


export const defaultIconImageSize: IIconSize = {
    width: "90",
    height: "90"
}

import { useEffect, useState } from "react";

import Card from "@mui/material/Card"
import Grid from '@mui/material/Grid';
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography"

import { DownloadIcon } from "../Icon/DownloadIcon";

import { downloadDocument } from "../../api/FormDocument.api"

import { IFormDocument } from "../../model/FormDocument.model"
import { enumFormDocumentTypeAttestationCPAM, enumFormDocumentTypeCniRecto, enumFormDocumentTypeCniVerso, enumFormDocumentTypeKbis, enumFormDocumentTypeSubscriptionCertificat } from "../../utils";


interface Props {
    plateformStyle: any;
    label: string;
    icon?: string;
    description?: string;
    document: IFormDocument;
    downloadFlag: number;
}


export const FieldFileDownload = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    const [isDownloading, setIsDownloading] = useState<boolean>(false);

    const download = () => {
        if (!props.document.uuid || !props.document.fileName)
            return;

        setIsDownloading(true);

        downloadDocument(props.document.uuid).then(response => {
            let fileName: string;

            const link = document.createElement('a');
            const url = window.URL.createObjectURL(
                new Blob([response]),
            );

            link.href = url;
            if (props.document.fileName &&
                (props.document.type === enumFormDocumentTypeCniRecto ||
                 props.document.type === enumFormDocumentTypeCniVerso ||
                 props.document.type === enumFormDocumentTypeKbis ||
                 props.document.type === enumFormDocumentTypeAttestationCPAM))
                fileName = props.document.type + props.document.fileExtension;
            else if (props.document.fileName && props.document.type === enumFormDocumentTypeSubscriptionCertificat)
                fileName = "GSMC - Certificat d'Adhésion" + props.document.fileExtension;
            else
                fileName = props.document.fileName || "";

            link.setAttribute('download', fileName);

            document.body.appendChild(link);

            link.click();

            setIsDownloading(false);
        });
    }

    useEffect(() => {
        if (props.downloadFlag !== 0) download();
    }, [props.downloadFlag])


    return (
        <Card
            sx={{
                textAlign: 'left',
                backgroundColor: 'white',
                cursor: "pointer",
                transition: "transform 400ms",
                ":hover": {
                    boxShadow : "rgba(0, 0, 0, 0.1)  0px 4px 6px -1px, rgba(0, 0, 0, 0.06) 0px 2px 4px -1px",
                    transform: "translateY(-8px)"
                }
            }}
            onClick={(e) => {
                e.stopPropagation();
                download()
            }}>
            <LinearProgress
                value={100}
                color='primary'
                sx={{
                    display: isDownloading ? 'block' : 'none'
                }} />
            <Grid
                container
                justifyContent='flex-start'
                alignItems='center'
                spacing={2}
                sx={{
                    p: 2,
                    minHeight: "100%",
                }} >
                <Grid
                    item
                    xs="auto">
                    <Typography variant='h3'>
                        {props.icon}
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs>
                    <Typography variant='h3'>
                        {props.label}
                    </Typography>
                    {props.description && !props.document.fileNameOrigin &&
                    <Typography variant='body2' fontWeight={500} sx={{ mb: 2 }} >
                        {props.description}
                    </Typography>}
                </Grid>

                <Grid
                    item
                    xs="auto">
                    <DownloadIcon color={plateformStyle.colors.black.main} />
                </Grid>
            </Grid>
        </Card>
    )
}

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';


interface Props {
    label: string;
    description?: string;
    icon?: JSX.Element;
}


export const FormHeader = (props: Props) => {

    return (
        <Box
            sx={{
                mt: 1
            }} >
            { props.icon }
            <Typography component="h1" variant="h1" > {props.label} </Typography>
            <Typography variant="body1" fontWeight={500} > {props.description} </Typography>
        </Box>
    )
}

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import Typography from "@mui/material/Typography";

import { CardOffer } from "../../components/Card/CardOffer";
import { CardOfferButton } from "../../components/Card/CardOfferButton";

import { IFormuleDetails } from "../../model/Formule.model";

interface Props {
    plateformStyle: any;
    formulesDetailsLength: number;
    formuleCenter: IFormuleDetails;
    formuleLeft?: IFormuleDetails;
    formuleRight?: IFormuleDetails;
    formulePrevious?: IFormuleDetails;
    formuleNext?: IFormuleDetails;
    isDetailDisplayed?: boolean;
    displaySingle?: Boolean;
    isMinified?: Boolean;
    selectFormule?: (formule: IFormuleDetails) => void;
    goToPrevious: () => void;
    goToNext: () => void;
    toggleDetail?: (formule: IFormuleDetails) => void;
}


export const FormOfferHeader = (props: Props) => {

    const theme = useTheme();
    const plateformStyle: any = props.plateformStyle;

    const screenSizeUpMD = useMediaQuery(theme.breakpoints.up('md'));
    const screenSizeUpLG = useMediaQuery(theme.breakpoints.up('lg'));
    const nbFormuleDisplayed = screenSizeUpLG ? 3 : screenSizeUpMD ? 2 : 1;

    const toggleDetail = (formule: IFormuleDetails) => {
        if (props.toggleDetail)
            props.toggleDetail(formule)
    }

    return (
        <Grid
            container
            justify-content='flex-end'
            spacing={{
                sm: props.isMinified ? 2 : 4,
                xs: 1
            }} >

            <Grid
                item
                sx={{
                    p: "0px !important",
                    width: (!props.isDetailDisplayed || props.displaySingle) ? "0px" :
                        props.formulesDetailsLength <= nbFormuleDisplayed ? "25%" :
                        props.isMinified && screenSizeUpLG ? "25%" :
                        props.isMinified && screenSizeUpMD ? "33.33%" : "16.66%"
                }}
                style={{
                    transition: theme.transitions.create("all", {
                        easing: theme.transitions.easing.easeInOut, 
                        duration: theme.transitions.duration.leavingScreen
                    }),
                }} >
            </Grid>

            <Grid
                item
                md={!props.isDetailDisplayed || props.displaySingle ? 12 :
                    props.formulesDetailsLength <= nbFormuleDisplayed ? 9 :
                    props.isMinified && screenSizeUpLG ? 9 :
                    props.isMinified && !screenSizeUpLG ? 8 : 10}
                xs={12}
                sx={{
                    width: '100%',
                    textAlign: 'left',
                    pr: {
                        lg: props.isMinified ? 10 : 0,
                        md: props.isMinified ? 17 : 0,
                        xs: 0
                    }
                }}
                style={{
                    transition: theme.transitions.create("all", {
                        easing: theme.transitions.easing.easeInOut, 
                        duration: theme.transitions.duration.leavingScreen
                    }),
                }} >

                <Box
                    sx={{
                        width: '100%',
                        display: 'flex'
                    }} >

                    {!props.isMinified &&
                    <Box
                        sx={{
                            width: {
                                sm: '110px',
                                xs: "auto"
                            },
                            my: 'auto',
                            pt: {
                                md: props.isMinified ? 4 : 0,
                                xs: props.isMinified ? 3 : 0
                            },
                            display: {
                                lg: props.formulesDetailsLength <= 3 ? 'none' : 'block',
                                md: props.formulesDetailsLength <= 2 ? 'none' : 'block',
                                xs: props.formulesDetailsLength <= 1 ? 'none' : 'block'
                            },
                            pr: {
                                lg: 0,
                                md: 3,
                                sm: 0
                            }
                        }} >

                        {props.formulePrevious &&
                        <CardOfferButton
                            plateformStyle={plateformStyle}
                            label={props.formulePrevious?.formule?.formuleLibelle}
                            labelColor={props.formulePrevious.color}
                            isPrevious
                            isMinified={props.isMinified}
                            onClick={() => props.goToPrevious()} />}
                    </Box>}

                    <Grid
                        container
                        spacing={3}
                        justifyContent='space-between'
                        alignItems='flex-end' >

                        <Grid
                            lg={props.formulesDetailsLength === 2 ? 6 : 4}
                            md={6}
                            xs={12}
                            item
                            sx={{
                                display: {
                                    lg: props.formulesDetailsLength === 1 ? "none" : 'block',
                                    xs: 'none'
                                },
                                pr: props.isMinified ? 0 : 2
                            }} >

                            {props.formuleLeft &&
                            <>
                            <CardOffer
                                plateformStyle={plateformStyle}
                                formule={props.formuleLeft?.formule}
                                color={props.formuleLeft.color}
                                isRecommended={props.formuleLeft.isRecommended}
                                isMinified={props.isMinified}
                                onClick={
                                    props.selectFormule ?
                                    () => {
                                        if (props.formuleLeft && props.selectFormule)
                                            props.selectFormule(props.formuleLeft);
                                    }
                                    : undefined}
                                onClickSecondary={
                                    props.toggleDetail ?
                                    () => {
                                        if (props.formuleLeft && props.toggleDetail)
                                            toggleDetail(props.formuleLeft)
                                    }
                                    : undefined} />

                            {!props.isMinified &&
                            <Box
                                sx={{
                                    mt: 2
                                }} >
                                <Typography>{props.formuleLeft.description}</Typography>
                                <LinearProgress
                                    value={props.formuleLeft.coverPercentage}
                                    sx={{
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: props.formuleLeft.color || plateformStyle.colors.blue.main,
                                        }
                                    }}
                                    variant="determinate" />
                            </Box>}
                            </>}
                        </Grid>

                        {props.formuleCenter &&
                        <Grid
                            lg={props.formulesDetailsLength === 1 ? 12 : props.formulesDetailsLength === 2 ? 6 : 4}
                            md={props.formulesDetailsLength === 1 ? 12 : 6}
                            xs={12}
                            item
                            sx={{
                                transform: {
                                    md: !props.isMinified && props.formulesDetailsLength !== 1 ? "scale(1.15)" : "none",
                                    xs: "none"
                                },
                                mb: {
                                    md: !props.isMinified && props.formulesDetailsLength !== 1 ? 2.6 : 0,
                                    xs: 0
                                }
                            }}>

                            <CardOffer
                                plateformStyle={plateformStyle}
                                formule={props.formuleCenter?.formule}
                                color={props.formuleCenter.color}
                                isRecommended={props.formuleCenter.isRecommended}
                                isMinified={props.isMinified}
                                onClick={
                                    props.selectFormule ?
                                    () => {
                                        if (props.formuleCenter && props.selectFormule)
                                            props.selectFormule(props.formuleCenter);
                                    }
                                    : undefined}
                                onClickSecondary={
                                    props.toggleDetail ?
                                    () => {
                                        if (props.formuleCenter && props.toggleDetail)
                                            toggleDetail(props.formuleCenter)
                                    }
                                    : undefined} />

                            {!props.isMinified &&
                            <Box
                                sx={{
                                    mt: 2
                                }} >
                                <Typography>{props.formuleCenter.description}</Typography>
                                <LinearProgress
                                    value={props.formuleCenter.coverPercentage}
                                    sx={{
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: props.formuleCenter.color || plateformStyle.colors.blue.main,
                                        }
                                    }}
                                    variant="determinate" />
                            </Box>}
                        </Grid>}

                        <Grid
                            lg={4}
                            md={6}
                            xs={12}
                            item
                            sx={{
                                display: {
                                    md: props.formulesDetailsLength === 1 ? "none" : 'block',
                                    xs: 'none'
                                },
                                pl: {
                                    md: props.isMinified ? 0 : "40px !important",
                                    sm: 0
                                }
                            }} >

                            {props.formuleRight &&
                            <>
                            <CardOffer
                                plateformStyle={plateformStyle}
                                formule={props.formuleRight?.formule}
                                color={props.formuleRight.color}
                                isRecommended={props.formuleRight.isRecommended}
                                isMinified={props.isMinified}
                                onClick={
                                    props.selectFormule ?
                                    () => {
                                        if (props.formuleRight && props.selectFormule)
                                            props.selectFormule(props.formuleRight);
                                    }
                                    : undefined}
                                onClickSecondary={
                                    props.toggleDetail ?
                                    () => {
                                        if (props.formuleRight && props.toggleDetail)
                                            toggleDetail(props.formuleRight)
                                    }
                                    : undefined} />

                            {!props.isMinified &&
                            <Box
                                sx={{
                                    mt: 2
                                }} >
                                <Typography>{props.formuleRight.description}</Typography>
                                <LinearProgress
                                    value={props.formuleRight.coverPercentage}
                                    sx={{
                                        '& .MuiLinearProgress-bar': {
                                            backgroundColor: props.formuleRight.color || plateformStyle.colors.blue.main,
                                        }
                                    }}
                                    variant="determinate" />
                            </Box>}
                            </>}
                        </Grid>
                    </Grid>

                    {!props.isMinified &&
                    <Box
                        sx={{
                            width: {
                                sm: '110px',
                                xs: "auto"
                            },
                            my: 'auto',
                            pt: {
                                md: props.isMinified ? 4 : 0,
                                xs: props.isMinified ? 3 : 0
                            },
                            display: {
                                lg: props.formulesDetailsLength <= 3 ? 'none' : 'block',
                                md: props.formulesDetailsLength <= 2 ? 'none' : 'block',
                                xs: props.formulesDetailsLength <= 1 ? 'none' : 'block'
                            }
                        }} >

                        {props.formuleNext &&
                        <CardOfferButton
                            plateformStyle={plateformStyle}
                            label={props.formuleNext?.formule?.formuleLibelle || ""}
                            labelColor={props.formuleNext.color}
                            isNext
                            isMinified={props.isMinified}
                            onClick={() => props.goToNext()} />}
                    </Box>}

                </Box>
            </Grid>
        </Grid>
    )
}

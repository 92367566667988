import { Navigate } from 'react-router-dom';
import AuthService from './AuthService';

interface IPrivateRouteProps {
    children: any
    authorities?: string[]
}

const authentication = new AuthService();

function PrivateRoute(props: IPrivateRouteProps) {

    return (
        (props.authorities === undefined && authentication.isLoggedIn()) ||
        (props.authorities !== undefined && authentication.hasAnyAuthorities(props.authorities)) ?
            props.children
            :
            <Navigate to="/auth/signin" />
    );
}

export default PrivateRoute;

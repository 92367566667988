import { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import Autocomplete, { AutocompleteCloseReason } from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { ClickAwayListener, IconButton, Link, MenuItem, Pagination, Popper, Select, SelectChangeEvent, TableSortLabel } from '@mui/material';

import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';
import ClearIcon from '@mui/icons-material/Clear';
import CloseIcon from '@mui/icons-material/Close';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';

import { getPlateformes } from '../../../api/Plateforme.api';
import { getAll } from '../../../api/Promotion.api';
import { IPlateforme } from '../../../model';
import { enumPromotionOffer, enumPromotionStatus, enumPromotionType, IPromotion, PromotionStatus } from '../../../model/Promotion.model';

import { colors } from '../../../static/themes/gsmc/colors';
import { useDebounce } from 'use-debounce';

export const PromotionList = () => {
    document.title = 'BO - Promotions';

    const navigate = useNavigate()

    // Table data.
    const [data, setData] = useState<IPromotion[] | null>();
    const [count, setCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [pageSize, setPageSize] = useState<number>(10);

    const forceRefresh = () => {
        getAll(
            pageNumber,
            pageSize,
            search,
            filterTypes.map(_ => _.value),
            filterOffer.map(_ => _.value),
            filterPlateforme.map(_ => _.id),
            filterStatus.map(_ => _.value))
        .then(response => {
            setData(response.content);
            setCount(response.totalElements);
        })
        .catch((exception) => {
            setData(null);
            setCount(0);
        })
    }

    // Filters.
    const [search, setSearch] = useState<string>();
    const [searchDebounced] = useDebounce(search, 300);

    const [anchorFilterTypes, setAnchorFilterTypes] = useState<null | HTMLElement>(null);
    const [filterTypes, setFilterTypes] = useState<any[]>([]);
    const filterTypesOpen = Boolean(anchorFilterTypes);
    const openFilterTypes = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterTypes(event.currentTarget);
    };

    const closeFilterTypes = () => {
        if (anchorFilterTypes) {
            anchorFilterTypes.focus();
        }
        setAnchorFilterTypes(null);
    };

    const [anchorFilterStatus, setAnchorFilterStatus] = useState<null | HTMLElement>(null);
    const [filterStatus, setFilterStatus] = useState<any[]>([]);
    const filterStatusOpen = Boolean(anchorFilterStatus);
    const openFilterStatus = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterStatus(event.currentTarget);
    };

    const closeFilterStatus = () => {
        if (anchorFilterStatus) {
            anchorFilterStatus.focus();
        }
        setAnchorFilterStatus(null);
    };

    const [anchorFilterOffer, setAnchorFilterOffer] = useState<null | HTMLElement>(null);
    const [filterOffer, setFilterOffer] = useState<any[]>([]);
    const filterOfferOpen = Boolean(anchorFilterOffer);
    const openFilterOffer = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterOffer(event.currentTarget);
    };

    const closeFilterOffer = () => {
        if (anchorFilterOffer) {
            anchorFilterOffer.focus();
        }
        setAnchorFilterOffer(null);
    };

    const [plateformes, setPlateformes] = useState<IPlateforme[]>([]);
    const [anchorFilterPlateforme, setAnchorFilterPlateforme] = useState<null | HTMLElement>(null);
    const [filterPlateforme, setFilterPlateforme] = useState<any[]>([]);
    const filterPlateformeOpen = Boolean(anchorFilterPlateforme);
    const openFilterPlateforme = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorFilterPlateforme(event.currentTarget);
    };

    const closeFilterPlateforme = () => {
        if (anchorFilterPlateforme) {
            anchorFilterPlateforme.focus();
        }
        setAnchorFilterPlateforme(null);
    };

    useEffect(() => {
        getPlateformes(0, 1000).then(_ => setPlateformes(_.content));
    }, [])

    const resetFilters = () => {
        setFilterTypes([])
        setFilterStatus([])
        setFilterOffer([])
        setFilterPlateforme([])
    };

    useEffect(() => {
        setPageNumber(0);
        forceRefresh();
    }, [pageNumber, pageSize, searchDebounced, filterTypes, filterOffer, filterStatus, filterPlateforme])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <CardGiftcardIcon />},
                {type: "LINK", label: "Promotion", url: "/bo/promotion"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12}>
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="center"
                        useFlexGap
                        flexWrap="wrap"
                        gap={2}>
                        <Stack
                            direction="row"
                            alignItems="center"
                            useFlexGap
                            flexWrap="wrap"
                            gap={2}>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        py: 1,
                                        borderColor: colors.grey.main
                                    }}
                                    endIcon={<ArrowDropDownIcon />}
                                    onClick={openFilterTypes}>
                                    <Typography
                                        variant='body1'
                                        fontWeight={500}>
                                        Type
                                        {filterTypes.length > 0 && 
                                        <Chip
                                            label={filterTypes.length}
                                            size="small"
                                            sx={{
                                                ml: 1,
                                                fontWeight: 700,
                                                borderRadius: 2
                                            }}
                                            />}
                                    </Typography>
                                </Button>
                                <Popper open={filterTypesOpen} anchorEl={anchorFilterTypes} placement="bottom-start">
                                    <ClickAwayListener onClickAway={closeFilterTypes}>
                                        <Box
                                            sx={{
                                                border: 1,
                                                borderColor: colors.grey.main,
                                                borderRadius: 2,
                                                background: "white"
                                            }}>
                                            <Autocomplete
                                                open
                                                multiple
                                                onClose={(
                                                    event: React.ChangeEvent<{}>,
                                                    reason: AutocompleteCloseReason,
                                                ) => {
                                                    if (reason === 'escape') {
                                                        closeFilterTypes();
                                                    }
                                                }}
                                                value={filterTypes}
                                                onChange={(event, newValue, reason) => {
                                                    if (event.type === 'keydown' &&
                                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                                        reason === 'removeOption') {
                                                        return;
                                                    }
                                                    setFilterTypes(newValue);
                                                }}
                                                disableCloseOnSelect
                                                renderTags={() => null}
                                                renderOption={(props, option, { selected }) => (
                                                <li {...props}
                                                    style={{
                                                        alignItems: "flex-start"
                                                    }}>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            width: 14,
                                                            height: 14,
                                                            flexShrink: 0,
                                                            borderRadius: '3px',
                                                            mr: 1,
                                                            mt: "6px",
                                                        }}
                                                        style={{ backgroundColor: option.color }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            flexGrow: 1
                                                        }}>
                                                        <Typography fontWeight={500}>
                                                            {option.label}
                                                        </Typography>
                                                        {option.description &&
                                                        <Typography fontWeight={500} variant="caption">
                                                            {option.description}
                                                        </Typography>}
                                                    </Box>
                                                    <Box
                                                        component={CloseIcon}
                                                        sx={{ opacity: 0.6, width: 18, height: 18,
                                                            mt: "4px", }}
                                                        style={{
                                                            visibility: selected ? 'visible' : 'hidden',
                                                        }}
                                                    />
                                                </li>
                                                )}
                                                options={enumPromotionType}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        autoFocus
                                                        size="small"
                                                        placeholder="Recherche"
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        InputProps={{
                                                            sx: {
                                                                p: 0,
                                                                m: 1,
                                                                borderRadius: 2,
                                                            }
                                                        }}
                                                    />
                                                )
                                                } />
                                        </Box>
                                    </ClickAwayListener>
                                </Popper>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        py: 1,
                                        borderColor: colors.grey.main
                                    }}
                                    endIcon={<ArrowDropDownIcon />}
                                    onClick={openFilterStatus}>
                                    <Typography
                                        variant='body1'
                                        fontWeight={500}>
                                        Statut
                                        {filterStatus.length > 0 && 
                                        <Chip
                                            label={filterStatus.length}
                                            size="small"
                                            sx={{
                                                ml: 1,
                                                fontWeight: 700,
                                                borderRadius: 2
                                            }}
                                            />}
                                    </Typography>
                                </Button>
                                <Popper open={filterStatusOpen} anchorEl={anchorFilterStatus} placement="bottom-start">
                                    <ClickAwayListener onClickAway={closeFilterStatus}>
                                        <Box
                                            sx={{
                                                border: 1,
                                                borderColor: colors.grey.main,
                                                borderRadius: 2,
                                                background: "white"
                                            }}>
                                            <Autocomplete
                                                open
                                                multiple
                                                onClose={(
                                                    event: React.ChangeEvent<{}>,
                                                    reason: AutocompleteCloseReason,
                                                ) => {
                                                    if (reason === 'escape') {
                                                        closeFilterStatus();
                                                    }
                                                }}
                                                value={filterStatus}
                                                onChange={(event, newValue, reason) => {
                                                    if (event.type === 'keydown' &&
                                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                                        reason === 'removeOption') {
                                                        return;
                                                    }
                                                    setFilterStatus(newValue);
                                                }}
                                                disableCloseOnSelect
                                                renderTags={() => null}
                                                renderOption={(props, option, { selected }) => (
                                                <li {...props}
                                                    style={{
                                                        alignItems: "flex-start"
                                                    }}>
                                                    <Box
                                                        component="span"
                                                        sx={{
                                                            width: 14,
                                                            height: 14,
                                                            flexShrink: 0,
                                                            borderRadius: '3px',
                                                            mr: 1,
                                                            mt: "6px",
                                                        }}
                                                        style={{ backgroundColor: option.color }}
                                                    />
                                                    <Box
                                                        sx={{
                                                            flexGrow: 1
                                                        }}>
                                                        <Typography fontWeight={500}>
                                                            {option.label}
                                                        </Typography>
                                                        {option.description &&
                                                        <Typography fontWeight={500} variant="caption">
                                                            {option.description}
                                                        </Typography>}
                                                    </Box>
                                                    <Box
                                                        component={CloseIcon}
                                                        sx={{ opacity: 0.6, width: 18, height: 18,
                                                            mt: "4px", }}
                                                        style={{
                                                            visibility: selected ? 'visible' : 'hidden',
                                                        }}
                                                    />
                                                </li>
                                                )}
                                                options={enumPromotionStatus}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        autoFocus
                                                        size="small"
                                                        placeholder="Recherche"
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        InputProps={{
                                                            sx: {
                                                                p: 0,
                                                                m: 1,
                                                                borderRadius: 2,
                                                            }
                                                        }}
                                                    />
                                                )
                                                } />
                                        </Box>
                                    </ClickAwayListener>
                                </Popper>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        py: 1,
                                        borderColor: colors.grey.main
                                    }}
                                    endIcon={<ArrowDropDownIcon />}
                                    onClick={openFilterOffer}>
                                    <Typography
                                        variant='body1'
                                        fontWeight={500}>
                                        Offre
                                        {filterOffer.length > 0 && 
                                        <Chip
                                            label={filterOffer.length}
                                            size="small"
                                            sx={{
                                                ml: 1,
                                                fontWeight: 700,
                                                borderRadius: 2
                                            }}
                                            />}
                                    </Typography>
                                </Button>
                                <Popper open={filterOfferOpen} anchorEl={anchorFilterOffer} placement="bottom-start">
                                    <ClickAwayListener onClickAway={closeFilterOffer}>
                                        <Box
                                            sx={{
                                                border: 1,
                                                borderColor: colors.grey.main,
                                                borderRadius: 2,
                                                background: "white"
                                            }}>
                                            <Autocomplete
                                                open
                                                multiple
                                                onClose={(
                                                    event: React.ChangeEvent<{}>,
                                                    reason: AutocompleteCloseReason,
                                                ) => {
                                                    if (reason === 'escape') {
                                                        closeFilterOffer();
                                                    }
                                                }}
                                                value={filterOffer}
                                                onChange={(event, newValue, reason) => {
                                                    if (event.type === 'keydown' &&
                                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                                        reason === 'removeOption') {
                                                        return;
                                                    }
                                                    setFilterOffer(newValue);
                                                }}
                                                disableCloseOnSelect
                                                renderTags={() => null}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}
                                                        style={{
                                                            alignItems: "flex-start"
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1
                                                            }}>
                                                            <Typography fontWeight={500}>
                                                                {option.icon && `${option.icon} `}{option.label}
                                                            </Typography>
                                                            {option.description &&
                                                            <Typography fontWeight={500} variant="caption">
                                                                {option.description}
                                                            </Typography>}
                                                        </Box>
                                                        <Box
                                                            component={CloseIcon}
                                                            sx={{ opacity: 0.6, width: 18, height: 18,
                                                                mt: "4px", }}
                                                            style={{
                                                                visibility: selected ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                    </li>
                                                )}
                                                options={enumPromotionOffer}
                                                getOptionLabel={(option) => option.label}
                                                renderInput={(params) => (
                                                    <TextField
                                                        autoFocus
                                                        size="small"
                                                        placeholder="Recherche"
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        InputProps={{
                                                            sx: {
                                                                p: 0,
                                                                m: 1,
                                                                borderRadius: 2,
                                                            }
                                                        }}
                                                    />
                                                )
                                                } />
                                        </Box>
                                    </ClickAwayListener>
                                </Popper>
                            </Box>
                            <Box>
                                <Button
                                    variant="outlined"
                                    color="inherit"
                                    sx={{
                                        py: 1,
                                        borderColor: colors.grey.main
                                    }}
                                    endIcon={<ArrowDropDownIcon />}
                                    onClick={openFilterPlateforme}>
                                    <Typography
                                        variant='body1'
                                        fontWeight={500}>
                                        Plateforme
                                        {filterPlateforme.length > 0 && 
                                        <Chip
                                            label={filterPlateforme.length}
                                            size="small"
                                            sx={{
                                                ml: 1,
                                                fontWeight: 700,
                                                borderRadius: 2
                                            }}
                                            />}
                                    </Typography>
                                </Button>
                                <Popper open={filterPlateformeOpen} anchorEl={anchorFilterPlateforme} placement="bottom-start">
                                    <ClickAwayListener onClickAway={closeFilterPlateforme}>
                                        <Box
                                            sx={{
                                                border: 1,
                                                borderColor: colors.grey.main,
                                                borderRadius: 2,
                                                background: "white"
                                            }}>
                                            <Autocomplete
                                                open
                                                multiple
                                                options={plateformes}
                                                disableCloseOnSelect
                                                value={filterPlateforme}
                                                getOptionLabel={(item) => item.name}
                                                onChange={(event, newValue, reason) => {
                                                    if (event.type === 'keydown' &&
                                                        (event as React.KeyboardEvent).key === 'Backspace' &&
                                                        reason === 'removeOption') {
                                                        return;
                                                    }
                                                    setFilterPlateforme(newValue);
                                                }}
                                                onClose={(
                                                    event: React.ChangeEvent<{}>,
                                                    reason: AutocompleteCloseReason,
                                                ) => {
                                                    if (reason === 'escape') {
                                                        closeFilterPlateforme();
                                                    }
                                                }}
                                                renderOption={(props, option, { selected }) => (
                                                    <li {...props}
                                                        style={{
                                                            alignItems: "flex-start"
                                                        }}>
                                                        <Box
                                                            sx={{
                                                                flexGrow: 1
                                                            }}>
                                                            <Typography fontWeight={500}>
                                                                {option.name}
                                                            </Typography>
                                                        </Box>
                                                        <Box
                                                            component={CloseIcon}
                                                            sx={{ opacity: 0.6, width: 18, height: 18,
                                                                mt: "4px", }}
                                                            style={{
                                                                visibility: selected ? 'visible' : 'hidden',
                                                            }}
                                                        />
                                                    </li>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        autoFocus
                                                        size="small"
                                                        placeholder="Recherche"
                                                        ref={params.InputProps.ref}
                                                        inputProps={params.inputProps}
                                                        InputProps={{
                                                            sx: {
                                                                p: 0,
                                                                m: 1,
                                                                borderRadius: 2,
                                                            }
                                                        }}
                                                    />
                                                )
                                                } />
                                        </Box>
                                    </ClickAwayListener>
                                </Popper>
                            </Box>
                            <Link underline='none' onClick={() => resetFilters()}>réinitialiser</Link>
                        </Stack>

                        <Box>
                            <Button
                                onClick={() => navigate("/bo/promotion/new")}
                                sx={{
                                    py: 1,
                                    px: 2,
                                    backgroundColor: colors.black.main,
                                    color: "white",
                                    transition: "transform 400ms",
                                    ":hover": {
                                        backgroundColor: "black",
                                        transform: "translateY(-6px)"
                                    }
                                }}>
                                Ajouter
                            </Button>
                        </Box>
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    sx={{
                    }}>
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.main,
                            background: "white"
                        }}>
                        <Grid
                            container
                            justifyContent="space-between"
                            alignItems="stretch">
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    p: 2
                                }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-between"
                                    alignItems="center"
                                    useFlexGap
                                    flexWrap="wrap"
                                    gap={2}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        id="search"
                                        name="search"
                                        value={search}
                                        onChange={(e: any) => setSearch(e.target.value)}
                                        InputProps={{
                                            placeholder: 'Recherche',
                                            startAdornment: (
                                                <InputAdornment
                                                    position="start"
                                                    sx={{
                                                        ml: "12px !important"
                                                    }}>
                                                    <SearchIcon />
                                                </InputAdornment>
                                            ),
                                            endAdornment: search && (
                                                <InputAdornment
                                                    position="end"
                                                    sx={{
                                                        mr: "4px !important"
                                                    }}>
                                                    <IconButton
                                                        size="small"
                                                        onClick={() => setSearch("")}>
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            ),
                                            sx: {
                                                borderRadius: 2
                                            }
                                        }}
                                        inputProps={{
                                            sx: {
                                                p: 1
                                            }
                                        }}
                                        sx={{
                                            maxWidth: "500px"
                                        }}/>

                                    <Typography fontWeight={500} noWrap>
                                        Afficher&nbsp;
                                        <Select
                                            value={pageSize.toString()}
                                            onChange={(event: SelectChangeEvent) => {
                                                setPageSize(parseInt(event.target.value));
                                            }}
                                            inputProps={{
                                                sx: {
                                                    px: 1,
                                                    py: 0.5,
                                                    borderRadius: 0.5
                                                }
                                            }}
                                            sx={{
                                                borderRadius: 2
                                            }}>
                                            <MenuItem value={10}>10</MenuItem>
                                            <MenuItem value={25}>25</MenuItem>
                                            <MenuItem value={100}>100</MenuItem>
                                        </Select>
                                        &nbsp;sur <b>{count}</b> résultats
                                    </Typography>
                                </Stack>
                            </Grid>
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    overflowX : "auto"
                                }}>
                                <Table
                                    stickyHeader
                                    sx={{
                                        borderTop: 1,
                                        borderColor: colors.grey.main,
                                    }}>
                                    <TableHead
                                        sx={{
                                            textTransform: "uppercase",
                                            fontWeight: 600,
                                            color: colors.black.secondary
                                        }}>
                                        <TableRow>
                                            <TableCell
                                                sx={{
                                                    py: 1,
                                                    borderRight: 1,
                                                    borderColor: colors.grey.main,
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    ID
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 1,
                                                    borderRight: 1,
                                                    borderColor: colors.grey.main,
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    TYPE
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 1,
                                                    borderRight: 1,
                                                    borderColor: colors.grey.main
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    CONTENU
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 1,
                                                    borderRight: 1,
                                                    borderColor: colors.grey.main
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    STATUT
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 1,
                                                    borderRight: 1,
                                                    borderColor: colors.grey.main,
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    OFFRES
                                                </TableSortLabel>
                                            </TableCell>
                                            <TableCell
                                                sx={{
                                                    py: 1
                                                }}>
                                                <TableSortLabel
                                                    disabled>
                                                    PLATEFORMES
                                                </TableSortLabel>
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>

                                    <TableBody>
                                    {data === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center',
                                                border: 0
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : data === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center',
                                                border: 0
                                            }}>
                                            <Typography variant="h3">❌</Typography>
                                            <Typography variant='subtitle1'>Une erreur est survenue</Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 0.5
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : data.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={6}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center',
                                                border: 0
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucun résultat
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : data.map(_ => {

                                    const type: any = enumPromotionType.find(t => t.value === _.type)
                                    const status: any = enumPromotionStatus.find(s => s.value === _.status)

                                    let before = new Date()
                                    before.setHours(23, 59, 59)
                                    let after = new Date()
                                    after.setHours(0, 0, 0)

                                    let startInDay
                                    let startInMonth
                                    let endInDay
                                    let endInMonth

                                    if (_.dateStart != null) {
                                        const date = new Date(_.dateStart)
                                        const diffTime = Math.abs(before.getTime() - date.getTime());
                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                        startInDay = diffDays;

                                        if (startInDay > 30)
                                            startInMonth = Math.floor(startInDay / 30);
                                    }
                                    if (_.dateEnd != null) {
                                        const date = new Date(_.dateEnd)
                                        const diffTime = Math.abs(after.getTime() - date.getTime());
                                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                        endInDay = diffDays;

                                        if (endInDay > 30)
                                            endInMonth = Math.floor(endInDay / 30);
                                    }

                                    return (
                                    <TableRow
                                        sx={{
                                            ":hover": {
                                                background: colors.blue.main + "10"
                                            }
                                        }}>
                                        <TableCell>
                                            <Typography>#{_.id}</Typography>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={type.label}
                                                sx={{
                                                    mr: 2,
                                                    color: 'white',
                                                    backgroundColor: type.color || colors.black.main,
                                                    fontSize: 14,
                                                    fontWeight: 700
                                                }} />
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                alignItems="center"
                                                gap={1}
                                                onClick={() => {
                                                    navigate(`/bo/promotion/${_.id}`);
                                                }}
                                                sx={{
                                                    borderRadius: 2,
                                                    cursor: "pointer",
                                                    ":hover": {
                                                        background: colors.grey.main + "60"
                                                    }
                                                }}>
                                                {_.image &&
                                                <img
                                                    src={_.image}
                                                    width="60px"
                                                    height="60px"
                                                    style={{
                                                        verticalAlign: "middle",
                                                        objectFit: "cover",
                                                        objectPosition: "center",
                                                        borderRadius: "8px"
                                                    }}>
                                                </img>}
                                                <Box
                                                    sx={{
                                                        height: "100%",
                                                        width: "100%",
                                                        maxWidth: {
                                                            sm: "300px",
                                                            xs: "200px"
                                                        },
                                                        p: 1,
                                                        textOverflow: 'ellipsis',
                                                    }}>
                                                    <Link
                                                        underline='none'
                                                        sx={{
                                                            ':hover': {
                                                                cursor: 'pointer',
                                                                color: colors.blue.main,
                                                            }
                                                        }}>
                                                        <Typography noWrap sx={{
                                                            ":hover": {fontWeight: 700}
                                                        }}>{_.title}</Typography>
                                                    </Link>
                                                    {_.description &&
                                                    <Typography
                                                        noWrap
                                                        variant="body2">
                                                        {_.description}
                                                    </Typography>}
                                                </Box>
                                            </Stack>
                                        </TableCell>

                                        <TableCell>
                                            <Typography
                                                variant="body2"
                                                fontSize="0.9rem"
                                                sx={{
                                                    mb: 0.5
                                                }}>
                                                <Chip
                                                    label={status.label.toUpperCase()}
                                                    sx={{
                                                        height: "24px",
                                                        mr: 1,
                                                        color: 'white',
                                                        backgroundColor: status.color || colors.black.main,
                                                        fontSize: 14,
                                                        fontWeight: 700
                                                    }} />
                                                {status.value === PromotionStatus.PENDING && startInDay ? <>🕑 démarre dans {startInMonth ? `${startInMonth} mois` : `${startInDay} jour(s)`}</>
                                                : status.value === PromotionStatus.IN_PROGRESS && endInDay ? <>🕑 se termine dans {endInMonth ? `${endInMonth} mois` : `${endInDay} jour(s)`}</>
                                                : status.value === PromotionStatus.EXPIRED && endInDay ?  <>🕑 depuis {endInMonth ? `${endInMonth} mois` : `${endInDay} jour(s)`}</> : ""}
                                            </Typography>
                                            <>
                                            <Typography
                                                component="span"
                                                fontWeight={500}
                                                sx={{
                                                    px: 0.5,
                                                    borderRadius: 1,
                                                    background: colors.grey.main + "60"
                                                }}>
                                                {_.dateStart ?
                                                new Date(_.dateStart).getDate().toString().padStart(2, '0')  + "/" + (new Date(_.dateStart).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.dateStart).getFullYear()
                                                : "-"}
                                            </Typography>
                                            <Typography
                                                component="span"
                                                fontWeight={500}>
                                                    &nbsp;➜&nbsp;
                                            </Typography>
                                            <Typography
                                                component="span"
                                                fontWeight={500}
                                                sx={{
                                                    px: 0.5,
                                                    borderRadius: 1,
                                                    background: colors.grey.main + "60"
                                                }}>
                                                {_.dateEnd ?
                                                new Date(_.dateEnd).getDate().toString().padStart(2, '0')  + "/" + (new Date(_.dateEnd).getMonth() + 1).toString().padStart(2, '0') + "/" + new Date(_.dateEnd).getFullYear()
                                                : "-"}
                                            </Typography>
                                            </>
                                        </TableCell>
                                        <TableCell>
                                            <Stack
                                                direction="row"
                                                useFlexGap
                                                flexWrap="wrap"
                                                spacing={1}>
                                                {(_.offers || enumPromotionOffer.map(o => o.value)).map((o, idx) => {
                                                    const offer = enumPromotionOffer.find(of => of.value === o);

                                                    if (!offer) return

                                                    return (
                                                        <Typography
                                                            component="span"
                                                            fontWeight={500}
                                                            sx={{
                                                                px: 0.5,
                                                                borderRadius: 1,
                                                                background: colors.grey.main + "60",
                                                                whiteSpace: "nowrap"
                                                            }}>
                                                            {offer.icon && `${offer.icon} `}{offer.label}
                                                        </Typography>
                                                    )
                                                })}
                                            </Stack>
                                        </TableCell>
                                        <TableCell>
                                            {!_.plateformes ? 
                                            <Typography
                                                component="span"
                                                fontWeight={500}
                                                sx={{
                                                    px: 0.5,
                                                    borderRadius: 1,
                                                    background: colors.grey.main + "60",
                                                    whiteSpace: "nowrap"
                                                }}>
                                                🚀 Toutes
                                            </Typography>
                                            :
                                            <Stack
                                                direction="row"
                                                useFlexGap
                                                flexWrap="wrap"
                                                spacing={1}>
                                                {_.plateformes.map((p, idx) => {
                                                if (idx + 1 > 6) return <></>
                                                return (
                                                    <Typography
                                                        component="span"
                                                        fontWeight={500}
                                                        sx={{
                                                            px: 0.5,
                                                            borderRadius: 1,
                                                            background: colors.grey.main + "60",
                                                            whiteSpace: "nowrap"
                                                        }}>
                                                        {p.name}
                                                    </Typography>
                                                )})}

                                                {_.plateformes.length > 6 &&
                                                <Typography
                                                    component="span"
                                                    sx={{
                                                        px: 0.5,
                                                        borderRadius: 1,
                                                        background: colors.grey.main + "60",
                                                        whiteSpace: "nowrap"
                                                    }}>
                                                    +{_.plateformes.length - 6}
                                                </Typography>
                                                }
                                                </Stack>}
                                        </TableCell>
                                    </TableRow>)})}

                                    </TableBody>
                                </Table>
                            </Grid>

                            {(data?.length !== undefined && data.length > 0) &&
                            <Grid
                                item
                                xs={12}
                                sx={{
                                    py: 1,
                                    textAlign: "center"
                                }}>
                                <Stack
                                    direction="row"
                                    justifyContent="space-around"
                                    alignItems="center">
                                    <Pagination
                                        shape="rounded"
                                        count={Math.floor(count / pageSize) + 1}
                                        page={pageNumber + 1}
                                        onChange={(event, value) => setPageNumber(value - 1)}  />
                                </Stack>
                            </Grid>}
                        </Grid>
                    </Card>
                </Grid>
            </Grid>
        </Box>
        </>
    )
}

import { defaultIconSize, IIcon } from "./settings"


export const FullScreenIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_142_6095)">
        <circle cx="15" cy="15" r="14" transform="rotate(180 15 15)" stroke={props.color} strokeWidth="2"/>
        <path d="M17 8H22V13" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M13 22L8 22L8 17" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M22 17L22 22L17 22" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8 13L8 8L13 8" stroke={props.color} strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
        </g>
        <defs>
        <clipPath id="clip0_142_6095">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

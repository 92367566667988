import { defaultIconStepSize, IIcon } from "./settings"


export const StepOfferIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconStepSize.width}
            height={props.height ? props.height : defaultIconStepSize.height}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M40 1.03513e-05C62.0914 9.38564e-06 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 -7.8281e-07 62.0914 -1.74846e-06 40C-2.7141e-06 17.9086 17.9086 1.13169e-05 40 1.03513e-05Z" fill={props.color}/>
        <mask id="mask0_26_907" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
        <ellipse cx="40" cy="40" rx="40" ry="40" transform="rotate(-90 40 40)" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_26_907)">
        <path d="M6.60872 42.0008L-3.3134 64.5069C-3.59358 65.1424 -3.73828 65.8294 -3.73828 66.5239V70.0919C-3.73828 72.8284 -1.53848 75.0565 1.19779 75.0915L84.5418 76.1572C88.2774 76.205 90.744 72.2883 89.087 68.94L67.0617 24.4327C65.3305 20.9345 60.4376 20.6802 58.3531 23.9801L45.9865 43.5575C43.9607 46.7647 39.2412 46.6361 37.3929 43.3235L36.6383 41.971C34.5861 38.293 29.1843 38.6448 27.6265 42.5579L26.328 45.8196C24.5967 50.1688 18.3731 49.967 16.9271 45.5148L15.9393 42.4733C14.5212 38.1067 8.46079 37.7998 6.60872 42.0008Z" fill="url(#paint0_linear_26_907)"/>
        <path d="M87.6552 56.0276L94.3939 68.6081C94.761 69.2935 94.9531 70.0589 94.9531 70.8364C94.9531 73.4234 92.8704 75.5282 90.2836 75.5554L32.1271 76.1674C28.2152 76.2086 25.7735 71.944 27.7894 68.5912L41.4227 45.9169C43.2393 42.8957 47.5279 42.6526 49.6743 45.4492L57.7145 55.9253C59.4759 58.2203 62.9673 58.1175 64.5907 55.7229C66.424 53.0185 70.4979 53.3149 71.9205 56.2561L72.1669 56.7654C73.7802 60.101 78.5908 59.9213 79.9509 56.4747C81.2804 53.1052 85.9448 52.8345 87.6552 56.0276Z" fill="url(#paint1_linear_26_907)"/>
        </g>
        <g filter="url(#filter0_bd_26_907)">
        <path fillRule="evenodd" clipRule="evenodd" d="M45.9619 24.6729C45.9619 23.4342 44.9576 22.4299 43.7189 22.4299H37.0284C35.7897 22.4299 34.7855 23.4342 34.7855 24.6729V33.2903C34.7855 34.5291 33.7812 35.5333 32.5425 35.5333H23.9251C22.6863 35.5333 21.6821 36.5375 21.6821 37.7763V44.4667C21.6821 45.7055 22.6864 46.7097 23.9251 46.7097H32.5425C33.7812 46.7097 34.7855 47.7139 34.7855 48.9527V57.5701C34.7855 58.8089 35.7897 59.8131 37.0284 59.8131H43.7189C44.9576 59.8131 45.9619 58.8089 45.9619 57.5701V48.9527C45.9619 47.7139 46.9661 46.7097 48.2049 46.7097H56.8223C58.0611 46.7097 59.0653 45.7055 59.0653 44.4667V37.7763C59.0653 36.5375 58.0611 35.5333 56.8223 35.5333H48.2049C46.9661 35.5333 45.9619 34.5291 45.9619 33.2903V24.6729Z" fill="white"/>
        </g>
        <g filter="url(#filter1_bd_26_907)">
        <path fillRule="evenodd" clipRule="evenodd" d="M43.7189 23.1776H37.0284C36.2026 23.1776 35.5331 23.8471 35.5331 24.6729V33.2903C35.5331 34.942 34.1942 36.281 32.5425 36.281H23.9251C23.0993 36.281 22.4298 36.9505 22.4298 37.7763V44.4667C22.4298 45.2926 23.0993 45.9621 23.9251 45.9621H32.5425C34.1942 45.9621 35.5331 47.301 35.5331 48.9527V57.5701C35.5331 58.396 36.2026 59.0654 37.0284 59.0654H43.7189C44.5447 59.0654 45.2142 58.396 45.2142 57.5701V48.9527C45.2142 47.301 46.5532 45.9621 48.2049 45.9621H56.8223C57.6482 45.9621 58.3176 45.2926 58.3176 44.4667V37.7763C58.3176 36.9505 57.6482 36.281 56.8223 36.281H48.2049C46.5532 36.281 45.2142 34.942 45.2142 33.2903V24.6729C45.2142 23.8471 44.5447 23.1776 43.7189 23.1776ZM43.7189 22.4299C44.9576 22.4299 45.9619 23.4342 45.9619 24.6729V33.2903C45.9619 34.5291 46.9661 35.5333 48.2049 35.5333H56.8223C58.0611 35.5333 59.0653 36.5375 59.0653 37.7763V44.4667C59.0653 45.7055 58.0611 46.7097 56.8223 46.7097H48.2049C46.9661 46.7097 45.9619 47.7139 45.9619 48.9527V57.5701C45.9619 58.8089 44.9576 59.8131 43.7189 59.8131H37.0284C35.7897 59.8131 34.7855 58.8089 34.7855 57.5701V48.9527C34.7855 47.7139 33.7812 46.7097 32.5425 46.7097H23.9251C22.6864 46.7097 21.6821 45.7055 21.6821 44.4667V37.7763C21.6821 36.5375 22.6863 35.5333 23.9251 35.5333H32.5425C33.7812 35.5333 34.7855 34.5291 34.7855 33.2903V24.6729C34.7855 23.4342 35.7897 22.4299 37.0284 22.4299H43.7189Z" fill="url(#paint2_linear_26_907)" shapeRendering="crispEdges"/>
        </g>
        <path d="M38.1309 5.23364L38.4165 6.23433L39.4259 5.98131L38.7021 6.72897L39.4259 7.47663L38.4165 7.22361L38.1309 8.2243L37.8453 7.22361L36.8359 7.47663L37.5598 6.72897L36.8359 5.98131L37.8453 6.23433L38.1309 5.23364Z" fill="white"/>
        <path d="M8.59823 32.8972L9.70889 35.8987L12.7104 37.0094L9.70889 38.12L8.59823 41.1215L7.48758 38.12L4.48608 37.0094L7.48758 35.8987L8.59823 32.8972Z" fill="white"/>
        <path d="M53.4581 8.22424L54.5687 11.2257L57.5702 12.3364L54.5687 13.447L53.4581 16.4485L52.3474 13.447L49.3459 12.3364L52.3474 11.2257L53.4581 8.22424Z" fill="white"/>
        <path d="M70.6544 42.6168L71.765 45.6183L74.7665 46.729L71.765 47.8396L70.6544 50.8411L69.5437 47.8396L66.5422 46.729L69.5437 45.6183L70.6544 42.6168Z" fill="white"/>
        <defs>
        <filter id="filter0_bd_26_907" x="1.68213" y="6.42993" width="77.3831" height="77.3832" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_26_907"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_26_907" result="effect2_dropShadow_26_907"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_26_907" result="shape"/>
        </filter>
        <filter id="filter1_bd_26_907" x="1.68213" y="6.42993" width="77.3831" height="77.3832" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_26_907"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_26_907" result="effect2_dropShadow_26_907"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_26_907" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_26_907" x1="44.486" y1="16.4486" x2="44.486" y2="76.2617" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_26_907" x1="59.0653" y1="39.6261" x2="59.0653" y2="76.2616" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint2_linear_26_907" x1="21.6821" y1="43.3467" x2="59.0653" y2="43.3467" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0.4"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

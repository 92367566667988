import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import WarningIcon from '@mui/icons-material/Warning';

import { colors } from "../../static/themes/gsmc/colors";


interface Props {
    title: string;
    description?: string;
}


export const CardError = (props: Props) => {


    return (
        <Grid
            container
            direction="column"
            alignItems='center'
            justifyContent='center'
            sx={{
                height: '100%',
                minHeight: '150px'
            }}>
            <Grid
                item>
                <WarningIcon
                    fontSize="large"
                    sx={{
                        color: colors.red.main
                    }} />
            </Grid>
            <Grid
                item>
                <Typography
                    variant='subtitle1'>
                    {props.title}
                </Typography>
            </Grid>
            <Grid
                item>
                <Typography
                    variant='body2'
                    sx={{
                        mt: 1
                    }}>
                    {props.description}
                </Typography>
            </Grid>
        </Grid>
    )
}

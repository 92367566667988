import { IIcon } from "./settings"


const defaultWidth: string = "325";
const defaultHeight: string = "225";


export const ContactPhoneBackgroundIcon = (props: IIcon) => {

    return (
        <svg 
            width={props.width ? props.width : defaultWidth}
            height={props.height ? props.height : defaultHeight}
            viewBox="0 0 317 218"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_1_6" maskUnits="userSpaceOnUse" x="-8" y="-7" width="325" height="225">
        <rect x="-8" y="-7" width="325" height="225" fill="white"/>
        </mask>
        <g mask="url(#mask0_1_6)">
        <path d="M38.2113 85.1948L3.6951 212.674C3.2337 214.378 3 216.135 3 217.901V239.642C3 250.588 11.7984 259.5 22.7429 259.641L413.405 264.664C428.328 264.856 438.196 249.226 431.606 235.835L325.866 20.9651C318.954 6.9188 299.313 5.901 290.985 19.1575L228.755 118.226C220.661 131.111 201.713 130.596 194.331 117.29L178.239 88.2887C171.659 76.4288 155.432 74.4194 146.158 84.316L129.784 101.787C122.377 109.691 110.024 110.262 101.92 103.073L70.7876 75.4595C59.6812 65.6082 42.0913 70.8648 38.2113 85.1948Z" fill="url(#paint0_linear_1_6)"/>
        <path d="M347.737 138.893L378.033 202.057C379.328 204.757 380 207.712 380 210.707V215.448C380 226.402 371.188 235.318 360.235 235.446L94.932 238.564C79.696 238.744 69.8635 222.501 77.087 209.085L143.366 85.9806C150.461 72.8039 168.983 71.8007 177.459 84.1341L214.394 137.878C221.903 148.804 238.185 148.344 245.065 137.01C252.759 124.336 271.55 125.624 277.443 139.229L278.619 141.945C285.358 157.503 307.678 156.732 313.327 140.745C318.86 125.088 340.555 123.919 347.737 138.893Z" fill="url(#paint1_linear_1_6)"/>
        <g filter="url(#filter0_bd_1_6)">
        <path d="M91 202.118C91 167.467 119.09 139.377 153.741 139.377V139.377C188.392 139.377 216.483 167.467 216.483 202.118V219.283H91V202.118Z" fill={props.color}/>
        </g>
        <g filter="url(#filter1_bd_1_6)">
        <circle cx="153.953" cy="95.9532" r="39.9532" fill={props.color}/>
        </g>
        </g>
        <defs>
        <filter id="filter0_bd_1_6" x="61" y="113.377" width="185.483" height="139.906" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_6"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="15"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_1_6" result="effect2_dropShadow_1_6"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_6" result="shape"/>
        </filter>
        <filter id="filter1_bd_1_6" x="94" y="40" width="119.906" height="119.906" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1_6"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_1_6" result="effect2_dropShadow_1_6"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1_6" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_1_6" x1="224.5" y1="-11.1843" x2="224.5" y2="265.083" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFCF8" stopOpacity="0.7"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_1_6" x1="220.5" y1="57.1292" x2="220.5" y2="238.963" gradientUnits="userSpaceOnUse">
        <stop stopColor="#FDFCF8" stopOpacity="0.7"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

import { getTarifContext } from "../api/Tarification.api";
import { IPerson } from "../model/Person.model";
import { ITarificationBeneficiariesDTO, ITarificationContextDTO } from "../model/Tarification";
import { enumHrRoleConjoint, enumHrRoleSubscriber } from "../utils";


export const getTarificationFromContext = (
    uuid: string,
    codeOffer: string,
    date: Date,
    postalCode: string,
    beneficiaries: IPerson[]) => {

    let sub: IPerson = beneficiaries.find(_ => _.role === enumHrRoleSubscriber) || beneficiaries[0]

    let payload: ITarificationContextDTO = {
        uuid: uuid,
        codeOffre: codeOffer,
        dateStart: date,
        adress: {
            postalCode: postalCode
        },
        beneficiaires: beneficiaries.map(_ => {
            return {
                lienDeParente: _.role === enumHrRoleSubscriber ? "AF" : _.role === enumHrRoleConjoint ? "CO" : "EN",
                acceptationEdi: true,
                civilite: "01",
                regimeSocial: "00" + sub.regime,
                dateDeNaissance: _.dateBirth
            } as ITarificationBeneficiariesDTO
        })
    }

    return getTarifContext(payload)
}

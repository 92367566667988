import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { Theme, ThemeProvider } from "@mui/material/styles";


interface Props {
    theme: Theme;
    header?: JSX.Element;
    content: JSX.Element;
    footer?: JSX.Element;
}


export const PageLayout = (props: Props) => {
    return (
        <ThemeProvider theme={props.theme}>
            <Grid
                container
                direction="column"
                justifyContent="space-between"
                alignItems="stretch"
                sx={{
                    minHeight: "100vh"
                }}>
                <Grid
                    item>
                    <Grid
                        container
                        gap={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                position: "sticky",
                                top: 0,
                                zIndex: 1000
                            }}>
                            {props.header}
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                width: '100%',
                                mx: 'auto'
                            }}>
                            {props.content}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item>
                </Grid>

                <Grid
                    item
                    sx={{
                        mb: 2,
                        px: 2,
                        mt: 10
                    }}>
                    <Box
                        sx={{
                            maxWidth: '1200px',
                            mx: 'auto'
                        }}>
                        {props.footer}
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    )
  }

import { defaultIconSize, IIcon } from "./settings"


export const ArrowRightIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="15" cy="15" r="14" transform="rotate(180 15 15)" stroke={props.color} strokeWidth="2"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M17.2557 7.8717C16.9106 7.51119 16.3512 7.51119 16.0061 7.8717C15.6611 8.23222 15.6611 8.81672 16.0061 9.17724L20.9169 14.067L6.01061 14.067C5.30966 14.067 4.74143 14.4976 4.74143 15.0287C4.74143 15.5599 5.30966 15.9904 6.01061 15.9904L21.2068 15.9904L16.0061 21.4241C15.6611 21.7846 15.6611 22.3691 16.0061 22.7297C16.3512 23.0902 16.9106 23.0902 17.2557 22.7297L23.7413 15.9534C24.0864 15.5929 24.0864 15.0084 23.7413 14.6479L17.2557 7.8717Z" fill={props.color}/>
        </svg>
    )
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const NeedAudioIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <path d="M7.37524 14.5784C7.37524 14.5784 7.26285 1.99997 19.0552 2C30.8475 2.00003 34.6575 13.8207 29.3874 19.9691C26.6921 23.1137 24.8975 23.7355 23.9748 26.4563C23.1538 28.8771 24.4459 30.7506 23.9748 33.0036C23.3582 35.9527 20.6223 37.6085 17.6251 37.3685C14.3231 37.1041 11.8641 34.321 12.0011 31.003" stroke={props.color} strokeWidth="2" strokeLinecap="round"/>
        <path d="M26.5423 14.4C26.5423 14.4 26.5423 7.09119 19.7682 7.09119C14.6084 7.09119 13.2754 11.3318 12.9331 13.3528C12.8372 13.9189 13.2996 14.3943 13.8682 14.4739C15.5163 14.7047 18.5204 15.6399 18.5204 19.3915C18.5204 24.5611 12.8159 24.5611 12.8159 24.5611" stroke={props.color} strokeWidth="2" strokeLinecap="round"/>
        </svg>
    )
}

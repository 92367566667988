import { defaultIconStepSize, IIcon } from "./settings"


export const OfferLeafIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconStepSize.width}
            height={props.height ? props.height : defaultIconStepSize.height}
            viewBox="0 0 75 75"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M12.1504 26.7363L13.489 30.3538L17.1064 31.6924L13.489 33.031L12.1504 36.6484L10.8118 33.031L7.19434 31.6924L10.8118 30.3538L12.1504 26.7363Z" fill={props.color}/>
            <path d="M62.5 3L63.9855 7.0145L68 8.5L63.9855 9.9855L62.5 14L61.0145 9.9855L57 8.5L61.0145 7.0145L62.5 3Z" fill={props.color}/>
            <path d="M43.2382 66.791L44.2117 69.4219L46.8426 70.3954L44.2117 71.3689L43.2382 73.9998L42.2647 71.3689L39.6338 70.3954L42.2647 69.4219L43.2382 66.791Z" fill={props.color}/>
            <path d="M61.5964 52.1182C55.3631 39.1905 38.8473 33.0373 24.7791 38.4031L22.3101 39.3442L23.4043 41.6134C26.4231 47.8761 31.9153 52.7516 38.8691 55.3417C45.8235 57.9327 53.4055 57.9277 60.2211 55.3286L62.6901 54.3871L61.5964 52.1182Z" fill={props.color}/>
            <path d="M63.0825 50.9928C63.3747 37.2414 51.3692 25.5009 36.1735 24.7584L33.488 24.627L33.4073 27.0619C33.3188 29.7218 33.6942 32.3083 34.4621 34.7584C46.3014 34.3879 57.8879 40.5564 63.0825 50.9928Z" fill={props.color}/>
            <path d="M42.7426 23.8454C48.2002 25.1749 53.1665 27.8912 57.1079 31.7835C61.9418 36.5571 64.7104 42.5746 65.0529 48.9175C66.6371 43.2072 65.9535 37.1862 63.0504 31.747C59.8013 25.6578 54.1691 21.028 47.1912 18.7112L44.6627 17.8718L43.7991 20.1523C43.3369 21.3734 42.9866 22.6077 42.7426 23.8454Z" fill={props.color}/>
        </svg>
    )
}

import React, {useEffect, useState} from 'react';

import {useFormik} from 'formik';
import * as yup from 'yup';

import LoadingButton from "@mui/lab/LoadingButton";
import {CircularProgress} from "@mui/material";
import Grid from '@mui/material/Grid';
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import {colors} from '../../static/themes/gsmc/colors';
import {messageFieldRequired} from "../../utils";
import {ITarificationPerson, ITarificationsControlResponse} from "../../model/Tarification";
import {convertHennerContext, postTarificationControl} from "../../api/Tarification.api";

interface Props {
    setResultTarification: React.Dispatch<React.SetStateAction<ITarificationsControlResponse | undefined>>
    setChildrenBirthDates: React.Dispatch<React.SetStateAction<(Date | undefined)[]>>
}


export const FormBackOfficeTarificationTextArea = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = yup.object({
        text: yup.string().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues: {
            text: undefined,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setError(false);
            setLoading(true);
            props.setResultTarification(undefined);
            if (values.text === undefined) {
                setError(true);
                return;
            }

            let request = undefined;
            const type = getInputType(values.text);
            if (type === 'xml') {
                convertHennerContext({xmlRequest: values.text})
                    .then(response => {
                        request = response;
                        if(request.children) {
                            props.setChildrenBirthDates(request.children.map((child: ITarificationPerson) => child.birthDate));
                        }
                        postTarificationControl(request)
                            .then((response: ITarificationsControlResponse) => {
                                if (!response.tarifications) throw new Error()

                                props.setResultTarification(response);
                                setSuccess(true);
                                setLoading(false);

                            })
                            .catch(exception => {
                                console.error(exception);
                                setError(true);
                                setLoading(false);
                            });
                    })
                    .catch(exception => {
                        console.error(exception);
                        setError(true);
                    });
            }
            else {
                request = JSON.parse(values.text);
                props.setChildrenBirthDates(request?.children?.map((child: ITarificationPerson) => child.birthDate));
                postTarificationControl(request)
                    .then((response: ITarificationsControlResponse) => {
                        if (!response.tarifications) throw new Error()
                        props.setResultTarification(response);
                        setSuccess(true);
                        setLoading(false);

                    })
                    .catch(exception => {
                        console.error(exception);
                        setError(true);
                        setLoading(false);
                    });
            }
            }
    });

    function getInputType(input: string): string  {
        try {
            //try to parse via json
            JSON.parse(input);
            return 'json';
        } catch(e) {
            //try xml parsing
            let parser = new DOMParser();
            var xmlDoc = parser.parseFromString(input,"application/xml");
            if (xmlDoc.documentElement.nodeName === "parsererror")
                return 'error';
            else
                return 'xml';
        }
    }

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={4}
                p={2}
                sx={{
                    textAlign: 'left'
                }}>


                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }}>
                            <Typography
                                variant='h3'>
                                Paramètres de tarification
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                multiline={true}
                                id="text"
                                name="text"
                                label={"Contenu de la requête *"}
                                value={formik.values.text}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.text && Boolean(formik.errors.text)}
                                helperText={formik.touched.text && formik.errors.text}>
                                rows={4}
                            </TextField>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid
                    item
                    p={2}
                    xs={12}>
                    <LoadingButton
                        type='submit'
                        loading={loading}
                        loadingIndicator={
                            <Stack direction="row" alignItems="center" gap={1}>
                                <CircularProgress/>
                                <Typography
                                    noWrap
                                    sx={{
                                        textAlign: 'center',
                                        color: 'white'
                                    }}>
                                    Tarification en cours...
                                </Typography>
                            </Stack>
                        }
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }}>
                        Calculer les tarifs
                    </LoadingButton>
                    {error &&
                        <Typography variant={"h3"} p={2}> ⚠️ Une erreur technique est apparue lors du calcul de la tarification</Typography>
                    }
                </Grid>
            </Grid>
        </form>
    )
}

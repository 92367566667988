import { IContractEstimation, IContractEstimationDTO } from "../model/ContractEstimation.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel/contract/estimation'


export const createContractEstimation = (contract: IContractEstimationDTO) : Promise<IContractEstimation> => {

    return fetch(`${apiUrl}`,
    {
        method: "POST",
        headers: {"content-type": "application/json"},
        body: JSON.stringify(contract)
    })
    .then(_ => _.json()) 
}

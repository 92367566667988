import { IIcon } from "./settings"


const defaultWidth: string = "300";
const defaultHeight: string = "200";


export const ContactMailBackgroundIcon = (props: IIcon) => {

    return (
        <svg 
            width={props.width ? props.width : defaultWidth}
            height={props.height ? props.height : defaultHeight}
            viewBox="0 0 267 193"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g filter="url(#filter0_bd_295_3741)">
        <rect x="95" y="86.8025" width="134.51" height="89.3898" rx="14" transform="rotate(-17.6584 95 86.8025)" fill={props.color}/>
        </g>
        <g filter="url(#filter1_d_295_3741)">
        <path d="M99.2468 100.143C96.9013 92.7752 100.973 84.9011 108.34 82.5557L209.832 50.2467C217.2 47.9013 225.074 51.9726 227.419 59.3403L227.664 60.1102C229.265 65.1389 227.902 70.6405 224.139 74.3403L181.212 116.544C178.261 119.445 174.105 120.768 170.02 120.107L110.595 110.486C105.385 109.643 101.093 105.941 99.4918 100.913L99.2468 100.143Z" fill={props.color}/>
        </g>
        <path d="M234 46L238.321 57.3136L250 61.5L238.321 65.6864L234 77L229.679 65.6864L218 61.5L229.679 57.3136L234 46Z" fill="white"/>
        <path d="M155 17L157.161 22.8393L163 25L157.161 27.1607L155 33L152.839 27.1607L147 25L152.839 22.8393L155 17Z" fill="white"/>
        <path d="M163 154L167.321 165.679L179 170L167.321 174.321L163 186L158.679 174.321L147 170L158.679 165.679L163 154Z" fill="white"/>
        <defs>
        <filter id="filter0_bd_295_3741" x="78.5833" y="33.5834" width="188.122" height="158.814" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feGaussianBlur in="BackgroundImage" stdDeviation="3.5"/>
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_295_3741"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="effect1_backgroundBlur_295_3741" result="effect2_dropShadow_295_3741"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_295_3741" result="shape"/>
        </filter>
        <filter id="filter1_d_295_3741" x="78.5833" y="33.5834" width="169.741" height="110.69" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3741"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3741" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_295_3741" x1="94.5" y1="26" x2="94.5" y2="163" gradientUnits="userSpaceOnUse">
        <stop stopColor="white"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

import {IContractSignature} from "../model/ContractSignature.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const sendContractSignatureSms = (uuid: String, reset: boolean): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/form/${uuid}/contract/sign?reset=${reset}`,
        {
            method: "PUT",
        })
        .then(_ => {
            if (_.status / 100 >= 4) throw _.json();
            return _.json();
        })
}


export const validateContractSignature = (uuid: String, code: String): Promise<IContractSignature> => {

    return fetch(`${apiUrl}/form/${uuid}/contract/signature/validate?code=${code}`,
        {
            method: "PUT",
        })
        .then(_ => {
            if (_.status / 100 >= 4) throw _.json();
            return _.json();
        })
}


export const sendContractSignatureLink = (uuid: string, email: string): Promise<void> => {
    return fetch(`${apiUrl}/contract/form/${uuid}/signature/mail/${email}`,
        {
            method: "POST",
            headers: {"content-type": "application/json"},
        }).then(_ => {
        if (_.status === 200) return;
        else throw _.json()
    })
}

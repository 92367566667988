import { colors } from "../static/themes/colors";


export const formulesStyles = [
    // Offer 512.
    {
        id: 18642,
        description: "Essentiel",
        coverPercentage: 10,
        color: colors.green.main
    },
    {
        id: 18643,
        description: "Standard",
        coverPercentage: 25,
        color: colors.blue.main
    },
    {
        id: 18644,
        description: "Confort",
        coverPercentage: 50,
        color: colors.purple.main
    },
    {
        id: 18645,
        description: "Optimale",
        coverPercentage: 75,
        color: colors.orange.main
    },
    {
        id: 18646,
        description: "Extra",
        coverPercentage: 100,
        color: colors.pink.main
    },
    // Offer 312.
    {
        id: 11899,
        description: "Essentiel",
        coverPercentage: 10,
        color: colors.green.main
    },
    {
        id: 11900,
        description: "Standard",
        coverPercentage: 25,
        color: colors.blue.main
    },
    {
        id: 11901,
        description: "Confort",
        coverPercentage: 50,
        color: colors.purple.main
    },
    {
        id: 11902,
        description: "Optimale",
        coverPercentage: 75,
        color: colors.orange.main
    },
    {
        id: 11903,
        description: "Supérieure",
        coverPercentage: 85,
        color: colors.pink.main
    },
    {
        id: 11904,
        description: "Extra",
        coverPercentage: 100,
        color: colors.red.main
    },
    // Offer 539.
    {
        id: 19036,
        description: "Essentiel",
        coverPercentage: 10,
        color: colors.green.main
    },
    {
        id: 19026,
        description: "Standard",
        coverPercentage: 25,
        color: colors.blue.main
    },
    {
        id: 19027,
        description: "Confort",
        coverPercentage: 50,
        color: colors.purple.main
    },
    {
        id: 19028,
        description: "Optimale",
        coverPercentage: 75,
        color: colors.orange.main
    },
    {
        id: 19029,
        description: "Supérieure",
        coverPercentage: 85,
        color: colors.pink.main
    },
    {
        id: 19030,
        description: "Extra",
        coverPercentage: 100,
        color: colors.red.main
    }
]


export interface IFormuleDetails {
    formule: IFormule
    description: string
    coverPercentage: number
    color: string
    isRecommended?: Boolean
}


export interface IFormule {
    identifiant: number
    dateStart?: Date
    type?: string
    formuleLibelle?: string
    tarif?: number
    beneficiaires?: IBeneficiaire[]
    renforts?: IFormule[]
    adress?: IAddress
}


export interface IAddress {
    adressLine1?: string,
    adressLine2?: string,
    adressLine3?: string,
    postalCode: string,
    city?: string,
    country?: string
}


export interface IBeneficiaire {
    acceptationEdi: boolean
    caisse?: string
    centre?: string
    civilite?: string
    dateDeNaissance: Date
    lienDeParente: string
    nni?: string
    nom?: string
    prenom?: string
    rangJumeau?: string
    regimeSocial?: string
    statut?: string
    statutTns?: string
    tarif: number
}


export const getFormuleDetails = (formule: IFormule, plateformStyle: any) => {
    let formuleStyle;
    let formuleStylePlateform = plateformStyle.components.Formules.colors.find((_: any) => _.id === formule.identifiant);

    formuleStyle = formulesStyles.find((_) => _.id === formule.identifiant);

    return {
        formule: formule,
        description: formuleStyle?.description || "",
        coverPercentage: formuleStyle?.coverPercentage || 50,
        color: formuleStylePlateform?.color || plateformStyle.components.Formules.defaultColor
    };
}


import { forwardRef, Ref, useEffect, useImperativeHandle, useState } from 'react';

import * as yup from 'yup';
import { useFormik, FormikErrors } from 'formik'

import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import TextField from '@mui/material/TextField';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { FieldLabel } from '../Field/FieldLabel';
import { TextFieldNIR, pattern as patternNIR, separator as separatorNIR } from '../Field/TextFieldNIR';
import { TextFieldPhone } from '../Field/TextFieldPhone';
import { TextFieldFormatted } from '../Field/TextFieldFormatted';
import { formatString } from '../Field/utils';
import { FormRadioButton } from './FormRadioButton';
import { IPerson } from '../../model/Person.model';

import {
    enumGsmcRegimes,
    enumHrCivilities,
    enumHrCivitityMadam,
    enumHrCivitityMister,
    enumHrRoleChild,
    enumHrRoleConjoint,
    enumHrRoleSubscriber,
    enumHrSexes,
    enumHrSexMan,
    enumHrSexWoman,
    messageFieldRequired
} from '../../utils';
import { convertUTCDateToLocalDate } from '../../utils/dateUtils';
import { checkPersonPhone, getPerson } from '../../api/Person.api';
import { Link } from '@mui/material';
import { DialogContact } from '../Dialog/DialogContact';
import { IForm } from '../../model/Form.model';
import { ICallback } from '../../model/Callback.model';


interface Props {
    plateformStyle: any;
    person: IPerson;
    persons?: IPerson[];
    form?: IForm
    resetForm?: number;
    isControlFraud?: boolean
    send: (person: IPerson) => void;
    save: (person: IPerson) => void;
    sendProgress?: (progress: number) => void;
}


interface FormValues {
    sex?: string | undefined;
    civility?: string | undefined;
    lastname: string | undefined;
    firstname: string | undefined;
    birthDate: Date | null;
    phone?: string | undefined;
    email?: string | undefined;
    regime?: { label: string; value: string; } | undefined;
    nir?: string | undefined;
}


const today = new Date();

const dateBirthMin = new Date(today);
dateBirthMin.setFullYear(dateBirthMin.getFullYear() - 200);

const dateBirthMaxConjoint = new Date(today);
dateBirthMaxConjoint.setFullYear(dateBirthMaxConjoint.getFullYear() - 18);

const dateBirthMinSubscriber = new Date(today);
dateBirthMinSubscriber.setFullYear(dateBirthMinSubscriber.getFullYear() - 18);

const dateBirthMinChild = new Date(today);
dateBirthMinChild.setFullYear(dateBirthMinChild.getFullYear() - 28);

// Get the validation schema according to the person role.
const getFormFields = (person: IPerson) => {
    switch (person.role) {
        case (enumHrRoleSubscriber):
        default:
            return ['civility', 'lastname', 'firstname', 'birthDate', 'phone', 'email', 'nir'];

        case (enumHrRoleConjoint):
            return ['civility', 'lastname', 'firstname', 'birthDate', 'nir'];

        case (enumHrRoleChild):
            return ['civility', 'lastname', 'firstname', 'birthDate'];
    };
};


export const FormPerson = forwardRef((props: Props, ref: Ref<any>) => {

    const plateformStyle: any = props.plateformStyle;
    const radioButtonStyle: any = plateformStyle.components.RadioButton;

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();

            if (formik.isValid)
                return true;

            return false;
        },
        leave() {
            send();
        }
    }));

    const [formFields, setFormFields] = useState<string[]>(getFormFields(props.person));
    const [fieldNIRError, setFieldNIRError] = useState<string>("");
    const [lastPhoneChecked, setLastPhoneChecked] = useState<string>();
    const [isFieldPhoneAlreadyUsed, setIsFieldPhoneAlreadyUsed] = useState<boolean>(false);
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [isFormikValidatedInit, setIsFormikValidatedInit] = useState<boolean>(false);


    // Yup validation of every field.
    const yupSex = yup.string()
        .oneOf(enumHrSexes.map((e) => e.value), "Choix incorrect")
        .nullable()
        .required(messageFieldRequired);
    const yupCivility = yup.string()
        .oneOf(enumHrCivilities.map((e) => e.value), "Choix incorrect")
        .nullable()
        .required(messageFieldRequired);
    const yupLastname = yup.string()
        .nullable()
        .required(messageFieldRequired)
        .max(100, "Champ non valide (100 caractères max)");
    const yupFirstname = yup.string()
        .nullable()
        .required(messageFieldRequired)
        .max(100, "Champ non valide (100 caractères max)");
    const yupBirthDate = yup.date()
        .typeError("Date non valide")
        .nullable()
        .min(dateBirthMin, "Date non valide")
        .max(new Date(), "Date non valide")
        .required(messageFieldRequired);
    const yupPhone = yup.string()
        .trim()
        .matches(/((\+?)33|32)(\s*\d){9}\s*$/g, 'Format de téléphone non valide')
        .test('isUnique',
            `Un contrat GSMC a déjà été signé avec ce numéro`,
            value => {
                if (!props.isControlFraud) return true

                if (lastPhoneChecked === value?.replaceAll(" ", "")) return !isFieldPhoneAlreadyUsed

                if (value === undefined || value === null) return true;

                if (!value.match(/((\+?)33|32)(\s*\d){9}\s*$/g)) return true;

                if (!props.person.id) return true

                setLastPhoneChecked(value?.replaceAll(" ", ""))

                return checkPersonPhone(props.person.id, value)
                .then((response: IPerson) => {
                    if (!response.id) {
                        setIsFieldPhoneAlreadyUsed(true)
                        return false
                    }

                    setIsFieldPhoneAlreadyUsed(false)
                    setIsDialogOpened(false)
                    return true
                })
            })
        .nullable()
        .required(messageFieldRequired);
    const yupEmail = yup.string()
        .email("Email non valide")
        .nullable()
        .required(messageFieldRequired)
        .max(100, "Champ non valide (200 caractères max)");
    const yupRegime = yup.object()
        .nullable()
        .required(messageFieldRequired);
    const yupNir = yup.string()
        .nullable()
        .required(messageFieldRequired);


    // Get the validation schema according to the person role.
    const getValidationSchema = (person: IPerson) => {
    switch (person.role) {
        case (enumHrRoleSubscriber):
        default:
            return yup.object({
                civility: yupCivility,
                lastname: yupLastname,
                firstname: yupFirstname,
                birthDate: yupBirthDate,
                phone: yupPhone,
                email: yupEmail,
                nir: yupNir
            });

        case (enumHrRoleConjoint):
            return yup.object({
                civility: yupCivility,
                lastname: yupLastname,
                firstname: yupFirstname,
                birthDate: yupBirthDate,
                nir: yupNir
            });

        case (enumHrRoleChild):
            return yup.object({
                civility: yupCivility,
                lastname: yupLastname,
                firstname: yupFirstname,
                birthDate: yupBirthDate
            });
    };
    };

    const getInitialValues = () => {
        return {
            sex: props.person?.sex,
            civility: props.person?.civility,
            lastname: props.person?.lastname,
            firstname: props.person?.firstname,
            birthDate: props.person?.dateBirth ? convertUTCDateToLocalDate(props.person.dateBirth) : null,
            phone: props.person?.phoneMobile,
            email: props.person?.email,
            regime: enumGsmcRegimes.filter((e) => e.value === props.person.regime)[0],
            nir: props.person?.nir ? formatString(props.person.nir, "", { toUpperCase: true, pattern: patternNIR, separator: separatorNIR }).value : undefined
        }
    };

    // Get the person: IPerson from the form fields.
    const getPersonFromForm = () => {
        let person: IPerson = { ...props.person };

        person.civility = formik.values.civility;
        person.lastname = formik.values.lastname;
        person.firstname = formik.values.firstname;
        person.dateBirth = formik.values.birthDate ? convertUTCDateToLocalDate(formik.values.birthDate) : undefined;

        person.phoneMobile = formik.values.phone?.replace(/\s/g, "");
        if (person.phoneMobile && person.phoneMobile[0] !== "+") person.phoneMobile = "+" + person.phoneMobile;

        person.email = formik.values.email;
        person.regime = formik.values.regime?.value;
        person.nir = formik.values.nir?.replace(/\s/g, "");
        person.sex = formik.values.civility === enumHrCivitityMister ? enumHrSexMan : enumHrSexWoman;

        return person;
    };

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: getValidationSchema(props.person),
        validateOnBlur: false,
        validate: (values: FormValues) => {
            let errors: FormikErrors<FormValues> = {};

            if (fieldNIRError) {
                errors.nir = fieldNIRError;
            }

            // Check birthDate according to role.
            if (formFields.includes('birthDate') &&
                values.birthDate !== null &&
                values.birthDate !== undefined &&
                !errors.birthDate) {

                if (values.birthDate.getTime() < dateBirthMin.getTime()) {
                    errors.birthDate = "Date non valide";
                    formik.touched.birthDate = true;
                }

                switch (props.person.role) {
                    case (enumHrRoleChild): {
                        if (values.birthDate.getTime() < dateBirthMinChild.getTime()) {
                            errors.birthDate = "Votre enfant doit avoir moins de 28 ans";
                            formik.touched.birthDate = true;
                        }
                        break;
                    }

                    case (enumHrRoleConjoint): {
                        if (values.birthDate.getTime() > dateBirthMaxConjoint.getTime()) {
                            errors.birthDate = "Votre conjoint doit avoir plus de 18 ans"
                            formik.touched.birthDate = true;
                        }
                        break;
                    }

                    case (enumHrRoleSubscriber):
                    default: {
                        if (values.birthDate.getTime() > dateBirthMinSubscriber.getTime()) {
                            errors.birthDate = "Vous devez être majeur pour souscrire"
                            formik.touched.birthDate = true;
                        }
                        break;
                    }
                }
            }

            // Check NIR format.
            if (formFields.includes('nir') &&
                values.nir !== null &&
                values.nir !== undefined) {

                // Check if NIR unique.
                if (!errors.nir && props.persons && props.persons.find(_ => _.nir === values.nir?.replace(/\s/g, ""))) {
                    errors.nir = "Le n° de sécurité sociale doit être différent de celui du souscripteur"
                    formik.touched.nir = true
                }

                // DISABLED
                /*
                if (formFields.includes('civility') &&
                    values.civility !== null &&
                    values.civility !== undefined &&
                    values.nir.length > 0 &&
                    !errors.civility) {
                    if (parseInt(values.nir.substring(0, 1)) === 1 && values.civility !== enumHrCivitityMister ||
                        parseInt(values.nir.substring(0, 1)) === 2 && values.civility !== enumHrCivitityMadam) {
                        errors.civility = "La civilité ne concorde pas avec le n° de sécurité sociale"
                        formik.touched.civility = true;
                    }
                }
                */

                if (formFields.includes('birthDate') &&
                    values.birthDate !== null &&
                    values.birthDate !== undefined &&
                    values.nir.length >= 7 &&
                    !isNaN(values.birthDate.getTime()) &&
                    values.birthDate.getFullYear() >= 1900 &&
                    !errors.birthDate) {
                    if (parseInt(values.nir.substring(2, 4)) !== parseInt(values.birthDate.getFullYear().toString().substr(-2)) ||
                        parseInt(values.nir.substring(5, 7)) !== values.birthDate.getMonth() + 1) {
                        errors.birthDate = "La date de naissance ne concorde pas avec le n° de sécurité sociale"
                        formik.touched.birthDate = true;
                    }
                }
            }

            if (props.persons && formFields.includes('lastname') && formFields.includes('firstname') &&
                values.lastname !== null && values.lastname !== undefined && !errors.lastname &&
                values.firstname !== null && values.firstname !== undefined && !errors.firstname) {
                if (props.persons.find(_ => _.lastname?.toLowerCase() === values.lastname?.toLowerCase() && _.firstname?.toLowerCase() === values.firstname?.toLowerCase())) {
                    errors.firstname = "Les prénoms des bénéficiaires doivent être uniques"
                    formik.touched.firstname = true;
                }
            }

            setIsFormikValidatedInit(true)

            return errors;
        },
        onSubmit: (values) => {
            save();
        }
    });

    const save = () => {
        props.save(getPersonFromForm());
    }

    const send = () => {
        props.send(getPersonFromForm());
    }

    const sendProgress = () => {
        if (props.sendProgress)
            props.sendProgress((Object.keys(formik.values).filter(_ => getFormFields(props.person).includes(_)).length - Object.keys(formik.errors).length) / (getFormFields(props.person).length) * 100);
    }

    useEffect(() => {
        if (props.resetForm === undefined) return;

        const values: any = getInitialValues()

        if (props.person.role === enumHrRoleChild) {
            formik.setValues({...values,
                civility: values.civility ? values.civility : "",
                lastname: values.lastname ? values.lastname : "",
                firstname: values.firstname ? values.firstname : "",
                birthDate: values.birthDate ? values.birthDate : null})
            formik.setTouched({
                civility: values.civility ? true : false,
                lastname: values.lastname ? true : false,
                firstname: values.firstname ? true : false,
                birthDate: values.birthDate ? true : false
            });
        }
    }, [props.resetForm])

    useEffect(() => {
        sendProgress();
    }, [])

    useEffect(() => {
        sendProgress();
    }, [formik.values,
        formik.errors])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (!formik.touched.regime) return
        formik.validateForm().then((errors: FormikErrors<FormValues>) => {
            if (errors.regime) return;
            send();
        })
    }, [formik.values.regime])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (!formik.touched.birthDate) return
        formik.validateForm().then((errors: FormikErrors<FormValues>) => {
            if (errors.birthDate) return;
            send();
        })
    }, [formik.values.birthDate])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (props.person.role !== enumHrRoleSubscriber) return;
        formik.validateForm().then((errors: FormikErrors<FormValues>) => {
            if (errors.lastname) return;
            send();
        })
    }, [formik.values.lastname])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (props.person.role !== enumHrRoleSubscriber) return;
        formik.validateForm().then((errors: FormikErrors<FormValues>) => {
            if (errors.firstname) return;
            send();
        })
    }, [formik.values.firstname])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (props.person.role !== enumHrRoleSubscriber) return;
        if (!formik.touched.email) return
        formik.validateForm().then((errors: FormikErrors<FormValues>) => {
            if (errors.email) return;
            send();
        })
    }, [formik.values.email])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        if (props.person.role !== enumHrRoleSubscriber) return;
        // formik.validateForm().then((errors: FormikErrors<FormValues>) => {
        //     if (errors.phone) return;
        //     send();
        // })
    }, [formik.values.phone])

    useEffect(() => {
        if (!isFormikValidatedInit) return
        formik.validateForm();
    }, [formik.values.civility,
        formik.values.nir])

    // Preselect the civility from the NIR.
    useEffect(() => {
        if (formik.values.nir === undefined || formik.values.nir === null || formik.values.nir.length === 0)
            return;

        if (formik.values.civility !== undefined && formik.values.civility !== null)
            return;
        
        formik.setFieldTouched("civility")
        formik.setFieldValue("civility", formik.values.nir[0] === "1" ? enumHrCivitityMister : enumHrCivitityMadam)
    }, [formik.values.nir])


    const handleClickPhone = () => {
        const link = document.createElement('a');
        link.href = 'tel:0320476200';
        link.click();
        document.body.appendChild(link);
    }

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={3}
                sx={{ textAlign: 'left' }} >
                {formFields.includes('nir') ?
                    <Grid item xs={12} >
                        <FormLabel error={formik.touched.nir && Boolean(formik.errors.nir)} >
                            <FieldLabel label="N° de Sécurité Sociale (15 chiffres)" isRequired />
                        </FormLabel>
                        <TextFieldNIR
                            plateformStyle={props.plateformStyle}
                            id="nir"
                            fullWidth
                            value={formik.values.nir}
                            showInfo
                            onChange={(e, value, error) => {
                                setFieldNIRError(error);
                                formik.setFieldValue("nir", value);
                            }}
                            onBlur={(e) => formik.setFieldTouched("nir")}
                            error={formik.touched.nir && Boolean(formik.errors.nir)}
                            helperText={formik.touched.nir && formik.errors.nir} />
                    </Grid>
                    : null}

                {formFields.includes('sex') ?
                    <Grid item xs={12} >
                        <FormLabel error={formik.touched.sex && Boolean(formik.errors.sex)} >
                            <FieldLabel label="Sexe" isRequired />
                        </FormLabel>
                        <RadioGroup
                            row
                            aria-label="sex"
                            value={formik.values.sex}
                            onChange={formik.handleChange("sex")} >
                            {enumHrSexes.map((_, id) => (
                                <FormControlLabel key={`sex_${id}`} value={_.value} control={<Radio />} label={_.label} />
                            ))}
                        </RadioGroup>
                        <FormHelperText
                            error={formik.touched.sex && Boolean(formik.errors.sex)} >
                            {formik.touched.sex && formik.errors.sex}
                        </FormHelperText>
                    </Grid>
                    : null}

                {formFields.includes('civility') ?
                    <Grid
                        item
                        xs={12} >
                        <FormLabel error={formik.touched.civility && Boolean(formik.errors.civility)} >
                            <FieldLabel label="Civilité" isRequired />
                        </FormLabel>
                        <Grid
                            container
                            spacing={2} >
                            {
                                enumHrCivilities.map(civility => {
                                    return <Grid
                                        item
                                        sm={4}
                                        xs={12} >
                                        <FormRadioButton
                                            code={civility.value}
                                            selected={formik.values.civility === civility.value}
                                            color={radioButtonStyle.color}
                                            onClick={(value) => {
                                                formik.setFieldValue("civility", value);
                                                formik.setFieldTouched("civility");
                                            }} >
                                            {civility.label}
                                        </FormRadioButton>
                                    </Grid>
                                })
                            }
                        </Grid>
                        <FormHelperText
                            error={formik.touched.civility && Boolean(formik.errors.civility)} >
                            {formik.touched.civility && formik.errors.civility}
                        </FormHelperText>
                    </Grid>
                    : null}

                {formFields.includes('lastname') ?
                    <Grid
                        item
                        xs={12}
                        sm={6} >
                        <FormLabel error={formik.touched.lastname && Boolean(formik.errors.lastname)} >
                            <FieldLabel label="Nom" isRequired />
                        </FormLabel>
                        <TextFieldFormatted
                            id="lastname"
                            fullWidth
                            toUpperCase
                            charactersEnabled="[^A-zÀ-ú\-\s]"
                            textLength={100}
                            value={formik.values.lastname}
                            onChange={(e, value) => formik.setFieldValue("lastname", value)}
                            onBlur={(e) => formik.setFieldTouched("lastname")}
                            error={formik.touched.lastname && Boolean(formik.errors.lastname)}
                            helperText={formik.touched.lastname && formik.errors.lastname} />
                    </Grid>
                    : null}

                {formFields.includes('firstname') ?
                    <Grid
                        item
                        xs={12}
                        sm={6} >
                        <FormLabel error={formik.touched.firstname && Boolean(formik.errors.firstname)} >
                            <FieldLabel label="Prénom" isRequired />
                        </FormLabel>
                        <TextFieldFormatted
                            id="firstname"
                            fullWidth
                            capitalize
                            charactersEnabled="[^A-zÀ-ú\-\s]"
                            textLength={100}
                            value={formik.values.firstname}
                            onChange={(e, value) => formik.setFieldValue("firstname", value)}
                            onBlur={(e) => formik.setFieldTouched("firstname")}
                            error={formik.touched.firstname && Boolean(formik.errors.firstname)}
                            helperText={formik.touched.firstname && formik.errors.firstname} />
                    </Grid>
                    : null}

                {formFields.includes('birthDate') &&
                <>
                <Grid
                    item
                    xs={12}
                    sm={6}>
                    <FormLabel error={formik.touched.birthDate && Boolean(formik.errors.birthDate)} >
                        <FieldLabel label="Date de naissance" isRequired />
                    </FormLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            disabled={props.person.role === enumHrRoleSubscriber}
                            value={formik.values.birthDate}
                            onChange={(value: any) => formik.setFieldValue("birthDate", value)}
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            maxDate={new Date()}
                            format='dd/MM/yyyy'
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    sx: {
                                        width: '100%'
                                    },
                                    onBlur: (e: any) => formik.setFieldTouched("birthDate"),
                                    error: formik.touched.birthDate && Boolean(formik.errors.birthDate),
                                    helperText: formik.touched.birthDate && <>{formik.errors.birthDate}</>,
                                },
                            }} />
                    </LocalizationProvider>
                </Grid>

                <Grid
                    item
                    sm={6}
                    sx={{
                        display: {
                            sm: 'flex',
                            xs: "none"
                        }
                    }}>
                </Grid>
                </>}

                {formFields.includes('phone') ?
                    <Grid
                        item
                        xs={12}
                        sm={6} >
                        <FormLabel error={(formik.touched.phone || isFieldPhoneAlreadyUsed) && Boolean(formik.errors.phone)} >
                            <FieldLabel label="Téléphone" isRequired />
                        </FormLabel>
                        <TextFieldPhone
                            id="phone"
                            fullWidth
                            value={formik.values.phone}
                            onChange={(value) => formik.setFieldValue("phone", value)}
                            onBlur={(e) => formik.setFieldTouched("phone")}
                            error={(formik.touched.phone || isFieldPhoneAlreadyUsed) && Boolean(formik.errors.phone)}
                            helperText={(formik.touched.phone || isFieldPhoneAlreadyUsed) && formik.errors.phone} />

                        {isFieldPhoneAlreadyUsed && formik.values.phone?.match(/((\+?)33|32)(\s*\d){9}\s*$/g) &&
                        <>
                        <FormHelperText>
                            Besoin d'aide ? <Link onClick={() => setIsDialogOpened(true)}>Contactez nos équipes !</Link>
                        </FormHelperText>

                        <DialogContact
                            isOpened={isDialogOpened}
                            plateformStyle={props.plateformStyle}
                            callback={{
                                form: props.form,
                                senderLastname: props.person?.lastname,
                                senderFirstname: props.person?.firstname,
                                senderMail: props.person?.email,
                                senderPhone: props.person?.phoneMobile,
                                subject: "Un contrat GSMC a déjà été signé avec mon numéro de téléphone",
                                message: `Bonjour,\n\nMon numéro de téléphone (${formik.values.phone}) a déjà été utilisé pour signer un contrat GSMC.\n\nMerci de me recontacter pour débloquer ma demande d'adhésion.`
                            } as ICallback}
                            handleClose={() => {
                                setIsDialogOpened(false);
                            }} />
                        </>}
                    </Grid>
                    : null}

                {formFields.includes('email') ?
                    <Grid
                        item
                        xs={12}
                        sm={6} >
                        <FormLabel error={formik.touched.email && Boolean(formik.errors.email)} >
                            <FieldLabel label="Adresse e-mail" isRequired />
                        </FormLabel>
                        <TextField
                            id="email"
                            name="email"
                            fullWidth
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            onBlur={(e) => formik.setFieldTouched("email")}
                            error={formik.touched.email && Boolean(formik.errors.email)}
                            helperText={formik.touched.email && formik.errors.email} />
                    </Grid>
                    : null}

                {formFields.includes('regime') ?
                    <Grid
                        item
                        xs={12} >
                        <FormLabel error={formik.touched.regime && Boolean(formik.errors.regime)} >
                            <FieldLabel label="Régime de Sécurité Sociale" isRequired />
                        </FormLabel>
                        <Grid
                            container
                            spacing={2} >
                            {enumGsmcRegimes.map(regime => {
                                return <Grid
                                    item
                                    sm={4}
                                    xs={12} >
                                    <FormRadioButton
                                        disabled={props.person.role === enumHrRoleSubscriber}
                                        code={regime.value}
                                        selected={formik.values.regime?.value === regime.value}
                                        color={radioButtonStyle.color}
                                        onClick={(value) => {
                                            formik.setFieldValue("regime", { value: value, lavel: regime.label });
                                            formik.setFieldTouched("regime");
                                        }} >
                                        {regime.label}
                                    </FormRadioButton>
                                </Grid>
                            })}
                        </Grid>
                        <FormHelperText
                            error={formik.touched.regime && Boolean(formik.errors.regime)} >
                            {formik.touched.regime && <>{formik.errors.regime}</>}
                        </FormHelperText>

                        {props.person.role !== enumHrRoleSubscriber &&
                            <FormHelperText
                                sx={{
                                    textDecoration: 'underline',
                                    cursor: 'pointer'
                                }}
                                onClick={() => handleClickPhone()}>
                                Vous ne trouvez pas votre régime social ? Contactez-nous
                            </FormHelperText>}
                    </Grid>
                    : null}

            </Grid>
        </form>
    )
})

import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import ArticleIcon from '@mui/icons-material/Article';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldImageUpload } from '../Field/FieldImageUpload';
import { FieldLabel } from '../Field/FieldLabel';

import { updatePlateformeSettingsTemplateDocument } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsTemplateDocument } from '../../model/PlateformeSettings.model';

import { messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';
import InputAdornment from '@mui/material/InputAdornment';


interface Props {
    settings: IPlateformeSettingsTemplateDocument;
    send: (settings: IPlateformeSettingsTemplateDocument) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsTemplateDocument = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        contractHeaderText: yup.string().nullable().required(messageFieldRequired),
        contractDescriptionText: yup.string().nullable(),
        contractRateCareTns: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        contractRateCareGen: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        contractRateCareSenior: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        contractRateManagementFeesTns: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        contractRateManagementFeesGen: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        contractRateManagementFeesSenior: yup.number().min(0, '0 minimum').max(100, '100 maximum').nullable().required(messageFieldRequired),
        needsHeaderText: yup.string().nullable().required(messageFieldRequired),
        needsHeaderImage: yup.string().nullable().nullable()
    })

    const formik = useFormik({
        initialValues: {
            contractHeaderText: props.settings.contractHeaderText,
            contractDescriptionText: props.settings.contractDescriptionText,
            contractRateCareTns: props.settings.contractRateCareTns,
            contractRateCareGen: props.settings.contractRateCareGen,
            contractRateCareSenior: props.settings.contractRateCareSenior,
            contractRateManagementFeesTns: props.settings.contractRateManagementFeesTns,
            contractRateManagementFeesGen: props.settings.contractRateManagementFeesGen,
            contractRateManagementFeesSenior: props.settings.contractRateManagementFeesSenior,
            needsHeaderText: props.settings.needsHeaderText,
            needsHeaderImage: props.settings.needsHeaderImage
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsTemplateDocument = {
                id: props.settings.id,
                contractHeaderText: values.contractHeaderText,
                contractDescriptionText: values.contractDescriptionText,
                contractRateCareTns: values.contractRateCareTns,
                contractRateCareGen: values.contractRateCareGen,
                contractRateCareSenior: values.contractRateCareSenior,
                contractRateManagementFeesTns: values.contractRateManagementFeesTns,
                contractRateManagementFeesGen: values.contractRateManagementFeesGen,
                contractRateManagementFeesSenior: values.contractRateManagementFeesSenior,
                needsHeaderText: values.needsHeaderText,
                needsHeaderImage: values.needsHeaderImage
            }
            updatePlateformeSettingsTemplateDocument(toSend)
            .then((response: IPlateformeSettingsTemplateDocument) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres des documents.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <ArticleIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        PDF
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Renseigner les informations des documents envoyés au souscripteur (contrat, recueil des besoins...)
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Contrat
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.contractHeaderText && Boolean(formik.errors.contractHeaderText)} >
                                <FieldLabel
                                    label="Titre"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="contractHeaderText"
                                name="contractHeaderText"
                                value={formik.values.contractHeaderText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contractHeaderText && Boolean(formik.errors.contractHeaderText)}
                                helperText={formik.touched.contractHeaderText && formik.errors.contractHeaderText}
                                sx={{
                                    maxWidth: '400px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.contractDescriptionText && Boolean(formik.errors.contractDescriptionText)} >
                                <FieldLabel
                                    label="Sous-titre"
                                    fontWeight={500} />
                            </FormLabel>
                            <TextField
                                fullWidth
                                multiline
                                rows={2}
                                maxRows={6}
                                id="contractDescriptionText"
                                name="contractDescriptionText"
                                value={formik.values.contractDescriptionText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contractDescriptionText && Boolean(formik.errors.contractDescriptionText)}
                                helperText={formik.touched.contractDescriptionText && formik.errors.contractDescriptionText}/>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormLabel error={(formik.touched.contractRateCareTns && Boolean(formik.errors.contractRateCareTns))
                                || (formik.touched.contractRateCareGen && Boolean(formik.errors.contractRateCareGen))
                                || (formik.touched.contractRateCareSenior && Boolean(formik.errors.contractRateCareSenior))} >
                                <FieldLabel
                                    label="Ratio prestations sur cotisations HT (%)"
                                    fontWeight={500} />
                            </FormLabel>
                            <Grid
                                container
                                spacing={2}>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        TNS (539)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateCareTns"
                                        name="contractRateCareTns"
                                        value={formik.values.contractRateCareTns}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateCareTns && Boolean(formik.errors.contractRateCareTns)}
                                        helperText={formik.touched.contractRateCareTns && formik.errors.contractRateCareTns}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        Génération (512)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateCareGen"
                                        name="contractRateCareGen"
                                        value={formik.values.contractRateCareGen}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateCareGen && Boolean(formik.errors.contractRateCareGen)}
                                        helperText={formik.touched.contractRateCareGen && formik.errors.contractRateCareGen}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        Sénior (312)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateCareSenior"
                                        name="contractRateCareSenior"
                                        value={formik.values.contractRateCareSenior}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateCareSenior && Boolean(formik.errors.contractRateCareSenior)}
                                        helperText={formik.touched.contractRateCareSenior && formik.errors.contractRateCareSenior}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={(formik.touched.contractRateManagementFeesTns && Boolean(formik.errors.contractRateManagementFeesTns))
                                || (formik.touched.contractRateManagementFeesGen && Boolean(formik.errors.contractRateManagementFeesGen))
                                || (formik.touched.contractRateManagementFeesTns && Boolean(formik.errors.contractRateManagementFeesTns))} >
                                <FieldLabel
                                    label="Ratio frais de gestion sur cotisation HT (%)"
                                    fontWeight={500} />
                            </FormLabel>
                            <Grid
                                container
                                spacing={2}>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        TNS (539)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateManagementFeesTns"
                                        name="contractRateManagementFeesTns"
                                        value={formik.values.contractRateManagementFeesTns}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateManagementFeesTns && Boolean(formik.errors.contractRateManagementFeesTns)}
                                        helperText={formik.touched.contractRateManagementFeesTns && formik.errors.contractRateManagementFeesTns}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        Génération (512)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateManagementFeesGen"
                                        name="contractRateManagementFeesGen"
                                        value={formik.values.contractRateManagementFeesGen}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateManagementFeesGen && Boolean(formik.errors.contractRateManagementFeesGen)}
                                        helperText={formik.touched.contractRateManagementFeesGen && formik.errors.contractRateManagementFeesGen}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                                <Grid
                                    item
                                    lg={3}
                                    md={4}
                                    sm={6}
                                    xs={12}>
                                    <Typography
                                        variant='caption'
                                        component='div'
                                        fontSize='0.9rem' >
                                        Sénior (312)
                                    </Typography>
                                    <TextField
                                        fullWidth
                                        type="number"
                                        id="contractRateManagementFeesSenior"
                                        name="contractRateManagementFeesSenior"
                                        value={formik.values.contractRateManagementFeesSenior}
                                        onChange={formik.handleChange}
                                        onBlur={formik.handleBlur}
                                        error={formik.touched.contractRateManagementFeesSenior && Boolean(formik.errors.contractRateManagementFeesSenior)}
                                        helperText={formik.touched.contractRateManagementFeesSenior && formik.errors.contractRateManagementFeesSenior}
                                        InputProps={{
                                            inputProps: { step: 0.01, min: 0, max: 100 },
                                            startAdornment: <InputAdornment position="start">%</InputAdornment>,
                                        }}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Recueil des besoins
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.needsHeaderText && Boolean(formik.errors.needsHeaderText)} >
                                <FieldLabel
                                    label="Titre"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <TextField
                                fullWidth
                                id="needsHeaderText"
                                name="needsHeaderText"
                                value={formik.values.needsHeaderText}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.needsHeaderText && Boolean(formik.errors.needsHeaderText)}
                                helperText={formik.touched.needsHeaderText && formik.errors.needsHeaderText}
                                sx={{
                                    maxWidth: '400px'
                                }} />
                        </Grid>

                        <Grid
                            item
                            md={6}
                            xs={12} >
                            <FormLabel error={formik.touched.needsHeaderImage && Boolean(formik.errors.needsHeaderImage)} >
                                <FieldLabel
                                    label="Image d'entête"
                                    fontWeight={500} />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                L'image doit être au format paysage (elle prendra toute la largeur du document).
                            </Typography>
                            <FieldImageUpload
                                image={formik.values.needsHeaderImage}
                                send={(_: string) => formik.setFieldValue("needsHeaderImage", _)} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

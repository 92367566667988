import {useState} from "react";

import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ClearIcon from "@mui/icons-material/Clear";
import EuroIcon from '@mui/icons-material/Euro';
import WarningIcon from "@mui/icons-material/Warning";
import {Accordion, AccordionDetails, AccordionSummary, Box, Collapse} from "@mui/material";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";

import AppBarHeader from "../../../components/AppBar/AppBarHeader";
import {CardInfo} from "../../../components/Card/CardInfo";
import {style, style as styleGSMC} from "../../../static/themes/gsmc/style";
import {FormBackOfficeTarification} from "../../../components/Form/FormBackOfficeTarification";
import {ITarificationBeneficiary, ITarificationsControlResponse} from "../../../model/Tarification";
import {colors} from "../../../static/themes/gsmc/colors";
import {FormBackOfficeTarificationTextArea} from "../../../components/Form/FormBackOfficeTarificationTextArea";
import {PromotionOffer} from "../../../model/Promotion.model";


interface BeneficiaryCardProps {
    type: string;
    beneficiaryGsmc?: ITarificationBeneficiary
    beneficiaryHenner?: ITarificationBeneficiary
}

interface BeneficiariesCardProps {
    beneficiariesGsmc?: ITarificationBeneficiary[]
    beneficiariesHenner?: ITarificationBeneficiary[]
    childrenBirthDates?: (Date | undefined)[]
}

const getFormuleColor = (idFormule: string) => {
    let formuleStylePlateform = styleGSMC.components.Formules.colors.find((_: any) => _.id === parseInt(idFormule));
    return formuleStylePlateform?.color;
}

const BeneficiaryCard = (props: BeneficiaryCardProps) => {
    return (
        <>
            <Card variant="outlined" sx={{padding: 2, margin: 1}}>
                <Typography>Type : {props.type}</Typography>
                <Typography>Date de naissance : {props.beneficiaryGsmc?.birthDate.toString()}</Typography>
                <Typography
                    variant='body1' color="secondary">
                    <Stack direction="row" alignItems="center" gap={1}>
                        Prix Henner : {
                        props.beneficiaryHenner ?
                            props.beneficiaryHenner.pricePerMonth + " €" :
                            <ClearIcon color='error'/>
                    }
                    </Stack>
                </Typography>
                <Typography
                    variant='body1' color="primary">
                    <Stack direction="row" alignItems="center" gap={1}>
                        Prix GSMC : {
                        props.beneficiaryGsmc ?
                            props.beneficiaryGsmc.pricePerMonth + " €" :
                            <ClearIcon color='error'/>
                    }
                    </Stack>
                </Typography>
                {props.beneficiaryGsmc?.basePrice &&
                    <Typography
                        variant='body2' color="primary">
                        <Stack direction="row" alignItems="center" gap={1}>
                            Prix de base GSMC : {props.beneficiaryGsmc.basePrice + " €"}
                        </Stack>
                    </Typography>
                }
                {props.beneficiaryGsmc?.zoneRate &&
                    <Typography
                        variant='body2' color="primary">
                        <Stack direction="row" alignItems="center" gap={1}>
                            Taux de zone GSMC : {props.beneficiaryGsmc.zoneRate}
                        </Stack>
                    </Typography>
                }
            </Card>
        </>
    )
}

const BeneficiaryCards = (props: BeneficiariesCardProps) => {
    const subscriberGsmc = props?.beneficiariesGsmc?.findLast(beneficiary => beneficiary.type === 'SUBSCRIBER');
    const subscriberHenner = props?.beneficiariesHenner?.findLast(beneficiary => beneficiary.type === 'SUBSCRIBER');
    const partnerGsmc = props?.beneficiariesGsmc?.findLast(beneficiary => beneficiary.type === 'PARTNER');
    const partnerHenner = props?.beneficiariesHenner?.findLast(beneficiary => beneficiary.type === 'PARTNER');

    const children = props?.beneficiariesGsmc?.filter(beneficiary => beneficiary.type === "CHILD")
        .map(gsmcBeneficiary => {
            // Henner does not return the beneficiary birth date so we retrieve the children orders
            const gsmcBirthDate = new Date(gsmcBeneficiary.birthDate);
            const index = props?.childrenBirthDates?.findIndex(child => {
               const childDate = child instanceof Date ? child : child ? new Date(child) : undefined;
                return childDate?.getDate() === gsmcBirthDate.getDate()
                && childDate?.getMonth() === gsmcBirthDate.getMonth() && childDate?.getFullYear() === gsmcBirthDate.getFullYear()
            });
            const hennerBeneficiary = index !== undefined &&
            props?.beneficiariesHenner &&
            props?.beneficiariesHenner.length >= index ?
                props?.beneficiariesHenner.filter(beneficiary => beneficiary.type === "CHILD")[index] :
                undefined;
            return {
                gsmcBeneficiary: gsmcBeneficiary,
                hennerBeneficiary: hennerBeneficiary
            }
        });

    return (
        <Grid container xs={12}>
            {(subscriberGsmc || subscriberHenner) &&
                <BeneficiaryCard type='Souscripteur' beneficiaryGsmc={subscriberGsmc}
                                 beneficiaryHenner={subscriberHenner}/>
            }
            {(partnerGsmc || partnerHenner) &&
                <BeneficiaryCard type='Conjoint' beneficiaryGsmc={partnerGsmc}
                                 beneficiaryHenner={partnerHenner}/>
            }
            {children &&
                children?.map(child => {
                    return <BeneficiaryCard type='Enfant' beneficiaryGsmc={child.gsmcBeneficiary}
                                            beneficiaryHenner={child.hennerBeneficiary}/>
                })
            }
        </Grid>
    )
}

export const Tarification = () => {
    const [currentTabIndex, setCurrentTabIndex] = useState(0);

    const [isInfoDetailShown, setIsInfoDetailShown] = useState<boolean>(true);
    const [resultTarification, setResultTarification] = useState<ITarificationsControlResponse | undefined>();
    const [childrenBirthDates, setChildrenBirthDates] = useState<(Date | undefined)[]>([]);

    const handleTabChange = (event: React.SyntheticEvent, tabIndex: number) => {
        setCurrentTabIndex(tabIndex);
    };


    return (
        <>
            <AppBarHeader
                items={[
                    {type: "TITLE", icon: <EuroIcon/>},
                    {type: "LINK", label: "Tarification"}
                ]}
            />

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12}>
                    <CardInfo
                        plateformStyle={style}>
                        Comment réaliser une tarification comparative Gsmc / Henner ?
                        <Button
                            variant="text"
                            sx={{
                                ml: 2,
                                height: 30
                            }}
                            onClick={() => setIsInfoDetailShown(!isInfoDetailShown)}>
                            <Typography fontSize={16}>{isInfoDetailShown ? "masquer" : "voir"}</Typography>
                        </Button>

                        <Collapse in={isInfoDetailShown}>
                            <Typography
                                variant='body2'
                                component='span'
                                color={colors.blue.main}
                                sx={{
                                    fontFamily: "monospace"
                                }}>
                                Formulaire :&nbsp;
                            </Typography>
                            Il est nécessaire de renseigner l'ensemble des champs obligatoires du formulaire pour
                            procéder à la tarification.
                            <br/>
                            &nbsp;&nbsp;
                            Points d'attention :
                            <br/>
                            &nbsp;&nbsp;&nbsp;•&nbsp; La tarification auprès d'Henner n'est pas possible avec une date d'effet
                            dans le passé
                            <br/>
                            &nbsp;&nbsp;&nbsp;•&nbsp; Le champ "Taux de Commission" doit impérativement être renseigné
                            dans le cadre d'une tarification indirecte

                            <br/><br/>
                            <Typography
                                variant='body2'
                                component='span'
                                color={colors.blue.main}
                                sx={{
                                    fontFamily: "monospace"
                                }}>
                                Champ de texte libre :&nbsp;
                            </Typography>
                            La requête doit contenir l'intégralité d'une requête JSON GSMC ou l'intégralité d'une
                            requête XML HENNER. <br />Autrement une erreur technique apparaitra pour non compréhension des
                            données transmises.
                        </Collapse>
                    </CardInfo>
                </Grid>
                <Box
                    sx={{
                        overflow: "auto",
                        p: 3
                    }}>
                    <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                        <Tabs value={currentTabIndex} onChange={handleTabChange}>
                            <Tab label="Formulaire"/>
                            <Tab label="Champ de texte libre"/>
                        </Tabs>
                    </Box>

                    {currentTabIndex === 0 &&
                        <FormBackOfficeTarification setResultTarification={setResultTarification}
                                                    setChildrenBirthDates={setChildrenBirthDates}/>
                    }
                    {currentTabIndex === 1 &&
                        <FormBackOfficeTarificationTextArea setResultTarification={setResultTarification}
                                                            setChildrenBirthDates={setChildrenBirthDates}/>
                    }
                </Box>
                <Grid
                    xs={12}
                    sx={{
                        overflow: "auto",
                        p: 3
                    }}>
                    {resultTarification &&
                        (<>
                                {resultTarification?.hennerError && (<Stack direction="row" alignItems="center" gap={1}>
                                    <WarningIcon
                                        fontSize="large"
                                        sx={{
                                            color: colors.red.main
                                        }}/>
                                    <Typography
                                        variant='body1'>
                                        La tarification auprès d'Henner a été un échec
                                    </Typography>
                                </Stack>)}
                                {resultTarification?.gsmcError && (<Stack direction="row" alignItems="center" gap={1}>
                                    <WarningIcon
                                        fontSize="large"
                                        sx={{
                                            color: colors.red.main
                                        }}/>
                                    <Typography
                                        variant='body1'>
                                        La tarification auprès de GSMC a été un échec
                                        : {resultTarification.gsmcErrorDetails}
                                    </Typography>
                                </Stack>)}

                                {resultTarification?.tarifications.map(tarification => (
                                    <Card variant="outlined" sx={{padding: 2, margin: 1}}>
                                        <Grid container p={2}
                                              alignItems="center"
                                              justifyContent="center">
                                            <Grid
                                                item
                                                xs={4}>
                                                <Typography
                                                    variant='h3'>
                                                    Offre
                                                    : {Object.keys(PromotionOffer)[Object.values(PromotionOffer).indexOf(resultTarification.offre as unknown as PromotionOffer)]}
                                                </Typography>
                                                <Typography
                                                    variant='h3' color={getFormuleColor(tarification.code)}>
                                                    Formule : {tarification.name + " (" + tarification.code + ")"}
                                                </Typography>
                                            </Grid>
                                            <Grid
                                                item
                                                xs={6}>
                                                <Stack direction="row" alignItems="center" gap={1}>
                                                    <Card sx={{padding: 2, backgroundColor: colors.orange.main}}>
                                                        <Typography
                                                            variant='h3'
                                                            color='white'>
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                Prix Henner : {
                                                                resultTarification.hennerError || tarification.pricePerMonthHenner === null ?
                                                                    <ClearIcon color='error'/> :
                                                                    tarification.pricePerMonthHenner + " €"}
                                                            </Stack>
                                                        </Typography>
                                                    </Card>
                                                    <Card sx={{padding: 2, backgroundColor: colors.blue.main}}>

                                                        <Typography
                                                            variant='h3'
                                                            color='white'>
                                                            <Grid item>
                                                                <Stack direction="row" alignItems="center" gap={1}>
                                                                    Prix GSMC : {
                                                                    resultTarification.gsmcError || tarification.pricePerMonthGsmc === null ?
                                                                        <ClearIcon color='error'/> :
                                                                        tarification.pricePerMonthGsmc + " €"}
                                                                </Stack>
                                                            </Grid>
                                                            {tarification.assistanceFees &&
                                                                <Grid item>
                                                                    <Typography
                                                                        variant='body1'
                                                                        color='white'>
                                                                        Frais d'assistance
                                                                        : {tarification.assistanceFees} €
                                                                    </Typography>
                                                                </Grid>}
                                                        </Typography>
                                                    </Card>
                                                    <Card sx={{padding: 2, backgroundColor: 'white'}}>
                                                        <Typography
                                                            variant='h3'
                                                            color={tarification.priceDifference !== undefined && tarification.priceDifference <= 0.01 ? colors.green.main : colors.red.main}>
                                                            <Stack direction="row" alignItems="center" gap={1}>
                                                                Différence de prix : {
                                                                resultTarification.hennerError || resultTarification.gsmcError || tarification.priceDifference === null ?
                                                                    <ClearIcon color='error'/> :
                                                                    tarification.priceDifference + " €"}
                                                            </Stack>
                                                        </Typography>
                                                    </Card>
                                                </Stack>
                                            </Grid>
                                            <Grid item xs={8} pt={2}>
                                                <Accordion>
                                                    <AccordionSummary expandIcon={<ArrowDropDownIcon/>}
                                                                      aria-controls="panel1-content"
                                                                      id="panel1-header">
                                                        <Stack direction="row" alignItems="center" gap={1}>
                                                            <AddCircleOutlineIcon/>
                                                            <Typography>Afficher le détail par
                                                                bénéficiaire</Typography>
                                                        </Stack>
                                                    </AccordionSummary>
                                                    <AccordionDetails>
                                                        <BeneficiaryCards
                                                            beneficiariesGsmc={tarification.beneficiariesGsmc}
                                                            beneficiariesHenner={tarification.beneficiariesHenner}
                                                            childrenBirthDates={childrenBirthDates}/>
                                                    </AccordionDetails>
                                                </Accordion>

                                            </Grid>

                                        </Grid>
                                    </Card>
                                ))}
                            </>
                        )}
                </Grid>
            </Grid>
        </>)
}

import { IContract } from "../model/Contract.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const createContract = (contract: IContract) : Promise<IContract> => {

    return fetch(`${apiUrl}/contract`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contract)

    }).then(_ => _.json()) 
}


export const modifyContract = (contract: IContract) : Promise<IContract> => {

    return fetch(`${apiUrl}/contract`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contract)

    }).then(_ => _.json()) 
}

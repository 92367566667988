
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";

import { colors } from '../../static/themes/colors';


export const CgvContent = () => {

    return (
        <Grid
            container
            justifyContent="space-between"
            spacing={2}
            sx={{
                textAlign: "justify"
            }}>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="preambule" sx={{mb: 1.5}}>Préambule</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Les présentes Conditions d’Utilisation ont pour objet de définir les modalités de mise à disposition du site&nbsp;
                    <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link>.
                </Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Les informations communiquées sur ce site sont présentées à titre indicatif et général. Elles ne prétendent nullement à l’exhaustivité et n’ont pas de valeur contractuelle.
                </Typography>

                <Typography variant="body1" fontWeight={500}>
                    La consultation du site <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link> et/ou l’utilisation des services proposés vaut acceptation sans réserve des présentes Conditions d’Utilisation.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="mentions_legales" sx={{py: 4}}>Mentions légales</Typography>

                <Typography variant="body1" fontWeight={500}>
                    Conformément aux dispositions de l’article 6 III 1 de la loi n°2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique, nous vous informons que :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Le présent site est édité et conçu par la Mutuelle GSMC, Groupe SMISO-Mutuelle des Cadres<br/>Siège social : 95 rue de Jemmapes - 59800 Lille - Tél. : <Link href="tel:03 20 82 82 26">03 20 82 82 26</Link>. Mutuelle soumise aux dispositions du livre II du code de la Mutualité - SIREN n°324 310 614 - TVA intra-communautaire : exonération article 261C du C.G.I.
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Les espaces personnels et professionnels sont hébergés par la société GROUPE HENNER HOLDING, société par actions simplifiée au capital de 3.800.000 euros, immatriculée au Registre du Commerce et des Sociétés de Paris sous le numéro 332 376 292, dont le siège social est sis 14 boulevard du Général Leclerc, 92200 Neuilly-sur-Seine
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Les autres contenus du site sont hébergés par NAITWAYS SAS , RCS NANTERRE 508 823 614 - Numéro SIRET 50882361400054 – 131 bis Rue de Billancourt, 92100 Boulogne-Billancourt - Tél. : <Link href="tel:01 83 64 00 00">01 83 64 00 00</Link>
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Le Directeur de Publication est Monsieur Guillaume GEORGES, Directeur général de GSMC.
                        </Typography>
                    </li>
                </ul>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="adresse_electronique" sx={{py: 4}}>Adresse électronique</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    L’emploi, par l’utilisateur, de son logiciel de messagerie entraine la communication de son adresse électronique. Celle-ci sera utilisée dans le respect de la loi du 21 juin 2004 portant sur la confiance dans l'économie numérique. Ainsi, l’utilisateur sera toujours mis en mesure de s’opposer de façon simple à l'utilisation de cette adresse, et toute utilisation à des fins de propositions commerciales effectuée par messagerie sera subordonnée au recueil préalable de son consentement exprès, sauf exception prévue par la loi.
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                    Il est loisible à l’utilisateur de demander à tout moment la mise à jour de son adresse électronique en écrivant à l'adresse suivante : Groupe SMISO-Mutuelle des Cadres - Siège social : 95 rue de Jemmapes - 59800 Lille.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="propriete_intellectuelle" sx={{py: 4}}>Propriété intellectuelle</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    L’ensemble des éléments composant ce site est protégé par le Code de la Propriété Intellectuelle.
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Ainsi, le droit de reproduire ou de faire reproduire ce site, sur tout support, tout format, par tout procédé et de représenter ou de faire représenter en toutes langues, en tout pays, à titre onéreux ou gratuit, intégralement ou par extrait, sur tout mode de diffusion, notamment par Internet, par voie hertzienne, par satellite ou par câble opérateur, par tout moyen de télédiffusion ou de télécommunication, ou par communication télématique, est strictement interdit.
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Cette interdiction s’applique sans limitation géographique pour la durée de protection accordée par le Code de la Propriété Intellectuelle, pour tout mode d'exploitation et sur tout support magnétique ou optique, CD-ROM ou sur tout autre support électronique ou non, actuel ou futur.
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                    Toutes les marques auxquelles il pourrait être fait référence dans ce site sont des marques régulièrement déposées auprès de l'INPI, et protégées à ce titre. De même, les noms des entreprises, produits et services cités sur ce site sont la propriété de GSMC, ou de l’un de leurs partenaires, et sont protégés en tant que tels.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="validite_informations" sx={{py: 4}}>Validité des informations</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Les simulations, informations et données fournies sur le site <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link> sont présentées à titre purement indicatif et ne sauraient être utilisées sans vérifications préalables de l'internaute.
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    GSMC s'efforce d'assurer l'exactitude et la mise à jour des informations diffusées sur le site <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link>, dont elle se réserve le droit de corriger, à tout moment et sans préavis, le contenu. Elle ne peut cependant en garantir l'exhaustivité ou l'absence de modification par un tiers (intrusion, virus). En outre, GSMC décline toute responsabilité (directe ou indirecte) en cas de retard, d'erreur ou d'omission quant au contenu des pages du site www.mutuelle-gsmc.fr et à l'utilisation qui pourrait en être faite par quiconque de même qu'en cas d'interruption ou de non disponibilité du service.
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                    A ce titre, le groupe GSMC ne saurait être tenu responsable des éléments en dehors de son contrôle et des dommages qui pourraient éventuellement être subis par votre environnement technique et notamment, vos ordinateurs, logiciels, équipements réseaux (modems, téléphones...) et tout matériel utilisé pour accéder à ou utiliser le service et/ou les informations.
                    <br/>Vous vous engagez à faire des informations contenues sur le présent site un usage personnel et non commercial.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="protection_donnees" sx={{py: 4}}>Protection des données personnelles</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    GSMC, en tant que responsable de traitement, collecte des données personnelles dans le but de proposer des produits d’assurance et des services associés répondant aux besoins de ses adhérents et prospects. GSMC traite les données personnelles en conformité avec le Règlement (UE) 2016/679 du Parlement européen et du Conseil du 27 avril 2016 relatif à la protection des personnes physiques à l'égard du traitement des données à caractère personnel et à la libre circulation de ces données dit Règlement Général sur la Protection des Données (RGPD).
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                    GSMC vous informe sur les mesures mises en œuvre par GSMC, en tant que responsable de traitement, pour collecter, utiliser, stocker et protéger les données de ses adhérents et de ses prospects.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="donnees_collectees" sx={{py: 4}}>Données collectées et traitées</Typography>

                <Typography variant="body1" fontWeight={500}>
                    GSMC collecte et traite un certain nombre de données personnelles relatives notamment aux catégories de données suivantes :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données d’identification (nom, prénom, civilité, date de naissance…)
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                          Numéro de sécurité sociale (ou NIR)
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Coordonnées de contact
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Situation familiale et données économiques, patrimoniales et financières
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Situation professionnelle
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données de connexion
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données nécessaires à l’appréciation du risque, à la passation, l’application du contrat et à la gestion des sinistres et des prestations
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données relatives à la vie personnelle, situation professionnelle et aux habitudes de vie en relation étroite avec le contrat d’assurance
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données de santé
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Données d’infractions, condamnations et mesures de sûreté,
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1" fontWeight={500} sx={{mt: 1.5}}>
                    Les données collectées sont adéquates, pertinentes et limitées à ce qui est nécessaire au regard des finalités.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="finalites_traitement" sx={{py: 4}}>Finalités de traitement</Typography>

                <Typography variant="body1" fontWeight={500}>
                    GSMC collecte des données pour des finalités déterminées, explicites et légitimes en lien avec ses activités et notamment :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La relation précontractuelle
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La conclusion, la gestion et l’exécution de contrats d’assurance et services associés
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Les opérations relatives à la gestion des clients, à la prospection commerciale
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Les opérations relatives à la gestion des clients, à la prospection commerciale
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La gestion de la relation client
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La gestion des réclamations et des contentieux
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La lutte contre le blanchiment des capitaux et le financement du terrorisme
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La lutte anti-fraude
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            La mise en œuvre des dispositions légales et réglementaires en vigueur
                        </Typography>
                    </li>
                </ul>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="destinataires_donnees" sx={{py: 4}}>Destinataires des données</Typography>

                <Typography variant="body1" fontWeight={500}>
                    Les destinataires des données collectées sont les personnes habilitées des services de GSMC, ainsi qu’à ses partenaires assureurs, intermédiaires, réassureurs, prestataires sous-traitants, dans la limite des prestations qui leur sont confiées, mais également aux organismes professionnels et sociaux ou autorités administratives et judiciaires habilités.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="conservation_donnees" sx={{py: 4}}>Conservation des données</Typography>

                <Typography variant="body1" fontWeight={500}>
                    La Mutuelle GSMC conserve les données personnelles pour la durée nécessaire à la réalisation des finalités déterminées et notamment la durée de vie des contrats d’assurances et les délais supplémentaires liés aux délais de prescription légaux applicables. La durée est déterminée en fonction de la réglementation en vigueur et également des recommandations émises par la CNIL.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="securite_donnees" sx={{py: 4}}>Sécurité des données personnelles</Typography>

                <Typography variant="body1" fontWeight={500}>
                GSMC en tant que responsable de traitement met en œuvre des mesures techniques et organisationnelles appropriées afin de garantir un niveau de sécurité et de confidentialité adapté au risque. Les sous-traitants de GSMC qui effectuent des traitements pour son compte devront présenter un niveau de sécurité et de confidentialité conforme aux exigences réglementaires. Ces mesures permettent d’écarter le risque de destruction, d’endommagement, ou de vol des données personnelles.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="droit_personnes" sx={{py: 4}}>Droits des personnes concernées</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Les personnes concernées par les données traitées par GSMC bénéficient de droits qu’elles peuvent exercer dans les conditions prévues par la règlementation :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Droit d’accès aux données
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                           Droit à la rectification de données inexactes
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                           Droit à l’effacement des données
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                           Droit d’opposition au traitement des données
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                           Droit à la limitation de traitement des données
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                           Droit à la portabilité des données
                        </Typography>
                    </li>
                </ul>

                <Typography variant="body1" fontWeight={500} sx={{my: 1.5}}>
                    Toute personne concernée peut exercer ces droits directement auprès de GSMC, par courrier postal accompagné d’un justificatif d’identité à :
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5, ml: 4, borderLeft: 2, borderColor: colors.blue.main, pl: 1}}>
                    Délégué à la Protection des données - GSMC<br/>
                    95 rue de Jemmapes<br/>
                    59800 LILLE
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Ou l’adresse électronique suivante : <Link href="mailto:rgpd@mutuellegsmc.fr">rgpd@mutuellegsmc.fr</Link>
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Par ailleurs, à l’issue de ces démarches, en cas de difficultés dans l’exercice de vos droits, la personne concernée a la possibilité de faire une réclamation auprès de la CNIL sur son site internet : <Link href="https://cnil.fr" rel="noopener noreferrer" target="_blank">www.cnil.fr</Link>
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Certains questionnaires proposés sur le site ne revêtent pas de caractère obligatoire. L’utilisateur peut donc choisir de ne pas les remplir.
                    <br/>
                    D'autres questionnaires doivent être obligatoirement remplis pour permettre à l’utilisateur d'accéder aux services du site ou pour permettre à GSMC de répondre à sa demande.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="limitation_responsabilite" sx={{py: 4}}>Limitation de la responsabilité</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    En aucun cas, GSMC ne pourra être tenu pour responsable des dommages directs et/ou indirects qui pourraient résulter de l'usage de ce site Web, notamment tout préjudice financier, perte de programmes ou de données, même si l’internaute a eu connaissance de la possibilité de la survenance de tels dommages.
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                Les liens hypertextes établis en direction d'autres sites ne sauraient engager la responsabilité de GSMC, notamment s'agissant du contenu de ces sites. En conséquence, tout préjudice direct ou indirect résultant de votre accès à un autre site relié par un lien hypertexte ne peut engager la responsabilité de GSMC.
                </Typography>
                <Typography variant="body1" fontWeight={500}>
                    Par ailleurs, GSMC n'est pas responsable des liens hypertextes pointant vers le <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link> et interdit à toute personne de mettre en place un tel lien sans son autorisation expresse écrite et préalable.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="cookies" sx={{py: 4}}>Cookies</Typography>

                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Un cookie permet de gagner du temps et d'accéder directement aux informations que l’internaute recherche. Les cookies utilisés pour collecter des informations dans son ordinateur, permettent uniquement d'optimiser et de personnaliser sa connexion au site. Les informations collectées par les cookies ne sont ni enregistrées, ni stockées, et s'effacent dès la fin de sa connexion. Le fait de refuser les cookies peut compromettre l’accès  à certaines fonctionnalités du site.
                </Typography>
                <Typography variant="body1" fontWeight={500} sx={{mb: 1.5}}>
                    Toutefois, il est possible de s’opposer à l'enregistrement des cookies en paramétrant le navigateur de la manière suivante :
                </Typography>

                <Typography variant="body1" sx={{mb: 1.5}}>
                    Mozilla Firefox
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Choisir le menu ‟<b>Outils</b>” puis ‟<b>Options</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquer sur l'icône ‟<b>Vie privée</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Repérer le menu ‟<b>Cookie</b>” et sélectionner les options adéquates
                        </Typography>
                    </li>
                </ul>

                <Typography variant="body1" sx={{my: 1.5}}>
                    Internet Explorer / Edge
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Choisir le menu ‟<b>Outils</b>” puis ‟<b>Options Internet</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquer sur l'onglet ‟<b>Confidentialité</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Sélectionner le niveau souhaité à l'aide du curseur
                        </Typography>
                    </li>
                </ul>

                <Typography variant="body1" sx={{my: 1.5}}>
                    Opéra 
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par un rouage)
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Sélectionnez <b>Paramètres‟</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquez sur ‟<b>Afficher les paramètres avancés</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Dans la section ‟<b>Confidentialité</b>”, cliquez sur ‟<b>Paramètres de contenu</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Dans la section ‟<b>Cookies</b>”, vous pouvez bloquer les cookies
                        </Typography>
                    </li>
                </ul>

                <Typography variant="body1" sx={{my: 1.5}}>
                    Chrome 
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquez en haut à droite du navigateur sur le pictogramme de menu (symbolisé par trois lignes horizontales)
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Sélectionnez ‟<b>Paramètres</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Cliquez sur ‟<b>Afficher les paramètres avancés</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Dans la section ‟<b>Confidentialité</b>”, cliquez sur ‟<b>Préférences</b>”
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Dans la section ‟<b>Cookies</b>”, vous pouvez bloquer les cookies
                        </Typography>
                    </li>
                </ul>

                <Typography variant="body1" fontWeight={500} sx={{mt: 1.5}}>
                    Plus d’information sur les cookies, rendez-vous sur le site de la CNIL, à cette adresse : <Link href="https://www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser" rel="noopener noreferrer" target="_blank">www.cnil.fr/fr/cookies-les-outils-pour-les-maitriser</Link>
                </Typography>
            </Grid>


            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="confidentilite" sx={{py: 4}}>Confidentialité de l’espace réservé</Typography>

                <Typography variant="body1" fontWeight={500}>
                    L'accès aux espaces réservés est sécurisé, ce qui garantit la confidentialité des informations communiquées pour réaliser certains actes en ligne.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="evolution" sx={{py: 4}}>Evolution des conditions d’utilisation</Typography>

                <Typography variant="body1" fontWeight={500}>
                    L’Editeur du site <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link> se réserve le droit de modifier les termes et conditions d’utilisation du site.
                    <br/>
                    De ce fait, il est conseillé aux utilisateurs de consulter régulièrement l’existence d’éventuels amendements.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="droit_applicable" sx={{py: 4}}>Droit applicable et attribution de juridiction</Typography>

                <Typography variant="body1" fontWeight={500}>
                    Les litiges relatifs à l’utilisation du site <Link href="https://mutuelle-gsmc.fr" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr</Link> sont soumis au droit français. Il est fait attribution exclusive de juridiction aux tribunaux compétents de Lille.
                </Typography>
            </Grid>

            <Grid
                item
                xs={12} >
                <Typography variant="h2" color={colors.blue.main} id="reclamation" sx={{py: 4}}>Réclamation / Médiation</Typography>

                <Typography variant="body1" sx={{mb: 1.5}}>1. Contacter votre conseiller en agence </Typography>
                <Typography variant="body1" fontWeight={500}>
                    En premier lieu, si vous souhaitez nous signaler un problème ou une insatisfaction, veuillez contacter votre conseiller en agence, qui reste votre interlocuteur privilégié. Vous pourrez lui faire part de vos observations et interrogations afin qu’il puisse vous apporter les éclaircissements souhaités. 
                    <br/>
                    Si vous formulez votre demande est formulée à l’oral (par téléphone, en Agence) et qu’il n’est pas possible d’y donner immédiatement suite, il conviendra de la formaliser par écrit.
                </Typography>
                
                <Typography variant="body1" sx={{my: 1.5}}>2. Contacter notre service réclamations </Typography>
                <Typography variant="body1" fontWeight={500}>
                    Si la réponse apportée par nos conseillers ne vous donne pas entière satisfaction, veuillez formuler votre demande écrite, accompagnée de toutes les pièces utiles à l’étude de celle-ci avant de les transmettre :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Via le formulaire de contact en ligne : <Link href="https://www.mutuelle-gsmc.fr/contact" rel="noopener noreferrer" target="_blank">www.mutuelle-gsmc.fr/contact</Link>
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Par courrier à l'adresse :
                        </Typography>
                        <Typography variant="body1" fontWeight={500} color={colors.black.main} sx={{mb: 1.5, ml: 4, borderLeft: 2, borderColor: colors.blue.main, pl: 1}}>
                            Groupe SMISO- Mutuelle des Cadres<br/>
                            Service Réclamations<br/>
                            95 rue de Jemmapes<br/>
                            59800 LILLE
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Sur votre espace personnel (en précisant vos coordonnées complètes : nom, prénom, numéro d’adhérent et coordonnées téléphoniques)
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1" fontWeight={500} sx={{my: 1.5}}>
                    Notre service réclamations vous apportera une réponse dans un délai de 10 jours calendaires maximum suivant votre réclamation. 
                    <br/>
                    En revanche, si la réclamation s’avère complexe et nécessite de plus amples recherches de notre part, nous accuserons réception de votre réclamation dans un délai de 10 jours calendaires maximum et vous apporterons une réponse dans les deux mois suivant l’envoi de votre réclamation.
                </Typography>

                <Typography variant="body1" sx={{my: 1.5}}>3. Contacter le médiateur </Typography>
                <Typography variant="body1" fontWeight={500}>
                    Suite au traitement de votre réclamation et en cas de désaccord persistant ou d’absence de réponse dans le délai de 2 mois, vous pouvez saisir le Médiateur de la Mutuelle :
                </Typography>
                <ul
                    style={{
                        margin: 0,
                        marginTop: '4px'
                    }}>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Via le formulaire de contact en ligne : <Link href="https://www.cmap.fr/contact" rel="noopener noreferrer" target="_blank">www.cmap.fr/contact</Link>
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Par courrier à l'adresse :
                        </Typography>
                        <Typography variant="body1" fontWeight={500} color={colors.black.main} sx={{mb: 1.5, ml: 4, borderLeft: 2, borderColor: colors.blue.main, pl: 1}}>
                            Centre de Médiation et d’Arbitrage de Paris<br/>
                            39 avenue Franklin D. Roosevelt<br/>
                            75008 PARIS
                        </Typography>
                    </li>
                    <li style={{ color: colors.blue.main }}>
                        <Typography variant='body1' fontWeight={500} color={colors.black.main}>
                            Par email à l'adresse : <Link href="mailto:cmap@cmap.fr">cmap@cmap.fr</Link>
                        </Typography>
                    </li>
                </ul>
                <Typography variant="body1" fontWeight={500} sx={{my: 1.5}}>
                    Les décisions des commissions d’actions sociales, la motivation des résiliations des contrats ou adhésion, les décisions d’augmentation des cotisations et les procédures de recouvrement des cotisations ne peuvent faire l’objet d’un recours devant le collège des médiateurs. 
                </Typography>

                <Typography variant="body1" fontWeight={500} fontStyle="italic">
                    N.B : Les demandes d’informations ou de constitution de dossiers, de mise à jour de dossiers et/ou de droits ne constituent pas des réclamations et ne seront pas traitées par le service réclamations. Pour les formuler, nous vous remercions de vous connecter sur votre espace Adhérent.
                </Typography>
            </Grid>


        </Grid>
    )
}

import { colors } from "../static/themes/colors"
import { Page } from "./Page.model"
import { IPlateforme } from "./Plateforme.model"


export enum PromotionType {
    BASE="BASE",
    INCENTIVE="INCENTIVE"
}


export enum PromotionStatus {
    PENDING="PENDING",
    IN_PROGRESS="IN_PROGRESS",
    EXPIRED="EXPIRED",
    DEACTIVATED="DEACTIVATED"
}


export enum PromotionOffer {
    SENIOR="312",
    GENERATION="512",
    TNS="539"
}

export const enumPromotionType = [
    {
        label: "GSMC",
        value: PromotionType.BASE,
        description: "Promotion GSMC",
        color: colors.black.main
    },
    {
        label: "INCENTIVE",
        value: PromotionType.INCENTIVE,
        description: "Promotion liée à une plateforme externe",
        color: colors.black.secondary
    },
]


export const enumPromotionStatus = [
    {
        label: "Programmée",
        value: PromotionStatus.PENDING,
        color: colors.blue.main
    },
    {
        label: "Active",
        value: PromotionStatus.IN_PROGRESS,
        color: colors.green.main
    },
    {
        label: "Expirée",
        value: PromotionStatus.EXPIRED,
        color: colors.orange.main
    },
    {
        label: "Désactivée",
        value: PromotionStatus.DEACTIVATED,
        color: colors.red.main
    },
]


export const enumPromotionOffer = [
    {
        label: "Sénior",
        value: PromotionOffer.SENIOR,
        icon: "👵"
    },
    {
        label: "Génération",
        value: PromotionOffer.GENERATION,
        icon: "🙆‍♀️"
    },
    {
        label: "TNS",
        value: PromotionOffer.TNS,
        icon: "🏢"
    },
]


export interface IPromotion {
    id?: number
    techDateCreated?: Date
    techDateModification?: Date
    type: PromotionType
    offers?: string[]
    plateformes?: IPlateforme[]
    status: PromotionStatus
    dateStart?: Date
    dateEnd?: Date
    hennerCode?: string
    hennerValue?: number
    title: string
    description?: string
    descriptionLegal?: string
    color?: string
    textColor?: string
    borderColor?: string
    image?: string
    productUri?: string
    cgvUri?: string
    isActive: boolean
}


export interface IPromotionDTO {
    id?: number
    type: PromotionType
    offers?: string[]
    plateformes?: number[]
    dateStart?: Date
    dateEnd?: Date
    hennerCode?: string
    hennerValue?: number
    title: string
    description?: string
    descriptionLegal?: string
    color?: string
    textColor?: string
    borderColor?: string
    image?: string
    productUri?: string
    cgvUri?: string
    isActive: boolean
}


export interface IPromotionPage extends Page {
    content: IPromotion[];
}

export function convertUTCDateToLocalDate(date : Date) {
    var dateLocal = new Date(date);
    var newDate = new Date(dateLocal.getTime() - dateLocal.getTimezoneOffset()*60*1000);
    return newDate;
}

  
const addDays = (date: Date, days: number) => new Date(date.getTime() + days * 24 * 60 * 60 * 1000)


export function isWeekend(date: Date) {
    return date.getDay() === 0 || date.getDay() === 6;
}


const paques = function (year: number) {
    const a = year % 19
    const century = Math.floor(year / 100)
    const yearsAfterCentury = year % 100
    const d = (19 * a + century - Math.floor(century / 4) - Math.floor((Math.floor(century - (century + 8) / 25) + 1) / 3) + 15) % 30
    const e = (32 + 2 * (century % 4) + 2 * Math.floor(yearsAfterCentury / 4) - d - (yearsAfterCentury % 4)) % 7
    const f = d + e - 7 * Math.floor((a + 11 * d + 22 * e) / 451) + 114
    const month = Math.floor(f / 31)
    const day = (f % 31) + 1

    return new Date(year, month - 1, day)
}


export function isClosedDay(date: Date) {
    const closedDay = [
        addDays(paques(date.getFullYear()), 1),  // Lundi de pâques
        addDays(paques(date.getFullYear()), 39),  // Ascension 
        addDays(paques(date.getFullYear()), 50),  // Lundi de pentecôte
        new Date(date.getFullYear(), 0, 1),  // 1 Janvier (Jour de l'an)
        new Date(date.getFullYear(), 4, 1),  // 1 Mai (Fête du travail)
        new Date(date.getFullYear(), 4, 8),  // 8 Mai (Armistice)
        new Date(date.getFullYear(), 6, 14),  // 14 Juillet (Fête national)
        new Date(date.getFullYear(), 7, 15),  // 15 Août (Assomption)
        new Date(date.getFullYear(), 10, 1),  // 1 Novembre (Toussaint)
        new Date(date.getFullYear(), 10, 11),  // 11 Novembre
        new Date(date.getFullYear(), 11, 25)  // 25 Décembre
    ]

    if (closedDay.find(_ => _.getDate() === date.getDate() && _.getMonth() === date.getMonth()))
        return true

    return false
}

import { IContractDebiting } from "../model/ContractDebiting.model"


const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const createContractDebiting = (contractDebiting: IContractDebiting) : Promise<IContractDebiting> => {

    return fetch(`${apiUrl}/contract_debiting`,
    {
        method: "POST",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractDebiting)

    }).then(_ => _.json()) 
}


export const modifyContractDebiting = (contractDebiting: IContractDebiting) : Promise<IContractDebiting> => {

    return fetch(`${apiUrl}/contract_debiting`,
    {
        method: "PUT",
        headers:{"content-type": "application/json"},
        body: JSON.stringify(contractDebiting)

    }).then(_ => _.json()) 
}


export const deleteContractDebiting = (id: number) : Promise<IContractDebiting> => {

    return fetch(`${apiUrl}/contract_debiting/${id}`,
    {
        method: "DELETE",

    }).then(_ => _.json()) 
}

import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import FormHelperText from '@mui/material/FormHelperText';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';

import { InfoIcon } from '../Icon/InfoIcon';
import { FieldLabel } from '../Field/FieldLabel';
import { FormRadioButton } from './FormRadioButton';

import { updatePlateformeSettingsSmtp } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsSmtp } from '../../model/PlateformeSettings.model';

import { enumSmtpMethod, enumSmtpMethodBrevo } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';


interface Props {
    settingsSmtp: IPlateformeSettingsSmtp;
    send: (settingsSmtp: IPlateformeSettingsSmtp) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsSmtp = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
    })

    const formik = useFormik({
        initialValues: {
            typeSmtp: props.settingsSmtp.typeSmtp,
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
        },
        onSubmit: (values) => {

            const toSend: IPlateformeSettingsSmtp = {
                id: props.settingsSmtp.id,
                typeSmtp: values.typeSmtp
            }

            updatePlateformeSettingsSmtp(toSend)
            .then((response: IPlateformeSettingsSmtp) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement des paramètres du SMTP.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);


    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <AlternateEmailIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        SMTP
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                   Configurer l'envoi de mail.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Paramètres généraux
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <FormLabel error={formik.touched.typeSmtp && Boolean(formik.errors.typeSmtp)} >
                                <FieldLabel
                                    label="Méthode d'envoi"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                La méthode d'envoi définie le service SMTP utilisé afin d'envoyer les mails.
                            </Typography>
                            <Grid
                                container
                                spacing={2} >
                                {enumSmtpMethod.map((_, id) => {
                                    return <Grid
                                        item
                                        lg={2}
                                        md={3}
                                        sm={4}
                                        xs={12} >
                                        <FormRadioButton
                                            code={_.value}
                                            selected={formik.values.typeSmtp === _.value}
                                            color={colors.blue.main}
                                            onClick={(value) => {
                                                formik.setFieldValue("typeSmtp", value);
                                                formik.setFieldTouched("typeSmtp");
                                            }} >
                                            {_.label}
                                        </FormRadioButton>
                                    </Grid>
                                })}
                            </Grid>
                            <FormHelperText
                                error={formik.touched.typeSmtp && Boolean(formik.errors.typeSmtp)} >
                                {formik.touched.typeSmtp && formik.errors.typeSmtp}
                            </FormHelperText>

                            {formik.values.typeSmtp !== enumSmtpMethodBrevo &&
                            <FormHelperText>
                                ⚠️ Certains emails ont été uniquement implémenté pour le SFTP Brevo via l'utilisation de template.
                                <br/>
                                Ces emails seront envoyés via le SMTP Brevo.
                            </FormHelperText>}
                        </Grid>



                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

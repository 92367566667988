import React, { useEffect } from 'react';

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { FormSignSend } from '../../components/Form/FormSignSend';

import { modifyPerson } from '../../api/Person.api';

import { IPerson } from '../../model/Person.model';
import { InfoIcon } from '../../components/Icon/InfoIcon';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { CardInfo } from '../../components/Card/CardInfo';
import { IForm } from '../../model/Form.model';
import * as Sentry from "@sentry/react";


interface Props {
    plateformStyle: any;
    sidebar?: JSX.Element;
    form?: IForm
    subscriber: IPerson;
    sendSubscriber: (subscriber: IPerson) => void;
    isControlFraud?: boolean
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormContractSignSend = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;
    const iconInfoStyle: any = plateformStyle.components.IconInfo;
    const listStyle: any = plateformStyle.components.List;

    const formRef = React.createRef<HTMLFormElement>();

    const updatePhoneSubscriber = (phone: string) => {
        let subscriber: IPerson = {...props.subscriber};
        subscriber.phoneMobile = phone;

        modifyPerson(subscriber).then((response : IPerson) => {
            if(!response.id) {
                // TODO : Gestion erreur.
                Sentry.captureMessage("updatePhoneSubscriber -> modifyPerson - Exception",
                    {
                        level: 'error',
                        extra: {
                            response: response,
                            request: subscriber,
                        }
                    }
                );
            }
            else {
                props.sendSubscriber(response);  // Update Form states.
            }
        });
    }


    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        if (formRef.current)
            formRef.current.leave();

        props.goToPrevious();
    }, [props.goToPreviousFlag])


    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Vérification de l’identité"
                    description="Vérifier que le numéro de téléphone saisi est bien le votre" />}
            sidebar={props.sidebar}
            content={
                <Card>
                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection label="Signature de votre contrat" />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <CardInfo
                                plateformStyle={plateformStyle}>
                                <Typography
                                    component='span'
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Nous allons vous envoyer un code confidentiel par SMS.<br />
                                    <Box sx={{py: 0.5}}></Box>
                                    Ce code vous permettra de signer électroniquement :
                                    <ul
                                        style={{
                                            margin: 0,
                                            marginTop: '4px'
                                        }}>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                votre recueil de besoins
                                            </Typography>
                                        </li>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                votre contrat
                                            </Typography>
                                        </li>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                votre mandat SEPA (et ainsi consentir au prélèvement automatique le 5 de chaque mois sur votre compte bancaire)
                                            </Typography>
                                        </li>
                                    </ul>
                                </Typography>
                            </CardInfo>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormSignSend
                                ref={formRef}
                                plateformStyle={props.plateformStyle}
                                subscriber={props.subscriber}
                                form={props.form}
                                isControlFraud={props.isControlFraud}
                                send={updatePhoneSubscriber}
                                goToNext={props.goToNext} />
                        </Grid>
                    </Grid>
                </Card>
            }
            footer={
                <Button
                    className="Button_Goto_Step3_Signature"
                    variant='contained'
                    color={buttonSubmitStyle.color}
                    sx={{
                        width: {
                            sm: 400,
                            xs: '100%'
                        },
                        my: 5,
                        px: 5,
                        color: 'white'
                    }}
                    onClick={() => {
                        let isValid: boolean = false;

                        if (formRef.current)
                            isValid = formRef.current.handleSubmit();

                        if (isValid)
                            props.goToNext();
                    }} >
                    Recevoir le code
                </Button>
            } />
    )
}

import { Ref, forwardRef, useEffect, useImperativeHandle } from 'react';

import * as Sentry from '@sentry/react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import {Checkbox, FormControlLabel, FormHelperText, Link} from '@mui/material';
import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { FieldLabel } from '../../../components/Field/FieldLabel';
import { FormRadioButton } from '../../../components/Form/FormRadioButton';
import { TextFieldPhone } from '../../../components/Field/TextFieldPhone';

import { CallbackHours, CallbackType, ICallback, ICallbackDTO, enumCallbackHours } from '../../../model/Callback.model';

import { createCallback, updateCallback } from '../../../api/Callback.api';
import { messageFieldRequired } from '../../../utils/messages';

import { convertUTCDateToLocalDate, isClosedDay, isWeekend } from '../../../utils/dateUtils';
import { TextFieldFormatted } from '../../Field/TextFieldFormatted';

const dateToday = new Date();
const dateYesterday = new Date(dateToday);
dateYesterday.setDate(dateYesterday.getDate() - 1);

const yupSenderFirstname = yup.string().nullable().required(messageFieldRequired);
const yupSenderLastname = yup.string().nullable().required(messageFieldRequired);
const yupSenderPhone = yup.string().matches(/((\+?)33|32)(\s*\d){9}\s*$/g, 'Format de téléphone non valide').nullable().required(messageFieldRequired);
const yupSenderEmail = yup.string().email("Email non valide").nullable().required(messageFieldRequired);
const yupDateCall = yup
    .date()
    .typeError("Date non valide")
    .min(dateYesterday, "Date non valide")
    .nullable()
    .required(messageFieldRequired)
    .test(
        "excludedWeekend",
        "Nos équipes ne peuvent pas vous recontacter le weekend. Merci de choisir une autre date.",
        function (value) {
            if (value)
                return !isWeekend(value);
            
            return true;
        }
    )
    .test(
        "excludedClosed",
        "Nos équipes ne peuvent pas vous recontacter les jours fériés. Merci de choisir une autre date.",
        function (value) {
            if (value)
                return !isClosedDay(value);

            return true;
        }
    );
const yupHours = yup.string().nullable().required("Veuillez choisir un horaire de rappel.");
const yupSubject = yup.string().nullable();
const yupMessage = yup.string().nullable();

const getValidationSchema = (callback: ICallback) => {
    switch (callback.type) {
        case (CallbackType.CALLBACK):
        default:
            return yup.object({
                senderFirstname: yupSenderFirstname,
                senderLastname: yupSenderLastname,
                senderPhone: yupSenderPhone,
                dateCall: yupDateCall,
                hours: yupHours,
                subject: yupSubject,
                message: yupMessage,
                isConsent: yup.boolean().isTrue("Consentement obligatoire."),
                isConsent2: yup.boolean().isTrue("Consentement obligatoire.")
            });

        case (CallbackType.MESSAGE):
            return yup.object({
                senderFirstname: yupSenderFirstname,
                senderLastname: yupSenderLastname,
                senderEmail: yupSenderEmail,
                subject: yupSubject.required(messageFieldRequired),
                message: yupMessage.required(messageFieldRequired),
                isConsent: yup.boolean().isTrue("Consentement obligatoire."),
                isConsent2: yup.boolean().isTrue("Consentement obligatoire.")
            });
    };
};

interface FormValues {
    senderFirstname?: string;
    senderLastname?: string;
    senderPhone?: string;
    senderEmail?: string;
    dateCall?: Date | null;
    hours?: string;
    subject?: string;
    message?: string;
    isConsent: boolean;
    isConsent2: boolean;
}

interface Props {
    plateformStyle: any;
    payload: ICallback;
    send: (payload: ICallback) => void;
}

export const FormContact = forwardRef((props: Props, ref: Ref<any>) => {
    
    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();

            if (formik.isValid)
                return true;

            return false;
        }
    }));

    const plateformStyle: any = props.plateformStyle;
    const radioButtonStyle: any = plateformStyle.components.RadioButton;

    const initialValues: FormValues = {
        senderFirstname: props.payload.senderFirstname as string | undefined,
        senderLastname: props.payload.senderLastname as string | undefined,
        senderPhone: props.payload.senderPhone as string | undefined,
        senderEmail: props.payload.senderMail as string | undefined,
        dateCall: (props.payload.dateCallback || isWeekend(dateToday) || isClosedDay(dateToday)) ? new Date(new Date().setDate(dateToday.getDate() + (((1 + 7 - dateToday.getDay()) % 7) || 7))) : dateToday as Date,
        hours: props.payload.hours || CallbackHours.AS_SOON_AS_POSSIBLE,
        subject: props.payload.subject as string | undefined,
        message: props.payload.message as string | undefined,
        isConsent: false,
        isConsent2: false
    }

    const formik = useFormik({
        initialValues,
        validationSchema: getValidationSchema(props.payload),
        onSubmit: (values) => {

            const dto: ICallbackDTO = {
                ...props.payload,
                campaignId: props.payload.campaign?.id,
                senderLastname: values.senderLastname,
                senderFirstname: values.senderFirstname,
                senderPhone: values.senderPhone,
                senderMail: values.senderEmail,
                dateCallback: values.dateCall && values.dateCall !== null ? convertUTCDateToLocalDate(values.dateCall) : undefined,
                hours: values.hours as CallbackHours,
                subject: values.subject,
                message: values.message,
            }

            if (dto.senderPhone && dto.senderPhone[0] !== "+") dto.senderPhone = "+" + dto.senderPhone;

            if (props.payload?.id) {
                updateCallback(props.payload.id, dto).then((response: ICallback) => {
                    props.send(response)
                })
                .catch(exception => {
                    Sentry.captureException(exception);
                    props.send(props.payload);
                });
            }
            else {
                createCallback(dto).then((response: ICallback) => {
                    props.send(response)
                })
                .catch(exception => {
                    Sentry.captureException(exception);
                    props.send(props.payload)
                });
            }
        }
    })

    useEffect(() => {
        let date = formik.values.dateCall

        if (date && date !== null) {
            if (date.setHours(0, 0, 0, 0) === dateToday.setHours(0, 0, 0, 0))
                formik.setFieldValue("hours", CallbackHours.AS_SOON_AS_POSSIBLE)
            else if (formik.values.hours === CallbackHours.AS_SOON_AS_POSSIBLE) {
                formik.setFieldTouched("hours", false)
                formik.setFieldValue("hours", null)
            }
        }
    }, [formik.values.dateCall, formik.values.hours])


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={2}
                alignItems="flex-start">
                {/* <Grid
                    item
                    xs={12}>
                    <CardInfo
                        plateformStyle={plateformStyle}>
                        Nos équipes vous rappelleront dans les plus brefs délais.
                    </CardInfo>
                </Grid> */}

                {props.payload.type === CallbackType.CALLBACK &&
                <Grid
                    item
                    md={6}
                    xs={12}>
                    <FormLabel error={formik.touched.senderPhone && Boolean(formik.errors.senderPhone)} >
                        <FieldLabel label="Votre téléphone" isRequired />
                    </FormLabel>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{
                            mb: 2
                        }}>
                        Votre téléphone sera utilisé pour vous recontacter.
                    </Typography>
                    <TextFieldPhone
                        id="senderPhone"
                        fullWidth
                        value={formik.values.senderPhone}
                        onChange={(value) => formik.setFieldValue("senderPhone", value)}
                        error={formik.touched.senderPhone && Boolean(formik.errors.senderPhone)}
                        helperText={formik.touched.senderPhone && formik.errors.senderPhone} />
                </Grid>}

                {props.payload.type === CallbackType.MESSAGE &&
                <Grid
                    item
                    md={6}
                    xs={12}>
                    <FormLabel error={formik.touched.senderEmail && Boolean(formik.errors.senderEmail)} >
                        <FieldLabel label="Votre email" isRequired />
                    </FormLabel>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{
                            mb: 2
                        }}>
                        Votre email sera utilisé pour vous envoyer notre réponse.
                    </Typography>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="senderEmail"
                        name="senderEmail"
                        value={formik.values.senderEmail}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.senderEmail && Boolean(formik.errors.senderEmail)}
                        helperText={formik.touched.senderEmail && formik.errors.senderEmail} />
                </Grid>}

                <Grid
                    item
                    md={6}
                    xs={12}
                    sx={{
                        display: {
                            md: "flex",
                            xs: "none"
                        }
                    }}>
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}>
                    <FormLabel error={formik.touched.senderLastname && Boolean(formik.errors.senderLastname)} >
                        <FieldLabel label="Nom" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="senderLastname"
                        fullWidth
                        toUpperCase
                        charactersEnabled="[^A-zÀ-ú\-\s]"
                        textLength={100}
                        value={formik.values.senderLastname}
                        onChange={(e, value) => formik.setFieldValue("senderLastname", value)}
                        onBlur={(e) => formik.setFieldTouched("senderLastname")}
                        error={formik.touched.senderLastname && Boolean(formik.errors.senderLastname)}
                        helperText={formik.touched.senderLastname && formik.errors.senderLastname} />
                </Grid>

                <Grid
                    item
                    md={6}
                    xs={12}>
                    <FormLabel error={formik.touched.senderFirstname && Boolean(formik.errors.senderFirstname)} >
                        <FieldLabel label="Prénom" isRequired />
                    </FormLabel>
                    <TextFieldFormatted
                        id="senderFirstname"
                        fullWidth
                        capitalize
                        charactersEnabled="[^A-zÀ-ú\-\s]"
                        textLength={100}
                        value={formik.values.senderFirstname}
                        onChange={(e, value) => formik.setFieldValue("senderFirstname", value)}
                        onBlur={(e) => formik.setFieldTouched("senderFirstname")}
                        error={formik.touched.senderFirstname && Boolean(formik.errors.senderFirstname)}
                        helperText={formik.touched.senderFirstname && formik.errors.senderFirstname} />
                </Grid>

                {props.payload.type === CallbackType.CALLBACK &&
                <>
                <Grid
                    item
                    xs={12}>
                    <FormLabel error={(formik.touched.hours && Boolean(formik.errors.hours)) || (formik.touched.dateCall && Boolean(formik.errors.dateCall))} >
                        <FieldLabel label="Disponibilités" isRequired />
                    </FormLabel>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'>
                        Précisez à quelle date et heure vous souhaitez être recontacté.
                    </Typography>
                </Grid>

                <Grid
                    item
                    sm={6}
                    xs={12}>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            value={formik.values.dateCall}
                            onChange={(value: any) => formik.setFieldValue("dateCall", value)}
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            minDate={dateToday}
                            format='dd/MM/yyyy'
                            shouldDisableDate={(date) => {
                                return (isWeekend(date) || isClosedDay(date))
                            }}
                            slotProps={{
                                textField: {
                                    fullWidth: true,
                                    onBlur: (e: any) => formik.setFieldTouched("dateCall"),
                                    error: formik.touched.dateCall && Boolean(formik.errors.dateCall),
                                    helperText: formik.touched.dateCall && <>{formik.errors.dateCall}</>,
                                }
                            }} />
                    </LocalizationProvider>
                </Grid>

                <Grid
                    item
                    md={3}
                    sm={6}
                    xs={12}
                    sx={{
                        display: formik.values.dateCall && formik.values.dateCall !== null && formik.values.dateCall.setHours(0, 0, 0, 0) === dateToday.setHours(0, 0, 0, 0) ? "flex" : "none"
                    }}>
                    <FormRadioButton
                        code={CallbackHours.AS_SOON_AS_POSSIBLE}
                        selected={true}
                        color={radioButtonStyle.color}
                        onClick={(value) => {
                            formik.setFieldTouched("hours");
                            formik.setFieldValue("hours", value);
                        }} >
                        Dès que possible
                    </FormRadioButton>
                </Grid>

                <Grid
                    item
                    xs={12}
                    sx={{
                        display: formik.values.dateCall && formik.values.dateCall !== null && formik.values.dateCall.setHours(0, 0, 0, 0) === dateToday.setHours(0, 0, 0, 0) ? "none" : "flex"
                    }}>
                    <Grid
                        container
                        spacing={2} >
                        {enumCallbackHours.filter(_ => _.value !== CallbackHours.AS_SOON_AS_POSSIBLE).map(_ => {
                            return <Grid
                                item
                                sm={3}
                                xs={_.value === CallbackHours.AS_SOON_AS_POSSIBLE ? 12 : 6} >
                                <FormRadioButton
                                    code={_.value}
                                    selected={formik.values.hours === _.value}
                                    color={radioButtonStyle.color}
                                    onClick={(value) => {
                                        formik.setFieldTouched("hours");
                                        formik.setFieldValue("hours", value);
                                    }} >
                                    {_.label}
                                </FormRadioButton>
                            </Grid>
                        })}
                        {formik.touched.hours && Boolean(formik.errors.hours) &&
                        <Grid
                            item
                            xs={12}
                            sx={{pt: "0px !important"}} >
                            <FormHelperText
                                error={true} >
                                <>{formik.errors.hours}</>
                            </FormHelperText>
                        </Grid>}
                    </Grid>
                </Grid>
                </>}

                {props.payload.type === CallbackType.MESSAGE &&
                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.subject && Boolean(formik.errors.subject)} >
                        <FieldLabel label="Objet" isRequired={props.payload.type === CallbackType.MESSAGE} />
                    </FormLabel>
                    <TextField
                        fullWidth
                        variant="outlined"
                        id="subject"
                        name="subject"
                        value={formik.values.subject}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.subject && Boolean(formik.errors.subject)}
                        helperText={formik.touched.subject && formik.errors.subject} />
                </Grid>}

                <Grid
                    item
                    xs={12}>
                    <FormLabel error={formik.touched.message && Boolean(formik.errors.message)} >
                        <FieldLabel label="Message" isRequired={props.payload.type === CallbackType.MESSAGE} />
                    </FormLabel>
                    <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        variant="outlined"
                        id="message"
                        name="message"
                        value={formik.values.message}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.message && Boolean(formik.errors.message)}
                        helperText={formik.touched.message && formik.errors.message} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.isConsent}
                                onChange={e => formik.setFieldValue("isConsent", !formik.values.isConsent)}
                                color="success"
                                sx={{
                                    color: formik.touched.isConsent && Boolean(formik.errors.isConsent) ? plateformStyle.colors.red.main : plateformStyle.colors.black.secondary,
                                    pt: 0,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 32
                                    }
                                }} />}
                        label={
                            <Typography
                                fontWeight={500}
                                color={formik.touched.isConsent && Boolean(formik.errors.isConsent) ? "error" : "inherit"}
                                sx={{mt: 0.5}}>
                                J’accepte de recevoir des informations commerciales sur les produits et services proposés par la Mutuelle GSMC.                            </Typography>
                        } />

                    {formik.touched.isConsent && Boolean(formik.errors.isConsent) &&
                    <FormHelperText
                        error={true}
                        sx={{
                            ml: 5,
                            mb: 1
                        }} >
                        <>{formik.errors.isConsent}</>
                    </FormHelperText>}
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={formik.values.isConsent2}
                                onChange={e => formik.setFieldValue("isConsent2", !formik.values.isConsent2)}
                                color="success"
                                sx={{
                                    color: formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) ? plateformStyle.colors.red.main : plateformStyle.colors.black.secondary,
                                    pt: 0,
                                    '& .MuiSvgIcon-root': {
                                        fontSize: 32
                                    }
                                }} />}
                        label={
                            <Typography
                                fontWeight={500}
                                color={formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) ? "error" : "inherit"}
                                sx={{mt: 0.5}}>
                                J’accepte de recevoir des informations commerciales sur les produits et services proposés par les partenaires de la Mutuelle GSMC.                            </Typography>
                        } />

                    {formik.touched.isConsent2 && Boolean(formik.errors.isConsent2) &&
                        <FormHelperText
                            error={true}
                            sx={{
                                ml: 5,
                                mb: 1
                            }} >
                            <>{formik.errors.isConsent2}</>
                        </FormHelperText>}
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{ textAlign: 'justify', pt: 2, mt: 1 }}>
                        Les informations recueillies dans ce formulaire sont nécessaires à GSMC pour traiter votre demande de rappel. Les échanges téléphoniques peuvent faire l’objet d’un enregistrement à des fins d’évaluation de la qualité de service. Ces données sont conservées sur une durée maximum de 90 jours.
                    </Typography>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'
                        sx={{ textAlign: 'justify', my: 1 }}>
                        Vous pouvez exercer vos droits d’accès, de suppression, de rectification des données auprès du Délégué à la protection des données soit par courrier adressé à « Mutuelle GSMC – Service DPO – 95 Rue de Jemmapes – 59000 Lille » ou via votre espace personnel.
                    </Typography>
                    <Typography
                        variant='caption'
                        component='div'
                        fontSize='0.9rem'>
                        Pour plus d'information, consulter notre rubrique{' '}
                        <Link
                            variant="caption"
                            fontWeight={500}
                            href="https://www.mutuelle-gsmc.fr/rgpd"
                            target="_blank">
                            Protection des données personnelles
                        </Link>.
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                </Grid>
            </Grid>
        </form>
    )
})

import { ICampaign } from "./Campaign.model";
import { IForm } from "./Form.model";


export enum CallbackType {
    CALLBACK="CALLBACK",
    MESSAGE="MESSAGE"
}


export enum CallbackStatus {
    PENDING="PENDING",
    IN_PROGRESS="IN_PROGRESS",
    PROCESSED="PROCESSED"
}


export enum CallbackHours {
    AS_SOON_AS_POSSIBLE="AS_SOON_AS_POSSIBLE",
    H9_H11="H9_H11",
    H11_H13="H11_H13",
    H13_H15="H13_H15",
    H15_H17="H15_H17"
}


export const enumCallbackHours = [
    {
        label: "9h - 11h",
        value: CallbackHours.H9_H11
    },
    {
        label: "11h - 13h",
        value: CallbackHours.H11_H13
    },
    {
        label: "13h - 15h",
        value: CallbackHours.H13_H15
    },
    {
        label: "15h - 17h",
        value: CallbackHours.H15_H17
    },
    {
        label: "Dès que possible",
        value: CallbackHours.AS_SOON_AS_POSSIBLE
    },
]

export interface ICallback {
    id?: number;
    techDateCreated?: Date;
    techDateModification?: Date;
    form?: IForm;
    campaign?: ICampaign;
    type: CallbackType;
    status: CallbackStatus;
    dateCallback?: Date;
    hours?: CallbackHours;
    subject?: string;
    message?: string;
    senderLastname?: string;
    senderFirstname?: string;
    senderMail?: string;
    senderPhone?: string;
    offerCode?: string;
}

export interface ICallbackDTO {
    id?: number;
    form?: IForm;
    campaignId?: number;
    type: CallbackType;
    status?: CallbackStatus;
    dateCallback?: Date;
    hours?: CallbackHours;
    subject?: string;
    message?: string;
    senderLastname?: string;
    senderFirstname?: string;
    senderMail?: string;
    senderPhone?: string;
    offerCode?: string;
}


import React from 'react';

import Box from '@mui/system/Box';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';


interface Props {
    plateformStyle: any;
    label: string;
    icon: any;
    isActive: boolean;
    isGoToEnabled: boolean;
    goToStep: () => void;
}


export const HeaderStep = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const componentStyle: any = plateformStyle.components.Stepper;

    return (
        <Step
            sx={{
                textAlign: 'center'
            }}
            key={props.label}
            style={{ cursor: props.isGoToEnabled ? "pointer" : undefined }}
            onClick={props.isGoToEnabled ? () => props.goToStep() : undefined} >

            <Box
                sx={{
                    width: {
                        md: 50,
                        sm: 40,
                        xs: 35
                    },
                    mx: {
                        md: 'none',
                        xs: 'auto'
                    },
                    cursor: props.isGoToEnabled ? "pointer" : "default",
                    transition: "transform 400ms",
                    ':hover': {
                        transform: props.isGoToEnabled ? "translateY(-6px)" : "none"
                    }
                }}
                >
                {React.cloneElement(props.icon, { color: props.isActive ? componentStyle.step.color : plateformStyle.colors.grey.main})}
            </Box>

            <StepLabel
                sx={{
                    display: {
                        md: 'block',
                        xs: 'none'
                    }
                }} >
                {props.label}
            </StepLabel>
        </Step>
    )
}

import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

import { cpam } from "../../utils/cpam";


interface Props {
    label?: string | JSX.Element;
    placeholder?: string;
    value?: string;
    disabled?: boolean;
    fullWidth?: boolean;
    onChange: (value: any) => void;
    onBlur?: (e: any) => void;
    error?: boolean;
    helperText?: boolean | string;
}


export const FieldCPAM = (props: Props) => {

    return (
        <Autocomplete
            disabled={props.disabled}
            disablePortal
            value={cpam.find(_ => _.value === props.value)}
            options={cpam}
            getOptionLabel={(_) => {
                return `${_.value} - ${_.label}`;
            }}
            onChange={(e, value) => props.onChange(value)}
            onBlur={(e) => {
                if (props.onBlur !== undefined)
                    props.onBlur(e);
            }}
            renderInput={
                (params) => {
                    return (<TextField
                        {...params}
                        label={props.label}
                        placeholder={props.placeholder}
                        error={props.error}
                        helperText={props.helperText} />)
                }
            }/>
    )
}

import React, { useState } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { InfoIcon } from '../Icon/InfoIcon';
import { DialogInfoRIB } from '../Dialog/DialogInfoRIB';
import { TextFieldFormatted } from './TextFieldFormatted';

var IBAN = require('iban');

interface Props {
    plateformStyle?: any;
    id: string;
    label?: string | JSX.Element;
    value: string | undefined;
    showInfo?: boolean;
    onChange: (event: React.ChangeEvent, value: string, error: string) => void;
    onBlur?: (e: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
    fullWidth?: boolean;
}


export const separator: string = " ";

const minLengthIBAN: number = 5;
const maxLengthIBAN: number = 34;
export const defaultParamsIBAN = {
    code: "",
    pattern: [4, 8, 12, 16, 20, 24, 28, 32, 34],
    length: maxLengthIBAN
}


// IBANs informations according to the country (two first letters of the IBAN).
const IBANs = [
    {
        code: "FR",
        pattern: [4, 8, 12, 16, 20, 24, 27],
        length: 27
    },
    {
        code: "BE",
        pattern: [4, 8, 12, 16],
        length: 16
    },
    {
        code: "CH",
        pattern: [4, 8, 12, 16, 20, 21],
        length: 21
    },
    {
        code: "DE",
        pattern: [4, 8, 12, 16, 20, 22],
        length: 22
    },
    {
        code: "MT",
        pattern: [4, 8, 12, 16, 20, 24, 28, 31],
        length: 31
    },
]


function checkIBAN(value: string, paramsIBAN: any): string {
    if (!value)
        return "";

    if (value.length >= 2 && !value.startsWith("FR"))
        return "Seuls les IBAN français sont autorisés";

    // Check the two first characters (country code).
    if (!IBAN.isValid(value))
        return "IBAN non valide";

    return "";
}


export const TextFieldIBAN = (props: Props) => {

    const iconStyle: any = props.plateformStyle?.components.IconHeader;

    const [isDialogOpened, setIsDialogOpened] = useState(false);

    const [paramsIBAN, setParamsIBAN] = useState(defaultParamsIBAN);

    const onChange = (event: React.ChangeEvent, value: string) => {
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
        let paramsIBAN: { code: string, pattern: number[], length: number } | undefined;
        let error: string;

        value = target.value;

        // Get the IBAN parameters (if the country is unknown the IBAN will not be formatted).
        paramsIBAN = IBANs.find(e => e.code === value.substring(0, 2));

        if (!paramsIBAN)
            paramsIBAN = defaultParamsIBAN;

        setParamsIBAN(paramsIBAN);

        error = checkIBAN(value, paramsIBAN);

        if (value === props.value) return;

        props.onChange(event, value, error);
    }


    return (
        <Grid
            container
            alignItems="center"
            gap={1}>
            <Grid
                item
                xs>
                <TextFieldFormatted
                    id={props.id}
                    label={props.label}
                    fullWidth
                    value={props.value}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    error={props.error}
                    helperText={props.helperText}
                    toUpperCase
                    textLength={paramsIBAN.length}
                    charactersEnabled="[^0-9A-Za-z]"
                    pattern={paramsIBAN.pattern}
                    separator={separator} />
            </Grid>

            {props.showInfo &&
            <Grid
                item
                xs='auto'
                sx={{
                    pb: props.error ? 3 : 0,
                    transition: "transform 400ms",
                    ":hover": {
                        transform: "translateY(-6px)"
                    }
                }}>
                <Tooltip title="Où le trouver ?" placement="top">
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setIsDialogOpened(true)}>
                        <InfoIcon color={iconStyle?.color || "black"} />
                    </IconButton>
                </Tooltip>

                <DialogInfoRIB
                    plateformStyle={props.plateformStyle}
                    isDisplayed={isDialogOpened}
                    onClose={() => setIsDialogOpened(false)} />
            </Grid>}
        </Grid>
    )
}

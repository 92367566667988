import { colors } from './colors';

import { enumPlateformCodeLesFurets } from '../../../utils';


export const style = {
    plateform: enumPlateformCodeLesFurets,
    colors: colors,
    components: {
        Button: {
            submit: {
                color: 'warning'
            }
        },
        Card: {
            contact: {
                color: colors.blue.main,
                bgColor: colors.blue.secondary
            },
            support: {
                color: colors.orange.main,
                bgColor: colors.orange.secondary
            }
        },
        Formules: {
            colors: [],
            defaultColor: colors.orange.main
        },
        Icon: {
            color: colors.blue.main,
            bgColor: colors.blue.secondary
        },
        IconHeader: {
            color: colors.orange.main,
            bgColor: colors.orange.secondary
        },
        IconInfo: {
            color: colors.blue.main,
            bgColor: colors.blue.secondary
        },
        Link: {
            color: colors.blue.main,
        },
        List: {
            color: colors.blue.main,
        },
        Offer: {
            color: colors.orange.main,
            recommendedColor: colors.blue.main
        },
        ProgressBar: {
            form: {
                color: 'primary'
            },
            loading: {
                color: 'primary'
            }
        },
        RadioButton: {
            color: colors.blue.main
        },
        Stepper: {
            step: {
                color: colors.orange.main
            },
            progressBar: {
                color: "warning"
            }
        }
    }
}

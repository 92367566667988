import { IPasswordChangeRequest, IPasswordResetRequest, ISigninRequest, ISignupRequest } from "../../model/auth/Authentication.model"
import AuthService from "../../services/AuthService";

const apiUrl = process.env.REACT_APP_API_URL + '/auth'


export const signin = (payload: ISigninRequest) => {
    return fetch(`${apiUrl}/signin`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(payload)
        }).then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const signup = (payload: ISignupRequest, token: string) => {
    return fetch(`${apiUrl}/signup`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "authorization": `Bearer ${token}`
            },
            body: JSON.stringify(payload)
        }).then(_ => {
            if ([401, 403, 404, 500, 503].includes(_.status)) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const resetPassword = (payload: IPasswordResetRequest) => {
    return fetch(`${apiUrl}/password/reset`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json"
            },
            body: JSON.stringify(payload)
        }).then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


export const changePassword = (payload: IPasswordChangeRequest) => {
    const authService = new AuthService();

    return fetch(`${apiUrl}/password/change`,
        {
            method: "POST",
            headers: {
                "content-type": "application/json",
                "Authorization": authService.getToken() || ""
            },
            body: JSON.stringify(payload)
        }).then(_ => {
            if (_.status / 100 >= 3) {
                throw new Error(_.status.toString())
            }
            return _.json()
        })
}


import Card from "@mui/material/Card";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";

import { ArrowLeftIcon } from "../Icon/ArrowLeftIcon";
import { ArrowRightIcon } from "../Icon/ArrowRightIcon";

import { Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useState } from "react";


interface Props {
    plateformStyle: any;
    label?: string;
    labelColor?: string;
    isPrevious?: Boolean;
    isNext?: Boolean;
    isMinified?: Boolean;
    onClick?: () => void;
}


export const CardOfferButton = (props: Props) => {
    const theme = useTheme();

    const screenSizeUpXS = useMediaQuery(theme.breakpoints.only('xs'));

    const [open, setOpen] = useState(false);

    const handleClose = () => {
        setOpen(false);
    };
  
    const handleOpen = () => {
        if (screenSizeUpXS) setOpen(true);
    };
  

    return (
        <Card
            sx={{
                textAlign: 'center',
                my: 'auto',
                ml: {
                    sm: props.isNext ? 2 : 0,
                    xs: props.isNext ? 1 : 0,
                },
                mr: {
                    sm: props.isPrevious ? 2 : 0,
                    xs: props.isPrevious ? 1 : 0,
                },
                p: {
                    sm: 1,
                    xs: 0
                },
                background: {
                    sm: props.plateformStyle.colors.grey.secondary,
                    xs: "none"
                },
                border: props.isMinified ? 2 : 0,
                borderColor: props.labelColor || theme.palette.primary.main
            }} >

            <Typography
                variant='caption'
                component='div'
                sx={{
                    display: {
                        sm: "block",
                        xs: "none"
                    },
                    color: props.labelColor || theme.palette.primary.main
                }} >
                {props.label || ""}
            </Typography>

            <Tooltip
                open={open}
                onClose={handleClose}
                onOpen={handleOpen}
                title={props.label || ""}
                placement={props.isPrevious ? "right" : "left"}
                componentsProps={{
                    tooltip: {
                        sx: {
                            borderRadius: 4,
                            border: 2,
                            borderColor: props.labelColor || theme.palette.primary.main,
                            color: props.labelColor || theme.palette.primary.main,
                            bgcolor: props.plateformStyle.colors.grey.secondary
                        }
                    }
                }}
                slotProps={{
                    popper: {
                        modifiers: [
                        {
                            name: 'offset',
                            options: {
                                offset: [0, -14],
                            },
                        },
                        ],
                    },
                }}>
                <IconButton
                    aria-label="previous-formule"
                    sx={{
                        mt: props.label ? 1 : 0,
                        transition: "transform 400ms",
                        ':hover': {
                            transform: "translateY(-6px)"
                        }
                    }}
                    onClick={props.onClick} >
                    {props.isPrevious ? <ArrowLeftIcon color={props.plateformStyle.colors.black.main} /> : <ArrowRightIcon color={props.plateformStyle.colors.black.main} />}
                </IconButton>
            </Tooltip>
        </Card>
    )
}

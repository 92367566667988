import decode from 'jwt-decode';
import { signin } from '../api/auth/Authentication.api';
import { ISigninRequest } from '../model/auth/Authentication.model';

const apiUrl = process.env.REACT_APP_API_URL + '/auth'

export default class AuthService {

    // Initializing important variables
    constructor() {
        this.login = this.login.bind(this)
        this.getProfile = this.getProfile.bind(this)
    }

    login(payload: ISigninRequest) {
        // Get a token from api server using the fetch api
        return signin(payload)
            .then(response => {
                this.setToken(response.token);

                // TODO : set refresh token

                return response
            })
            .catch(error => {
                throw error
            })
    }

    isLoggedIn() {
        // Checks if there is a saved token and it's still valid
        const token = this.getToken() // Getting token from localstorage
        return !!token && !this.isTokenExpired(token) // handwaiving here
    }

    isTokenExpired(token: string) {
        try {
            const decoded: any = decode(token);
            if (decoded.exp < Date.now() / 1000) { // Checking if token is expired.
                return true;
            }
            else
                return false;
        }
        catch (err) {
            return false;
        }
    }

    setToken(idToken: string) {
        // Saves user token to localStorage
        localStorage.setItem('id_token', idToken)
    }

    getToken(): string | undefined {
        // Retrieves the user token from localStorage.
        const token = localStorage.getItem('id_token');

        if (token === null || token === undefined || token.length === 0)
            return;

        if (this.isTokenExpired(token)) {
            this.refresh()  // TODO
        }

        return token;
    }

    logout() {
        // Clear user token and profile data from localStorage
        localStorage.removeItem('id_token');
    }

    refresh() {
        //TODO
    }

    getProfile() {
        if (this.isLoggedIn())
            return decode(this.getToken() as string);

        return undefined
    }

    _checkStatus(response: any) {
        // raises an error in case response status is not a success
        if (response.status >= 200 && response.status < 300) {
            return response
        } else {
            var error: any = new Error(response.statusText)
            error.response = response
            throw error
        }
    }

    _parseJSON(response: any) {
        return response && response.text().then(function (text: string) {
            return text ? JSON.parse(text) : {}
        })
    }

    hasAnyAuthorities(hasAnyAuthorities: String[]) {
        if (this.isLoggedIn()) {
            const profile: any = this.getProfile();
            const authorities = profile.role;
            if (authorities && authorities.length !== 0) {
                if (hasAnyAuthorities.length === 0) {
                    return true;
                }
                return hasAnyAuthorities.some(auth => authorities.includes(auth));
            }
            return false;
        }
        return false;
    }

    getUserInfo() {
        if (this.isLoggedIn()) {
            const profile: any = this.getProfile();

            return {
                firstname: profile.firstname,
                lastname: profile.lastname
            }
        }

        return undefined
    }

    getRequestHeaders(headers: any) {
        if (this.isLoggedIn())
            headers["Authorization"] = `Bearer ${this.getToken()}`;

        return headers;
    }
}

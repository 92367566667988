import { forwardRef, Ref, useEffect, useImperativeHandle } from 'react';

import * as yup from 'yup';
import { useFormik } from 'formik'

import FormLabel from '@mui/material/FormLabel';
import Grid from '@mui/material/Grid';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { FormRadioButton } from './FormRadioButton';
import { FieldLabel } from '../Field/FieldLabel';

import { IContractDebiting } from '../../model/ContractDebiting.model';

import { enumContractPeriodicities,
         enumContractPeriodicityAnnually,
         messageFieldRequired } from '../../utils';


interface Props {
    debiting: IContractDebiting;
    isModificationEnabled?: boolean;
    send: (debiting: IContractDebiting) => void;
    save: (debiting: IContractDebiting) => void;
    sendProgress?: (progress: number) => void;
}

const defaultDate = new Date();
defaultDate.setDate(5);

const validationSchema = yup.object({
    date: yup
        .date()
        .typeError("Date non valide")
        .nullable()
        .required(messageFieldRequired),
    periodicity: yup
        .string()
        .required(messageFieldRequired),
});


export const FormDebiting = forwardRef((props: Props, ref: Ref<any>) => {

    useImperativeHandle(ref, () => ({
        handleSubmit() {
            formik.handleSubmit();

            if (formik.isValid)
                return true;

            return false;
        },
        leave() {
            send();
        }
    }));

    const formik = useFormik({
        initialValues: {
            date: props.debiting.dateDebiting && props.debiting.dateDebiting !== null ? new Date(props.debiting.dateDebiting) : defaultDate,
            periodicity: props.debiting.periodicity ? props.debiting.periodicity : enumContractPeriodicities[0].value
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            save();
        }
    });

    const save = () => {
        let debiting: IContractDebiting = { ...props.debiting };

        debiting.dateDebiting = formik.values.date ? formik.values.date : undefined;
        debiting.periodicity = formik.values.periodicity;

        props.save(debiting);
    }

    const send = () => {
        let debiting: IContractDebiting = { ...props.debiting };

        debiting.dateDebiting = formik.values.date ? formik.values.date : undefined;
        debiting.periodicity = formik.values.periodicity;

        props.send(debiting);
    }

    const sendProgress = () => {
        if (props.sendProgress)
            props.sendProgress((Object.keys(formik.values).length - Object.keys(formik.errors).length) / 2 * 100);
    }

    useEffect(() => {
        sendProgress();
    }, [])

    useEffect(() => {
        sendProgress();
    }, [formik.values,
        formik.errors])

    useEffect(() => {
        formik.validateForm();
    }, [props.debiting])


    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <FormLabel error={formik.touched.periodicity && Boolean(formik.errors.periodicity)} >
                        <FieldLabel label="Périodicité" isRequired />
                    </FormLabel>
                    <Grid
                        container
                        spacing={1} >
                        {enumContractPeriodicities.map(_ => {
                            return (
                                <Grid
                                    item
                                    xs={4} >
                                    <FormRadioButton
                                        code={_.value}
                                        selected={formik.values.periodicity === _.value}
                                        onClick={(value) => formik.setFieldValue("periodicity", value)}>
                                        {_.label}
                                    </FormRadioButton>
                                </Grid>);
                        })}
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >

                    {formik.values.periodicity === enumContractPeriodicityAnnually ?
                    <>
                    <FormLabel error={formik.touched.date && Boolean(formik.errors.date)} >
                        <FieldLabel label="Date de prélèvement" isRequired />
                    </FormLabel>
                    <LocalizationProvider
                        dateAdapter={AdapterDateFns}
                        adapterLocale={fr}
                        localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                        <DatePicker
                            views={['month', 'day']}
                            value={formik.values.date}
                            onChange={(value: any) => formik.setFieldValue("date", value)}
                            localeText={{
                                toolbarTitle: "Sélectionnez une date",
                                okButtonLabel: "Valider"
                            }}
                            format='dd/MM'
                            slotProps={{
                                textField: {
                                    sx: {
                                        width: '100%',
                                        maxWidth: '400px'
                                    },
                                    onBlur: (e) => formik.setFieldTouched("date"),
                                    error: formik.touched.date && Boolean(formik.errors.date),
                                    helperText: formik.touched.date && <>{formik.errors.date}</>
                                },
                            }} />
                    </LocalizationProvider>
                    </>

                    :
                    <>
                    <FormLabel error={formik.touched.periodicity && Boolean(formik.errors.periodicity)} >
                        <FieldLabel label="Jour de prélèvement" isRequired />
                    </FormLabel>
                    <Grid
                        container
                        spacing={1} >
                        <Grid
                            item
                            xs={4} >
                            <FormRadioButton
                                code="5"
                                selected={formik.values.date && formik.values.date.getDate().toString() === "5"}
                                onClick={(value) => {
                                    let d = new Date();
                                    d.setDate(5);
                                    d.setMonth(0);
                                    formik.setFieldValue("date", d)
                                }}>
                                5 du mois
                            </FormRadioButton>
                        </Grid>
                        <Grid
                            item
                            xs={4} >
                            <FormRadioButton
                                code="10"
                                selected={formik.values.date && formik.values.date.getDate().toString() === "10"}
                                onClick={(value) => {
                                    let d = new Date();
                                    d.setDate(10);
                                    d.setMonth(0);
                                    formik.setFieldValue("date", d)
                                }}>
                                10 du mois
                            </FormRadioButton>
                        </Grid>
                        <Grid
                            item
                            xs={4} >
                            <FormRadioButton
                                code="15"
                                selected={formik.values.date && formik.values.date.getDate().toString() === "15"}
                                onClick={(value) => {
                                    let d = new Date();
                                    d.setDate(15);
                                    d.setMonth(0);
                                    formik.setFieldValue("date", d)
                                }}>
                                15 du mois
                            </FormRadioButton>
                        </Grid>
                    </Grid>
                    </>}
                </Grid>
            </Grid>
        </form>
    )
})

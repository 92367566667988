import { IOffre, IPageOffre } from "../model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getOffres = (page: number, pageSize: number, search?: string) : Promise<IPageOffre> => {

    let url: string = `${apiUrl}/offre?page=${page}&pageSize=${pageSize}`

    if (search)
        url += `&search=${search}`

    return fetch(url,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const getOffre = (id: number) : Promise<IOffre> => {

    return fetch(`${apiUrl}/offre/${id}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}

export const createOffre = (offre: IOffre) : Promise<IOffre> => {

    return fetch(`${apiUrl}/offre`,
    {
        method: "POST",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(offre)

    }).then(_ => _.json()) 
}

export const modifyOffre = (offre: IOffre) : Promise<IOffre> => {

    return fetch(`${apiUrl}/offre`,
    {
        method: "PUT",
        headers:{"content-type":"application/json"},
        body: JSON.stringify(offre)

    }).then(_ => _.json()) 
}

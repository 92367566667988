import { cloneElement, useState } from 'react';

import { Collapse, useTheme } from '@mui/material';
import Box from '@mui/system/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { ChevronUpIcon } from '../Icon/ChevronUpIcon';


interface Props {
    plateformStyle: any;
    label: string;
    icon?: JSX.Element;
    children: JSX.Element;
    isOpen?: boolean;
}


export const Accordion = (props: Props) => {

    const theme = useTheme();

    const plateformStyle: any = props.plateformStyle;

    const [isOpen, setIsOpen] = useState<boolean>(props.isOpen || false);


    return (
        <Card
            sx={{
                px: 1.5,
                py: 1
            }} >
            <Grid
                container
                justifyContent='space-between'
                sx={{
                    cursor: 'pointer',
                    my: 'auto'
                }}
                onClick={() => setIsOpen(!isOpen)} >
                <Grid
                    item
                    xs
                    sx={{
                        display: 'inline-flex'
                    }} >
                    {props.icon && 
                    <Box
                        sx={{
                            width: {
                                sm: "40px",
                                xs: "30px"
                            },
                            minWidth: {
                                sm: "40px",
                                xs: "30px"
                            },
                            pt: '4px',
                            mr: 2 
                        }}>
                        {cloneElement(props.icon, {width: "auto"})}
                    </Box>}
                    <Typography variant='h3' sx={{ my: 'auto'}} >{props.label}</Typography>
                </Grid>

                <Grid
                    item
                    xs="auto"
                    sx={{
                        pt: '4px',
                        my: 'auto',
                        transform: isOpen ? "none" : "rotate(180deg)",
                        transition: theme.transitions.create("all", {
                            easing: theme.transitions.easing.easeIn, 
                            duration: theme.transitions.duration.leavingScreen
                        })
                    }} >
                    <ChevronUpIcon color={plateformStyle.colors.black.main} />
                </Grid>
            </Grid>

            <Grid
                container
                justifyContent='space-between'
                sx={{
                    width: "100%",
                    transition: theme.transitions.create("all", {
                        easing: theme.transitions.easing.easeInOut, 
                        duration: theme.transitions.duration.leavingScreen
                    }),
                }} >
                <Collapse in={isOpen} sx={{width: "100%"}}>{props.children}</Collapse>
            </Grid>
        </Card>
    )
}

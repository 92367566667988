import { createRef, useState } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";

import SendIcon from '@mui/icons-material/Send';

import { IForm } from '../../model/Form.model';

import { TransitionSlideUP } from '../../utils/transistions/transitions';
import { CallbackStatus, CallbackType, ICallback } from '../../model/Callback.model';
import { FormContact } from '../Form/Contact/FormContact';
import Portal from '@mui/material/Portal';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertColor } from '@mui/material/Alert';
import Slide from '@mui/material/Slide';
import { CheckFullIcon } from '../Icon/CheckFullIcon';
import { CloseFullIcon } from '../Icon/CloseFullIcon';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';


interface Props {
    plateformStyle: any;
    isOpened: boolean;
    callback?: ICallback;
    handleClose: () => void;
}


export const DialogSupport = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle: any = props.plateformStyle;

    const formRef = createRef<HTMLFormElement>();

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);
    const [snackbarMessage, setSnackbarMessage] = useState<string>();
    const [snackbarSeverity, setSnackbarSeverity] = useState<string>("success");
    
    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    return (
        <>
        <Dialog
            fullWidth
            maxWidth="md"
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}
            PaperProps={{
                sx: {
                    borderRadius: {
                        sm: 4,
                        xs: 4
                    }
                }
            }} >

            <DialogTitle>
                <Typography variant="h2">Demandez à être rappelé !</Typography>
            </DialogTitle>

            <DialogContent>
                <FormContact
                    ref={formRef}
                    plateformStyle={plateformStyle}
                    payload={{...props.callback, type: CallbackType.CALLBACK, status: CallbackStatus.PENDING}}
                    send={(_) => {
                        if (_.id !== undefined) {
                            setSnackbarMessage("Votre demande de rappel a bien été prise en compte.");
                            setSnackbarSeverity("success")
                            setIsSnackbarOpened(true)
                        }
                        else {
                            setSnackbarMessage("Une erreur est survenue lors de votre demande de rappel.");
                            setSnackbarSeverity("error")
                            setIsSnackbarOpened(true)
                        }

                        props.handleClose()
                    }} />
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2
                }}>
                <Button
                    onClick={() => props.handleClose()}
                    sx={{
                        mr: 2,
                        color: plateformStyle.colors.black.main
                    }} >
                    Fermer
                </Button>
                <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SendIcon />}
                    onClick={() => {
                        if (formRef.current)
                            formRef.current.handleSubmit();
                    }}
                    sx={{
                        px: 3,
                        color: 'white',
                        backgroundColor: plateformStyle.colors.black.main,
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }} >
                    Envoyer
                </Button>
            </DialogActions>
        </Dialog>

        <Portal>
            <Snackbar
                sx={{
                    zIndex: 1000,
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                TransitionComponent={(_) => <Slide {..._} direction="down" />} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                    }}
                    severity={snackbarSeverity as AlertColor}
                    iconMapping={{
                        success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                        error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleSnackbarClose}>
                            <CloseIcon fontSize="inherit" fontWeight={700} />
                        </IconButton>
                    }>
                    <Typography fontWeight={500}>{snackbarMessage}</Typography>
                </Alert>
            </Snackbar>
        </Portal>
        </>
    )
}

import { createRef, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import { Alert, IconButton, Slide, Snackbar } from '@mui/material';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { default as MUICloseIcon} from '@mui/icons-material/Close';
import LoadingButton from '@mui/lab/LoadingButton'
import TelegramIcon from '@mui/icons-material/Telegram';
import WarningIcon from '@mui/icons-material/Warning';

import { DialogSuccess } from '../../components/Dialog/DialogSuccess';
import { MainHeader } from "../../components/Header/MainHeader";

import { CallbackStatus, CallbackType, ICallback, ICallbackDTO, enumCallbackHours } from '../../model/Callback.model';
import { ICampaign, ICampaignSettingsFrontDTO } from '../../model/Campaign.model';

import { getCampaignByToken, getCampaignSettingsFront } from '../../api/Campaign.api';

import { gsmcUrl } from '../../utils';

import { theme as themeGSMC } from '../../static/themes/gsmc/theme';
import { style as styleGSMC } from '../../static/themes/gsmc/style';
import { getPlateformStyle, getPlateformTheme } from '../../static/themes/theme';
import { CheckFullIcon } from '../../components/Icon/CheckFullIcon';
import { CloseFullIcon } from '../../components/Icon/CloseFullIcon';
import { FormContact } from '../../components/Form/Contact/FormContact';
import { IForm } from '../../model/Form.model';
import { getFormContext } from '../../api/Form.api';


export const Contact = () => {

    const navigate = useNavigate();
    
    document.title = 'Mutuelle GSMC - Rappel';

    // URL parameters.
    const campaignToken = new URLSearchParams(window.location.search).get("campaign");
    const formUuid = new URLSearchParams(window.location.search).get("form");
    const offerCode = new URLSearchParams(window.location.search).get("offer");

    const [theme, setTheme] = useState<any>(themeGSMC);
    const [plateformStyle, setPlateformStyle] = useState<any>(styleGSMC);

    const [isSnackbarDisplayed, setIsSnackbarDisplayed] = useState<boolean>(false);
    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarDisplayed(false);
    };

    const formRef = createRef<HTMLFormElement>();

    const [callback, setCallBack] = useState<ICallback>({
        type: CallbackType.CALLBACK,
        status: CallbackStatus.PENDING
    });

    const [form, setForm] = useState<IForm | null>();
    const [campaign, setCampaign] = useState<ICampaign | null>();
    const [campaignSettings, setCampaignSettings] = useState<ICampaignSettingsFrontDTO | null>();
    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);
    const [isSubmiting, setIsSubmiting] = useState<boolean>(false);

    useEffect(() => {
        if (formUuid === null && campaignToken === null)
            return;

        if (formUuid === null && offerCode !== null)
            setCallBack((prev) => {return {...prev, offerCode: offerCode}});

        if (formUuid !== null) {
            getFormContext(formUuid)
            .then((response) => {
                let f = {...response.form, plateform: response.plateform}
                setForm(f);
                setCallBack((prev) => {return {
                    ...prev,
                    form: f,
                    senderLastname: f.contract?.person.lastname,
                    senderFirstname: f.contract?.person.firstname,
                    senderMail: f.contract?.person.email,
                    senderPhone: f.contract?.person.phoneMobile
                }});
            })
            .catch(exception => {
                setForm(null);
            })
        }
        
        if (campaignToken !== null) {
            getCampaignByToken(campaignToken)
            .then((response) => {
                setCampaign(response);
                setCallBack((prev) => {return {...prev, campaign: response}});
    
                return getCampaignSettingsFront(response.code);
            })
            .then((response) => {
                setCampaignSettings(response);
            })
            .catch(exception => {
                setCampaign(null);
                setCampaignSettings(null);
            })
        }
    }, [])

    useEffect(() => {
        if (formUuid !== null) {
            setTheme(getPlateformTheme(form?.plateform?.code));
            setPlateformStyle(getPlateformStyle(form?.plateform?.code));
        }
        else {
            setTheme(getPlateformTheme(campaign?.plateforme?.code));
            setPlateformStyle(getPlateformStyle(campaign?.plateforme?.code));
        }
    }, [form?.plateform?.code, campaign?.plateforme?.code])


    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    height: '100%'
                }} >

                <MainHeader
                    plateformStyle={plateformStyle}
                    isReturnEnabled={false}
                    goToPrevious={() => { }} />

                <Box
                    sx={{
                        maxWidth: '1200px',
                        width: '100%',
                        mx: 'auto',
                        marginTop: '20px'
                    }} >
                    <Grid
                        container
                        spacing={2}
                        alignItems="flex-start"
                        sx={{
                            px: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <Typography variant="h2">Demandez à être rappelé !</Typography>
                        </Grid>

                        {form === undefined && campaign === undefined ?
                        <Grid
                            item
                            xs={12} >
                            <Card
                                sx={{
                                    height: '150px',
                                    textAlign: 'center'
                                }}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        height: '100%'
                                    }} >
                                    <Grid
                                        item>
                                        <Typography
                                            variant='subtitle1'>
                                            Chargement du formulaire de contact en cours...
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        : form === null || campaign === null ?
                        <Grid
                            item
                            xs={12} >
                            <Card
                                sx={{
                                    height: '150px',
                                    textAlign: 'center'
                                }}>
                                <Grid
                                    container
                                    spacing={0}
                                    direction="column"
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        height: '100%'
                                    }} >
                                    <Grid
                                        item>
                                        <WarningIcon
                                            fontSize="large"
                                            sx={{
                                                color: plateformStyle.colors.red.main
                                            }} />
                                    </Grid>
                                    <Grid
                                        item>
                                        <Typography
                                            variant='subtitle1'>
                                            Une erreur est survenue lors du chargement du formulaire
                                        </Typography>
                                    </Grid>
                                    <Grid
                                        item>
                                        <Typography
                                            variant='body2'>
                                            Veuillez réessayer ultérieurement
                                        </Typography>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>

                        :
                        <>
                        <Grid
                            item
                            xs={12}>
                            <FormContact
                                ref={formRef}
                                plateformStyle={plateformStyle}
                                payload={callback}
                                send={(_) => {
                                    if (!_.id)
                                        setIsSnackbarDisplayed(true);
                                }} />
                        </Grid>

                        <Grid
                            item
                            xs={12}
                            sx={{
                                textAlign: {
                                    sm: 'end',
                                    xs: 'center'
                                }
                            }}>
                            <LoadingButton
                                variant="contained"
                                color='success'
                                loading={isSubmiting}
                                startIcon={<TelegramIcon />}
                                onClick={() => {
                                    setIsSubmiting(true);

                                    let isValid: boolean = false;

                                    if (formRef.current)
                                        isValid = formRef.current.handleSubmit();

                                    setIsDialogOpened(isValid);
                                    setIsSubmiting(false);
                                }}
                                loadingIndicator={
                                    <Typography
                                        noWrap
                                        variant="button"
                                        sx={{
                                            textTransform: 'none',
                                            textAlign: 'center',
                                            color: 'white'
                                        }}>
                                        Envoi en cours...
                                    </Typography>
                                }
                                sx={{
                                    color: 'white',
                                }}>
                                Envoyer ma demande
                            </LoadingButton>
                        </Grid>
                        </>}

                        <Grid
                            item
                            xs={12}>
                        </Grid>
                    </Grid>
                </Box>
            </Box>

            <Backdrop
                sx={{
                    backdropFilter: "blur(3px)",
                    backgroundColor: "#FFFFFFAA",
                    zIndex: (theme) => theme.zIndex.drawer + 1
                }}
                open={isDialogOpened}>
                <DialogSuccess
                    plateformStyle={plateformStyle}
                    isOpened={isDialogOpened}
                    title="Félicitations !"
                    description="Votre demande de contact a bien été prise en compte"
                    handleContinue={() => {

                        if (form) {
                            navigate(`/lead/${formUuid}`)
                            return;
                        }

                        if (campaignSettings?.redirectionUrl !== undefined && campaignSettings?.redirectionUrl !== null) {
                            window.location.href = campaignSettings.redirectionUrl;
                            return;
                        }

                        window.location.href = gsmcUrl;
                    }} />
            </Backdrop>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarDisplayed}
                autoHideDuration={5000}
                onClose={handleSnackbarClose}
                TransitionComponent={(_) => <Slide {..._} direction="down" />} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                    }}
                    severity='error'
                    iconMapping={{
                        success: <CheckFullIcon bgColor='white' color={plateformStyle.colors.green.main} />,
                        error: <CloseFullIcon bgColor='white' color={plateformStyle.colors.red.main} />,
                    }}
                    action={
                        <IconButton
                            aria-label="close"
                            color="inherit"
                            size="small"
                            onClick={handleSnackbarClose}>
                            <MUICloseIcon fontSize="inherit" fontWeight={700} />
                        </IconButton>
                    }>
                    <Typography fontWeight={500}>Une erreur est survenue. Veuillez réessayer ultérieurement.</Typography>
                </Alert>
            </Snackbar>

        </ThemeProvider>
    )
}

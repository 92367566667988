import { useEffect, useState } from 'react';

import { useParams } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import { CardError } from '../../../components/Card/CardError';

import { getById } from '../../../api/Promotion.api';
import { IPromotionDTO } from '../../../model/Promotion.model';

import { PromotionContent } from './{id}_content';


export const Promotion = () => {

    const { id } = useParams()
    const [promotion, setPromotion] = useState<IPromotionDTO | null>();

    document.title = `BO - Promotion #${id}`;

    useEffect(() => {
        if (!id) return

        getById(parseInt(id))
        .then(_ => setPromotion({..._, plateformes: _.plateformes?.map(_ => _.id)} as IPromotionDTO))
        .catch(_ => setPromotion(null))
    }, [])

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <CardGiftcardIcon />},
                {type: "LINK", label: "Promotion", url: "/bo/promotion"},
                {type: "TITLE", label: `#${id}`}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            {promotion === null ?
            <CardError
                title="Erreur de chargement" />

            : promotion === undefined ?
            <></>

            :
            <PromotionContent
                promotion={promotion}
                send={(_) => setPromotion(_)} />
            }
        </Box>
        </>
    )
}


import { useEffect, useState } from 'react';

import { Outlet } from 'react-router-dom';

import Box from '@mui/system/Box';

import { PageLayout } from '../../components/Layout/PageLayout';
import { MainHeader } from '../../components/Header/MainHeader';
import { Footer } from '../../components/Footer/Footer';

import { theme } from '../../static/themes/gsmc/theme'
import { style } from '../../static/themes/gsmc/style'

import { getPlateformeSettings } from '../../api/PlateformeSettings.api';
import { IPlateformeSettings } from '../../model/PlateformeSettings.model';


export const Layout = () => {

    const [plateformSettings, setPlateformSettings] = useState<IPlateformeSettings>();

    useEffect(() => {
        getPlateformeSettings("GSMC")
        .then((response?: IPlateformeSettings) => {
            if ((response as any).status)
                throw new Error((response as any).status)

            setPlateformSettings(response);
        })
        .catch((exception) => {});
    }, [])


    return (
        <PageLayout
            theme={theme}
            header={
                <MainHeader
                    plateformStyle={style}
                    isReturnEnabled={false}
                    goToPrevious={() => {}} />}
            content={
                <Box
                    sx={{
                        maxWidth: "1200px",
                        mx: 'auto',
                        px: 2}}>
                    <Outlet />
                </Box>
            }
            footer={
                <Box
                    sx={{
                        maxWidth: '1200px',
                        mx: 'auto'
                    }}>
                    {plateformSettings &&
                    <Footer
                        plateformStyle={style}
                        plateformSettings={plateformSettings} /> }
                </Box>
            }
        />
    )
}

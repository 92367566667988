import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import CardGiftcardIcon from '@mui/icons-material/CardGiftcard';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';

import { PromotionContent } from './{id}_content';


export const PromotionNew = () => {
    document.title = 'BO - Promotion';

    const navigate = useNavigate()

    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <CardGiftcardIcon />},
                {type: "LINK", label: "Promotion", url: "/bo/promotion"},
                {type: "TITLE", label: "New"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <PromotionContent
                send={(_) => {
                    if (_.id) navigate(`/bo/promotion/${_.id}`)
                }} />
        </Box>
        </>
    )
}

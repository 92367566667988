import { useNavigate, useSearchParams } from "react-router-dom";

import { FormSignin } from "../../components/Form/Auth/FormSignin";

export const Signin = () => {

    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();


    return (
        <FormSignin
            send={() => {
                if (searchParams.get('redirect') && searchParams.get('redirect') !== null) {
                    navigate(searchParams.get('redirect') as string);
                    return;
                }

                navigate("/verification/souscription");
            }} />
    )
}

import {IWarrantiesAcronyms, IWarrantiesSection} from "../model/Waranties.model";

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const getWarrantiesForFormules = (formuleIds: string[]) : Promise<IWarrantiesSection[]> => {

    return fetch(`${apiUrl}/warranties/formulas?formulaIds=${formuleIds.join(',')}`,
    {
        method: "GET",

    }).then(_ => _.json()) 
}


export const getWarrantiesAcronyms = () : Promise<IWarrantiesAcronyms[]> => {

    return fetch(`${apiUrl}/warranties/acronyms`,
    {
        method: "GET",

    }).then(_ => _.json())
}



import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { theme } from '../../static/themes/gsmc/theme'


interface Props {
    code: string
    color: "warning" | "error"
    title?: string
    emoji?: string
    content?: JSX.Element
}


export const ErrorContent = (props: Props) => {

    document.title = `Mutuelle GSMC - ${props.code}`;

    return (
        <Grid
            container
            spacing={4}
            alignItems="center"
            sx={{
            }}>
            <Grid
                item
                sm="auto"
                xs={12}
                sx={{
                    display: "flex",
                    alignItems: "center"
                }}>
                <Typography component='span' fontSize={70} fontWeight={900}>Oups !</Typography>
                <Chip label={props.code} color={props.color} variant="outlined" sx={{border: 3, fontSize: 24, fontWeight: 900, ml: 2}}/>
            </Grid>
            <Grid
                item
                xs={12}
                sx={{
                    pt: "0px !important"
                }}>
                <Typography variant='h3' sx={{color: theme.palette.text.secondary}}>
                    {props.title ? props.title
                    : props.code === "404" ? "La page que vous cherchez est introuvable."
                    : "Tout ne s'est pas passé comme prévu."}
                    &nbsp;
                    <Typography component='span' fontSize={32} fontWeight={900}>
                        {props.emoji ? props.emoji
                        : props.code === "404" ? "🤷‍♀️"
                        : "🚧"}
                    </Typography>
                </Typography>
            </Grid>

            {props.content &&
            <Grid
                item
                xs={12}>
                {props.content}
            </Grid>}

            <Grid
                item
                xs={12}
                sx={{
                    textAlign: {
                        sm: "left",
                        xs: "center"
                    }
                }}>
                <Button variant="contained" color="success" sx={{color: "white", px: 4}} href='https://www.mutuelle-gsmc.fr/nos-offres'>Découvrez nos offres</Button>
            </Grid>
        </Grid>
    )
}

import { defaultIconHeaderSize, IIcon } from "./settings"


export const HrSubscriberIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle fill={props.bgColor} cx="20" cy="20" r="20"/>
        <mask id="mask0_295_3825" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <path d="M20 2.94047e-06C31.0457 2.45765e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 3.42329e-06 20 2.94047e-06Z" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_295_3825)">
        <path d="M1.43182 17.4427L-10.0964 28.6753C-10.8029 29.3637 -11.1245 30.3561 -10.956 31.3281L-9.22899 41.2917C-8.97886 42.7348 -7.72044 43.7844 -6.25589 43.7715L50.2641 43.2727C52.3997 43.2539 53.8279 41.0671 52.9866 39.1041L38.7188 5.81264C37.7281 3.50115 34.5003 3.37014 33.3256 5.59373L22.2668 26.5264C21.3813 28.2027 19.1794 28.6425 17.7178 27.4351L5.42359 17.279C4.24633 16.3065 2.5255 16.3771 1.43182 17.4427Z" fill="url(#paint0_linear_295_3825)"/>
        </g>
        <g filter="url(#filter0_d_295_3825)">
        <path d="M8 36.5801C8 29.6765 13.5964 24.0801 20.5 24.0801C27.4036 24.0801 33 29.6765 33 36.5801V39.9999H8V36.5801Z" fill={props.color}/>
        <circle cx="20.5424" cy="15.4289" r="7.95991" fill={props.color} />
        </g>
        <defs>
        <filter id="filter0_d_295_3825" x="-12" y="-8.53101" width="65" height="72.5309" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="out"/>
        <feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_295_3825"/>
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_295_3825" result="shape"/>
        </filter>
        <linearGradient id="paint0_linear_295_3825" x1="22.9804" y1="4.91537" x2="22.9804" y2="43.7939" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

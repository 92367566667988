import { defaultIconSize, IIcon } from "./settings"


export const UploadIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_35_5636)">
        <path fillRule="evenodd" clipRule="evenodd" d="M9.21071 13.9995C8.92977 14.2908 8.92977 14.7631 9.21071 15.0544C9.49165 15.3457 9.94715 15.3457 10.2281 15.0544L14.0386 10.9087L14.0098 21.1872C14.0098 21.7789 14.3454 22.2586 14.7593 22.2586C15.1732 22.2586 15.5087 21.7789 15.5087 21.1872L15.5375 10.664L19.7719 15.0544C20.0529 15.3457 20.5083 15.3457 20.7893 15.0544C21.0702 14.7631 21.0702 14.2908 20.7893 13.9995L15.5087 8.5244C15.2277 8.23311 14.7723 8.23311 14.4913 8.5244L9.21071 13.9995Z" fill={props.color}/>
        <path d="M20 6.30591L10 6.30591" stroke={props.color} strokeWidth="1.6" strokeLinecap="round"/>
        <circle cx="15" cy="15" r="14" stroke={props.color} strokeWidth="2"/>
        </g>
        <defs>
        <clipPath id="clip0_35_5636">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

import { useEffect, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Divider from '@mui/material/Divider';
import { FieldLabel } from '../Field/FieldLabel';
import FormLabel from '@mui/material/FormLabel';
import { FormRadioButton } from './FormRadioButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { colors } from '../../static/themes/gsmc/colors';
import { IPlateforme } from '../../model';
import { IPlateformeSettingsTarification } from '../../model/PlateformeSettings.model';
import { enumTarificationModes, messageFieldRequired } from '../../utils';
import { updatePlateformeSettingsTarification } from '../../api/PlateformeSettings.api';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { MonetizationOn } from '@mui/icons-material';
import { InfoIcon } from '../Icon/InfoIcon';

interface Props {
    plateforme: IPlateforme
    settingsTarification: IPlateformeSettingsTarification;
    send: (settingsTarification: IPlateformeSettingsTarification) => void;
    handleError: (message: string) => void;
}

export const FormPlateformeSettingsTarification = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        mode: yup.string().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues: {
            id:props.settingsTarification.id,
            mode: props.settingsTarification.mode,
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: IPlateformeSettingsTarification = {
                id: props.settingsTarification.id,
                mode: values.mode
            }
            updatePlateformeSettingsTarification(toSend)
            .then((response: IPlateformeSettingsTarification) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement du paramétrage de la tarification.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);

    return(
        <form onSubmit={formik.handleSubmit} >
            <Grid
            container
            spacing={3}
            sx={{
                textAlign: 'left'
                }} >
                    
                    <Grid
                    item
                    xs={12} >
                        <Typography
                        variant='h2'>
                        <MonetizationOn
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Tarification
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                   Configurer la tarification
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>


                <Grid
                item
                xs={12} >
                            <FormLabel error={formik.touched.mode && Boolean(formik.errors.mode)} >
                                <FieldLabel
                                    label="Méthode de tarification"
                                    fontWeight={500}
                                    isRequired />
                            </FormLabel>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    mb: 0.75
                                }} >
                                    Méthode de tarification utilisée pour la plateforme (Henner par défaut)
                            </Typography>
                            <Grid
                                container
                                spacing={2} >
                                {enumTarificationModes.map((_, id) => {
                                    return <Grid
                                        item
                                        lg={2}
                                        md={3}
                                        sm={4}
                                        xs={12} >
                                        <FormRadioButton
                                            code={_.value}
                                            selected={formik.values.mode === _.value}
                                            color={colors.blue.main}
                                            onClick={(value) => {
                                                formik.setFieldValue("mode", value);
                                                formik.setFieldTouched("mode");
                                            }} >
                                            {_.label}
                                        </FormRadioButton>
                                    </Grid>
                                })}
                            </Grid>
                        </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>

                </Grid>
        </form>
    )

}

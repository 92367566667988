import { ChangeEvent, useEffect } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';


interface Props {
    id: string;
    label?: string | JSX.Element;
    value: string | undefined;
    onChange: (value: string) => void;
    onBlur?: (event: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
    fullWidth?: boolean;
}

export const TextFieldPhone = (props: Props) => {
    const onChange = (_: string | ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (_ === props.value) return;
        props.onChange(_ as string);
    }

    useEffect(() => {
        // Remove the zero if "+33 0".
        if (props.value && props.value.replace(/\+\d+\s/, '')[0] === "0") {
            props.onChange(props.value.substring(0, props.value.length - 1));
        }

    }, [props.value])


    return (
        <MuiPhoneNumber
            variant="outlined"
            onlyCountries= {['fr', 'be']}
            defaultCountry='fr'
            label={props.label}
            fullWidth
            value={props.value}
            onChange={(_) => onChange(_)}
            onBlur={props.onBlur}
            error={props.error}
            helperText={props.helperText} />
    )
}

import { IWebhook } from "../model/Webhook.model"

const apiUrl = process.env.REACT_APP_API_URL + '/tunnel'


export const postWebhook = (webhook: IWebhook, token: string) : Promise<any> => {

    return fetch(`${apiUrl}/webhook`,
    {
        method: "POST",
        headers: {
            "content-type":"application/json",
            "authorization": `Bearer ${token}`
        },
        body: JSON.stringify(webhook)
    }).then(_ => _.text())
}

import { useEffect, useState } from 'react';

import AwesomeDebouncePromise from 'awesome-debounce-promise';

import Alert from '@mui/material/Alert';
import Autocomplete from '@mui/material/Autocomplete';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import SearchIcon from '@mui/icons-material/Search';
import WarningIcon from '@mui/icons-material/Warning';

import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import CustomizedDialogs from '../../../components/Dialog/CustomizedDialog';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';
import useConstant from '../../../components/useConstant';

import {  IGamme, IOffre } from '../../../model';
import { createGamme, getGammes, modifyGamme } from '../../../api/Gamme.api';
import { getOffres } from '../../../api/Offre.api';

import { messageFieldRequired } from '../../../utils/messages';

import { colors } from '../../../static/themes/gsmc/colors';


interface IDialogProps {
    open: boolean;
    gamme?: IGamme;
    handleClose: () => void;
    handleError: () => void;
}


const initialValues = {
    code: undefined as undefined | string,
    label: undefined as undefined | string,
    offre: undefined as any,
    status: undefined as undefined | string,
    dateStart: null as Date | null,
    dateEnd: null as Date | null,
    isActive: true as boolean,
}


const DialogGamme = (props: IDialogProps) => {

    const [optionOffres, setOptionsOffres] = useState<{ value: IOffre, label: string }[]>([]);

    const getOffresDebounced = useConstant(() =>
        AwesomeDebouncePromise(getOffres, 300)
    );

    useEffect(() => {
        formik.resetForm();

        getOffresDebounced(0, 100, "")
        .then(response => setOptionsOffres(response.content.map((_: IOffre) => ({ value: _, label: _.libOffre }))))
        .catch(exception => setOptionsOffres([]));

        if (props.gamme !== undefined) {
            const values = {
                code: props.gamme.codeGamme,
                label: props.gamme.libelleGamme,
                offre: {
                    value : props.gamme.offre,
                    label : props.gamme.offre.libOffre
                },
                status: props.gamme.statutGamme,
                dateStart: props.gamme.dateDebut,
                dateEnd: props.gamme.dateFin,
                isActive: props.gamme.estActif
            };

            formik.setValues(values);
        }
        else {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.gamme])

    const validationSchema = yup.object({
        code: yup.string().required(messageFieldRequired),
        label: yup.string().required(messageFieldRequired),
        offre: yup.object().required(messageFieldRequired),
        status: yup.string().nullable(),
        dateStart: yup
            .date()
            .typeError("Date non valide")
            .nullable(),
        dateEnd: yup
            .date()
            .typeError("Date non valide")
            .nullable()
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if(props.gamme) {
                const toSend : IGamme = {
                    id: props.gamme.id,
                    codeGamme: values.code as string,
                    libelleGamme: values.label as string,
                    offre: values.offre.value,
                    statutGamme: values.status as string,
                    dateDebut: values.dateStart as Date,
                    dateFin: values.dateEnd as Date,
                    estActif: values.isActive
                }
                modifyGamme(toSend).then((response : IGamme )=> {
                    if(response.id) {
                        props.handleClose();
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
            else {
                const toSend: IGamme = {
                    codeGamme: values.code as string,
                    libelleGamme: values.label as string,
                    offre: values.offre.value,
                    statutGamme: values.status as string,
                    dateDebut: values.dateStart as Date,
                    dateFin: values.dateEnd as Date,
                    estActif: values.isActive
                }
                createGamme(toSend).then((response : IGamme )=> {
                    if(response.id) {
                        props.handleClose();
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
        }
    })


    return (
        <CustomizedDialogs
            title="Gamme"
            open={props.open}
            handleClose={props.handleClose}>
            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="code"
                                name="code"
                                label="Code*"
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="label"
                                name="label"
                                label="Libelle*"
                                value={formik.values.label}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.label && Boolean(formik.errors.label)}
                                helperText={formik.touched.label && formik.errors.label} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Autocomplete
                                id="offre"
                                value={formik.values.offre}
                                options={optionOffres}
                                noOptionsText="Aucune offre"
                                getOptionLabel={(option) => option.label}
                                onChange={(e, value) => formik.setFieldValue("offre", value)}
                                onInputChange={(e, value) => getOffresDebounced(0, 100, value)
                                    .then(res => setOptionsOffres(res.content.map(( _: IOffre) => ({ value: _, label: _.libOffre }))))
                                    .catch(exception => { return; })}
                                onBlur={formik.handleBlur}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        name="offre"
                                        label="Offre*" variant="outlined"
                                        error={formik.touched.offre && Boolean(formik.errors.offre)}
                                        helperText={formik.touched.offre && <>{formik.errors.offre}</>} />} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="status"
                                name="status"
                                label="Statut"
                                value={formik.values.status}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.status && Boolean(formik.errors.status)}
                                helperText={formik.touched.status && formik.errors.status} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={fr}
                                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                <DatePicker
                                    label="Date de début"
                                    value={formik.values.dateStart}
                                    onChange={(value: any) => formik.setFieldValue("dateStart", value)}
                                    localeText={{
                                        toolbarTitle: "Sélectionnez une date",
                                        okButtonLabel: "Valider"
                                    }}
                                    format='dd/MM/yyyy'
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                width: '100%'
                                            },
                                            onBlur: (e) => formik.setFieldTouched("dateStart"),
                                            error: formik.touched.dateStart && Boolean(formik.errors.dateStart),
                                            helperText: formik.touched.dateStart && <>{formik.errors.dateStart}</>,
                                        },
                                    }} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={fr}
                                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                <DatePicker
                                    label="Date de fin"
                                    value={formik.values.dateEnd}
                                    onChange={(value: any) => formik.setFieldValue("dateEnd", value)}
                                    localeText={{
                                        toolbarTitle: "Sélectionnez une date",
                                        okButtonLabel: "Valider"
                                    }}
                                    format='dd/MM/yyyy'
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                width: '100%'
                                            },
                                            onBlur: (e) => formik.setFieldTouched("dateEnd"),
                                            error: formik.touched.dateEnd && Boolean(formik.errors.dateEnd),
                                            helperText: formik.touched.dateEnd && <>{formik.errors.dateEnd}</>,
                                        },
                                    }} />
                            </LocalizationProvider>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <span><strong>Actif</strong></span>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                        </Grid>
                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button autoFocus type="submit">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </CustomizedDialogs>
    )
}


export const GammeList = () => {
    document.title = 'BO - Gammes';

    const [gamme, setGamme] = useState<IGamme | undefined>();
    const [gammes, setGammes] = useState<IGamme[] | undefined | null>(undefined);
    const [gammesCount, setGammesCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleClickModify = (gamme: IGamme) => {
        setGamme(gamme);
        setOpen(true);
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        getGammes(pageNumber, rowPerPage, search).then(response => {
            setGammes(response.content);
            setGammesCount(response.totalElements);
        })
        .catch((exception) => {
            setGammes(null);
            setGammesCount(0);
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, rowPerPage])

    const forceRefresh = () => {
        getGammes(pageNumber, rowPerPage, search).then(response => {
            setGammes(response.content);
            setGammesCount(response.totalElements);
        })
        .catch((exception) => {
            setGammes(null);
            setGammesCount(0);
        })
        setOpen(false);
        setGamme(undefined);
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "LINK", label: "Gamme"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2} >
                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                        <IconButton
                            onClick={() => setOpen(true)}>
                            <AddCircleIcon color="primary"/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                minWidth: "100px",
                                                position: "sticky",
                                                left: 0,
                                                background: colors.grey.secondary,
                                            }}>Nom</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Offre</TableCell>
                                        <TableCell>Statut</TableCell>
                                        <TableCell>Date de début</TableCell>
                                        <TableCell>Date de fin</TableCell>
                                        <TableCell align="center">Actif</TableCell>
                                        <TableCell
                                            key={'outil'}
                                            sx={{
                                                minWidth: '80px',
                                                position: "sticky",
                                                right: 0,
                                                background: colors.grey.secondary,
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {gammes === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des gammes en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : gammes === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des gammes
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : gammes.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucune gamme
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : gammes.map((_, id) => {
                                        return (
                                            <TableRow key={id}>
                                                <TableCell
                                                    sx={{
                                                        position: "sticky",
                                                        left: 0,
                                                        background: colors.grey.secondary,
                                                    }}>{_.libelleGamme}</TableCell>
                                                <TableCell>{_.codeGamme}</TableCell>
                                                <TableCell>
                                                    {_.offre !== undefined && _.offre !== null &&
                                                    <Chip
                                                        label={_.offre.libOffre} />}
                                                    </TableCell>
                                                <TableCell>{_.statutGamme}</TableCell>
                                                <TableCell>
                                                    {_.dateDebut !== undefined && _.dateDebut !== null &&
                                                    new Date(_.dateDebut).getDate().toString().padStart(2, '0')  + "-" + (new Date(_.dateDebut).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(_.dateDebut).getFullYear()}
                                                </TableCell>
                                                <TableCell>
                                                    {_.dateFin !== undefined && _.dateFin !== null &&
                                                    new Date(_.dateFin).getDate().toString().padStart(2, '0')  + "-" + (new Date(_.dateFin).getMonth() + 1).toString().padStart(2, '0') + "-" + new Date(_.dateFin).getFullYear()}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {_.estActif ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        position: "sticky",
                                                        right: 0,
                                                        background: colors.grey.secondary,
                                                    }}>
                                                    <IconButton
                                                        onClick={() => handleClickModify(_)}>
                                                        <CreateIcon
                                                            sx={{
                                                                color: colors.blue.main
                                                            }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={gammesCount}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >Erreur lors de l'enregistrement de la gamme.</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

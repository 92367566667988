import { useEffect, useState } from 'react';

import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Checkbox from '@mui/material/Checkbox';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import TroubleshootIcon from '@mui/icons-material/Troubleshoot';

import { InfoIcon } from '../Icon/InfoIcon';

import { updatePlateformeSettingsFraud } from '../../api/PlateformeSettings.api';

import { IPlateformeSettingsFraud } from '../../model/PlateformeSettings.model';

import { messageFieldRequired } from '../../utils';

import { colors } from '../../static/themes/gsmc/colors';
import { IPlateforme } from '../../model';


interface Props {
    plateforme: IPlateforme
    settingsFraud: IPlateformeSettingsFraud;
    send: (settingsFraud: IPlateformeSettingsFraud) => void;
    handleError: (message: string) => void;
}


export const FormPlateformeSettingsFraud = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);

    const validationSchema = yup.object({
        emailAlert: yup.string().email("Email non valide").nullable(),
        enableAlertEmail: yup.boolean().required(messageFieldRequired),
        enableControlPhone: yup.boolean().required(messageFieldRequired),
        enableControlIban: yup.boolean().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues: {
            emailAlert: props.settingsFraud.emailAlert,
            enableAlertEmail: props.settingsFraud.enableAlertEmail,
            enableControlPhone: props.settingsFraud.enableControlPhone,
            enableControlIban: props.settingsFraud.enableControlIban
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            const toSend: IPlateformeSettingsFraud = {
                id: props.settingsFraud.id,
                emailAlert: values.emailAlert ? values.emailAlert : undefined,
                enableAlertEmail: values.enableAlertEmail,
                enableControlPhone: values.enableControlPhone,
                enableControlIban: values.enableControlIban
            }
            updatePlateformeSettingsFraud(toSend)
            .then((response: IPlateformeSettingsFraud) => {
                props.send(response);
                setSuccess(true);
            })
            .catch(exception => {
                props.handleError("Erreur lors de l'enregistrement du paramétrage des fraudes.");
                setError(true);
            });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    useEffect(() => {
        if (!error)
            return;

        setTimeout(function () {
            setError(false);
        }, 2000);
    }, [error]);

    return (
        <form onSubmit={formik.handleSubmit} >
            <Grid
                container
                spacing={3}
                sx={{
                    textAlign: 'left'
                }} >

                <Grid
                    item
                    xs={12} >
                    <Typography
                        variant='h2'>
                        <TroubleshootIcon
                            fontSize='medium'
                            sx={{
                                verticalAlign: 'bottom',
                                mb: 0.75,
                                mr: 1
                            }} />
                        Contrôle des fraudes
                    </Typography>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            p: 2,
                            backgroundColor: colors.blue.secondary
                        }} >
                        <Grid
                            container
                            wrap='nowrap'
                            spacing={2} >
                            <Grid
                                item
                                sx={{
                                    display: {
                                        sm: 'block',
                                        xs: 'none'
                                    }
                                }}>
                                <InfoIcon color={colors.blue.main} />
                            </Grid>
                            <Grid
                                item
                                sx={{
                                    my: 'auto'
                                }} >
                                <Typography
                                    fontWeight={500}
                                    sx={{
                                        my: 'auto'
                                    }} >
                                    Choisissez le paramétrage des contrôles pour détecter les potentielles souscriptions frauduleuse.
                                </Typography>
                            </Grid>
                        </Grid>
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Contrôles formulaire
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Le souscripteur ne pourra pas utiliser un numéro de téléphone déjà utilisé dans un contrat GSMC signé.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.enableControlPhone}
                                        onChange={(e, value) => formik.setFieldValue("enableControlPhone", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Vérifier si le numéro de téléphone est utilisé
                                    </Typography>
                                } />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Le souscripteur ne pourra pas utiliser un IBAN déjà utilisé dans un contrat GSMC signé.
                            </Typography>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.enableControlIban}
                                        onChange={(e, value) => formik.setFieldValue("enableControlIban", value)} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Vérifier si l'IBAN est utilisé
                                    </Typography>
                                } />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Grid
                        container
                        spacing={2} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }} >
                            <Typography
                                variant='h3'>
                                Alerte
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Un email sera envoyé si un des contrôles de fraude du formulaire est vérifier. (voir ci-dessus)
                            </Typography>

                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={formik.values.enableAlertEmail}
                                        onChange={(e, value) => {
                                            if (!value) {
                                                formik.setFieldValue("emailAlert", props.settingsFraud.emailAlert || "")
                                                formik.setFieldTouched("emailAlert", false)
                                            }
                                            formik.setFieldValue("enableAlertEmail", value)
                                        }} />
                                }
                                label={
                                    <Typography
                                        component='span'
                                        fontWeight={500}>
                                        Activer l'alerte via email
                                    </Typography>
                                } />
                        </Grid>

                        <Grid
                            item
                            xs={12} >
                            {!formik.values.emailAlert && formik.values.enableAlertEmail &&
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Si aucun email n'est renseigné, les alertes seront envoyées à l'email de mise en gestion.
                            </Typography>}
                            <TextField
                                disabled={!formik.values.enableAlertEmail}
                                id="emailAlert"
                                name="emailAlert"
                                fullWidth
                                placeholder={props.plateforme?.mailMiseEnGestion}
                                value={formik.values.emailAlert}
                                onChange={formik.handleChange}
                                onBlur={(e) => formik.setFieldTouched("emailAlert")}
                                error={formik.touched.emailAlert && Boolean(formik.errors.emailAlert)}
                                helperText={formik.touched.emailAlert && formik.errors.emailAlert}
                                sx={{
                                    maxWidth: "500px"
                                }} />
                        </Grid>
                    </Grid>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Divider light />
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Button
                        type='submit'
                        sx={{
                            px: 2,
                            py: 1,
                            color: 'white',
                            backgroundColor: colors.black.main,
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Enregistrer
                    </Button>

                    <Typography
                        component='span'
                        sx={{
                            display: success ? 'inline' : 'none',
                            ml: 2,
                            color: colors.green.main
                        }}>
                        <CheckCircleIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Enregistrement réussi
                        </Typography>
                    </Typography>

                    <Typography
                        component='span'
                        sx={{
                            display: error ? 'inline' : 'none',
                            ml: 2,
                            color: colors.red.main
                        }}>
                        <CancelIcon
                            fontSize='small'
                            sx={{
                                verticalAlign: 'middle',
                                mr: 1,
                                mb: 0.5
                            }} />
                        <Typography
                            component='span'
                            sx={{
                                display: {
                                    sm: 'inline',
                                    xs: 'none'
                                }
                            }}>
                            Erreur lors de l'enregistrement
                        </Typography>
                    </Typography>
                </Grid>
            </Grid>
        </form>
    )
}

import createTheme from "@mui/material/styles/createTheme";
import responsiveFontSizes from "@mui/material/styles/responsiveFontSizes";

import { frFR } from '@mui/material/locale';

import { colors } from './colors'


export const theme = responsiveFontSizes(createTheme({
    palette: {
        primary: {
            main: colors.blue.main
        },
        secondary: {
            main: colors.orange.main
        },
        error: {
            main: colors.red.main
        },
        warning: {
            main: colors.orange.main
        },
        info: {
            main: colors.blue.main
        },
        success: {
            main: colors.green.main
        },
        text: {
            primary: colors.black.main,
            secondary: colors.black.secondary
        }
    },
    typography: {
        fontFamily: "'Quicksand'",
        fontWeightRegular: 500,
        fontWeightBold: 700,
        h1: {
            fontSize: '2rem',
            lineHeight: 1.5,
            fontWeight: 'bold'
        },
        h2: {
            fontSize: '1.625rem',
            lineHeight: 1.5,
            fontWeight: 'bold'
        },
        h3: {
            fontSize: '1.25rem',
            lineHeight: 1.25,
            fontWeight: 'bold'
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 'bold'
        },
        body2: {
            fontSize: '1rem',
            lineHeight: 1.5,
            fontWeight: 'normal',
            color: colors.black.secondary
        },
        caption: {
            fontSize: '0.8rem',
            lineHeight: 1.5,
            fontWeight: 'normal',
            color: colors.black.secondary
        },
        button: {
            fontSize: '1.125rem',
            fontWeight: 'bold'
        },
    },
    components: {
        MuiAlert: {
            styleOverrides: {
                standardSuccess: {
                    backgroundColor: colors.green.main,
                    color: 'white'
                },
                standardError: {
                    backgroundColor: colors.red.main,
                    color: 'white'
                },
                standardWarning: {
                    backgroundColor: colors.orange.main,
                    color: 'white'
                },
                standardInfo: {
                    backgroundColor: colors.blue.secondary,
                    color: 'black'
                },
                root: {
                    width: '100%',
                    borderRadius: 16,
                    textAlign: 'left',
                    color: colors.black.main,
                    backgroundColor: colors.grey.secondary
                },
                action: {
                    padding: 0,
                    alignItems: "center"
                },
                message: {
                    paddingTop: '10px'
                }
            }
        },
        MuiAutocomplete: {
            styleOverrides: {
                input: {
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingRight: 16,
                    paddingLeft: 20
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    padding: 12,
                    textTransform: 'none',
                    borderRadius: 32
                }
            }
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 16,
                    backgroundColor: colors.grey.secondary,
                    boxShadow: 'none'
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    paddingTop: '4px',
                    paddingBottom: '4px'
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 16
                }
            }
        },
        MuiFormControl: {
            styleOverrides: {
                root: {
                    border: 1
                }
            }
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    alignItems: "flex-start"
                }
            }
        },
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    marginLeft: 25
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: colors.black.main
                }
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    background: 'white'
                }
            }
        },
        MuiInputAdornment: {
            styleOverrides: {
                root: {
                    marginRight: 0
                }
            }
        },
        MuiLink: {
            styleOverrides: {
                root: {
                    fontFamily: "Quicksand",
                    ":hover": {
                        cursor: "pointer"
                    }
                }
            }
        },
        MuiLinearProgress: {
            styleOverrides: {
                root: {
                    borderRadius: 8,
                    background: colors.grey.main
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    padding: 0,
                    borderRadius: 32
                },
                input: {
                    paddingTop: 14,
                    paddingBottom: 14,
                    paddingRight: 16,
                    paddingLeft: 20
                }
            }
        },
        MuiPopover: {
            styleOverrides: {
                paper: {
                    backgroundColor: colors.blue.secondary,
                    borderRadius: '16px',
                    maxWidth: '300px'
                }
            }
        },
        MuiSnackbar: {
            styleOverrides: {
                root: {
                    maxWidth: '1200px'
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    alignItems: 'flex-start',
                    minWidth: '10px',
                    textTransform: 'none'
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    padding: 8
                },
                head: {
                    padding: 16
                }
            }
        }
    }
},
frFR));

import Box from '@mui/material/Box';
import Skeleton from '@mui/material/Skeleton';

import { PageContentLayout } from '../../components/Layout/PageContentLayout';


export const FormInitializing = () => {

    return (
        <PageContentLayout
            header={
                <Box sx={{pt: 2}}></Box>
            }
            sidebar={
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{
                        height: {
                            md: "15vh",
                            xs: "10vh"
                        },
                        borderRadius: 4
                    }}>
                </Skeleton>}
            content={
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    sx={{
                        height: {
                            md: "50vh",
                            xs: "40vh"
                        },
                        borderRadius: 4
                    }}>
                </Skeleton>
            } />
    )
}

import { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';

import { ThemeProvider } from '@mui/material/styles';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import CloseIcon from '@mui/icons-material/Close';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import Logout from '@mui/icons-material/Logout';
import MenuIcon from '@mui/icons-material/Menu';

import AppBar from '../../components/AppBar/AppBar';
import { GSMCIcon } from "../../components/Icon/GSMCIcon";
import { ButtonLink } from '../../components/Button/ButtonLink';

import { IUser } from '../../model/auth/User.model';
import { getUserCurrent } from '../../api/auth/User.api';

import AuthService from '../../services/AuthService';
import PrivateRoute from '../../services/private-route';

import { colors } from '../../static/themes/gsmc/colors';
import { theme } from '../../static/themes/gsmc/theme'
import { Search, Timelapse } from '@mui/icons-material';


const authService = new AuthService();


export const Layout = () => {

    const navigate = useNavigate();
    const location = useLocation();

    const [user, setUser] = useState<IUser>();

    useEffect(() => {
        if (!authService.isLoggedIn()) {
            navigate(`/auth/signin?redirect=${location.pathname}`)
            return;
        }
        getUserCurrent().then(response => {
            setUser(response)
        })
        .catch(e => {
            navigate(`/auth/signin?redirect=${location.pathname}`)
        })
    }, [])

    const [open, setOpen] = useState(false);

    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    useEffect(() => {
        handleClose();
    }, [location]);


    const appBar = <AppBar
        header={
            <GSMCIcon
                width="40px"
                height="auto" />}
        items={[
            {type: "TITLE", label: "Souscription", icon: <EmojiEventsIcon />},
            {type: "LINK", label: "Vérification", icon: <Search />, url: "souscription", urlBase: "verification", isActive: "/verification" === location.pathname},
            {type: "LINK", label: "Incomplète", icon: <Timelapse />, url: "souscription/incomplete", urlBase: "verification"}
        ]}
        footer={
            <>
            {user &&
            <Button
                fullWidth
                onClick={() => {
                    navigate("account")
                }}
                sx={{
                    justifyContent: "flex-start",
                    py: 1,
                    px: 2,
                    borderRadius: 4,
                    border: 1,
                    borderColor: colors.blue.main,
                    background: colors.grey.secondary,
                    color: colors.black.main,
                    ":hover": {
                        background: colors.grey.main,
                    }
                }}>
                <Box
                    sx={{
                        textAlign: "left"
                    }}>
                    {user.firstname &&<Typography>{user.firstname} {user.lastname && `${user.lastname[0]}.`}</Typography>}
                    <Typography variant='body2'>@{user.username}</Typography>
                </Box>
            </Button>}

            <ButtonLink
                label="Se déconnecter"
                icon={<Logout />}
                color={colors.red.main}
                onClick={() => {
                    authService.logout()
                    navigate("/auth/signin")
                }} />
            </>
        } />

    return (
        <ThemeProvider theme={theme}>
            <PrivateRoute>
                <Grid
                    container
                    justifyContent="flex-start"
                    sx={{
                        position: "relative",
                        wrap: {
                            md: "nowrap",
                            xs: "wrap"
                        },
                        width: "100%",
                        height: "100vh",
                        overflowY: "auto",
                    }}>
                    <Grid
                        item
                        xs
                        sx={{
                            position: "sticky",
                            top: 0,
                            display: {
                                md: "flex",
                                xs: "none"
                            },
                            width: "300px",
                            height: "100vh",
                            p: 2,
                            borderRight: 1,
                            borderColor: colors.grey.main
                        }}>
                        {appBar}
                    </Grid>

                    <Grid
                        item
                        xs
                        sx={{
                            position: "sticky",
                            top: 0,
                            display: {
                                md: "none",
                                xs: "flex"
                            },
                            width: "100vw",
                            p: 1,
                            zIndex: 1000,
                            background: "white",
                            borderBottom: 1,
                            borderColor: colors.grey.main
                        }}>
                        <Stack
                            direction="row"
                            justifyContent="space-between"
                            alignItems="center"
                            spacing={2}
                            sx={{
                                width: "100%"
                            }}>
                            <GSMCIcon
                                width="36px"
                                height="36px" />
                            <IconButton onClick={handleClickOpen}>
                                <MenuIcon />
                            </IconButton>
                        </Stack>

                        <Dialog
                            fullScreen
                            open={open}
                            onClose={handleClose}>
                            <IconButton
                                onClick={handleClose}
                                sx={{
                                    position: "absolute",
                                    right: 0,
                                    m: 1
                                }}>
                                <CloseIcon />
                            </IconButton>
                            <DialogContent
                                sx={{
                                    p: 2
                                }}>
                                {appBar}
                            </DialogContent>
                        </Dialog>
                    </Grid>

                    <Grid
                        item
                        sx={{
                            width: {
                                md: "calc(100vw - 300px)",
                                xs: "100%"
                            },
                        }} >
                        <Outlet />
                    </Grid>
                </Grid>
            </PrivateRoute>
        </ThemeProvider>
    )
}
function componentDidMount() {
    throw new Error('Function not implemented.');
}

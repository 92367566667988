import Typography from "@mui/material/Typography"


interface Props {
    label: string;
    isRequired?: boolean;
    fontWeight?: string | number;
    color?: string;
    mb?: boolean
}


export const FieldLabel = (props: Props) => {

    return (
        <Typography
            component='div'
            variant='body1'
            fontWeight={props.fontWeight || 700}
            sx={{
                mb: props.mb !== false ? 1.5 : 0,
                color: props.color
            }} >
            {props.label}

            {props.isRequired &&
            <>
            &nbsp;
            <Typography
                display='inline'
                variant='body1'
                color='error' >
                *
            </Typography>
            </>}
        </Typography>
    )
}

import { useTheme } from "@mui/material/styles";

import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

import { InfoIcon } from "../Icon/InfoIcon";
import { AlertIcon } from "../Icon/AlertIcon";
import { CheckFullIcon } from '../Icon/CheckFullIcon';


interface Props {
    plateformStyle: any
    type?: "info" | "success" | "warning" | "error"
    hideIcon?: boolean
    isMinify?: boolean
    children: any
}


export const CardInfo = (props: Props) => {

    const theme = useTheme()

    const plateformStyle: any = props.plateformStyle;

    let bgColor: string
    let color: string
    let icon: JSX.Element

    switch (props.type) {
        default:
        case "info":
            color = plateformStyle.components.IconInfo.color
            bgColor = plateformStyle.components.IconInfo.bgColor
            icon = <InfoIcon color={color} />
            break

        case "success":
            color = theme.palette.success.main
            bgColor = theme.palette.success.main + "18"
            icon = <CheckFullIcon bgColor={color} color="white" />
            break

        case "warning":
            color = theme.palette.warning.main
            bgColor = theme.palette.warning.main + "18"
            icon = <AlertIcon color={color} />
            break

        case "error":
            color = theme.palette.error.main
            bgColor = theme.palette.error.main + "18"
            icon = <AlertIcon color={color} />
            break
    }

    return (
        <Card
            sx={{
                p: 2,
                py: props.isMinify ? 1 : 2,
                backgroundColor: bgColor,
                borderRadius: 2
            }} >
            <Grid
                container
                alignItems="left"
                wrap='nowrap'
                spacing={2}
                sx={{
                    textAlign: "left"
                }}>
                {(props.hideIcon === undefined || props.hideIcon === true) &&
                <Grid
                    item
                    sx={{
                        display: {
                            sm: 'block',
                            xs: 'none'
                        }
                    }}>
                    {icon}
                </Grid>}
                <Grid
                    item
                    xs
                    sx={{
                        my: 'auto'
                    }} >
                    {props.children}
                </Grid>
            </Grid>
        </Card>
    )
}

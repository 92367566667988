import React, {useEffect, useState} from 'react';

import {FormikErrors, useFormik} from 'formik';
import * as yup from 'yup';

import fr from "date-fns/locale/fr";
import AddIcon from '@mui/icons-material/Add';
import LoadingButton from "@mui/lab/LoadingButton";
import {CircularProgress, Collapse} from "@mui/material";
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Switch from '@mui/material/Switch';
import TextField from "@mui/material/TextField";
import Typography from '@mui/material/Typography';
import {AdapterDateFns} from "@mui/x-date-pickers/AdapterDateFns";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import {frFR} from "@mui/x-date-pickers/locales";
import {colors} from '../../static/themes/gsmc/colors';
import {PromotionOffer} from "../../model/Promotion.model";
import {enumGsmcRegimes, messageFieldRequired} from "../../utils";
import {ITarificationGsmcRequest, ITarificationsControlResponse} from "../../model/Tarification";
import {postTarificationControl} from "../../api/Tarification.api";
import {convertUTCDateToLocalDate} from "../../utils/dateUtils";

interface Props {
    setResultTarification: React.Dispatch<React.SetStateAction<ITarificationsControlResponse | undefined>>
    setChildrenBirthDates: React.Dispatch<React.SetStateAction<(Date | undefined)[]>>
}


export const FormBackOfficeTarification = (props: Props) => {

    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    const validationSchema = yup.object({
        offerCode: yup.string().required(messageFieldRequired),
        regime: yup.string().required(messageFieldRequired),
        postalCode: yup.string().required(messageFieldRequired),
        date: yup.string().required(messageFieldRequired),
        subscriberBirthDate: yup.string().required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues: {
            offerCode: undefined,
            isDirect: true,
            commissionPercentage: undefined,
            regime: undefined,
            postalCode: undefined,
            date: undefined,
            subscriberBirthDate: undefined,
            partnerBirthDate: undefined,
            partner: false,
            childrenBirthDates: [],
        },
        validationSchema: validationSchema,
        validate: (values: any) => {
            let errors: FormikErrors<any> = {};
            if (!values.isDirect && values.commissionPercentage === undefined) {
                errors.commissionPercentage = "Champ obligatoire lors d'une vente indirecte ";
            }
            return errors;
        },
        onSubmit: (values) => {
            setError(false);
            setLoading(true);
            props.setResultTarification(undefined);
            props.setChildrenBirthDates(values.childrenBirthDates.filter(Boolean))

            const toSend: ITarificationGsmcRequest = {
                offerCode: values.offerCode,
                date: values.date ? convertUTCDateToLocalDate(values.date) : undefined,
                postalCode: values.postalCode,
                commissionPercentage: values.isDirect ? undefined : values.commissionPercentage,
                subscriber: {
                    regime: values.regime,
                    birthDate: values.subscriberBirthDate ? convertUTCDateToLocalDate(values.subscriberBirthDate) : undefined,
                },
                partner: values.partnerBirthDate ? {
                    regime: values.regime,
                    birthDate: values.partnerBirthDate ? convertUTCDateToLocalDate(values.partnerBirthDate) : undefined
                } : undefined,
                children: values.childrenBirthDates.filter(Boolean)
                    .map((child) => {
                        return {regime: values.regime, birthDate: convertUTCDateToLocalDate(child)}
                    }),
            }

            postTarificationControl(toSend)
                .then((response: ITarificationsControlResponse) => {
                    if (!response.tarifications) throw new Error()

                    props.setResultTarification(response);
                    setSuccess(true);
                    setLoading(false);

                })
                .catch(exception => {
                    console.error(exception);
                    setError(true);
                    setLoading(false);
                });
        }
    });

    useEffect(() => {
        if (!success)
            return;

        setTimeout(function () {
            setSuccess(false);
        }, 2000);
    }, [success]);

    function getEnumKeys<
        T extends string,
        TEnumValue extends string | number,
    >(enumVariable: { [key in T]: TEnumValue }) {
        return Object.keys(enumVariable) as Array<T>;
    }

    const handleClickAddChildren = () => {
        formik.setFieldValue('childrenBirthDates', [...formik.values.childrenBirthDates, undefined]);
    };

    return (
        <form onSubmit={formik.handleSubmit}>
            <Grid
                container
                spacing={4}
                p={2}
                sx={{
                    textAlign: 'left'
                }}>


                <Grid
                    item
                    xs={12}>
                    <Grid
                        container
                        spacing={2}>
                        <Grid
                            item
                            xs={12}
                            sx={{
                                mb: 1
                            }}>
                            <Typography
                                variant='h3'>
                                Paramètres de tarification
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                id="offerCode"
                                name="offerCode"
                                label={"Code de l'offre *"}
                                value={formik.values.offerCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.offerCode && Boolean(formik.errors.offerCode)}
                                helperText={formik.touched.offerCode && formik.errors.offerCode}>
                                {getEnumKeys(PromotionOffer).map((key, index) => {
                                    return <MenuItem key={index} value={PromotionOffer[key]}>{key}</MenuItem>
                                })
                                }
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                id="isDirect"
                                name="isDirect"
                                label="Tarification directe"
                                value={formik.values.isDirect}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.isDirect && Boolean(formik.errors.isDirect)}
                                helperText={formik.touched.isDirect && formik.errors.isDirect}>
                                <MenuItem value={true as any}>Oui (Cabinet GSMC)</MenuItem>
                                <MenuItem value={false as any}>Non</MenuItem>
                            </TextField>
                        </Grid>
                        {!formik.values.isDirect &&
                            <Grid
                                item
                                xs={6}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    select
                                    id="commissionPercentage"
                                    name="commissionPercentage"
                                    label="Taux de commission *"
                                    value={formik.values.commissionPercentage}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    error={formik.touched.commissionPercentage && Boolean(formik.errors.commissionPercentage)}
                                    helperText={formik.touched.commissionPercentage && formik.errors.commissionPercentage}>
                                    <MenuItem key={0} value={0}>0 %</MenuItem>
                                    <MenuItem key={10} value={10}>10 %</MenuItem>
                                    <MenuItem key={15} value={15}>15 %</MenuItem>
                                    <MenuItem key={20} value={20}>20 %</MenuItem>
                                </TextField>
                            </Grid>}
                        <Grid
                            item
                            xs={6}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                select
                                id="regime"
                                name="regime"
                                label="Régime *"
                                value={formik.values.regime}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.regime && Boolean(formik.errors.regime)}
                                helperText={formik.touched.regime && formik.errors.regime}>
                                {enumGsmcRegimes.map(regime => {
                                    return <MenuItem key={regime.value} value={regime.value}>{regime.label}</MenuItem>
                                })
                                }
                            </TextField>
                        </Grid>
                        <Grid
                            item
                            xs={6}>
                            <TextField
                                fullWidth
                                id="postalCode"
                                label="Code Postal *"
                                value={formik.values.postalCode}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.postalCode && Boolean(formik.errors.postalCode)}
                                helperText={formik.touched.postalCode && formik.errors.postalCode}
                            />
                        </Grid>
                        <Grid
                            item
                            xs={6}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={fr}
                                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                <DatePicker
                                    label="Date d'effet *"
                                    value={formik.values.date}
                                    onChange={(value: any) => formik.setFieldValue("date", value)}
                                    localeText={{
                                        toolbarTitle: "Sélectionnez une date",
                                        okButtonLabel: "Valider"
                                    }}
                                    format='dd/MM/yyyy'
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                width: '100%'
                                            },
                                            onBlur: (e) => formik.setFieldTouched("date"),
                                            error: formik.touched.date && Boolean(formik.errors.date),
                                            helperText: formik.touched.date && <>{formik.errors.date}</>,
                                        },
                                    }}/>
                            </LocalizationProvider>
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'
                                sx={{
                                    ml: 2
                                }}>
                                ⚠️ La tarification auprès d'Henner est uniquement possible avec une date d'effet dans le
                                futur.
                            </Typography>
                        </Grid>
                        <Grid
                            item
                            xs={6}>
                            <LocalizationProvider
                                dateAdapter={AdapterDateFns}
                                adapterLocale={fr}
                                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                <DatePicker
                                    label="Date de naissance du souscripteur *"
                                    value={formik.values.subscriberBirthDate}
                                    onChange={(value: any) => formik.setFieldValue("subscriberBirthDate", value)}
                                    localeText={{
                                        toolbarTitle: "Sélectionnez une date",
                                        okButtonLabel: "Valider"
                                    }}
                                    format='dd/MM/yyyy'
                                    slotProps={{
                                        textField: {
                                            sx: {
                                                width: '100%'
                                            },
                                            onBlur: (e) => formik.setFieldTouched("subscriberBirthDate"),
                                            error: formik.touched.subscriberBirthDate && Boolean(formik.errors.subscriberBirthDate),
                                            helperText: formik.touched.subscriberBirthDate && <>{formik.errors.subscriberBirthDate}</>,
                                        },
                                    }}/>
                            </LocalizationProvider>
                        </Grid>
                        <Grid
                            item
                            xs={12}>
                            <Stack direction="row" alignItems="center" gap={1}>

                                <Switch
                                    checked={formik.values.partner}
                                    onChange={(e, value) => {
                                        formik.setFieldValue("partner", value);
                                        formik.setFieldValue("partnerBirthDate", null);
                                        formik.setFieldTouched("partnerBirthDate", false);
                                    }
                                    }/>
                                <Typography
                                    variant='h3'>
                                    Renseigner un conjoint
                                </Typography>
                            </Stack>
                        </Grid>
                        {formik.values.partner && <Grid
                            item
                            xs={6}>
                            <Collapse in={formik.values.partner}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={fr}
                                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DatePicker
                                        label="Date de naissance du conjoint"
                                        value={formik.values.partnerBirthDate}
                                        onChange={(value: any) => formik.setFieldValue("partnerBirthDate", value)}
                                        localeText={{
                                            toolbarTitle: "Sélectionnez une date",
                                            okButtonLabel: "Valider"
                                        }}
                                        format='dd/MM/yyyy'
                                        slotProps={{
                                            textField: {
                                                sx: {
                                                    width: '100%'
                                                },
                                                onBlur: (e) => formik.setFieldTouched("partnerBirthDate"),
                                                error: formik.touched.partnerBirthDate && Boolean(formik.errors.partnerBirthDate),
                                                helperText: formik.touched.partnerBirthDate && <>{formik.errors.partnerBirthDate}</>,
                                            },
                                        }}/>
                                </LocalizationProvider>
                            </Collapse>
                        </Grid>}

                        <Grid
                            item
                            xs={12}>
                            <Button onClick={handleClickAddChildren} startIcon={<AddIcon/>}>
                                <Typography
                                    variant='h3'>
                                    Ajouter un enfant
                                </Typography>
                            </Button>
                        </Grid>

                        {formik.values.childrenBirthDates.map((child, index) => {
                            return (<Grid
                                item
                                xs={6}
                                p={1}>
                                <LocalizationProvider
                                    dateAdapter={AdapterDateFns}
                                    adapterLocale={fr}
                                    localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                    <DatePicker
                                        label={"Date de naissance de l'enfant " + (index + 1)}
                                        value={child}
                                        onChange={(value: any) => {
                                            const values: any[] = [...formik.values.childrenBirthDates];
                                            values[index] = value;
                                            formik.setFieldValue("childrenBirthDates", values);
                                        }}
                                        localeText={{
                                            toolbarTitle: "Sélectionnez une date",
                                            okButtonLabel: "Valider"
                                        }}
                                        format='dd/MM/yyyy'
                                        slotProps={{
                                            textField: {
                                                sx: {
                                                    width: '100%'
                                                },
                                            },
                                        }}/>
                                </LocalizationProvider>
                            </Grid>);
                        })}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                p={2}
                xs={12}>
                <LoadingButton
                    type='submit'
                    loading={loading}
                    loadingIndicator={
                        <Stack direction="row" alignItems="center" gap={1}>
                            <CircularProgress/>
                            <Typography
                                noWrap
                                sx={{
                                    textAlign: 'center',
                                    color: 'white'
                                }}>
                                Tarification en cours...
                            </Typography>
                        </Stack>
                    }
                    sx={{
                        width: {
                            sm: 'auto',
                            xs: '100%'
                        },
                        px: {
                            sm: 10,
                            xs: 0
                        },
                        color: 'white',
                        backgroundColor: colors.black.main,
                        boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                        ':hover': {
                            backgroundColor: 'black'
                        }
                    }}>
                    Calculer les tarifs
                </LoadingButton>
                {error &&
                    <Typography variant={"h3"} p={2}> ⚠️ Une erreur technique est apparue lors du calcul de la tarification</Typography>
                }
            </Grid>
        </form>
    )
}

import { useEffect } from 'react';

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import fr from 'date-fns/locale/fr';
import { frFR } from '@mui/x-date-pickers/locales';

import { ButtonGroup, Collapse, InputAdornment, TextField } from '@mui/material';
import Button from "@mui/material/Button";
import Card from '@mui/material/Card';
import Checkbox, { checkboxClasses } from '@mui/material/Checkbox';
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from '@mui/material/IconButton';
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";

import { useFormik } from 'formik';
import * as yup from 'yup';

import { TransitionSlideUP } from '../../utils/transistions/transitions';

import { CloseIcon } from '../Icon/CloseIcon';
import { CardInfo } from '../Card/CardInfo';

import { enumHrRoleChild, enumHrRoleConjoint } from '../../utils';
import { messageFieldRequired } from '../../utils/messages';

import { IPerson } from '../../model/Person.model';

import { createPerson, deletePerson, modifyPerson } from '../../api/Person.api';
import { convertUTCDateToLocalDate } from '../../utils/dateUtils';
import * as Sentry from "@sentry/react";


const today = new Date();

const dateBirthMax = new Date(today);
dateBirthMax.setFullYear(dateBirthMax.getFullYear() - 200);

const dateBirthMinAdult = new Date(today);
dateBirthMinAdult.setFullYear(dateBirthMinAdult.getFullYear() - 18);
dateBirthMinAdult.setMonth(0)
dateBirthMinAdult.setDate(1)

const dateBirthMaxChild = new Date(today);
dateBirthMaxChild.setFullYear(dateBirthMaxChild.getFullYear() - 28);


interface Props {
    plateformStyle: any;
    isOpened: boolean;
    subscriber: IPerson;
    conjoint?: IPerson;
    children?: IPerson[];
    sendConjoint: (conjoint?: IPerson) => void;
    sendChildren: (children?: IPerson[]) => void;
    handleClose: () => void;
}


export const DialogBeneficiaries = (props: Props) => {

    const theme = useTheme();
    const screenSizeUpSM = useMediaQuery(theme.breakpoints.up('sm'));

    const plateformStyle: any = props.plateformStyle;
    const iconHeaderStyle: any = plateformStyle.components.IconHeader;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;

    const getInitialValues = () => {
        return {
            conjoint: props.conjoint?.dateBirth ? new Date(props.conjoint.dateBirth) : undefined,
            children: props.children && props.children.length === 0 ? undefined : props.children?.map(_ => _.dateBirth ? new Date(_.dateBirth) : undefined)
        } as {conjoint: Date | undefined | null, children: (Date | null)[] | undefined}
    }

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: yup.object({
            conjoint: yup
                .date()
                .nullable()
                .test(
                    'nonNullable',
                    messageFieldRequired,
                    function (item: any) {
                        return item !== null
                })
                .min(dateBirthMax, "Date non valide")
                .max(dateBirthMinAdult, "Votre conjoint doit avoir au moins 18 ans au 1er janvier " + today.getFullYear()),
            children: yup.array().of(yup
                .date()
                .nullable()
                .test(
                    'nonNullable',
                    messageFieldRequired,
                    function (item: any) {
                        return item !== null
                })
                .min(dateBirthMaxChild, "Votre enfant ne peut pas avoir plus de 28 ans")
                .max(today, "Date non valide"))
        }),
        onSubmit: (values) => {

            if (formik.values.conjoint !== undefined && formik.values.conjoint !== null) {
                let c: IPerson = {
                    ...props.conjoint,
                    role: enumHrRoleConjoint,
                    dateBirth: convertUTCDateToLocalDate(formik.values.conjoint)
                }

                saveConjoint(c)
            }
            else
                deleteConjoint()

            let promises: Promise<any>[] = []
            if (formik.values.children !== undefined && formik.values.children !== null) {
                formik.values.children.map((_, id) => {

                    let c: IPerson = props.children && props.children[id] ? {...props.children[id]} : {role: enumHrRoleChild}

                    c.role = enumHrRoleChild
                    c.person = props.subscriber

                    if (_ === null) return

                    c.dateBirth = convertUTCDateToLocalDate(_)

                    if (!c?.id) {
                        promises.push(createPerson(c).then((response : IPerson) => {
                            if(!response.id) {
                                Sentry.captureMessage("DialogBeneficiaries -> onSubmit -> createPerson - Exception",
                                    {
                                        level: 'error',
                                        extra: {
                                            response: response,
                                            request: c,
                                        }
                                    }
                                );
                                return undefined
                            }
                            else {
                                return response
                            }
                        }));
                    }
                    else {
                        promises.push(modifyPerson(c).then((response : IPerson) => {
                            if(!response.id) {
                                Sentry.captureMessage("DialogBeneficiaries -> onSubmit -> modifyPerson - Exception",
                                    {
                                        level: 'error',
                                        extra: {
                                            response: response,
                                            request: c,
                                        }
                                    }
                                );
                                return undefined
                            }
                            else {
                                return response
                            }
                        }));
                    }
                })

                Promise.all(promises).then((_: IPerson[]) => props.sendChildren(_.filter(_ => _ !== undefined)))
            }
            else
                props.sendChildren([])

            props.children && props.children.map((_, id) => {
                if (_.id !== undefined && (formik.values.children === undefined || formik.values.children.length <= id))
                    deletePerson(_.id)
            })

            props.handleClose()
        }
    });

    const saveConjoint = (conjoint: IPerson) => {
        conjoint.person = props.subscriber;

        if (!conjoint.id) {
            createPerson(conjoint).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveConjoint -> createPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: conjoint,
                            }
                        }
                    );
                }
                else {
                    props.sendConjoint(response);  // Update Form states.
                }
            });
        }
        else {
            modifyPerson(conjoint).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveConjoint -> modifyPerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: conjoint,
                            }
                        }
                    );
                }
                else {
                    props.sendConjoint(response);  // Update Form states.
                }
            });
        }
    }

    const deleteConjoint = () => {
        if (props.conjoint?.id) {
            deletePerson(props.conjoint.id).then((response : IPerson) => {
                if(!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("deleteConjoint -> deletePerson - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props?.conjoint?.id?.toString(),
                            }
                        }
                    );
                }
            });
        }
        props.sendConjoint(undefined)
    }


    useEffect(() => {
        if (!props.isOpened) formik.resetForm()

        const init = getInitialValues()
        formik.setFieldValue("conjoint", init.conjoint)
        formik.setFieldValue("children", init.children)
    }, [props.isOpened,
        props.children,
        props.conjoint])


    return (
        <Dialog
            fullWidth
            maxWidth={"lg"}
            fullScreen={!screenSizeUpSM}
            TransitionComponent={screenSizeUpSM ? undefined : TransitionSlideUP}
            open={props.isOpened}
            onClose={(event, reason) => {
                if (reason === 'backdropClick' || reason === "escapeKeyDown")
                    return;

                props.handleClose();
            }}>

            <DialogTitle>
                <Typography variant="h2">Qui souhaitez-vous couvrir ?</Typography>
                <IconButton
                    sx={{
                        position: 'absolute',
                        top: 10,
                        right: 10,
                    }}
                    onClick={() => props.handleClose()}>
                    <CloseIcon color={plateformStyle.colors.black.main}/>
                </IconButton>
            </DialogTitle>

            <DialogContent>
                <form onSubmit={formik.handleSubmit}>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="center"
                        alignItems="stretch"
                        sx={{
                            height: "100%"
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <CardInfo
                                plateformStyle={plateformStyle}>
                                Les tarifs évoluent en fonction du nombre de personnes couvertes et en fonction de leur âge.
                            </CardInfo>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}>
                            <Card
                                sx={{
                                    p: 2,
                                    border: formik.values.conjoint !== undefined ? 2 : 0,
                                    borderColor: iconHeaderStyle.color,
                                    ":hover": formik.values.conjoint === undefined ? {
                                        cursor: "pointer",
                                        background: plateformStyle.colors.grey.main
                                    } : {}
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    if (formik.values.conjoint === undefined)
                                        formik.setFieldValue("conjoint", null)
                                }}>
                                <Grid
                                    container
                                    justifyContent="center"
                                    alignItems="center">
                                    <Grid
                                        item
                                        xs={12}>
                                        <Grid
                                            container
                                            justifyContent="center"
                                            alignItems="center"
                                            spacing={1}>
                                            <Grid
                                                item
                                                xs="auto">
                                                <Checkbox
                                                    checked={formik.values.conjoint !== undefined}
                                                    sx={{
                                                        p: 0,
                                                        [`&, &.${checkboxClasses.checked}`]: {
                                                            color: iconHeaderStyle.color,
                                                        },
                                                    }}
                                                    onChange={(e, value) => {
                                                        if (value)
                                                            formik.setFieldValue("conjoint", null)
                                                        else {
                                                            formik.setFieldValue("conjoint", undefined)
                                                            formik.setFieldTouched("conjoint", false)
                                                        }}}
                                                    />
                                            </Grid>
                                            <Grid
                                                item
                                                xs>
                                                <Typography
                                                    variant="h3"
                                                    component="span"
                                                    onClick={(e) => {
                                                        if (formik.values.conjoint === undefined)
                                                            formik.setFieldValue("conjoint", null)
                                                        else {
                                                            formik.setFieldValue("conjoint", undefined)
                                                            formik.setFieldTouched("conjoint", false)
                                                        }}
                                                    }
                                                    sx={{
                                                        cursor: "pointer"
                                                    }}>
                                                    Votre conjoint
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>

                                    <Grid
                                        item
                                        xs={12}>
                                        <Collapse in={formik.values.conjoint !== undefined}>
                                            <LocalizationProvider
                                                dateAdapter={AdapterDateFns}
                                                adapterLocale={fr}
                                                localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                <DatePicker
                                                    value={formik.values.conjoint}
                                                    onChange={(value: any) => formik.setFieldValue("conjoint", value)}
                                                    localeText={{
                                                        toolbarTitle: "Sélectionnez une date",
                                                        okButtonLabel: "Valider"
                                                    }}
                                                    minDate={dateBirthMax}
                                                    maxDate={dateBirthMinAdult}
                                                    format='dd/MM/yyyy'
                                                    slotProps={{
                                                        textField: {
                                                            fullWidth: true,
                                                            sx: {
                                                                mt: 2,
                                                                width: '100%'
                                                            },
                                                            onBlur: (e: any) => formik.setFieldTouched("conjoint"),
                                                            error: formik.touched.conjoint && Boolean(formik.errors.conjoint),
                                                            helperText: formik.touched.conjoint && <>{formik.errors.conjoint}</>,
                                                        },
                                                    }} />
                                            </LocalizationProvider>
                                        </Collapse>
                                    </Grid>
                                </Grid>
                            </Card>
                        </Grid>
                        <Grid
                            item
                            md={6}
                            xs={12}>
                            <Card
                                sx={{
                                    p: 2,
                                    border: formik.values.children !== undefined ? 2 : 0,
                                    borderColor: iconHeaderStyle.color,
                                    ":hover": formik.values.children === undefined ? {
                                        cursor: "pointer",
                                        background: plateformStyle.colors.grey.main
                                    } : {}
                                }}
                                onClick={(e) => {
                                    e.stopPropagation();

                                    if (formik.values.children === undefined)
                                        formik.setFieldValue("children", [null])
                                }}>
                                    <Grid
                                        container
                                        justifyContent="center"
                                        alignItems="center">
                                        <Grid
                                            item
                                            xs={12}>
                                            <Grid
                                                item
                                                xs={12}>
                                                <Grid
                                                    container
                                                    justifyContent="center"
                                                    alignItems="center"
                                                    spacing={1}>
                                                    <Grid
                                                        item
                                                        xs="auto">
                                                        <Checkbox
                                                            checked={formik.values.children !== undefined}
                                                            sx={{
                                                                p: 0,
                                                                [`&, &.${checkboxClasses.checked}`]: {
                                                                    color: iconHeaderStyle.color,
                                                                },
                                                            }}
                                                            onChange={(e, value) => {
                                                                if (value)
                                                                    formik.setFieldValue("children", [null])
                                                                else {
                                                                    formik.setFieldValue("children", undefined)
                                                                    formik.setFieldTouched("children", false)
                                                                }}}
                                                            />
                                                    </Grid>
                                                    <Grid
                                                        item
                                                        xs>
                                                        <Typography
                                                            variant="h3"
                                                            component="span"
                                                            onClick={(e) => {
                                                                if (formik.values.children === undefined)
                                                                    formik.setFieldValue("children", [null])
                                                                else {
                                                                    formik.setFieldValue("children", undefined)
                                                                    formik.setFieldTouched("children", false)
                                                                }}
                                                            }
                                                            sx={{
                                                                cursor: "pointer"
                                                            }}>
                                                            Vos enfants
                                                        </Typography>
                                                    </Grid>

                                                    {formik.values.children &&
                                                    <Grid
                                                        item
                                                        xs="auto">
                                                        <ButtonGroup
                                                            variant="contained"
                                                            color="inherit"
                                                            sx={{
                                                                boxShadow: "none"
                                                            }}>
                                                            <Button
                                                                sx={{
                                                                    py: 0,
                                                                    background: plateformStyle.colors.black.main,
                                                                    color: "white",
                                                                    ":hover": {
                                                                        background: formik.values.children?.length === 1 ? plateformStyle.colors.black.main : "black"
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    if (!formik.values.children || formik.values.children?.length === 1) return
                                                                    let c = [...formik.values.children]
                                                                    c.pop()

                                                                    formik.setFieldValue("children", c)
                                                                }}>
                                                                -
                                                            </Button>
                                                            <Button
                                                                sx={{
                                                                    py: 0,
                                                                    background: plateformStyle.colors.black.main,
                                                                    color: "white",
                                                                    ":hover": {
                                                                        background: plateformStyle.colors.black.main
                                                                    }
                                                                }}>
                                                                {formik.values.children?.length}
                                                            </Button>
                                                            <Button
                                                                sx={{
                                                                    py: 0,
                                                                    background: plateformStyle.colors.black.main,
                                                                    color: "white",
                                                                    ":hover": {
                                                                        background: "black"
                                                                    }
                                                                }}
                                                                onClick={() => {
                                                                    if (!formik.values.children) return

                                                                    let c = [...formik.values.children]
                                                                    c.push(null)

                                                                    formik.setFieldValue("children", c)
                                                                    formik.setFieldTouched("children", false)
                                                                }}>
                                                                +
                                                            </Button>
                                                        </ButtonGroup>
                                                    </Grid>}
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid
                                            item
                                            xs
                                            sx={{
                                                mt: 2
                                            }}>
                                            <Collapse in={formik.values.children !== undefined}>
                                                <Grid
                                                    container
                                                    spacing={2}
                                                    justifyContent="center"
                                                    alignItems="center">
                                                    {formik.values?.children && formik.values.children.map((_, index) => (
                                                    <Grid
                                                        key={index}
                                                        item
                                                        xs={12}>
                                                        <LocalizationProvider
                                                            dateAdapter={AdapterDateFns}
                                                            adapterLocale={fr}
                                                            localeText={frFR.components.MuiLocalizationProvider.defaultProps.localeText}>
                                                            <DatePicker
                                                                value={_}
                                                                onChange={(value: any) => {
                                                                    if (!formik.values.children) return
                
                                                                    let c = [...formik.values.children]
                                                                    c[index] = value

                                                                    formik.setFieldValue("children", c)
                                                                }}
                                                                localeText={{
                                                                    toolbarTitle: "Sélectionnez une date",
                                                                    okButtonLabel: "Valider"
                                                                }}
                                                                minDate={dateBirthMaxChild}
                                                                maxDate={today}
                                                                format='dd/MM/yyyy'
                                                                slotProps={{
                                                                    textField: {
                                                                        fullWidth: true,
                                                                        sx: {
                                                                            maxWidth: '400px'
                                                                        },
                                                                        onBlur: (e: any) => formik.setFieldTouched("children"),
                                                                        error: formik.touched.children && formik.errors.children !== undefined && Boolean((formik.errors.children as any)[index]),
                                                                        helperText: formik.touched.children && formik.errors.children !== undefined && <>{(formik.errors.children as any)[index]}</>
                                                                    },
                                                                }} />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    ))}
                                                </Grid>
                                            </Collapse>
                                        </Grid>
                                    </Grid>
                            </Card>
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>

            <DialogActions
                sx={{
                    p: 2,
                    pt: 0,
                    justifyContent: "center"
                }}>
                <Button
                    type="submit"
                    variant="contained"
                    color={buttonSubmitStyle.color}
                    sx={{
                        width: {
                            sm: 400,
                            xs: '100%'
                        },
                        px: 5,
                        color: 'white'
                    }}
                    onClick={() => {
                        formik.handleSubmit()
                    }} >
                    Continuer
                </Button>
            </DialogActions>
        </Dialog>
    )
}

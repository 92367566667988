import { defaultIconHeaderSize, IIcon } from "./settings"


export const BillingPaymentIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconHeaderSize.width}
            height={props.height ? props.height : defaultIconHeaderSize.height}
            viewBox="0 0 40 40"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" r="20" fill={props.bgColor} />
        <mask id="mask0_258_4475" maskUnits="userSpaceOnUse" x="0" y="0" width="40" height="40">
        <path d="M20 2.94047e-06C31.0457 2.45765e-06 40 8.95431 40 20C40 31.0457 31.0457 40 20 40C8.95431 40 -3.91405e-07 31.0457 -8.74228e-07 20C-1.35705e-06 8.95431 8.9543 3.42329e-06 20 2.94047e-06Z" fill="#5FDED6"/>
        </mask>
        <g mask="url(#mask0_258_4475)">
        <path d="M1.43182 17.4427L-10.0964 28.6753C-10.8029 29.3637 -11.1245 30.3561 -10.956 31.3281L-9.22899 41.2917C-8.97886 42.7348 -7.72044 43.7844 -6.25589 43.7715L50.2641 43.2727C52.3997 43.2539 53.8279 41.0671 52.9866 39.1041L38.7188 5.81264C37.7281 3.50115 34.5003 3.37014 33.3256 5.59373L22.2668 26.5264C21.3813 28.2027 19.1794 28.6425 17.7178 27.4351L5.42359 17.279C4.24633 16.3065 2.5255 16.3771 1.43182 17.4427Z" fill="url(#paint0_linear_258_4475)"/>
        </g>
        <g filter="url(#filter0_i_258_4475)">
        <path fillRule="evenodd" clipRule="evenodd" d="M4.42373 17C1.98057 17 0 18.9631 0 21.3846V31.6154C0 34.0369 1.98057 36 4.42373 36H24.5763C27.0194 36 29 34.0369 29 31.6154V21.3846C29 18.9631 27.0194 17 24.5763 17H4.42373ZM18.8614 34.1C19.8162 34.1 20.6733 33.6853 21.2591 33.0279C21.8449 33.6853 22.702 34.1 23.6568 34.1C25.4224 34.1 26.8537 32.6822 26.8537 30.9333C26.8537 29.1844 25.4224 27.7667 23.6568 27.7667C22.702 27.7667 21.8449 28.1813 21.2591 28.8388C20.6733 28.1813 19.8162 27.7667 18.8614 27.7667C17.0958 27.7667 15.6646 29.1844 15.6646 30.9333C15.6646 32.6822 17.0958 34.1 18.8614 34.1Z" fill={props.color} />
        </g>
        <path fillRule="evenodd" clipRule="evenodd" d="M32.5142 0.270385C32.1692 -0.0901282 31.6097 -0.0901287 31.2647 0.270385C30.9196 0.630898 30.9196 1.21541 31.2647 1.57592L36.1755 6.46571L21.2692 6.46571C20.5682 6.46571 20 6.89628 20 7.42741C20 7.95854 20.5682 8.38911 21.2692 8.38911L36.4654 8.38911L31.2647 13.8228C30.9196 14.1833 30.9196 14.7678 31.2647 15.1283C31.6097 15.4888 32.1692 15.4888 32.5142 15.1283L38.9999 8.35213C39.3449 7.99161 39.3449 7.40711 38.9999 7.04659L32.5142 0.270385Z" fill={props.color} />
        <defs>
        <filter id="filter0_i_258_4475" x="0" y="17" width="29" height="19" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset/>
        <feGaussianBlur stdDeviation="1"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0.916667 0 0 0 0 0.482167 0 0 0 0 0.0916667 0 0 0 1 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_258_4475"/>
        </filter>
        <linearGradient id="paint0_linear_258_4475" x1="22.9804" y1="4.91537" x2="22.9804" y2="43.7939" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        </defs>
        </svg>
    )
}


import { createRef, useEffect, useState } from 'react';

import Box from '@mui/system/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';

import { FormBilling } from '../../components/Form/FormBilling';
import { FormDebiting } from '../../components/Form/FormDebiting';
import { FormHeader } from '../../components/Form/FormHeader';
import { FormHeaderSection } from '../../components/Form/FormHeaderSection';
import { BillingIncomeIcon } from '../../components/Icon/BillingIncomeIcon';
import { BillingPaymentIcon } from '../../components/Icon/BillingPaymentIcon';
import { InfoIcon } from '../../components/Icon/InfoIcon';

import { IContract } from '../../model/Contract.model';
import { IContractBilling } from '../../model/ContractBilling.model';
import { IContractDebiting } from '../../model/ContractDebiting.model';
import { IPlateformeSettings } from '../../model/PlateformeSettings.model';

import { modifyContract } from '../../api/Contract.api';
import { createContractBilling,
         modifyContractBilling } from '../../api/ContractBilling.api';
import { createContractDebiting,
         modifyContractDebiting } from '../../api/ContractDebiting.api';

import { enumContractBillingTransactionTypeAll, enumContractBillingTransactionTypePayment } from '../../utils';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { CardInfo } from '../../components/Card/CardInfo';
import { IForm } from '../../model/Form.model';
import { IPerson } from '../../model/Person.model';
import * as Sentry from "@sentry/react";


interface Props {
    plateformStyle: any;
    plateformSettings: IPlateformeSettings;
    sidebar?: JSX.Element;
    form?: IForm
    subscriber?: IPerson
    contract: IContract;
    billingMain: IContractBilling;
    billingSecondary: IContractBilling;
    debiting: IContractDebiting;
    isControlFraud?: boolean
    sendContract: (contract: IContract) => void;
    sendBilling: (contractBilling: IContractBilling) => void;
    sendDebiting: (contractDebiting: IContractDebiting) => void;
    goToPreviousFlag: boolean;
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormContractBilling = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const buttonSubmitStyle: any = plateformStyle.components.Button.submit;
    const iconInfoStyle: any = plateformStyle.components.IconInfo;
    const iconHeaderStyle: any = plateformStyle.components.IconHeader;
    const listStyle: any = plateformStyle.components.List;
    const progressBarStyle: any = plateformStyle.components.ProgressBar;

    const refFormBillingMain = createRef<HTMLFormElement>();
    const refFormBillingSecondary = createRef<HTMLFormElement>();
    const refFormDebiting = createRef<HTMLFormElement>();

    const [isSeveralAccount, setIsSeveralAccount] = useState<boolean>(props.billingSecondary.isActive && props.plateformSettings.settingsBanking.enableSeveralAccounts);
    const [progressBillingMain, setProgressBillingMain] = useState<number>(0);
    const [progressBillingSecondary, setProgressBillingSecondary] = useState<number>(0);
    const [progressDebiting, setProgressDebiting] = useState<number>(0);

    const toggleBillingSecondary = (switchValue: boolean) => {
        setIsSeveralAccount(!switchValue);
        saveBilling(props.billingMain, true);
        saveBilling(props.billingSecondary, false);
    }

    const sendBilling = (billing: IContractBilling) => {
        props.sendBilling(billing);
    }

    const saveBilling = (billing: IContractBilling, isMain?: boolean) => {
        billing.contract = props.contract;

        if (isMain !== undefined) {
            if (isMain) {
                billing.typeTransaction = isSeveralAccount ? enumContractBillingTransactionTypeAll : enumContractBillingTransactionTypePayment;
            }
            else {
                billing.isActive = !isSeveralAccount;
            }
        }

        if (!billing.id) {
            createContractBilling(billing).then((response: IContractBilling) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveBilling -> createContractBilling - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: billing,
                            }
                        }
                    );
                }
                else {
                    sendBilling(response);  // Update Form states.
                }
            });
        }
        else {
            modifyContractBilling(billing).then((response: IContractBilling) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveBilling -> modifyContractBilling - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: billing,
                            }
                        }
                    );
                }
                else {
                    sendBilling(response);  // Update Form states.
                }
            });
        }
    }

    const sendDebiting = (debiting: IContractDebiting) => {

        if (debiting.dateDebiting)
            debiting.dateDebiting = new Date(debiting.dateDebiting);

        props.sendDebiting(debiting);
    }

    const sendContract = (contract: IContract) => {
        props.sendContract(contract);
    }

    const saveDebiting = (debiting: IContractDebiting) => {
        // TODO : Manage dateDebiting with JsonFormat (currently date - 1)
        if (!debiting.id) {
            createContractDebiting(debiting)
            .then((response: IContractDebiting) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveDebiting -> createContractDebiting - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: debiting,
                            }
                        }
                    );
                }
                sendDebiting(response);  // Update Form states.

                let contract: IContract = props.contract;

                contract.debiting = response;

                return modifyContract(contract);
            })
            .then((response: IContract) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveDebiting -> modifyContract - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.contract,
                            }
                        }
                    );
                }
                sendContract(response);  // Update Form states.
            });
        }
        else {
            modifyContractDebiting(debiting)
            .then((response: IContractDebiting) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveDebiting -> modifyContractDebiting - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: debiting,
                            }
                        }
                    );
                }
                sendDebiting(response);  // Update Form states.

                let contract: IContract = props.contract;

                contract.debiting = response;

                return modifyContract(contract);
            })
            .then((response: IContract) => {
                if (!response.id) {
                    // TODO : Gestion erreur.
                    Sentry.captureMessage("saveDebiting -> modifyContract - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.contract,
                            }
                        }
                    );
                }
                sendContract(response);  // Update Form states.
            });
        }
    }


    useEffect(() => {
        if (!props.goToPreviousFlag)
            return;

        if (refFormBillingMain.current)
            refFormBillingMain.current.leave();

        if (refFormBillingSecondary.current)
            refFormBillingSecondary.current.leave();

        if (refFormDebiting.current)
            refFormDebiting.current.leave();

        props.goToPrevious();
    }, [props.goToPreviousFlag])


    return (
        <PageContentLayout
            header={
                <FormHeader
                    label="Informations bancaires"
                    description="Veuillez renseigner vos informations bancaires" />}
            sidebar={props.sidebar}
            content={
                <>
                {props.plateformSettings.settingsBanking.enableSeveralAccounts &&
                <>
                <FormControlLabel
                    label="Utiliser plusieurs comptes bancaires"
                    labelPlacement="end"
                    control={
                        <Switch
                            checked={isSeveralAccount}
                            onChange={(e, value) => toggleBillingSecondary(!value)} />
                    } />
                <Typography
                    variant='caption'
                    component='div'
                    fontSize='0.9rem'
                    sx={{
                        mb: 2
                    }} >
                    Utiliser plusieurs comptes bancaires vous permet de définir un compte bancaire sur lequel seront prélevées vos cotisations et un compte sur lequel seront versés vos remboursements.
                </Typography>
                </>}

                <Card
                    sx={{
                        mb: 2
                    }}>
                    <LinearProgress
                        variant="determinate"
                        value={progressBillingMain}
                        color={progressBarStyle.form.color} />

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection
                                label={`Coordonnées bancaires${isSeveralAccount ? " de prélèvement" : ""}`}
                                icon={<BillingPaymentIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor} />} />
                            
                            {isSeveralAccount &&
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Ce compte sera utilisé pour vous prélever vos cotisations.
                            </Typography>}
                        </Grid>
                        
                        <Grid
                            item
                            xs={12}>
                            <CardInfo
                                plateformStyle={plateformStyle}>
                                <Typography fontWeight={500} >
                                    Veuillez renseigner les informations du compte bancaire sur lequel :
                                    <ul
                                        style={{
                                            margin: 0,
                                            marginTop: '4px'
                                        }}>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Vos cotisations seront prélevées le 5 de chaque mois
                                            </Typography>
                                        </li>
                                        {!isSeveralAccount &&
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Vos remboursements de frais de santé seront versés
                                            </Typography>
                                        </li>}
                                    </ul>

                                    {!isSeveralAccount &&
                                    <>
                                    <Box sx={{py: 1}}></Box>
                                    Vous serez notifié par email de la mise à disposition de votre décompte de remboursement sur votre Espace adhérent, avec un lien pour y accéder.
                                    </>}
                                </Typography>
                            </CardInfo>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormBilling
                                plateformStyle={props.plateformStyle}
                                ref={refFormBillingMain}
                                form={props.form}
                                subscriber={props.subscriber}
                                billing={props.billingMain}
                                contractId={props.contract.id}
                                isControlFraud={props.isControlFraud}
                                showBic={props.plateformSettings.settingsBanking.displayFieldBIC}
                                showOwnerName={props.plateformSettings.settingsBanking.displayFieldOwnerName}
                                showBankName={props.plateformSettings.settingsBanking.displayFieldBankName}
                                send={sendBilling}
                                save={saveBilling}
                                sendProgress={setProgressBillingMain} />
                        </Grid>
                    </Grid>
                </Card>

                {isSeveralAccount &&
                <Card
                    sx={{
                        mb: 2
                    }}>
                    <LinearProgress
                        variant="determinate"
                        value={progressBillingSecondary}
                        color={progressBarStyle.form.color} />

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection
                                label="Coordonnées bancaires de remboursement"
                                icon={<BillingIncomeIcon color={iconHeaderStyle.color} bgColor={iconHeaderStyle.bgColor} />} />
                            
                            {isSeveralAccount &&
                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem' >
                                Ce compte sera utilisé pour vous verser vos remboursements.
                            </Typography>}
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <CardInfo
                                plateformStyle={plateformStyle}>
                                <Typography fontWeight={500} >
                                    Veuillez renseigner les informations du compte bancaire sur lequel :
                                    <ul
                                        style={{
                                            margin: 0,
                                            marginTop: '4px'
                                        }}>
                                        <li style={{ color: listStyle.color }}>
                                            <Typography variant='body1' fontWeight={500} color="InfoText">
                                                Vos remboursements de frais de santé seront versés
                                            </Typography>
                                        </li>
                                    </ul>
                                    <Box sx={{py: 1}}></Box>
                                    Vous serez notifié par email de la mise à disposition de votre décompte de remboursement sur votre Espace adhérent, avec un lien pour y accéder.
                                </Typography>
                            </CardInfo>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormBilling
                                plateformStyle={props.plateformStyle}
                                ref={refFormBillingSecondary}
                                form={props.form}
                                subscriber={props.subscriber}
                                billing={props.billingSecondary}
                                isControlFraud={props.isControlFraud}
                                showBic={props.plateformSettings.settingsBanking.displayFieldBIC}
                                showOwnerName={props.plateformSettings.settingsBanking.displayFieldOwnerName}
                                showBankName={props.plateformSettings.settingsBanking.displayFieldBankName}
                                send={sendBilling}
                                save={saveBilling}
                                sendProgress={setProgressBillingSecondary} />
                        </Grid>
                    </Grid>
                </Card>}

                {props.plateformSettings.settingsBanking.enableDebitingCustomization &&
                <Card>
                    <LinearProgress
                        variant="determinate"
                        value={progressDebiting}
                        color={progressBarStyle.form.color} />

                    <Grid
                        container
                        spacing={2}
                        sx={{
                            p: 2
                        }}>
                        <Grid
                            item
                            xs={12}>
                            <FormHeaderSection
                                label="Informations de prélèvement" />

                            <Typography
                                variant='caption'
                                component='div'
                                fontSize='0.9rem'>
                                Personnalisez votre date et votre période de prélèvement de vos cotisations.
                            </Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <FormDebiting
                                ref={refFormDebiting}
                                debiting={props.debiting}
                                send={sendDebiting}
                                save={saveDebiting}
                                sendProgress={setProgressDebiting} />
                        </Grid>
                    </Grid>
                </Card>}
                </>
            }
            footer={
                <Button
                    variant='contained'
                    color={buttonSubmitStyle.color}
                    sx={{
                        width: {
                            sm: 400,
                            xs: '100%'
                        },
                        my: 5,
                        px: 5,
                        color: 'white'
                    }}
                    onClick={() => {
                        let isValidBillingMain: boolean = false;
                        let isValidBillingSecondary: boolean = false;
                        let isValidDebiting: boolean = false;

                        if (refFormBillingMain.current)
                            isValidBillingMain = refFormBillingMain.current.handleSubmit();

                        if (refFormBillingSecondary.current && isSeveralAccount)
                            isValidBillingSecondary = refFormBillingSecondary.current.handleSubmit();

                        if (refFormDebiting.current && props.plateformSettings.settingsBanking.enableDebitingCustomization)
                            isValidDebiting = refFormDebiting.current.handleSubmit();

                        if (!isSeveralAccount)
                            isValidBillingSecondary = true;

                        if (!props.plateformSettings.settingsBanking.enableDebitingCustomization)
                            isValidDebiting = true;

                        if (isValidBillingMain && isValidBillingSecondary && isValidDebiting)
                            props.goToNext();
                    }} >
                    Valider les informations
                </Button>
            } />
    )
}

import { defaultIconStepSize, IIcon } from "./settings"


export const StepInformationIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconStepSize.width}
            height={props.height ? props.height : defaultIconStepSize.height}
            viewBox="0 0 80 80"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <mask id="mask0_5_40" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
        <rect width="80" height="80" fill="white"/>
        </mask>
        <mask id="mask1_5_40" maskUnits="userSpaceOnUse" x="0" y="0" width="80" height="80">
        <path d="M40 7.62939e-06C62.0914 6.66374e-06 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 8.59503e-06 40 7.62939e-06Z" fill="white"/>
        </mask>
        <g mask="url(#mask1_5_40)">
        <path d="M40 1.52588e-05C62.0914 1.42932e-05 80 17.9086 80 40C80 62.0914 62.0914 80 40 80C17.9086 80 0 62.0914 0 40C0 17.9086 17.9086 1.62245e-05 40 1.52588e-05Z" fill={props.color}/>
        <path d="M5.87487 38.5126L-17.1815 60.9778C-18.5945 62.3545 -19.2377 64.3395 -18.9008 66.2833L-15.4468 86.2105C-14.9465 89.0967 -12.4296 91.1959 -9.50056 91.1701L103.539 90.1727C107.811 90.135 110.667 85.7615 108.984 81.8354L80.4488 15.2525C78.4675 10.6295 72.0119 10.3675 69.6624 14.8147L47.5449 56.68C45.7737 60.0326 41.3701 60.9122 38.4469 58.4974L13.8584 38.1852C11.5039 36.2402 8.0622 36.3813 5.87487 38.5126Z" fill="url(#paint0_linear_5_40)"/>
        <path d="M17.9438 34.3925C17.9438 30.6762 20.9565 27.6636 24.6728 27.6636H55.327C59.0433 27.6636 62.056 30.6762 62.056 34.3925V50.0935C62.056 53.8098 59.0433 56.8224 55.327 56.8224H24.6728C20.9565 56.8224 17.9438 53.8098 17.9438 50.0935V34.3925Z" fill="white"/>
        <path d="M51.497 49.0468C51.497 51.7308 49.3199 53.9066 46.6342 53.9066C43.9486 53.9066 41.7715 51.7308 41.7715 49.0468C41.7715 46.3628 43.9486 44.187 46.6342 44.187C49.3199 44.187 51.497 46.3628 51.497 49.0468Z" fill="url(#paint1_linear_5_40)"/>
        <path d="M58.7909 49.0468C58.7909 51.7308 56.6138 53.9066 53.9282 53.9066C51.2426 53.9066 49.0654 51.7308 49.0654 49.0468C49.0654 46.3628 51.2426 44.187 53.9282 44.187C56.6138 44.187 58.7909 46.3628 58.7909 49.0468Z" fill="url(#paint2_linear_5_40)"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M30.2805 17.9438C31.3128 17.9438 32.1497 17.107 32.1497 16.0747C32.1497 15.0424 31.3128 14.2055 30.2805 14.2055C29.2482 14.2055 28.4113 15.0424 28.4113 16.0747C28.4113 17.107 29.2482 17.9438 30.2805 17.9438ZM30.2805 19.4392C32.1387 19.4392 33.645 17.9328 33.645 16.0747C33.645 14.2165 32.1387 12.7102 30.2805 12.7102C28.4223 12.7102 26.916 14.2165 26.916 16.0747C26.916 17.9328 28.4223 19.4392 30.2805 19.4392Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M21.6824 62.8037C23.747 62.8037 25.4207 61.13 25.4207 59.0654C25.4207 57.0008 23.747 55.3271 21.6824 55.3271C19.6178 55.3271 17.9441 57.0008 17.9441 59.0654C17.9441 61.13 19.6178 62.8037 21.6824 62.8037ZM21.6824 64.2991C24.5728 64.2991 26.916 61.9559 26.916 59.0654C26.916 56.175 24.5728 53.8318 21.6824 53.8318C18.7919 53.8318 16.4487 56.175 16.4487 59.0654C16.4487 61.9559 18.7919 64.2991 21.6824 64.2991Z" fill="white"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M61.6824 31.4019C62.7147 31.4019 63.5515 30.565 63.5515 29.5327C63.5515 28.5004 62.7147 27.6635 61.6824 27.6635C60.65 27.6635 59.8132 28.5004 59.8132 29.5327C59.8132 30.565 60.65 31.4019 61.6824 31.4019ZM61.6824 32.8972C63.5405 32.8972 65.0468 31.3909 65.0468 29.5327C65.0468 27.6745 63.5405 26.1682 61.6824 26.1682C59.8242 26.1682 58.3179 27.6745 58.3179 29.5327C58.3179 31.3909 59.8242 32.8972 61.6824 32.8972Z" fill="white"/>
        </g>
        <defs>
        <filter id="filter0_i_5_40" x="17.9438" y="27.6636" width="44.1122" height="33.1588" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
        <feFlood floodOpacity="0" result="BackgroundImageFix"/>
        <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
        <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
        <feOffset dy="4"/>
        <feGaussianBlur stdDeviation="10"/>
        <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
        <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"/>
        <feBlend mode="normal" in2="shape" result="effect1_innerShadow_5_40"/>
        </filter>
        <linearGradient id="paint0_linear_5_40" x1="48.9721" y1="13.4579" x2="48.9721" y2="91.2149" gradientUnits="userSpaceOnUse">
        <stop stopColor="white" stopOpacity="0.6"/>
        <stop offset="1" stopColor="white" stopOpacity="0"/>
        </linearGradient>
        <linearGradient id="paint1_linear_5_40" x1="46.6342" y1="44.187" x2="46.6342" y2="53.9066" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.color}/>
        <stop offset="1" stopColor="#E8EEED" stopOpacity="0.2"/>
        </linearGradient>
        <linearGradient id="paint2_linear_5_40" x1="53.9282" y1="44.187" x2="53.9282" y2="53.9066" gradientUnits="userSpaceOnUse">
        <stop stopColor={props.color}/>
        <stop offset="1" stopColor="#E8EEED" stopOpacity="0.2"/>
        </linearGradient>
        </defs>
        </svg>
    )
}

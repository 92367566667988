import { useState } from 'react';

import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

import { DialogInfoRIB } from '../Dialog/DialogInfoRIB';
import { TextFieldFormatted } from './TextFieldFormatted';
import { InfoIcon } from '../Icon/InfoIcon';


interface Props {
    plateformStyle?: any;
    id: string;
    label?: string | JSX.Element;
    value: string | undefined;
    showInfo?: boolean;
    onChange: (event: React.ChangeEvent, value: string, error?: string) => void;
    onBlur?: (e: any) => void;
    error?: boolean | undefined;
    helperText?: boolean | string | undefined;
    fullWidth?: boolean;
}


export const pattern: number[] = [4, 6, 8];
export const separator: string = " ";
export const length: number = 8;


export const TextFieldBIC = (props: Props) => {

    const iconStyle: any = props.plateformStyle?.components.IconHeader;

    const [isDialogOpened, setIsDialogOpened] = useState(false);

    const onChange = (event: React.ChangeEvent, value: string) => {
        const target: HTMLTextAreaElement = event.target as HTMLTextAreaElement;
        let error: string | undefined;

        value = target.value;

        error = value.replace(/\s/g, "").length !== length ? "BIC non valide" : undefined;

        if (value === props.value) return;

        props.onChange(event, value, error);
    }


    return (
        <Grid
            container
            alignItems="center"
            gap={1}>
            <Grid
                item
                xs>
                <TextFieldFormatted
                    id={props.id}
                    label={props.label}
                    fullWidth
                    value={props.value}
                    onChange={onChange}
                    onBlur={props.onBlur}
                    error={props.error}
                    helperText={props.helperText}
                    toUpperCase
                    textLength={length}
                    charactersEnabled="[^0-9A-Za-z]"
                    pattern={pattern}
                    separator={separator} />
            </Grid>

            {props.showInfo &&
            <Grid
                item
                xs='auto'
                sx={{
                    pb: props.error ? 3 : 0,
                    transition: "transform 400ms",
                    ":hover": {
                        transform: "translateY(-6px)"
                    }
                }}>
                <Tooltip title="Où le trouver ?" placement="top">
                    <IconButton
                        aria-label="close"
                        color="inherit"
                        size="small"
                        onClick={() => setIsDialogOpened(true)}>
                        <InfoIcon color={iconStyle?.color || "black"} />
                    </IconButton>
                </Tooltip>
                <DialogInfoRIB
                    plateformStyle={props.plateformStyle}
                    isDisplayed={isDialogOpened}
                    onClose={() => setIsDialogOpened(false)} />
            </Grid>}
        </Grid>
    )
}

import { defaultIconSize, IIcon } from "./settings"


export const DownloadIcon = (props: IIcon) => {

    return (
        <svg
            width={props.width ? props.width : defaultIconSize.width}
            height={props.height ? props.height : defaultIconSize.height}
            viewBox="0 0 30 30"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
        <g clipPath="url(#clip0_33_5328)">
        <path fillRule="evenodd" clipRule="evenodd" d="M21.1634 13.9478C21.4771 13.6476 21.4771 13.1608 21.1634 12.8605C20.8497 12.5603 20.3411 12.5603 20.0274 12.8605L15.7727 17.1335L15.8049 6.10438C15.8049 5.49447 15.4303 5.00004 14.9681 5.00004C14.506 5.00004 14.1313 5.49447 14.1313 6.10438L14.0992 17.3858L9.3712 12.8605C9.05751 12.5603 8.54892 12.5603 8.23523 12.8605C7.92154 13.1608 7.92154 13.6476 8.23523 13.9478L14.1313 19.5911C14.445 19.8913 14.9536 19.8913 15.2673 19.5911L21.1634 13.9478Z" fill={props.color}/>
        <path d="M9 21.8163H20.5" stroke={props.color} strokeWidth="1.6" strokeLinecap="round"/>
        <circle cx="15" cy="15" r="14" transform="rotate(180 15 15)" stroke={props.color} strokeWidth="2"/>
        </g>
        <defs>
        <clipPath id="clip0_33_5328">
        <rect width="30" height="30" fill="white"/>
        </clipPath>
        </defs>
        </svg>
    )
}

import { useEffect, useState } from 'react';

import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import CreateIcon from '@mui/icons-material/Create';
import InboxIcon from '@mui/icons-material/Inbox';
import InputAdornment from '@mui/material/InputAdornment';
import LanguageIcon from '@mui/icons-material/Language';
import SettingsIcon from '@mui/icons-material/Settings';
import WarningIcon from '@mui/icons-material/Warning';
import SearchIcon from '@mui/icons-material/Search';

import { useFormik } from 'formik';
import * as yup from 'yup';

import AppBarHeader from '../../../components/AppBar/AppBarHeader';
import CustomizedDialogs from '../../../components/Dialog/CustomizedDialog';
import { CloseFullIcon } from '../../../components/Icon/CloseFullIcon';
import { TextFieldFormatted } from '../../../components/Field/TextFieldFormatted';

import { IPlateforme } from '../../../model';
import { createPlateforme, getPlateformes, modifyPlateforme } from '../../../api/Plateforme.api';

import { messageFieldRequired } from '../../../utils/messages';

import { colors } from '../../../static/themes/gsmc/colors';


interface IDialogProps {
    open: boolean;
    plateforme?: IPlateforme;
    handleClose: () => void;
    handleError: () => void;
}


const initialValues = {
    name: undefined as undefined | string,
    code: undefined as undefined | string,
    idHenner: undefined as undefined | number,
    codeHenner: undefined as undefined | string,
    codeCrm: undefined as undefined | string,
    mailMiseEnGestion: undefined as undefined | string,
    isActive: true as boolean,
    isGSMC: false as boolean,
    isBroker: false as boolean
}


const DialogPlateforme = (props: IDialogProps) => {

    useEffect(() => {
        formik.resetForm();

        if (props.plateforme !== undefined) {
            const values = {
                name: props.plateforme.name,
                code: props.plateforme.code,
                idHenner: props.plateforme.idHenner,
                codeHenner: props.plateforme.codeHenner,
                codeCrm: props.plateforme.codeCrm,
                mailMiseEnGestion: props.plateforme.mailMiseEnGestion,
                isActive: props.plateforme.isActive,
                isGSMC: props.plateforme.isGSMC,
                isBroker: props.plateforme.isBroker
            }

            formik.setValues(values);
        } else {
            formik.setValues(initialValues);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.open, props.plateforme])

    const validationSchema = yup.object({
        name: yup.string().required(messageFieldRequired),
        code: yup.string().required(messageFieldRequired).max(10, "10 caractères maximum").matches(/^[a-zA-Z0-9-_\.]+$/, "Ne doit pas contenir d'espace ni d'accent, seuls les caractères spéciaux '-', '_' et '.' sont autorisés"),
        idHenner: yup.string().required(messageFieldRequired),
        codeHenner: yup.string().required(messageFieldRequired),
        codeCrm: yup.string().required(messageFieldRequired),
        mailMiseEnGestion: yup.string().email("Email non valide").required(messageFieldRequired)
    })

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit: (values) => {
            if (props.plateforme) {
                const toSend: IPlateforme = {
                    id: props.plateforme.id,
                    dateCreated: props.plateforme.dateCreated,
                    techDateModification: props.plateforme.techDateModification,
                    name: values.name as string,
                    code: props.plateforme.code,
                    idHenner: values.idHenner as number,
                    codeHenner: values.codeHenner as string,
                    codeCrm: values.codeCrm as string,
                    mailMiseEnGestion: values.mailMiseEnGestion as string,
                    token: props.plateforme.token,
                    isActive: values.isActive,
                    isGSMC: values.isGSMC,
                    isBroker: values.isBroker,
                }
                modifyPlateforme(toSend).then((res: IPlateforme) => {
                    props.handleClose();
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
            else {
                const toSend: IPlateforme = {
                    name: values.name as string,
                    code: values.code as string,
                    idHenner: values.idHenner as number,
                    codeHenner: values.codeHenner as string,
                    codeCrm: values.codeCrm as string,
                    mailMiseEnGestion: values.mailMiseEnGestion as string,
                    isActive: values.isActive,
                    isGSMC: values.isGSMC,
                    isBroker: values.isBroker,
                }
                createPlateforme(toSend).then((res: IPlateforme) => {
                    if (res.id) {
                        props.handleClose();
                    }
                })
                .catch(exception => {
                    props.handleClose();
                    props.handleError();
                });
            }
        }
    })


    return (
        <CustomizedDialogs
            title="Plateforme"
            open={props.open}
            handleClose={props.handleClose}>
            <form
                onSubmit={formik.handleSubmit}>
                <DialogContent>
                    <Grid
                        container
                        spacing={2}
                        justifyContent="flex-start">
                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="name"
                                name="name"
                                label={"Nom*"}
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.name && Boolean(formik.errors.name)}
                                helperText={formik.touched.name && formik.errors.name} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="code"
                                name="code"
                                label={"Code*"}
                                value={formik.values.code}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.code && Boolean(formik.errors.code)}
                                helperText={formik.touched.code && formik.errors.code} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextFieldFormatted
                                fullWidth
                                id="idHenner"
                                label={"Identifiant Henner*"}
                                value={formik.values.idHenner?.toString()}
                                charactersEnabled="[^0-9]"
                                textLength={9}
                                onChange={(e, value) => formik.setFieldValue("idHenner", value)}
                                onBlur={formik.handleBlur}
                                error={formik.touched.idHenner && Boolean(formik.errors.idHenner)}
                                helperText={formik.touched.idHenner && formik.errors.idHenner} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="codeHenner"
                                name="codeHenner"
                                label={"Code Henner*"}
                                value={formik.values.codeHenner}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.codeHenner && Boolean(formik.errors.codeHenner)}
                                helperText={formik.touched.codeHenner && formik.errors.codeHenner} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="codeCrm"
                                name="codeCrm"
                                label={"Code CRM*"}
                                value={formik.values.codeCrm}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.codeCrm && Boolean(formik.errors.codeCrm)}
                                helperText={formik.touched.codeCrm && formik.errors.codeCrm} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <TextField
                                variant="outlined"
                                fullWidth
                                id="mailMiseEnGestion"
                                name="mailMiseEnGestion"
                                label={"Mail de mise en gestion*"}
                                value={formik.values.mailMiseEnGestion}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.mailMiseEnGestion && Boolean(formik.errors.mailMiseEnGestion)}
                                helperText={formik.touched.mailMiseEnGestion && formik.errors.mailMiseEnGestion} />
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Switch
                                checked={formik.values.isGSMC}
                                onChange={(e, value) => formik.setFieldValue("isGSMC", value)}
                                color="primary"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Typography component="span">Plateforme GSMC</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Switch
                                checked={formik.values.isActive}
                                onChange={(e, value) => formik.setFieldValue("isActive", value)}
                                color="primary"
                                name="estActif"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Typography component="span">Actif</Typography>
                        </Grid>

                        <Grid
                            item
                            xs={12}>
                            <Switch
                                checked={formik.values.isBroker}
                                onChange={(e, value) => formik.setFieldValue("isBroker", value)}
                                color="primary"
                                name="estDecrochageCourtier"
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Typography component="span">Décrochage depuis le CRM d'un courtier</Typography>
                        </Grid>
                    </Grid>
                </DialogContent>

                <DialogActions>
                    <Button autoFocus type="submit">
                        Sauvegarder
                    </Button>
                </DialogActions>
            </form>
        </CustomizedDialogs>
    )
}


export const PlateformeList = () => {
    document.title = 'BO - Plateformes';

    const [plateforme, setPlateforme] = useState<IPlateforme | undefined>();
    const [plateformes, setPlateformes] = useState<IPlateforme[] | undefined | null>(undefined);
    const [plateformesCount, setPlateformesCount] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(0);
    const [rowPerPage, setRowPerPage] = useState<number>(10);
    const [search, setSearch] = useState<string>();
    const [open, setOpen] = useState<boolean>(false);

    const [isSnackbarOpened, setIsSnackbarOpened] = useState<boolean>(false);

    const handleClickModify = (plateforme: IPlateforme) => {
        setPlateforme(plateforme);
        setOpen(true);
    }

    const handleClickSettings = (plateforme: IPlateforme) => {
        window.location.href = `/bo/plateforme/${plateforme.code}/settings`
    }

    const handleSnackbarClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway')
            return;

        setIsSnackbarOpened(false);
    };

    useEffect(() => {
        getPlateformes(pageNumber, rowPerPage, search).then(response => {
            setPlateformes(response.content);
            setPlateformesCount(response.totalElements);
        })
        .catch((exception) => {
            setPlateformes(null);
            setPlateformesCount(0);
        });
    }, [pageNumber, rowPerPage])


    const forceRefresh = () => {
        getPlateformes(pageNumber, rowPerPage, search).then(response => {
            setPlateformes(response.content);
            setPlateformesCount(response.totalElements);
        })
        .catch((exception) => {
            setPlateformes(null);
            setPlateformesCount(0);
        });
        setOpen(false);
        setPlateforme(undefined);
    }

    const copy = (value: string) => {
        navigator.clipboard.writeText(value);
    }

    const keyPress = (e: any) => {
        if (e.keyCode === 13) {
            setPageNumber(0);
            setSearch(e.target.value);
            forceRefresh();
        }
    }

    useEffect(() => {
        if (search === "")
            forceRefresh();
    }, [search])


    return (
        <>
        <AppBarHeader
            items={[
                {type: "TITLE", icon: <LanguageIcon />},
                {type: "LINK", label: "Plateforme"}
            ]}
            />

        <Box
            sx={{
                overflow:"auto",
                p: 2
            }}>
            <DialogPlateforme
                open={open}
                plateforme={plateforme}
                handleClose={() => forceRefresh()}
                handleError={() => setIsSnackbarOpened(true)} />

            <Grid
                container
                justifyContent="space-between"
                alignItems="stretch"
                spacing={2}>
                <Grid
                    item
                    xs={12} >
                    <Stack direction="row" alignItems="center" gap={1}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            id="search"
                            name="search"
                            onKeyDown={keyPress}
                            value={search}
                            onChange={(e: any) => setSearch(e.target.value)}
                            InputProps={{
                                placeholder: 'Recherche',
                                startAdornment: (
                                    <InputAdornment
                                        position="start">
                                        <SearchIcon />
                                    </InputAdornment>
                                ),
                            }}
                            sx={{
                                maxWidth: "500px"
                            }}/>
                        <IconButton
                            onClick={() => setOpen(true)}>
                            <AddCircleIcon color="primary"/>
                        </IconButton>
                    </Stack>
                </Grid>

                <Grid
                    item
                    xs={12} >
                    <Card
                        sx={{
                            border: 1,
                            borderColor: colors.grey.secondary,
                            width: '100%'
                        }}>
                        <TableContainer>
                            <Table aria-label="custom pagination table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            sx={{
                                                minWidth: "100px",
                                                position: "sticky",
                                                left: 0,
                                                background: colors.grey.secondary,
                                            }}>Nom</TableCell>
                                        <TableCell>Code</TableCell>
                                        <TableCell>Henner (identifiant - code)</TableCell>
                                        <TableCell>CRM (code)</TableCell>
                                        <TableCell>Email MEG</TableCell>
                                        <TableCell>Token</TableCell>
                                        <TableCell align="center">Actif</TableCell>
                                        <TableCell
                                            key={'settings'}
                                            sx={{
                                                minWidth: '80px',
                                                position: "sticky",
                                                right: 0,
                                                background: colors.grey.secondary,
                                            }}>
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {plateformes === undefined ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <Typography
                                                variant='subtitle1'>
                                                Chargement des plateformes en cours...
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : plateformes === null ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <WarningIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.red.main
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Une erreur est survenue lors de la récupération des plateformes
                                            </Typography>
                                            <Typography
                                                variant='body2'
                                                sx={{
                                                    mt: 1
                                                }}>
                                                Veuillez réessayer ultérieurement
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : plateformes.length === 0 ?
                                    <TableRow>
                                        <TableCell
                                            colSpan={8}
                                            sx={{
                                                height: '150px',
                                                textAlign: 'center'
                                            }}>
                                            <InboxIcon
                                                fontSize="large"
                                                sx={{
                                                    color: colors.black.secondary
                                                }} />
                                            <Typography
                                                variant='subtitle1'>
                                                Aucune plateforme
                                            </Typography>
                                        </TableCell>
                                    </TableRow>

                                    : plateformes.map((_, id) => {
                                        return (
                                            <TableRow key={id}>
                                                <TableCell
                                                    sx={{
                                                        position: "sticky",
                                                        left: 0,
                                                        background: colors.grey.secondary,
                                                    }}>{_.name}</TableCell>
                                                <TableCell>{_.code}</TableCell>
                                                <TableCell>{_.idHenner} - {_.codeHenner}</TableCell>
                                                <TableCell>{_.codeCrm}</TableCell>
                                                <TableCell>{_.mailMiseEnGestion}</TableCell>
                                                <TableCell>
                                                    <Stack direction="row" alignItems="center" gap={1}>
                                                        <IconButton
                                                            onClick={() => copy(_.token || "")}>
                                                            <ContentCopyIcon color="primary"/>
                                                        </IconButton>
                                                        {_.token}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell align="center">
                                                    {_.isActive ? <CheckIcon sx={{ color: colors.green.main }} /> : <CancelIcon sx={{ color: colors.red.main }} />}
                                                </TableCell>
                                                <TableCell
                                                    align="center"
                                                    sx={{
                                                        position: "sticky",
                                                        right: 0,
                                                        background: colors.grey.secondary,
                                                    }}>
                                                    <IconButton
                                                        onClick={() => handleClickSettings(_)}>
                                                        <SettingsIcon
                                                            sx={{
                                                                color: colors.black.main
                                                            }} />
                                                    </IconButton>
                                                    <IconButton
                                                        onClick={() => handleClickModify(_)}>
                                                        <CreateIcon
                                                            sx={{
                                                                color: colors.blue.main
                                                            }} />
                                                    </IconButton>
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>

                        <TablePagination
                            rowsPerPageOptions={[10, 25, 100]}
                            count={plateformesCount}
                            rowsPerPage={rowPerPage}
                            page={pageNumber}
                            onPageChange={(event, newPage) => setPageNumber(newPage)}
                            onRowsPerPageChange={(event) => setRowPerPage(parseInt(event.target.value, 10))}
                            sx={{
                                border: 0
                            }} />
                    </Card>
                </Grid>

                <Grid
                    item
                    xs={12} >
                </Grid>
            </Grid>

            <Snackbar
                sx={{
                    maxWidth: '1200px',
                    width: {
                        sm: '100%'
                    },
                    px: {
                        sm: 2
                    },
                    py: 'auto'
                }}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                open={isSnackbarOpened}
                autoHideDuration={3000}
                onClose={handleSnackbarClose} >
                <Alert
                    sx={{
                        mx: {
                            sm: 2
                        },
                        my: 'auto',
                        boxShadow: 3
                    }}
                    severity='error'
                    iconMapping={{
                        error: <CloseFullIcon color='white' bgColor={colors.red.main} />,
                    }} >
                    <Typography fontWeight={500} >Erreur lors de l'enregistrement de la plateforme.<br />⚠️  Le code, nom et code CRM doivent être uniques.</Typography>
                </Alert>
            </Snackbar>
        </Box>
        </>
    )
}

import { useState } from 'react';

import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { DialogSupport } from '../Dialog/DialogSupport';
import { ArrowRightIcon } from '../Icon/ArrowRightIcon';
import { ContactPhoneBackgroundIcon } from '../Icon/ContactPhoneBackgroundIcon';

import { IForm } from '../../model/Form.model';
import { IPerson } from '../../model/Person.model';
import { IPlateformeSettingsContact } from '../../model/PlateformeSettings.model';

import { enumContactMethodForm, enumContactMethodRedirect } from '../../utils/enums';
import { ICallback } from '../../model/Callback.model';


interface Props {
    plateformStyle: any;
    form?: IForm;
    person?: IPerson;
    settings: IPlateformeSettingsContact;
}


export const CardSupport = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;
    const cardStyle: any = plateformStyle.components.Card.support;

    const [isDialogOpened, setIsDialogOpened] = useState<boolean>(false);

    const handleAction = () => {

        if (props.settings.supportMethod === enumContactMethodRedirect &&
            props.settings.supportUri && props.settings.supportUri !== null) {
            window.open(props.settings.supportUri, '_blank');
            return;
        }

        if (props.settings.supportMethod === enumContactMethodForm) {
            setIsDialogOpened(true);
            return;
        }

        return;
    }

    const handleTelTo = () => {
        const link = document.createElement('a');

        link.href = 'tel:' + props.settings.supportPhone;
        link.click();

        document.body.appendChild(link);
    }

    return (
        <Card
            sx={{
                display: !props.settings.displaySupportCard ? 'none' : 'block',
                position: 'relative',
                height: '100%',
                zIndex: 100,
                backgroundColor: cardStyle.bgColor,
            }} >
            <CardMedia
                sx={{
                    position: 'absolute',
                    top: 0,
                    right: 0,
                    zIndex: -90,
                    height: '100%',
                    pl: 1,
                    pt: 2
                }} >
                <ContactPhoneBackgroundIcon color={cardStyle.color} width='100%' height='100%' />
            </CardMedia>
            <CardContent
                sx={{
                    "&:last-child": {
                        pb: 1,
                    },
                    zIndex: 1
                }} >
                <Grid
                    container
                    spacing={1}
                    alignItems="space-between">
                    <Grid
                        item
                        xs={12}>
                        <Typography variant='h2'>Besoin d'aide ?</Typography>
                    </Grid>

                    <Grid
                        item
                        xs={12}>
                        {props.settings.supportPhone && props.settings.supportPhone !== null &&
                        <Typography
                            variant='body1'
                            fontWeight={500}
                            onClick={() => handleTelTo()}
                            sx={{
                                textDecoration:'underline',
                                cursor:'pointer'
                            }}>
                            {props.settings.supportPhone}
                        </Typography>}
    
                        {props.settings.supportHours && props.settings.supportHours !== null &&
                        <Typography
                            variant='body1'
                            fontWeight={500}>
                            {props.settings.supportHours}
                        </Typography>}
                    </Grid>

                    {props.settings.displaySupportAction &&
                    <Grid
                        item
                        xs={12}
                        sx={{
                            pl: "0px !important"
                        }}>
                        <Button
                            onClick={() => handleAction()}
                            sx={{
                                py: 1,
                                textAlign: 'left',
                                color: plateformStyle.colors.black.main,
                                transition: "transform 400ms",
                                ':hover': {
                                    transform: "translateY(-6px)"
                                }
                            }}
                            endIcon={<ArrowRightIcon color={plateformStyle.colors.black.main} />} >
                            Je souhaite être rappelé
                        </Button>
                    </Grid>}
                </Grid>
            </CardContent>

            {props.settings.displaySupportAction &&
             props.settings.supportMethod === enumContactMethodForm &&
            <>
            <DialogSupport
                isOpened={isDialogOpened}
                plateformStyle={props.plateformStyle}
                callback={{
                    form: props.form,
                    senderLastname: props.person?.lastname,
                    senderFirstname: props.person?.firstname,
                    senderMail: props.person?.email,
                    senderPhone: props.person?.phoneMobile
                } as ICallback}
                handleClose={() => {
                    setIsDialogOpened(false);
                }} />
            </>}
        </Card>
    )
}

import { useEffect } from 'react';

import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Typography from '@mui/material/Typography';

import { CardOffer } from '../../components/Card/CardOffer';
import { FormHeader } from '../../components/Form/FormHeader';

import { IContract } from '../../model/Contract.model';
import { IContractBilling } from '../../model/ContractBilling.model';
import { IContractNeeds, getFormuleRecommendedId } from '../../model/ContractNeeds.model';
import { IContractSignature } from '../../model/ContractSignature.model';
import { IDocumentsDTO } from '../../model/DocumentsDTO.model';
import { IForm } from '../../model/Form.model';
import { IFormule } from '../../model/Formule.model';
import { IPerson } from '../../model/Person.model';

import { generateFormDocuments, generateFormDocumentsStatic } from '../../api/FormDocument.api';

import { enumGsmcRegimes } from '../../utils';
import { IPlateforme } from '../../model';
import { PageContentLayout } from '../../components/Layout/PageContentLayout';
import { IPromotion } from '../../model/Promotion.model';
import * as Sentry from "@sentry/react";


interface Props {
    plateformStyle: any;
    signature?: Boolean;
    sidebar?: JSX.Element;
    form: IForm;
    plateforme: IPlateforme;
    subscriber: IPerson;
    conjoint?: IPerson;
    childrens?: IPerson[];
    contract: IContract;
    contractNeeds?: IContractNeeds;
    contractSignature: IContractSignature;
    billingMain: IContractBilling;
    promotions?: IPromotion[]
    goToNext: () => void;
    goToPrevious: () => void;
}


export const FormDocumentsGenerate = (props: Props) => {

    const plateformStyle: any = props.plateformStyle;

    useEffect(() => {
        if (props.form.uuid && props.billingMain.iban) {
            const document = getDocumentsDTO();

            generateFormDocuments(props.form.uuid, document)
            .then(response => {
                if (response && response.length === 3)
                    props.goToNext();
                else {
                    Sentry.captureMessage("FormDocumentsGenerate -> generateFormDocuments - Exception",
                        {
                            level: 'error',
                            extra: {
                                response: response,
                                request: props.form?.uuid?.toString(),
                            }
                        }
                    );
                }
            })

            if (props.contractNeeds)
                generateFormDocumentsStatic(props.form.uuid, props.contractNeeds?.codeOffre)
        }
    }, [props.form.uuid, props.billingMain.iban])


    const getDocumentsDTO = () => {
        const toReturn: IDocumentsDTO = {
            codePlateforme: props.plateforme.code,
            bulletinAdhesion: {
                subscriber: {
                    ...props.subscriber,
                    phone: props.subscriber.phoneMobile,
                    regime: enumGsmcRegimes.find(e => e.value === props.subscriber.regime)?.label
                },
                conjoint: props.conjoint ? {
                    ...props.conjoint,
                    regime: enumGsmcRegimes.find(e => e.value === props.conjoint?.regime)?.label
                }: undefined,
                children: props.childrens && props.childrens.length > 0 ? props.childrens : undefined,
                offre: {
                    offerCode: parseInt(props.contractNeeds ? props.contractNeeds.codeOffre : '0'),
                    formuleCode: parseInt(props.contractNeeds?.formule ? props.contractNeeds.formule.idHenner : '0'),
                    label: props.contractNeeds?.formule?.formuleHenner,
                    price: 0.00,
                    subscribeDate: new Date(),
                    renfort: "",
                    detail: ""
                },
                contract: {
                    bic: props.billingMain.bic,
                    iban: props.billingMain.iban,
                    dateSigned: props.contractSignature.dateSigned,
                    dateStart: props.contract.dateStart ? props.contract.dateStart : new Date(),
                    dateCreated: new Date()
                },
                promotions: props.promotions
            },
            mandatPrelevement: {
                subscriber: {
                    ...props.subscriber,
                    phone: props.subscriber.phoneMobile,
                    regime: enumGsmcRegimes.find(e => e.value === props.subscriber.regime)?.label
                },
                contract: {
                    bic: props.billingMain.bic,
                    iban: props.billingMain.iban,
                    dateSigned: props.contractSignature.dateSigned,
                    dateStart: props.contract.dateStart ? props.contract.dateStart : new Date(),
                    dateCreated: new Date()
                },
                offre: {
                    offerCode: parseInt(props.contractNeeds ? props.contractNeeds.codeOffre : '0'),
                    formuleCode: parseInt(props.contractNeeds?.formule ? props.contractNeeds.formule.idHenner : '0'),
                    label: props.contractNeeds?.formule?.formuleHenner,
                    price: 0.00,
                    subscribeDate: new Date(),
                    renfort: "",
                    detail: ""
                },
            },
            recueilBesoins: {
                subscriber: {
                    ...props.subscriber,
                    phone: props.subscriber.phoneMobile,
                    regime: enumGsmcRegimes.find(e => e.value === props.subscriber.regime)?.label
                },
                conjoint: props.conjoint ? {
                    ...props.conjoint,
                    regime: enumGsmcRegimes.find(e => e.value === props.conjoint?.regime)?.label
                }: undefined,
                children: props.childrens && props.childrens.length > 0 ? props.childrens : undefined,
                offre: {
                    offerCode: parseInt(props.contractNeeds ? props.contractNeeds.codeOffre : '0'),
                    formuleCode: parseInt(props.contractNeeds?.formule ? props.contractNeeds.formule.idHenner : '0'),
                    label: props.contractNeeds?.formule?.formuleHenner,
                    price: 0.00,
                    subscribeDate: new Date(),
                    renfort: "",
                    detail: ""
                },
                formules: [
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needHospital,
                        icon: "hospital",
                        name: "Hospitalisation"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needRoutineCare,
                        icon: "routineCare",
                        name: "Soins courants"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needOptical,
                        icon: "optical",
                        name: "Optique"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needDental,
                        icon: "dental",
                        name: "Dentaire"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needSoftMedicine,
                        icon: "softMedicine",
                        name: "Médecine douce"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needAudio,
                        icon: "audio",
                        name: "Prothèse auditive"
                    },
                    {
                        powerName: undefined,
                        length: 5,
                        index: props.contractNeeds && props.contractNeeds.needHealthCure,
                        icon: "cure",
                        name: "Cure de soin"
                    }
                ],
                contract: {
                    bic: props.billingMain.bic,
                    iban: props.billingMain.iban,
                    dateSigned: props.contractSignature.dateSigned,
                    dateStart: props.contract.dateStart ? props.contract.dateStart : new Date(),
                    dateCreated: new Date()
                }
            }
        }

        // Remove unwanted needs.
        if (props.contractNeeds?.codeOffre === "512")
            toReturn.recueilBesoins.formules = toReturn.recueilBesoins.formules.filter(_ => _.icon !== "cure")
        else if  (props.contractNeeds?.codeOffre === "539")
            toReturn.recueilBesoins.formules = toReturn.recueilBesoins.formules.filter(_ => _.icon !== "cure" || _.icon !== "audio")

        if (props.contractNeeds)
            toReturn.recueilBesoins.offre.formuleRecommendedCode = getFormuleRecommendedId(props.contractNeeds)

        return toReturn;
    }


    return (
        <PageContentLayout
            header={
                <FormHeader
                    label={props.signature ? "Signature" : "Contrat"}
                    description={props.signature ? "" : ""} />}
            sidebar={props.sidebar}
            content={
                <Card
                    sx={{
                        height: '100%',
                        textAlign: 'center'
                    }} >
                    <LinearProgress
                        value={100}
                        color='primary' />

                    <Grid
                        container
                        alignItems='center'
                        sx={{
                            height: '100%'
                        }} >
                        <Grid
                            item
                            xs={12}
                            sx={{
                                my: 3,
                                mx: 1
                            }}>
                            <Typography
                                component='div'
                                variant='h2' >
                                {props.signature ?
                                <>Signature de votre contrat en cours...</>
                                :
                                <>Génération de votre contrat en cours...</>
                                }
                            </Typography>
                        </Grid>
                    </Grid>
                </Card>
            }/>
    )
}

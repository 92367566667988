import { colors } from './colors';

import { enumPlateformCodeTraduc } from '../../../utils';


export const style = {
    plateform: enumPlateformCodeTraduc,
    colors: colors,
    components: {
        Button: {
            submit: {
                color: 'secondary'
            }
        },
        Card: {
            contact: {
                color: colors.blue.main,
                bgColor: colors.blue.secondary
            },
            support: {
                color: colors.orange.main,
                bgColor: colors.orange.secondary
            }
        },
        Formules: {
            colors: [
                {
                    id: 18642,
                    color: colors.green.main
                },
                {
                    id: 18643,
                    color: colors.blue.main
                },
                {
                    id: 18644,
                    color: colors.purple.main
                },
                {
                    id: 18645,
                    color: colors.orange.main
                },
                {
                    id: 18646,
                    color: colors.pink.main
                },
                // Offer 312.
                {
                    id: 11899,
                    color: colors.green.main
                },
                {
                    id: 11900,
                    color: colors.blue.main
                },
                {
                    id: 11901,
                    color: colors.purple.main
                },
                {
                    id: 11902,
                    color: colors.orange.main
                },
                {
                    id: 11903,
                    color: colors.pink.main
                },
                {
                    id: 11904,
                    color: colors.red.main
                },
                // Offer 539.
                {
                    id: 19036,
                    color: colors.green.main
                },
                {
                    id: 19026,
                    color: colors.blue.main
                },
                {
                    id: 19027,
                    color: colors.purple.main
                },
                {
                    id: 19028,
                    color: colors.orange.main
                },
                {
                    id: 19029,
                    color: colors.pink.main
                },
                {
                    id: 19030,
                    color: colors.red.main
                }
            ],
            defaultColor: colors.blue.main
        },
        Icon: {
            color: colors.orange.main,
            bgColor: colors.orange.light
        },
        IconHeader: {
            color: colors.orange.main,
            bgColor: colors.orange.light
        },
        IconInfo: {
            color: colors.blue.main,
            bgColor: colors.blue.secondary
        },
        Link: {
            color: colors.blue.main,
        },
        List: {
            color: colors.blue.main,
        },
        Offer: {
            color: colors.blue.main,
            recommendedColor: colors.blue.main
        },
        ProgressBar: {
            form: {
                color: 'primary'
            },
            loading: {
                color: 'primary'
            }
        },
        RadioButton: {
            color: colors.blue.main
        },
        Stepper: {
            step: {
                color: colors.blue.main
            },
            progressBar: {
                color: "info"
            }
        }
    }
}

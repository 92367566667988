import { useFormik } from 'formik';
import * as yup from 'yup';

import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { colors } from '../../../static/themes/gsmc/colors';
import FormHelperText from '@mui/material/FormHelperText';
import { TextFieldPassword } from '../../Field/TextFieldPassword';
import { IUser } from '../../../model/auth/User.model';
import { ISigninRequest, passwordMaxLength, passwordMinLength } from '../../../model/auth/Authentication.model';
import AuthService from '../../../services/AuthService';
import { useNavigate } from 'react-router-dom';


const authService = new AuthService();


interface Props {
    user?: IUser;
    send: () => void;
}


export const FormSignin = (props: Props) => {

    const navigate = useNavigate();

    const getInitialValues = () => {
        return {
            username: props.user?.email,
            password: undefined as string | undefined
        }
    };

    const validationSchema = yup.object({
        username: yup.string().required('Champ obligatoire'),
        password: yup.string().required('Champ obligatoire')
    })

    const formik = useFormik({
        initialValues: getInitialValues(),
        validationSchema: validationSchema,
        onSubmit: (values) => {

            if (!values.username || !values.password)
                return;  // Should never occur with the yup validation.

            const toSend: ISigninRequest = {
                username: values.username,
                password: values.password
            };

            authService.login(toSend)
            .then(response => {
                props.send()
            })
            .catch(error => {
                if (error.message === "404")
                    formik.setFieldError("username", "Ce compte n'existe pas")
                else
                    formik.setFieldError("password", "Mot de passe incorrect")
            });
        }
    })


    return (
        <form
            onSubmit={formik.handleSubmit}>
            <Grid
                container
                justifyContent="space-between"
                spacing={4}>

                <Grid
                    item
                    xs={12}>
                    <Typography variant='h1'>Bonjour !</Typography>
                    <Typography variant='body2'>Bienvenue sur l'espace de vérification des souscriptions GSMC.</Typography>
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextField
                        fullWidth
                        id="username"
                        name="username"
                        placeholder="Votre mail ou nom de compte"
                        value={formik.values.username}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.username && Boolean(formik.errors.username)}
                        helperText={formik.touched.username && formik.errors.username} />
                </Grid>

                <Grid
                    item
                    xs={12}>
                    <TextFieldPassword
                        fullWidth
                        id="password"
                        placeholder="Votre mot de passe"
                        value={formik.values.password}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        error={formik.touched.password && Boolean(formik.errors.password)} />

                    {formik.touched.password && formik.errors.password &&
                    <FormHelperText
                        sx={{
                            ml: 2
                        }}>
                        <Typography
                            variant='caption'
                            color={colors.red.main}>
                            {formik.errors.password}
                        </Typography>
                    </FormHelperText>}
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 3,
                        sm: 2
                    }}
                    sx={{
                        pt: {
                            sm: '8px !important',
                            xs: 1.5
                        },
                        textAlign: {
                            sm: 'right',
                            xs: 'center'
                        }
                    }}>
                    <Link
                        sx={{
                            ':hover': {
                                cursor: 'pointer'
                            },
                            color: colors.blue.main,
                            textDecorationColor: colors.blue.main
                        }}
                        onClick={() => {
                            navigate("/auth/password/reset");
                        }}>
                        <Typography
                            noWrap
                            component='span'
                            fontWeight={700}>
                            Mot de passe oublié ?
                        </Typography>
                    </Link>
                </Grid>

                <Grid
                    item
                    xs={12}
                    order={{
                        xs: 2,
                        sm: 3
                    }}
                    sx={{
                        textAlign: 'center'
                    }}>
                    <Button
                        type='submit'
                        sx={{
                            width: {
                                sm: 'auto',
                                xs: '100%'
                            },
                            px: {
                                sm: 10,
                                xs: 0
                            },
                            color: 'white',
                            backgroundColor: colors.black.main,
                            boxShadow: "0px 4px 10px 0px #3F8CD860, 0px -4px 10px 0px #3F8CD860",
                            ':hover': {
                                backgroundColor: 'black'
                            }
                        }} >
                        Se connecter
                    </Button>
                </Grid>
            </Grid>
        </form>
    )
}
